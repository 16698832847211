<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Contratos de Aerolineas con Sorto's Travel Services</h5>
                    <p class="text-muted">Porcentajes de comision por ruta, aerolinea y clase de serivicio.</p>
                </div>
                <div>
                    <button  class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#contractModal">
                        <span class="icon-add-2 mr-2"></span> Nuevo Contrato
                    </button> 
                    <button  class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#testcomision">
                        <span class="icon-calculator"></span> Realizar Consulta de Comision 
                    </button>
                </div>
            </div>
            <div >
                <data-table :ajax="{ url: '/admin/contracts/get/allTable'}" :scrollY="'calc(100vh - 350px)'" ref="contractsTable" :serverSide="true" :columns="dt.columns()"></data-table>
            </div>
        </div>
        <div class="modal fade" id="contractModal" tabindex="-1" role="dialog" aria-labelledby="contractModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                        <form id="formContracts" autocomplete="off" @submit.prevent="saveContract" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title" id="contractModalTitle">Crear Nuevo Contrato</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 py-3 section">
                                <h5 class="font-weight-bold mb-0">{{ form.title }}</h5>
                                <p class="text-muted">Esto creará o editará un Contrato para una Aerolinea</p>
                                    <div class="form-row" >
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <label for="startDate">Fecha Inicio</label>
                                                <vue-datepicker v-model="form.start_date" class="form-control"></vue-datepicker>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <label for="endDate">Fecha Fin</label>
                                                <vue-datepicker v-model="form.end_date" class="form-control"></vue-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="primaryairline">Aerolinea</label>
                                                <airline-input id="primaryairline" v-model="form.airlines"></airline-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="airlineassoc">EMISION DE OTRAS PLACAS</label>
                                                <airline-input id="airlineassoc" v-model="form.otherairlines" :multiple="true"></airline-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-4">
                                            <div class="form-check">
                                                <input type="checkbox" id="infants" v-model="form.infants" class="form-check-input">
                                                <label for="infants">INFANTES</label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-check">
                                                <input type="checkbox" id="military" v-model="form.military" class="form-check-input">
                                                <label for="military">MILITARES</label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-check">
                                                <input type="checkbox" id="student" v-model="form.student" class="form-check-input">
                                                <label for="student">ESTUDIANTES</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-6">
                                            <div class="form-check">
                                                <input type="checkbox" id="roundtrip" v-model="form.roundtrip" class="form-check-input">
                                                <label for="roundtrip">ROUND TRIP</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-check">
                                                <input type="checkbox" id="oneway" v-model="form.oneway" class="form-check-input">
                                                <label for="oneway">ONE WAY</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-check">
                                                <input type="checkbox" id="multicity" v-model="form.multicity" class="form-check-input">
                                                <label for="multicity">MULTICITY</label>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                        </form>
                    </div>
                </div>
        </div>
        <div class="modal fade" id="testcomision" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                        <form id="formTest" autocomplete="off" @submit.prevent="testCommision" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Calcular Comision segun ruta</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label for="startDate">Fecha Inicio</label>
                                        <vue-datepicker name="departuredate" id="departuredate"  v-model="formtest.departuredate" class="form-control" ></vue-datepicker>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <label for="endDate">Fecha Fin</label>
                                        <vue-datepicker name="enddate" id="enddate"  v-model="formtest.enddate" class="form-control" ></vue-datepicker>
                                    </div>
                                </div>
                                </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="primaryairline">Aerolinea</label>
                                        <airline-input id="primaryairline" v-model="formtest.primaryairline"></airline-input>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="parthnerairlines">Aerolinea Conexion/Operada</label>
                                        <airline-input id="parthnerairlines" v-model="formtest.parthnerairlines"></airline-input>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="departurecode" class="d-flex justify-content-between">Origen </label>
                                        <v-select id="departurecode" placeholder="Buscar Origen..." v-model="formtest.departurecode" :options="airports" label="name" :filterable="false" @search="onSearchAirport">
                                            <template slot="no-options">Buscar Aeropuertos...</template>
                                            <template slot="option" slot-scope="option">
                                                ({{ option.iata }}) {{ option.name }}
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                {{ option.iata }}
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="endpointcode" class="d-flex justify-content-between">Destino </label>
                                        <v-select id="endpointcode" placeholder="Buscar Destino..." v-model="formtest.endpointcode" :options="airports" label="name" :filterable="false" @search="onSearchAirport">
                                            <template slot="no-options">Buscar Aeropuertos...</template>
                                            <template slot="option" slot-scope="option">
                                                ({{ option.iata }}) {{ option.name }}
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                {{ option.iata }}
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                            </div>


                            <div class="form-row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="flight_type">TIPO DE VUELO</label>
                                        <select id="flight_type" name="flight_type" class="form-control" v-model="formtest.flight_type">
                                            <option value="ROUNDTRIP">ROUND TRIP</option>
                                            <option value="ONEWAY">ONE WAY</option>
                                            <option value="MULTICITY">MULTICITY</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="serviceClass" class="d-flex justify-content-between">Clase de Servicio
                                        <!-- <button class="btn btn-primary" @click.prevent="selectAllClass">Select All</button> -->
                                        </label>
                                        <v-select id="serviceClass" placeholder="Seleccionar Clase de Servicio..." v-model="formtest.serviceClass" :options="serviceClass" taggable label="text" :filterable="false" multiple>
                                            <template slot="no-options">Seleccionar Clase de Servicio...</template>
                                            <template slot="option" slot-scope="option">
                                                {{ option.text }}
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                {{ option.text }}
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-4">
                                    <div class="form-check">
                                        <input type="checkbox" id="test_infants" v-model="formtest.infants" class="form-check-input">
                                        <label for="test_infants">INFANTES</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-check">
                                        <input type="checkbox" id="test_military" v-model="formtest.military" class="form-check-input">
                                        <label for="test_military">MILITARES</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-check">
                                        <input type="checkbox" id="test_student" v-model="formtest.student" class="form-check-input">
                                        <label for="test_student">ESTUDIANTES</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                    <p class="ml-auto p-2" style="font-size:18px;">Comision Aplica : <code id="commissionresult" class="highlighter-rouge"> ? % </code>.</p>
                            </div>

                                <div class="form-row">
                                    <p>Resultado : <code  class="highlighter-rouge" style="font-size:10px;"> {{ resultTestCommission }} </code>.</p>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Calcular Comision</button>
                        </div>
                        </form>
                    </div>
                </div>
        </div>
       
    </div>
    
</template>
<script>
import dataTable from '~/components/dataTable';
import ToggleButton from 'vue-js-toggle-button/src/Button'
import airlineInput from '~/components/airlineInput'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    components: {
        dataTable ,
        airlineInput,
		'toggle-button': ToggleButton
    },
    metaInfo() {
        return {
            title: "Boletines Informativos Pavolar"
        }
    },
    data() {
        return {
           firstLoad: false,
            dt: {
                columns : () => {
                    var $this = this;
                    let columns = [
                        { title: 'ID', data: 'id' },
                        { title: 'PERIODO DE VIGENCIA', data: 'id',
                            render: (data, type, row) => {
                                let start_date  = row.start_date 
                                let end_date    = row.end_date  
                                return start_date + ' - ' + end_date
                            }
                        },
                        { title: 'AEROLINEA PRINCIPAL', data: 'airlinecontract',className:'sm-column text-wrap',
                            render: (data, type, row) => {
                                let airline = ''
                                let airlines = data
                                data.forEach(element => {
                                    let image   = `<img class="airline-logo" width="20" src="/img/airlines/${element.iata}.png" onerror="this.src='/img/airlines/default.png';" />`
                                    if(element.pivot.relation_type=="primary"){
                                        airline += (airline != '') ? ','+element.name+image : element.name+image
                                    }
                                });

                                return airline
                            }
                        },
                        { title: 'AEROLINEAS ASOCIADAS', data: 'airlinecontract',className:'sm-column text-wrap',
                            render: (data, type, row) => {
                                let airline = ''
                                let airlines = data
                                data.forEach(element => {
                                    let image   = `<img class="airline-logo" width="20" src="/img/airlines/${element.iata}.png" onerror="this.src='/img/airlines/default.png';" />`
                                    if(element.pivot.relation_type!="primary"){
                                        airline += (airline != '') ? '</br>'+element.name+image : element.name+image
                                    }
                                });

                                return airline
                            }
                        },
                        { title: 'REGIONES', data: 'id', className:'md-column text-wrap',
                            render: (data, type, row) => {
                                let regions         = row.regions
                                let regions_content = ''
                                regions.forEach(element => {
                                    let countries_regions = element.countries_region
                                    regions_content += '<b>'+element.name+' : </b>'
                                    countries_regions.forEach((count,index) => {
                                        let separated = (index==0) ? '' : ', '
                                        regions_content += separated + count.countries_in_region.name                                         
                                    });
                                    regions_content += '</br>'
                                    
                                });
                                return regions_content
                            }
                        },
                         { title: 'APLICA A TIPOS DE VIAJE', data: 'id',className:'sm-column text-wrap',
                            render: (data, type, row) => {
                                let apply_round_trip= (row.apply_round_trip==1) ? ' ROUND TRIP </br>' : ''
                                let apply_one_way   = (row.apply_one_way==1) ? ' ONE WAY </br>' : ''
                                let apply_multicity = (row.apply_multicity==1) ? ' MULTICITY </br>' : ''
                                let apply_military  = (row.apply_military==1) ? ' MILITARY </br>' : ''
                                let apply_student   = (row.apply_student==1) ? ' STUDENT </br>' : ''
                                let apply_groups    = (row.apply_groups==1) ? ' GROUPS  ' : ''
                                let all =   apply_round_trip+apply_one_way+apply_multicity+apply_military+apply_student+apply_groups
                                all = all.trim()
                                all = (all.substring(all.length -1) == "-") ? all.substring(0 , all.length -1 ) : all
                                return all
                            }
                        },
                        { title: 'Opciones', data: 'id', orderable: false,className:'sm-column text-wrap',
                            render: (data, type, row, meta) => {
                                let buttons = []
                                let btnManage   =   `<button class="btn btn-soft btn-soft-success btn-rounded btn-sm font-weight-bold mx-1 btn-manage" title="Administrar" data-toggle="tooltip"><span class="icon-settings pointer-events-none"></span></button>`                                
                                let btnedit     =   `<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#contractModal">Editar</button>`;
                                let btndelete   =   `<button class="btn btn-soft btn-soft-danger btn-rounded btn-sm font-weight-bold btn-sm ml-2 font-weight-bold btn-delete" data-id="${data}">Eliminar</button>`

                                return `<div class="d-flex tooltip-table">${btnManage + btnedit + btndelete}</div>`;
                            } 
                        }
                    ];
                    return columns
                }
            },
            resultTestCommission : [{}],
            optionListDefault : [],
            optionList : [],
            airlines   : [],
            airports   : [],
            form: new WebForm("#formContracts", {
                    title : "Nuevo contrato",
                    saveContractId  :   0,
                    start_date      :   "2020-01-01",
                    end_date        :   "2020-12-01",
                    airlines        :   [],
                    otherairlines   :   [],
                    infants         : false,
                    military        : false,
                    student         : false,
                    roundtrip       : false,
                    oneway          : false,
                    multicity       : false
            }),
            formtest: new WebForm("#formTest", {
                title : "Prueba de Comisiones",
                departurecode    :   [],
                endpointcode     :   [],
                departuredate    :   "2020-01-01",
                enddate          :   "2020-12-31",
                primaryairline   :   [],
                parthnerairlines :   [],
                flight_type      : "ROUNDTRIP",
                military        : false,
                student         : false,
                infants         : false,
                roundtrip       : false,
                oneway          : false,
                multicity       : false,
                serviceClass    : ''
            }),
        }
    },
    methods: {
         getContracts(){
            var vm = this
            return axios.get('/admin/contracts/get/allTable').then(response => { 
                console.log(response.data)
            }).catch(exception => {
                vm.$snotify.warning('Ocurrio un error en retraer la reglas de utilidad.'); 
            });
        },
        testCommision(){
            $("#commissionresult").text("0");
            this.formtest.post('/admin/contracts/testcommision').then(response => {
                // this.$snotify.simple(response);
                this.resultTestCommission = response.data[0]
                $("#commissionresult").text(response.data[0].commision+ ' % ');
            }).catch(exception => {
                // this.setFormErrors(response, this.formtest)
            });
        },
        async saveContract(){
            let vm = this
            await this.form.post('/admin/contracts/savecontract').then(response => {
                this.$snotify.simple('Contrato de Aerolinea Agregado con Exito.');
                this.form.reset();
                $('#contractModal').modal('hide');
            }).catch(exception => {
                this.setFormErrors(response, this.form)
            });

            $('#contractModal').modal('hide');         
            vm.$refs.contractsTable.ajaxReload(); 
        },
        async deleteContract(contract){
            let vm = this
            let contract_id = contract.id
            let text = '¿Está seguro que desea eliminar este contrato de comision <span class="p-1 text-primary bg-light rounded font-weight-bold"> Perido # '+contract.id+' - '+contract.start_date+'-'+contract.end_date+'</span>?'
            $.confirm.delete(
                'Eliminar contrato de comision',
                text,
                function() {
                    axios.post('/admin/contracts/delete', {
                        id: contract_id
                        }).then((response) => {
                            vm.$snotify.success("Periodo Eliminado Exitosamente.")                                      
                            vm.$refs.contractsTable.ajaxReload();  
                        }).catch(() => {
                            vm.$snotify.warning("No se pudo eliminar este Contrato")
                        })
                    }
            )
        },
        editContract(data){
            let primary =   []
            let other   =   []
            
            data.airlinecontract.forEach(element => {
                if(element.pivot.relation_type=="primary"){
                    primary.push(element);
                }else{
                    other.push(element);
                }
            });

            let vm  = this
            vm.form.title           = "Editar Contrato Aerolineas"
            vm.form.saveContractId  =   data.id
            vm.form.start_date      =   data.start_date
            vm.form.end_date        =   data.end_date
            vm.form.airlines        =   primary
            vm.form.otherairlines   =   other
            vm.form.infants         =   data.apply_infant
            vm.form.military        =   data.apply_military
            vm.form.student         =   data.apply_student
            vm.form.roundtrip       =   data.apply_round_trip
            vm.form.oneway          =   data.apply_one_way
            vm.form.multicity       =   data.apply_multicity

         },
         onsearchPoint(search, loading) {
            if(_.isEmpty(search)){
                this.optionList = this.optionListDefault
                loading(false);
                return
            }
            loading(true);
            this.searchPoint(loading, search, this);
        },
        searchPoint: _.debounce((loading, search, vm) => {
            debugger
            axios.get(`/admin/contracts/get/pointSearch/${vm.selectedContract.id}/${escape(search)}`).then(res => {
                vm.airlines = res.data
                loading(false);
            });
        }, 800),
        onSearchAirlines(search, loading) {
                if(_.isEmpty(search)){
                    this.airlines = this.favoriteAirlines
                    loading(false);
                    return
                }
                loading(true);
                this.searchAirlines(loading, search, this);
            },
        searchAirlines: _.debounce((loading, search, vm) => {
            axios.get(`/admin/airlines/${escape(search)}`).then(res => {
                vm.airlines = res.data
                loading(false);
            });
        }, 800),
        onSearchAirport(search, loading) {
            if(_.isEmpty(search))
                return
            loading(true);
            this.searchAirport(loading, search, this);
        },
        searchAirport: _.debounce((loading, search, vm) => {
            axios.get(`/admin/airports/${escape(search)}`).then(res => {
                vm.airports = res.data
                loading(false);
            });
        }, 800)
    },
    mounted() {
        let vm = this 

        $(vm.$el).off("click.commission_contracts",".btn-delete");
        $(vm.$el).on("click.commission_contracts",".btn-delete",function(){
            let contract = vm.$refs.contractsTable.rowData($(this).closest('tr'));
            vm.deleteContract(contract);
        });

        $(vm.$el).off("click.commission_contracts",".btn-edit");
        $(vm.$el).on("click.commission_contracts",".btn-edit",function(){
            let contract = vm.$refs.contractsTable.rowData($(this).closest('tr'));
            vm.editContract(contract);
        });

        $(vm.$el).off('click.commission_contracts', '.btn-manage')
        $(vm.$el).on('click.commission_contracts', '.btn-manage', function(){
            let data = vm.$refs.contractsTable.rowData($(this).closest('tr'))
            $(this).addClass('btn-loading btn-loading-dark')
            vm.$router.push({ name: `booking-engine-commissions-id`, params: { id: data.id }})
        })
        this.getContracts();
    }
}
</script>
<style>
.card__cover {
    height: 200px;
    display: block;
    background-position: center;
    background-size: cover;
}
.card__cover p{
    color: white;
    overflow: hidden;
}

</style>