<template>
    <div class="setting-item">
        <div class="d-flex align-items-center">
            <div class="custom-control custom-checkbox my-1" v-if="item.type === 'BOOLEAN'">
                <input type="checkbox" :id="`item${item.name}`" name="itemList" v-model='value' @change="save" class="custom-control-input">
                <label class="custom-control-label" :for="`item${item.name}`">
                    <p class="font-weight-bold mb-0">{{ item.title }}</p>
                    <p class="text-muted small mb-2" v-text="item.description"></p>
                </label>
            </div>
            <div v-else>
                <p class="font-weight-bold mb-0">{{ item.title }}</p>
                <p class="text-muted small mb-2" v-text="item.description"></p>
            </div>
            <div class="ml-auto">
                <button class="btn btn-sm btn-light btn-hover shadow-none ml-auto mx-1" data-toggle="modal" data-target="#addSetting" v-can:manage @click="edit">
                    <span class="icon-pencil"></span>
                </button>
            </div>
        </div>
        <template v-if="item.type === 'STRING'">
            <input type="text" class="form-control" v-model='value' @keypress="characterLimit" />
        </template>
        <template v-else-if="item.type === 'NUMBER'">
            <input class="form-control" type="number" v-model='value' @keypress="numericInput" />
        </template>
        <template v-else-if="item.type === 'ARRAY' && validate.multiple">
            <TagInput
                :ref="`taginput-${item.name}`"
                placeholder="Seleccionar elementos..."
                :value="value"
                :show-focus="true"
                :data="validate.items"
                />
        </template>
        <template v-else-if="item.type === 'ARRAY'">
            <div class="custom-control custom-radio my-1" v-for="(item, index) in validate.items" :key="index">
                <input type="radio" :value="item" :id="`item${_uid}${index}`" name="itemList" v-model='value' @change="save" class="custom-control-input">
                <label class="custom-control-label" :for="`item${_uid}${index}`">{{ item }}</label>
            </div>
        </template>
        <p class="mb-0 text-danger" v-if="errorMessage">{{errorMessage}}</p>
        <button v-if="item.type !== 'BOOLEAN' && !(item.type === 'ARRAY' && !validate.multiple)" :disabled="!isValid" class="btn btn-soft-primary shadow-none center-content btn-sm mt-2" @click="save">
            <span class="icon-floppy-disk-2 mr-2"></span> Guardar Datos
        </button>
    </div>
</template>

<style scoped>
    .setting-item .btn-hover{
        display: none;
    }
    .setting-item:hover .btn-hover{
        display: initial;
    }
</style>

<script>
import TagInput from '~/components/tagInput'
export default {
    components: {
        TagInput
    },
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            value: null,
            itemValue: null,
            errorMessage: null
        }
    },
    watch: {
        item: {
            handler: function(newVal, oldVal) {
                if(!_.isNull(newVal))
                    this.itemValue = newVal.value
            },
            immediate: true
        },
    },
    computed: {
        validate(){
            return _.isEmpty(this.item.validate) ? null : JSON.parse(this.item.validate || null)
        },
        isValid(){
            return (this.itemValue || '').toString() !== (this.value || '').toString() &&
                   (this.value || '').toString().trim() != '' && (_.isArray(this.value) ? this.value.length > 0 : true)
        }
    },
    methods: {
        save () {
            let vm       = this
            let endpoint = `/admin/settings/sdk/bookingengine/set/value`

            let data = {
                id    : vm.item.id,
                value : vm.item.type === 'ARRAY' &&  vm.validate != null && vm.validate.multiple ? (vm.value).join(',') : vm.value.toString()
            }

            //validaciones adicionales
            if(vm.validate != null){
                if(vm.item.type === 'NUMBER'){
                    let number = parseFloat(data.value)
                    if(isNaN(number))
                        return vm.setError(`Valor numerico no valido`)
                    if(!vm.validate.decimal && !Number.isInteger(number))
                        return vm.setError(`No se permiten decimales`)
                    if(number < parseFloat(vm.validate.min))
                        return vm.setError(`El monto no debe ser menor a ${vm.validate.min}`)
                    if(number > parseFloat(vm.validate.max))
                        return vm.setError(`El monto no debe ser mayor a ${vm.validate.max}`)
                }
                if(vm.item.type === 'STRING' && (data.value || '').length > vm.validate.limit)
                    return vm.setError(`El limite de caracteres permitidos es ${vm.validate.limit}`)
            }

            axios.post(endpoint, data).then(response => {
                vm.itemValue = data.value
                vm.$snotify.success(`Los datos se han actualizado`);
            })
        },
        edit() {
            this.$emit('edit', this.item)
        },
        setError(message){
            if(this.errorMessage != null)
                return false
            
            this.errorMessage = message
            setTimeout(() => {
                this.errorMessage = null
            }, 5000);
            
            return false
        },
        characterLimit(e){
            if(this.validate == null || this.validate.limit == null)
                return

            //limite de caracteres
            if((this.value || '').toString().length > this.validate.limit){
                e.preventDefault()
                this.setError(`El limite de caracteres permitidos es ${this.validate.limit}`)
            }
        },
        numericInput(e){
            let keyCode = (e.keyCode ? e.keyCode : e.which)

            //permite solo valores numericos, 46 = punto, 45 = signo negativo
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 45)
                e.preventDefault()

            if(this.validate == null)
                return
            
            //Solo permite el signo negativo al inicio
            if((this.value != null && this.value != '') && keyCode == 45)
                e.preventDefault()

            //validacion si no permite decimales evita el punto
            if(!this.validate.decimal && keyCode == 46){
                this.setError(`No se permiten decimales`)
                e.preventDefault()
            }
            
            //obtiene el valor del elemento
            let value = ((this.value || 0) + parseInt(e.key) || 0)
            value = parseFloat(keyCode == 45 ? e.key + value : value)

            if(this.validate.min != null && value < parseFloat(this.validate.min)){
                this.setError(`El monto no debe ser menor a ${this.validate.min}`)
                e.preventDefault()
            }

            if(this.validate.max != null && value > parseFloat(this.validate.max)){
                this.setError(`El monto no debe ser mayor a ${this.validate.max}`)
                e.preventDefault()
            }
        }
    },
    created() {
        this.value = (this.item.type === 'ARRAY' && this.validate != null && this.validate.multiple) ? 
                     (this.itemValue || '').split(',').filter(n => n) : 
                      this.item.type === 'BOOLEAN' ? (this.itemValue === 'true' ? true : false ) : this.itemValue 
    }
}
</script>