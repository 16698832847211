
<script>

  import { ContentLoader } from "vue-content-loader"

  export default {
    props: [
        'primary_color', 
        'secondary_color',
        ],
 
    components: {
      ContentLoader
    }
  }

</script>
<template>
    <div class="user-loader" style="width: 200px;">
        <content-loader id="menuLoader" 
            v-bind:speed="1.5" 
            :primaryColor="primary_color" 
            :preserveAspectRatio="'xMinYMid meet'" 
            v-bind:secondaryColor="secondary_color"
            :width="200"
            :height="300"
            >


            <circle cx="100" cy="85" r="65"/> 
            <rect x="50" y="170" rx="5" ry="5" width="100" height="20"/> 
            <rect x="25" y="200" rx="5" ry="5" width="150" height="15"/> 
            <rect x="75" y="225" rx="5" ry="5" width="50" height="10"/> 

            

        </content-loader>
    </div>
</template>


<style scoped>
    .user-loader{
        width: 200px;
        display: inline-block;
        position: relative;
    }
    svg#menuLoader{
        z-index: 1;
        top: 0;
        left: 0;
    }
</style>