<template>
    <div>
        <label class="label-form">
            <p v-if="title">{{ title }}</p>
            <div class="input-text" :class="{'is-invalid': !isValid}">
                <span class="input-token" v-for="(item, index) in selection" :key="item.id || index">
                    <img v-if="item.img" @error="defaultImg" class="input-token-img" :src="item.img || imgDefault" :alt="item.label">
                    {{ item.label || item }}
                    <span class="input-token-close" @click="removeItem(item)"><span class="icon-close" grid="12"></span></span>
                </span>
                <input type="text" v-if="list.length == 0"
                    :id="`input-tag-${id}`"
                    v-model="inputText"
                    @keypress.enter.stop.prevent="selectTooglelist"
                    @keydown.delete.stop="deleteLastItem"
                    >
                <input v-else type="text"
                    :id="`input-tag-${id}`"
                    @keydown.down.stop.prevent="selectTooglelist"
                    @keydown.up.stop.prevent="selectTooglelist"
                    @keypress.enter.stop.prevent="selectTooglelist"
                    @keydown.delete.stop="deleteLastItem"
                    @focus="activeFocus"
                    @blur="resetFocus"
                    :data-toogle-list="'#list-'+id"
                    :placeholder="placeholder"
                    v-model="inputText">
                    <span class="token-measuring"></span>
            </div>
            <ul :id="'list-'+id" @mousedown.stop="" class="toggle-list" v-show="results">
                <li class="toggle-list-item py-2 px-3" v-for="(item, index) in results" :key="item.id || index"
                    :class="{'focus': index == 0}"
                    @mousedown.stop="addItem(item)">
                    <span class="${data} mr-2"></span>
                    <img v-if="item.img" @error="defaultImg" :src="item.img || imgDefault" class="toggle-list-item-img"/>
                    <span v-if="item.icon" :class="` mr-2 ${item.icon}`"></span> <!-- Agregado por didier xD, si ocupan quitarlo me avisan -->
                    <span class="toggle-list-item-title">{{ item.label || item }}</span>
                </li>
            </ul>
        </label>
    </div>
</template>
<style lang="css" scoped>
    .is-invalid{
        border-color: #e3342f;
    }
</style>
<script>
import { debuglog } from 'util';
import { setTimeout, clearTimeout } from 'timers';
export default {
    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        },
        title: String,
        placeholder: String,
        imgDefault: String,
        showFocus:  {
            type: Boolean,
            default: function () {
                return false
            }
        },
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        ajax: {
            type: Object,
            required: false
        },

    },
    data () {
        return {
            id: null,
            inputText: null,
            selection: this.value || [],
            list: this.data || [],
            add: true,
            remove: true,
            pending_remove: [],
            results_in_focus: false,
            removeTimeOut: null,
            isValid: true,
        }
    },
    watch : {
        value (new_value, old_value) {
            this.selection = new_value
        },
        data (new_value, old_value) {
            this.list = new_value
        },
        inputText(new_value, old_value) {
            let input = $(this.$el).find(`input[type="text"]`)
            let spa   = $(this.$el).find(`.token-measuring`)
            spa.text(input.val() || input.attr('placeholder') || '');
            // input[0].style('width', spa.width()+5 , 'important')
            input.css('cssText', 'width: '+spa.width()+5+'px !important; padding: 0 !important');
        },
    },
    computed: {
        results () {
            let vm = this
            if(vm.results_in_focus && !vm.inputText) {
                return vm.list.filter (itemList => {
                    let index_item = vm.selection.findIndex(item => {
                        return (item.id == itemList.id && item.id != undefined) || (item == itemList)
                    })

                    if(index_item < 0) {
                        return itemList
                    }
                })
            }
            let results = vm.list.filter(itemList => {
                let str = (itemList.queryMatch || itemList.label || itemList).toLowerCase()
                let n = -1

                if(vm.inputText) {
                    n = str.search(vm.inputText.toLowerCase())
                }
                let indexItemList = vm.selection.findIndex(item => {
                    return (item.id == itemList.id && item.id != undefined) || (item == itemList)
                })
                return n >= 0 && indexItemList < 0 
            }) 

            return results
        },
        ajaxAdd () {
            if(!this.ajax) {
                return false
            }
            if (!this.ajax.add) {
                return this.ajax
            }
            return this.ajax.add
        },
        ajaxRemove () {
            if(!this.ajax) {
                return false
            }
            if (!this.ajax.remove) {
                return this.ajax
            }
            return this.ajax.remove
        }
    },
    mounted () {
        this.id = this._uid
        let input = $(this.$el).find(`input[type="text"]`)
        let spa   = $(this.$el).find(`.token-measuring`)
        spa.text(input.val() || input.attr('placeholder') || '');
        input.css('cssText', 'width:'+spa.width()+5+'px !important; padding: 0 !important');
    },
    methods: {
        clear() {
            this.selection = [];
        },
        resetFocus () {
            let vm = this
            vm.results_in_focus = false
        },
        activeFocus () {
            if(!this.inputText && this.showFocus) {
               this.results_in_focus = true
            }
        },
        deleteLastItem () {
            if(!this.inputText && this.selection.length > 0) {
                let item = this.selection[this.selection.length - 1]
                this.removeItem(item)
            }
        },
        addItemText () {
            let exist = this.selection.find(s => {
                return (s.label == this.inputText) || s == this.inputText
            })

            if(exist || this.inputText == null) {
                this.isValid = false
                return
            }

            this.isValid = true
           
            this.addItem(this.inputText)
        },
        addItem (item) {
            let beforeData = JSON.stringify({item: item, selection: this.selection})

            this.$emit('beforeAdd', JSON.parse(beforeData))
            
            if(item == undefined || !this.add) {
                return
            } 
            let indexItemSelection = this.selection.findIndex(i => {
                return (i.id == item.id && i.id != undefined) || i == item
            })
            if(indexItemSelection > 0) return

            this.selection.push(item)

            let afterData = JSON.stringify({item: item, selection: this.selection })

            if(this.ajaxAdd) {
                let data = this.mergeParams(this.ajaxAdd.data, item)
                let ajax = JSON.stringify(this.ajaxAdd)
                    ajax = JSON.parse(ajax)
                    ajax.data = data
                console.log(ajax)
                axios(ajax).then(res => {
                    console.log('ajaxAdd', res)
                    this.$emit('afterAdd', JSON.parse(afterData))
                    this.inputText = null
                }) 
                return
            } 

            this.$emit('afterAdd', JSON.parse(afterData))

            let vm = this
            vm.inputText = ''
            let input =  document.getElementById(`input-tag-${this.id}`)
            setTimeout(function () {
                //Soluciona bus asignacion de tamaño del input despues del render
                vm.inputText = null
                //asigna foco al elemento despues de agregar el item
                input.focus()
            }, 50)
        },
        removeItem(item) {
            let vm = this
            this.isValid = true
            let beforeData = JSON.stringify({item: item, items: vm.pending_remove, selection: vm.selection })

            vm.$emit('beforeRemove', JSON.parse(beforeData))

            if(!this.remove) {
                return
            }

            let indexItemSelection = this.selection.findIndex(i => {
                return (i.id == item.id && i.id != undefined) || i == item
            })

            if(indexItemSelection < 0) return

            this.selection.splice(indexItemSelection, 1);

            console.log(this.selection)
            if(this.removeTimeOut) {
                clearTimeout(this.removeTimeOut)
            }

            this.pending_remove.push(item)

            let afterData = JSON.stringify({items: vm.pending_remove, selection: vm.selection })

            if(this.ajaxRemove) {
                let ajax = JSON.stringify(this.ajaxRemove)
                    ajax = JSON.parse(ajax)
                    ajax.data = {
                        items:  this.pending_remove
                    }
                this.removeTimeOut = setTimeout(function () {
                    axios(ajax).then(res => {
                        console.log('ajaxRemove', res)
                        vm.$emit('afterRemove', JSON.parse(afterData))
                        vm.pending_remove = []

                    }) 
                }, 200)
                return
            }
            
            this.removeTimeOut = setTimeout(function () { 
                vm.$emit('afterRemove', JSON.parse(afterData))
                vm.pending_remove = []
            }, 200)
        },
        mergeParams (object_1, object_2) {
            
            let obj_2 = JSON.stringify(object_2)
                obj_2 = JSON.parse(obj_2)
            if(object_1) {
                let obj_1 = JSON.stringify(object_1)
                    obj_1 = JSON.parse(obj_1)
                return {...obj_1, ...obj_2}
            }
            return obj_2
        },
        selectTooglelist (event) {
            let $input = event.target
            let direction = event.key
            if(this.data.length == 0) {
                if(direction == 'Enter') {
                    this.addItemText()
                }
                return
            }
            let toggle_list = $input.dataset.toogleList
            let $toggle_list = document.querySelector(toggle_list)
            let $child_focused = $toggle_list.querySelector('li.focus')
            if($child_focused && direction == 'Enter') {
                $child_focused.dispatchEvent(new Event('mousedown'))
                return
            }

            if(!$child_focused) {
                if(!$toggle_list.firstChild) {
                    return
                }
                $toggle_list.firstChild.classList.add('focus')
            } else {
                $child_focused.classList.remove('focus')
                let $next_child =  $child_focused.nextElementSibling

                if(direction == 'ArrowUp') {
                    $next_child =$child_focused.previousElementSibling
                }

                if($next_child) {
                    $next_child.classList.add('focus')
                } else {
                    if(direction == 'ArrowUp') {
                        $toggle_list.lastChild.classList.add('focus')
                    } else {
                        $toggle_list.firstChild.classList.add('focus')
                    }
                }
            }

        }
    }
}
</script>