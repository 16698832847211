<template>
    <div class="modal fade" id="addRoomService">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="formRoomService" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formRoomService.id">
                                Editar Servico de Cuarto
                            </span>
                            <span v-else>Nuevo Servico de Cuarto</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="title">Nombre Servicio</label>
                            <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formRoomService.title" name="title">
                        </div>
                        <div class="form-group">
                            <label for="icon">Icono</label>
                            <IconSelector name="icon" v-model="formRoomService.icon" required="required"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'packages-hotels-index'" > 
                            <span v-if="formRoomService.id && $can('update','packages-hotels-index')">
                                Editar
                            </span>
                            <span v-else-if="formRoomService.id && !$can('update','packages-hotels-index')">
                                Solicitar Edicion
                            </span> 
                            <span v-else>
                                Guardar
                            </span>
                        </button> 
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import IconSelector from '~/components/icons/IconSelector.vue'

export default {
    props: ["HotelService"],
    components: {
        IconSelector
    },
    data() {
        return {
            formRoomService: new WebForm("#formRoomService", {
                title           : '',
                icon            : '',
                id: null,
            }),
        };
    },
    watch: {
        HotelService: function(val, oldVal) {
            if (_.isEmpty(this.HotelService) || this.HotelService.id == '') {
                // console.log('Vacio Att: ', this.AttractionCategory);    
            }else {
                this.formRoomService.fill(this.HotelService);  
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            let tipo = vm.formRoomService.id == null ? 1 : 2;
            if(this.formRoomService.id != null) {
                $.confirm({
                    title: `Editar Servicio`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.updateOrCreate('/admin/hotels/services/save', tipo)
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.updateOrCreate('/admin/hotels/services/save', tipo)
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formRoomService.post(url).then(response => {
                let data = response.data; 
                vm.formRoomService.id = null;
                if (data.user_request_status) {
                    $('#addRoomService').modal('hide');
                    return;
                }

                let snotify_message =
                    type == 1
                    ? "Elemento Agregado con exito."
                    : "Elemento actualizado con exito.";
                vm.$snotify.success(snotify_message);

                let obj = data.success;
                vm.$emit('reloadRoomServiceModal', obj);
                $("#addRoomService").modal("hide");
                return;
            });
        }
    },
    mounted() {
        let vm = this;
        $('#addRoomService').off('hidden.bs.modal');
        $('#addRoomService').on('hidden.bs.modal', function () {
            vm.formRoomService.clear();
            vm.formRoomService.reset();
            vm.$emit('closeRoomServiceModal');
        });
    }
};
</script>