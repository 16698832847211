<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Paquetes Turisticos</h5>
                    <p class="text-muted mb-1">Lista de Paquetes disponibles</p>
                </div>
                <div>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent='seeServices'>
                        <span class="icon-menu-4 mr-2"></span> Servicios
                    </button>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent='seeCategories'>
                        <span class="icon-menu-4 mr-2"></span> Categorias
                    </button>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalMyPackage">
                        <span class="icon-add-2 mr-2"></span> Nuevo Paquete
                    </button>
                </div>
            </div>

            <package-modal :MyPackage="MyPackage" v-on:closePackageModal="clearPackageVar()" v-on:reloadPackage="reloadPackage()" v-on:createdPackage="goPackage"
            :PackageCategoryNew="PackageCategory" :StateNew="State" :CityNew="City"
            ></package-modal>

            <!-- Modals Inner PackageModal -->
            <package-category-modal v-on:reloadPackageCategoryModal="reloadPackageCategory"></package-category-modal>
            <state-modal v-on:reloadStateModal="reloadState"></state-modal>
            <city-modal v-on:reloadCityModal="reloadCity"></city-modal>
            <!--  -->
         
            <data-table :ajax="{ url: '/admin/packages/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="packageTable" :serverSide="true" :columns="dt.columns()"></data-table>
        
        </div>
    </div>
</template>

<script>
    import dataTable from '~/components/dataTable';
    import PackageModal from '~/components/packages/PackageModal';
    import StateModal from '~/components/geodatos/StateModal';
    import CityModal from '~/components/geodatos/CityModal';
    import PackageCategoryModal from '~/components/packages/PackageCategoryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            PackageModal,
            StateModal,
            CityModal,
            PackageCategoryModal,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                PackageCategory     : null,
                State               : null,
                City                : null,
                MyPackage           : null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id',              data: 'id' },
                                { title: 'Category', data: 'package_category.name',  orderable: true, searchable: true,
                                render: function ( data, type, row) {
                                    let name = data;
                                    return  `<div style="font-size: 12px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    ${name}</div>`;
                                }},
                                { title: 'Title',     data: 'title',
                                render: function ( data, type, row) {
                                    return  `<div style="width: 200px; margin-right: 5px; font-size: 12px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" title="${data}">
                                    <span class="title_package go-admin">${data}</span>
                                    </div>`;
                                }},
                                { title: 'Location', data: 'city',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let name = data.name + ' ' + data.state.name ;
                                    return  `<div style="font-size: 12px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    <img src="https://www.countryflags.io/${data.state.country.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.state.country.short_iso_code}">
                                    ${data.state.country.name} </br>${name}</div>`;
                                }},
                                { title: 'State',     data: 'state_package', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `<div style="margin-right: 5px; font-size: 12px; line-height: 25px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    <span class=" text-state ${ data.toLowerCase()} change-state">${data}</span>
                                    </div>`;
                                }},
                                { title: 'Start Date',     data: null,  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `<div style="margin-right: 5px; font-size: 11px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    ${data.start_date}
                                    <br>
                                    ${data.start_time}
                                    </div>`;
                                }},
                                { title: 'End Date',     data: null,  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `<div style="margin-right: 5px; font-size: 11px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    ${data.expired_date}
                                    <br>
                                    ${data.expired_time}
                                    </div>`;
                                }},
                                { title: 'Options', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalMyPackage" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalMyPackage" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let allButtons  =  `
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop" type="button" class="btn btn-soft btn-sm btn-soft-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Opciones <span class="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop">
                                                <a class="dropdown-item" href="/voucher/package/${data.id}" target="_blank">
                                                    <span class="icon-print mr-1" grid="16"></span>
                                                    Imprimir
                                                </a>
                                                <a class="dropdown-item go-admin" data-id="${data.id}" href="#">
                                                    <span class="icon-config-double mr-1" grid="16"></span>
                                                    Administrar
                                                </a>
                                                <a class="dropdown-item change-state" data-id="${data.id}" href="#">
                                                    <span class="icon-refresh mr-1" grid="16"></span>
                                                    Cambiar Estado
                                                </a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#modalMyPackage" href="#">
                                                    <span class="icon-pencil-edith mr-1" grid="16"></span> 
                                                    Editar
                                                </a>
                                                <a class="dropdown-item btn-del" data-id="${data.id}" href="#">
                                                    <span class="icon-bin mr-1" grid="16"></span>
                                                    Eliminar
                                                </a>
                                            </div>
                                        </div>
                                        `;
                                        // let btnedit     =  $this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#modalMyPackage">Editar</button>&nbsp;` : `-`;
                                        // let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm font-weight-bold btn-delete" data-id="${data}">Eliminar</button>&nbsp; ` : `-`;
                                        return `<div class="d-flex">${ btni18n + ' ' + allButtons }</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            goAdmin(idP) {
                this.$router.push({ name: 'packages-id', params: { id: idP }});
            },
            reloadPackageCategory(obj) {
                this.PackageCategory = null;
                this.PackageCategory = obj;
                setTimeout(function(){
                    this.PackageCategory = null;
                }, 1000);
            },
            reloadState(obj) {
                this.State = null;
                this.State = obj;
                setTimeout(function(){
                    this.State = null;
                }, 1000);
            },
            reloadCity(obj) {
                this.City = null;
                this.City = obj;
                setTimeout(function(){
                    this.City = null;
                }, 1000);
            },
            clearVarGallery() {
                this.Gallery = null;
            },
            seeCategories: function () {
                this.$router.push({ name: 'packages-categories-index'})
            },
            seeServices: function () {
                this.$router.push({ name: 'packages-services-index'})
            },
            clearPackageVar() {
                this.MyPackage = null;
            },
            reloadPackage() {
                this.$refs.packageTable.ajaxReload();
            },
            goPackage(obj){
                if(obj.data.success) {
                    let package_id = obj.data.success.id;
                    this.goAdmin(package_id);
                }
            },
            onDelete(my_package) {
                let vm = this;
                $.confirm.delete(
                    'Eliminar Paquete',
                    `¿Está seguro que desea eliminar el Paquete <b>${my_package.title}</b>?`, 
                    function(){
                        vm.deleteItem(my_package)
                    }
                )
            },
            deleteItem (my_package) {
                let vm = this;
                axios.post('/admin/packages/delete', { id: my_package.id , image: my_package.banner }).then(response => {
                    let data = response.data;
                    if(data.success) {
                        vm.$snotify.success('El paquete se elimino con exito.');
                        vm.$refs.packageTable.ajaxReload();
                        return;
                    }
                    vm.$snotify.info('Se ha enviado solicitud');
                }).catch(err => console.log(err));
            },
            changeDisplay(pack_change) {
                const vm = this;
                let titulo = '';
                let desc = '';
                let clase = '';
                let textBoton = '';
                let val = '';
                if (pack_change.state_package == 'PUBLISHED' ) {
                    titulo = 'Cambiar a Borrador';
                    desc = `¿Desea cambiar este paquete a modo <b>'Borrador'</b>?`;
                    clase = 'btn-primary';
                    textBoton = 'Continuar';
                    val = 'DRAFT';
                } else if(pack_change.state_package == 'DRAFT' ) {
                    titulo = 'Publicar Paquete';
                    desc = `¿Desea <b>'Publicar'</b> este paquete ahora?`;
                    clase = 'btn-primary';
                    textBoton = 'Continuar';
                    val = 'PUBLISHED';
                } else {
                    return;
                }
                $.confirm({
                    title: titulo,
                    content: desc,
                    autoClose: 'Cancel|5000',
                    buttons: {
                        Editar:{
                            text: textBoton,
                            btnClass: clase,
                            keys: ['enter'],
                                action: function() {
                                    vm.changeState(pack_change.id, val);
                                }
                        },
                        Cancel: {
                            text: `Cancelar`,
                        },
                    }
                });
            },
            changeState(id_change, val) {
                let vm = this;
                axios.post('/admin/packages/change', { id: id_change , val: val }).then(response => {
                    let data = response.data;
                    if(data.success) {
                        vm.$snotify.success('Cambio de estado exitoso.');
                        vm.$refs.packageTable.ajaxReload();
                        return;
                    }
                    vm.$snotify.info('Se ha enviado solicitud');
                }).catch(err => console.log(err));
            }
        },
        mounted(){ 
            var vm = this;

            $(vm.$el).off("click.package",".go-admin");
            $(vm.$el).on("click.package",".go-admin",function(){
                let this_package = vm.$refs.packageTable.rowData($(this).closest('tr'));
                this_package.btn_i18n = false;
                vm.goAdmin(this_package.id);
            });

            $(vm.$el).off("click.package",".change-state");
            $(vm.$el).on("click.package",".change-state",function(){
                let this_package = vm.$refs.packageTable.rowData($(this).closest('tr'));
                this_package.btn_i18n = false;
                vm.changeDisplay(this_package);
            });

            $(vm.$el).off("click.package",".btn-edit");
            $(vm.$el).on("click.package",".btn-edit",function(){
                let this_package = vm.$refs.packageTable.rowData($(this).closest('tr'));
                this_package.btn_i18n = false;
                vm.MyPackage = this_package;
            });

            $(vm.$el).off("click.package",".btn-i18n");
            $(vm.$el).on("click.package",".btn-i18n",function(){
                let this_package = vm.$refs.packageTable.rowData($(this).closest('tr'));
                this_package.btn_i18n = true;
                vm.MyPackage = this_package;
            });
        
            $(vm.$el).off("click.package",".btn-del");
            $(vm.$el).on("click.package",".btn-del",function(){
                let this_package = vm.$refs.packageTable.rowData($(this).closest('tr'));
                vm.onDelete(this_package);
            });
           
        },
    }
</script>
<style>
    .published {
        border-bottom: solid 4px green;
        color: green;
    } 
    .expired {
        border-bottom: solid 4px orange;
        color: orange;
    } 
    .outoftime {
        border-bottom: solid 4px gray;
        color: gray;
    }
    .draft {
        border-bottom: solid 4px #27b4f5;
        color: #27b4f5;
    } 

    .title_package{
        font-weight: 600;
        padding: 5px;
        border-radius: 6px 6px;
    }
    .title_package:hover{
        cursor: pointer;
        background-color: cornflowerblue;
        color: beige;
    }
    .text-state{
        font-weight: 500;
    }
    .text-state:hover{
        cursor: pointer;
    }
</style>