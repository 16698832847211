<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Atracciones Turisticas</h5>
                    <p class="text-muted mb-1">Lista de Atracciones Turisticas</p>
                </div>
                <div class="ml-auto">
                    <button class="btn btn-info center-content d-inline-block" @click.prevent="goTo('packages-attractions-categories-index')">
                        <span class="icon-menu-4 mr-2"></span> Categorias
                    </button>
                    <button v-can:create="'packages-attractions-index'" class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalAttraction" >
                        <span class="icon-add-2 mr-2"></span> Nueva Atracción Turística
                    </button>
                </div>
            </div>

            <attraction-modal :Attraction="Attraction" 
            v-on:closeAttractionModal="clearAttractionVar()"
            v-on:reloadAttraction="reloadAttraction()"
            :AttractionCategoryNew="AttractionCategory" 
            :StateNew="State" 
            :CityNew="City">
            </attraction-modal>
            <!--  -->
            <attraction-category-modal 
            v-on:reloadAttractionCategoryModal="reloadAttractionCategory">
            </attraction-category-modal>

            <state-modal v-on:reloadStateModal="reloadState">
            </state-modal>
            <city-modal v-on:reloadCityModal="reloadCity">
            </city-modal>
            <!--  -->
            <attraction-gallery-modal :Gallery="Gallery" v-on:closeAttractionGalleryModal="clearVarGallery()"></attraction-gallery-modal>

            <data-table :ajax="{ url: '/admin/attractions/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="attractionTable" :serverSide="true" :columns="dt.columns()"></data-table>
        
        </div>
    </div>
</template>

<script>
    'use strict'
    import dataTable from '~/components/dataTable';
    import AttractionModal from '~/components/packages/AttractionModal';
    import AttractionCategoryModal from '~/components/packages/AttractionCategoryModal';
    import StateModal from '~/components/geodatos/StateModal';
    import CityModal from '~/components/geodatos/CityModal';

    import AttractionGalleryModal from '~/components/packages/AttractionGalleryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            AttractionModal,
            AttractionCategoryModal,
            StateModal,
            CityModal,
            AttractionGalleryModal,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                AttractionCategory          : null,
                State                       : null,
                City                        : null,
                Attraction                  : null,
                Gallery                     : null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'name', data: 'name' },
                                { title: 'Category', data: 'attraction_category.name', orderable: true, searchable: true },
                                { title: 'Description', data: 'description',
                                render: function ( data, type, row) {
                                    return  `<div style="width: 400px; line-height: 20px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${ (data) ? data : '' }</div>`;
                                }},
                                { title: 'Location', data: 'city',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let val = data.name + ' ' + data.state.name ;
                                    return  `<center>
                                    <img src="https://www.countryflags.io/${data.state.country.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.state.country.short_iso_code}">
                                    ${data.state.country.name} </br>${name}</center>`;
                                }},
                                { title: 'Actions', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalAttraction" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalAttraction" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let btngal     =  $this.$can('write')  ? `<button class="btn btn-primary btn-sm font-weight-bold btn-gal" data-id="${data.id}" data-toggle="modal" data-target="#modalAttractionGallery">Galeria</button>&nbsp; ` : `-`;
                                        let btnedit     =  $this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#modalAttraction">Editar</button>&nbsp;  ` : `-`;
                                        let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>&nbsp; ` : `-`;
                                        return `<div class="d-flex">${ btni18n + btngal + btnedit + btndelete }</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            reloadAttractionCategory(obj) {
                this.AttractionCategory = null;
                this.AttractionCategory = obj;
                setTimeout(function(){
                    this.AttractionCategory = null;
                }, 1000);
            },
            reloadState(obj) {
                this.State = null;
                this.State = obj;
                setTimeout(function(){
                    this.State = null;
                }, 1000);
            },
            reloadCity(obj) {
                this.City = null;
                this.City = obj;
                setTimeout(function(){
                    this.City = null;
                }, 1000);
            },
            clearVarGallery() {
                this.Gallery = null;
            },
            goTo: function (route_to_go) {
                this.$router.push({ name: route_to_go})
            },
            clearAttractionVar() {
                this.Attraction = null;
            },
            reloadAttraction() {
                this.$refs.attractionTable.ajaxReload();
            },
            setFocustags() {
                // this.$refs.vSelectCharacters.focus();
            },
            onDelete(attraction) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Attractión',
                    `¿Está seguro que desea eliminar la atracción <u>${attraction.name}</u>?`, 
                    function(){
                        vm.deleteItem(attraction.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/attractions/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.attractionTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
            onSaveItem() {
                let vm = this

                if(vm.validateImage()==0) {
                    vm.currentImage = '/img/pick.png';
                    vm.newImage = null;
                    vm.form.image = '';
                }

                if(vm.form.city) {
                    vm.form.city_id = vm.form.city.id;
                }
                if(vm.form.attraction_category) {
                    vm.form.attraction_category_id = vm.form.attraction_category.id;
                }

                let tipo = (vm.form.id != null) ? 1 : 2;
                if(this.form.id != null) {
                    $.confirm({
                        title: `Editar Atracción`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (type) {
                let vm = this
                vm.form.post('/admin/attractions/save').then(response => {
                    let data = response.data;
                    this.form.id = null;
                    if(!data.success) {
                        if(data.error) {
                           vm.form.errors = data.error
                        }
                        return;
                    }

                    if(data.is_manage || data.success) {
                        let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                        vm.$snotify.sucess(snotify_message);
                        this.$refs.attractionTable.ajaxReload();
                        $('#addAttraction').modal('hide');
                        vm.form.clear();
                        vm.form.reset();
                        return;
                    } 

                    vm.$snotify.info(`Se ha enviado solicitud`)
                    vm.$refs.attractionTable.ajaxReload();
                    $('#addAttraction').modal('hide');
                });
            },
        },
        mounted(){ 
            let vm = this;

            $(vm.$el).off("click.attraction",".btn-gal");
            $(vm.$el).on("click.attraction",".btn-gal",function(){
                let attraction = vm.$refs.attractionTable.rowData($(this).closest('tr'));
                attraction.btn_i18n = false;
                vm.Gallery = attraction.id;
            });

            $(vm.$el).off("click.attraction",".btn-edit");
            $(vm.$el).on("click.attraction",".btn-edit",function(){
                let attraction = vm.$refs.attractionTable.rowData($(this).closest('tr'));
                attraction.btn_i18n = false;
                vm.Attraction = attraction;
            });

            $(vm.$el).off("click.attraction",".btn-i18n");
            $(vm.$el).on("click.attraction",".btn-i18n",function(){
                let attraction = vm.$refs.attractionTable.rowData($(this).closest('tr'));
                attraction.btn_i18n = true;
                vm.Attraction = attraction;
            });
        
            $(vm.$el).off("click.attraction",".btn-delete");
            $(vm.$el).on("click.attraction",".btn-delete",function(){
                let attraction = vm.$refs.attractionTable.rowData($(this).closest('tr'));
                attraction.btn_i18n = true;
                vm.onDelete(attraction);
            });

        },
    }
</script>