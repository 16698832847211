<template>
    <div class="modal fade" id="modalQuoteState">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="formQuoteState" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h6 class="font-weight-bold mb-0">
                            <span>Estado de Cotización</span>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <select name="state_quote" id="state_quote" class="form-control" v-model="formQuoteState.state_quote">
                            <option value="PENDING">Pendiente</option>
                            <option value="IN_PROCESS">En proceso</option>
                            <option value="QUOTED">Cotizado</option>
                            <option value="FINISHED">Finalizado</option>
                        </select>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" v-can:create="'marketing-quotes-index'">
                            <span>Guardar</span>
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["quote"],
    data() {
        return {
            formQuoteState: new WebForm("#formQuoteState", {
                state_quote:        "",
                id:                 null
            })
        };
    },
    watch: {
        quote: function(val, oldVal) {
            let vm = this;
            if(vm.quote!=null) {
                vm.formQuoteState.id = vm.quote.quote_id;
                vm.formQuoteState.state_quote = vm.quote.state_quote;
            } else {
                vm.formQuoteState.id = null;
                vm.formQuoteState.state_quote = 'PENDING';
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            if (vm.formQuoteState.id) {
                vm.formQuoteState.post('/admin/quotes/change').then(response => {
                    let data = response.data;
                    if (data.user_request_status) {
                        $("#modalQuoteState").modal("hide");
                        return;
                    }

                    if(data.success) {
                        vm.$emit("saveModalQuoteState", data.success);
                        vm.$snotify.success("Estado de cotizacion actualizado");
                    }
                    $("#modalQuoteState").modal("hide");
                    return;
                });
            }
        },
    },
    mounted() {
        let vm = this;
        $("#modalQuoteState").off("hidden.bs.modal");
        $("#modalQuoteState").on("hidden.bs.modal", function() {
            if(vm.quote!=null) {
                vm.formQuoteState.id = vm.quote.quote_id;
                vm.formQuoteState.state_quote = vm.quote.state_quote;
            } else {
                vm.formQuoteState.id = null;
                vm.formQuoteState.state_quote = 'PENDING';
            }
        });
    }
};
</script>
<style scoped>
</style>