window.Vue      = require('vue')
window._        = require('lodash')
window.numeral  = require('numeral')
window.Popper   = require('popper.js').default

/**
 * Vue Filters
 */
Vue.filter("numeral", function (value, format = "0,0") {
    return window.numeral(value).format(format)
})

/**
 * Vue LoaDash
 */
window._.isNumeric = (x) => {
    return !(isNaN(x)) && (typeof x !== "object") && (x != Number.POSITIVE_INFINITY) && (x != Number.NEGATIVE_INFINITY);
}
Object.defineProperty(Vue.prototype, '_', { value: window._ });

/**
 * Quill Editor
 */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module-withfix'
import vueQuillEditor from 'vue-quill-editor'

window.Quill = require('quill')
window.Quill.register('modules/ImageExtend', ImageExtend)
window.Quill.register('modules/imageDrop', ImageDrop)
window.Quill.register('modules/imageResize', ImageResize)

Vue.use(vueQuillEditor, {
    placeholder: 'Escribir texto aquí...',
    modules: {
        ImageExtend: {
            loading: true,
            name: 'img',
        },
        history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
        },
        imageDrop: true,
        imageResize: {
            displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        },
        toolbar: {
            container: container,
            isplayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            handlers: {
                'image': function () {
                    QuillWatch.emit(this.quill.id)
                }
            }
        }
    }
})

/**
 * Moment
 */
window.moment = require('moment')
require('moment/locale/es')
require('moment/locale/es-us')

const TZ_EL_SALVADOR = "America/El_Salvador";

let tz      = Intl.DateTimeFormat().resolvedOptions().timeZone || TZ_EL_SALVADOR
let locale  = tz == TZ_EL_SALVADOR ? 'es' : 'es-us'

// tz      = "America/New_York"
// locale  = 'es-us'

window.moment.tz.setDefault('UTC')
if(locale == 'es'){
    window.moment.updateLocale('es', {
        longDateFormat: {
            LT:"hh:mm A",
            LTS:"hh:mm:ss A",
            L:"DD-MM-YYYY",
            LL:"D [de] MMMM [de] YYYY",
            LLL:"D [de] MMMM [de] YYYY hh:mm A",
            LLLL:"dddd, D [de] MMMM [de] YYYY hh:mm A"
        }
    });
}else{
    window.moment.locale(locale)
}

import 'moment-timezone'
Vue.use(require('vue-moment'), {
    moment
});

/**
 * Snotify
 */
import snotify, { SnotifyPosition } from 'vue-snotify'
const options = {
    toast: {
        timeout: 5000,
        maxOnScreen: 5,
        showProgressBar: false,
        position: SnotifyPosition.rightBottom,
        // icon: false
    },
    // global: {
    //     preventDuplicates: true,
    // }
}
Vue.use(snotify, options)

/**
 * TimePicker
 */
import vueTimepicker from 'vue2-timepicker'
Vue.component('vue-timepicker', vueTimepicker)

/**
 * Push
 */
window.Push = window.push = require('push.js')
window.focused = true
window.onfocus = function() {
    focused = true
};
window.onblur = function() {
    focused = false
};

/**
 * vCan Permission
 */
import vCan from '~/plugins/v-can.js'
Vue.use(vCan)

/**
 * vCan Permission
 */
import vRange from '~/plugins/v-range.js'
Vue.use(vRange)

/**
 * Tooltip
 */
import vTooltip from 'v-tooltip'
Vue.use(vTooltip)
Vue.directive('v-tooltip', vTooltip)

/**
 * Yandex
 */
import YT from '~/plugins/yandexTranslate.js'
window.YandexTranslate = new YT('trnsl.1.1.20190611T222746Z.b4d40b05b9a26f8f.1e9092987f24c22da48f04ddd96ed8072a22c4dd')

/**
 * Vue Select
 */
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span', {
            class: 'icon-close',
            attrs: { 
                grid: '12'
            }
        }),
    },
    OpenIndicator: {
        render: createElement => createElement('span', {
            class: 'icon-down-arrow mr-1', 
            attrs: { 
                grid: '12'
            }
        }),
    },
});
Vue.component('v-select', vSelect)

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery')
    require('bootstrap')

    //Requerido Exportar Excel Datatable
    window.JSZip = require('jszip')
    //Requerido Exportar Pdf Datatable
    window.pdfMake = require('pdfmake')
    
    const pdfFonts = require("pdfmake/build/vfs_fonts")
    window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

    /**
     * https://datatables.net/
     */
    require('datatables.net')
    require('datatables.net-dt')
    require('datatables.net-buttons')
    require('datatables.net-buttons-dt')
    require('datatables.net-buttons/js/buttons.colVis.js' )
    require('datatables.net-buttons/js/buttons.html5.js' )
    require('datatables.net-buttons/js/buttons.print.js' )
    require('datatables.net-responsive')
    require('datatables.net-responsive-dt')
    require('datatables.net-select')
    require('datatables.net-select-dt')
    require('jquery-datatables-checkboxes')

} catch (e) {
	console.log(e)
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/**
 * DatePicker
 */
require('./datepicker/datepicker')
require('./datepicker/i18n/datepicker.es')
require('./datepicker/i18n/datepicker.en')
import vueDatepicker from '~/components/datePicker'
Vue.component('vue-datepicker', vueDatepicker)

/**
 * ToggleButton
 */
import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

import webForm from '~/plugins/webForm';
window.WebForm = webForm

require('./is-light-background')
require('./ripple')
require('./v-crumbs')

/**
 * Jquery Confirm
 */
require('./jquery-confirm')
window.$.confirm.delete = function (title, content, action, cancel = null) {
    this({
        title: title,
        content: content, 
        autoClose: 'Cancel|6000',
        buttons: {
            Delete:{
                text: `<div class="center-content"><span class="icon-bin mr-1"></span> Eliminar</div>`,
                btnClass: 'btn-soft btn-soft-danger',
                keys: ['enter'],
                action: action
            },
            Cancel: {
                text: `Cancelar`,
                action: cancel
            },
        }
    });
};

/**
 * Loading
 */
import loading from '~/components/loading'
Vue.component('loading', loading)
