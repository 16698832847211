<template>
    <div class="container" v-if="user">
        <img src="/img/logo-white.png" alt="pavolar.com" class="logo mt-1 mt-md-3 mb-2 mb-md-5">
        <div class="bg-curve">
            <svg xmlns="http://www.w3.org/2000/svg" class="curve" viewBox="0 0 1440 68" enable-background="new 0 0 1440 68"><path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill="#F4F5F7" transform="translate(0-1977)"></path></svg>
        </div>
        <div v-if="!expired" class="d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start flex-wrap login-container">
            <div class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                <h5 class="font-weight-bold mb-1 mt-3">Verificar Cuenta</h5>
                <p class="text-muted">Por favor cree una contraseña para su cuenta</p>
                <div class="alert alert-danger" v-if="form.errors.length == 1">
                    {{ form.errors[0] }}
                </div>
                <form autocomplete="off" @submit.prevent="verify" novalidate method="post">

                    <div class="form-group mt-4">
                        <label for="password">Password</label>
                        <div class="position-relative">
                            <input type="password" id="password" class="form-control bg-white" v-model="form.password" v-bind:class="{ 'is-invalid': form.errors.password }">
                            <span class="invalid-feedback" v-if="form.errors.password" v-for="error in form.errors.password">{{ error }}</span>
                            <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="togglePassword" v-model="form.visible">
                                <i class="far fa-eye"></i>
                            </button>
                        </div>
                    </div>

                    <div class="form-group" >
                        <label for="passwordConfirm">Confirm Password</label>
                        <input type="password" id="passwordConfirm" class="form-control"  v-model="form.passwordConfirmation">
                    </div>

                    <div class="form-group d-flex justify-content-between mt-5">
                        <button type="submit" class="btn btn-success ajax">Verificar Cuenta</button>
                    </div>
                </form>
            </div>
            <div class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                <h4>Hola! {{ user.first_name }} {{ user.last_name }}, bienvenido a Pavolar Admin</h4>
                <p>Esta seccion es para verficacion de de la cuenta del usuario <span v-if="user.username">{{ user.username }}</span>  <span v-if="user.email">y correo {{ user.email }}</span></p>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start flex-wrap login-container">
            <transition name="fade-slide" mode="out-in">
                <div v-if="!forward" :key="1001" class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                    <h4>Hola! {{ user.first_name }} {{ user.last_name }}.</h4>
                    <h4>Upps! este link de verificacion ha expirado.</h4>
                    <p>Contacte con el Administrador o su jefe Inmediato.</p>
                </div>
                <div v-else :key="1002" class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                    <h4>Nuevo link de virificacion enviado a {{ user.email }}, por favor rebice su correo.</h4>
                </div>
            </transition>
        </div>
        <p class="text-muted mb-0">© Pavolar | Todos los derechos reservados <span v-once>{{ year }}</span></p>
    </div>
</template>

<script>

    "use strict";
    import store from '~/store'

    export default {
        middleware: 'guest',
        metaInfo () {
            return { title: "Login" }
        },

        data(){
            return {
                token: null,
                user: null,
                expired: false,
                forward: false,
                visible: false,
                form: {
                    password : '',
                    passwordConfirmation : '',
                    errors   : {},
                },
                year: (new Date).getFullYear()
            };
        },

        methods: {
            // forwardEmail (event) {
            //     event.currentTarget.classList.add('load')
			// 	let vm = this
			// 	let endpoin = '/admin/forward/verification'
			// 	axios.post(endpoin, {
			// 		user_id: vm.user.user_id
			// 	}).then(response => {
			// 		if(response.data.success) {
            //             vm.forward = true
            //             event.currentTarget.classList.remove('load')
            //         }
			// 	})

            // },
            togglePassword: function ( e ) {
                $('.toggle-password').html(!this.visible ? '<i class="far fa-eye-slash"></i>' : '<i class="far fa-eye"></i>')
                $('#password').attr('type', this.visible ? 'password': 'text')
                $('#passwordConfirm').attr('type', this.visible ? 'password': 'text')
                this.visible = !this.visible
            },
            getUser() {
                let vm = this
                let endpoint = '/admin/account/user/token/' + vm.token
                axios.get(endpoint).then(response => {
                    let data = response.data
                    if(!data.success) {
                        vm.$router.push({name: 'error-not-found'})
                        return
                    }
                    vm.user    = response.data.user
                    vm.expired = response.data.expired
                })
            },

            verify () {
                let vm = this
                let endpoint = '/admin/account/email/verify'
                axios.post(endpoint, {
                    token: vm.token,
                    password: vm.form.password,
                    password_confirmation: vm.form.passwordConfirmation
                }).then(response => {
                    let data = response.data

                    if(!data.success) {
                        vm.form.errors = data.error
                    }
                     // Almacena el token y el usuario
                    this.$store.dispatch('auth/login', {
                        user:     data.data.user || null,
                        token:    data.data.token,
                        remember: false
                    })
                    this.$router.push({ name: 'index' })
                })
            }

        },
        mounted: function() {
            this.token = this.$route.params.token
            this.getUser();
        },

    }
</script>

<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
</style>
