<template>
    <div>
        <div class="section pt-3 pb-1">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Subscriptores</h5>
                    <p class="text-muted mb-1">Boletin de promociones</p>
                </div>
            </div>
            <UpdateTags v-if="multiTags.table" v-model="multiTags.tags" @input="updateTags" :action="multiTags.action"/>
            <EditSubscriber v-if="subscriber" :subscriber="subscriber" v-model="subscriber" @input="refreshData"/>
            <div class="position-relative">
                <data-table
                    ref="subscribersList"
                    :ajax="{ url: '/admin/subscriptions/members' }"
                    :serverSide="false"
                    :columns="dt.columns"
                    :buttonsPush="dt.buttonsPush"
                    :select="{
                        'style': 'multi'
                    }"
                    :order="[[8, 'asc']]"
                    :fixedColumns="{
                        leftColumns: 3,
                    }"
                    :scrollY="'calc(100vh - 365px)'">
                </data-table>
                <transition name="fade">
                    <div v-if="dt.loading" class="subscribers-list-loader text-center">
                        <DotsLoader message="Actualizado datos"/>
                    </div>
                </transition>
            </div>

        </div>
    </div>


</template>
<style lang="scss" scoped>
    .rating{
        &-icon{
            font-size: 0.9em;
        }
    }
    .subscribers-list-loader{
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1010;
    }
</style>
<script>

    import dataTable from '~/components/dataTable'
    import EditSubscriber from '~/components/marketing/EditSubcriber.vue'
    import UpdateTags from '~/components/marketing/UpdateTags.vue'
    import DotsLoader from '~/components/loaders/DotsLoader'

    export default {
        middleware:'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            EditSubscriber,
            DotsLoader,
            UpdateTags
        },
        metaInfo () {
            return { title: "Suscriptores" }
        },
        data(){
            let vm = this
            return {
                dt: {
                    xhr:(e, settings, json, xhr) =>{
                        $('.dataTables_wrapper').find('[data-toggle="tooltip"]').tooltip({container: 'td > .tooltip-table'})
                    },
                    loading: false,
                    buttonsPush: (() => {
                        
                        if(!vm.$can('write', 'marketing-email-subscribe-index'))
                           return []

                        return [
                            {
                                extend: 'collection',
                                fade: 150,
                                background: false,
                                text: 'Acciones',
                                className: 'btn-primary',
                                buttons: [
                                    {
                                        text: 'Desuscribir',
                                        className: 'btn-danger',
                                        action: function ( e, dt, node, config ) {
                                            vm.subscriberOrUnsubscribe(dt, 'unsubscribe')
                                        }
                                    },
                                    {
                                        text: 'Subscribir',
                                        className: 'btn-success',
                                        action: function ( e, dt, node, config ) {
                                            vm.subscriberOrUnsubscribe(dt, 'subscribe')
                                        }
                                    },
                                    {
                                        text: 'Agregar Etiquetas',
                                        action: function ( e, dt, node, config ) {
                                            vm.showTagsModal(dt, 'add')
                                        }
                                    },
                                    {
                                        text: 'Quitar Etiquetas',
                                        action: function ( e, dt, node, config ) {
                                            vm.showTagsModal(dt, 'remove')
                                        }
                                    },
                                ]
                            }
                        ]
                        
                    })(),
                    columns : [
                        {   title: 'Seleccionar',
                            data: 'email_address',
                            checkboxes: {
                                selectRow: true
                            }
                        },
                        // { title: 'Id', data: 'unique_email_id' },
                        { title: 'Cliente', data: 'customer' ,
                            render: (data, type, row) => {
                                let customer = row.customer
                                let mergeFields = row.merge_fields
                                let $photo = `<img src="${customer ? customer.photo : '/img/none.png'}" alt="${customer ? customer.first_name : mergeFields.FNAME}" class="rounded" onerror="${ this.imgNotFound() }" style="width:50px; height: 50px;">`
                                return `<div class="d-flex align-items-center modal-subscriber">
                                            ${$photo}
                                            <div class="ml-2">
                                                <p class="mb-0">${customer ?
                                                    `${customer.first_name} ${customer.last_name}` : (
                                                    mergeFields.FNAME || mergeFields.LNAME ? `${mergeFields.FNAME} ${mergeFields.LNAME || ''}` : 'Anónimo')}</p>
                                                <p class="mb-0 text-muted">${customer ? 'Cliente' : 'Visitante'}</p>
                                            </div>
                                        </div>`;
                            },
                            createdCell : (td, cellData, rowData, row, col) => {
                                $(td).on('click', '.modal-subscriber', (event) => {
                                    event.stopPropagation()
                                    vm.showSubscriberModal(rowData, row)
                                })
                            }
                        },

                        { title: 'Email', data: 'email_address',
                            render: (data, type, row) => {
                                let customer = row.customer
                                let isVerified = customer ? !_.isEmpty(customer["email_verified_at"]) : false
                                let linkToOpenMailChimp =`<a href='https://us13.admin.mailchimp.com/lists/members?id=${row['id']}' target='_blank'>${data}</a>`;
                                return `<span class="customer-status ${isVerified ? 'icon-circle-check status-success' : 'icon-alert-warning status-warning' }"></span> ${linkToOpenMailChimp}`;
                            }
                        },
                        { title: 'Telefono', data: null,
                            render: (data, type, row) => {
                                let customer = row.customer
                                let mergeFields = row.merge_fields
                                return `${customer ? customer.phone || '' : mergeFields.PHONE || ''}`;
                            }
                        },
                        { title: 'Email Marketing', data: 'status',
                            render: (data, type, row) => {
                                return `<span class="badge-pill ${data == 'subscribed' ?  'badge-success' : 'badge-light'}">${data}</span>`
                            }
                        },
                        { title: 'Rating', data: 'member_rating',
                            render: (data, type, row) => {
                                let rating  = []
                                for (let index = 0; index < 5; index++) {
                                    rating.push(`<span class="rating-icon icon-star-bold ${index < parseInt(data) ? 'text-warning' : ''}"></span>`)
                                }
                                return `<span style="color: #cfd4da;">${rating.join(' ')}</span>`
                            }
                        },
                        { title: 'Etiquetas', data: 'tags',
                            render: (data, type, row) => {
                                let html = data.map(tag => {
                                    return `<span class="badge-pill badge-primary">${tag.name}</span>`
                                })
                                return html.join(' ');
                            }
                        },
                        { title: 'Dirección', data: null, className: 'md-column text-wrap', render: (data, type, row) => {
                                let customer = row.customer
                                let mergeFields = row.merge_fields
                                let address = mergeFields.ADDRESS
                                let validAddress = typeof mergeFields.ADDRESS === 'object'
                                if(!validAddress) {
                                    address = {
                                        addr1: '',
                                        city: '',
                                        state: '',
                                        zip: '',
                                        country: '',
                                    }

                                    mergeFields.ADDRESS = address
                                }

                                if(_.isNull(customer) && !validAddress)
                                    return ''

                                let country = address.country || (!_.isNull(customer) && customer.country ? customer.country.short_iso_code : '?')
                                return validAddress ? `<img src="https://www.countryflags.io/${country}/flat/24.png" width="24" height="24" alt="${country}"> ${address.addr1}, ${address.city}, ${address.state} | ${address.zip} ${address.country}` : customer ? `${customer.address} ${customer.country ? customer.country.short_iso_code : ''}` : '';
                            }
                        },
                        { title: 'Fecha de subscripcion', data: 'timestamp_opt',
                            render: (data, type, row) => {
                                return $vm.$moment(data).format('L LT')
                            }
                        },
                        { title: 'Ultima actualización', data: 'last_changed',
                            render: (data, type, row) => {
                                return $vm.$moment(data).format('L LT')
                            }
                        },
                    ]
                },
                subscriber: null,
                currentRow: null,
                multiTags: {
                    table: null,
                    tags: [],
                    indexes: [],
                    action: 'add'
                },
                nSubscriptors : null,
            };
        },
        methods: {
            showSubscriberModal(data, row) {
                this.currentRow = row
                this.subscriber = data
                setTimeout(() => {
                    $('#editSubscriberModal').modal('show');
                }, 300)
            },
            refreshData(data) {
                this.rowDataRefresh(data, this.currentRow, false)
                this.subscriber = null
                this.currentRow = null
            },
            rowDataRefresh(data, idx, select = true) {
                let $table = this.$refs.subscribersList
                let row = $table.table.row(idx)
                row.data(data)
                row.draw()
                if(select) row.select()
            },
            subscriberOrUnsubscribe(table, action) {
                let vm = this
                let endpoint = `/admin/subscriptions/members/${action}`
                let indexesRows = Array.from(table.rows( { selected: true } ).indexes())
                let data = Array.from(table.rows( { selected: true } ).data())
                let emails = data.map(d => d.email_address)
                if(!emails.length) {
                    return vm.$snotify.warning('Seleccione subscriptores')
                }
                vm.dt.loading = true
                axios.post(endpoint, {
                    emails
                }).then(({data}) => {
                    indexesRows.forEach(idx => {
                        let dataRow = table.row(idx).data()
                        let newDataRow = data.find(d => d.id == dataRow.id )
                        let assingData = Object.assign(dataRow, newDataRow)
                        vm.rowDataRefresh(assingData, idx)

                    })
                    vm.$snotify.success(action == 'subscribe' ? 'Subscriptos' : 'Desuscriptos')
                    vm.dt.loading = false
                })
            },
            showTagsModal(table, action) {
                let vm = this
                vm.multiTags.table = table.rows( { selected: true } ).data()
                vm.multiTags.action = action
                vm.multiTags.indexes = Array.from(table.rows( { selected: true } ).indexes())
                if(!vm.multiTags.indexes.length) {
                    return vm.$snotify.warning('Seleccione subscriptores')
                }
                setTimeout(() => {
                    $('#tagsModal').modal('show');
                }, 300)
            },
            updateTags(tags) {
                let vm = this
                let dt =  Array.from(vm.multiTags.table)
                let endpoint = `/admin/subscriptions/members/multiple/tags/${vm.multiTags.action}`
                let emails = dt.map(d => d.email_address)
                vm.dt.loading = true
                axios.post(endpoint, {
                    emails,
                    tags
                }).then(({data}) => {
                    vm.dt.loading = false
                    if(data.indexOf(false) > 0) {
                        return vm.$snotify.warning('Algunos datos no se pudieron actualizar')
                    }
                    vm.multiTags.indexes.forEach(idx => {
                        let dataRow = vm.multiTags.table.row(idx).data()
                        let newTags = tags.map(t => {return {name: t}})
                        let oldTags = dataRow.tags
                        let mergeTags = vm.mergeTags(oldTags, newTags, vm.multiTags.action)
                        let assingData = Object.assign(dataRow, {tags: mergeTags})
                        vm.rowDataRefresh(assingData, idx)
                    })
                    vm.$snotify.success('Etiquestas actualizadas')

                    vm.multiTags.table = null,
                    vm.multiTags.action = 'add'
                    vm.multiTags.indexes = []
                    vm.multiTags.tags = []
                })
            },
            mergeTags(oldTags, newTags, action) {
                if(action == 'add') {
                    let tags = oldTags.concat(newTags)

                    return _.uniqBy(tags, function (e) {
                                return e.name;
                            });
                }

                let remove =  _.remove(oldTags, function(tag) {
                        let index = newTags.findIndex(t => t.name == tag.name)
                        return index >= 0;
                });
                return oldTags
            },
            initVerified() {
                let vm = this;
                setInterval(async () => this.verifiedNew(), 15000);
            },
            async verifiedNew() {
                await axios.get('/admin/subscriptions/count').then(response => {
                    let data = response.data;
                    if(!data.success) {
                        console.log("Error al verificar nuevas subscripciones...");
                        return;
                    }
                    if(data.success) {
                        if (this.nSubscriptors==null || this.nSubscriptors > data.success) {
                            this.nSubscriptors = data.success;
                        } else if (this.nSubscriptors < data.success) {
                            this.nSubscriptors = data.success
                            this.$snotify.success('Hay una nueva subscripcion...');
                            this.$refs.subscribersList.ajaxReload();
                        }
                        return;
                    }
                    return;
                }).catch(err => console.log(err));
            }
        },
        mounted() {
            let vm = this
            vm.initVerified();
        },
    }

</script>
