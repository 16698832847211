<template>
    <div>
        <div class="section pt-3 pb-1">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Aeropuertos</h5>
                    <p class="text-muted mb-1">Lista de aeropuertos disponibles</p>
                </div>
                <div>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" @click="addAirport()"  data-toggle="modal" data-target="#modalAirport">
                        <span class="icon-add-2 mr-2"></span> Nuevo
                    </button>
                </div>
            </div>
            <transition name="fade">
                <data-table :ajax="{ url: '/admin/airports' }" :scrollY="'calc(100vh - 350px)'" ref="airportTable" :serverSide="true" :columns="dt.columns"></data-table>
            </transition>
        </div>

        <AirportModal :Airport="Airport" v-on:closeAirportModal="clearAirportVar()"
        v-on:reloadAirport="reloadAirport()" :StateNew="State" :CityNew="City">
        </AirportModal>

        <state-modal v-on:reloadStateModal="reloadState"></state-modal>
        <city-modal v-on:reloadCityModal="reloadCity"></city-modal>

    </div>
</template>
<script>

    import dataTable from '~/components/dataTable';

    import AirportModal from '~/components/AirportModal';
    import StateModal from '~/components/geodatos/StateModal';
    import CityModal from '~/components/geodatos/CityModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            AirportModal,
            StateModal,
            CityModal
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        },
        computed: {
            
        },
        data() {
            return {
                Airport:    null,
                State       : null,
                City        : null,
                
                countries   : [],
                zones       : [],
                airports    : [],
                dt: {
                    columns : [
                        { title: 'Id',     data: 'id' },
                        { title: 'Nombre', data: 'name' },
                        { title: 'IATA',   data: 'iata' },
                        { title: 'ICAO',   data: 'icao' },
                        { title: 'Pais',   data: 'country', orderable: false, searchable: false,
                        render: function ( data, type, row, meta) {
                            
                            return  `<img src="https://www.countryflags.io/${data.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.short_iso_code}"> ${data.name} <br>
                                     <span class="text-muted">${row["state"] != '' ? row["state"]+', '+row["city"] : row["city"]}</span>`;
                        }},
                        { title: 'Ubicación', data: null, orderable: false, searchable: false, className: 'text-center',
                        render: function ( data, type, row, meta) {
                            return _.isEmpty(row["latitude"]) || _.isEmpty(row["longitude"]) ? `<i class="icon-location-pin"></i>` : `<a class="icon-location-pin btn-lg text-decoration-none w-100 d-block text-center" target="_blank" href="http://www.google.com/maps/place/${row["latitude"]},${row["longitude"]}"></a>`;
                        }},
                        { title: 'Zona Horaria', data: 'region_timezone.name', orderable: false, searchable: false},
                        { title: 'Opciones', data: 'id', orderable: false, searchable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write') ? `<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#modalAirport">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write') ? `<button class="btn btn-soft btn-soft-danger btn-rounded btn-sm ml-2 font-weight-bold btn-delete" data-id="${data}" data-name="${row["name"]}">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            };
        },
        methods: {
            addAirport() {
                this.Airport = null;
            },
            reloadState(obj) {
                this.State = null;
                this.State = obj;
                setTimeout(function(){
                    this.State = null;
                }, 1000);
            },
            reloadCity(obj) {
                this.City = null;
                this.City = obj;
                setTimeout(function(){
                    this.City = null;
                }, 1000);
            },
            clearAirportVar() {
                this.Airport = null;
            },
            reloadAirport() {
                this.$refs.airportTable.ajaxReload();
            },
            deleteAirport (airport_id) {
                let vm = this
                axios.post('/admin/airports/delete', { id: airport_id }).then(response => {
                    let data = response.data;
                    console.log(data);
                    if(response.data) {
                        if(data.is_manage) {
                            vm.$refs.airportTable.ajaxReload()
                            vm.$snotify.success('Elemento eliminado con exito.');
                        } else {
                            vm.$snotify.info(`Se ha enviado solicitud ${data.data.type}`);
                        }
                    } else  {
                        if(!data.is_manage) {
                            vm.$snotify.warning(`Ya existe una solicitud ${data.data.type} para este aeropuerto`);
                        } else {
                            vm.$snotify.error('Ocurrio un error :O');
                        }
                    }
                })
            }
        },
        mounted() {
            let vm = this;

            $(vm.$el).off("click.airport", ".btn-delete");
            $(vm.$el).on("click.airport", ".btn-delete",function(){
                let airport = vm.$refs.airportTable.rowData($(this).closest('tr'))
                $.confirm.delete(
                    'Eliminar Aeropuerto',
                    `¿Está seguro que desea eliminar el aeropuerto <u>${airport.name}</u>?`, 
                    function(){
                        vm.deleteAirport(airport.id)
                    }
                )
            })
            
            $(vm.$el).off("click.airport", ".btn-edit");
            $(vm.$el).on("click.airport", ".btn-edit", function(e){
                vm.Airport = vm.$refs.airportTable.rowData($(this).closest('tr'));
            });
        },
        destroyed() {
            let vm = this
            $(vm.$el).off("click.airport", ".btn-delete")
            $(vm.$el).off("click.airport", ".btn-edit")
        }
    }

</script>

<style scoped>
    .floating-button {
        width: 56px;
        height: 56px;
        bottom: 5px;
        right: 20px;
        font-size: 1rem;
        position: fixed !important;
    }
    .pagination a {
        padding: 5px 15px;
        border: 1px solid #dee2e6;
        border-radius: 3px;
    }
    .btn-transparent {
        outline: none;
        color: #fff;
        font-size: 2em;
        cursor: pointer;
    }
    .btn-transparent:hover {
        background: transparent;
    }
    .flag-img{
        width: 30px;
    }
</style>
