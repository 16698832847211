<template>
    <div class="modal fade" id="modalLanguage">
        <div class="modal-dialog">
            <div class="modal-content">
                <form :id="'formLanguage-'+_uid" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formLanguage.id">Editar lenguaje</span>
                            <span v-else>Nuevo Lenguaje</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 ">
                                <label for="name" class="required">Nombre</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre"
                                v-model="formLanguage.name" name="name" />
                            </div>
                            <div class="form-group col-12 ">
                                <label for="native_name" class="required">Nombre Nativo</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre Nativo"
                                v-model="formLanguage.native_name" name="native_name" />
                            </div>
                            <div class="form-group col-12">
                                <label for="iso_code" class="required">Codigo ISO</label>
                                <input type="text" class="form-control" v-model="formLanguage.iso_code"
                                placeholder="Ingrese ISO" name="iso_code">
                            </div>
                            <div class="form-group col-12">
                                <label for="short_iso_code" class="required">Codigo ISO corto</label>
                                <input type="text" class="form-control" v-model="formLanguage.short_iso_code"
                                placeholder="Ingrese ISO corto" name="short_iso_code">
                            </div>
                            <div class="form-group col-12">
                                <label for="system_language" class="required">Lenguage por defecto del Sistema</label> 
                                <toggle-button id="system_language" v-model="formLanguage.system_language" :sync="true" title="por defecto" data-toggle="tooltip" 
                                class="mb-0" color="#38c172" :width="40" />                            
                            </div>

                                   
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'geodata-languages'">
                            <span v-if="formLanguage.id && $can('update','geodata-languages')">Editar</span>
                            <span v-else-if="formLanguage.id && !$can('update','geodata-languages')">Solicitar Edicion</span>
                            <span v-else>Guardar</span>
                        </button>
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["Language"],
    components: {
        },
    data() {
        return {
            countries   : [],
            formLanguage: new WebForm("#formLanguage-" + this._uid, {
                name            : null,
                native_name     : null,
                iso_code        : null,
                short_iso_code  : null,
                id              : null,
                system_language : false
            }),
        };
    },
    watch: {
        Language: function(val, oldVal) {
            if (_.isEmpty(this.Language) || this.Language.id == '') {
                // console.log('Vacio Att: ', this.AttractionCategory);    
            }else {
                this.formLanguage.fill(this.Language);
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;

            let tipo = (vm.formLanguage.id == null) ? 1 : 2;
            if (this.formLanguage.id != null) {
                $.confirm({
                    title: `Editar Lenguaje`,
                    content: "¿Seguro quiere editar este lenguaje?",
                    buttons: {
                        eliminar: {
                            text: "Editar",
                            btnClass: "btn-primary",
                            keys: ["enter"],
                            action: function() {
                                vm.updateOrCreate("/admin/location/languages/save", tipo);
                            }
                        },
                        cancelar: function() {}
                    }
                });
            } else {
                vm.updateOrCreate("/admin/location/languages/save", tipo);
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formLanguage.post(url).then(response => {
                let data = response.data;

                console.log(data);
                if (data.user_request_status) {
                    $('#modalLanguage').modal('hide');
                    return;
                }

                let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                vm.$snotify.success(snotify_message);
                vm.$emit('reloadLanguageModal');
                vm.formLanguage.clear();
                vm.formLanguage.reset();
                $('#modalLanguage').modal('hide');
                return;

            });
        }
    },
    mounted() {
        let vm = this;
        $('#modalLanguage').off('hidden.bs.modal');
        $('#modalLanguage').on('hidden.bs.modal', function () {
            vm.formLanguage.clear();
            vm.formLanguage.reset();
            vm.$emit('closeLanguageModal');
        });
    }
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>