<template>
    <table></table>
</template>

<script>
    export default {
        props: {
            // https://datatables.net/reference/option/

            //DataTables - Features
            autoWidth: {
                type: Boolean,
                default: true,
            },
            deferRender: Boolean,
            info: {
                type: Boolean,
                default: true,
            },
            lengthChange: {
                type: Boolean,
                default: true,
            },
            ordering: {
                type: Boolean,
                default: true,
            },
            paging: {
                type: Boolean,
                default: true,
            },
            processing: {
                type: Boolean,
                default: true,
            },
            scrollX: {
                type: Boolean,
                default: true,
            },
            scrollY: {
                type: String,
                default: 'calc(100vh - 330px)',
            },
            searching: {
                type: Boolean,
                default: true,
            },
            serverSide: {
                type: Boolean,
                default: false,
            },
            stateSave: {
                type: Boolean,
                default: true,
            },
            //DataTables - Data
            ajax: [String, Object, Function],
            data: Array,
            //DataTables - Callbacks
            createdRow: Function,
            drawCallback: Function,
            footerCallback: Function,
            formatNumber: Function,
            headerCallback: Function,
            infoCallback: Function,
            initComplete: Function,
            preDrawCallback: Function,
            rowCallback: Function,
            stateLoadCallback: Function,
            stateLoadParams: Function,
            stateLoaded: Function,
            stateSaveCallback: Function,
            stateSaveParams: Function,
            //DataTables - Options
            deferLoading: {
                type: [Number, Array],
                default: null,
            },
            destroy: Boolean,
            displayStart: {
                type: Number,
                default: 0,
            },
            dom:  {
                type: String,
                default: "Bfrtip"
                // default: "<'d-flex row flex-wrap'<'col-xs-12 col-s-6 py-1 text-center text-md-left'B><'col-xs-12 col-s-6 py-1 text-center text-md-right'f>>" +
                //             "<'d-flex row flex-wrap'<'col-xs-12'tr>>" +
                //             "<'d-flex row flex-wrap'<'col-xs-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            },
            lengthMenu: {
                type: Array,
                default: () => {
                    return [[25, 50, 100, 200, 500, 1000], ["25 filas", "50 filas", "100 filas", "200 filas", "500 filas", "1000 filas"]]
                },
            },
            order: {
                type: Array,
                default: () => {
                    return  [[0, 'asc']]
                },
            },
            orderCellsTop: Boolean,
            orderClasses: {
                type: Boolean,
                default: true,
            },
            orderFixed: [Object, Array],
            orderMulti: {
                type: Boolean,
                default: true,
            },
            pageLength: {
                type: Number,
                default: 25,
            },
            pagingType: {
                type: String,
                default: 'simple_numbers',
                validator: function (value) {
                    let options = [
                        'numbers',            // Page number buttons only (1.10.8)
                        'simple',             //'Previous' and 'Next' buttons only
                        'simple_numbers',     //'Previous' and 'Next' buttons, plus page numbers
                        'full',               //'First', 'Previous', 'Next' and 'Last' buttons
                        'full_numbers',       //'First', 'Previous', 'Next' and 'Last' buttons, plus page numbers
                        'first_last_numbers', //'First' and 'Last' buttons, plus page numbers
                    ]
                    return options.indexOf(value) !== -1
                }
            },
            renderer: [String, Array],
            retrieve: Boolean,
            rowId: {
                type: String,
                default: 'DT_RowId',
            },
            scrollCollapse: {
                type: Boolean,
                default: false,
            },
            search: Object,
            searchCols: Array,
            searchDelay: {
                type: Number,
                default: 350
            },
            stateDuration: Number,
            stripeClasses: Array,
            tabIndex: Number,
            //DataTables - Columns
            columnDefs: Array,
            columns: Array,
            //DataTables - Internationalisation
            language: {
                type: Object,
                default: () => {
                    return {
                        sProcessing :         `<div class="table-processing"><div class="load-animation"></div></div>`,
                        sLengthMenu :         "Mostrar _MENU_ registros",
                        sZeroRecords :        "No se encontraron resultados",
                        sEmptyTable :         "Ningún dato disponible en esta tabla",
                        sInfo :               "Registros del _START_ al _END_ de _TOTAL_",
                        sInfoEmpty :          "0 registros",
                        sInfoFiltered :       "(filtrado de un total de _MAX_ registros)",
                        sInfoPostFix :        "",
                        sSearch :             "Buscar:",
                        sUrl :                "",
                        sInfoThousands :      ",",
                        sLoadingRecords :     "Cargando...",
                        oPaginate : {
                            sFirst :          "Primero",
                            sLast :           "Último",
                            sNext :           "Siguiente",
                            sPrevious :       "Anterior"
                        },
                        oAria : {
                            sSortAscending :  ": Activar para ordenar la columna de manera ascendente",
                            sSortDescending : ": Activar para ordenar la columna de manera descendente"
                        },
                        buttons: {
                            pageLength: {
                                _: "%d filas",
                                '-1': "Ver todo"
                            },
                            colvis : "Columnas"
                        }
                    }
                },
            },
            //DataTables - AutoFill
            autoFill: {
                type: [Boolean, Object],
                default: undefined
            },
            //DataTables - Buttons
            buttons: {
                type: Array,
                default: () => {
                    return [
                        {
                            extend: 'colvis',
                            text: `<span class="icon-menu-2-bold" grid="12"></span>`,
                            fade: 150,
                            background: false,
                        },
                        {
                            extend: 'pageLength',
                            fade: 150,
                            background: false,
                        },
                        {
                            extend: 'collection',
                            fade: 150,
                            background: false,
                            text: 'Exportar',
                            buttons: [
                                'copyHtml5',
                                'csvHtml5',
                                'pdfHtml5',
                                'excelHtml5'
                            ]
                        },
                        {
                            extend: 'print',
                            text: `<span class="icon-print mr-2" grid="12"></span> Imprimir`,
                        },
                    ]
                },
            },
            buttonsPush: {
                type: Array
            },
            //DataTables - ColReorder
            colReorder: {
                type: [Boolean, Object],
                default: undefined
            },
            //DataTables - Fixed
            fixedColumns: {
                type: [Boolean, Object],
                default: undefined
            },
            fixedHeader: {
                type: [Boolean, Object],
                default: undefined
            },
            keys: {
                type: [Boolean, Object],
                default: undefined
            },
            responsive: {
                type: [Boolean, Object],
                default: false
            },
            rowGroup: {
                type: [Boolean, Object],
                default: undefined
            },
            rowReorder: {
                type: [Boolean, Object],
                default: undefined
            },
            scroller: {
                type: [Boolean, Object],
                default: undefined
            },
            searchPanes: {
                type: [Boolean, Object],
                default: false
            },
            select: [Boolean, Object, String],
        },
        data () {
            return {
                table: null
            }
        },
        methods: {
            isDefined(object){
                return object != null && typeof(object) !== 'undefined'
            },
            //https://datatables.net/reference/api/

            //DataTables - Core
            tableClear(){
                this.table.clear()
            },
            ajaxReload() {
                this.table.ajax.reload()
            },
            ajaxJson() {
                this.table.ajax.json()
            },
            ajaxParams() {
                this.table.ajax.params()
            },
            ajaxUrl() {
                this.table.ajax.url()
            },
            ajaxUrlLoad(url = null, callback = null, resetPaging = true) {
                this.table.ajax.url(url).load(callback, resetPaging)
            },
            dataSource() {
                return this.table.data()
            },
            destroyTable(remove = false) {
                return this.table.destroy(remove)
            },
            tableDraw(paging = true) {
                //full-reset | true, full-hold | false, page
                return this.table.draw(paging)
            },
            tableI18n(token, def, numeric) {
                return this.table.i18n(token, def, numeric)
            },
            tableOrder(order, multiOrder = []){
                return this.table.order(order, ...multiOrder)
            },
            tablePage(set = null){
                //set: first, next, previous, last
                return this.isDefined(set) ? this.table.page(set) : this.table.page()
            },
            tableSearch(input, regex = false, smart = true, caseInsen = true){
                return this.table.search(input, regex, smart, caseInsen)
            },
            tableSettings() {
                return this.table.settings()
            },
            tableState() {
                return this.table.state()
            },
            //DataTables - Cells
            tableCell(...options){
                return this.isDefined(options) ? this.table.cell(...options) : this.table.cell()
            },
            cellData(...options){
                return this.tableCell(...options).data()
            },
            tableCells(...options){
                return this.isDefined(options) ? this.table.cells(...options) : this.table.cells()
            },
            cellsData(...options){
                return this.tableCells(...options).data()
            },
            //DataTables - Columns
            tableColumn(columnSelector = null, modifier = null){
                return this.table.column(columnSelector, modifier)
            },
            columnData(columnSelector = null, modifier = null){
                return this.tableColumn(columnSelector, modifier).data()
            },
            tableColumns(...options){
                return this.isDefined(options) ? this.table.columns(...options) : this.table.columns()
            },
            columnsData(...options){
                return this.tableColumns(...options).data()
            },
            //DataTables - Rows
            tableRow(rowSelector, modifier = null){
                return this.table.row(rowSelector, modifier)
            },
            rowData(rowSelector, data = null){
                return this.isDefined(data) ? this.tableRow(rowSelector).data(data) : this.tableRow(rowSelector).data()
            },
            tableRows(...options){
                return this.isDefined(options) ? this.table.rows(...options) : this.table.rows()
            },
            rowsData(...options){
                return this.tableRows(...options).data()
            }
        },
        mounted() {

            let vm       = this
            let dtObject = {}

            if(!vm.isDefined(vm.data) && !vm.isDefined(vm.ajax))
                throw new Error('Datatable - Data or Ajax is required.')

            //El delay por defecto del dt no se reinicia cuando se escriben caracteres nuevos
            // dtObject.searchDelay = this.searchDelay

            

            //Carga todas las propiedad al objecto del Datatable
            vm.$options._propKeys.forEach(prop => {
                if(vm.isDefined(vm[prop]))
                    dtObject[prop] = vm[prop]
            });

            //En el caso que no esten definidas las columnas toma la estrutura del objeto "data"
            if(!vm.isDefined(dtObject.columns) && vm.isDefined(vm.data) && vm.data.length > 0)
                dtObject.columns = Object.keys(vm.data[0])
            else if(!vm.isDefined(vm.columns))
                throw new Error('Datatable - Columns are required.')

            //Concatena los botones
            if(vm.isDefined(vm.buttonsPush))
                dtObject.buttons = (vm.buttons || []).concat(vm.buttonsPush || [])

            vm.$nextTick(() => {

                vm.table    = $(vm.$el).DataTable(dtObject)
                let wrapper = $(vm.$el).closest('.dataTables_wrapper')

                //Eventos
                vm.table.off('draw')
                vm.table.on('draw', function(e, settings){
                    vm.$emit('draw', {e, settings})
                })
                vm.table.off('destroy')
                vm.table.on('destroy', function(e, settings){
                    vm.$emit('destroy', {e, settings})
                })
                vm.table.off('init')
                vm.table.on('init', function(e, settings, json){
                    vm.$emit('init', {e, settings, json})
                })
                vm.table.off('preXhr')
                vm.table.on('preXhr', function(e, settings, json){
                    vm.$emit('preXhr', {e, settings, json})
                })
                vm.table.off('preInit')
                vm.table.on('preInit', function(e, settings, json){
                    vm.$emit('preInit', {e, settings, json})
                })
                vm.table.off('preDraw')
                vm.table.on('preDraw', function(e, settings){
                    vm.$emit('preDraw', {e, settings})
                })
                vm.table.off('page')
                vm.table.on('page', function(e, settings){
                    vm.$emit('page', {e, settings})
                })
                vm.table.off('search')
                vm.table.on('search', function(e, settings){
                    vm.$emit('search', {e, settings})
                })
                vm.table.off('processing')
                vm.table.on('processing', function(e, settings, proccessing){
                    vm.$emit('processing', {e, settings, proccessing})
                })
                vm.table.off('xhr')
                vm.table.on('xhr', function(e, settings, json, xhr){
                    vm.$emit('xhr', {e, settings, json, xhr})
                })

                //Es necesario colocar la propiedad "background: false" en la declaracion de los botones
                //tipo "collection" de esta manera el "background" se administrara manualmente
                wrapper.off("click", ".dt-buttons > .buttons-collection")
                wrapper.on("click", ".dt-buttons > .buttons-collection", function(e) {
                    setTimeout(() => {
                        if($(this).next(".dt-button-background").length == 0)
                            $(this).after(`<div class="dt-button-background dt-pointer-all"></div>`)
                    }, 50);
                })

                //Solucion bug dataTable, conflicto de eventos con el "background" y "click" de boton
                wrapper.off("mousemove", ".dt-buttons")
                wrapper.on("mousemove", ".dt-buttons", function(e) {
                    var x = e.pageX - this.offsetLeft;
                    var y = e.pageY - this.offsetTop;
                    $(this).find(".dt-button").each(function(i){

                        var bg = $(this).next(".dt-button-background")
                        if(bg.length == 0)
                            return

                        //Si el mouse esta sobre el boton, permite que el "backgound" acepte el evento click
                        var rect = this.getBoundingClientRect();
                        if (x > rect.left && x < rect.right && y > rect.top && y < rect.bottom) {
                            bg.removeClass("dt-pointer-none")
                            bg.addClass("dt-pointer-all")
                            return
                        }

                        //Caso contrario el "background" no permite ningun evento
                        bg.removeClass("dt-pointer-all")
                        bg.addClass("dt-pointer-none")
                    })
                })

                //Retraso al realizar la busqueda
                if(vm.searchDelay > 0 && dtObject.serverSide){

                    var sDelay = null
                    var $table = vm.table
                    var $delay = vm.searchDelay
                    var $input = wrapper.find('div.dataTables_filter input')

                    $input.off('keyup.DT input.DT');
                    $input.on('keyup', function() {

                        var search = $input.val();
                        clearTimeout(sDelay);

                        sDelay = setTimeout(function() {
                            if (search != null)
                                $table.search(search).draw();
                        }, $delay);
                    });
                }
            })

            $.fn.dataTable.ext.errMode = 'none';
        }
    }
</script>
