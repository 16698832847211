import store from '~/store'

const VCAN = {
    install(Vue, options) {
        
        Vue.directive('can', {
            inserted (el, binding, vnode, oldVnode) {
                
                let data = {}
                data.author     = 0
                data.roles      = store.getters['permission/access']['roles']
                data.access     = store.getters['permission/access']['permissions']
                data.user       = store.getters['auth/user']
                data.permission = binding.arg || ''
                data.name       = vnode.context.$route.name || vnode.context.$options.name || ''
                data.option     = Object.keys(binding.modifiers).slice(-1).pop() || 'remove'

                //Es necesario definir un permiso (create, read, update, delete) o nivel (manage, write, view) 
                if(data.permission == '')
                    throw new Error('A permission was expected.')
                
                //Verifica si se trata de un objecto = { name: 'nombreComponente', author: Id Autor }
                if (binding.value instanceof Object) {

                    if(binding.value.hasOwnProperty('name'))
                        data.name = binding.value.name
                    
                    if(binding.value.hasOwnProperty('author'))
                        data.author = binding.value.author

                }else {

                    //Define el tipo de valor que se recibe, si es numerico se trata de el ID del autor
                    //si es una cadena de texto se define como nombre del componente
                    let bindingName = binding.value || binding.expression
                    if(isNaN(bindingName))
                        data.name = bindingName || data.name
                    else
                        data.author = bindingName || 0
                }
                
                if(data.name == '')
                    throw new Error('Permission name not found.')

                //El usuario es propietario
                if(data.author != 0 && data.author == data.user.id) {
                    // TODO: Agregar soporte para administracion de permisos de autor de contenido
                }

                // console.log(data)

                if(!canAccess(data)) {
                   
                    switch (data.option) {
                        case 'remove':
                            el.remove()
                            break;
                        case 'disable':
                            el.disabled = true
                            break;
                        case 'hide':
                            el.style.display = 'none'
                            break;
                        default:
                            //En el caso que no este en la lista se agrega como clase
                            el.classList.add(data.option)
                            break;
                    }
                }
            }
        });
        
        Vue.prototype.$can = function (permission, options) {

            let data = {}
            data.author     = 0
            data.user       = store.getters['auth/user']
            data.access     = store.getters['permission/access']['permissions']
            data.permission = permission
            data.name       = this.$route.name || this.$options.name || ''

            if(data.permission == '')
                throw new Error('A permission was expected.')
         
            if (options instanceof Object) {

                if(options.hasOwnProperty('name'))
                    data.name = options.name
                
                if(options.hasOwnProperty('author'))
                    data.author = options.author

            }else {

                if(isNaN(options))
                    data.name = options || data.name
                else
                    data.author = options || 0
            }
            
            if(data.name == '')
                throw new Error('Permission name not found.')

            // console.log(data)

            //El usuario es propietario
            if(data.author != 0 && data.author == data.user.id) {
                // TODO: Agregar soporte para administracion de permisos de autor de contenido
            }

            return canAccess(data)

        }

        function canAccess(data){

            if(typeof data === 'undefined' && data == null)
                return false;

            //Evalua si existe un permiso de acceso o nivel que concuerde con los datos que se obtienen desde la directiva
            return (typeof data.access[data.name] !== 'undefined' && 
                   (data.access[data.name].access.indexOf(data.permission) !== -1 || compareLevels(data)))
        }

        function compareLevels(data) {

            //Evalua los niveles de acceso y verifica si ese nivel es superior o inferior al que se solicita
            let accessType = data.access[data.name].accessType
            let permission = data.permission.toUpperCase()
            let levels     = [ "VIEW", "WRITE", "MANAGE" ]

            return levels.indexOf(permission) === -1 ? false : levels.indexOf(accessType) >= levels.indexOf(permission);
        }
    }
};



export default VCAN
// module.exports = VCAN