import store from '~/store'

export default async (to, from, next) => {
    
    if (store.getters['auth/check']) {
        try {
            //Actualiza la lista de permisos
            await store.dispatch('permission/getPermissions')
            
            // TODO: Programar la opcion de sub-componentes para cada componente, de esta manera
            // se pueden validar si visita una sub-ruta del menu y verificar si tiene permiso

            // let access      = store.getters['permission/access']['permissions']
            // let permission  = access[to.name]
            // let middleware  = to.matched[0].components.default.middleware || 'guest'

            // //Verifica si existen permisos para acceder a esta ruta
            // if(store.getters['auth/check'] && typeof permission === 'undefined' && middleware != 'guest'){
            //     next({name: 'error-access-denied'})
            //     return
            // }
            
        } catch (e) {
        }
    }

    next()
}
