<template>
    <div>
        <nav class="d-flex d-md-none navbar fixed-bottom navbar-light bg-light">
            <div class="d-flex justify-content-between w-100">
                <router-link class="menu-bar-item" :to="{name: 'account-index'}" exact-active-class="active">
                    <span class="icon-user-2 d-inline-block w-100"></span>
                    <span class="menu-bar-label">General</span>
                </router-link>
                <router-link class="menu-bar-item" :to="{name: 'account-settings'}" exact-active-class="active">
                    <span class="icon-settings d-inline-block w-100"></span>
                    <span class="menu-bar-label">Configuración</span>
                </router-link>
                <router-link class="menu-bar-item" :to="{name: 'account-security'}" exact-active-class="active">
                    <span class="icon-key-lock d-inline-block w-100"></span>
                    <span class="menu-bar-label">Seguridad</span>
                </router-link>
            </div>
        </nav>
        <div class="row mx-0 mt-4">
            <div class="menu-container d-none d-md-block col-md-3 col-lg-2 border-right">
                <ul class="menu-list defaul-size">
                    <li class="p-0">
                        <router-link :to="{name: 'account-index'}" exact-active-class="active">General</router-link>
                    </li>
                    <li class="p-0">
                        <router-link :to="{name: 'account-settings'}" exact-active-class="active">Configuración</router-link>
                    </li>
                    <li class="p-0">
                        <router-link :to="{name: 'account-security'}" exact-active-class="active">Seguridad</router-link>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <router-view class="view-account" />
            </div>
        </div>

    </div>
</template>
<style scoped>
    .menu-container{
        min-width: 160px;
    }
    .menu-bar-item{
        text-align: center;
        text-decoration: none;
        color: #333333;
    }
    .menu-bar-item.active{
        color:#3e85f7;
    }
    .menu-bar-label{
        font-size: small;
    }
    .view-account{
        max-width: 500px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 2.5rem;
    }
</style>
<script>
    export default {
        middleware: 'auth',
        layout: 'dashboard',
        name: 'account',
    }
</script>
