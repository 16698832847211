<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Paises</h5>
                    <p class="text-muted mb-1">Lista de paises del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalCountry" >
                        <span class="icon-add-2 mr-2"></span> Nuevo Pais
                    </button>
                </div>
            </div>
            
            <country-modal :Country="Country" v-on:closeCountryModal="clearCountryVar()" v-on:reloadCountryModal="reloadCountryModal()"></country-modal>

            <data-table :ajax="{ url: '/admin/location/countries/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="countryTable" :serverSide="true" :columns="dt.columns()"></data-table>

        </div>
    </div>
</template>

<script>
    import dataTable from '~/components/dataTable'
    import CountryModal from '~/components/geodatos/CountryModal'
    
    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            CountryModal
        },
        metaInfo() {
            return {
                title: "Paises del Sistema"
            }
        }, 
        data() {
            return {
                Country:  null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'name', data: 'name', render: function ( data, type, row, meta) {
                                    return  `<img src="https://www.countryflags.io/${row.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${row.short_iso_code}">
                                            ${data}`;
                                }},
                                { title: 'native_name', data: 'native_name' },
                                { title: 'iso_code', data: 'iso_code' },
                                { title: 'short_iso_code', data: 'short_iso_code' },
                                { title: 'numeric_code', data: 'numeric_code' },
                                { title: 'denomination', data: 'demonym' },
                                { title: 'capital', data: 'capital' },
                                { title: 'phone_extension', data: 'phone_extension' },
                                { title: 'latitude', data: 'latitude' },
                                { title: 'longitude', data: 'longitude' },
                                { title: 'Actions', data: 'id', orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btnedit     =  $this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#modalCountry">Editar</button>` : `-`;
                                        let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            clearCountryVar() {
                this.Country = null;
            },
            reloadCountryModal() {
                this.$refs.countryTable.ajaxReload();
            },
            onDelete(country) {
                let vm = this
                $.confirm.delete(
                    'Eliminar pais',
                    `¿Está seguro que desea eliminar el pais <u>${country.name}</u>?`, 
                    function(){
                        vm.deleteItem(country.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/location/countries/delete', { id: idDel }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    }
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.countryTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            },
        },
        mounted(){ 
            let vm = this

            $(vm.$el).off("click.country",".btn-edit");
            $(vm.$el).on("click.country",".btn-edit",function(){
               let country = vm.$refs.countryTable.rowData($(this).closest('tr'));
                vm.Country = country;
            }); 

            $(vm.$el).off("click.country",".btn-delete");
            $(vm.$el).on("click.country",".btn-delete",function(){
                let country = vm.$refs.countryTable.rowData($(this).closest('tr'));
                vm.onDelete(country);
            });
        },
    }
</script>