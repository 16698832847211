<template>
    <div class="mx-3">
        <div class="row">
            <div class="col-12">
                <h5 class="font-weight-bold mb-0" v-if="question"> 
                    <span :class="question.category.icon"></span>
                    {{ question.category.name }}
                </h5>
                <p class="text-muted" v-if="question">{{ question.category.description }}</p>
                <div class="section w-100 mt-3">
                    <form id="supportQuestionForm" class="my-2" autocomplete="off" @submit.prevent="saveQuestion">
                        <div class="row mx-0">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label class="mb-1" for="question_title">Titulo</label>
                                    <input type="text" id="title" class="form-control" placeholder="Insertar un titulo para la pregunta" v-can:manage.disable="'contents-faqs-index'" v-model="questionForm.title">
                                </div>
                            </div>
                            <div class="col-12 col-lg-2">
                                <div class="form-group">
                                    <label class="mb-1" for="sltLng">Idioma</label>
                                    <select name="sltLng" id="sltLng" v-model="lngQst" @change="loadData" class="form-control">
                                        <option value="15">ESPAÑOL</option>
                                        <option value="7">ENGLISH</option>
                                    </select>
                                   </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label class="mb-1" for="question_title">Categoría</label>
                                    <div class="d-flex">
                                        <select id="support_category_id" class="form-control" v-can:manage.disable="'contents-faqs-index'" v-model="questionForm.support_category_id">
                                            <option value="">Seleccionar Categoria...</option>
                                            <option v-for="category in categories" :key='category.id' :value="category.id" :selected="!_.isEmpty(question) && question.category.id == category.id">
                                                {{category.name}}
                                            </option>
                                        </select>
                                        <button type='submit' class="btn ml-2" :class="{'btn-primary' : _.isEmpty(question), 'btn-success' : !_.isEmpty(question)}">{{ _.isEmpty(question) ? 'Guardar' : 'Editar'}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 support-editor">
                                <quill-editor id="content" ref="editor" v-model="questionForm.content"></quill-editor>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .support-editor .ql-container{
        height: calc(100vh - 365px)
    }
</style>

<script>

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Agregar Pregunta" }
        },
        data() {
            return {
                lngQst : 15,
                categories: [],
                question: null,
                questionForm: new WebForm("#supportQuestionForm", {
                    id: 0,
                    title: '',
                    content: '',
                    support_category_id: '',
                }),
            }
        },
        watch: {
            // question: function(newVal, oldVal) {

            //     if(_.isNull(newVal))
            //         this.questionForm.reset()
            //     else
            //         this.questionForm.fill(newVal)
            // }

            question: {
                handler: function(newVal, oldVal) {
                    if(_.isNull(newVal))
                        this.questionForm.reset()
                    else
                        this.questionForm.fill(newVal)
                },
                immediate: true
            },
        },
        methods: {
            saveQuestion: function () {
                this.questionForm.post('/admin/contents/support/question/save')
                .then(response => {
                    this.$snotify.success('Contenido almacenado exitosamente.');
                    this.$router.push({ name: 'contents-faqs-index' })
                })
                .catch(error => {
                    this.$snotify.warning('Ocurrió un problema al guardar el contenido.');
                })
            },
            async loadData(){
                let lng = this.lngQst
                this.categories = []
                let data = await axios.get('/admin/contents/support/categories/all/'+lng)
                debugger
                this.categories = data.data
            },
        },
        async asyncData(app, route, store, redirect){

            var data = {
                categories: [],
                question: null,
            }

            let { id }      = route.params
            let lng = 15
            let categoryRes = await axios.get('/admin/contents/support/categories/all/'+lng)
            data.categories = categoryRes.data

            //Si no existe el "ID" se trata de una nueva pregunta
            if(_.isNull(id) || _.isUndefined(id))
                return data

            //Si el parametro no es numerico redireccionar al inicio
            if(!_.isNumeric(id)){
                redirect({ name: 'contents-faqs-index' })
                context.$snotify.warning("Parametro no valido")
                return data
            }

            let questionRes = await axios.get('/admin/contents/support/question/'+id)
            data.question   = questionRes.data.question

            //La pregunda que busca no existe 
            if(_.isEmpty(data.question)){
                redirect({ name: 'contents-faqs-index' })
                context.$snotify.warning(`El contenido relacionado al ID "${id}" no existe`)
                return data
            }

            return data
        },
    }
</script>
