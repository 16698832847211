<template>
     <div class="notifications-wrapper">
        <div  class="notification-item py-2 px-2" 
                v-for="alert in user_requests" :key="alert.id"
                :class="{'pending': alert.request.is_approve == null}" 
            >
            <div class="notification-item-img">
                <img :src="alert.request.user.photo || '/img/none.png'" alt="">
            </div>
            <div class="notification-item-info">
                <div class="pl-2">
                    <p class="notification-item-title m-0">
                        {{ alert.request.model.replace('App\\', '')+"s" }}
                    </p>
                    <p class="mb-1">
                        <span class="notification-item-date ml-auto" data-toggle="tooltip" data-placement="bottom" :title="alert.created_at | moment('timezone', timezone, 'LLLL')">
                            <span class="icon-clock" grid="16"></span> {{ alert.created_at | moment('from', 'now') }}
                        </span>
                    </p>
                    <p class="notification-item-description">
                        El usuario <b>{{ alert.request.user.first_name }} {{ alert.request.user.last_name }}</b> esta solicitando realizar una 
                        <span class="text-primary font-weight-bold">{{ getAction(alert.request.type) }}</span> en el elemento <b>{{ alert.request.title }}</b>
                    </p>
                </div>
                
                <transition name="fade-in">
                    <div key="1" v-if="alert.request.is_approve == null" class="pl-2 d-flex mt-2">
                        <button @click="approve(alert.request.id)" class="btn btn-soft btn-with-icon btn-rounded btn-sm btn-soft-success">
                            Aprobar
                            <span class="icon icon-circle-check"></span>
                        </button>
                        <button @click="reject(alert.request.id)" class="btn btn-soft btn-with-icon btn-rounded btn-sm btn-soft-danger ml-1">
                            Cancelar
                            <span class="icon icon-cancel"></span>
                        </button>
                        <button @click.stop="openModal(alert.request)" class="btn btn-soft btn-with-icon btn-rounded btn-sm center-content ml-1">
                            <span class="icon-file-edit mr-1"></span>
                            Detallles
                        </button>
                    </div>
                    <div key="2" v-else class="pl-2">
                        <span class="tag-rounded">
                            <div class="d-flex align-items-center ">
                                <img :src="alert.request.manage_by.photo ||  '/img/none.png'" alt="" >
                                <span class="ml-1">
                                    {{ alert.request.manage_by.first_name }} 
                                    {{ alert.request.manage_by.last_name }}
                                </span>
                                <span v-if="alert.request.is_approve" class="icon text-success ico-circle-check"></span>
                                <span v-else class="icon ico-circle-close text-danger"></span>
                            </div>
                        </span>
                    </div>
                </transition>

            </div>
            <!-- <div class="align-items-center d-flex ">
                <button class="btn btn-link btn-sm mb-2" @click.stop="openModal(alert.request)">Detalles</button>
            </div> -->
        </div>
        
    </div>
</template>
<script>
export default {
    props: ['user_requests'],
    data () {
        return {
            compare_form: {
                open: false,
                request: null,
            }
        }
    },
    methods: {
        approve (id) {
            let vm = this
            let endpoint = '/admin/requests/approve'
            axios.post(endpoint, {
                id: id,
            }).then( ({data}) => {
            })
        },
        reject  (id) {
            let vm = this
            let endpoint = '/admin/requests/reject'
            axios.post(endpoint, {
                id: id,
            }).then( ({data}) => {
            })
        },
       openModal (r) {
            this.$emit('openRequestCompare', r)
       },
       getAction(action){
           switch (action) {
               case 'UPDATE':
                   return 'actualización';
               case 'DELETE':
                   return 'elimiación';
               case 'CREATE':
                   return 'creación';
               default:
                   return action;
           }
       }
    },
    created () {
        this.interval = setInterval(() => this.$forceUpdate(), 1000);
    },
    beforeDestroy() {
		clearInterval(this.interval);
    },
    updated () {
        let vm = this
        vm.user_requests.forEach(r => {
            if(typeof(r.request.data) != 'object') {
                let data_action = JSON.parse(r.request.data)
                r.request.data = data_action
            } 
        });
    }
}
</script>
