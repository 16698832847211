<template>
    <div class="row section mx-2 my-1 p-2" v-if="quote">
        <div class="col-md-5">
            <div class="row">
                <div class="col-12">
                    <label class="font-weight-bold mb-2">
                        Datos del Cliente 
                    </label>
                        <span :disabled="!owner" data-toggle="modal" data-target="#modalQuoteState"
                        :class="'float-right pointer ' + quote.state_quote.toLowerCase()">
                            {{quote.state_quote}}
                        </span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <b>Cliente:</b> {{quote.customer_name}}
                    <br>
                    <b>Lenguaje:</b> {{quote.language.name}} <img :src="`https://www.countryflags.io/${(quote.language.short_iso_code.toLowerCase() == 'es') ? 'es' : 'us'}/flat/24.png`" class="mr-2" width="24" height="24" alt="">
                    <br>
                    <b>Correo:</b> {{quote.customer_email}}
                    <br>
                    <b>Telefono:</b> {{ (quote.customer_phone_number) ? `(${quote.customer_country_code}) + ${quote.customer_country_calling_code} ${quote.customer_phone_number}` : '' }}
                    <br>
                    <b>Fecha en que Cotización:</b> {{quote.created_at | moment('LLL')}}
                    <br>
                    <b>Agente encargado:</b> {{ (quote.user) ? quote.user.first_name + ' ' + quote.user.last_name : 'Nunguno...' }}
                    <br>
                    <b>Codigo de Cotizacion:</b> {{ quote.code_quote }}
                    <br>
                    <b>Comentarios:</b>
                    <br>
                    <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quote.customer_comments}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-7 border-left">
            <div class="col-md-12 h-100 p-0">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Observaciones del Agente
                        </label>
                        <button class="btn btn-primary float-right btn-sm" :disabled="!owner" data-toggle="modal"
                         data-target="#modalQuoteObservation">Nueva Observacion</button>
                    </div>
                </div>
                <div v-if="(JSON.parse(quote.observations))" class="col-md-12 h-100 p-0" style=" height: 260px !important; overflow-y:scroll;">
                    <div v-for="item in (JSON.parse(quote.observations)).reverse()" :key="item.id" class="modal-header p-2 bg-light">
                        <h6 class="mb-0 pb-0 w-100">
                            <span style="font-size: 12px;">{{ item.description }}</span>
                            <p class="mb-0 text-right">
                                <small class="font-weight-bold" style="font-size: 10px;">{{ $moment(item.date).format('LLL') }}</small>
                            </p>
                        </h6>
                    </div>
                </div>
                <div v-else class="col-md-12 h-100 p-0" style=" height: 260px !important; overflow-y:scroll;">
                    <h6 class="text-center mb-0 pt-5 pb-0 w-100">
                        <span style="font-size: 12px;" class="text-muted"> -- No hay observaciones -- </span>
                    </h6>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>

export default {
    props: {
        quote: {
            type: [Object],
            default () {
                return null
            }
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    data() {
        return {
            
        };
    },
    mounted() {
        console.log('QuoteResumen mounted.');
    }
};
</script>