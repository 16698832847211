<template>
    <div>
        <div class="modal fade" id="modalHotelRoom">
            <div class="modal-dialog modal-lg" :class="{'modal-xl': !(Hotel && Hotel.hotel_room_i18n)}">
                <div class="modal-content">
                    <form id="formHotelRoom" autocomplete="off" @submit.prevent="onSaveHotelRoom()" novalidate>
                        <div class="modal-header flex-wrap">
                            <div class="row w-100 ml-1">
                                <div class="col-md-8">
                                    <h5 class="ml-0 font-weight-bold" v-if="Hotel">
                                        <span v-if="Hotel && Hotel.hotel_room_i18n">
                                            Traducir Habitacion 
                                        </span>
                                        <span v-else-if="formHotelRoom.id">
                                            Editar Habitacion 
                                        </span>
                                        <span v-else>
                                            Nueva Habitacion 
                                        </span>
                                         - {{Hotel.name}}
                                    </h5>
                                </div>
                                <div v-show="!(Hotel && Hotel.hotel_room_i18n)" v-if="formHotelRoom.id" class="col-md-4 text-right">
                                    <b>Editado el:</b> {{formHotelRoom.updated_at | moment('LL')}}
                                </div>
                            </div>
                            <button type="button" class="close position-absolute" style="right: 10px; top: 9px;" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row border-bottom mb-3" v-show="!(Hotel && Hotel.hotel_room_i18n)">
                                <div class="form-group col-md-3">
                                    <label for="list_beds" style="width: 100%">Seleccione Cama</label>
                                    <v-select :options="list_beds" label="title" placeholder="Seleccione..."
                                    @keypress.enter.native.prevent="" ref="vSelectBeds"
                                    v-model="bed">
                                        <template  slot="option" slot-scope="option">
                                            <span class="mr-2" :class="option.icon"></span>
                                            {{ option.title }} <b class="float-right">{{option.capacity}} <span class="ml-1 icon-user-2"></span></b>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <span class="mr-2" :class="option.icon"></span>
                                            {{ option.title }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="quantity" style="width: 100%">Cantidad</label>
                                    <input type="number" name="quantity" v-model.number="quantity" min="0" max="6" class="form-control">
                                </div>
                                <div class="form-group col-2 text-center">
                                    <label for="beds" style="width: 100%">Añadir</label>
                                    <button class="btn btn-ms btn-secondary" @click.prevent="addBed()"> >> </button>
                                </div>
                                <div class="form-group col-5">
                                    <label for="beds" class="required" style="width: 100%">Camas Seleccionadas</label>
                                    <div class="form-control" style="height: auto; min-height: 115px;">
                                        <p v-for="(item, index) in formHotelRoom.bedsObj" :key="index" class="mb-2 border-bottom">
                                            <span class="mr-2" :class="item.icon"></span> 
                                            <b>{{item.n}}</b> -  {{item.title}} ( <b>{{item.capacity * item.n}} <span class="icon-user-2"></span></b> )
                                            <span class="icon-remove" title="Remover" style="margin-top: 2px; float:right; cursor:pointer;"
                                            @click.prevent="removeBed(index)"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-show="!(Hotel && Hotel.hotel_room_i18n)">
                                <div class="form-group col-12 col-md-3">
                                    <label for="capacity">Capacidad Actual</label>
                                    <div class="text-center"><b>{{totalCapacity}} {{ (totalCapacity===1) ? ' Persona' : 'Personas' }}</b></div>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="type_room_id" class="required" style="width: 100%">Tipo Habitacion
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#modalTypeRoom">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select name="type_room_id"
                                    v-model="formHotelRoom.type_room" :options="type_rooms"
                                    ref="vSelectTypeRoom" placeholder="Tipo de Habitacion"
                                    @keypress.enter.native.prevent="" selectOnTab>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="hotel_room__smok" style="margin-top: 25px;">
                                        ¿Se permite fumar?
                                        <input type="checkbox" v-model="formHotelRoom.smok" id="hotel_room__smok" name="smok" class="pt-3">
                                    </label> 
                                </div>
                                <div class="form-group col-6">
                                    <label for="furnitures" style="width: 100%">Muebles y Equipo
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addFurnishing">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select name="furnishings" style="heigth: auto;"
                                    v-model="formHotelRoom.furnishings" :options="furnishings"
                                    ref="vSelectFurnishings" placeholder="Ingrese Muebles y equipos"
                                    multiple @keypress.enter.native.prevent="setFocusFurnishings" push-tags>
                                    </v-select>
                                </div>
                                <div class="form-group col-6">
                                    <label for="room_services" style="width: 100%">Servicios de Habitacion
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addRoomService">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <tag-input name="room_services" v-model="formHotelRoom.room_services" ref="taginput" placeholder="Servicios..." :show-focus="true" :data="room_services"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group" :class="(Hotel && Hotel.hotel_room_i18n) ? 'col-12' : 'col-8'">
                                    <label for="description">Descripción de la Habitacion</label>
                                    <textarea type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formHotelRoom.description" name="description" required="required" ></textarea>
                                </div>
                                <div class="form-group" :class="(Hotel && Hotel.hotel_room_i18n) ? 'col-12' : 'col-4'">
                                    <label for="views">Cuenta con vistas a</label>
                                    <v-select name="views" taggable 
                                    v-model="formHotelRoom.viewsObj"
                                    ref="vSelectViews" placeholder="Ingrese Vistas" 
                                    multiple @keypress.enter.native.prevent="" push-tags
                                    @option:created="setFocusViews" >
                                    </v-select>
                                </div>
                            </div>
                            <div class="row" v-show="!(Hotel && Hotel.hotel_room_i18n)">
                                <div class="form-group col-12">
                                    <label for="load">Imagenes Actuales</label>
                                    <image-display :images="formHotelRoom.hotel_room_gallery" :clearAll="clearDisplayImage" :urlDelete="'/admin/hotels/hotel_rooms/deleteimage'"></image-display>
                                    <span class="badge" v-if="formHotelRoom.hotel_room_gallery.length===0">Ninguna...</span>
                                </div>
                                <div class="form-group col-12">
                                    <label for="load">Cargar Imagenes (Maximo {{nMaxImages}})</label>
                                    <file-image v-on:setGalery="getGalery" :nImgs="imagesRemaining" :clearAll="clearFileImage"></file-image>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button v-show="!(Hotel && Hotel.hotel_room_i18n)" type="button" v-if="formHotelRoom.id && $can('delete','packages-hotels-index')"
                            class="btn btn-danger" @click.prevent="onDelete()">
                                Borrar
                            </button>
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-hotels-index'" > 
                                <span v-if="formHotelRoom.id && $can('update','packages-hotels-index')">
                                    Editar
                                </span>
                                <span v-else-if="formHotelRoom.id && !$can('update','packages-hotels-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <type-room-modal v-on:reloadTypeRoomModal="reloadTypeRoom"></type-room-modal>

        <furnishing-modal v-on:reloadFurnishingModal="reloadFurnishing"></furnishing-modal>

        <room-service-modal v-on:reloadRoomServiceModal="reloadRoomService"></room-service-modal>

    </div>
</template>
<script>
    import tagInput from '~/components/tagInput';
    import TypeRoomModal from '~/components/packages/TypeRoomModal';
    import RoomServiceModal from '~/components/packages/RoomServiceModal';
    import FurnishingModal from '~/components/packages/FurnishingModal';
    import FileImage from '~/components/packages/FileImage';
    import ImageDisplay from '~/components/gallery/imageDisplay';

    export default {
        props: [
            "Hotel"
            ],
        components: {
            tagInput,
            TypeRoomModal,
            RoomServiceModal,
            FurnishingModal,
            FileImage,
            ImageDisplay
        },
        data() {
            return {
                nMaxImages                  : 5,
                clearFileImage              : false,
                clearDisplayImage           : false,
                bed                         : null,
                quantity                    : 1,
                formHotelRoom: new WebForm('#formHotelRoom', {
                    type_room               : null,
                    type_room_id            : null,
                    hotel_id                : null,
                    capacity                : 0,
                    smok                    : false,
                    beds                    : [],
                    bedsObj                 : [],
                    furnishings             : [],
                    room_services           : [],
                    views                   : [],
                    viewsObj                : [],
                    description             : null,
                    hotel_room_gallery      : [],
                    newGallery              : [],
                    id                      : null,
                    updated_at              : null,
                    btn_i18n                : false,
                    id_i18n                 : null,
                }),
                room_services               : [],
                furnishings                 : [],
                type_rooms                  : [],
                list_beds: [ // Lista de camas disponibles
                    {
                        id: 1,
                        name: 'single',
                        title: 'Individual',
                        icon: 'icon-bed-single',
                        capacity: 1,
                        visible: true,
                        n: 0,
                        image: 'individual'
                    },
                    {
                        id: 2,
                        name: 'bunk',
                        title: 'Litera',
                        icon: 'icon-bed-bunk',
                        capacity: 2,
                        visible: true,
                        n: 0,
                        image: 'bunk'
                    },
                    {
                        id: 3,
                        name: 'double',
                        title: 'Doble',
                        icon: 'icon-bed-double-1',
                        capacity: 2,
                        visible: true,
                        n: 0,
                        image: 'double'
                    },
                    {
                        id: 4,
                        name: 'queen',
                        title: 'Queen Size',
                        icon: 'icon-bed-queen',
                        capacity: 2,
                        visible: true,
                        n: 0,
                        image: 'queen'
                    },
                    {
                        id: 5,
                        name: 'king',
                        title: 'king Size',
                        icon: 'icon-bed-king',
                        capacity: 2,
                        visible: true,
                        n: 0,
                        image: 'king'
                    }
                ]
            }
        },
        watch: {
            Hotel: function(val, oldVal) {
                let vm = this; // wait
                if (_.isEmpty(vm.Hotel) || vm.Hotel.id == '') {
                    vm.formHotelRoom.reset();
                }else {
                    vm.formHotelRoom.hotel_id = vm.Hotel.id;
                    if (vm.Hotel.hotel_room_idx || vm.Hotel.hotel_room_edit) {
                        let hotel_room = null;
                        if(vm.Hotel.hotel_room_edit) {
                            hotel_room = vm.Hotel.hotel_room;
                        } else {
                            hotel_room = vm.Hotel.hotel_room[vm.Hotel.hotel_room_idx];
                        }

                        vm.formHotelRoom.id             = hotel_room.id;
                        vm.formHotelRoom.type_room_id   = hotel_room.type_room_id;
                        vm.formHotelRoom.smok           = hotel_room.smok;
                        vm.formHotelRoom.description    = hotel_room.description;
                        vm.formHotelRoom.updated_at     = hotel_room.updated_at;

                        vm.formHotelRoom.hotel_room_gallery = hotel_room.hotel_room_gallery;
                        vm.loadFurnishings(hotel_room.furnishings);
                        vm.loadRoomServices(hotel_room.hotel_services);
                        vm.formHotelRoom.viewsObj          = (hotel_room.views !=null ) ? JSON.parse(vm.decodeHTML(hotel_room.views)) : '';
                        vm.formHotelRoom.bedsObj        = (hotel_room.beds !=null ) ? JSON.parse(vm.decodeHTML(hotel_room.beds)) : '';

                        if (vm.Hotel && vm.Hotel.hotel_room_i18n) {
                            vm.formHotelRoom.btn_i18n    = true;
                        } else {
                            vm.formHotelRoom.btn_i18n    = false;
                        }

                        if (hotel_room && vm.Hotel.hotel_room_i18n && hotel_room.i18n.length>0) {
                            vm.formHotelRoom.description    = (hotel_room.i18n[0].description) ? hotel_room.i18n[0].description : vm.formHotelRoom.description;
                            vm.formHotelRoom.viewsObj       = (hotel_room.i18n[0].views) ? JSON.parse(vm.decodeHTML(hotel_room.i18n[0].views)) : vm.formHotelRoom.viewsObj;
                            vm.formHotelRoom.id_i18n   	    = hotel_room.i18n[0].id;
                        }

                        vm.formHotelRoom.type_room = vm.type_rooms.find(c => {
                            return c.id == hotel_room.type_room.id;
                        });
                    }
                }
            }
        },
        computed: {
            totalCapacity() { // ok
                let total = 0;
                if (this.formHotelRoom.bedsObj.length>0) {
                    this.formHotelRoom.bedsObj.map(b => {
                        return total += (b.capacity * b.n);
                    });
                    this.formHotelRoom.capacity = total;
                }
                return total;
            },
            imagesRemaining() {
                let vm = this;
                if (vm.formHotelRoom.hotel_room_gallery && vm.formHotelRoom.hotel_room_gallery.length>0) {
                    let cant = parseInt(vm.formHotelRoom.hotel_room_gallery.length);
                    return vm.nMaxImages - cant;
                } else {
                    return vm.nMaxImages;
                }
            }
        },
        methods: {
            getGalery(obj) {
                this.formHotelRoom.newGallery = obj;
            },
            setFocusViews() { // ok
                setTimeout (() => {
                    $(this.$refs.vSelectViews.$el).find('.vs__search').focus();
                }, 200);
            },
            setFocusFurnishing() { // nooo
                setTimeout (() => {
                    $(this.$refs.vSelectFurnishings.$el).find('.vs__search').focus();
                }, 200);
            },
            addBed() { // ok
                var bed = this.bed;
                var quantity = this.quantity;
                if (bed!=null && quantity>0) {
                    bed.n = quantity;
                    this.formHotelRoom.bedsObj.push(bed);
                    this.quantity = 1;
                }
            },
            removeBed(index) { // ok
                this.formHotelRoom.bedsObj.splice(index, 1);
            },
            reloadRoomService(obj) { // ok
                let service_ = {
                    id: obj.id,
                    icon: `${obj.icon}`,
                    name: `${obj.title}`,
                    label: `${obj.title}`
                };

                this.room_services.push(service_);
                this.formHotelRoom.room_services.push(service_);
            },
            reloadFurnishing(obj) { // ok
                let furnishing_ = {
                    id: obj.id,
                    name: `${obj.name}`,
                    label: `${obj.name}`
                };
                this.furnishings.push(furnishing_);
                this.formHotelRoom.furnishings.push(furnishing_);
            },
            reloadTypeRoom(obj) { // ok
                let typeroom_ = {
                    id: obj.id,
                    name: `${obj.title}`,
                    label: `${obj.title}`
                }
                this.type_rooms.push(typeroom_);
                this.formHotelRoom.type_room = typeroom_;
            },
            getRoomServices(){ // ok
                let endpoint = '/admin/hotels/services/all';
                axios.get(endpoint).then(({data}) => {
                    let services = data.map(l => {
                        let service = l
                        let d = {
                            id: service.id,
                            icon: `${service.icon}`,
                            name: `${service.title}`,
                            label: `${service.title}`
                        }
                        return d
                    });
                    this.room_services = services;

                    this.room_services.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0; 
                        return a1> b1? 1: -1;    
                    });
                });
            },
            loadFurnishings(furnishingsToLoad) { // ok
                let vm = this;
                let arrayNew =[];
                furnishingsToLoad.forEach(itemToLoad => {
                    vm.furnishings.forEach(item => {
                        if(item.id == itemToLoad.id) {
                            arrayNew.push(item);
                        }
                    });
                });
                vm.formHotelRoom.furnishings = arrayNew;
            },
            loadRoomServices(roomServicesToLoad) { // ok
                let vm = this;
                let arrayNew =[];
                roomServicesToLoad.forEach(itemToLoad => {
                    vm.room_services.forEach(item => {
                        if(item.id == itemToLoad.id) {
                            arrayNew.push(item);
                        }
                    });
                });
                vm.formHotelRoom.room_services = arrayNew;
            },
            onSaveHotelRoom() { // ok
                let vm = this

                let tipo = (vm.formHotelRoom.id == null) ? 1 : 2;
                if(this.formHotelRoom.id != null && this.formHotelRoom.btn_i18n==false) {
                    $.confirm({
                        title: `Editar Cuarto de Hotel`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (type) {
                let vm = this;

                if(vm.formHotelRoom.type_room && vm.formHotelRoom.type_room.id) {
                    vm.formHotelRoom.type_room_id = vm.formHotelRoom.type_room.id;
                }

                vm.formHotelRoom.beds = JSON.stringify(this.formHotelRoom.bedsObj);
                vm.formHotelRoom.views = JSON.stringify(this.formHotelRoom.viewsObj);

                vm.formHotelRoom.post('/admin/hotels/hotel_rooms/save').then(response => {
                    let data = response.data;

                    if (data.user_request_status) {
                        $('#modalHotelRoom').modal('hide');
                        return;
                    }

                    vm.clearImageContainer();
                    let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);
                    if(type==1) {
                        vm.$emit('createdHotelRoom', data);
                    } else {
                        vm.$emit('reloadHotelRoom', data);
                    }
                    $('#modalHotelRoom').modal('hide');
                    vm.formHotelRoom.clear();
                    vm.formHotelRoom.reset();
                    return;
                });
            },
            getTypeRooms(){ 
                let endpoint = '/admin/hotels/typerooms/all';
                axios.get(endpoint).then(({data}) => {
                    let typerooms = data.map(l => {
                        let typeroom = l
                        let d = {
                            id: typeroom.id,
                            name: `${typeroom.title}`,
                            label: `${typeroom.title}`
                        }
                        return d
                    });
                    this.type_rooms = typerooms;

                    this.type_rooms.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            getFurnishings(){ // ok
                let endpoint = '/admin/hotels/furnishings/all';
                axios.get(endpoint).then(({data}) => {
                    let furnishings = data.map(l => {
                        let furnishing = l
                        let d = {
                            id: furnishing.id,
                            name: `${furnishing.name}`,
                            label: `${furnishing.name}`
                        }
                        return d
                    });
                    this.furnishings = furnishings;

                    this.furnishings.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            clearImageContainer() {
                setTimeout (() => {
                    this.clearFileImage = false;
                    this.clearDisplayImage = false;
                }, 350);
                this.clearFileImage = true;
                this.clearDisplayImage = true;
            },
            onDelete() {
                let vm = this;
                if(this.formHotelRoom.id != null) {
                    $.confirm.delete(
                        `Borrar Habitacion de Hotel ${vm.Hotel.name}`,
                        `¿Seguro que quiere <b>borrar</b> esta habitación?`, 
                        function(){
                            vm.deleteHotelRoom();
                        }
                    )
                }
            },
            deleteHotelRoom() {
                let vm = this;
                let idDel = vm.formHotelRoom.id;
                axios.post('/admin/hotels/hotel_rooms/delete', { id: idDel }).then(response => {
                    let data = response.data;

                    if(!data.success) {
                        vm.$snotify.warning('Ups. ocurrio un error');
                        return
                    } 

                    if(data.success) {
                        vm.$emit('reloadHotelRoom');
                        vm.$snotify.success('Habitación borrada con exito.');
                        $('#modalHotelRoom').modal('hide');
                        vm.formHotelRoom.clear();
                        vm.formHotelRoom.reset();
                        return
                    } 

                    vm.$snotify.info('Se ha enviado solicitud');

                }).catch(err => console.log(err));
            },
        },
        mounted() {
           let vm = this

            vm.getRoomServices();
            vm.getFurnishings();
            vm.getTypeRooms();

            $('#modalHotelRoom').off('hidden.bs.modal');
            $('#modalHotelRoom').on('hidden.bs.modal', function () {
                vm.clearImageContainer();
                vm.$refs.taginput.clear();
                vm.formHotelRoom.clear();
                vm.formHotelRoom.reset();
                vm.formHotelRoom.bedsObj = [];
                vm.formHotelRoom.beds = [];
                vm.formHotelRoom.newGallery = [];
                vm.$emit('closeHotelRoomModal');
            });

        }
    }
</script>
<style>
    .pac-container.hdpi{
        z-index: 10000;
    }
    .modal{
        overflow-y: auto !important;
    }
</style>