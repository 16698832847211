<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Regiones</h5>
                    <p class="text-muted mb-1">Lista de Regiones del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalRegion" >
                        <span class="icon-add-2 mr-2"></span> Nueva Region
                    </button>
                </div>
            </div>
            
            <region-modal :Region="Region" v-on:closeRegionModal="clearRegionVar()" v-on:reloadRegionModal="reloadRegionModal()"></region-modal>

            <data-table :ajax="{ url: '/admin/location/regions/all/dt'}" :scrollY="'calc(100vh - 350px)'" ref="regionTable" :serverSide="true" :columns="dt.columns()"></data-table>

        </div>
    </div>
</template>

<script>
    'use strict'
    import dataTable from '~/components/dataTable'
    import RegionModal from '~/components/geodatos/RegionModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            RegionModal,
        },
        metaInfo() {
            return {
                title: "Regiones del Sistema"
            }
        }, 
        data() {
            return {
                Region  :   null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'name', data: 'name' },
                                { title: 'Actions', data: 'id', orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btnedit     =  $this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#modalRegion">Editar</button>` : `-`;
                                        let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            clearRegionVar() {
                this.Region = null;
            },
            reloadRegionModal() {
                this.$refs.regionTable.ajaxReload();
            },
            onDelete(item) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Region',
                    `¿Está seguro que desea eliminar la region <u>${item.name}</u>?`, 
                    function(){
                        vm.deleteItem(item.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/location/regions/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return;
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.regionTable.ajaxReload();
                        return;
                    }
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
        },
        mounted(){
            let vm = this;

            $(vm.$el).off("click.regions",".btn-edit");
            $(vm.$el).on("click.regions",".btn-edit",function(){
                let region = vm.$refs.regionTable.rowData($(this).closest('tr'));
                vm.Region = region;
            });

            $(vm.$el).off("click.regions",".btn-delete");
            $(vm.$el).on("click.regions",".btn-delete",function(){
                let region = vm.$refs.regionTable.rowData($(this).closest('tr'));
                vm.onDelete(region);
            });
        }
    }
</script>
