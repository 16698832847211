<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Lenguajes</h5>
                    <p class="text-muted mb-1">Lista de Lenguajes del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalLanguage" >
                        <span class="icon-add-2 mr-2"></span> Nueva Lenguaje
                    </button>
                </div>
            </div>
            
            <language-modal :Language="Language" v-on:closeLanguageModal="clearLanguageVar()" v-on:reloadLanguageModal="reloadLanguage()"></language-modal>

            <data-table :ajax="{ url: '/admin/location/languages/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="languageTable" :serverSide="false" :columns="dt.columns"></data-table>
            
        </div>
    </div>
</template> 
<script>
    import dataTable from '~/components/dataTable'
    import LanguageModal from '~/components/geodatos/LanguageModal'

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            LanguageModal
        },
        metaInfo() {
            return {
                title: "Zonas Horarias del Sistema"
            }
        },
        data() {
            return {
                Language: null,
                dt: {
                    columns : [
                        { title: 'Id', data: 'id', searchable: true },
                        { title: 'nombre', data: 'name', searchable: true },
                        { title: 'nombre nativo',   data: 'native_name', searchable: true },
                        { title: 'ISO',   data: 'iso_code', searchable: true },
                        { title: 'Iso Min',   data: 'short_iso_code', searchable: true },
                        { title: 'Idioma por defecto',   data: 'system_language', searchable: true,
                            render: (data, type, row, meta) => {
                                 return (data == true) ? 'SI' : 'NO';
                            }
                        },
                        { title: 'Actions',       data: 'id', orderable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#modalLanguage">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            }
        },
        methods: {
            reloadLanguage() {
                this.$refs.languageTable.ajaxReload();
            },
            clearLanguageVar() {
                this.Language = null;
            },
            onDelete(language) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Lenguaje',
                    `¿Está seguro que desea eliminar el lenguaje <u>${language.name}</u>?`, 
                    function(){
                        vm.deleteItem(language.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/location/languages/delete', { id: idDel }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 

                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.languageTable.ajaxReload();
                        return;
                    }

                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            }
        },
        mounted(){ 
            let vm = this

            $(vm.$el).off("click.language", ".btn-delete");
            $(vm.$el).on("click.language", ".btn-delete",function(){
                let language = vm.$refs.languageTable.rowData($(this).closest('tr'));
                vm.onDelete(language);
            });

            $(vm.$el).off("click.language", ".btn-edit");
            $(vm.$el).on("click.language", ".btn-edit", function(e){
                let language = vm.$refs.languageTable.rowData($(this).closest('tr'))
                vm.Language = language;
            });
        },
    }
</script>