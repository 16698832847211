<template>
	<div class="modal fade" id="addAttractionCategory">
		<div class="modal-dialog">
			<div class="modal-content">
				<form id="formAttractionCategory" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span v-if="AttractionCategory && AttractionCategory.btn_i18n==true">Traducción Categoria de Atracción Turística</span>
							<span v-else-if="formAttractionCategory.id">Editar Categoria de Atracción Turística</span>
							<span v-else>Nueva Categoria de Atracción Turística</span>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="name">Nombre Categoria</label>
							<input
								type="text"
								class="form-control"
								placeholder="Ingrese Nombre"
								v-model="formAttractionCategory.name"
								name="name"
							/>
						</div>
						<div class="form-group">
							<label for="description">Descripción de la Categoria</label>
							<textarea
								type="text"
								class="form-control"
								rows="4"
								placeholder="Ingrese Descripción"
								v-model="formAttractionCategory.description"
								name="description"
							></textarea>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" v-can:create="'packages-attractions-index'">
							<span v-if="AttractionCategory && AttractionCategory.btn_i18n==true">Guardar Traducción</span>
							<span v-else-if="formAttractionCategory.id && $can('update','packages-attractions-index')">Editar</span>
							<span v-else-if="formAttractionCategory.id && !$can('update','packages-attractions-index')">Solicitar Edicion</span>
							<span v-else>Guardar</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["AttractionCategory"],
	data() {
		return {
			formAttractionCategory: new WebForm("#formAttractionCategory", {
				name: "",
				description: "",
				id: null,
				btn_i18n: false,
				id_i18n: null,
			})
		};
	},
	watch: {
		AttractionCategory: function(val, oldVal) {
			if (
				_.isEmpty(this.AttractionCategory) ||
				this.AttractionCategory.id == ""
			) {
				// console.log('Vacio Att: ', this.AttractionCategory);
			} else {
				this.formAttractionCategory.fill(this.AttractionCategory);
				if(this.AttractionCategory.btn_i18n && this.AttractionCategory.i18n.length>0) {
					this.formAttractionCategory.name 		= this.AttractionCategory.i18n[0].name;
					this.formAttractionCategory.id_i18n 		= this.AttractionCategory.i18n[0].id;
					this.formAttractionCategory.description 	= this.AttractionCategory.i18n[0].description;
				}
			}
		}
	},
	methods: {
		onSaveItem() {
			let vm = this;

			// tipo : 1 "Nuevo" , Sino, Editar
			let tipo = vm.formAttractionCategory.id == null ? 1 : 2;
			if (this.formAttractionCategory.id != null && this.formAttractionCategory.btn_i18n==false) {
				$.confirm({
					title: `Editar Categoria`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function() {
								vm.updateOrCreate(
									"/admin/attractions/categories/save",
									tipo
								);
							}
						},
						cancelar: function() {}
					}
				});
			} else {
				vm.updateOrCreate("/admin/attractions/categories/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formAttractionCategory.post(url).then(response => {
				let data = response.data;

				this.formAttractionCategory.id = null;

				if (data.user_request_status) {
					$("#addAttractionCategory").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento Actualizado con exito.";
				vm.$snotify.success(snotify_message);
				let obj = data.success;
				vm.$emit("reloadAttractionCategoryModal", obj);

				$("#addAttractionCategory").modal("hide");
				return;
			});
		}
	},
	mounted() {
		let vm = this;
		$("#addAttractionCategory").off("hidden.bs.modal");
		$("#addAttractionCategory").on("hidden.bs.modal", function() {
			vm.formAttractionCategory.clear();
			vm.formAttractionCategory.reset();
			vm.$emit("closeAttractionCategoryModal");
		});
	}
};
</script>
<style scoped>
</style>