<template>
    <div class="modal fade" id="newRole" tabindex="-1" role="dialog" aria-labelledby="authModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="authModalLabel">
                        <span class="icon-user-shield mr-2" grid="24"></span>
                        <span v-if="form.id">
                            Editar Rol
                        </span>
                        <span v-else>
                            Nuevo Rol
                        </span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form id="roleForm" autocomplete="off" class="needs-validation" novalidate @submit.prevent="saveRole">
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="col-12" :class="{ 'col-md-8': level_edit, 'col-md-12': !level_edit}">
                                <div class="form-group">
                                    <label for="title">Titulo</label>
                                    <input type="text" name="title" class="form-control" v-model="form.title">
                                </div>
                            </div>
                            <div v-if="level_edit" class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="authority">Nivel</label>
                                    <select class="form-control" id="authority" name="authority" :value="highRole.authority" v-model="form.authority">
                                        <option v-for="level in rangeLevel" :key="level" :value="level">{{level}}</option>
                                    </select>
                                </div>
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="description">Descripción</label>
                                    <textarea name="description" class="form-control" cols="10" rows="5" v-model="form.description"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">
                            <span v-if="form.id">
                                Editar Rol
                            </span>
                            <span v-else>
                                Crear Rol
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { mapGetters } from 'vuex'
    
    export default {
        props: ['roles'],
        data () {
            return {
                level_edit: true,
                form : new WebForm("#roleForm", {
                    id : null,
                    title : '',
                    authority: '',
                    description : '',
                })
            }
        },
        computed: {
            highRole () {
                let h  = null
                Object.keys(this.ownerRoles.roles).forEach(key => {
                    if(!h) {
                        h = this.ownerRoles.roles[key]
                        return
                    }

                    if(this.ownerRoles.roles[key].authority > h.authority) {
                        h = this.ownerRoles.roles[key]
                        return
                    }
                    
                })
                return h
            },
            rangeLevel() {
                let vm = this
                let range = []
                for(let i = vm.highRole.authority; i >= 0; i-=5) {
                    range.push(i)
                }
                return range
            },
            ...mapGetters({
                ownerRoles : 'permission/access', 
            })
        },
        methods: {
            onEditRole (role_id, level_edit) {
                let role = this.roles.find(r => {
                    return r.id == role_id
                })

                if(!role)
                    return

                this.level_edit = level_edit
                this.form.fill(role)
            },
            saveRole(){
                this.form.post('/admin/permission/role/new')
                .then(response => {
                    this.$parent.$emit('update-data')
                    $('#newRole').modal('hide')
                })
            }
        },
        mounted () {
            let vm = this
            vm.form.authority = vm.highRole.authority

            $("#newRole").off("hidden.bs.modal")
            $("#newRole").on("hidden.bs.modal", function(){
                vm.form.reset()
                vm.level_edit = true
                vm.form.authority = vm.highRole.authority
            });
        }
    }
</script>

<style scoped>
</style>