<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }

</style>
<template>
    <div>
        <h6 class="font-weight-bold m-0">Contraseña</h6>
        <p class="text-muted">Actualizar contraseña de la cuenta</p>
        <form id="generalForm" class="mt-4" @submit.prevent="save">
            <div class="form-group">
                <label class="m-0 font-weight-bold" for="password">Contraseña Actual</label>
                <div class="position-relative">
                    <input :type="!passwordShowC ? 'password' : 'text'"  id="password_current" class="form-control bg-white" v-model="form.password_current">
                    <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="passwordShowC = !passwordShowC">
                        <span class="icon-view" :class="!passwordShowC ? 'icon-view' : 'icon-view-off'"></span>
                    </button>
                </div>
            </div>
            <hr>
            <div class="form-group">
                <label class="m-0 font-weight-bold" for="password">Nueva Contraseña</label>
                <div class="position-relative">
                    <input :type="!passwordShow ? 'password' : 'text'"  id="password" class="form-control bg-white" v-model="form.password">
                    <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="passwordShow = !passwordShow">
                        <span class="icon-view" :class="!passwordShow ? 'icon-view' : 'icon-view-off'"></span>
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label class="m-0 font-weight-bold" for="passwordConfirm">Confirmar Contraseña</label>
                <input :type="!passwordShow ? 'password' : 'text'" id="password_confirmation" class="form-control"  v-model="form.password_confirmation">
            </div>
            <div class="mt-3">
                <v-button class="center-content" :type="'primary'" :loading="form.processing">
                    Guardar
                </v-button>
            </div>
        </form>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import vButton from '~/components/button'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    // data() {
    //     let vm = this
    //     return {
    //         form: new WebForm("#generalForm", {
    //             first_name: vm.user.first_name,
    //             last_name : vm.user.last_name,
    //         }),
    //     }
    // },
    data() {
        return {
            passwordShow: false,
            passwordShowC: false,
            form: new WebForm("#generalForm", {
                password_current: '',
                password: '',
                password_confirmation: '',
            }),
        }
    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
            displayName : 'auth/displayName',
            roles: 'permission/roles'
        })
    },

    components: {
        vButton
    },
    methods: {
        save() {
            let endpoint = '/admin/user/update/part'
            this.form.post(endpoint).then(response => {
                if(response.data.id) {
                    this.$snotify.success('Guardado')
                }
            })
        }
    }
}
</script>
