<template>
    <div class="modal fade" id="modalCurrency">
        <div class="modal-dialog">
            <div class="modal-content">
                <form :id="'formCurrency-'+_uid" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formCurrency.id">Editar Moneda</span>
                            <span v-else>Nueva Moneda</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 ">
                                <label for="name" class="required">Nombre</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre"
                                v-model="formCurrency.name" name="name" />
                            </div>
                            <div class="form-group col-12">
                                <label for="code" class="required">Codigo</label>
                                <input type="text" class="form-control" v-model="formCurrency.code"
                                placeholder="Ingrese Codigo" name="code">
                            </div>
                            <div class="form-group col-12">
                                <label for="symbol" class="required">Simbolo</label>
                                <input type="text" class="form-control" v-model="formCurrency.symbol"
                                placeholder="Ingrese Simbolo" name="symbol">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'geodata-currencies'">
                            <span v-if="formCurrency.id && $can('update','geodata-currencies')">Editar</span>
                            <span v-else-if="formCurrency.id && !$can('update','geodata-currencies')">Solicitar Edicion</span>
                            <span v-else>Guardar</span>
                        </button>
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["Currency"],
    components: {
        },
    data() {
        return {
            countries   : [],
            formCurrency: new WebForm("#formCurrency-" + this._uid, {
                name            : null,
                code            : null,
                symbol          : null,
                id              : null
            }),
        };
    },
    watch: {
        Currency: function(val, oldVal) {
            if (_.isEmpty(this.Currency) || this.Currency.id == '') {
                // console.log('Vacio Att: ', this.AttractionCategory);    
            }else {
                this.formCurrency.fill(this.Currency);  
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;

            let tipo = (vm.formCurrency.id == null) ? 1 : 2;
            if (this.formCurrency.id != null) {
                $.confirm({
                    title: `Editar Moneda`,
                    content: "¿Seguro quiere editar esta moneda?",
                    buttons: {
                        eliminar: {
                            text: "Editar",
                            btnClass: "btn-primary",
                            keys: ["enter"],
                            action: function() {
                                vm.updateOrCreate("/admin/location/currencies/save", tipo);
                            }
                        },
                        cancelar: function() {}
                    }
                });
            } else {
                vm.updateOrCreate("/admin/location/currencies/save", tipo);
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formCurrency.post(url).then(response => {
                let data = response.data;

                console.log(data);
                if (data.user_request_status) {
                    $('#modalCurrency').modal('hide');
                    return;
                }

                let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                vm.$snotify.success(snotify_message);
                vm.$emit('reloadCurrencyModal');
                vm.formCurrency.clear();
                vm.formCurrency.reset();
                $('#modalCurrency').modal('hide');
                return;

            });
        }
    },
    mounted() {
        let vm = this;
        $('#modalCurrency').off('hidden.bs.modal');
        $('#modalCurrency').on('hidden.bs.modal', function () {
            vm.formCurrency.clear();
            vm.formCurrency.reset();
            vm.$emit('closeCurrencyModal');
        });
    }
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>