<template>
    <div class="modal fade" id="newPermission" tabindex="-1" role="dialog" aria-labelledby="authModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="authModalLabel">
                        <span class="icon-lock-circle mr-2" grid="24"></span>
                        <span v-if="form.id">
                            Editar Permiso
                        </span>
                        <span v-else>
                            Nuevo Permiso
                        </span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form id="permissionForm" autocomplete="off" class="needs-validation" novalidate method="post">
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name">Nombre <small class="text-muted">(Unico)</small> </label>
                                    <input type="text" name="name" class="form-control" v-model="form.name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="title">Titulo</label>
                                    <input type="text" name="title" class="form-control" v-model="form.title">
                                </div>
                            </div>
                        </div> 
                        <!-- <div class="form-row">
                            <div class="col-md-12 mb-4">
                                <div class="form-group mb-2">
                                    <label for="last_name" class="d-flex align-content-center">Permisos del propietario 
                                        <span class="icon-alert-info" data-toggle="tooltip" data-placement="top" 
                                        title="El propietario es el usuario que crea la información, los permisos se resumen a si el propietario puede ver, editar o eliminar la información que esta creando."></span>
                                    </label>
                                    <select name="owner_permission" id="owner_permission" class="form-control" :disabled="form.only_owner" v-model="form.owner_permission">
                                        <option value="">Seleccionar...</option>
                                        <option value="VIEW">VIEW</option>
                                        <option value="WRITE">WRITE</option>
                                        <option value="MANAGE" selected>MANAGE</option>
                                    </select>
                                </div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="only_owner" name="only_owner" v-model="form.only_owner" v-on:change="form.owner_permission = (form.owner ? 'MANAGE' : '')">
                                    <label class="form-check-label" for="only_owner"> Solo el propietario puede administrar</label>
                                </div>
                            </div>
                        </div> -->
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="last_name">Descripción</label>
                                    <textarea name="description" class="form-control" cols="10" rows="5" v-model="form.description"></textarea>
                                </div>
                            </div>
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" @click.prevent="savePermission">
                            <span v-if="form.id">
                                Editar Permiso
                            </span>
                            <span v-else>
                                Crear Permiso
                            </span>
                        </button>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['permissions'],
        data () {
            return {
                form : new WebForm("#permissionForm", {
                    id: null,
                    name : '',
                    title : '',
                    description : '',
                    only_owner : false,
                    owner_permission: 'MANAGE',
                }),
            }
        },
        methods: {
            savePermission(){
                this.form.post('/admin/permission/component/new').then(response => {
                    this.$parent.$emit('update-data');
                    $('#newPermission').modal('hide')
                })
            },
            onEditPermission (id) {
                let permission = this.permissions.find(permission => {
                    return permission.id == id
                })

                if(!permission)
                    return

                permission.only_owner = permission.only_owner == 1;
                this.form.fill(permission)
            }
        },
        mounted: function () {
            let vm = this

            $('.icon-alert-info').tooltip();
            $("#newPermission").off("hidden.bs.modal");
            $("#newPermission").on("hidden.bs.modal", function(){
                vm.form.reset()
            });
        }
    }
</script>

<style scoped>
    .icon-alert-info{
        cursor: pointer;
        font-size: 20px;
        margin-left: 5px;
        color: #FFC107;
    }
</style>