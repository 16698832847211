<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Servicios de Hotel</h5>
                    <p class="text-muted mb-1">Lista de Servicios de Hotel Incluidos</p>
                </div>
                <div class="ml-auto">
                    <button class="btn btn-secondary center-content d-inline-block"  @click.prevent='goBack()'>
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button>
                    <button v-can:create="'packages-hotels-index'" class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addHotelService" >
                        <span class="icon-add-2 mr-2"></span> Nuevo Servicio
                    </button>
                </div>
            </div>

            <hotel-service-modal :HotelService="HotelService" v-on:closeHotelServiceModal="clearHotelServiceVar()" v-on:reloadHotelServiceModal="reloadHotelServiceModal()"></hotel-service-modal>

            <data-table :ajax="{ url: '/admin/hotels/services/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="hotelServiceTable" :serverSide="true" :columns="dt.columns()"></data-table>
        
        </div>
    </div>
</template>

<script>
    import dataTable from '~/components/dataTable'
    import HotelServiceModal from '~/components/packages/HotelServiceModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            HotelServiceModal
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                HotelService        : null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id',      data: 'id' },
                                { title: 'Nombre',  data: 'title' },
                                { title: 'icon', data: 'icon',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `
                                    <span class="${data} mr-2"></span> ${data}`;
                                }},
                                { title: 'Actions', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addHotelService" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addHotelService" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let btnedit     =  $this.$can('write', 'packages-hotels-index')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#addHotelService">Editar</button>` : `-`;
                                        let btndelete   =  $this.$can('write', 'packages-hotels-index')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btni18n + btnedit + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            clearHotelServiceVar() {
                this.HotelService = null;
            },
            reloadHotelServiceModal() {
                this.$refs.hotelServiceTable.ajaxReload();
            },
            goBack: function () {
                window.history.back();
            },
            onDelete(service) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Servicio',
                    `¿Está seguro que desea eliminar el servicio <u>${service.title}</u>?`, 
                    function(){
                        vm.deleteItem(service.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/hotels/services/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.hotelServiceTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
        },
        mounted(){ 
            var $this = this
            let vm = this
            
            $(vm.$el).off("click.hotelservices",".btn-edit");
            $(vm.$el).on("click.hotelservices",".btn-edit",function(){
                let hotel_service = vm.$refs.hotelServiceTable.rowData($(this).closest('tr'));
                hotel_service.btn_i18n = false;
                vm.HotelService = hotel_service;
            }); 

            $(vm.$el).off("click.hotelservices",".btn-i18n");
            $(vm.$el).on("click.hotelservices",".btn-i18n",function(){
                let hotel_service = vm.$refs.hotelServiceTable.rowData($(this).closest('tr'));
                hotel_service.btn_i18n = true;
                vm.HotelService = hotel_service;
            });

            $(vm.$el).off("click.hotelservices",".btn-delete");
            $(vm.$el).on("click.hotelservices",".btn-delete",function(){
                let service = vm.$refs.hotelServiceTable.rowData($(this).closest('tr'));
                vm.onDelete(service);
            });

        },
    }
</script>