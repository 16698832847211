import axios   from 'axios'

//State
export const state = {
    emails: {
        unanswered: {
            items: [],
            counter: 0,
            totalPages: null,
            currentPage: null,
            loading: true,
        },
        taken: {
            items: [],
            counter: 0,
            totalPages: null,
            currentPage: null,
            loading: true,
        },
        answered: {
            items: [],
            counter: 0,
            totalPages: null,
            currentPage: null,
            loading: true,
        }
    },
}


// Mutations
export const mutations = {
    LOADING_EMAILS(state, {type, loading}) {
        state.emails[type].loading = loading
    },

    UPDATE_EMAILS (state, {emails, type}) {
        let stateEmails = state.emails[type].items

        emails.forEach(email => {
            let index = stateEmails.findIndex(e => e.id === email.id)
            if(index >= 0) {
                return
            }
            stateEmails.push(email)
        });

        stateEmails.sort((a, b) => {
            return b.id - a.id
        });
    },

    UPDATE_EMAIL_PAGINATION (state, {currentPage, totalPages, type}) {
        state.emails[type].currentPage = currentPage
        state.emails[type].totalPages = totalPages
    },

    UPDATE_EMAIL_COUNTER (state, {counter, type}) {
        state.emails[type].counter = counter
    },

    UPDATE_STATUS_EMAIL (state, newEmail) {
        let types = ['answered', 'unanswered', 'taken']
        let type = newEmail.status.toLowerCase()
        types.forEach(t => {
            let index = state.emails[t].items.findIndex(email => email.id == newEmail.id)
            if(index < 0 ) {
                return
            }
            state.emails[t].items.splice(index, 1)
            state.emails[t].counter = state.emails[t].counter - 1
        })


        state.emails[type].items.push(newEmail)
        state.emails[type].counter = state.emails[type].counter + 1

    },
    SET_REPLY_EMAIL (state, reply) {
        let email = state.emails.find(e => e.id == reply.support_email_id)
        if(!email) return
        email.reply = reply
    }
}

// Actions
export const actions = {
    updateStatusEmail({commit}, email) {
        commit('UPDATE_STATUS_EMAIL', email)
    },

    async getEmails ({commit}, payload = {}) {
        let  { type = 'unanswered', page = 1 } = payload
        try{
            commit('LOADING_EMAILS', {type, loading: true})
            const { data } = await axios.get(`/admin/support/email/${type}?page=${page}`)
            let {emails, counter} = data
            commit('UPDATE_EMAILS', {emails: emails.data, type})
            commit('UPDATE_EMAIL_PAGINATION', {currentPage: emails.current_page, totalPages: emails.last_page, type})
            commit('UPDATE_EMAIL_COUNTER', {counter, type})
            commit('LOADING_EMAILS', {type, loading: false})
        } catch (e) {
            console.log(e)
        }
    },

    async getEmail ({commit}, id) {
        try{
            const { data } = await axios.get(`/admin/support/email/${id}`)
            let type = data.state.toLowerCase()
            commit('UPDATE_EMAILS', {mails: [data], type})
        } catch (e) {
            console.log(e)
        }
    }
}

// Getters
export const getters = {
    emailById: (state) => id => {
        let unansweredEmail = state.emails.unanswered.items.find(email => parseInt(email.id) == parseInt(id))
        let answeredEmail = state.emails.answered.items.find(email => parseInt(email.id) == parseInt(id))
        let takenEmail = state.emails.taken.items.find(email => parseInt(email.id) == parseInt(id))
        let email = unansweredEmail || answeredEmail || takenEmail || null
        return email
    },
    unansweredEmailsCounter: (state, getters) => {
        return state.emails.unanswered.counter > 20 ? '20+' : state.emails.unanswered.counter
    },
    unansweredEmails: state => {

        let emails = state.emails.unanswered.items
        return emails
    },
    takenEmailsCounter: (state, getters) => {
        return state.emails.taken.counter > 20 ? '20+' : state.emails.taken.counter
    },
    takenEmails: state => {

        let emails = state.emails.taken.items
        return emails
    },
    answeredEmailsCounter: (state, getters) => {
        return state.emails.answered.counter
    },
    answeredEmails: state => {
        let emails = state.emails.answered.items
        return emails
    },
    paginationEmails: state => {
        let data = {}

        Object.keys(state.emails).forEach((key) => {
            let {currentPage, totalPages, loading} = state.emails[key]
            data[key] = {currentPage, totalPages, loading}
        })


        return data
    }
}
