<template>
    <div v-if="editable" class="star-cb-group">
        <span class="star-item" v-for="(star, index) in stars" :key="`star-${star.value}-${index}`">
            <input type="radio" :class="{'checked': star.value <= rating, 'star-cb-clear': star.value == 0}" :id="`rating-${star.value}`" name="rating" :value="star.value" v-model="rating" @change="$emit('input', rating)"/>
            <label :for="`rating-${star.value}`"><span class="icon-star-bold"></span></label>
        </span>
    </div>
    <div v-else class="star-cb-group star-cb-group--noeditable">
        <span class="icon-star-bold star-item-noeditable"
            :class="star.value <= rating ? 'text-warning' : 'start-empty'"
            v-for="(star, index) in stars"
            :key="`star-${star.value}-${index}`">
        </span>
    </div>
</template>
<script>
export default {
    props: {
        size: {
            type: Number,
            default() {
                return 5
            }
        },
        editable: {
            type: Boolean,
            default() {
                return true
            }
        },
        zero: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    data() {
        return {
            rating: this.$attrs.value,
            stars: []
        }
    },
    mounted() {
        let vm = this
        let stars = []
        for (let index = 0; vm.zero ? index <= vm.size : index < vm.size; index++) {
            let data = {
                value: vm.size-index,
                checked: vm.size==index
            }

            stars.push(data)
        }

        this.stars = stars
    }
}
</script>
<style lang="scss" scoped>
.start-empty{
    color: #cfd4da;
}
.star-cb-group {
    /* remove inline-block whitespace */
    &--noeditable{
        margin-left: -0.1em;
        margin-right: -0.1em;
    }
    height: 1rem;
    font-size: 0;
    display: inline-block;
    * {
        font-size: 1rem;
    }
    /* flip the order so we can use the + and ~ combinators */
    unicode-bidi: bidi-override;
    direction: rtl;
    white-space: nowrap;

    & input {
      display: none;
      & + label {
        /* only enough room for the star */
        display: inline-block;
        overflow: hidden;
        min-width: 1em;
        white-space: nowrap;
        cursor: pointer;
        margin: 0;
        padding-left: 0.1em;
        padding-right: 0.1em;
        & {
          display: inline-block;
          color: #cfd4da;
        }
        &:hover{
            color: #FFC107;
        }
      }
      &.checked ~ label,
        & + label:hover ~ label,
        & + label:hover {
        color: #FFC107;
      }
    }

    /* the hidden clearer */
    & .star-cb-clear + label {
      width: .5em;
      margin-left: -1em;
      opacity: 0;
      padding: 0;
    }
    & .star-cb-clear + label {
      width: .5em;
    }

    &:hover > .star-item > input + label,
    &:hover > .star-item > input:checked + label,
    &:hover > .star-item > input.checked + label {
      color: #cfd4da ;
      text-shadow: none;
    }
    & > .star-item-noeditable{
        padding-left: 0.1em;
        padding-right: 0.1em;
    }
    & > .star-item{
        display: inline-block;
        height: 100%;
        &:hover  ~ .star-item > input + label,
        &:hover  ~ .star-item > input:checked + label,
        &:hover  ~ .star-item > input.checked + label,
        &:hover > input + label {
          color: lighten(#FFC107, $amount: 10) !important;
        }
    }
  }
</style>
