<template>
    <div class="section pt-3 pb-1 mx-2 mt-2">
        <transition name="fade">
            <div class="row mx-2">
                <div class="form-group col-12 col-md-9 div-hotel-name">
                    <label class="pr-2"><b><u>{{hotelPack.hotel.name}}</u></b></label> <span v-for="(star, index) in hotelPack.hotel.stars" :key="index" class="icon-star mr-1 pb-5" grid="12"></span>
                    <button class="btn btn-default ml-3 btn-sm d-inline-block"
                        data-toggle="modal" data-target="#modalHotel" @click="editHotel()">
                        <span class="icon-pencil-edith mr-1"></span> Editar Hotel
                    </button>
                    <br>
                    <label class="text-muted">{{hotelPack.hotel.address}}, {{hotelPack.hotel.city.name}} {{hotelPack.hotel.city.state.name}} ( {{hotelPack.hotel.city.state.country.name}} )<br> 
                        Phone: {{ (hotelPack.hotel.phone_number) ?  '(' + hotelPack.hotel.country_code + ') +' + hotelPack.hotel.country_calling_code + ' ' + hotelPack.hotel.phone_number : ''}}</label>
                </div>
                <div class="form-group col-12 col-md-3 text-right">
                    <button class="btn btn-sm btn-danger mr-1" @click="removePack(hotelPack)"> <span class="icon-close mr-1"></span> Remover</button>
                </div>
                <div class="form-group col-12 col-md-9 text-justify">
                    <div>
                        <button class="btn btn-sm btn-success mb-3" data-toggle="modal" data-target="#modalHotelGallery" @click.prevent="viewGalery(hotelPack.hotel.id)">
                            <span class="icon-image-gallery mr-1"></span> 
                            Galeria de imagenes del Hotel
                        </button>
                    </div>
                    <div>
                        <label style="height: auto;">{{hotelPack.hotel.description}}</label>
                    </div>
                </div>
                <!-- Despues tambien mostrar los servicios -->
                <div class="form-group col-12 col-md-3">
                    <label class="optionHotel">
                        <input @change="changeService(hotelPack)" type="checkbox" v-model="hotelPack.airport_transportation" class="pt-2">
                        Transporte al Aeropuerto.
                    </label>
                    <label class="optionHotel">
                        <input @change="changeService(hotelPack)" type="checkbox" v-model="hotelPack.they_allow_pets" class="pt-2">
                        Se permiten mascotas.
                    </label>
                    <label class="optionHotel">
                        <input @change="changeService(hotelPack)" type="checkbox" v-model="hotelPack.all_inclusive" class="pt-2">
                        Todo Incluido.
                    </label>
                </div>
            </div>
        </transition>
        <div class="row mx-2">
            <div class="col-12 col-md-8">
                <h6 class="font-weight-bold">Disponibilidades de Estadia en el Hotel</h6>
            </div>  
            <div class="col-12 col-md-4 text-right">
                <button class="btn btn-sm btn-info add-hotel-stay" @click="addPackageHotelStay(hotelPack, null)" data-toggle="modal" data-target="#addPackageHotelStay">
                    Nueva Estadia
                </button>
            </div>
        </div>
        <div class="row mx-2 mt-3">
            <package-hotel-stay-component :stays="stays" 
            v-on:deletePackageHotelStay="deletePackageHotelStay"
            v-on:editPackageHotelStay="editPackageHotelStay"
            v-on:clickStayService="clickStayService"
            v-on:clickStayAttraction="clickStayAttraction"
            v-on:viewGalleryAttraction="viewGalleryAttraction"
            v-on:reloadStays="getPackageStays">
            </package-hotel-stay-component>
        </div>
    </div>
</template>
<script>
import PackageHotelStayComponent from '~/components/packages/listeds/PackageHotelStayComponent';

export default {
    components : {
        PackageHotelStayComponent,
    },
    props: [
        "hotelPack",
        "indexKey",
        "reloadId"
        ],
    data() {
        return {
            stays       : [], // estadias pertenecientes
            formHotelPack: new WebForm('#formHotelPack', {
                id                      : null,
                package_id              : '', 
                hotel_id                : '',
                airport_transportation  : '',
                they_allow_pets         : '',
                all_inclusive           : '',
            }),
        };
    },
    watch: {
        reloadId: function(val, oldVal) {
            let vm = this;
            if (vm.reloadId!= null && vm.hotelPack.id == vm.reloadId) {
                vm.getPackageStays();
            }
        },
    },
    methods: {
        editHotel() {
            let idx = this.indexKey;
            this.$emit('editHotelIndex', { idx });
        },
        // Para StayService -----------------
        clickStayService(obj) {
            let vm = this;
            vm.addStayService(vm.stays[obj.indexStay], obj.indexService);
        },
        addStayService(stay, service) {
            stay.package_service = (service!=null) ? service : null;
            stay.hotel_pack_id = this.hotelPack.id;
            this.$emit('addStayService', { stay });
        },
        // ----------------------------------
        // Para StayAttraction -----------------
        clickStayAttraction(obj) {
            let vm = this;
            vm.addStayAttraction(vm.stays[obj.indexStay], obj.indexAttraction);
        },
        addStayAttraction(stay, attraction) {
            stay.package_attraction = (attraction!=null) ? attraction : null;
            stay.hotel_pack_id = this.hotelPack.id;
            this.$emit('addStayAttraction', { stay });
        },
        // ----------------------------------
        viewGalery(id) {
            this.$emit('viewGalery', id);
        },
        viewGalleryAttraction(id) {
            this.$emit('viewGalleryAttraction', id);
        },
        addPackageHotelStay(hotelPack, stay) {
            this.$emit('addPackageHotelStay', { hotelPack, stay });
        },
        editPackageHotelStay(index) {
            let vm = this;
            vm.addPackageHotelStay(vm.hotelPack, vm.stays[index]);
        },
        deletePackageHotelStay(index) {
            this.stays.splice(index, 1);
        },
        removePack(pack) {
            let vm = this;
            
            $.confirm.delete(
                `Eliminar Hotel`,
                `¿Está seguro que desea eliminar el hotel <br><b><u>${pack.hotel.name}</u></b> de este paquete?`, 
                function(){
                    vm.deleteHotelPak(pack.id);
                }
            )
        },
        deleteHotelPak(id) {
            let vm = this;
            vm.$emit('displayLoadModal', true);
            axios.post('/admin/packages/hotels/delete', { id: id }).then(response => {
                let data = response.data;

                if (data.user_request_status) {
                    vm.$emit('displayLoadModal', false);
                    return;
                }

                if(!data.success) {
                    vm.$snotify.warning('Ups. Algo a salido mal.');
                    vm.$emit('displayLoadModal', false);
                    return;
                }

                if(data.success) {
                    vm.$snotify.simple('Se Elimino con Exito');
                    vm.$emit('HotelPackDelete', vm.indexKey);
                    return;
                }
                
                vm.$emit('displayLoadModal', false);
            }).catch(err => {
                console.log(err);
                vm.$emit('displayLoadModal', false);
            });
        },
        changeService(pack) {
            let vm = this;
            vm.formHotelPack.id                         = pack.id;
            vm.formHotelPack.hotel_id                   = pack.hotel_id;
            vm.formHotelPack.package_id                 = pack.package_id;
            vm.formHotelPack.airport_transportation     = pack.airport_transportation;
            vm.formHotelPack.they_allow_pets            = pack.they_allow_pets;
            vm.formHotelPack.all_inclusive              = pack.all_inclusive;
            
            vm.formHotelPack.post('/admin/packages/hotels/save').then(response => {
                let data = response.data;
                
                if (data.user_request_status) {
                    return;
                }

                if(data.success) {
                    vm.$snotify.success('Accion exitosa.');
                    vm.formHotelPack.reset();
                    vm.formHotelPack.clear();
                    return;
                }

                vm.$snotify.warning('Ups. Algo salio mal.');
            });
        },
        getPackageStays() {
            let vm = this;
            let endpoint = `/admin/packages/hotels/stays/allbyid/${vm.hotelPack.id}`;
            axios.get(endpoint).then(({data}) => {
                vm.stays = data;
            });
        },
    },
    mounted() {
        let vm = this;
        vm.getPackageStays();
    }
}
</script>
<style scoped>
    .div-hotel-name .icon-change {
        display: none;
    }
    .div-hotel-name:hover .icon-change {
        display: inline-block;
        cursor: pointer;
    }
    label.optionHotel {
        display: block !important;
    }
</style>