<template>
    <div>
        <div class="modal fade" @submit.prevent="saveHotelStay()" id="addPackageHotelStay">
            <div v-if="PackageHotel" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formHotelStay" autocomplete="off"  novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="PackageHotel.stay">
                                    Editar estadia en {{PackageHotel.hotelPack.hotel.name}}
                                </span>
                                <span v-else>Nueva estadia en {{PackageHotel.hotelPack.hotel.name}}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!--  -->
                                <div class="form-group col-12 col-md-3">
                                    <label for="start_date" class="required">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="formHotelStay.start_date" name="start_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="start_hour" class="required">Hora de Ingreso</label>
                                    <vue-timepicker name="start_hour" v-model="formHotelStay.start_hour_a" placeholder="Hora de Ingreso" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="end_date" class="required">Fecha de Salida</label>
                                    <vue-datepicker v-model="formHotelStay.end_date" name="end_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="end_hour" class="required">Hora de Salida</label>
                                    <vue-timepicker name="end_hour" v-model="formHotelStay.end_hour_a" placeholder="Hora de Salida" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-12">
                                    <label for="hotel_room_id" class="required" style="width: 100%">Habitación
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" 
                                        style="padding: 2px 8px;" data-toggle="modal" data-target="#modalHotelRoom"
                                        @click.prevent="addHotelRoom()">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="formHotelStay.hotel_room"
                                    @keypress.enter.native.prevent="" name="hotel_room_id" required="required"
                                    ref="vSelectRoomId" placeholder="Tipo Habitacion" :options="hotelRoomsSelect">
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-12">
                                    <label for="beds" style="width: 100%">Resumen de habitacion</label>
                                    <span v-show="!formHotelStay.hotel_room">-- Debe seleccionar una habitacion --</span>
                                    <div class="form-control" v-show="formHotelStay.hotel_room" style="height: auto; min-height: 35px;">
                                        <button class="btn btn-default ml-3 float-right btn-sm d-inline-block"
                                            @click.prevent="editHotelRoom()" data-toggle="modal" data-target="#modalHotelRoom">
                                            <span class="icon-pencil-edith mr-1"></span> Editar
                                        </button>
                                        <div class="row">
                                            <div class="col-3 font-weight-bold">Capacidad:</div>
                                            <div class="col-9">
                                                <span>{{resumenRoom['capacity']}} {{ (resumenRoom['capacity'] > 1) ? ' Personas' : ' Persona' }}</span>
                                            </div>
                                            <div class="col-3 font-weight-bold">Camas:</div>
                                            <div class="col-9">
                                                <span v-for="(item, index) in resumenRoom.beds" class="my-1" style="display: block" :key="'bed-'+index">
                                                    <span class="mr-2" :class="item.icon"></span> 
                                                    <b>{{item.n}}</b> -  {{item.title}} ( <b>{{item.capacity * item.n}} <span class="icon-user-2"></span></b> )
                                                </span>
                                            </div>
                                            <div v-show="resumenRoom['views'] && resumenRoom['views'].length>0" class="col-3 font-weight-bold">Vistas:</div>
                                            <div v-show="resumenRoom['views'] && resumenRoom['views'].length>0" class="col-9">
                                                <span v-for="(item, index) in resumenRoom.views" class="badge badge-light mr-1 py-1 my-1" :key="'view-'+index">
                                                    {{item}}
                                                </span>
                                            </div>
                                            <div v-show=" resumenRoom.hotel_services &&  resumenRoom.hotel_services.length>0" class="col-3 font-weight-bold">Servicios:</div>
                                            <div v-show=" resumenRoom.hotel_services &&  resumenRoom.hotel_services.length>0" class="col-9">
                                                <span v-for="(item, index) in  resumenRoom.hotel_services" class="badge badge-light mr-1 py-1 my-1" :key="'service-'+index">
                                                    <!-- <span :class="item.icon" grid="16"></span>  -->
                                                    {{item.title}}
                                                </span>
                                            </div>
                                            <div v-show=" resumenRoom.furnishings &&  resumenRoom.furnishings.length>0" class="col-3 font-weight-bold">Muebles:</div>
                                            <div v-show=" resumenRoom.furnishings &&  resumenRoom.furnishings.length>0" class="col-9">
                                                <span v-for="(item, index) in  resumenRoom.furnishings" class="badge badge-light mr-1 py-1 my-1" :key="'furnishing-'+index">
                                                    {{item.name}}
                                                </span>
                                            </div>
                                            <div v-show="resumenRoom.description" class="col-3 font-weight-bold">Descripcion:</div>
                                            <div v-show="resumenRoom.description" class="col-9">
                                                {{resumenRoom.description}}
                                            </div>
                                            <div class="col-3"></div>
                                            <div v-show="resumenRoom.smok" class="col-9">
                                                Se permite fumar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formHotelStay.cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelStay__cost"
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formHotelStay.taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelStay__taxes"
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="link">Enlace</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="formHotelStay.link" name="link">
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formHotelStay.utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelStay__utility"
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="total">Precio total por estadia</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="priceTotal"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" 
                                    :precision="2" :decimal-separator="'.'" disabled
                                    name="total"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4"></div>
                                <!--  -->
                                <div class="form-group col-12">
                                    <label for="description" class="w-100">
                                        <span class="mr-3">Descripción de la Estadia (Opcional)</span>
                                        <!-- <toggle-button 
                                        :value="!i18n" 
                                        color="#38c172" 
                                        :sync="true"
                                        :labels="{checked: 'Español', unchecked: 'Ingles'}"
                                        :width="80" 
                                        @change="i18n=!i18n"
                                        /> -->
                                        <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                            <label class="btn btn-sm" :class="formHotelStay.i18n ? 'btn-light' : 'btn-success'" @click="formHotelStay.i18n=false">
                                                <input type="radio"> Español
                                            </label>
                                            <label class="btn btn-sm " :class="!formHotelStay.i18n ? 'btn-light' : 'btn-success'" @click="formHotelStay.i18n=true">
                                                <input type="radio"> Ingles
                                            </label>
                                        </div>
                                    </label>
                                    <textarea v-show="!formHotelStay.i18n" type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formHotelStay.description" name="description" ></textarea>
                                    <textarea v-show="formHotelStay.i18n" type="text" class="form-control" rows="4" placeholder="Enter Description" v-model="formHotelStay.description_i18n" name="description" ></textarea>
                                </div>
                                <!--  -->
                                <div class="form-group col-12" style="margin-top: 12px; margin-bottom: 5px;">
                                    <h5>
                                        <span class="badge badge-warning">
                                            Colocar Montos que se cobraran con tarjeta y efectivo a cancelar en el hotel
                                        </span>
                                    </h5>
                                </div>
                                <!--  -->
                                <div class="form-group col-12 col-md-3">
                                    <label for="card_amount" class="required">Pago con Tarjeta</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model="formHotelStay.card_amount"
                                    :min="0" :max="99999" :empty-value="0" 
                                    :precision="2" :decimal-separator="'.'" id="formHotelStay__card_amount"
                                    name="card_amount"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="cash_amount" class="required">Restante en Efectivo</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="cashTotal"
                                    :min="0" :max="99999" :empty-value="0" disabled
                                    :precision="2" :decimal-separator="'.'" id="formHotelStay__cash_amount"
                                    name="cash_amount"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="type_refund" class="required">Reembolso</label>
                                    <select v-model="formHotelStay.type_refund" name="type_refund" class="form-control">
                                        <option value="NONE">Ninguno</option>
                                        <option value="TOTAL">Total</option>
                                        <option value="PARTIAL">Parcial</option>
                                    </select>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="refund" class="required">Monto Reembolso</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model="formHotelStay.refund"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'" :disabled="formHotelStay.type_refund!='PARTIAL'"
                                    name="refund"></vue-numeric>
                                </div>
                                <!--  -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-index'"> 
                                <span v-if="PackageHotel.stay && $can('update', 'packages-index')">
                                    Editar
                                </span>
                                <span v-else-if="PackageHotel.stay && !$can('update', 'packages-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                            <!-- <button type="button" class="btn btn-info" @click.prevent="ver()" >
                                Show Console
                            </button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <room-modal v-on:reloadRoomModal="getRooms()"></room-modal>
    </div>
</template>
<style lang="css" scoped>
    .is-invalid{
        border-color: #e3342f;
    }
</style>
<script>

import VueNumeric from 'vue-numeric';
import RoomModal from '~/components/packages/RoomModal';

export default {
    components: {
        VueNumeric,
        RoomModal
    },
    props: [
        "PackageHotel",
        "HotelStay",
        "HotelRommAdded"
        ],
    data() {
        return {
            rooms                   : [],
            hotelRooms              : [],
            hotelRoomsSelect        : [],
            formHotelStay: new WebForm('#formHotelStay', {
                id                      : null,
                package_hotel_id        : null,
                hotel_room_id           : null,
                hotel_room              : null,
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                start_hour_a            : {"hh":"01", "mm":"00", "A":"PM"},
                end_hour_a              : {"hh":"11", "mm":"00", "A":"AM"},
                cost                    : 0,
                taxes                   : 0,
                utility                 : 0,
                description             : '',
                description_i18n        : null,
                type_refund             : 'NONE',
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
                total                   : 0,
                link                    : '',
                i18n                    : false,
            }),
        };
    },
    watch: {
        PackageHotel: function(val, oldVal) {
            let vm = this;
            if(vm.PackageHotel!=null) {
                vm.formHotelStay.package_hotel_id = vm.PackageHotel.hotelPack.id;

                if (vm.PackageHotel.stay==null) {
                    vm.initDate();
                } else {
                    let stay = vm.PackageHotel.stay;
                    vm.formHotelStay.id             = stay.id;
                    vm.formHotelStay.cost           = stay.cost;
                    vm.formHotelStay.taxes          = stay.taxes;
                    vm.formHotelStay.type_utility   = stay.type_utility;
                    vm.formHotelStay.utility        = stay.utility;
                    vm.formHotelStay.n_people       = stay.n_people;
                    vm.formHotelStay.cant_room      = stay.cant_room;
                    vm.formHotelStay.link           = stay.link;
                    vm.formHotelStay.description    = stay.description;
                    vm.formHotelStay.start_date     = stay.start_date;
                    vm.formHotelStay.end_date       = stay.end_date;
                    vm.formHotelStay.type_refund    = stay.type_refund;
                    vm.formHotelStay.refund         = stay.refund;
                    vm.formHotelStay.cash_amount    = stay.cash_amount;
                    vm.formHotelStay.card_amount    = stay.card_amount;
                    vm.formHotelStay.description_i18n = stay.description_i18n;

                    if (stay.start_hour) {
                        let time = stay.start_hour;
                        vm.formHotelStay.start_hour = time;
                        vm.formHotelStay.start_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formHotelStay.start_hour_a = { "hh" : "01", "mm" : "00", "A"  : "AM" }
                    }

                    if (stay.end_hour) {
                        let time = stay.end_hour;
                        vm.formHotelStay.end_hour = time;
                        vm.formHotelStay.end_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formHotelStay.end_hour_a = { "hh" : "11", "mm" : "59", "A"  : "PM" }
                    }
                }
                this.getHotelRooms();
            } else {
                
            }
        },
        HotelRommAdded: function(val, oldVal) {
            let vm = this;
            if (vm.HotelRommAdded && vm.HotelRommAdded != null) {
                if (vm.HotelRommAdded.success.id) {
                    vm.getHotelRooms(vm.HotelRommAdded.success.id);
                }
            }
        }
    },
    computed: {
            resumenRoom() {
                let vm = this;
                if(vm.formHotelStay.hotel_room && vm.formHotelStay.hotel_room.id) {
                    let newResumen = vm.hotelRooms.find(c => {
                        return c.id == vm.formHotelStay.hotel_room.id;
                    });
                    newResumen = Object.assign({}, newResumen);
                    newResumen.beds = (newResumen.beds && newResumen.beds!=null) ? JSON.parse(vm.decodeHTML(newResumen.beds)) : '';
                    newResumen.views = (newResumen.views && newResumen.views!=null) ? JSON.parse(vm.decodeHTML(newResumen.views)) : '';
                    
                    return newResumen;
                } else {
                    return [];
                }
            },
            priceTotal() {
                let vm = this;
                let cost        = (vm.formHotelStay.cost) ? parseFloat(vm.formHotelStay.cost) : 0; 
                let taxes       = (vm.formHotelStay.taxes) ? parseFloat(vm.formHotelStay.taxes) : 0;
                let utility     = (vm.formHotelStay.utility) ? parseFloat(vm.formHotelStay.utility) : 0;
                let total = cost + taxes + utility;
                vm.formHotelStay.card_amount = total;
                return total;
            },
            cashTotal() {
                let vm = this;
                let card = (vm.formHotelStay.card_amount) ? parseFloat(vm.formHotelStay.card_amount) : 0; 
                let totalCash = 0;
                if(vm.priceTotal >= card) {
                    totalCash = vm.priceTotal - card;
                } else {
                    card = vm.priceTotal;
                    totalCash = vm.priceTotal - card;
                    vm.formHotelStay.card_amount = card;
                }
                vm.formHotelStay.cash_amount = totalCash;

                if(vm.formHotelStay.type_refund=="TOTAL") {
                    vm.formHotelStay.refund = vm.priceTotal;
                } else if(vm.formHotelStay.type_refund=="NONE") {
                    vm.formHotelStay.refund = 0;
                }

                return totalCash;
            }
        },
    methods: {
        addHotelRoom() {
            let vm = this;
            let hotel = Object.assign({}, vm.PackageHotel.hotelPack.hotel);
            vm.$emit('addNewHotelRoom', hotel);
        },
        editHotelRoom() {
            let vm = this;
            let hotel = null;
            hotel = Object.assign({}, vm.PackageHotel.hotelPack.hotel);
            hotel.hotel_room = vm.hotelRooms.find(c => {
                return c.id == vm.formHotelStay.hotel_room.id;
            });
            hotel.hotel_room_edit = true;
            vm.$emit('addNewHotelRoom', hotel);
        },
        ver() {
            console.log(this.formHotelStay);
        },
        saveHotelStay() {
            let vm = this;

            if(vm.formHotelStay.hotel_room) {
                vm.formHotelStay.hotel_room_id = vm.formHotelStay.hotel_room.id;
            }

            if(vm.formHotelStay.start_hour_a) {
                let time = vm.formHotelStay.start_hour_a;
                vm.formHotelStay.start_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            if(vm.formHotelStay.end_hour_a) {
                let time = vm.formHotelStay.end_hour_a;
                vm.formHotelStay.end_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            vm.formHotelStay.post('/admin/packages/hotels/stays/save').then(response => {
                let data = response.data;
                
                if(data.success) {
                    vm.$emit('reloadPackageHotelStay', vm.PackageHotel.hotelPack.id);
                    let snotify_message = vm.PackageHotel.stay == null ? 'Estadia agregada con exito.' : 'Estadia Actualizada con exito.';
                    vm.$snotify.success(snotify_message);
                    $('#addPackageHotelStay').modal('hide');
                    vm.formHotelStay.clear();
                    vm.formHotelStay.reset();
                    return;
                }
                
                vm.$snotify.info(`Algo resulto mal...`)
                // $('#addPackageHotelStay').modal('hide');
            });
        },
        getHotelRooms(id_select) {
            let vm = this;
            let endpoint = `/admin/hotels/hotel_rooms/allbyid/${vm.PackageHotel.hotelPack.hotel_id}`;
            axios.get(endpoint).then(({data}) => {
                let hotelRooms = data.map(l => {
                    let room = l
                    let d = {
                        id: room.id,
                        name: room.type_room.title,
                        label: `${room.type_room.title} - Capacidad ${room.capacity} ${ (room.capacity > 1) ? 'Personas' : 'Persona' } `,
                    }
                    return d
                });
                vm.hotelRooms = data;

                vm.hotelRoomsSelect = hotelRooms;
                vm.hotelRoomsSelect.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0;
                    return a1> b1? 1: -1;
                });

                if(id_select) {
                    vm.formHotelStay.hotel_room = vm.hotelRoomsSelect.find(c => {
                        return c.id == id_select;
                    });
                }

                if(this.PackageHotel.stay!=null && this.PackageHotel.stay.hotel_room_id) {
                    vm.formHotelStay.hotel_room = vm.hotelRoomsSelect.find(c => {
                        return c.id == this.PackageHotel.stay.hotel_room_id;
                    });
                }
            });
        },
        intTwoChar(val) {
            if(val>9) {
                return val;
            } else {
                return '0'+val;
            }
        },
        initDate() {
            let vm = this;
            if(vm.PackageHotel.stay==null) {
                // colocamos como fecha de expiracion 3 dias despues de hoy
                let hoy = new Date();
                var devolucion = new Date();
                devolucion.setDate(hoy.getDate() + 3);
                vm.formHotelStay.start_date = hoy.getUTCFullYear() + '-' + vm.intTwoChar(hoy.getMonth() + 1) + '-' + vm.intTwoChar(hoy.getDate());
                vm.formHotelStay.end_date = devolucion.getUTCFullYear() + '-' + vm.intTwoChar(devolucion.getMonth() + 1) + '-' + vm.intTwoChar(devolucion.getDate());
            }
        }
    },
    mounted() {
        let vm = this;

        $('#addPackageHotelStay').off('hidden.bs.modal');
        $('#addPackageHotelStay').on('hidden.bs.modal', function () {
            vm.formHotelStay.clear();
            vm.formHotelStay.reset();
            vm.$emit('closePackageHotelStayModal');
        });

        // console.log('PackageHotelStayModal mounted.');
    }
};
</script>

