<template>
    <div>
        <div v-show="!currentImage">
            <input type="file" class="file-style rounded" :ref="'imgUpload'" @change="onFileChange" accept="image/jpg,image/jpeg,image/png">
            <label title="File" class="btn btn-light btn-lg label-file-style center-content" @click.prevent="pickImage" >
                <i class="icon-add-2" grid="48" ></i>
            </label>
        </div>
        <div id="listImg">
            <div v-if="currentImage" class="contenedorImg" :style="'background-color:' + color_primary">
                <img :src="currentImage" @click.prevent="pickImage" class="miniature">
            </div>
        </div>
    </div>    
</template>
<script>
import ColorThief from 'colorthief';

export default {
    props: [
        'last_image',
        'clearCurrent',
        'color_primary'
        ],
    data() {
        return {
            currentImage: '',
            newImage: '',
        }
    },
    watch: {
        clearCurrent: function(val, oldVal) {
            if (this.clearCurrent) {
                this.currentImage = null;
            }
        },
        last_image: function(val, oldVal) {
            if (this.last_image || this.last_image!="") {
                let vm = this;
                vm.currentImage = vm.last_image;
                const colorThief = new ColorThief();
                let vImage = new Image();
                vImage.src = vm.currentImage;
                vImage.onload = () => {
                    let color = colorThief.getColor(vImage);
                    let paleta = colorThief.getPalette(vImage, 7);
                    vm.$emit('getImage', {'image': vm.newImage, 'primary' : color, 'palette': paleta, 'last': true});
                }
            }
        },
    },
    methods: {
        pickImage() {
            this.$refs[('imgUpload')].click();
        },
        onFileChange(e) {
            const vm = this;
            if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    vm.currentImage =  e.target.result;
                    vm.newImage =  e.target.result;
                    const colorThief = new ColorThief();
                    let vImage = new Image();
                    vImage.src = vm.currentImage;
                    vImage.onload = () => {
                        let color = colorThief.getColor(vImage);
                        let paleta = colorThief.getPalette(vImage, 7);
                        vm.$emit('getImage', {'image': vm.newImage, 'primary' : color, 'palette': paleta});
                    }
                }
                reader.readAsDataURL(e.target.files[0]);
            }
            // $('#inputFile').val('');
        },
    },
    mounted() {
        let vm = this;
        // vm.currentImage = vm.last_image;
    }
}
</script>
<style scoped>
.file-style{
    display: none;
}
.label-file-style{
    width: 200px;
    height: 200px;
    padding: 0px;
}
.miniature{
    margin: 5px;
    width: auto;
    max-height: 190px;
    max-width: 300px;
    display: inline-block;
    cursor: pointer;
}
.contenedorImg{
    background-color: rgba(250, 250, 250, 0.767);
    display: inline-block;
    width: auto;
    max-height: 200px;
    margin-right: 10px;
    position: absolute;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.close{
    background-color: rgb(160, 160, 160);
    position: absolute;
    margin-left: 98%;
    margin-top: -4%;
    width: 20px;
}
</style>