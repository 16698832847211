<template>
    <div class="col-12">
        <table class="table" style="font-size: 12px;">
        <thead class="thead-light">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Detalle de Estadia</th>
                <th scope="col" width="200" class="mr-2">Costos de Estadia</th>
                <th scope="col">Otros Servicios</th>
                <th scope="col">Atracciones</th>
                <th scope="col" width="200" class="mr-2">Montos Totales</th>
                <th scope="col" width="140">Opciones</th>
            </tr>
        </thead>
        <tbody>
            <!--  -->
            <tr v-for="(stay, index) in stays" :key="index">
                    <th>{{ index + 1 }}</th>
                    <td class="option-details">
                        Del <b>{{ stay.start_date }}</b> al <b>{{ stay.end_date }}</b>
                        <br>
                        Entrada: <b>{{ stay.start_hour }}</b>, Salida: <b>{{ stay.end_hour }}</b>
                        <br>
                        Tipo Habitacion <b>{{ stay.hotel_room.type_room.title }}</b>
                        <br>
                        Capacidad <b>{{ stay.hotel_room.capacity }} {{ (stay.hotel_room.capacity>1) ? 'Personas' : 'Persona' }}</b>
                        <br>
                        <div>
                            <p v-for="(item, index) in JSON.parse(decodeHTML(stay.hotel_room.beds))" :key="index" class="mb-1 mt-1" style="border: none !important;">
                                <span class="mr-2" :class="item.icon"></span>
                                <b>{{item.n}}</b> -  {{item.title}} <span class="float-right">( <b>{{item.capacity * item.n}} <span class="icon-user-2"></span></b> )</span>
                            </p>
                        </div>
                        <i><b>"{{ stay.description }}"</b></i>
                        <div class="stay-options" >
                            <!-- <span class="badge pointer btn-outline-secondary" grid="16" @click.prevent="duplicateStay(stay)" 
                                 data-toggle="modal" title="Duplicar Estadia" data-target="#modalLoading">
                                <span class="icon-copy"></span> 
                            </span> -->
                            <span class="badge pointer btn-outline-secondary" data-toggle="modal" 
                                data-target="#addPackageHotelStay" @click.prevent="onEditStay(index)">
                                <span class="icon-pencil-edith" grid="16"></span> 
                            </span>
                            <span class="badge pointer btn-outline-secondary" @click.prevent="onDeleteStay(stay, index)">
                                <span class="icon-bin" grid="16"></span> 
                            </span>
                        </div>

                    </td>
                    <td class="pr-3">
                        <div class="row">
                            <div class="col-md-6">Costo: </div>
                            <div class="col-md-6 text-right">$ {{ stay.cost }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Impuestos: </div>
                            <div class="col-md-6 text-right"><span class="text-danger">$ {{ stay.taxes }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Utilidad:</div>
                            <div class="col-md-6 text-right"><span class="text-success">$ {{ parseFloat(stay.utility) }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Total: </div>
                            <div class="col-md-6 text-right"><span class="text-primary">$ {{ parseFloat(stay.cost) + parseFloat(stay.taxes) + parseFloat(stay.utility) }}</span></div>
                        </div>
                        <hr style="margin: 5px 0;">
                        <div class="row">
                            <div class="col-md-6">Tarjeta: </div>
                            <div class="col-md-6 text-right"><span class="text-secondary">$ {{ parseFloat(stay.card_amount) }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Efectivo: </div>
                            <div class="col-md-6 text-right"><span class="text-secondary">$ {{ parseFloat(stay.cash_amount) }}</span></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">Reembolso: </div>
                            <div class="col-md-6 text-right"><span class="text-secondary">$ {{ parseFloat(stay.refund) }}</span></div>
                        </div>
                    </td>
                    <td class="view-service">
                        <h6 v-for="(stay_service, indexService) in stay.package_stay_service" :key="indexService" class="mb-1">
                            <span class="badge pointer btn-outline-dark" @click.prevent="onStayService(index, indexService)" data-toggle="modal" data-target="#addStayServiceModal" >
                                <span :class="'mr-1 ' + stay_service.package_service.icon" grid="16"></span> 
                                {{stay_service.package_service.title}} 
                                <span v-if="stay_service.is_free==1"> - <i>Gratis</i></span>
                                <span v-else> - <i>$ {{ parseFloat( parseFloat(stay_service.cost) + parseFloat(stay_service.taxes) + parseFloat(stay_service.utility) ) }}</i></span>
                                
                            </span>
                        </h6>
                        <button class="btn btn-sm btn-secondary add-button" data-toggle="modal"
                        data-target="#addStayServiceModal" @click.prevent="onStayService(index, null)">
                            <span class="icon-add-2 mr-1"></span> Agregar
                        </button>
                    </td>
                    <td class="view-service">
                        <h6 v-for="(stay_attraction, indexAttraction) in stay.package_stay_attraction" :key="indexAttraction" class="mb-1">
                            <span class="badge pointer btn-outline-info" @click.prevent="onStayAttraction(index, indexAttraction)" data-toggle="modal" data-target="#addStayAttractionModal" >
                                <span class="">
                                    <span class="icon-check mr-1" grid="16"></span> 
                                    {{stay_attraction.attraction.name}}
                                </span>
                            </span>
                            <span class="badge pointer btn-outline-info" @click.prevent="viewGalleryAttraction(stay_attraction.attraction.id)" data-toggle="modal" data-target="#modalAttractionGallery">
                                <span class="icon-image-gallery" grid="16"></span> 
                            </span>
                        </h6>
                        <button class="btn btn-sm btn-secondary add-button" data-toggle="modal"
                        data-target="#addStayAttractionModal" @click.prevent="onStayAttraction(index, null)">
                            <span class="icon-add-2 mr-1"></span> Agregar
                        </button>
                    </td>
                    <td v-html="renderTotal(stay)">
                    </td>
                    <td>
                        <div class="btn-group" role="group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-soft btn-sm btn-soft-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Opciones <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                <a class="dropdown-item" data-toggle="modal" data-target="#addStayServiceModal"
                                @click.prevent="onStayService(index, null)">
                                    <span class="icon-pin mr-1"></span> 
                                    Otros Servicios
                                </a>
                                <a class="dropdown-item" data-toggle="modal" data-target="#addStayAttractionModal" 
                                @click.prevent="onStayAttraction(index, null)">
                                    <span class="icon-pin mr-1"></span> Atracciones
                                </a>
                                <div class="dropdown-divider"></div>
                                <!-- <a class="dropdown-item" @click.prevent="duplicateStay(stay)" data-toggle="modal" data-target="#modalLoading">
                                    <span class="icon-copy mr-1"></span> Duplicar
                                </a> -->
                                <a class="dropdown-item" data-toggle="modal" data-target="#addPackageHotelStay"
                                @click.prevent="onEditStay(index)">
                                    <span class="icon-pencil-edith mr-1"></span> 
                                    Editar
                                </a>
                                <a class="dropdown-item" @click.prevent="onDeleteStay(stay, index)">
                                    <span class="icon-bin mr-1"></span>
                                    Eliminar
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="stays.length==0">
                    <th colspan="9">Ninguna estadia registrada...</th>
                </tr>
            <!--  -->
        </tbody>
        </table>
    </div>
</template>
<script>

export default {
    props: [
        "stays",
        ],
    data() {
        return {
            // formularios para duplicar
            formHotelStay: new WebForm('#formHotelStay', {
                id                      : null,
                package_hotel_id        : null,
                room                    : null,
                cant_room               : 1,
                room_id                 : '',
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                n_people                : 1,
                cost                    : 0,
                taxes                   : 0,
                type_utility            : 2,
                utility                 : 0,
                description             : '',
                type_refund             : 1,
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
                total                   : 0,
                link                    : '',
                simbol                  : '$',
            }),
            formStayService: new WebForm('#formStayService', {
                id                      : null,
                package_stay_id         : null,
                package_service         : null,
                package_service_id      : '',
                cant_service            : 1,
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                cost                    : 0,
                taxes                   : 0,
                type_utility            : 2,
                utility                 : 0,
                link                    : '',
                description             : '',
                simbol                  : '$',
                is_free                 : null,
                non_date                : null,
                type_refund             : 1,
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
            }),
            formStayAttraction: new WebForm('#formStayAttraction', {
                id                      : null,
                package_stay_id         : null,
                attraction              : null,
                attraction_id           : '',
                tickets                 : 1,
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                cost                    : 0,
                taxes                   : 0,
                type_utility            : 2,
                utility                 : 0,
                link                    : '',
                description             : '',
                simbol                  : '$',
                is_free                 : null,
                non_date                : null,
                type_refund             : 1,
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
            }),
        };
    },
    methods: {
        renderTotal(stay) {
            let cost= 0;
            let taxes = 0;
            let utility = 0;
            let total = 0;
            let card = 0;
            let cash = 0;
            let refund = 0;
            cost    += parseFloat(stay.cost);
            taxes   += parseFloat(stay.taxes);
            card    += parseFloat(stay.card_amount);
            cash    += parseFloat(stay.cash_amount);
            refund  += parseFloat(stay.refund);
            utility += parseFloat(stay.utility);
            total = parseFloat(cost) + parseFloat(taxes) + parseFloat(utility);

            stay.package_stay_service.forEach(element => {
                cost    += parseFloat(element.cost);
                taxes   += parseFloat(element.taxes);
                card    += parseFloat(element.card_amount);
                cash    += parseFloat(element.cash_amount);
                refund  += parseFloat(element.refund);
                utility += parseFloat(element.utility);
                total = parseFloat(cost) + parseFloat(taxes) + parseFloat(utility);
            });

            stay.package_stay_attraction.forEach(element => {
                cost    += parseFloat(element.cost);
                taxes   += parseFloat(element.taxes);
                card    += parseFloat(element.card_amount);
                cash    += parseFloat(element.cash_amount);
                refund  += parseFloat(element.refund);
                utility += parseFloat(element.utility);
                total = parseFloat(cost) + parseFloat(taxes) + parseFloat(utility);
            });

            return `<div class="row">
                        <div class="col-md-6">Costo: </div>
                        <div class="col-md-6 text-right">$ ${cost}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">Impuestos: </div>
                        <div class="col-md-6 text-right"><span class="text-danger">$ ${ taxes }</span></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">Utilidad:</div>
                        <div class="col-md-6 text-right"><span class="text-success">$ ${ utility }</span></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">Total: </div>
                        <div class="col-md-6 text-right"><span class="text-primary">$ ${ total }</span></div>
                    </div>
                    <hr style="margin: 5px 0;">
                    <div class="row">
                        <div class="col-md-6">Tarjeta: </div>
                        <div class="col-md-6 text-right"><span class="text-secondary">$ ${ card }</span></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">Efectivo: </div>
                        <div class="col-md-6 text-right"><span class="text-secondary">$ ${ cash }</span></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">Reembolso: </div>
                        <div class="col-md-6 text-right"><span class="text-secondary">$ ${ refund }</span></div>
                    </div>`;
        },
        // Para StayService
        onStayService(indexStay, indexService) {
            let vm = this;
            vm.$emit('clickStayService', {indexStay, indexService });
        }, 
        // 
        // Para StayAttraction
        onStayAttraction(indexStay, indexAttraction) {
            let vm = this;
            vm.$emit('clickStayAttraction', {indexStay, indexAttraction });
        },
        // 
        addPackageHotelStay(hotelPack, stayId) {
            // this.$emit('addPackageHotelStay', { hotelPack, stayId });
        },
        viewGalleryAttraction(id) {
            console.log('Desde staycomponent: ' + id);
            this.$emit('viewGalleryAttraction', id);
        }, 
        onEditStay(index) {
            let vm = this;
            vm.$emit('editPackageHotelStay', index);
        },
        onDeleteStay(stay, index) {
            let vm = this
            
            $.confirm.delete(
                'Eliminar Estadia',
                `¿Está seguro que desea eliminar la estadia (${index})<br> del <b>${stay.start_date} al ${stay.end_date}</b>?`, 
                function(){
                    vm.deleteStay(stay.id, index)
                }
            )
        },
        deleteStay(idDel, index) {
            let vm = this;
            axios.post('/admin/packages/hotels/stays/delete', { id: idDel }).then(response => {
                let data = response.data;
                if(data.success) {
                    vm.$snotify.success('La estadia ha sido eliminada');
                    vm.$emit('deletePackageHotelStay', index);
                    return
                } 
                
                vm.$snotify.info('Se ha enviado solicitud') 

            }).catch(err => console.log(err));
        },
        duplicateStay(stay) {
            let vm = this;
            vm.formHotelStay.fill(stay);
            vm.formHotelStay.start_hour             = stay.start_hour.substring(0, 5);
            vm.formHotelStay.end_hour               = stay.end_hour.substring(0, 5);
            vm.formHotelStay.id = null;

            vm.formHotelStay.post('/admin/packages/hotels/stays/save').then(async response => {
                let data = response.data;
                if(!data.success) {
                    vm.$snotify.error('Ha ocurrido un error');
                    setTimeout(() => {
                        $('#modalLoading').modal('hide');
                    },300);
                    vm.formHotelStay.clear();
                    vm.formHotelStay.reset();
                    return;
                }

                vm.$snotify.success('Estadia duplicada con exito.');

                if(stay.package_stay_service.length>0 || stay.package_stay_attraction.length>0 ) {
                    
                    await new Promise((resolve, reject) => { 

                        let promises = [];

                        for (let index = 0; index < stay.package_stay_service.length; index++) {
                            const myService = stay.package_stay_service[index];
                            vm.formStayService.fill(myService);
                            vm.formStayService.package_stay_id = data.success.id;
                            vm.formStayService.start_hour   = (myService.start_hour) ? myService.start_hour.substring(0, 5) : null ;
                            vm.formStayService.end_hour     = (myService.end_hour) ? myService.end_hour.substring(0, 5) : null ;
                            vm.formStayService.id = null;
                            vm.formStayService.post('/admin/packages/hotels/services/save').then(response => { 
                                vm.formStayService.clear();
                                vm.formStayService.reset();
                            });
                            promises.push(process);
                            if((index + 1) == stay.package_stay_service.length) {
                                vm.$snotify.success('Servicios duplicados con exito.');
                            }
                        }

                        for (let index = 0; index < stay.package_stay_attraction.length; index++) {
                            const myAttraction = stay.package_stay_attraction[index];
                            vm.formStayAttraction.fill(myAttraction);
                            vm.formStayAttraction.package_stay_id = data.success.id;
                            vm.formStayAttraction.start_hour   = (myAttraction.start_hour) ? myAttraction.start_hour.substring(0, 5) : null ;
                            vm.formStayAttraction.end_hour     = (myAttraction.end_hour) ? myAttraction.end_hour.substring(0, 5) : null ;
                            vm.formStayAttraction.id = null;
                            vm.formStayAttraction.post('/admin/packages/hotels/attractions/save').then(response => { 
                                vm.formStayAttraction.clear();
                                vm.formStayAttraction.reset();
                            });
                            promises.push(process);
                            if((index + 1) == stay.package_stay_attraction.length) {
                                vm.$snotify.success('Atracciones duplicados con exito.');
                            }
                        }

                        
                        vm.$snotify.success('Accion realizada con exito.');
                        setTimeout(() => {
                            vm.$emit('reloadStays');
                            $('#modalLoading').modal('hide');
                        },300);
                        vm.formHotelStay.clear();
                        vm.formHotelStay.reset();
                        return;
                        
                        Promise.all(promises).then(function(values) { 
                            resolve();
                        });

                    });

                } else {
                    vm.$snotify.success('Accion realizada con exito.');
                    setTimeout(() => {
                        vm.$emit('reloadStays');
                        $('#modalLoading').modal('hide');
                    },300);
                    vm.formHotelStay.clear();
                    vm.formHotelStay.reset();
                    return;
                }

            }).catch(() => {
                vm.$snotify.error('Ha ocurrido un errorxxxxx');
                setTimeout(() => {
                    $('#modalLoading').modal('hide');
                },300);
                return;
            });
        },
    },
    mounted() {
        let vm = this;

        console.log('PackageHotelStayComponent mounted.');
    }
}
</script>
<style scoped>
    .option-details {
        position: relative;
    }
    .option-details:hover .stay-options {
        opacity: 1;
        top: 10px;
    }
    .option-details .stay-options {
        position: absolute;
        right: 0px;
        top: 20px;
        opacity: 0;
        transition: 0.3s;
    }

    .view-service {
        position: relative;
    }

    .view-service:hover .add-button {
        opacity: 1;
        margin-top: 10px;
    }
    .view-service .add-button {
        position: static;
        display: inline-block;
        margin-top: 20px;
        opacity: 0;
        transition: 0.3s;
    }
</style>