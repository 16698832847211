<template>
    <div>
        <div class="modal fade" id="modalAttraction">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formAttraction" autocomplete="off" @submit.prevent="onSaveAttraction()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="Attraction && Attraction.btn_i18n == true">Traducción de Atraccion Turistica</span>
                                <span v-else-if="formAttraction.id">
                                    Editar Atraccion Turistica
                                </span>
                                <span v-else>Nueva Atraccion Turistica</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12 col-md-12">
                                    <label for="name">Nombre Atracción</label>
                                    <input type="text" class="form-control" ref="name" placeholder="Ingrese Nombre" v-model="formAttraction.name" name="name">
                                </div>
                            </div>
                            <div class="row" v-show="!(Attraction && Attraction.btn_i18n == true)">
                                <div class="form-group col-12 col-md-6">
                                    <label for="attraction_category_id" style="width: 100%">Categoria de Atraccion  
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addAttractionCategory">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="attraction_category" label="name"
                                    name="attraction_category_id" ref="vSelectCategory" 
                                    @keypress.enter.native.prevent="" selectOnTab 
                                    placeholder="Seleccione Categoria" :options="attraction_categories">
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="country_id" class="required">Pais</label>
                                    <v-select v-model="country" name="country_id" 
                                    @search="searchCountry" ref="vSelectCountry" 
                                    placeholder="Seleccione Pais" selectOnTab
                                    :options="countries" required="required">
                                        <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="state_id" class="required" style="width: 100%">Estado
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addState">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select autocomplete="off"  v-model="state" name="state_id" ref="vSelectState" placeholder="Seleccione Estado" :options="states"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="city_id" class="required" style="width: 100%">Ciudad
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addCity">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="city" name="city_id" ref="vSelectCity" placeholder="Seleccione Ciudad" :options="cities"></v-select>
                                </div>
                            </div>
                            <div class="row" v-show="!(Attraction && Attraction.btn_i18n == true)">
                                <div class="form-group col-12">
                                    <label :for="'address-'+_uid" style="width: 100%">Dirección </label>
                                    <input type="text" class="form-control map-input" placeholder="Ingrese Dirección" v-model="formAttraction.address" name="address">
                                    <input type="hidden" name="latitude" :id="'address-' + _uid + '-latitude'" v-model="formAttraction.latitude" />
                                    <input type="hidden" name="longitude" :id="'address-' + _uid + '-longitude'" v-model="formAttraction.longitude" />
                                </div>
                                <div id="address-map-container" class="form-group col-12" style="width:100%;height:200px; ">
                                    <div style="width: 100%; height: 100%" :id="'address-' + _uid + '-map'"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12" v-show="(Attraction && Attraction.btn_i18n == true)">
                                    <label>Dirección </label>
                                    <input type="text" class="form-control" placeholder="Ingrese Dirección" v-model="formAttraction.address">
                                </div>
                                <div class="form-group col-12">
                                    <label for="features">Caracteristicas</label>
                                    <v-select name="features" id="features" taggable 
                                    v-model="formAttraction.featuresObj"
                                    ref="vSelectFeatures" placeholder="Ingrese caracteristicas" 
                                    multiple @keypress.enter.native.prevent="" push-tags
                                    @option:created="setFocus" >
                                    </v-select>
                                </div>
                                <div class="form-group col-12">
                                    <label for="description">Descripción</label>
                                    <textarea type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formAttraction.description" name="description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-attractions-index'" > 
                                <span v-if="Attraction && Attraction.btn_i18n==true">Guardar Traducción</span>
                                <span v-else-if="formAttraction.id && $can('update','packages-attractions-index')">
                                    Editar
                                </span>
                                <span v-else-if="formAttraction.id && !$can('update','packages-attractions-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { stringify } from 'querystring';
    export default {
        props: {
            Attraction: {
                type : [Object, null],
                default: null,
            },
            AttractionCategoryNew: {
                type: [Object, null],
                default: null,
            },
            StateNew: {
                type: [Object, null],
                default: null,
            },
            CityNew: {
                type: [Object, null],
                default: null,
            },
        },
        components: {
        },
        data() {
            return {
                searchDelay                 : null,
                formAttraction: new WebForm('#formAttraction', {
                    name                    : '',
                    attraction_category_id  : null,
                    description             : null,
                    address                 : null,
                    latitude                : 0,
                    longitude               : 0,
                    featuresObj             : [],
                    features                : [],
                    city_id                 : null,
                    state_id                : null,
                    country_id              : null,
                    id                      : null,
                    btn_i18n                : false,
                    id_i18n                 : null,
                }),
                countries                   : [],
                states                      : [],
                cities                      : [],
                attraction_categories       : [],
                country                     : null,
                state                       : null,
                city                        : null,
                attraction_category         : null,
                autocomplete                : null
            }
        },
        watch: {
            country: function(val, oldVal) {
                this.$refs.vSelectState.clearSelection();
                this.states = [];
                if (!_.isEmpty(this.country)) {
                    this.getStates(this.country.id);
                }
            },
            state: function(val, oldVal) {
                this.$refs.vSelectCity.clearSelection();
                this.cities = [];
                if (!_.isEmpty(this.state)) {
                    this.$refs.vSelectCity.clearSelection();
                    this.getCities(this.state.id);
                }
            },
            AttractionCategoryNew: function(val, oldVal) {
                if (!_.isEmpty(this.AttractionCategoryNew)) {
                    this.attraction_categories.push(this.AttractionCategoryNew);
                    this.attraction_category = this.AttractionCategoryNew;
                }
            },
            StateNew: function(val, oldVal) {
                if (!_.isEmpty(this.StateNew) && this.country && this.country.id) {
                    if (this.StateNew && this.StateNew.id && this.StateNew.country_id == this.country.id) {
                        let state_ = {
                            id: this.StateNew.id,
                            label: `${this.StateNew.name}`,
                            name: `${this.StateNew.name}`,
                        };
                        this.states.push(state_);
                        this.state = state_;
                    }
                }
            },
            CityNew: function(val, oldVal) {
                if (!_.isEmpty(this.CityNew) && this.state && this.state.id) {
                    if (this.CityNew && this.CityNew.id && this.CityNew.state_id == this.state.id) {
                        let city_ = {
                            id: this.CityNew.id,
                            label: `${this.CityNew.name}`,
                            name: `${this.CityNew.name}`,
                        };
                        this.cities.push(city_);
                        this.city = city_;
                    }
                }
            },
            Attraction: function(val, oldVal) {
                let vm = this;
                if (_.isEmpty(vm.Attraction) || vm.Attraction.id == '') {
                    // 
                }else {
                    vm.formAttraction.fill(vm.Attraction);
                    vm.formAttraction.name          = vm.decodeHTML(vm.Attraction.name);
                    vm.formAttraction.description   = vm.decodeHTML(vm.Attraction.description);
                    vm.formAttraction.address       = vm.decodeHTML(vm.Attraction.address);
                    vm.formAttraction.featuresObj   = (vm.formAttraction.features !=null ) ? JSON.parse(vm.decodeHTML(vm.formAttraction.features)) : '';

                    if (this.Attraction.btn_i18n && this.Attraction.i18n.length > 0) {
                        vm.formAttraction.name          = vm.decodeHTML( (vm.Attraction.i18n[0].name) ? vm.Attraction.i18n[0].name : vm.Attraction.name );
                        vm.formAttraction.description   = vm.decodeHTML( (vm.Attraction.i18n[0].description) ? vm.Attraction.i18n[0].description : vm.Attraction.description );
                        vm.formAttraction.address       = vm.decodeHTML( (vm.Attraction.i18n[0].address) ? vm.Attraction.i18n[0].address : vm.Attraction.address );
                        if (vm.Attraction.i18n[0].features != null) {
                            vm.formAttraction.featuresObj   = (JSON.parse(vm.decodeHTML(vm.Attraction.i18n[0].features))) ? JSON.parse(vm.decodeHTML(vm.Attraction.i18n[0].features)) : '';
                        }

                        vm.formAttraction.id_i18n   = vm.Attraction.i18n[0].id;
                    } else {
                        if(vm.Attraction!=null && vm.Attraction.attraction_category_id!="") {
                            vm.attraction_category = vm.attraction_categories.find(c => {
                                return c.id == vm.Attraction.attraction_category_id;
                            });
                        }

                        vm.getCountry(vm.Attraction.city.state.country.id);
                    }
                    console.log(vm.formAttraction);
                }
            },
        },
        methods: {
            setFocus() {
                setTimeout (() => {
                    $(this.$refs.vSelectFeatures.$el).find('.vs__search').focus();
                }, 200);
            },
            // funcion para buscar paises mientras se escribe
            searchCountry(search, loading) {
                if (search.length>2 ) {
                    loading(true);
                    const vm = this;
                    clearInterval(vm.searchDelay);
                    vm.searchDelay = setInterval(function(){
                        clearInterval(vm.searchDelay);
                        axios.get(`/admin/location/countries/${search}`).then((response) => {
                            if (response.data) {
                                let countries = response.data.map(y => {
                                    let country = y;
                                    let d = {
                                        id      :       country.id,
                                        code    :       country.short_iso_code,
                                        name    :       `${country.name} (${country.native_name})`,
                                        label   :       `${country.name} (${country.native_name})` 
                                    }
                                    return d;
                                });
                                vm.countries = countries;
                            } else {
                                console.log('No se pudieron cargar los paquetes');
                            }
                            loading(false);
                        });
                    }, 500);
                }
            },
            getCountry(id) {
                let vm = this;
                axios.get(`/admin/location/countries/${id}`).then((response) => {
                    if (response.data) {
                        let data = response.data;
                        let countries = [{
                            id      :       data.id,
                            code    :       data.short_iso_code,
                            name    :       `${data.name} (${data.native_name})`,
                            label   :       `${data.name} (${data.native_name})` 
                        }];
                        vm.countries = countries;
                        vm.country = vm.countries[0];
                        // vm.getStates(vm.country.id);
                    } else {
                        console.log('No se pudieron cargar los paquetes');
                    }
                });
            },
            getStates(country_id){
                let vm = this;
                let endpoint = `/admin/location/states/country/${country_id}`;
                axios.get(endpoint).then(({data}) => {
                    let states = data.map(state => {
                        let d = {
                            id: state.id,
                            label: `${state.name}`,
                            name: `${state.name}`,
                        }
                        return d;
                    });
                    this.states = states;
                    if(vm.Attraction!=null && vm.Attraction.city.state_id!="") {
                        vm.state = vm.states.find(c => {
                            return c.id == vm.Attraction.city.state_id;
                        });
                    }
                });
            },
            getCities(state_id){
                let vm = this;
                let endpoint = `/admin/location/cities/state/${state_id}`;
                axios.get(endpoint).then(({data}) => {
                    let cities = data.map(city => {
                        let d = {
                            id: city.id,
                            label: `${city.name}`,
                            name: `${city.name}`,
                        }
                        return d;
                    });
                    this.cities = cities;

                    if(vm.Attraction!=null && vm.Attraction.city.id!="") {
                        vm.city = vm.cities.find(c => {
                            return c.id == vm.Attraction.city.id;
                        });
                    }
                });
            },
            async getAttractionCategories(){
                let endpoint = '/admin/attractions/categories/all';
                let {data} = await axios.get(endpoint);
                this.attraction_categories = data;
            },
            onSaveAttraction() {
                let vm = this;
                // vm.formAttraction.address = $('.map-input').val();
                
                if(vm.city) {
                    vm.formAttraction.city_id = vm.city.id;
                }
                if(vm.state) {
                    vm.formAttraction.state_id = vm.state.id;
                }
                if(vm.country) {
                    vm.formAttraction.country_id = vm.country.id;
                }
                if(vm.attraction_category) {
                    vm.formAttraction.attraction_category_id = vm.attraction_category.id;
                }

                vm.formAttraction.features = (this.formAttraction.featuresObj) ? JSON.stringify(this.formAttraction.featuresObj) : null;

                let tipo = (vm.formAttraction.id != null) ? 1 : 2;
                if(this.formAttraction.id != null && this.formAttraction.btn_i18n==false) {
                    $.confirm({
                        title: `Editar Atraccion Turistica`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {

                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (tipo) {
                let vm = this;
                
                vm.formAttraction.post('/admin/attractions/save').then(response => {
                    let data = response.data;

                    if (data.user_request_status) {
                        $('#modalAttraction').modal('hide');
                        return;
                    }
                    
                    let snotify_message = tipo == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);
                    let obj = data.success;

                    vm.$emit('reloadAttraction', obj);
                    $('#modalAttraction').modal('hide');
                    return;
                });
            },
        },
        async mounted() {
           let vm = this

            await vm.getAttractionCategories();

            $('#modalAttraction').off('hidden.bs.modal');
            $('#modalAttraction').on('hidden.bs.modal', function () {
                vm.countries    = [];
                vm.states       = [];
                vm.cities       = [];
                vm.attraction_category    = null;
                vm.country      = null;
                vm.state        = null;
                vm.city         = null;
                vm.formAttraction.clear();
                vm.formAttraction.reset();
                vm.$emit('closeAttractionModal');
            });

            $('form').on('keyup keypress', function(e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            });

            const locationInputs = document.getElementsByClassName("map-input");

            let autocompletes = [];
            
            const geocoder = new google.maps.Geocoder;
            for (let i = 0; i < locationInputs.length; i++) {

                const input = locationInputs[i];
                const fieldKey = 'address-' + this._uid;
                const isEdit = document.getElementById(fieldKey + "-latitude").value != '' && document.getElementById(fieldKey + "-longitude").value != '';
                
                const latitude = parseFloat(document.getElementById(fieldKey + "-latitude").value) || 13.4591376;
                const longitude = parseFloat(document.getElementById(fieldKey + "-longitude").value) || -88.1596901;

                const map = new google.maps.Map(document.getElementById(fieldKey + '-map'), {
                    center: {lat: latitude, lng: longitude},
                    zoom: 17
                });

                const marker = new google.maps.Marker({
                    map: map,
                    position: {lat: latitude, lng: longitude},
                });

                marker.setVisible(isEdit);

                const autocomplete = new google.maps.places.Autocomplete(input);
                autocomplete.key = fieldKey;
                autocompletes.push({input: input, map: map, marker: marker, autocomplete: autocomplete});
            }

            for (let i = 0; i < autocompletes.length; i++) {
                const input = autocompletes[i].input;
                const autocomplete = autocompletes[i].autocomplete;
                const map = autocompletes[i].map;
                const marker = autocompletes[i].marker;

                google.maps.event.addListener(autocomplete, 'place_changed', function () {
                    marker.setVisible(false);
                    const place = autocomplete.getPlace();

                    geocoder.geocode({'placeId': place.place_id}, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            const lat = results[0].geometry.location.lat();
                            const lng = results[0].geometry.location.lng();
                            setLocationCoordinates(autocomplete.key, lat, lng);
                        }
                    });

                    if (!place.geometry) {
                        window.alert("No details available for input: '" + place.name + "'");
                        input.value = "";
                        return;
                    }

                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(17);
                    }
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
                });
            }

            function setLocationCoordinates(key, lat, lng) {
                const latitudeField = document.getElementById(key + "-" + "latitude");
                const longitudeField = document.getElementById(key + "-" + "longitude");
                latitudeField.value = lat;
                longitudeField.value = lng;
                vm.formAttraction.latitude = lat;
                vm.formAttraction.longitude = lng;
                
            }

        }
    }
</script>
<style>
    .pac-container.hdpi{
        z-index: 10000;
    }
    .modal#modalAttraction{
        overflow-y: auto !important;
    }
</style>