<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Zona Horaria</h5>
                    <p class="text-muted mb-1">Lista de Zonas Horarias del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalTimezone" >
                        <span class="icon-add-2 mr-2"></span> Nueva Zona
                    </button>
                </div>
            </div>
            
            <timezone-modal :Timezone="Timezone" v-on:closeTimezoneModal="clearTimezoneVar()" v-on:reloadTimezoneModal="reloadTimezone()"></timezone-modal>

            <data-table :ajax="{ url: '/admin/location/timezones/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="timezoneTable" :serverSide="false" :columns="dt.columns"></data-table>
            
        </div>
    </div>
</template> 
<script>
    import dataTable from '~/components/dataTable'
    import TimezoneModal from '~/components/geodatos/TimezoneModal'

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            TimezoneModal
        },
        metaInfo() {
            return {
                title: "Zonas Horarias del Sistema"
            }
        },
        data() {
            return {
                Timezone: null,
                dt: {
                    columns : [
                        { title: 'ID',          data: 'id' },
                        { title: 'Nombre',      data: 'name' },
                        { title: 'Descripcion', data: 'description' },
                        { title: 'Abreviacion', data: 'abbreviation' },
                        { title: 'offset',      data: 'offset' },
                        { title: 'Opciones',    data: 'id', orderable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#modalTimezone">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            }
        },
        methods: {
            reloadTimezone() {
                this.$refs.timezoneTable.ajaxReload();
            },
            clearTimezoneVar() {
                this.Timezone = null;
            },
            onDelete(timezone) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Zona Horaria',
                    `¿Está seguro que desea eliminar la zona <u>${timezone.name}</u>?`, 
                    function(){
                        vm.deleteItem(timezone.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/location/timezones/delete', { id: idDel }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 

                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.timezoneTable.ajaxReload();
                        return;
                    }

                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            }
        },
        mounted(){ 
            let vm = this

            $(vm.$el).off("click.timezone", ".btn-delete");
            $(vm.$el).on("click.timezone", ".btn-delete",function(){
                let timezone = vm.$refs.timezoneTable.rowData($(this).closest('tr'));
                vm.onDelete(timezone);
            });

            $(vm.$el).off("click.timezone", ".btn-edit");
            $(vm.$el).on("click.timezone", ".btn-edit", function(e){
                let timezone = vm.$refs.timezoneTable.rowData($(this).closest('tr'))
                vm.Timezone = timezone;
            });
        },
    }
</script>