<template>
    <div style="height: 100%">
         <div class="legal-docs-wrapper">
            <div  class="d-flex align-items-center">
                <h5 class="font-weight-bold mb-0">Información Legal</h5>

                <div class="ml-auto">
                    <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newLegalDocsModal" v-can:create>Agregar <span class="icon-add-2"></span></button>
                </div>
            </div>
            <div v-if="loading" class="d-flex flex-wrap" style="height: 100%;">
                <div class="col-12 col-lg-2 py-3">
                    <legal-docs-loader :width_svg="150" :height_svg="150" :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'" />
                </div> 
                <div class="col-12 col-lg-10 panel-border-left py-3 legal-documents-text">
                   Cargando...
                </div>
            </div>
            <template v-else>
                <div v-if="documents.length > 0 " class="section mt-3 section-scrolled flex-grow-1">
                    <div class="d-flex flex-wrap position-relative" style="height: 100%;">
                        <div class="d-lg-block col-12 col-lg-3 py-3" :class="{'d-none': showing}">
                            <label for="name">Seleccione Idioma</label>
                            <select @change="updateShowDocsList" id="documentLanguages" v-if="documentLanguages" class="form-control" v-model="languageView">
                                <template v-for="language in documentLanguages">
                                    <option :value="language.id" :key="language.id">
                                        {{ language.native_name }}
                                    </option>
                                </template>
                            </select>
                            <ul class="menu-list defaul-size">
                                <li v-for="document in showListDocument" :key="'doc-'+document.id" @click="showDocument(document.id)" :class="{'active': document.id == documentActive.id}">
                                    {{ document.title }}
                                </li>
                            </ul>
                        </div> 
                        <div v-if="documentActive" class=" d-lg-block col-12 col-lg-9 panel-border-left py-3 legal-documents-text" :class="{'d-none': !showing}">
                            <header>
                                <h4 class="font-weight-bold">{{ documentActive.title }}</h4>
                            </header>
                            <hr>
                            <div class="html-block" v-html="documentActive.content"></div>
                        </div>
                        <div class="legal-documents-tools  d-lg-block" :class="{'d-none': !showing}">
                            <div class="d-flex">
                                <button @click="showing = false" class="btn btn-primary d-lg-none mx-1" :class="{'d-none': !showing}"><span class="icon-left-arrow"></span></button>

                                <button class="btn btn-light ml-auto mx-1" data-toggle="modal" data-target="#updateLegalDocsModal" v-can:update  @click="onEditDoc"><span class="icon-paper-edit"></span></button>
                                <button class="btn btn-light mx-1" data-toggle="modal" data-target="#translateLegalDocsModal" v-can:update  @click="onTranslateDoc"><span class="icon-translate"></span></button>
                                <button class="btn btn-light text-danger mx-1" v-can:manage @click="onDeleteDoc"><span class="icon-bin"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="section-empty">
                    <div class="text-center">
                        <span class="icon-folder icon"></span>
                        <p class="title my-2">No se ha agregado información legal</p>
                        <div>
                            <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newLegalDocsModal" v-can:create>Agregar <span class="icon-add-2"></span></button>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- Modal Create -->
        <div class="modal fade" id="newLegalDocsModal" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nueva Información Legal</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="legalDocsFrom" @submit.prevent="saveDocument">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Nombre (unico)</label>
                                        <input type="text" name="name" class="form-control bg-white" v-model="form.name">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Seleccione Idioma</label>
                                        <select id="system_language_id" class="form-control" v-can:manage.disable v-model="form.language_id">
                                            <option disabled selected>Seleccione Idioma</option>
                                            <template v-for="system_language in systemLanguages">
                                                <option :value="system_language.id" :key="system_language.id">
                                                    {{ system_language.native_name }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label for="title">Titulo</label>
                                <input type="text" id="title" class="form-control bg-white" v-model="form.title">
                            </div>
                            <div class="form-group editor-height mt-4">
                                <label for="content">Contenido</label>
                                <quill-editor id="content" v-model="form.content" 
                                    ref="editor" 
                                    @blur="onEditorBlur($event)"
                                    @focus="onEditorFocus($event)"
                                    @ready="onEditorReady($event)">
                                </quill-editor>
                            </div>
                            <div class="text-center">
                                <v-button class="center-content ml-auto" :type="'success'" :loading="form.processing">
                                    Guardar <span class="icon-floppy-disk ml-2"></span>
                                </v-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal update -->
        <div class="modal fade" id="updateLegalDocsModal" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar {{ updateForm.title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="legalDocsFormUpdate" @submit.prevent="updateDocument">
                            <div class="form-group">
                                <label for="title">Titulo</label>
                                <input type="text" id="title" class="form-control bg-white" v-model="updateForm.title">
                            </div>
                            <div class="form-group editor-height mt-4">
                                <label for="content">Contenido</label>
                                <quill-editor id="content" v-model="updateForm.content" 
                                    ref="editor" 
                                    @blur="onEditorBlur($event)"
                                    @focus="onEditorFocus($event)"
                                    @ready="onEditorReady($event)"></quill-editor>
                            </div>
                            <div class="text-center">
                                <v-button class="center-content ml-auto" :type="'success'" :loading="updateForm.processing">
                                    Guardar <span class="icon-floppy-disk ml-2"></span>
                                </v-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Translate -->
        <div class="modal fade" id="translateLegalDocsModal" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Traducir {{ translateForm.title }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="legalDocsFormTranslate" @submit.prevent="translateDocument">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Seleccione Idioma</label>
                                        <select @change="translate" id="system_language_id" class="form-control" v-can:manage.disable v-model="translateForm.language_id">
                                            <option disabled selected>Seleccione Idioma</option>
                                            <template v-for="system_language in systemLanguages">
                                                <option v-if="system_language.id != documentActive.language_id" :value="system_language.id" :key="system_language.id">
                                                    {{ system_language.native_name }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="title">Nombre (Unico)</label>
                                        <input type="text" name="name" class="form-control bg-white" v-model="translateForm.name">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="title">Titulo</label>
                                <input type="text" id="title" class="form-control bg-white" v-model="translateForm.title">
                            </div>
                            <div class="form-group editor-height mt-4">
                                <label for="content">Contenido</label>
                                <quill-editor id="content" v-model="translateForm.content" 
                                    ref="editor" 
                                    @blur="onEditorBlur($event)"
                                    @focus="onEditorFocus($event)"
                                    @ready="onEditorReady($event)">
                                </quill-editor>
                            </div>
                            <div class="text-center">
                                <v-button class="center-content ml-auto" :type="'success'" :loading="translateForm.processing">
                                    Guardar <span class="icon-floppy-disk ml-2"></span>
                                </v-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
       
        
    </div>
</template>
<style scoped>
    .legal-docs-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .section-scrolled{
        height: 50%;
    }
    .legal-documents-text {
        height: 100%;
        overflow: auto;
        padding-top: 67px !important;
    }
    .legal-documents-tools{
        position: absolute;
        padding: 0.75rem;
        width: 100%;
        right: 0;
    }
    @media (min-width: 992px) {
        .legal-documents-text { 
            padding-top: 1rem !important;
        }
        .legal-documents-tools{
            width: auto;
        }

    }
</style>

<script>

import vButton from '~/components/button'
import LegalDocsLoader from '~/components/LegalDocsLoader'
import { filter } from 'minimatch'

export default {
    middleware:'auth',
    layout: 'dashboard',
    components: {
        vButton,
        LegalDocsLoader
    },
    data () {
        return {
            languageView: null,
            showing: false,
            form: new WebForm("#legalDocsFrom", {
                    name                : '',
                    title               : '',
                    content             : '',
                    language_id  : ''
            }),
            updateForm:  new WebForm('#legalDocsFormUpdate', {
                    id      : '',
                    title   : '',
                    content : ''
            }),
            translateForm:  new WebForm('#legalDocsFormTranslate', {
                    id      : '',
                    name    : '',
                    title   : '',
                    content : '',
                    language_id  : ''
            }),
            systemLanguages: [],
            loading: true,
            documents: [],
        }
    },
    computed: {
        documentActive () {
            let document = this.documents.find(item => {
                return item.show == true
            })
            
            if(document) {
                return document
            }
            return false
        },
        showListDocument () {
            let vm = this
            let documents = this.documents.filter(doc => {
                return doc.language_id == vm.languageView
            })
            return documents
        },
        documentLanguages(){
            let languages = _.uniq(this.documents.map((item) => item.language_id))
            let filters   = this.systemLanguages.filter(e => {
                if(languages.indexOf(e.id) !== -1)
                    return e
            })

            if(filters.length > 0)
                this.languageView = filters[0].id

            return filters;
        }
    },
    watch: {
        // documentActive (newData, oldData) {
        //     if(newData) {
        //         // UpdateForm
        //         this.updateForm.id = newData.id
        //         this.updateForm.title = newData.title
        //         this.updateForm.content = newData.content
            
        //         // TranslateForm
        //         let name = newData.name.split('_')
        //         this.translateForm.id = newData.id
        //         this.translateForm.name = name[0]
        //         this.translateForm.title = newData.title
        //         this.translateForm.content = newData.content
        //         this.translateForm.language_id = newData.language_id
        //     }
        // },
        // showListDocument (newData, oldData) {
        //     if(this.documentActive) {
        //         this.documentActive.show = false
        //     }
        //     newData[0].show = true
        // }
    },
    methods: {
        onEditDoc(){
            this.updateForm.id      = this.documentActive.id
            this.updateForm.title   = this.documentActive.title
            this.updateForm.content = this.documentActive.content
        },
        onTranslateDoc(){
            let name                        = this.documentActive.name.split('_')
            this.translateForm.id           = this.documentActive.id
            this.translateForm.name         = name[0]
            this.translateForm.title        = this.documentActive.title
            this.translateForm.content      = this.documentActive.content
            this.translateForm.language_id  = this.documentActive.language_id
        },
        onDeleteDoc () {
            let vm = this
            $.confirm.delete(
                `Eliminar Información Legal`,
                `Seguro quiere Información Legal? ${vm.documentActive.title}`,
                function(){
                    vm.deteleDoc()
                }
            )
        },
        deteleDoc () {
            let vm = this
            let endpoint = `/admin/contents/support/documents/delete`
            axios.post(endpoint, vm.documentActive).then(response => {
                let document_index = vm.documents.findIndex(doc => {
                    return doc.id == response.data.id
                })

                if(document_index >= 0) {
                    vm.documents.splice(document_index, 1)
                }

                vm.documents[0].show = true
                vm.languageView = vm.documentActive.language_id

            })
        },
        updateShowDocsList () {
            if(this.documentActive) {
                this.documentActive.show = false
            }
            if(this.showListDocument.length) {
                this.showListDocument[0].show = true
            }
        },
        getSystemLanguages () {
            let endpoint = '/admin/languages/system'
            axios.get(endpoint).then(({data}) => {
                this.systemLanguages = data
                if(this.languageView !== null) {
                    return
                }
                this.languageView = this.systemLanguages[0].id
            })
        },
        showDocument (id) {
            this.showing = true
            if(this.documentActive.id == id) {
                window.history.pushState(null, null, '/contents/legal/' + this.documentActive.name);
                return
            }

            if(this.documentActive) {
                this.documentActive.show = false 
            }

            let document = this.documents.find(item  => {
                return item.id == id
            })
            window.history.pushState(null, null, '/contents/legal/' + document.name);
            document.show = true
        },
        translateDocument () {
            let vm = this
            let endpoint = '/admin/contents/support/documents/create'
            this.translateForm.post(endpoint).then(response  => {
                response.data.show = false
                vm.documents.push(response.data)
                
                setTimeout(function () {
                    if(vm.documentActive) {
                        vm.documentActive.show = false 
                    } 
                    vm.documents[vm.documents.length-1].show = true
                    vm.languageView = vm.documents[vm.documents.length-1].language_id
                }, 300)
                vm.showing = true
                vm.form.reset()
                let $modal = $('#translateLegalDocsModal')
                $modal.modal('hide')
               
            })
        },
        updateDocument () {
            let vm = this
            let endpoint = '/admin/contents/support/documents/update'
            this.updateForm.post(endpoint).then(response => {
                if(response.data) {
                    let doc = vm.documents.find(d => {
                        return d.id == vm.updateForm.id
                    })

                    if(!doc) {
                        return
                    }

                    doc.title = vm.updateForm.title
                    doc.content = vm.updateForm.content

                    let $modal = $('#updateLegalDocsModal')
                    $modal.modal('hide')
                }
            })
        },
        saveDocument () {
            let vm = this
            let endpoint = '/admin/contents/support/documents/create'

            this.form.post(endpoint).then(response  => {
                response.data.show = false
                vm.documents.push(response.data)
                

                setTimeout(function () {
                    if(vm.documentActive) {
                        vm.documentActive.show = false 
                    } 
                    vm.documents[vm.documents.length-1].show = true
                    vm.languageView = vm.documents[vm.documents.length-1].language_id
                }, 300)
                vm.showing = true
                vm.form.reset()
                let $modal = $('#newLegalDocsModal')
                $modal.modal('hide')
               
            })
        },
        getDocuments () {
            let vm = this
            let endpoint = '/admin/contents/support/documents/all'
            let name = this.$route.params.name
            axios.get(endpoint)
                .then(response => {
                    response.data.forEach( (item, index) => {

                        item.show = false
                        if(name) {
                            if (name ==  item.name) {
                                item.show = true
                                vm.languageView = item.language_id
                            }
                            vm.showing = true
                        } else if(index == 0) {
                            item.show = true
                        } 
                        vm.documents.push(item)
                    });
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                })
        },
        onEditorBlur () {

        }, 
        onEditorFocus () {

        },
        onEditorReady () {

        },
        translate () {
            let vm = this
            let lang_from = this.systemLanguages.find(l => {
                return l.id == vm.documentActive.language_id
            })

            let lang_to = this.systemLanguages.find(l => {
                return l.id == vm.translateForm.language_id
            })
            if(!(lang_from && lang_to)) {
                console.warn('Lenguages no especificado')
            }
            let lang = `${lang_from.short_iso_code}-${lang_to.short_iso_code}`.toLowerCase()

            let $content = $(`<div>${this.documentActive.content}</div>`)

            window.YandexTranslate.translate({lang: lang, text: this.documentActive.name}, function (text) {
                vm.translateForm.name = text
            })

            window.YandexTranslate.translate({lang: lang, text: this.documentActive.title}, function (text) {
                vm.translateForm.title = text
            })

            $content.children().each(async function(item) {
                let _this = this
                let text = this.textContent
                await window.YandexTranslate.translate({lang: lang, text: text}, function (text) {
                    _this.textContent = text
                    vm.translateForm.content = $content.html()
                })
            })
        },
    },
    async mounted () {
        await this.getDocuments()
        await this.getSystemLanguages()
    }
}
</script>