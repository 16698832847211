import vue from 'vue'
import '~/plugins'
require('./chat.js')

import router  from '~/router'
import store   from '~/store'
// import i18n    from '~/lang'

/* Helpers */
import interceptor from '~/helpers/axiosInterceptor'

/* Intercept all axios responses & validate errors */
interceptor.interceptResponse(vue)

vue.config.productionTip = false

vue.directive('copy', {
    // When the bound element is inserted into the DOM...
    inserted: function (el, binding, vnode) {
        let $target = document.getElementById(binding.value)
       
        el.classList.add('position-relative', 'pointer')
        el.addEventListener('click', function () {
            let tag = document.createElement('span')
                tag.innerText = 'Copiado'
                tag.classList.add('tag-copy')
            var range = document.createRange();
            if(binding.value) {
                el.appendChild(tag)
                range.selectNode($target);
            } else {
                el.parentElement.classList.add('position-relative')
                el.parentElement.appendChild(tag)
                range.selectNode(el);
            }
            var audio = new Audio('/mp3/popup.mp3');
            audio.volume = 0.15;
            audio.play();
            window.getSelection().removeAllRanges(); // clear current selection
            window.getSelection().addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection().removeAllRanges();// to deselect
            tag.classList.add('tag-copy-end')
            interval = setTimeout(function () {
                tag.remove()
            }, 1500)
        })
    }
})

/* Helpers */
vue.mixin({
    data(){
        return {
            timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
    },
    methods: {
        baseName(){
            return this.$route.name || this.$options.name
        },
        defaultImg (event) {
            let img = event.target
            
            if (!img.complete) {
                img.src = '/img/none.png'
                return false;
            }
            if (img.naturalWidth === 0) {
                img.src = '/img/none.png'
                return false;
            }
            return true;
        },
        decodeHTML (html) {
            var txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        imgNotFound(){
            return `this.src = '/img/none.png'`;
        },
        clearForm(form){
            Object.keys(form).forEach(function(key, index) {
                let val = form[key]
                if(typeof val === 'string')
                    form[key] = ''
                
                if(typeof val === 'number')
                    form[key] = ''

                if(typeof val === 'object')
                    form[key] = val instanceof Array ? [] : {}
                
                if(typeof val === 'boolean')
                    form[key] = false
            });
        },
        setFormErrors(exception, form){
            let error = (typeof exception.response === 'undefined') ? exception.data.error : exception.response.data.error
            error = error || {}
            
            form.errors = (typeof error === 'string') ? [error] : error
            return (typeof form.errors !== 'undefined' && form.errors != null && ((form.errors instanceof Array && form.errors.length > 0) || ((typeof form.errors === 'object' && !(form.errors instanceof Array)) && Object.keys(form.errors).length > 0)))
        }
    },
})

import app from './app.vue'
const vm = new vue({
    // i18n,
    store,
    router,
    ...app
})

window.$vm = vm

if (module.hot) {
    // module.hot.accept() // already had this init code 

    module.hot.addStatusHandler(status => {
        // console.log('EVENT =>', status)

        //Solucion bug modales bootstrap
        if(status === 'idle' && $('body').hasClass('modal-open')){
            setTimeout(() => {
                if($('.modal.show').length > 0)
                    return

                $('body').removeClass('modal-open')
                $('.modal-backdrop').remove()
            }, 200);
        }
    })
}

let $APP = $('#app')
$APP.on('click', '[data-toggle-class]', function () {
    let $this = $(this)
    let params = $this.data('toggle-class').replace(/\s/g, "")
    let group = params.split('\|')
    
    group.forEach(g => {
        let params = g.split('\/')
        let _c = params[0].split('\,')
        let _t = params[1].split('\,')
        _t.forEach(t => {
            $(t).toggleClass(_c.join(' '))
        })
    });

})

$APP.on('click', '[data-remove-class]', function () {
    let $this = $(this)
    let params = $this.data('remove-class').replace(/\s/g, "")
    let group = params.split('\|')
    
    group.forEach(g => {
        let params = g.split('\/')
        let _c = params[0].split('\,')
        let _t = params[1].split('\,')
        _t.forEach(t => {
            $(t).removeClass(_c.join(' '))
        })
    });

})

$APP.on('click', '[data-add-class]', function () {
    let $this = $(this)
    let params = $this.data('add-class').replace(/\s/g, "")
    let group = params.split('\|')
    
    group.forEach(g => {
        let params = g.split('\/')
        let _c = params[0].split('\,')
        let _t = params[1].split('\,')
        _t.forEach(t => {
            $(t).addClass(_c.join(' '))
        })
    });

})