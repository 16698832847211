<template>
     <!-- Busqueda -->
    <div class="popup-search" v-bind:class="{ 'active': showSearch}">

        <div class="search-box">
            <div class="close-search" v-on:click.prevent="close">
                <span class="icon-close font-weight-bold"></span>
            </div>
            <div class="input-search">
                <span class="icon-search"></span>
                <input class="m-0" type="text" @keyup="onSearch" @keydown.up.prevent="selectResult" @keydown.enter.prevent="selectResult" @keydown.down.prevent="selectResult" v-model="query" name="" id="search-popup-input" placeholder="Realizar busqueda...">
            </div>
        </div>
        <div id="search-results" class="search-result">
            <div v-show="searchLoading">
                <div class="px-3 py-1 d-flex align-items-center">
                    <span class="loading"></span> <span class="ml-2"> Buscando... </span>
                </div>
            </div>
            <div v-show="searchError.show">
                <div class="px-3 py-1">
                    <span class="icon-emoji-sad search-results-icon-error"></span> <span class="ml-2"> {{ searchError.error }} </span>
                </div>
            </div>
            <div v-for="(type, index) in results" :key="index">
                <h5 class="search-result-title px-3 py-1">{{type.type}}</h5>
                <ul class="search-result-list">
                    <li class="search-result-item" v-for="(item, index) in type.items" :key="index" @click="addLatestSearch(item)">
                        <router-link  class="d-flex align-items-center px-3 py-2 search-result-link" :to="item.url">
                            <img v-if="item.title.img" class="search-result-img mr-2" :src="item.title.img || '/img/none.png'" alt="">
                            <div class="search-result-info mr-2 d-flex">
                                <p class="mr-2">{{ item.title.alias }} <b>{{ item.title.title }}</b></p>
                            </div>
                            <span class="search-result-tag" :class="`search-result-tag-${item.type}`">{{item.type}}</span>
                        </router-link>
                        
                    </li>
                </ul>
            </div>
            <p class="m-0 small text-muted px-3 py-1"><span class="ico-clock-small" style="vertical-align: middle;"></span> Busquedas recientes</p>
            <div class="search-info">
                <ul class="search-result-list">
                    <li class="search-result-item" v-for="(item, index) in latestSearch" :key="index" @click="addLatestSearch(item)">
                        <router-link :to="item.url" class="d-flex align-items-center px-3 py-2 search-result-link" >
                            <img v-if="item.title.img" class="search-result-img mr-2" :src="item.title.img || '/img/none.png'" alt="">
                            <div class="search-result-info mr-2 d-flex">
                                <p class="mr-2">{{ item.title.alias }} <b>{{ item.title.title }}</b></p>
                            </div>
                            <span class="search-result-tag" :class="`search-result-tag-${item.type}`">{{item.type}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>
<script>
const CancelToken = axios.CancelToken;
export default {
    props: ['showSearch'],
    data() {
        return {
            query: "",
            searchInterval: null,
            searchLoading: false,
            searchError: {
                show: false,
                error: ''
            },
            searchCancel: null,
            results: [
                
            ],
            latestSearch: []
        }
    },
    
    mounted () {
        let local = JSON.parse(localStorage.getItem('latestSearch'))
        if(local) {
            this.latestSearch = local
        }
    },
    updated () {
        if(this.showSearch) {
            $('#search-popup-input').focus()
        }
    },
    methods: {
        selectResult (event) {
            let vm = this
            let direction = event.key
            let $resultsList = $('#search-results')
            let $childFocus = $resultsList.find('li.focus')
            let indexChildFocus = $resultsList.find('li').index($childFocus)
            if($childFocus[0] && direction == 'Enter') {
                $childFocus[0].dispatchEvent(new Event('click'))
                let href = $childFocus.find('a').attr('href')
                this.$router.push(href)
                return
            }

            if(!$childFocus[0]) {
                $resultsList.find('li').first().addClass('focus')
            } else {
                $childFocus.removeClass('focus')
                let $next_child =  $($resultsList.find('li')[indexChildFocus + 1])

                if(direction == 'ArrowUp') {
                    $next_child = $($resultsList.find('li')[indexChildFocus - 1])
                }

                if($next_child[0]) {
                    $next_child.addClass('focus')
                } 
                else {
                    if(direction == 'ArrowUp') {
                        $resultsList.find('li').last().addClass('focus')
                    } else {
                        $resultsList.find('li').first().addClass('focus')
                    }
                }
            }

        },
        close() {
            this.$emit('closeSearch', false)
        },
        addLatestSearch(item) {
            let local = JSON.parse(localStorage.getItem('latestSearch'))
            this.close()
            if(!local) {
                let data = [item]
                localStorage.setItem('latestSearch', JSON.stringify(data))
                this.latestSearch = data
                return
            }
            let existInLocal = local.findIndex(element => {
                return item.type == element.type && item.title.id == element.title.id
            })
            if(existInLocal >= 0) {
                local.splice(existInLocal, 1)
            }
            if(local.length > 4) {
                local.splice(-1,1)
            }
            local.unshift(item)
            localStorage.setItem('latestSearch', JSON.stringify(local))
            this.latestSearch = local
            return

        },
        onSearch (e) {
            let prevenetCode = [37,38,39,40,13]

            if(prevenetCode.indexOf(e.keyCode) >= 0) {
                return
            }
            this.searchLoading = true
            this.searchError.show = false,
            clearInterval(this.searchInterval)
            this.searchInterval = setInterval(() => {
                this.search()
            }, 1000)
        },
        async search () {
            let vm = this
            clearInterval(this.searchInterval)
            if(vm.searchCancel != null) {
                vm.searchCancel()
            }
            let endpoint = '/admin/search'
            await axios.get(`${endpoint}/${this.query}`, {
                 cancelToken: new CancelToken(function executor(c) {
                    vm.searchCancel = c;
                })
            })
            .then(response => {
                vm.searchLoading = false
                let data = response.data
                if(data.length > 0) {
                    this.results = []
                    data.forEach(element => {
                        element.title = JSON.parse(element.title)
                        let typeFind = this.results.find(item => {
                            return item.type  === element.type
                        }) 
                        if(typeFind) {
                            typeFind.items.push(element)
                            return
                        } 
                        let data = {
                            type: element.type,
                            items: [
                                element
                            ] 
                        }
                        this.results.push(data)
                    });
                    this.results.sort((a, b) => a.items.length - b.items.length);
                    return
                }
                vm.searchError.show = true
                vm.searchError.error = 'No encontramos lo que buscas...'
            })
        }
    }
}
</script>
