<template>
    <div>
        <div class="modal fade" @submit.prevent="onStayService()" id="addStayServiceModal">
            <div v-if="Stay" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formStayService" autocomplete="off"  novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="Stay.package_service!=null">
                                    Editar servicio en estadia
                                    <br><small>Del <u>{{Stay.start_date}} al {{Stay.end_date}}</u></small>
                                </span>
                                <span v-else>Agregar servicios a estadia
                                    <br><small>Del <u>{{Stay.start_date}} al {{Stay.end_date}}</u></small>
                                </span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!--  -->
                                <div class="form-group col-12 col-md-6">
                                    <label for="package_service_id"  style="width: 100%">Servicio
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addService">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="package_service" @keypress.enter.native.prevent="" selectOnTab
                                    name="package_service_id" ref="vSelectServiceId" placeholder="Servicio" :options="package_services"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label for="cant_service">Cantidad</label>
                                    <input type="number" name="cant_service" min="1" max="10" class="form-control" v-model="formStayService.cant_service">                     
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="formStayService.non_date" name="non_date" class="pt-2 btn-add-aa">
                                        Sin fechas
                                    </label>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="formStayService.is_free" name="is_free" class="pt-2 btn-add-aa">
                                        Sin Costo
                                    </label>  
                                </div>
                                <!--  -->
                                <div v-show="!formStayService.non_date" class="form-group col-12 col-md-3">
                                    <label for="start_date">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="formStayService.start_date" name="start_date"></vue-datepicker>
                                </div>
                                <div v-show="!formStayService.non_date" class="form-group col-12 col-md-3">
                                    <label for="start_hour">Hora de Ingreso</label>
                                    <vue-timepicker name="start_hour" v-model="formStayService.start_hour_a" placeholder="Hora de Ingreso" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div v-show="!formStayService.non_date" class="form-group col-12 col-md-3">
                                    <label for="end_date">Fecha de Salida</label>
                                    <vue-datepicker v-model="formStayService.end_date" name="end_date"></vue-datepicker>
                                </div>
                                <div v-show="!formStayService.non_date" class="form-group col-12 col-md-3">
                                    <label for="end_hour">Hora de Salida</label>
                                    <vue-timepicker name="end_hour" v-model="formStayService.end_hour_a" placeholder="Hora de Salida" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <!--  -->
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayService.cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayService.taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                    <label for="link">Enlace</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="formStayService.link" name="link">
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayService.utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                    <label for="total">Precio total del servicio</label>
                                    <vue-numeric currency="$" class="form-control" separator="," disabled
                                    :min="0" :max="99999" :empty-value="0" placeholder="" :value="priceTotal"
                                    :precision="2" :decimal-separator="'.'"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-4">
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-8">
                                    <label for="description" class="w-100">
                                        <span>Descripción</span>
                                        <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                            <label class="btn btn-sm" :class="formStayService.i18n ? 'btn-light' : 'btn-success'" @click="formStayService.i18n=false">
                                                <input type="radio"> Español
                                            </label>
                                            <label class="btn btn-sm " :class="!formStayService.i18n ? 'btn-light' : 'btn-success'" @click="formStayService.i18n=true">
                                                <input type="radio"> Ingles
                                            </label>
                                        </div>
                                    </label>
                                    <textarea v-show="!formStayService.i18n" type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formStayService.description" name="description" ></textarea>
                                    <textarea v-show="formStayService.i18n" type="text" class="form-control" rows="4" placeholder="Enter Description" v-model="formStayService.description_i18n" name="description" ></textarea>
                                </div>
                                <div class="form-group col-4">
                                    <label for="description">Imagen (Opcional)</label>
                                    <image-display :images="formStayService.last_image" :clearAll="clearDisplayImage"
                                    :urlDelete="'/admin/packages/hotels/services/delimg'" v-on:imageErased="imageErased"></image-display>
                                    <file-image v-if="formStayService.last_image==null" v-on:setGalery="getImage" :nImgs="1" :clearAll="clearFileImage"></file-image>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayService.is_free" class="form-group col-12" style="margin-top: 12px; margin-bottom: 5px;">
                                    <h5>
                                        <span class="badge badge-warning">
                                            Colocar monto que se cobrara con tarjeta; Y efectivo a cancelar en el lugar de servicio
                                        </span>
                                    </h5>
                                </div>
                                <!--  -->
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-3">
                                    <label for="card_amount">Pago Tarjeta</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model="formStayService.card_amount"
                                    :min="0" :max="99999" :empty-value="0"
                                    :precision="2" :decimal-separator="'.'" id="formStayService__card_amount"
                                    name="card_amount"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-3">
                                    <label for="cash_amount">Restante en Efectivo</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="cashTotal"
                                    :min="0" :max="99999" :empty-value="0" disabled
                                    :precision="2" :decimal-separator="'.'" id="formStayService__cash_amount"
                                    name="cash_amount"></vue-numeric>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-3">
                                    <label for="type_refund">Reembolso</label>
                                    <select v-model="formStayService.type_refund" name="type_refund" class="form-control">
                                        <option value="NONE">Ninguno</option>
                                        <option value="TOTAL">Total</option>
                                        <option value="PARTIAL">Parcial</option>
                                    </select>
                                </div>
                                <div v-show="!formStayService.is_free" class="form-group col-12 col-md-3">
                                    <label for="refund">Monto Reembolso</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model="formStayService.refund"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'" :disabled="formStayService.type_refund!='PARTIAL'"
                                    name="refund"></vue-numeric>
                                </div>
                                <!--  -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-danger" @click.prevent="onDeleteService()"
                            v-if="Stay.package_service!=null && $can('delete', 'packages-index')"> 
                                <span>
                                    Borrar
                                </span>
                            </button> 
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-index'"> 
                                <span v-if="Stay.package_service!=null && $can('update', 'packages-index')">
                                    Editar
                                </span>
                                <span v-else-if="Stay.package_service!=null && !$can('update', 'packages-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                            <!-- <button type="button" class="btn brn-default" @click.prevent="ver()" >
                                Show Console
                            </button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
        
    </div>
</template>
<script>
import VueNumeric from 'vue-numeric';

import FileImage from '~/components/packages/FileImage';
import ImageDisplay from '~/components/gallery/imageDisplay';

export default {
    components: {
        VueNumeric,
        FileImage,
        ImageDisplay
    },
    props: {
        Stay: {
            type : [Object, null],
            default: null,
        },
        PackageServiceNew: {
            type: [Object, null],
            default: null,
        },
    },
    data() {
        return {
            clearDisplayImage           : false,
            clearFileImage              : false,
            package_service             : null,
            package_services            : [],
            formStayService: new WebForm('#formStayService', {
                id                      : null,
                package_stay_id         : null,
                package_service_id      : '',
                cant_service            : 1,
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                start_hour_a            : {"hh":"01", "mm":"00", "A":"PM"},
                end_hour_a              : {"hh":"11", "mm":"00", "A":"AM"},
                cost                    : 0,
                taxes                   : 0,
                utility                 : 0,
                total                   : 0,
                link                    : '',
                description             : '',
                description_i18n        : '',
                image                   : null,
                is_free                 : null,
                non_date                : null,
                type_refund             : 'NONE',
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
                last_image              : null,
                i18n                    : false,
            }),
        };
    },
    watch: {
        PackageServiceNew: function(val, oldVal) {
            if ( this.PackageServiceNew ) {
                let service_ = {
                    id: this.PackageServiceNew.id,
                    name: this.PackageServiceNew.title,
                    label: `${this.PackageServiceNew.title}`
                };
                this.package_services.push(service_);
                this.package_service = service_;
            }
        },
        Stay: function(val, oldVal) {
            let vm = this;
            if(vm.Stay!=null) {
                if (vm.Stay.package_service==null) {
                    vm.initDate();
                    vm.formStayService.package_stay_id  = vm.Stay.id;
                } else {
                    let stay = vm.Stay.package_stay_service[vm.Stay.package_service];
                    console.log('stay', stay);
                    vm.formStayService.id               = stay.id;
                    vm.formStayService.non_date         = stay.non_date;
                    vm.formStayService.is_free          = stay.is_free;
                    vm.formStayService.cant_service     = stay.cant_service;
                    vm.formStayService.cost             = stay.cost;
                    vm.formStayService.taxes            = stay.taxes;
                    vm.formStayService.utility          = stay.utility;
                    vm.formStayService.type_refund      = stay.type_refund;
                    vm.formStayService.link             = stay.link;
                    vm.formStayService.description      = stay.description;
                    vm.formStayService.description_i18n = stay.description_i18n;
                    vm.formStayService.package_stay_id  = stay.package_stay_id;
                    vm.formStayService.start_date       = stay.start_date;
                    vm.formStayService.end_date         = stay.end_date;
                    vm.formStayService.cash_amount      = stay.cash_amount;
                    vm.formStayService.card_amount      = stay.card_amount;
                    vm.formStayService.refund           = stay.refund;

                    if(stay.image) {
                        vm.formStayService.last_image   = [
                            {id: stay.id,
                            image: stay.image,
                            route: '/storage/stay_service/',
                            }
                        ]
                    }

                    setTimeout (() => {
                        vm.formStayService.cash_amount      = stay.cash_amount;
                        vm.formStayService.card_amount      = stay.card_amount;
                    }, 500);

                    if (stay.start_hour) {
                        let time = stay.start_hour;
                        vm.formStayService.start_hour = time;
                        vm.formStayService.start_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formStayService.start_hour_a = { "hh" : "01", "mm" : "00", "A"  : "AM" }
                    }

                    if (stay.end_hour) {
                        let time = stay.end_hour;
                        vm.formStayService.end_hour = time;
                        vm.formStayService.end_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formStayService.end_hour_a = { "hh" : "11", "mm" : "59", "A"  : "PM" }
                    }

                    if(stay!=null && stay.package_service_id!="") {
                        vm.package_service = vm.package_services.find(c => {
                            return c.id == stay.package_service_id;
                        });
                    }
                }
            } else {
                
            }
        },
    },
    computed: {
        priceTotal() {
            let vm = this;
            let cost        = (vm.formStayService.cost) ? parseFloat(vm.formStayService.cost) : 0; 
            let taxes       = (vm.formStayService.taxes) ? parseFloat(vm.formStayService.taxes) : 0;
            let utility     = (vm.formStayService.utility) ? parseFloat(vm.formStayService.utility) : 0;
            let total = cost + taxes + utility;
            vm.formStayService.card_amount = total;
            return total;
        },
        cashTotal() {
            let vm = this;
            let card = (vm.formStayService.card_amount) ? parseFloat(vm.formStayService.card_amount) : 0; 
            let totalCash = 0;
            if(vm.priceTotal >= card) {
                totalCash = vm.priceTotal - card;
            } else {
                card = vm.priceTotal;
                totalCash = vm.priceTotal - card;
                vm.formStayService.card_amount = card;
            }
            vm.formStayService.cash_amount = totalCash;

            if(vm.formStayService.type_refund=="TOTAL") {
                vm.formStayService.refund = vm.priceTotal;
            } else if(vm.formStayService.type_refund=="NONE") {
                vm.formStayService.refund = 0;
            }
            return totalCash;
        }
    },
    methods: {
        imageErased(obj) {
            this.formStayService.last_image = null;
        },
        getImage(obj) {
            this.formStayService.image = (obj.length>0) ? obj[0] : null;
        },
        ver() {
            console.log(this.formStayService);
        },
        validateImage() {
            let vm = this;
            let respo = 0;
            let formato =["data:image/jpeg;base64", "data:image/jpg;base64", "data:image/png;base64"];
            if(vm.formStayService.image!="" && vm.formStayService.image!=null) {
                let short = vm.formStayService.image.substring(0, 50);
                for (var i = 0; i < formato.length; i++) {
                    if(short.indexOf(formato[i], 0) == 0 ) {
                        respo++;
                    }
                }
            }
            return respo;
        },
        onStayService() {
            let vm = this;

            if(vm.package_service && vm.package_service.id) {
                vm.formStayService.package_service_id = vm.package_service.id
            }

            if(vm.formStayService.start_hour_a) {
                let time = vm.formStayService.start_hour_a;
                vm.formStayService.start_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            if(vm.formStayService.end_hour_a) {
                let time = vm.formStayService.end_hour_a;
                vm.formStayService.end_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            if(vm.formStayService.is_free) {
                vm.formStayService.is_free = 1;
            }

            if(vm.formStayService.non_date) {
                vm.formStayService.non_date = 1;
            }

            if(vm.formStayService.image != null ) {
                if(vm.validateImage()==0) {
                    vm.clearFileImage = true;
                    setTimeout (() => {
                        vm.clearFileImage = false;
                    }, 500);
                    vm.$snotify.warning('Archivo de Imagen no compatible...');
                    return;
                }
            }

            if(this.formStayService.id != null) {
                $.confirm({
                    title: `Editar Servicio de Estadia`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.saveStayService();
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.saveStayService();
            }
        },
        saveStayService() {
            let vm = this;
            vm.formStayService.post('/admin/packages/hotels/services/save').then(response => {
                let data = response.data;
                if(data.success) {
                    vm.$emit('reloadPackageHotelStay', vm.Stay.package_hotel_id);
                    let snotify_message = vm.Stay.package_service == null ? 'Servicio agregado con exito.' : 'Servicio Actualizado con exito.';
                    vm.$snotify.success(snotify_message);
                    $('#addStayServiceModal').modal('hide');
                    vm.formStayService.clear();
                    vm.formStayService.reset();
                    return;
                }
                
                vm.$snotify.info(`Algo resulto mal...`)
            });
        },
        onDeleteService() {
            let vm = this;
            if(this.formStayService.id != null) {
                $.confirm.delete(
                    'Borrar Servicio de Estadia',
                    '¿Seguro que quiere <b>borrar</b> este servicio?',
                    function(){
                        vm.deleteStayService();
                    }
                )
            }
        },
        deleteStayService() {
            let vm = this;
            let idDel = vm.formStayService.id;
            axios.post('/admin/packages/hotels/services/delete', { id: idDel }).then(response => {
                let data = response.data;
            
                if(!data.success) {
                    vm.$snotify.warning('Ups. ocurrio un error');
                    return
                } 

                if(data.success) {
                    vm.$emit('reloadPackageHotelStay', vm.Stay.package_hotel_id);
                    vm.$snotify.success('El servicio se elimino con exito con Exito');
                    $('#addStayServiceModal').modal('hide');
                    vm.formStayService.clear();
                    vm.formStayService.reset();
                    return
                } 

                vm.$snotify.info('Se ha enviado solicitud') 

            }).catch(err => console.log(err));
        },
        getServices() {
            let endpoint = '/admin/packages/services/all';
            axios.get(endpoint).then(({data}) => {
                let services = data.map(l => {
                    let service = l
                    let d = {
                        id: service.id,
                        name: service.title,
                        label: `${service.title}`
                    }
                    return d
                });
                this.package_services = services;

                this.package_services.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });
            });
        },
        intTwoChar(val) {
            if(val>9) {
                return val;
            } else {
                return '0'+val;
            }
        },
        initDate() {
            let vm = this;
            if(vm.Stay.package_service==null) {
                // colocamos como fecha de expiracion 3 dias despues de hoy
                let hoy = new Date();
                var devolucion = new Date();
                devolucion.setDate(hoy.getDate() + 3);
                vm.formStayService.start_date = hoy.getUTCFullYear() + '-' + vm.intTwoChar(hoy.getMonth() + 1) + '-' + vm.intTwoChar(hoy.getDate());
                vm.formStayService.end_date = devolucion.getUTCFullYear() + '-' + vm.intTwoChar(devolucion.getMonth() + 1) + '-' + vm.intTwoChar(devolucion.getDate());
            }
        }
    },
    mounted() {
        let vm = this;

        vm.getServices();

        $('#addStayServiceModal').off('hidden.bs.modal');
        $('#addStayServiceModal').on('hidden.bs.modal', function () {
            vm.currentImage     = '/img/pick.png';
            vm.newImage         = null;
            vm.formStayService.clear();
            vm.formStayService.reset();
            vm.$emit('closeStayServiceModal');
        });

        // console.log('StayServiceModal mounted.');
    }
};
</script>
<style>
    .modal#addStayServiceModal{
        overflow-y: auto !important;
    }
</style>