
<script>

  import { ContentLoader } from "vue-content-loader"

  export default {
    props: [
        'primary_color', 
        'secondary_color',
        'width_svg',
        'height_svg',
        ],
 
    components: {
      ContentLoader
    }
  }

</script>
<template>
    <div class="table-loader">
        <content-loader id="menuLoader" 
            v-bind:speed="1.5" 
            :height="400"
            :primaryColor="primary_color" 
            :preserveAspectRatio="'xMidYMid meet'" 
            v-bind:secondaryColor="secondary_color" >

            <rect x="104" y="3" rx="3" ry="3" width="93" height="67" />
            <rect x="203" y="3" rx="3" ry="3" width="93" height="67" />
            <rect x="301" y="3" rx="3" ry="3" width="93" height="67" />

            <rect x="1" y="76" rx="3" ry="3" width="90" height="17" />
            <rect x="1" y="99" rx="3" ry="3" width="90" height="17" />
            <rect x="1" y="122" rx="3" ry="3" width="90" height="17" />
            <rect x="1" y="145" rx="3" ry="3" width="90" height="17" />

            <circle cx="111" cy="85" r="5"/>
            <circle cx="210" cy="85" r="5"/>
            <circle cx="308" cy="85" r="5"/>
            <rect x="124" y="76" rx="3" ry="3" width="73" height="17" />
            <rect x="223" y="76" rx="3" ry="3" width="73" height="17" />
            <rect x="321" y="76" rx="3" ry="3" width="73" height="17" />

            <circle cx="111" cy="108" r="5"/>
            <circle cx="210" cy="108" r="5"/>
            <circle cx="308" cy="108" r="5"/>
            <rect x="124" y="99" rx="3" ry="3" width="73" height="17" />
            <rect x="223" y="99" rx="3" ry="3" width="73" height="17" />
            <rect x="321" y="99" rx="3" ry="3" width="73" height="17" />

            <circle cx="111" cy="131" r="5"/>
            <circle cx="210" cy="131" r="5"/>
            <circle cx="308" cy="131" r="5"/>
            <rect x="124" y="122" rx="3" ry="3" width="73" height="17" />
            <rect x="223" y="122" rx="3" ry="3" width="73" height="17" />
            <rect x="321" y="122" rx="3" ry="3" width="73" height="17" />

            <circle cx="111" cy="154" r="5"/>
            <circle cx="210" cy="154" r="5"/>
            <circle cx="308" cy="154" r="5"/>
            <rect x="124" y="145" rx="3" ry="3" width="73" height="17" />
            <rect x="223" y="145" rx="3" ry="3" width="73" height="17" />
            <rect x="321" y="145" rx="3" ry="3" width="73" height="17" />

        </content-loader>
    </div>
</template>


<style scoped>
    .table-loader{
        position: relative;
        width: 730px;
        height: 300px;
        margin-top: 3px;
    }
    svg#menuLoader{
        z-index: 1;
        top: 0;
        left: 0;
    }
</style>