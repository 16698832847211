<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Cotizaciones</h5>
                    <p class="text-muted mb-1">Lista de Cotizaciones de Clientes</p>
                </div>
                <div class="ml-auto">
                    <!-- <button v-can:create class="btn btn-secondary center-content d-inline-block"  @click.prevent='goBack()'>
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button> -->
                    <button class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#NewQuoteModal">
                        <span class="icon-add-2 mr-2"></span> Nueva Cotización
                    </button>
                </div>
            </div>
            
            <!-- <attraction-category-modal :AttractionCategory="AttractionCategory" v-on:closeAttractionCategoryModal="clearAttractionCategoryVar()" v-on:reloadAttractionCategoryModal="reloadAttractionCategoryModal()"></attraction-category-modal> -->

            <new-quote-modal></new-quote-modal>

            <data-table :ajax="{ url: '/admin/quotes/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="quoteTable" :serverSide="false" :order="[0, 'desc']" :columns="dt.columns()"></data-table>

        </div>
    </div>
</template>

<script>
    'use strict'
    import dataTable from '~/components/dataTable'
    import NewQuoteModal from '~/components/marketing/quotes/NewQuoteModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            NewQuoteModal,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                timerReload:            15000,
                AttractionCategory  :   null,
                nQuotes : null,
                msjPending: 0,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'customer', data: 'customer_name' },
                                { title: 'Contact', data: null,  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let phone_number = (data.customer_phone_number) ? `(${data.customer_country_code}) + ${data.customer_country_calling_code} ${data.customer_phone_number}` : '';
                                    return `<small>${data.customer_email}<br>
                                    ${phone_number}</small>`;
                                }},
                                { title: 'language', data: 'language', render: function ( data, type, row, meta) {
                                    let lang_code = (data.short_iso_code.toLowerCase() == 'es') ? 'es' : 'us';
                                    return  `<img src="https://www.countryflags.io/${lang_code}/flat/24.png" class="mr-2" width="24" height="24" alt=""> ${data.name}`;
                                }},
                                { title: 'date', data: null,  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let date = moment(data.created_at, "YYYY-MM-DD").format('ll');
                                    let time = moment(data.created_at, "HH:mm:ss").format('LT');
                                    return `<small>${date}<br>
                                    ${time}</small>`;
                                }},
                                // { title: 'date', data: 'created_at' },
                                { title: 'elapsed', data: null,  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  $this.timeNow(data);
                                }},
                                { title: 'tracing', data: 'user.username' },
                                { title: 'State', data: 'state_quote', orderable: false, searchable: true,
                                render: function ( data, type, row) {
                                    return  `<div style="margin-right: 5px; font-size: 12px; line-height: 25px; text-aling: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                    <span class="text-state ${ data.toLowerCase()}">${data}</span>
                                    </div>`;
                                }},
                                { title: 'Actions', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btnedit     =  ($this.$can('write') && data.user_id==null )  ? `<button class="btn btn-secondary btn-sm font-weight-bold btn-follow" style="width: 95px;">Administrar</button>` : '';
                                        let btnedit2    =  ($this.$can('write') && data.user_id!=null )  ? `<button class="btn btn-success btn-sm font-weight-bold btn-follow" style="width: 95px;">Visualizar</button>` : '';
                                        let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btnedit + btnedit2 + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            timeNow(data) {
                let vm = this;
                if (data.state_quote=="FINISHED" || data.state_quote=="QUOTED") {
                    let text = moment(data.created_at, "YYYY-MM-DD HH:mm:ss").fromNow();
                    return `<span class="badge-pill">${text}</span>`;
                } else {
                    let porcentaje = 100;
                    let className = 'bg-dark';

                    var end = moment(data.created_at);
                    var now = moment(Date.now());

                    var diffDays = now.diff(end, 'd'); // Diff in days
                    var diffHours = now.diff(end, 'h'); // Diff in hours

                    if (diffHours<48) {
                        porcentaje = ((100/48).toFixed(0) * diffHours);
                        if (porcentaje<25) {
                            className="bg-success";
                        } else if (porcentaje<50) {
                            className="bg-info";
                        } else if (porcentaje<75) {
                            className="bg-warning";
                        } else {
                            className="bg-danger";
                        }
                    }

                    if(this.msjPending == 0) {
                        vm.$snotify.warning("Existen cotizaciones pendientes...");
                        this.msjPending = 1;
                    }


                    // console.log("ID: " + data.id +  " Hace " + diffDays + " Dias, " + diffHours + " Horas, " + porcentaje + "%");

                    let text = moment(data.created_at, "YYYY-MM-DD HH:mm:ss").fromNow();
                    return `<span class="badge-pill">${text}</span>
                    <div class="progress">
                        <div class="progress-bar ${className}" role="progressbar" style="width: ${porcentaje}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" title="${porcentaje}%"></div>
                    </div>
                    `;
                }
                
            },
            // Redirecciones=========================================================
            goBack: function () {
                window.history.back();
            },
            clearAttractionCategoryVar() {
                this.AttractionCategory = null;
            },
            reloadAttractionCategoryModal() {
                this.$refs.attractionCategoryTable.ajaxReload();
            },
            onFollow(quote, thisButton) {
                let vm = this;
                $.confirm({
                    title: 'Resolver Cotización',
                    content: `Esta apunto de adjuntar la <u>cotizacion</u> a su <b>agenda</b>, 
                    solo usted podra darle continuidad a esta cotización.<br>
                    <b>¿Desea continuar?</b>`,
                    autoClose: 'Cancel|15000',
                    buttons: {
                        Editar:{
                            text: 'Continuar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                                action: function() {
                                    vm.setQuote(quote, thisButton);
                                }
                        },
                        Cancel: {
                            text: `Cancelar`,
                        },
                    }
                });
            },
            async setQuote(quote, thisButton) {
                let vm = this;
                $(thisButton).addClass('btn-loading');
                await axios.post('/admin/quotes/assign', {id: quote.id}).then(response => {
                    let data = response.data;
                    console.log("res:: ", data);
                    if (data.user_request_status) {
                        vm.$snotify.error('Ups, algo ha salido mal...');
                    }
                    if(data.success) {
                        vm.$snotify.success('Cotizacion asignada a su agenda.');
                        $(thisButton).addClass('btn-success');
                        $(thisButton).removeClass('btn-info');
                        vm.goToQuote(quote.id);
                    }
                    return;
                });
            },
            goToQuote(QuoteId) {
                this.$router.push({ name: 'marketing-quotes-id', params: { id: QuoteId }});
            },
            onDelete(quote) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Cotización',
                    `¿Está seguro que desea eliminar la cotizacion?`, 
                    function(){
                        vm.deleteItem(quote.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/quotes/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.quoteTable.ajaxReload();
                        this.nQuotes = null;
                        return
                    }
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
            async initVerified() {
                let vm = this;
                setInterval(async () => this.verifiedNew(), vm.timerReload);
            },
            async verifiedNew() {
                let vm = this;
                vm.timerReload = 10000000;
                await axios.get('/admin/quotes/count').then(response => {
                    let data = response.data;
                    
                    if(!data.success) {
                        console.log("Error al verificar nuevas cotizaciones...");
                        vm.timerReload = 15000;
                        return;
                    }
                    if(data.success) {
                        if (this.nQuotes==null || this.nQuotes > data.success) {
                            this.nQuotes = data.success;
                        } else if (this.nQuotes < data.success) {
                            this.nQuotes = data.success
                            this.$snotify.success('Hay una nueva cotizacion...');
                            this.$refs.quoteTable.ajaxReload();
                        }
                        vm.timerReload = 15000;
                        return;
                    }
                }).catch(err => console.log(err));
            },
            sendMail () {
                let vm = this;
                // axios.post('/admin/quotes/sendmail', { customer_email: 'doncanzsa@gmail.com', customer_name: 'Didier Alvarez' }).then(response => {
                //     let data = response.data;
                
                //     console.log(data); 

                // }).catch(err => console.log(err));
            },
        },
        mounted(){
            moment().tz("America/New_York").format();
            let vm = this;

            // ------------ Para usar el valor que tiene la tabla actual.
            $(vm.$el).off("click.quotes",".btn-follow");
            $(vm.$el).on("click.quotes",".btn-follow",function(){
                let quote = vm.$refs.quoteTable.rowData($(this).closest('tr'));
                let thisButton = this;
                if(quote.user_id) {
                    $(this).addClass('btn-loading');
                    vm.goToQuote(quote.id);
                } else {
                    vm.onFollow(quote, thisButton);
                }
            });

            $(vm.$el).off("click.quotes",".btn-delete");
            $(vm.$el).on("click.quotes",".btn-delete",function(){
                let quote = vm.$refs.quoteTable.rowData($(this).closest('tr'));
                vm.onDelete(quote);
            });

            vm.initVerified();
        },
    }
</script>
<style>
    .finished {
        border-bottom: solid 4px green;
        color: green;
    } 
    .in_progress {
        border-bottom: solid 4px orange;
        color: orange;
    } 
    .in_process {
        border-bottom: solid 4px orange;
        color: orange;
    }
    .quoted {
        border-bottom: solid 4px gray;
        color: gray;
    }
    .pending {
        border-bottom: solid 4px #27b4f5;
        color: #27b4f5;
    } 

    .text-state{
        font-weight: 500;
    }
    .text-state:hover{
        cursor: default;
    }
</style>