<template>
    <div class="modal fade" id="EmptyRequirementModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="formEmptyRequirement" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h6 class="font-weight-bold mb-0">
                            <span>Nuevo Requerimiento vacio de 
                                {{ (empty_quote.type_quote) ? empty_quote.type_quote : '' }}
                            </span>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-0">
                            <textarea
                                type="text"
                                class="form-control"
                                rows="4"
                                placeholder="Ingrese comentario"
                                v-model="formEmptyRequirement.comments"
                            ></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :class="{'disabled' : formEmptyRequirement.comments.length<=8}" v-if="owner">
                            <span>Guardar</span>
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        empty_quote: {
            type: [Object],
            default () {
                return false
            }
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    data() {
        return {
            formEmptyRequirement: new WebForm("#formEmptyRequirement", {
                id:             null,
                comments:       '',
                quote_id:       null,
                empty_quote:    1,
            })
        };
    },
    methods: {
        onSaveItem() {
            let vm = this;
            let url = "setobs";
            vm.formEmptyRequirement.id = (vm.quote.id) ? vm.quote.id : null;
            
            newObservations.push(newArray);
            this.formEmptyRequirement.observations = (JSON.stringify(newObservations)) ? JSON.stringify(newObservations) : null;
            if (vm.formEmptyRequirement.id) {
                vm.formEmptyRequirement.post('/admin/quoteIds/setobs').then(response => {
                    let data = response.data;
                    
                    if (data.user_request_status) {
                        $("#EmptyRequirementModal").modal("hide");
                        return;
                    }

                    if(data.success) {
                        vm.$emit("saveModalEmptyRequirement", data.success);
                    }

                    vm.formEmptyRequirement.clear();
                    vm.formEmptyRequirement.reset();
                    $("#EmptyRequirementModal").modal("hide");
                    return;
                });
            }
        },
    },
    mounted() {
        let vm = this;
        $("#EmptyRequirementModal").off("hidden.bs.modal");
        $("#EmptyRequirementModal").on("hidden.bs.modal", function() {
            vm.formEmptyRequirement.clear();
            vm.formEmptyRequirement.reset();
            vm.$emit("closeModalEmptyRequirement");
        });
    }
};
</script>