<template>
    <div>
        <!-- Solo sirve para colocar un fondo negro con icono de carga -->
        <loading-modal></loading-modal>

        <div class="d-flex justify-content-between">
            <div class="col-md-6">
                <h5 class="font-weight-bold mb-0">Vista de Cotizacion</h5>
                <p class="text-muted mb-1">Información requerida por el cliente</p>
            </div>
            <div class="col-md-6 text-right">
                
                    <button class="btn btn-secondary center-content d-inline-block"  @click.prevent="goBack()">
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button>
                    <!-- <a :href="'/marketing/quotes/print/' + idQuote + '/pdf'" target="_blank" class="btn btn-secondary center-content d-inline-block">
                        <span class="icon-print mr-2"></span> Imprimir
                    </a> -->
                    <div class="dropdown d-inline-block center-content ">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon-menu-9"></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a :href="'/marketing/quotes/print/' + idQuote + '/pdf'" target="_blank" class="dropdown-item">
                                <span class="icon-print mr-2"></span> Imprimir
                            </a>
                            <a @click="sendEmailQuote()" target="_blank" class="dropdown-item">
                                <span class="icon-send mr-2"></span> Enviar Email
                            </a>
                        </div>
                    </div>
                
            </div>
        </div>

        <quote-resumen :quote="myQuote"
        :owner="owner">
        </quote-resumen>

        <empty-requirement-modal 
        :empty_quote="{'quote_id': myQuote.id, 'type_quote': type_quote, 'id': quote_empty_id, 'comments': quote_empty_comments}"
        :owner="owner">
        </empty-requirement-modal>

        <quote-observation-modal 
        :quote="[{'quote_id': myQuote.id, 'observations': myQuote.observations}]"
        :owner="owner"
        v-on:saveModalQuoteObservation="saveObservation">
        </quote-observation-modal>

        <quote-state-modal 
        :quote="{'quote_id': myQuote.id, 'state_quote': myQuote.state_quote}"
        v-on:saveModalQuoteState="saveModalQuoteState">
        </quote-state-modal>

        <view-package-requirements v-if="myQuote && myQuote.quote_package[0]"
        :quotePackage="myQuote.quote_package[0]"
        :lastUpdateLocal="lastUpdateLocal"
        :owner="owner"
        ></view-package-requirements>

        <view-flight-requirements v-if="myQuote && myQuote.quote_flight[0]"
        :quoteFlight="myQuote.quote_flight[0]"
        :lastUpdateLocal="lastUpdateLocal"
        :owner="owner"
        v-on:saveNewFlightResponse="saveNewFlightResponse"
        v-on:deleteFlightResponse="saveNewFlightResponse"
        ></view-flight-requirements>

        <view-hotel-requirements v-if="myQuote && myQuote.quote_hotel[0]"
        :quoteHotel="myQuote.quote_hotel[0]"
        :lastUpdateLocal="lastUpdateLocal"
        :owner="owner"
        v-on:saveNewHotelResponse="saveNewHotelResponse"
        ></view-hotel-requirements>

        <view-service-requirements v-if="myQuote && myQuote.quote_service[0]"
        :quoteService="myQuote.quote_service[0]"
        :lastUpdateLocal="lastUpdateLocal"
        :owner="owner"
        v-on:saveServiceResponse="saveServiceResponse"
        ></view-service-requirements>

        <view-tourist-requirements v-if="myQuote && myQuote.quote_tourist[0]"
        :quoteTourist="myQuote.quote_tourist[0]"
        :lastUpdateLocal="lastUpdateLocal"
        :owner="owner"
        v-on:saveTouristResponse="saveTouristResponse"
        ></view-tourist-requirements>

        <div class="row section mx-2 my-1 p-2" v-if="myQuote && (!myQuote.quote_tourist[0] || !myQuote.quote_service[0] || !myQuote.quote_hotel[0] || !myQuote.quote_flight[0]) ">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 text-center" v-if="myQuote && !myQuote.quote_flight[0]">
                <button class="btn btn-secondary pt-2 btn-block">
                    <span class="icon-add-2 mb-1" style="font-size: 40px;"></span>
                    <p class="m-0 mt-2">Agregar requerimiento<br> vacio de vuelo</p>
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 text-center" v-if="myQuote && !myQuote.quote_hotel[0]">
                <button class="btn btn-secondary pt-2 btn-block" @click="show_empty_quote(null, 'hotel')">
                    <span class="icon-add-2 mb-1" style="font-size: 40px;"></span>
                    <p class="m-0 mt-2">Agregar requerimiento<br> vacio de hotel</p>
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 text-center" v-if="myQuote && !myQuote.quote_service[0]">
                <button class="btn btn-secondary pt-2 btn-block">
                    <span class="icon-add-2 mb-1" style="font-size: 40px;"></span>
                    <p class="m-0 mt-2">Agregar requerimiento<br> vacio de servicio</p>
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 text-center" v-if="myQuote && !myQuote.quote_tourist[0]">
                <button class="btn btn-secondary pt-2 btn-block">
                    <span class="icon-add-2 mb-1" style="font-size: 40px;"></span>
                    <p class="m-0 mt-2">Agregar requerimiento<br> vacio de atraccion</p>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingModal from '~/components/packages/LoadingModal';
import QuoteResumen from '~/components/marketing/quotes/QuoteResumen';
import QuoteObservationModal from '~/components/marketing/quotes/QuoteObservationModal';
import QuoteStateModal from '~/components/marketing/quotes/QuoteStateModal';
import ViewFlightRequirements from '~/components/marketing/quotes/flight/ViewFlightRequirements';
import ViewHotelRequirements from '~/components/marketing/quotes/hotel/ViewHotelRequirements';
import ViewServiceRequirements from '~/components/marketing/quotes/service/ViewServiceRequirements';
import ViewTouristRequirements from '~/components/marketing/quotes/tourist/ViewTouristRequirements';
import ViewPackageRequirements from '~/components/marketing/quotes/package/ViewPackageRequirements';

import EmptyRequirementModal from '~/components/marketing/quotes/EmptyRequirementModal';

export default {
    middleware: 'auth',
    layout: 'dashboard',
    route: {
        validate: {
            id: '\\d+'
        }
    },
    components: {
        LoadingModal,
        QuoteResumen,
        QuoteObservationModal,
        QuoteStateModal,
        ViewFlightRequirements,
        ViewHotelRequirements,
        ViewServiceRequirements,
        ViewTouristRequirements,
        ViewPackageRequirements,
        EmptyRequirementModal
    },
    metaInfo() {
        return {
            title: "Vista Cotización"
        }
    },
    data() {
        return {
            owner :                 false,
            idQuote :               null,
            myQuote :               null,
            type_quote :            null,
            flightResponse :        null,
            quote_empty_id :        null,
            lastUpdateLocal :       null,
            quote_empty_comments :  null,
        }
    },
    watch: {
        // 
    },
    async asyncData(app, route, store, redirect){

        let data = {
            idQuote :           null,
            myQuote :           null,
            flightResponse :    null,
            lastUpdateLocal :   null,
        }

        //Valida que el parametro sea correcto
        let { id } = route.params
        if(_.isNull(id) || _.isUndefined(id) || !_.isNumeric(id)){
            redirect({ name: 'error-not-found' })
            return data
        }

        let quote    = await axios.get(`/admin/quotes/${id}`)

        if (quote.data && quote.data.quote_flight[0]) {

            let flight = quote.data.quote_flight[0];

            if (!flight.empty_quote) {
                let origin_iata = flight.origin_iata;
                let origin = await axios.get(`/admin/airports/find/${origin_iata}`);
                quote.data.quote_flight[0].origin = origin.data;

                let destiny_iata = flight.destiny_iata;
                let destiny = await axios.get(`/admin/airports/find/${destiny_iata}`);
                quote.data.quote_flight[0].destiny = destiny.data;
            }
        }

        if (quote.data && quote.data.quote_hotel[0]) {

            let hotel = quote.data.quote_hotel[0];

            if (!hotel.empty_quote) {
                let country = await axios.get(`/admin/location/countries/${hotel.country_id}`);
                quote.data.quote_hotel[0].country = country.data;
    
                let category = await axios.get(`/admin/hotels/categories/${hotel.hotel_category_id}`);
                quote.data.quote_hotel[0].hotel_category = category.data;
            }

        }

        if (quote.data && quote.data.quote_service[0]) {

            let hotel = quote.data.quote_service[0];

            if (!hotel.empty_quote) {
                let country = await axios.get(`/admin/location/countries/${hotel.country_id}`);
                quote.data.quote_service[0].country = country.data;
            }

        }

        if (quote.data && quote.data.quote_tourist[0]) {

            let tourist = quote.data.quote_tourist[0];

            if (!tourist.empty_quote) {
                let country = await axios.get(`/admin/location/countries/${tourist.country_id}`);
                quote.data.quote_tourist[0].country = country.data;
    
                let category = await axios.get(`/admin/attractions/categories/${tourist.attraction_category_id}`);
                quote.data.quote_tourist[0].attraction_category = category.data;
            }

        }

        data.myQuote     = quote.data;
        data.idQuote     = id;
        console.log('DATA: ', data);
        return data;
    },
    methods: {
        show_empty_quote(data, type = null) {
            let vm = this;
            if (data) {
                vm.quote_empty_id = data.id;
                vm.quote_empty_comments = data.comments;
            }
            vm.type_quote = type;
            $("#EmptyRequirementModal").modal('show');
        },
        async sendEmailQuote() {
            console.log('Vamos a enviar el correo');

            let vm = this;
            vm.displayLoadModal(true);
            await axios.get(`/marketing/quotes/print/${vm.idQuote}/send`).then( async response => {
                let data = response.data;
                if(data.success) {
                    vm.saveObservation(data.success);
                    vm.$snotify.success('Cotizacion enviada.');
                } else {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                vm.displayLoadModal(false);
                return;
            });
        },
        saveNewFlightResponse(obj) {
            let vm = this;
            vm.myQuote.quote_flight[0].quote_flight_response = (obj.length>0) ? obj : [];
            vm.lastUpdateLocal = Date.now();
        },
        saveNewHotelResponse(obj) {
            let vm = this;
            vm.myQuote.quote_hotel[0].quote_hotel_response = (obj.length>0) ? obj : [];
            vm.lastUpdateLocal = Date.now();
        },
        saveServiceResponse(obj) {
            let vm = this;
            vm.myQuote.quote_service[0].quote_service_response = (obj.length>0) ? obj : [];
            vm.lastUpdateLocal = Date.now();
        },
        saveTouristResponse(obj) {
            let vm = this;
            vm.myQuote.quote_tourist[0].quote_tourist_response = (obj.length>0) ? obj : [];
            vm.lastUpdateLocal = Date.now();
        },
        displayLoadModal(state) {
            if(state) {
                $('#modalLoading').modal('show');
            } else {
                $('#modalLoading').modal('hide');
            }
        },
        saveObservation(data) {
            this.myQuote.observations = data.observations;
            this.myQuote.updated_at = data.updated_at;
        },
        saveModalQuoteState(data) {
            this.myQuote.state_quote = data.state_quote;
            this.myQuote.updated_at = data.updated_at;
        },
        goBack: function () {
            window.history.back();
        }
    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
            displayName : 'auth/displayName',
        })
    },
    mounted() {
        let vm = this;
        this.owner = (this.myQuote.user_id!=null && this.myQuote.user_id == this.user.id) ? true : false;
        this.lastUpdateLocal = Date.now();
    },
    destroyed() {
        // 
    }
}
</script>
