<template>
    <div class="modal fade" id="modalQuoteObservation">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="formQuoteObservation" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h6 class="font-weight-bold mb-0">
                            <span>Nueva Observacion</span>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-0">
                            <textarea
                                type="text"
                                class="form-control"
                                rows="3"
                                placeholder="Ingrese observaciones"
                                v-model="formQuoteObservation.newObservation"
                                name="newObservation"
                            ></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :class="{'disabled' : formQuoteObservation.newObservation.length<=8}" v-can:create="'marketing-quotes-index'">
                            <span>Guardar</span>
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["quote"],
    data() {
        return {
            formQuoteObservation: new WebForm("#formQuoteObservation", {
                newObservation:     "",
                observations:       "",
                id:                 null
            })
        };
    },
    methods: {
        getObservations() {
            let temp = [];
            let observations = (JSON.parse(this.quote[0].observations)) ? JSON.parse(this.quote[0].observations) : null;
            if (observations) {
                observations.forEach(element => {
                    let item = {
                        "description": element.description,
                        "date": element.date
                    };
                    temp.push(item);
                });
            }
            return temp;
        },
        onSaveItem() {
            let vm = this;
            let url = "setobs";
            vm.formQuoteObservation.id = (vm.quote[0].quote_id) ? vm.quote[0].quote_id : null;
            var newObservations = this.getObservations();
            var newArray = {
                "description": this.formQuoteObservation.newObservation,
                "date": moment().format('YYYY-MM-DD HH:mm:ss')
            };
            newObservations.push(newArray);
            this.formQuoteObservation.observations = (JSON.stringify(newObservations)) ? JSON.stringify(newObservations) : null;
            if (vm.formQuoteObservation.id) {
                vm.formQuoteObservation.post('/admin/quotes/setobs').then(response => {
                    let data = response.data;
                    
                    if (data.user_request_status) {
                        $("#modalQuoteObservation").modal("hide");
                        return;
                    }

                    if(data.success) {
                        vm.$emit("saveModalQuoteObservation", data.success);
                    }

                    vm.formQuoteObservation.clear();
                    vm.formQuoteObservation.reset();
                    $("#modalQuoteObservation").modal("hide");
                    return;
                });
            }
        },
    },
    mounted() {
        let vm = this;
        $("#modalQuoteObservation").off("hidden.bs.modal");
        $("#modalQuoteObservation").on("hidden.bs.modal", function() {
            vm.formQuoteObservation.clear();
            vm.formQuoteObservation.reset();
            vm.$emit("closeModalQuoteObservation");
        });
    }
};
</script>
<style scoped>
</style>