const VRANGE = {
    install(Vue, options) {
        
        Vue.directive('range', {
            // inserted (el,binding){
            //     debugger
            //     let {prev,next,limit} = binding.value
            //     console.log(binding)
            //     el.addEventListener('change',function(){
            //         let $prev = prev ? document.querySelector(prev) : null
            //         let $next = next ? document.querySelector(next)  : null 
            //         let $limit  = limit ? document.querySelector(limit)  : null   
            //         let prevExist   = document.getElementById(prev) 
            //         let nextExist   = document.getElementById(next)
            //         let elValue =   (this.value)*1
            //         let prevVal =   ($prev ? $prev.value : 0)*1
            //         let nextVal =   ($next ? $next.value : 0)*1
            //         let difPrev =   elValue - prevVal
            //         let difNext =   elValue - nextVal
            //         debugger
            //         if($limit != null){
            //                if(difPrev>0 && $prev!=null)
            //                     $prev.value = elValue-1
            //                 if(elValue==nextVal)
            //                     $next.value = elValue+1

            //         }
            //     })
            // }
            inserted (el, binding) {
                let {prev, next, start, end} = binding.value

                el.addEventListener('change',function(){
                    let $prev = prev ? document.querySelector(prev) : null
                    let $next = next ? document.querySelector(next) : null
                    let $start = start ? document.querySelector(start) : null
                    let $end = end ? document.querySelector(end) : null

                    // values
                    let elValue = this.value * 1
                    let prevValue = ($prev ? $prev.value : 0) * 1
                    let nextValue = ($next ? $next.value : 0) * 1
                    let startValue = ($start ? $start.value : elValue) * 1
                    let endValue = ($end ? $end.value : elValue) * 1

                    //Diff    
                    let diffPrev = $prev ? elValue - prevValue : null
                    let diffNext = $next  ? nextValue - elValue : null
                    let diffRange = endValue - startValue


                    if(diffPrev != null && diffPrev != 1) {
                        $prev.value = elValue-1
                        $prev.dispatchEvent(new Event('change'))
                    }
                    
                    if(diffNext != null && diffNext != 1) {
                        $next.value = elValue+1
                        $next.dispatchEvent(new Event('change'))
                    }

                    if(diffRange < 1) {
                        if($end) {
                            $end.value = startValue + 1
                            $end.dispatchEvent(new Event('change'))
                        }
                        if($start){
                            $start.value = endValue - 1
                            $start.dispatchEvent(new Event('change'))
                        }
                    }
                    

                })
            }
        })
    }
}


export default VRANGE