<template>
	<div class="row justify-content-center mx-0">
		<div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <p class="ml-2 font-weight-bold mb-0">Lista de Reservas</p>
                <!-- <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addAirline" >
                        <span class="icon-add-2 mr-2"></span> Nuevo
                    </button>
                </div> -->
            </div>
            <data-table :ajax="{ url: '/admin/reports/bookings' }" ref="bookingsTable" :serverSide="true" :columns="dt.columns()"></data-table>
        </div>
	</div>
</template>

<script>
    import dataTable from '~/components/dataTable'

	export default {
		middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
        },
        metaInfo() {
            return {
                title: "Bookings Report"
            }
        }, 
        data () {
        	return {
        		bookigns: [],
        		dt: {
		            columns : () => {
		                var $this = this
		                let columns =
		                    [
		                        { title: '#', data: 'id' },
		                        { title: 'Reservation Status', data: 'reservation_state', 
		                        	render: function ( data, type, row, meta ) {
		                        		if(data == 'COMPLETED')
		                        			return `<span class="badge badge-success" style="font-size: 12px">${data}</span>`
		                        		return `<span class="badge badge-light" style="font-size: 12px">${data}</span>`
		                        	}
		                    	},
		                        { title: 'Control Number (PNR)', data: 'control_number',
		                        	render: function ( data, type, row, meta ) {
		                        		return `<span class="badge badge-light" style="font-size: 14px">${data}</span>`
		                        	}
		                        },
		                        { title: 'Customer', data: 'customer', 
		                        	render: function ( data, type, row, meta ) {
		                        		if ( data == null )
		                        			return `
			                        		<div style="width: auto; margin-right: 5px; font-size: 12px; line-height: 20px; text-align: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" title="Guest">
	                                    		<span class="title_package">Guest</span>
	                                    	</div>`;

		                        		return  `
		                        		<div style="width: auto; margin-right: 5px; font-size: 12px; line-height: 20px; text-align: justify; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" title="${data.first_name} ${data.last_name}">
                                    		<span class="title_package customer-details">${data.first_name} ${data.last_name}</span>
                                    	</div>`;
		                        	}
		                    	},
		                        { title: 'Reservation Date', data: 'creation_date', className: 'text-center' },
		                        { title: 'Route', data: 'route', 
		                        	render: function ( data, type, row, meta ) {
                                        return data || `------`;
                                    } 
		                      	},
		                        { title: 'Flight Type', data: 'flight_type',
		                        	render: function ( data, type, row, meta ) {
		                        		switch(data) {
		                        			case 'OW':
		                        				return 'One Way';
		                        			case 'RT':
		                        				return 'Round Trip';
		                        			case 'MC':
		                        				return 'Multi City';
		                        			default:
		                        				return '---';
		                        		}
                                    }
		                    	},
		                        { title: 'Airline', data: 'airline' },
		                        { title: 'Flight Date', data: 'flight_date' },
		                        { title: 'Cabin', data: 'cabin_class',
		                        	render: function ( data, type, row, meta ) {
		                        		switch(data) {
		                        			case 'M':
		                        				return 'Economy';
		                        			case 'W':
		                        				return 'Economy Premium';
		                        			case 'C':
		                        				return 'Bussines';
		                        			case 'F':
		                        				return 'First';
		                        			default:
		                        				return 'Economy';
		                        		}
                                    } 
		                        },
		                        { title: 'Passengers', data: 'passengers',
		                        	render: function ( data, type, row, meta ) {
		                        		let passengers = data.map(p => '<span style="font-size:12px;font-weight:600;text-transform:uppercase;">' + p.first_surname + '/' + p.first_name + ' (' + p.passenger_type + ') </span>');
		                        		return passengers.join('<br>');
                                    }
		                    	},
		                        { title: 'Total Amount', data: 'amount', className: 'text-right',
		                        	render: function ( data, type, row , meta) {
		                        		return numeral(data).format('$ 0,0[.]00')
		                        	}
		                    	},
		                        { title: 'Total Taxes', data: 'taxes', className: 'text-right',
		                        	render: function ( data, type, row , meta) {
		                        		return numeral(data).format('$ 0,0[.]00')
		                        	}
		                        },
		                        { title: 'Payment Type', data: 'payment_type',
		                        	render :  function ( data, type, row, meta ) {
		                        		switch(data) {
		                        			case 'CC':
		                        				return 'Credit Card';
		                        			case 'CK':
		                        				return 'Check';
		                        			case 'CA':
		                        				return 'Cash';
		                        			default:
		                        				return 'Cash';
		                        		}
		                        	}
		                        },
		                    ]
		                return columns
		            }
		        }
        	}
        },
        methods: {
        	async getBookings(){
                await axios.get('/admin/reports/bookings').then(response => {
                    console.log(response.data)
                    this.bookings = response.data
                })
            },
            goToCustomerProfile(customerId) {
            	this.$router.push({ name: 'customers-id', params: { id: customerId }})
            }
        },
        mounted () {
        	let vm = this
        	$(vm.$el).off("click.booking",".customer-details");
            $(vm.$el).on("click.booking",".customer-details",function(){
                let booking = vm.$refs.bookingsTable.rowData($(this).closest('tr'));
                vm.goToCustomerProfile(booking.customer.id);
            });
        }
	}
</script>