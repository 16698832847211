<template>
    <div class="section-404 p-3">
            <div class="section-404-svg">
                <img src="../../../svg/404.svg" alt="">
            </div>
            <div class="section-404-plane">
                <img src="../../../svg/plane.svg" alt="">
            </div>
        <div class="section-404-info text-center container mt-5">
            <h1 class="section-404-title">404</h1>
            <p class="section-404-subtitle">No pudimos encontrar lo que buscas</p>
            <router-link :to="{ path: $store.state.route.from.fullPath || '/' }" class="section-404-btn btn btn-primary">Regresar</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        route: {
            path: '*'
        }
    }
</script>