<template>
    <div class="">
        <div class="row mx-n2">
            <div class="col-2 px-0">
                <ul class="support-email__menu">
                    <li v-for="(item, index) in menuData" :key="`menuSupportEmail-${index}`" class="support-email__menu__item" :class="{active: item.section == section}" @click="section = item.section">
                        <span class="icon mr-2" :class="item.icon"></span>
                        <span class="d-inline-block">
                            {{ item.title }}
                        </span> 
                        <span v-show="getCounter(item.section)" class="support-email__menu__item__counter">
                            {{ getCounter(item.section) }}
                        </span>
                    </li>
                    
                </ul>
            </div>
            <div class="col-10 px-0  support-email">
                <div class="d-flex h-100">
                    <div class="col-4 h-100 px-0 border-left border-right">
                        <ul class="support-email__inbox h-100">
                            <template v-if="sectionEmails.length || sectionPagination.loading">
                                <li class="support-email__inbox__item" :class="{'active': email.id == currentEmail}" v-for="(email, index) in sectionEmails" :key="`support-email-${index}-${email}`" @click="selectEmail(email.id)">
                                    <div class="support-email__inbox__item__photo mr-2">
                                        <img :src="`${email.customer_id ? email.customer.photo ||  '/img/none.png' : '/img/none.png'}`">
                                    </div>
                                    <div class="flex-grow-1 w-50">
                                        <h6 class="support-email__inbox__item__title mb-0">{{ email.customer_id ? `${email.customer.first_name} ${email.customer.last_name}` : email.email }}</h6>
                                        <div class="d-flex">
                                            <h6 class="support-email__inbox__item__title text-primary mb-1 w-50 flex-grow-1 support-email__inbox__item__excerpt pr-2">{{ email.subject }}</h6>
                                            <div>
                                                <span class="small text-muted">
                                                    {{ getDate(email.created_at) }}
                                                </span>
                                            </div>
                                        </div>
                                        <p class="m-0 text-muted support-email__inbox__item__excerpt">
                                            {{ email.excerpt }}
                                        </p>
                                        <div class="d-flex" v-if="email.status !== 'UNANSWERED'">
                                            <span class="text-muted ">Soporte por: </span> 
                                            <span class="rounded-pill bg-light pr-3">
                                                <img class="rounded-circle  d-inline-block" style="width: 15px; height: 15px" :src="`${ email.user.photo ||  '/img/none.png' }`">
                                                <span class="d-inline-block small text-primary">{{ email.user.first_name }}  {{ email.user.last_name }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </li>
                            </template>
                            <template v-else>
                                <div class="h-100 d-flex justify-content-center  align-items-center">
                                    No hay emails
                                </div>
                            </template>
                            <div v-if="sectionPagination.loading"  :class="{'h-100 d-flex justify-content-center align-items-center': sectionEmails.length == 0}">
                                <div>
                                    <div style="font-size: 0.8em" class="text-center py-3">
                                        <span class="loading loading-dots">
                                            <span></span>
                                        </span>
                                        <div>Cargando emails...</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="sectionPagination.totalPages > 1 && sectionPagination.currentPage < sectionPagination.totalPages && !sectionPagination.loading" class="p-3 text-center">
                                <button class="btn btn-link"   @click="loadMoreEmails(section, sectionPagination.currentPage+1)">Cargar mas <span class="small icon-down-arrow"></span></button>
                            </div>
                        </ul>
                    </div>
                    <div class="col-8 h-100 support-email__view px-0">
                        <div class="h-100 d-flex justify-content-center align-items-center" v-if="selectedEmail === null">
                            <div class="d-flex align-items-center">
                                <div class="mr-3">
                                    <span class="icon-left-arrow"></span>
                                </div>
                                <div>
                                    <h3 class="m-0">Selecciona un email <span class="icon-email-monitor"></span></h3>
                                    <p class="text-muted m-0">Para brindar soporte</p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="support-email__content">
                            <div class="py-3 px-2 support-email__content__header">
                                <div class="d-flex ">
                                    <div class="support-email__inbox__item__photo mr-3">
                                        <img :src="`${selectedEmail.customer_id ? selectedEmail.customer.photo ||  '/img/none.png' : '/img/none.png'}`">
                                    </div>
                                    <div class="flex-grow-1 w-50">
                                        <h6 class="support-email__inbox__item__title mb-0">
                                            {{ selectedEmail.customer_id ? `${selectedEmail.customer.first_name} ${selectedEmail.customer.last_name}` : selectedEmail.email }}
                                            <small class="d-block" v-if="selectedEmail.customer_id">
                                                {{ selectedEmail.email }}
                                            </small>
                                        </h6>
                                        <span class="small text-muted">
                                            {{ selectedEmail.created_at | moment('dddd D [de] MMMM YYYY hh:mm A') }}
                                        </span>
                                    </div>
                                    <div class="support-emial__content__tools d-flex align-items-start">
                                        <template v-if="selectedEmail.status != 'ANSWERED' && (!selectedEmail.user_id || selectedEmail.user_id == user.id )">
                                            <button @click="triggerReply" class="btn btn-primary text-nowrap">Responder</button>
                                            <button v-if="selectedEmail.status != 'TAKEN'" v-tooltip="'Responder Despues'" class="btn btn-light text-nowrap ml-2" @click="replyLater(selectedEmail.id)">
                                                <span class="icon m-0 icon-clock-hand"></span>
                                            </button>
                                            <button v-tooltip="'Respuesta Rápida'" class="btn btn-light text-nowrap ml-2" data-toggle="modal" data-target="#quickReplyModal">
                                                <span class="icon m-0 icon-email-fly"></span>
                                            </button>
                                        </template>
                                        <button v-tooltip="'Firma de Mensajes'" class="btn btn-light text-nowrap ml-2" data-toggle="modal" data-target="#emailSignatureModal">
                                            <span class="icon m-0 icon-id-card"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-5 pr-3 support-email__content__body">
                                <h4 class="support-email__inbox__item__title text-primary mb-0 ">{{ selectedEmail.subject }}</h4>
                                {{ selectedEmail.content }}
                                <div v-if="selectedEmail.reply" >
                                    <div class="support-email__reply mt-2">
                                        <div class="d-flex p-2">
                                            <div class="support-email__inbox__item__photo mr-2" >
                                                <img :src="selectedEmail.reply.user.photo || '/img/none.png'">
                                            </div>
                                            <div class="">
                                                <h6 class="support-email__inbox__item__title mb-0">{{selectedEmail.reply.user.first_name}} {{selectedEmail.reply.user.last_name}}</h6>
                                                <p class="m-0 small text-muted">
                                                    {{ selectedEmail.reply.created_at | moment('dddd D [de] MMMM YYYY hh:mm A') }}
                                                </p>
                                                <div class="mt-3" v-html="selectedEmail.reply.content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="support-email__content__footer">
                                <div v-if="selectedEmail.reply" class="alert alert-warning rounded-0 mb-0" role="alert">
                                    Este correo ya tiene respuesta
                                </div>
                                <div v-else class="d-flex align-items-center p-2">
                                    <div class="support-email__inbox__item__photo mr-3">
                                        <img :src="user.photo || '/img/none.png'">
                                    </div>
                                    <div class="">
                                        <h6 class="support-email__inbox__item__title mb-0">{{displayName}}</h6>
                                        <p class="m-0 text-muted">{{ user.email }}</p>
                                    </div>
                                    <div v-if="selectedEmail.status != 'ANSWERED' && (!selectedEmail.user_id || selectedEmail.user_id == user.id )" class="px-2">
                                        <button class="btn btn-primary ml-2" v-if="!showMessageBox" @click="triggerReply">Responder</button>
                                    </div>
                                </div>
                                <form v-if="showMessageBox && !selectedEmail.reply" class="d-flex border-top" @submit.prevent.stop="replyEmail(selectedEmail.id)">
                                    <!-- <textarea id="boxReplyMessage" style="height: 150px;" class="flex-grow-1 w-50 p-2" placeholder="Escribir respuesta" v-model="messageReply"></textarea> -->
                                    <quill-editor id="boxReplyMessage" ref="editor" class="flex-grow-1 w-50 p-2" 
                                        :options="editorOption"
                                        v-model="messageReply">
                                    </quill-editor>
                                    <button class="btn btn-primary no-shadow rounded-0" type="submit">
                                        Enviar 
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Respuesta Rápida -->
        <div class="modal fade" id="quickReplyModal" tabindex="-1" role="dialog" aria-labelledby="quickReplyLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="quickReplyLabel">Respuesta Rápida</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="list-group list-group-flush">
                            <a v-for="(reply, index) in quickReplies" :key="index" 
                            v-on:click="setReplyContent(reply.content)" 
                            :class="{'border-top' : index == 0, 'border-bottom': (index + 1) == quickReplies.length}"
                            class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">Respuesta Número {{ index + 1}}</h5>
                                    <small>{{ reply.created_at | moment('from', 'now') }}</small>
                                </div>
                                <div class="mb-1">{{ toPlaintText(reply.content) }}</div>
                            </a>
                        </div>
                        <p v-if="quickReplies.length == 0" class="mx-4">No hay respuestas rápidas...</p>
                    </div>
                    <div class="modal-footer">
                        <button v-can:manage type="button" class="btn btn-primary" data-toggle="modal" data-target="#newQuickReplyModal">Nueva Respuesta Rápida</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Nueva Respuesta Rápida -->
        <div class="modal fade" id="newQuickReplyModal" tabindex="-1" role="dialog" aria-labelledby="newQuickReplyLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="newQuickReplyLabel">{{ quickReplyForm.id ? 'Editar' : 'Nueva' }} Respuesta Rápida</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="quickReplyForm" autocomplete="off" class="needs-validation" @submit.prevent="saveEmailQuickReplies">
                        <div class="modal-body py-0">
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group mb-0 editor-height-sm">
                                        <quill-editor id="quickReply" name="quickReply" ref="quickReplyEditor" :options="quickResponseEditor" v-model="quickReplyForm.quickReply"></quill-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary">{{ quickReplyForm.id ? 'Editar' : 'Guardar' }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal Firma de Correo -->
        <div class="modal fade" id="emailSignatureModal" tabindex="-1" role="dialog" aria-labelledby="emailSignatureLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="emailSignatureLabel">Firma de Correo Electrónico</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="signatureForm" autocomplete="off" class="needs-validation" @submit.prevent="saveEmailSignature">
                        <div class="modal-body py-0">
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group mb-0 editor-height-sm">
                                        <quill-editor id="signature" name="signature" ref="signatureEditor" :options="signatureEditor" v-model="signatureForm.signature"></quill-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
export default {
    middleware:'auth',
    layout: 'dashboard',
    metaInfo() {
        return {
            title: this.selectedEmail ? this.selectedEmail.subject : "Soporte Email"
        }
    }, 
    data() {
        return {
            menuData: [
                {
                    section: 'unanswered',
                    icon: 'icon-download-arrow',
                    title: 'Nuevos'
                },
                {
                    section: 'taken',
                    icon: 'icon-clock-hand',
                    title: 'En espera'
                },
                {
                    section: 'answered',
                    icon: 'icon-read-email',
                    title: 'Respondidos'
                },
            ],
            section: 'unanswered',
            currentEmail: null,
            showMessageBox: false,
            messageReply: '',
            quickReplies: [],
            quickReplyForm: new WebForm("#quickReplyForm", {
                id : null,
                quickReply : '',
            }),
            signatureForm: new WebForm("#signatureForm", {
                signature : '',
            }),
            editorOption: {
                theme: 'bubble',
                placeholder: "Escribir respuesta...",
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'color': [] }, { 'background': [] }],
                        ['link', 'image']
                    ]
                }
            }
        }
    },
    computed: {
        sectionPagination () {
            return this.paginationEmails[this.section]
        },
        sectionEmails () {
            let type = `${this.section}Emails`
            let emails = this[type]
            return emails
        },
        selectedEmail() {
            return this.emailById(this.currentEmail)
        },
        ...mapGetters({
            user : 'auth/user',
            displayName : 'auth/displayName', 
            unansweredEmails: 'support/unansweredEmails',
            answeredEmails: 'support/answeredEmails',
            takenEmails: 'support/takenEmails',
            emailById: 'support/emailById',
            unansweredEmailsCounter: 'support/unansweredEmailsCounter',
            takenEmailsCounter: 'support/takenEmailsCounter',
            answeredEmailsCounter: 'support/answeredEmailsCounter',
            paginationEmails: 'support/paginationEmails',
        }),
        quickResponseEditor(){
            return {
                placeholder: "Escribir respuesta rápida...",
                modules: this.editorOption.modules
            }
        },
        signatureEditor(){
            return {
                placeholder: "Escribir contenido de firma...",
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],
                        ['link', 'image']
                    ]
                }
            }

        }
    },
    watch: {
        '$route.params.id': function(id) {
            this.currentEmail = id
            console.log(id)
        },
        currentEmail(newVal, oldVal) {
            if(newVal != oldVal) {
                this.showMessageBox = false
            }
        }
    },
    methods: {
        loadMoreEmails(type, page) {
            this.getEmails({type, page})
        },
        getCounter(section) {
            return this[`${section}EmailsCounter`]
        },
        replyLater (id) {
            let vm = this
            let endpoint = `/admin/support/email/reply/later`
            axios.post(endpoint, {
                id,
            }).then(({data})=> {
                vm.updateStatusEmail(data)
            })
        },
        triggerReply () {
            let vm = this
            vm.showMessageBox = true

            setTimeout(() => {
                let $boxReplyMessage = vm.$el.querySelector('#boxReplyMessage')
                $boxReplyMessage.focus()
            }, 300)

        },
        replyEmail (id) {
            let vm = this
            let endpoint = `/admin/support/email/reply`
            let emailReply = this.toPlaintText(this.messageReply)

            if(emailReply.trim() == ''){
                vm.$snotify.warning("El mensaje no debe estar vacio")
                return
            }

            axios.post(endpoint, {
                id,
                content: this.messageReply
            }).then(({data})=> {
                vm.messageReply = ''
                vm.updateStatusEmail(data)
                vm.setReplyEmail(data.reply)
            })
        },
        getDate(d) {
            let now = this.$moment()
            let emailDate = this.$moment(d)
            let duration = this.$moment.duration(now.diff(emailDate))
            let hours = parseInt(duration.asHours())
            let sameMont = emailDate.isSame(now, 'month')
            if(hours  < 20) {
                return emailDate.fromNow()
            }
            if(sameMont) {
                return emailDate.format('ddd DD')
            }
            return emailDate.format('MMM DD')
            
        },
        selectEmail(id) {
            this.$router.push(`/support/email/${id}`)
            this.currentEmail = id
        },
        ...mapActions({
            getEmail: 'support/getEmail',
            getEmails: 'support/getEmails'
        }),
        ...mapMutations({
            updateStatusEmail: 'support/UPDATE_STATUS_EMAIL',
            setReplyEmail: 'support/SET_REPLY_EMAIL'
        }),
        getEmailQuickReplies() {
            let vm = this
            axios.get(`/admin/support/email/quick/reply`)
            .then(({data})=> {
                console.log('REPLY', data)
                vm.quickReplies = data || []
            })
        },
        saveEmailQuickReplies() {
            let vm = this
            vm.quickReplyForm.post(`/admin/support/email/quick/reply`)
            .then(({data})=> {
                vm.getEmailQuickReplies()
                $("#newQuickReplyModal").modal('hide')
            })
        },
        getEmailSignature() {
            let vm = this
            axios.get(`/admin/support/email/signature`)
            .then(({data}) => {
                console.log('SIGNATURE', data)
                vm.signatureForm.signature = data.content || ''
            })
        },
        saveEmailSignature() {
            this.signatureForm.post(`/admin/support/email/signature`)
            .then(({data})=> {
                $("#emailSignatureModal").modal('hide')
            })
        },
        setReplyContent(content){
            this.messageReply = content
            this.triggerReply()
            $("#quickReplyModal").modal('hide')
        },
        toPlaintText(html, limit = 100){
            var text = html.replace(/<[^>]+>/gm, ' ').split(' ').filter(e => e).join(' ')
            return text.length > limit ? `${ text.substring(0, limit-3) }...` : text
        }
    },
    async created () {
        await this.getEmails({type: 'taken'})
        await this.getEmails({type: 'answered'})
    },
    mounted () {

        if(this.$route.params.id) {
            this.currentEmail = this.$route.params.id
            // if(!this.selectedEmail) {
            //     this.getEmail(this.currentEmail)
            // }
        }
        
        this.getEmailSignature()
        this.getEmailQuickReplies()
    }
}
</script>