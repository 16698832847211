<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Hoteles</h5>
                    <p class="text-muted mb-1">Lista de hoteles disponibles</p>
                </div>
                <div>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent="goTo('packages-hotels-furnishings-index')">
                        <span class="icon-menu-4 mr-2"></span> Mobiliario
                    </button>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent="goTo('packages-hotels-categories-index')">
                        <span class="icon-menu-4 mr-2"></span> Categorias
                    </button>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent="goTo('packages-hotels-services-index')">
                        <span class="icon-menu-4 mr-2"></span> Servicios
                    </button>
                    <button class="btn btn-info center-content d-inline-block" @click.prevent="goTo('packages-hotels-rooms-index')">
                        <span class="icon-menu-4 mr-2"></span> Tipo Habitaciones
                    </button>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalHotel">
                        <span class="icon-add-2 mr-2"></span> Nuevo Hotel
                    </button>
                </div>
            </div>

            <hotel-modal :Hotel="Hotel" v-on:closeHotelModal="clearHotelVar()" 
            v-on:reloadHotel="reloadHotel()" :HotelCategoryNew="HotelCategory"
            :StateNew="State" :CityNew="City" :HotelServiceNew="HotelService">
            </hotel-modal>
            
            <!-- Modals Inner HotelModal -->
            <hotel-category-modal v-on:reloadHotelCategoryModal="reloadHotelCategory"></hotel-category-modal>
            <state-modal v-on:reloadStateModal="reloadState"></state-modal>
            <city-modal v-on:reloadCityModal="reloadCity"></city-modal>
            <hotel-service-modal v-on:reloadHotelServiceModal="reloadHotelService"></hotel-service-modal>
            <!--  -->

            <hotel-room-modal :Hotel="Hotel" v-on:closeHotelRoomModal="clearHotelRoomVar()" v-on:reloadHotelRoom="reloadHotel()"
            v-on:createdHotelRoom="reloadHotel()"></hotel-room-modal>

            <hotel-gallery-modal :Gallery="Gallery" v-on:closeHotelGalleryModal="clearVarGallery()"></hotel-gallery-modal>
         
            <data-table :ajax="{ url: '/admin/hotels/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="hotelTable" :serverSide="true" :columns="dt.columns()"></data-table>
        
        </div>
    </div>
</template>

<script>

    import dataTable from '~/components/dataTable'
    import HotelModal from '~/components/packages/HotelModal';
    import HotelCategoryModal from '~/components/packages/HotelCategoryModal';
    import StateModal from '~/components/geodatos/StateModal';
    import CityModal from '~/components/geodatos/CityModal';
    import HotelServiceModal from '~/components/packages/HotelServiceModal';

    import HotelRoomModal from '~/components/packages/HotelRoomModal';
    import HotelGalleryModal from '~/components/packages/HotelGalleryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            HotelModal,
            HotelCategoryModal,
            StateModal,
            CityModal,
            HotelServiceModal,
            HotelRoomModal,
            HotelGalleryModal
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                Hotel                       : null,
                HotelCategory               : null,
                State                       : null,
                City                        : null,
                HotelService                : null,
                Gallery                     : null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'Nombre', data: 'name' },
                                { title: 'Location', data: 'city',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let name = data.name + ' ' + data.state.name;
                                    return  `
                                    <img src="https://www.countryflags.io/${data.state.country.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.state.country.short_iso_code}">
                                    <small>${data.state.country.name} </br>${name}</small>`;
                                }},
                                { title: 'Categoria', data: 'hotel_category', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `${data.name}`;
                                }},
                                { title: 'Habitaciones', data: null, class:"view-rooms", orderable: false, searchable: false,
                                render: function ( data, type, row, meta) {
                                    let button     =  $this.$can('write')  ? `
                                    <button class="btn btn-secondary btn-sm add-button btn-block"
                                    data-toggle="modal" data-target="#modalHotelRoom"><span class="icon-add-2 mr-1"></span> Agregar Habitacion
                                    </button>` : ``;
                                    let hotel_rooms = ``;
                                                                                                                                                                    
                                    data.hotel_room.map((element, index) => {
                                        if(element.type_room ) {
                                            let btn_i18n_room = (element.i18n.length>0) ? `<button class="btn btn-secondary btn-sm font-weight-bold btn_i18n_room" data-idx="${index}" data-toggle="modal" data-target="#modalHotelRoom" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn bg-white btn-outline-secondary btn-sm font-weight-bold btn_i18n_room" data-idx="${index}" data-toggle="modal" data-target="#modalHotelRoom" title="Traducción"><span class="icon-translate"></span></button>`;
                                            hotel_rooms += `
                                            <p class="item-room">
                                                ${btn_i18n_room}
                                                <span data-toggle="modal" data-idx="${index}" data-target="#modalHotelRoom" class="val-room pointer ml-3"> Habitación ${element.type_room.title}</span>
                                            </p>`;
                                        }
                                    });
                                    return `${hotel_rooms + button}`;
                                }},
                                // { title: 'phone', data: null,  orderable: false, searchable: false,
                                // render: function ( data, type, row) {
                                //     return  (data.phone_number) ? `(${data.country_code}) +${data.country_calling_code} ${data.phone_number}` : '';
                                // }},
                                { title: 'airports', data: 'hotel_airport_detail',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let htmlText = '';
                                    data.forEach(element => {
                                        htmlText = htmlText + '<p><small>(' + element.airport[0].iata + ') ' + element.airport[0].name + '</small></p>';
                                    });
                                    return `${htmlText}`;
                                }},
                                { title: 'Options', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalHotel" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#modalHotel" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let btngal      =  $this.$can('write')  ? `<button class="btn btn-primary btn-sm font-weight-bold btn-gal" data-id="${data.id}" data-toggle="modal" data-target="#modalHotelGallery">Galeria</button>&nbsp; ` : `-`;
                                        let btnedit     =  $this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#modalHotel">Editar</button>&nbsp;` : `-`;
                                        let btndelete   =  $this.$can('write')  ? `<button class="btn btn-danger btn-sm font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>&nbsp; ` : `-`;
                                        return `<div class="d-flex">${ btni18n + btngal + btnedit + btndelete }</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            reloadHotelCategory(obj) {
                this.HotelCategory = null;
                this.HotelCategory = obj;
                setTimeout(function(){
                    this.HotelCategory = null;
                }, 1000);
            },
            reloadState(obj) {
                this.State = null;
                this.State = obj;
                setTimeout(function(){
                    this.State = null;
                }, 1000);
            },
            reloadCity(obj) {
                this.City = null;
                this.City = obj;
                setTimeout(function(){
                    this.City = null;
                }, 1000);
            },
            reloadHotelService(obj) {
                this.HotelService = null;
                this.HotelService = obj;
                setTimeout(function(){
                    this.HotelService = null;
                }, 1000);
            },
            clearVarGallery() {
                this.Gallery = null;
            },
            clearHotelRoomVar() {
                this.Hotel = null;
            },
            goTo: function (route_to_go) {
                this.$router.push({ name: route_to_go})
            },
            clearHotelVar() {
                this.Hotel = null;
            },
            reloadHotel() {
                this.$refs.hotelTable.ajaxReload();
            },
            onDelete(hotel) {
                let vm = this;
                $.confirm.delete(
                    'Eliminar Hotel',
                    `¿Está seguro que desea eliminar el Hotel <b>${hotel.name}</b>?`, 
                    function(){
                        vm.deleteItem(hotel.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/hotels/delete', { id: idDel }).then(response => {
                    let data = response.data;
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        vm.$refs.hotelTable.ajaxReload();
                        return;
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            },
        },
        mounted() {
            var vm = this

            $(vm.$el).off("click.hotel",".btn-gal");
            $(vm.$el).on("click.hotel",".btn-gal",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                vm.Gallery = hotel.id;
            });

            $(vm.$el).off("click.hotel",".btn-edit");
            $(vm.$el).on("click.hotel",".btn-edit",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                hotel.btn_i18n = false;
                vm.Hotel = hotel;
            });
            
            $(vm.$el).off("click.hotel",".add-button");
            $(vm.$el).on("click.hotel",".add-button",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                hotel.hotel_room_idx = null;
                hotel.btn_i18n = false;
                vm.Hotel = hotel;
            });

            $(vm.$el).off("click.hotel",".btn-i18n");
            $(vm.$el).on("click.hotel",".btn-i18n",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                hotel.btn_i18n = true;
                vm.Hotel = hotel;
            });

            $(vm.$el).off("click.hotel",".btn_i18n_room");
            $(vm.$el).on("click.hotel",".btn_i18n_room",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                let hotel_room_idx = $(this).attr("data-idx");
                hotel.btn_i18n = false;
                hotel.hotel_room_idx = hotel_room_idx;
                hotel.hotel_room_i18n = true;
                vm.Hotel = hotel;
            });

            $(vm.$el).off("click.hotel",".val-room");
            $(vm.$el).on("click.hotel",".val-room",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                let hotel_room_idx = $(this).attr("data-idx");
                hotel.btn_i18n = false;
                hotel.hotel_room_idx = hotel_room_idx;
                hotel.hotel_room_i18n = false;
                vm.Hotel = hotel;
            });
        
            $(vm.$el).off("click.hotel",".btn-delete");
            $(vm.$el).on("click.hotel",".btn-delete",function(){
                let hotel = vm.$refs.hotelTable.rowData($(this).closest('tr'));
                vm.onDelete(hotel);
            });
           
        },
    }
</script>

<style>
    .view-rooms {
        position: relative;
    }

    .view-rooms:hover .add-button {
        opacity: 1;
    }
    .view-rooms .add-button {
        position: static;
        display: inline-block;
        margin-top: 2px;
        opacity: 0;
        transition: 0.3s;
    }
    .item-room {
        /* display: inline-block; */
        background-color: #c0c0c0;
        font-size: 11px;
        color: #000;
        /* cursor: pointer; */
        padding: 2px 10px;
        margin: 5px 0px;
        /* text-decoration: underline; */
        border-radius: 5px;
        /* opacity: 0.8; */
        transition: 0.4s;
    }
    .item-room:hover {
        background-color: #747474;
        color: #fff;
        /* opacity: 1.0; */
        /* font-weight: 600; */
        /* background-color: rgb(230, 230, 230); */
    }
</style>