import { render, staticRenderFns } from "./RoomServiceModal.vue?vue&type=template&id=6366049d&"
import script from "./RoomServiceModal.vue?vue&type=script&lang=js&"
export * from "./RoomServiceModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports