<template>
    <div>
        <div class="modal fade" id="modalAirport">
            <div class="modal-dialog modal-lg"> <!--  modal-lg -->
                <div class="modal-content">
                    <form id="formAirport" autocomplete="off" @submit.prevent="onSaveAirport()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="formAirport && formAirport.id">Editar Aeropuerto</span>
                                <span v-else>Nuevo Aeropuerto</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="name" class="required">Nombre de Aeropuerto</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formAirport.name" name="name">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="country_id" class="required">Pais</label>
                                    <v-select v-model="country" name="country_id" 
                                    @search="searchCountry" ref="vSelectCountry" 
                                    placeholder="Seleccione Pais" selectOnTab
                                    :options="countries" required="required">
                                        <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="region_timezone_id" class="required">
                                        Zona Horaria 
                                        <span v-if="!loading.zones" class="icon-refresh icon-for-reload" title="Reload" @click="getRegionTimezones()"></span>
                                        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </label>
                                    <v-select autocomplete="off" v-model="zone" name="region_timezone_id" ref="vSelectZone" placeholder="Seleccione Zona"
                                    :options="zones" label="name"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="state" class="required" style="width: 100%">
                                        Estado
                                        <span v-if="!loading.states" class="icon-refresh icon-for-reload" title="Reload" @click="getStates()"></span>
                                        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addState">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select autocomplete="off"  v-model="state" name="state" ref="vSelectState" placeholder="Seleccione Estado" :options="states"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="city" class="required" style="width: 100%">
                                        Ciudad
                                        <span v-if="!loading.cities" class="icon-refresh icon-for-reload" title="Reload" @click="getCities()"></span>
                                        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addCity">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="city" name="city" ref="vSelectCity" placeholder="Seleccione Ciudad" :options="cities"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="iata" class="required">Codigo IATA</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Codigo IATA" v-model="formAirport.iata" name="iata">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="icao" class="required">Codigo ICAO</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Codigo ICAO" v-model="formAirport.icao" name="icao">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="latitude" class="required">Latitude</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Latitude" v-model="formAirport.latitude" name="latitude">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="longitude" class="required">Longitude</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Longitude" v-model="formAirport.longitude" name="longitude">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-hotels-index'" > 
                                <span v-if="formAirport.id && $can('update','packages-hotels-index')">
                                    Guardar Cambios
                                </span>
                                <span v-else-if="formAirport.id && !$can('update','packages-hotels-index')">
                                    Solicitar Edicion
                                </span>
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            Airport: {
                type : [Object, null],
                default: null,
            },
            StateNew: {
                type: [Object, null],
                default: null,
            },
            CityNew: {
                type: [Object, null],
                default: null,
            }
        },
        data() {
            return {
                searchDelay                 : null,
                countryCode                 : null,
                formAirport: new WebForm('#formAirport', {
                    id                      : null,
                    name                    : null,
                    iata                    : null,
                    icao                    : null,
                    country_id              : null,
                    city                    : null,
                    state                   : null,
                    latitude                : null,
                    longitude               : null,
                    region_timezone_id      : null
                }),
                countries                   : [],
                zones                       : [],
                states                      : [],
                cities                      : [],
                country                     : null,
                zone                        : null,
                state                       : null,
                city                        : null,
                autocomplete                : null,
                loading: {
                    zones: false,
                    cities: false,
                    states: false
                }
            }
        },
        watch: {
            country: function(val, oldVal) {
                this.$refs.vSelectState.clearSelection();
                this.states = [];
                if (!_.isEmpty(this.country)) {
                    let code = this.country.code;
                    this.countryCode = code;
                    this.getStates();
                }
            },
            state: function(val, oldVal) {
                this.$refs.vSelectCity.clearSelection();
                this.cities = [];
                if (!_.isEmpty(this.state)) {
                    this.$refs.vSelectCity.clearSelection();
                    this.getCities();
                }
            },
            StateNew: function(val, oldVal) {
                if (!_.isEmpty(this.StateNew) && this.country && this.country.id) {
                    if (this.StateNew && this.StateNew.id && this.StateNew.country_id == this.country.id) {
                        let state_ = {
                            id: this.StateNew.id,
                            label: `${this.StateNew.name}`,
                            name: `${this.StateNew.name}`,
                        };
                        this.states.push(state_);
                        this.state = state_;
                    }
                }
            },
            CityNew: function(val, oldVal) {
                if (!_.isEmpty(this.CityNew) && this.state && this.state.id) {
                    if (this.CityNew && this.CityNew.id && this.CityNew.state_id == this.state.id) {
                        let city_ = {
                            id: this.CityNew.id,
                            label: `${this.CityNew.name}`,
                            name: `${this.CityNew.name}`,
                        };
                        this.cities.push(city_);
                        this.city = city_;
                    }
                }
            },
            Airport: function(val, oldVal) {
                let vm = this;
                if ( !_.isEmpty(vm.Airport) && vm.Airport != null) {
                    vm.formAirport.id = vm.Airport.id;
                    vm.formAirport.name = vm.Airport.name;
                    vm.formAirport.iata = vm.Airport.iata;
                    vm.formAirport.icao = vm.Airport.icao;
                    vm.formAirport.latitude = vm.Airport.latitude;
                    vm.formAirport.longitude = vm.Airport.longitude;
                    vm.zone = vm.Airport.region_timezone;
                    vm.getCountry(vm.Airport.country_id);
                    vm.formAirport.state = vm.Airport.state;
                    vm.formAirport.city = vm.Airport.city;
                } else {
                    vm.resetAll();
                }
            },
        },
        methods: {
            getRegionTimezones() {
                let vm = this;
                vm.loading.zones = true;
                axios.get('/admin/location/regiontimezones/all').then((response) => {
                    if (response && response.data) {
                        vm.zones = response.data;
                    } else {
                        vm.$snotify.warning('Las zonas horarias no fueron cargadas.');
                    }
                    vm.loading.zones = false;
                });
            },
            getStates(){
                let vm = this;
                if (vm.country && vm.country.hasOwnProperty('id') && vm.country.id) {
                    vm.loading.states = true;
                    let endpoint = `/admin/location/states/country/${vm.country.id}`;
                    axios.get(endpoint).then(({data}) => {
                        let states = data.map(state => {
                            let d = {
                                id: state.id,
                                label: `${state.name}`,
                                name: `${state.name}`,
                            }
                            return d;
                        });
                        this.states = states;
                        if(vm.formAirport!=null && vm.formAirport.state) {
                            vm.state = vm.states.find(c => {
                                return c.name == vm.formAirport.state;
                            });
                            if (vm.state==null) {
                                vm.$snotify.warning(`El estado ${vm.formAirport.state} no fue encontrado.`);
                            }
                        }
                        vm.loading.states = false;
                    }).catch( () => {
                        vm.$snotify.warning('Los estados no fueron cargadas.');
                    });
                }
            },
            getCities(){
                let vm = this;
                if (vm.state && vm.state.hasOwnProperty('id') && vm.state.id) {
                    vm.loading.cities = true;
                    let endpoint = `/admin/location/cities/state/${vm.state.id}`;
                    axios.get(endpoint).then(({data}) => {
                        let cities = data.map(city => {
                            let d = {
                                id: city.id,
                                label: `${city.name}`,
                                name: `${city.name}`,
                            }
                            return d;
                        });
                        this.cities = cities;
                        if(vm.formAirport!=null && vm.formAirport.city) {
                            vm.city = vm.cities.find(c => {
                                return c.name == vm.formAirport.city;
                            });
                            if (vm.city==null) {
                                vm.$snotify.warning(`La ciudad ${vm.formAirport.city} no fue encontrada.`);
                            }
                        }
                        vm.loading.cities = false;
                    }).catch( () => {
                        vm.$snotify.warning('Las ciudades no fueron cargadas.');
                    });
                }
            },
            searchCountry(search, loading) {
                if (search.length>2 ) {
                    loading(true);
                    const vm = this;
                    clearInterval(vm.searchDelay);
                    vm.searchDelay = setInterval(function(){
                        clearInterval(vm.searchDelay);
                        axios.get(`/admin/location/countries/${search}`).then((response) => {
                            if (response.data) {
                                let countries = response.data.map(y => {
                                    let country = y;
                                    let d = {
                                        id      :       country.id,
                                        code    :       country.short_iso_code,
                                        name    :       `${country.name} (${country.native_name})`,
                                        label   :       `${country.name} (${country.native_name})` 
                                    }
                                    return d;
                                });
                                vm.countries = countries;
                            } else {
                                console.log('No se pudieron cargar los paquetes');
                            }
                            loading(false);
                        }).catch( () => {
                            vm.$snotify.warning('Los paises no fueron cargadas.');
                        });
                    }, 500);
                }
            },
            getCountry(id) {
                let vm = this;
                axios.get(`/admin/location/countries/${id}`).then((response) => {
                    if (response.data) {
                        let data = response.data;
                        let countries = [{
                            id      :       data.id,
                            code    :       data.short_iso_code,
                            name    :       `${data.name} (${data.native_name})`,
                            label   :       `${data.name} (${data.native_name})` 
                        }];
                        vm.countries = countries;
                        vm.country = vm.countries[0];
                        vm.getStates();
                    } else {
                        console.log('No se pudieron cargar los paquetes');
                    }
                }).catch( () => {
                    vm.$snotify.warning('El pais no fue cargado.');
                });
            },
            onSaveAirport() {
                let vm = this
                if(vm.zone && vm.zone.id) {
                    vm.formAirport.region_timezone_id = vm.zone.id;
                }
                if(vm.country && vm.country.id) {
                    vm.formAirport.country_id = vm.country.id;
                }
                if(vm.state && vm.state.id) {
                    vm.formAirport.state = vm.state.name;
                }
                if(vm.city && vm.city.id) {
                    vm.formAirport.city = vm.city.name;
                }

                let tipo = (vm.formAirport.id != null) ? 1 : 2;
                if(this.formAirport.id != null ) {
                    $.confirm({
                        title: `Editar Aeropuerto`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (type) {
                let vm = this;
                vm.formAirport.post('/admin/airports/save').then(response => {
                    let data = response.data;

                    if (data.user_request_status) {
                        $('#modalAirport').modal('hide');
                        return;
                    }

                    let snotify_message = type != 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);

                    let obj = data.success;
                    vm.$emit('reloadAirport', obj);
                    $('#modalAirport').modal('hide');
                    return;
                });
            },
            resetAll() {
                let vm = this
                vm.formAirport.clear();
                vm.formAirport.reset();
                vm.$refs.vSelectCountry.clearSelection();
                vm.$refs.vSelectZone.clearSelection();
                vm.$refs.vSelectState.clearSelection();
                vm.$refs.vSelectCity.clearSelection();
                vm.country  = null;
                vm.zone = null;
                vm.state = null;
                vm.city = null;
            }
        },
        async mounted() {
            let vm = this
            await vm.getRegionTimezones();

            this.$nextTick(() => {
                $('#modalAirport').off('hidden.bs.modal');
                $('#modalAirport').on('hidden.bs.modal', function () {
                    vm.resetAll();
                    vm.$emit('closeAirportModal');
                });

                $('form').on('keyup keypress', function(e) {
                    var keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                });
            });
        },
    }
</script>
<style>

.modal#modalAirport{
    overflow-y: auto !important;
}
</style>