<template>
    <div class="modal fade" id="modalTimezone">
        <div class="modal-dialog">
            <div class="modal-content">
                <form :id="'formTimezone-'+_uid" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formTimezone.id">Editar Zona Horaria</span>
                            <span v-else>Nueva Zona Horaria</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 ">
                                <label for="name" class="required">Nombre</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre"
                                v-model="formTimezone.name" name="name" />
                            </div>
                            <div class="form-group col-12 ">
                                <label for="description" class="required">Descripcion</label>
                                <input type="text" class="form-control" placeholder="Ingrese Descripcion"
                                v-model="formTimezone.description" name="description" />
                            </div>
                            <div class="form-group col-12">
                                <label for="abbr" class="required">Abreviacion</label>
                                <input type="text" class="form-control" v-model="formTimezone.abbreviation"
                                placeholder="Ingrese Abreviacion" name="abbreviation">
                            </div>
                            <div class="form-group col-12">
                                <label for="offset" class="required">Offset</label>
                                <input type="text" class="form-control" v-model="formTimezone.offset"
                                placeholder="Ingrese Offset" name="offset">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'geodata-timezones'">
                            <span v-if="formTimezone.id && $can('update','geodata-timezones')">Editar</span>
                            <span v-else-if="formTimezone.id && !$can('update','geodata-timezones')">Solicitar Edicion</span>
                            <span v-else>Guardar</span>
                        </button>
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["Timezone"],
    components: {
        },
    data() {
        return {
            countries   : [],
            formTimezone: new WebForm("#formTimezone-" + this._uid, {
                name            : null,
                description     : null,
                abbreviation    : null,
                offset          : null,
                id              : null
            }),
        };
    },
    watch: {
        Timezone: function(val, oldVal) {
            if (_.isEmpty(this.Timezone) || this.Timezone.id == '') {
                // console.log('Vacio Att: ', this.AttractionCategory);    
            }else {
                this.formTimezone.fill(this.Timezone);
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;

            let tipo = (vm.formTimezone.id == null) ? 1 : 2;
            if (this.formTimezone.id != null) {
                $.confirm({
                    title: `Editar Zona Horaria`,
                    content: "¿Seguro quiere editar esta Zona?",
                    buttons: {
                        eliminar: {
                            text: "Editar",
                            btnClass: "btn-primary",
                            keys: ["enter"],
                            action: function() {
                                vm.updateOrCreate("/admin/location/timezones/save", tipo);
                            }
                        },
                        cancelar: function() {}
                    }
                });
            } else {
                vm.updateOrCreate("/admin/location/timezones/save", tipo);
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formTimezone.post(url).then(response => {
                let data = response.data;

                console.log(data);
                if (data.user_request_status) {
                    $('#modalTimezone').modal('hide');
                    return;
                }

                let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                vm.$snotify.success(snotify_message);
                vm.$emit('reloadTimezoneModal');
                vm.formTimezone.clear();
                vm.formTimezone.reset();
                $('#modalTimezone').modal('hide');
                return;

            });
        }
    },
    mounted() {
        let vm = this;
        $('#modalTimezone').off('hidden.bs.modal');
        $('#modalTimezone').on('hidden.bs.modal', function () {
            vm.formTimezone.clear();
            vm.formTimezone.reset();
            vm.$emit('closeTimezoneModal');
        });
    }
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>