<template>
	<div class="modal fade" id="modalRegion">
		<div class="modal-dialog">
			<div class="modal-content">
				<form id="formRegion" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span v-if="formRegion.id">Editar Región</span>
							<span v-else>Nueva Región</span>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="name">Nombre Región</label>
							<input type="text" class="form-control"
							placeholder="Ingrese Nombre" v-model="formRegion.name" name="name"/>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary"
							v-can:create="'geodata-regions'" >
							<span v-if="formRegion.id && $can('update','geodata-regions')">Editar</span>
							<span v-else-if="formRegion.id && !$can('update','geodata-regions')">Solicitar Edicion</span>
							<span v-else>Guardar Region</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["Region"],
	data() {
		return {
			formRegion: new WebForm("#formRegion", {
				name: "",
				id: null
			})
		};
	},
	watch: {
		Region: function(val, oldVal) {
			if ( _.isEmpty(this.Region) || this.Region.id == "") {
				// 
			} else {
				this.formRegion.fill(this.Region);
			}
		}
	},
	methods: {
		onSaveItem() {
			let vm = this;

			let tipo = vm.formRegion.id == null ? 1 : 2;
			if (this.formRegion.id != null) {
				$.confirm({
					title: `Editar Region`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function() {
								vm.updateOrCreate("/admin/location/regions/save", tipo);
							}
						},
						cancelar: function() {}
					}
				});
			} else {
				vm.updateOrCreate("/admin/location/regions/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formRegion.post(url).then(response => {
				let data = response.data;

				if (data.user_request_status) {
					$("#modalRegion").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento Actualizado con exito.";
				vm.$snotify.success(snotify_message);
				$("#modalRegion").modal("hide");
				vm.formRegion.clear();
				vm.formRegion.reset();
				vm.$emit("reloadRegionModal");
				return;
			});
		}
	},
	mounted() {
		let vm = this;
		$("#modalRegion").off("hidden.bs.modal");
		$("#modalRegion").on("hidden.bs.modal", function() {
			vm.formRegion.clear();
			vm.formRegion.reset();
			vm.$emit("closeRegionModal");
		});
	}
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>