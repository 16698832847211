<template>
    <div class="modal fade" id="editSubscriberModal" tabindex="-1" role="dialog" aria-labelledby="editSubscriberModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editSubscriberModalLabel">Información Subscriptor</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-wrap mx-n1">
                    <div class="col-12 col-lg-7 px-1">
                        <div class="d-flex mx-n2 mb-2">
                            <div class="col-3 px-2">
                                <div class="customer-image">
                                    <img class="rounded-circle" :src="photo" :onerror="imgNotFound()">
                                </div>
                            </div>
                            <div class="col-9 px-2">
                                <h5 class="title m-0">
                                    {{ fullName }}
                                </h5>
                                <p class="text-muted">
                                    {{ subscriber.email_address }}
                                </p>

                                <p class="mb-0">
                                    Agregado el {{ subscriber.timestamp_opt | moment('LL') }}
                                </p>
                                <div>
                                    <StarRating
                                        v-model="subscriber.member_rating"
                                        :size="5"
                                        :editable="false" :zero="false"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <h5 class="title">Tags</h5>
                            <v-select name="views" taggable
                                v-model="tags"
                                ref="vSelectTags" placeholder="Ingrese Etiquetas"
                                multiple @keypress.enter.native.prevent="" push-tags
                                @option:created="setFocusViews"
                                @input="changeTags">
                            </v-select>
                        </div>
                        <hr>
                        <div class="d-none d-lg-block">
                            <div class="mb-2">
                                <div class="d-flex">
                                    <label for="notes" class="d-block m-0">Notas</label>
                                    <span v-if="noteCharacters > 0" class="ml-auto" :class="{'text-danger': noteCharacters < 20}">{{ noteCharacters }} carateres restantes</span>
                                    <span v-else class="ml-auto text-danger">Maximo de caracteres</span>
                                </div>
                                <textarea v-model="note" maxlength="1000" class="form-control" id="notes" cols="30" rows="3" placeholder="Escribir nota"></textarea>
                                <transition name="fade">
                                    <div v-show="note.length > 10" class="text-right py-2">
                                        <button class="btn btn-primary" @click="addNote">Guardar</button>
                                    </div>
                                </transition>
                            </div>
                            <transition-group name="fade" tag="div" v-if="notes.length">
                                <div class="py-1" v-for="(note, index) in notes" :key="`note-${note.id}-${index}`">
                                    <p class="m-0 subscriber-note">{{note.note}}</p>
                                    <p class="small text-muted m-0">{{ note.created_at | moment('LL LT') }}</p>
                                </div>
                            </transition-group>
                            <transition name="fade">
                                <div v-if="notesLoading" class="text-center py-3">
                                    <DotsLoader  message="Cargando Notas"/>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 px-1">
                        <div>
                            <h5 class="">Estadisticas</h5>
                            <div class="d-flex subscriber-stats">
                                <div class="col-6">
                                    <div class="text-center">
                                        <span class="d-block">{{ subscriber.stats.avg_open_rate }} %</span>
                                        <span class="d-block text-muted small">
                                            Emails Abiertos
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-center">
                                        <span class="d-block">{{ subscriber.stats.avg_click_rate }} %</span>
                                        <span class="d-block text-muted small">
                                            Clicks
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <hr>
                        <div >
                            <div class="">
                                <h5 class="title">Información del subscriptor</h5>
                            </div>
                            <div v-if="! $can('write','marketing-email-subscribe-index')" >
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Email</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.email_address || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Nombre</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.FNAME">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Apellido</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.LNAME">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Telefono</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.PHONE">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Dirrección</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.ADDRESS.addr1">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Ciudad</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.ADDRESS.city">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Estado</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.ADDRESS.state">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Zip code</label>
                                    <input class="form-control col-9 px-1" type="text" v-model="subscriber.merge_fields.ADDRESS.zip">
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Pais</label>
                                    <div class="col-9 px-0" id="searchCoutryEditSubs">
                                        <v-select v-model="subscriber.merge_fields.ADDRESS.country" 
                                        @search="searchCountry" ref="vSelectCountry" 
                                        placeholder="Seleccione Pais"
                                        :reduce="country => country.code" 
                                        @keypress.enter.native.prevent="" selectOnTab
                                        :options="countries" required="required">
                                            <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                                {{ option.label }} {{ option.code }}
                                            </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div v-if="subscriber.customer" class="text-center d-flex">
                                    <button  class="btn btn-small btn-link text-nowrap" @click="completeFromData">Automatico <span class="icon-database-download"></span></button>
                                    <button  class="btn btn-primary btn-block" @click="updateMergeFields">Guardar</button>
                                </div>
                                <div v-else  class="text-center">
                                    <button  class="btn btn-primary btn-block" @click="updateMergeFields">Guardar</button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Email</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.email_address || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Nombre</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.FNAME || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Apellido</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.LNAME || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Telefono</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.PHONE || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Dirrección</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.ADDRESS.addr1 || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Ciudad</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.ADDRESS.city || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Estado</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.ADDRESS.state || '-' }}
                                    </span>
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Zip code</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.ADDRESS.zip || '-' }}
                                    </span>
                                    
                                </div>
                                <div class="d-flex mb-2">
                                    <label for="" class="col-3 px-1">Pais</label>
                                    <span class="col-9 px-1">
                                        {{ subscriber.merge_fields.ADDRESS.country || '-' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-lg-none">
                            <hr>
                            <div class="mb-2">
                                <div class="d-flex">
                                    <label for="notes" class="d-block m-0">Notas</label>
                                    <span v-if="noteCharacters > 0" class="ml-auto" :class="{'text-danger': noteCharacters < 20}">{{ noteCharacters }} carateres restantes</span>
                                    <span v-else class="ml-auto text-danger">Maximo de caracteres</span>
                                </div>
                                <textarea v-model="note" maxlength="1000" class="form-control" id="notes" cols="30" rows="3" placeholder="Escribir nota"></textarea>
                                <transition name="fade">
                                    <div v-show="note.length > 10" class="text-right py-2">
                                        <button class="btn btn-primary" @click="addNote">Guardar</button>
                                    </div>
                                </transition>
                            </div>
                            <transition-group name="fade" tag="div" v-if="notes.length">
                                <div class="py-1" v-for="(note, index) in notes" :key="`note-${note.id}-${index}`">
                                    <p class="m-0 subscriber-note">{{note.note}}</p>
                                    <p class="small text-muted m-0">{{ note.created_at | moment('LL LT') }}</p>
                                </div>
                            </transition-group>
                            <transition name="fade">
                                <div v-if="notesLoading" class="text-center py-3">
                                    <DotsLoader  message="Cargando Notas"/>
                                </div>
                            </transition>
                        </div>

                    </div>
                </div>



            </div>
            <div class="modal-footer">
                <button type="button"  class="btn btn-primary" data-dismiss="modal">Continuar</button>
            </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    #searchCoutryEditSubs{
         .vs__search{
            position: absolute;
            z-index: 99;
            width: 100%;
        }
    }
</style>
<style lang="scss" scoped>
   
    .subscriber-stats{
        font-size: 1.5em;
        .small{
            font-size: 60%;
        }
    }
    .subscriber-note{
        border-radius: 0.25em;
        background-color: #f5f5f5;
        padding: 1em;
        padding-left: 1.5em;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            left: 0.5em;
            width: 4px;
            background: #3e85f7;
            border-radius: 10px;
            top: 1em;
            bottom: 1em;
        }
    }
    .customer-image{
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
</style>
<script>
import StarRating from '~/components/StarRating'
import DotsLoader from '~/components/loaders/DotsLoader'
export default {
    components: {
        StarRating,
        DotsLoader
    },
    props: {
        subscriber: {
            type: Object,
            required: true,
        }
    },
    data () {
        return {
            note: '',
            tags: '',
            notes: [],
            notesLoading: false,
            countries: []
        }
    },
    computed: {
        photo() {
            let customer    = this.subscriber.customer
            let mergeFields = this.subscriber.merge_fields

            return `${customer ? customer.photo : '/img/none.png'}"`
        },
        fullName() {
            let customer    = this.subscriber.customer
            let mergeFields = this.subscriber.merge_fields

            return customer ? `${customer.first_name} ${customer.last_name}` :
                    mergeFields.FNAME || mergeFields.LNAME ? `${mergeFields.FNAME} ${mergeFields.LNAME}` :
                    'Visitante'
        },
        noteCharacters () {

            return 1000 - this.note.length
        }
    },
    methods: {
        addNote(event) {
            let vm = this
            let endpoint = '/admin/subscriptions/members/notes/add'
            event.target.classList.add('btn-loading')
            axios.post(endpoint, {
                id     : vm.subscriber.id,
                listId : vm.subscriber.list_id,
                note   : vm.note
            }).then(({data}) => {
                event.target.classList.remove('btn-loading')
                vm.notes.push(data)
                vm.note = ''
                vm.$snotify.success('Nota agregada')
            })
        },

        completeFromData() {
            let vm           = this
            let customer     = this.subscriber.customer
            let merge_fields = this.subscriber.merge_fields
            event.target.classList.add('btn-loading')
            if(customer) {
                merge_fields.FNAME   = customer.first_name
                merge_fields.LNAME   = customer.last_name
                merge_fields.PHONE   = customer.phone
                merge_fields.ADDRESS.addr1 = customer.address
                merge_fields.ADDRESS.city = 'San Miguel'
                merge_fields.ADDRESS.state = 'San Miguel'
                merge_fields.ADDRESS.zip = '3301'
                merge_fields.ADDRESS.country = customer.country ? customer.country.short_iso_code : 'US'
            }
            event.target.classList.remove('btn-loading')

        },

        updateMergeFields (event) {
            let vm           = this
            let customer     = this.subscriber.customer
            let merge_fields = this.subscriber.merge_fields
            event.target.classList.add('btn-loading')
            axios.post('/admin/subscriptions/members/update', {
                email: this.subscriber.email_address,
                firstName : merge_fields.FNAME,
                lastName  : merge_fields.LNAME,
                address   : merge_fields.ADDRESS,
                phone     : merge_fields.PHONE,
            }).then(({data} )=> {
                event.target.classList.remove('btn-loading')
                if(!data) {
                    return vm.$snotify.error('Complete todos los campos')
                }
                vm.$snotify.success('Actualizado')
                
            })



        },

        changeRating(data) {
            let vm = this
            vm.$snotify.success(`Rating de ${vm.fullName} cambiado`);
        },

        changeTags (tags) {
            this.updateTags(tags, this)
        },

        updateTags: _.debounce((tags, vm) => {
            let endpoint = `/admin/subscriptions/members/tags`

            let remove = vm.subscriber.tags.filter(t => {
                let indexNewTags = tags.findIndex(nt => nt == t.name)
                return indexNewTags < 0
            }).map(t => t.name)

            let add = tags.filter(t => {
                let indexNewTags = vm.subscriber.tags.findIndex(nt => nt.name == t)
                return indexNewTags < 0
            })

            axios.post(endpoint, {
                email: vm.subscriber.email_address,
                add,
                remove
            }).then(({data}) => {
                if(data.added) {
                    vm.addTags(add)
                }

                if(data.removed) {
                    vm.removeTags(remove)
                }
                vm.$snotify.success('Etiquetas actualizadas')
            }).catch(err => {
                console.error(err)
                vm.$snotify.error('No se pudo actualizar etiquetas')
                vm.setTags()
            })

            console.log('add:', add, 'remove:', remove)
        }, 800),

        addTags(tags) {
            let vm = this
            tags.forEach(tag => {
                vm.subscriber.tags.push({name: tag})
            });
        },

        removeTags(tags) {
            let vm = this
            tags.forEach(tag => {
                let index = vm.subscriber.tags.findIndex(t => t.name == tag)
                if(index >= 0) {
                    vm.subscriber.tags.splice(index, 1)
                }
            })

        },

        setTags() {
            let vm = this
            vm.tags = vm.subscriber.tags.map(tag => tag.name)
        },

        setFocusViews() { // ok
            setTimeout (() => {
                $(this.$refs.vSelectTags.$el).find('.vs__search').focus();
            }, 200);
        },

        getNotes() {
            let vm       = this
            let endpoint = `/admin/subscriptions/members/notes`
            // si no tiene notas se inicia vacio
            if(!vm.subscriber.hasOwnProperty('notes')) {
                vm.subscriber.notes = []
            } else {
                vm.notes = vm.subscriber.notes
            }
            vm.notesLoading = true
            axios.get(endpoint, {
                params: {
                    id: vm.subscriber.id,
                    listId: vm.subscriber.list_id
                }
            }).then(({data}) => {
                vm.subscriber.notes = data.notes
                vm.notes = vm.subscriber.notes
                vm.notesLoading = false
            })
        },

        searchCountry(search, loading) {
            if (search.length>1 ) {
                loading(true);
                const vm = this;
                vm.getCountries(search, vm, loading)
            }
        },

        getCountries: _.debounce((search, vm, loading) => {
            let endpoint = `/admin/location/countries/${search}`
            axios.get(endpoint).then((response) => {
                    if (response.data) {
                        let countries = response.data.map(y => {
                            let country = y;
                            let d = {
                                id      :       country.id,
                                name    :       `${country.name} (${country.native_name})`,
                                label   :       `${country.name} (${country.native_name})`,
                                code    :       country.short_iso_code
                            }
                            return d;
                        });
                        vm.countries = countries;

                        vm.countries.sort(function(a, b) {
                            var a1= a.name, b1= b.name;
                            if(a1 == b1) return 0;
                            return a1> b1? 1: -1;
                        });
                        
                    } else {
                        console.log('No se pudieron cargar los paquetes');
                    }
                    loading(false);
                });
        }, 800)

    },
    mounted() {
        let vm = this
        vm.setTags()
        vm.getNotes()
        $(vm.$el).on('hidden.bs.modal', function (e) {
            vm.$emit('input', vm.subscriber)
        })
    }
}
</script>
