<template>
    <div class="chat-panel-message-input">
        <!-- <input type="text"
            class="input"
            placeholder="New message"
            v-model="message"
            @keyup.enter.prevent="sendMessage"> -->
            <p :id="`textarea-${channel}`" class="chat-textarea m-0 px-2" 
                placeholder="Escribir mensaje"
                contenteditable="true"
                @click="setRange"
                @focus="hideEmojiPicker"
                @input="updateMessage"
                @keypress="typing"
                @keypress.enter="sendMessage"
                @keyup.ctrl.enter="addTextBreak"
                @paste="onPaste"></p>
            <label class="m-0">
                <span class="icon-attachment chat-btn"></span>
                <input class="d-none" type="file"  @change="uploadFile" @click.stop="" accept=".png,.jpg,.jpeg,.gif,
        .doc,.dot,.wbk,.docx,.docm,.dotx,.dotm,.docb,
        .xls,.xlt,.xlm,.xlsx,.xlsm,.xltx,.xltm,.xlsb,.xla,.xlam,.xll,.xlw,.csv,
        .ppt,.pot,.pps,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.sldx,.sldm,
        .accdb,.accde,.accdt,.accdr,
        .rar,.rar4,.zip,.7zip,.pdf,.txt">
            </label>
            <span @click.stop="showEmojiPicker" v-click-outside="hideEmojiPicker">
                <!-- <emoji   emoji=":grinning:" set="messenger" :size="20" /> -->
                <span class="icon-emoji-happy chat-btn"></span>
            </span>
            <div v-show="emojiPicker" @click.stop="" v-click-outside="hideEmojiPicker">
                <picker  @select="addEmoji" set="messenger" :style="{ position: 'absolute', bottom: '40px', right: '0', width: '270px', zIndex: '99' }" />
            </div>
    </div>
</template>

<script>
export default {
    props: ['channel_id', 'channel','activeChannel', 'user'],
    
    data() {
        return {
            message: '',
            file: null,
            file_name: null,
            file_format: null,
            emojiPicker: false,
            rangeSelection: null,
            width: null,
            height: null,
        };
    },

    methods: {
        parseMessage (str) {
            let words = str.split(' ')
            return words
        },
        onPaste (event) {
            event.preventDefault();

            // get text representation of clipboard
            var text = (event.originalEvent || event).clipboardData.getData('text/plain');

            // insert text manually
            document.execCommand("insertHTML", false, text);
        },
        addTextBreak(event) {
            document.execCommand('insertHTML', false, '<br><br>')
            return false
        },
        sendMessage(event) {
            if(event) event.preventDefault()
            let endpoint = `/admin/chat/channels/${this.channel}/messages`;
            let message = this.message.replace(/::/g, ": :")
                message = message.replace('&nbsp;', " ")
            
            let data = {
                user: this.user,
                message: message,
                file: this.file,
                file_name: this.file_name,
                file_format: this.file_format,
                height: this.height,
                width: this.width
            }

            if(!this.message && !this.file) return

            axios.post(endpoint, data).then( data => {
                this.message = ''
                this.file = null
                this.file_name = null
                this.file_format = null
                this.height = null
                this.width = null
                this.$el.querySelector('.chat-textarea').innerHTML = ''
            });
        },
        setRange(event) {
            let vm = this
            var sel, range, html;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    vm.rangeSelection = range
                }
            } 
        },
        insertTextAtCursor(text) {
            var sel, range, html;
            if(this.rangeSelection == null) {
                this.$el.querySelector('.chat-textarea').innerHTML += text
                return
            }
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    let newNode = document.createTextNode(text)
                    this.rangeSelection.insertNode( newNode ) 
                    this.rangeSelection.setStart(newNode, text.length)
                }
            } else if (document.selection && document.selection.createRange) {
                document.selection.createRange().text = text;
            }
            
            this.message = this.$el.querySelector('.chat-textarea').innerHTML
        },
        showEmojiPicker() {
            this.emojiPicker = true
            this.eventHub.$emit('update-picker-emoji', this.channel)

        },
        hideEmojiPicker() {
            this.emojiPicker = false
        },
        addEmoji(data) {
            this.insertTextAtCursor(` ${data.colons} `)
        },
        updateMessage(event){
            this.message = event.target.innerHTML
            this.setRange()
        },
        uploadFile(event) {
            let vm  = this
            let endpoint = `/admin/chat/upload/file`;
            let formData = new FormData()
            formData.append('file', event.target.files[0])
            formData.append('channel', this.channel)
            formData.append('channel_id', this.channel_id)
            axios.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                let data = response.data
                vm.file = data.file
                vm.file_format = data.file_format
                if(['png', 'jpg', 'jpeg', 'gif'].indexOf(data.file_format) >= 0) {
                    vm.height = data.height
                    vm.width = data.width
                }
                vm.file_name = data.file_name
                this.sendMessage()
            });
        },
        typing (event) {
             this.eventHub.$emit('typing', this.channel)
        }
    },
    mounted () {
       this.eventHub.$on('update-picker-emoji', data => {
            if(data != this.channel) {
                this.emojiPicker = false
            }
        })
    },
    
    
}
</script>

<style>
.message-input-wrapper {
    border-top: 2px dotted greenyellow;
}
</style>