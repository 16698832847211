<template>
    <div>
        <h5 class="font-weight-bold mb-0">Clientes</h5>
        <p class="text-muted">Lista de clientes registrados</p>

        <div class="section pt-3 pb-1">
            <data-table 
                :ajax="{ url: '/admin/customers' }" 
                :serverSide="true" 
                :columns="dt.columns" 
                v-on:xhr="dt.xhr"
                :scrollY="'calc(100vh - 365px)'">
            </data-table>
        </div>

    </div>
</template>

<script>

    import dataTable  from '~/components/dataTable'

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Customers Report" }
        },
        components: {
            dataTable,
        },
        data(){
            return {
                customers: [],
                dt: {
                    xhr:(e, settings, json, xhr) =>{
                        $('.dataTables_wrapper').find('[data-toggle="tooltip"]').tooltip()
                    },
                    columns : [
                        {   title: 'Id', data: 'id', },
                        {   title: 'Nombre', data: 'first_name', 
                            render: (data, type, row) => {
                                return `<div class="d-flex">
                                            <img src="${row['photo']}" alt="${row['username']}" class="rounded" onerror="${ this.imgNotFound() }" style="width:50px; height: 50px;">
                                            <div class="ml-2 align-self-start">
                                                <p class="mb-0">${row['first_name']} ${row['last_name']}</p>
                                                <small class="text-muted">${ !_.isEmpty(row['username']) ? `@${row['username']}` : 'Sin Nombre de Usuario'}</small>
                                            </div>
                                        </div>`;
                            }
                        },
                        {   title: 'Correo Electrónico', data: 'email',
                            render: (data, type, row) => {
                                let isVerified = !_.isEmpty(row["email_verified_at"])
                                return `<span title="${isVerified ? 'Correo Verificado' : 'Pendiente de Verificación' }" data-toggle="tooltip">
                                            <span class="customer-status ${isVerified ? 'icon-circle-check status-success' : 'icon-alert-warning status-warning' }"></span>
                                            ${data}
                                        </span>`;
                            } 
                        },
                        {   title: 'Género', data: 'gender',
                            render: (data, type, row) => {
                                switch (data) {
                                    case 'MALE':
                                        return 'MASCULINO'
                                    case 'FEMALE':
                                        return 'FEMENINO'
                                    case 'OTHERS':
                                        return 'OTROS'
                                    default:
                                        return 'INDEFINIDO'
                                }
                            }
                        },
                        {   title: 'Fecha de Nacimiento', data: 'birthday',
                            render: (data, type, row) => {
                                return _.isEmpty(data) ? '' : moment(data, 'YYYY-MM-DD').format('L')
                            }
                        },
                        {   title: 'Teléfono', data: 'phone',
                            render: (data, type, row) => {
                                return _.isEmpty(data) ? '' : `+${row["phone_extension"]} ${data}`
                            }
                        },
                        {   title: 'Dirección', data: 'address', className: 'md-column text-wrap',
                            render: (data, type, row) => {
                                return _.isEmpty(data) ? '' : data
                            }
                        },
                        {   title: 'Fecha de Registro', data: 'created_at',
                            render: (data, type, row) => {
                                return _.isEmpty(data) ? '' : moment(data, 'YYYY-MM-DD').format('LL')
                            }
                        },
                    ]
                }
            }
        },
       
    }
</script>

<style>
    .customer-status{
        border-radius: 50px;
        padding: 3px;
    }
    .status-success{
        color: #38c172;
        background: #cff0dd;
    }
    .status-warning{
        color: #ffc107;
        background: #fff4d2;
    }
</style>