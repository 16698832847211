<template>
	<div class="app-customer-container">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>Información del Cliente</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card" style="box-shadow: none !important">
                        <div class="card-body">                            
                            <ul class="nav nav-tabs" id="customerHistoryTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="bookings-tab" data-toggle="tab" href="#bookings" role="tab" aria-controls="bookings" aria-selected="true">Reservas</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="searches-tab" data-toggle="tab" href="#searches" role="tab" aria-controls="searches" aria-selected="false">Historial de Búsquedas</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="customerHistoryTabContent">
                                <div class="tab-pane fade show active" id="bookings" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="container mt-3">
                                        <div class="row">
                                            <div class="col-12">
                                                
                                                <p>Reservas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="searches" role="tabpanel" aria-labelledby="profile-tab">
                                    <p>Busquedas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
	export default {
		middleware: 'auth',
        layout: 'dashboard',
        route: {
            //Habia un conflicto con las rutas base
            //validacion del parametro numerico con regex
            validate: {
                id: '\\d+'
            }
        }
	}
</script>