<template>
    <div>
        <div class="pt-2 pb-1">
            <div class="row mx-0">
                <div class="col-12 col-md-8 col-lg-9">
                    <h5 class="font-weight-bold mb-0">Trafico de datos y analítica</h5>
                    <p class="text-muted mb-1">Información agrupada del tráfico que llega a Pavolar según la audiencia</p>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div :class="{'btn-loading btn-loading-dark': loading}">
                        <select name="filter" id="filter" v-model="filter" class="form-control" @change="getAnalytics">
                            <option value="YESTERDAY">Ayer</option>
                            <option value="TODAY">Hoy</option>
                            <option value="THIS_WEEK">Esta semana</option>
                            <option value="LAST_WEEK">La semana pasada</option>
                            <option value="LAST_7_DAYS">Los últimos 7 días</option>
                            <option value="LAST_15_DAYS">Los últimos 15 días</option>
                            <option value="LAST_30_DAYS">Los últimos 30 días</option>
                            <option value="LAST_60_DAYS">Los últimos 60 días</option>
                            <option value="LAST_90_DAYS">Los últimos 90 días</option>
                            <option value="THIS_YEAR">Este año (de enero a hoy)</option>
                            <option value="LAST_YEAR">Último año</option>
                            <!-- <option value="">personalizado</option> -->
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mx-0">
            <div class="col-12 col-md-8 pr-md-1">
                <div class="section h-100">
                    <div class="flex mb-2 chart-options">
                        <button class="btn btn-chart shadow-none font-weight-bold" :class="{ 'active': areaChart.filter == 'users'}" @click="areaChart.filter = 'users'">
                            Visitantes
                        </button>
                        <button class="btn btn-chart shadow-none font-weight-bold" :class="{ 'active': areaChart.filter == 'pageviews'}" @click="areaChart.filter = 'pageviews'">
                            Visitas
                        </button>
                        <button class="btn btn-chart shadow-none font-weight-bold" :class="{ 'active': areaChart.filter == 'timeOnPage'}" @click="areaChart.filter = 'timeOnPage'">
                            Duración
                        </button>
                        <button class="btn btn-chart shadow-none font-weight-bold" :class="{ 'active': areaChart.filter == 'sessions'}" @click="areaChart.filter = 'sessions'">
                            Sesiones
                        </button>
                    </div>
                    <apexchart type="area" height="350" ref="chart" :options="areaChart.chartOptions" :series="mainData"></apexchart>
                </div>
            </div>
            <div class="col-12 col-md-4 pl-md-1 mt-2 mt-md-0">
                <div class="section h-100 p-2">
                    <p class="chart-title mb-0">Tipos de Visitantes</p>
                    <p class="chart-title font-weight-normal text-muted">Personas que visitan la página</p>
                    <apexchart class="mt-2" type="donut" height="350" ref="donut" :options="donutChart.chartOptions" :series="userTypes"></apexchart>
                </div>
            </div>
        </div>

        <div class="row mx-0 mt-2">
            <div class="col-12 col-md-8 pr-md-1">
                <div class="row">
                    <div class="col-12">
                        <div class="section h-100 p-2"> 
                            <p class="chart-title mb-0">Actividad</p>
                            <p class="chart-title font-weight-normal text-muted">Actividad de los usuarios por periodos</p>
                            <apexchart type="line" height="225" :options="lineChart.chartOptions" :series="activeUsers"></apexchart>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="section p-2"> 
                            <p class="chart-title mb-0">Tasa de rebote vs. tasa de salida</p>
                            <p class="chart-title font-weight-normal text-muted">Comparación visual entre la tasa de rebote y de salida</p>
                            <apexchart type="bar" height="225" :options="barChart.chartOptions" :series="bounceRateVSExitRate"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 pl-md-1 mt-2 mt-md-0">
                <div class="section h-100 p-2">
                    <div class="chart-title mb-0">
                        <select name="mapfilter" id="mapfilter" v-model="mapChart.filter" class="chart-select">
                            <option value="users">Usuarios</option>
                            <option value="sessions">Visitantes</option>
                            <option value="pageviews">Visitas</option>
                        </select> por País
                    </div>
                    <p class="chart-title font-weight-normal text-muted">Número de usuarios activos por por país</p>
                    <analytics-map :countries="analytics.countrySessions" :filter="mapChart.filter"></analytics-map>
                </div>
            </div>
        </div>
        
        <div class="row mx-0 mt-2">
            <div class="col-12 col-md-6 pr-md-1">
                <div class="section h-100 p-2">
                    <p class="chart-title mb-0">Páginas</p>
                    <p class="chart-title font-weight-normal text-muted">Lista de página mas visitadas</p>
                    <table class="table table-sm table-hover" v-if="!_.isNull(analytics) && !_.isEmpty(analytics.mostVisitedPages)">
                        <thead class="border-none">
                            <tr>
                                <th class="border-0 small font-weight-bold" scope="col">TÍTULO DE PÁGINA</th>
                                <th class="border-0 small font-weight-bold" scope="col">VISITAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(page, index) in analytics.mostVisitedPages" :key="index" v-tooltip="page.url">
                                <td class="small">{{ page.pageTitle }}</td>
                                <td class="small">{{ page.pageViews }}</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-md-6 pl-md-1 mt-2 mt-md-0">
                <div class="section h-100 p-2">
                    <apexchart type="bar" height="350" :options="horizontalBarChart.chartOptions" :series="browserAndOSTraffic"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .btn-chart {
        font-size: 12px;
        padding: 0.8rem 0.75rem;
        position: relative;
    }
    .btn-chart.active:after {
        content: '';
        display: inline-block;
        width: 75%;
        height: 3px;
        left: 12.5%;
        top: 0;
        background: #3e85f7;
        position: absolute;
        border-radius: 0 0 3px 3px;
    }
    .chart-title{
        font-size: 12px;
        font-weight: bold;
    }
    .chart-select {
        border: 0;
        font-weight: inherit;
        outline: none;
        background: #FAFAFA;
        border-radius: 3px;
        margin-right: 3px;
        display: inline-block;
    }
</style>

<script>

    import ApexCharts from 'apexcharts'
    import VueApexCharts from 'vue-apexcharts'
    import analyticsMap from '~/components/analytics/map'

    export default {
        components: {
            'apexchart': VueApexCharts,
            analyticsMap
        },
        middleware:'auth',
        layout: 'dashboard',
        data() {
            return {
                loading: false,
                filter: 'LAST_15_DAYS',
                analytics: null,
                areaChart: {
                    filter: 'users',
                    chartOptions: {
                        chart: {
                            type: 'area',
                            height: 350,
                            zoom: {
                                autoScaleYaxis: true
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0,
                            style: 'hollow',
                        },
                        stroke: {
                            width: 2,
                            curve: 'smooth'
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'left'
                        },
                        xaxis: {
                            type: 'datetime',
                        },
                        tooltip: {
                            x: {
                                format: 'dd MMM yyyy'
                            }
                        },
                        colors: ['#1ea5f5', '#4677fd', '#886cff'],
                        fill: {
                            type: 'gradient',
                            colors: ['#1ea5f5', '#4677fd', '#886cff'],
                            gradient: {
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                stops: [0, 100]
                            }
                        },
                    }
                },
                donutChart: {
                    chartOptions: {
                        plotOptions: {
                            pie: {
                                donut: {
                                    size: '85%'
                                }
                            }
                        },
                        chart: {
                            type: 'donut',
                        },
                        dataLabels: {
                            enabled: false
                        },
                        colors: ['#ffe551', '#4677fd', '#00c9a7'],
                        fill:{
                            colors: ['#ffe551', '#4677fd', '#00c9a7'],
                        },
                        labels: [],
                        legend: {
                            position: 'bottom',
                            horizontalAlign: 'center', 
                            offsetY: 0,
                        }
                    },
                },
                mapChart: {
                    filter: 'users',
                },
                lineChart: {
                    chartOptions: {
                        chart: {
                            // height: 350,
                            type: 'line',
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: 2,
                            curve: 'straight',
                            dashArray: [0, 0, 5]
                        },
                        colors: ['#1ea5f5', '#4677fd', '#886cff'],
                        legend: {
                            position: 'left',
                            horizontalAlign: 'center',
                            formatter: (seriesName, opts) => {
                                return [`
                                    <span class="text-muted font-weight-bold small">${_.toUpper(seriesName).replace('ACTIVIDAD ', '')}</span>
                                    <h5 class="text-center mb-2">${_.sum(opts.w.globals.series[opts.seriesIndex])}</h5>
                                `];
                            },
                        },
                        markers: {
                            size: 0,
                            hover: {
                                sizeOffset: 6
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                        },
                    }
                },
                barChart: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            height: 350,
                            zoom: {
                                enabled: false
                            },
                            stacked: true,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '60%',
                                // endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            type: 'datetime'
                        },
                        legend: {
                            position: 'right',
                            offsetY: 40
                        },
                        colors: ['#4677fd', '#1ecdf5'],
                        fill: {
                            opacity: 1,
                            colors: ['#4677fd', '#1ecdf5']
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `${val}%`
                                }
                            }
                        }
                    }
                },
                horizontalBarChart: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            height: 350
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        annotations: {
                            xaxis: []
                        },
                        xaxis: {
                            categories: [],
                        },
                        fill:{
                            colors: [ '#1ecdf5', '#00c9a7', '#ffe551']
                        }
                    },
                },
            }
        },
        computed: {
            mainData() {

                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.mainContent))
                    return []

                let series    = []
                let source    = this.analytics.mainContent
                let filter    = this.areaChart.filter

                let buildAxis = (titles) => {
                    titles = _.isArray(titles) ? titles : [titles]
                    titles.forEach(e => {
                        series.push({ name: Object.values(e)[0], title: Object.keys(e)[0], data: [] })  
                    })
                }

                switch (filter) {
                    case 'users':
                        buildAxis([{'users': 'Usuarios'}, {'sessions': 'Visitantes'}])
                        break;
                    case 'pageviews':
                        buildAxis({'pageviews': 'Vistas'})
                        break;
                    case 'timeOnPage':
                        buildAxis({'timeOnPage': 'Tiempo en la Página (minutos)'})
                        break;
                    case 'sessions':
                        buildAxis([{'bounces': 'Rebotes'}, {'entrances': 'Entradas'}, {'exits': 'Salidas'}])
                        break;
                }

                series.forEach(serie => {
                    source.forEach(element => {
                        let item = []
                        item.push(moment(element.date).valueOf())
                        item.push(serie.title == 'timeOnPage' ? this.round(element[serie.title] / 60) : element[serie.title])
                        serie.data.push(item)
                    })
                })

                return series
            },
            userTypes(){

                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.userTypes))
                    return []

                let series    = []
                let labels    = []
                let source    = this.analytics.userTypes
                
                source.forEach(element => {
                    labels.push(element.type)
                    series.push(element.sessions)
                })
                
                this.donutChart.chartOptions.labels = labels
                return series
            },
            activeUsers(){
                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.activeUsers))
                    return []

                let series = []
                let source = this.analytics.activeUsers
                
                series.push({ name: 'Actividad 1 día', data: [] })
                series.push({ name: 'Actividad 7 días', data: [] })
                series.push({ name: 'Actividad 30 días', data: [] })

                source.forEach(element => {
                    // Actividad 1 día
                    series[0].data.push([moment(element['date']).valueOf(), element['1dayUsers']])
                    // Actividad 7 días
                    series[1].data.push([moment(element['date']).valueOf(), element['7dayUsers']])
                    // Actividad 30 días
                    series[2].data.push([moment(element['date']).valueOf(), element['30dayUsers']])
                });

                return series
            },
            bounceRateVSExitRate(){
                
                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.bounceRateVSExitRate))
                    return []

                let series = []
                let source = this.analytics.bounceRateVSExitRate
                
                series.push({ name: 'Tasa de Rebote', data: [] })
                series.push({ name: 'Tasa de Salida', data: [] })

                source.forEach(element => {
                    // Tasa de Rebote
                    series[0].data.push([moment(element['date']).valueOf(), this.round(element['bounceRate'])])
                    // Tasa de Salida
                    series[1].data.push([moment(element['date']).valueOf(), this.round(element['exitRate'])])
                });

                return series
            },
            browserAndOSTraffic(){

                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.browserAndOSTraffic))
                    return []

                let series = [{ name: 'Sesiones', data: [] }]
                let source = _.chain(this.analytics.browserAndOSTraffic).groupBy((item)=>`${item.operatingSystem} ${item.operatingSystemVersion}`).value()

                let categories = Object.keys(source)

                categories.forEach(key => {
                    series[0].data.push(_.sumBy(source[key], (e) => parseFloat(e.sessions)) || 0)
                });

                if(!_.isEmpty(this.analytics.mobileDeviceTraffic)){
                    let mobileTraffic = _.sumBy(this.analytics.mobileDeviceTraffic, (e) => parseFloat(e.sessions)) || 0
                    let annotation    = {
                        x: mobileTraffic,
                        borderColor: '#00E396',
                        label: {
                            borderColor: '#00E396',
                            style: {
                                color: '#fff',
                                background: '#00E396',
                            },
                            offsetY: 0,
                            textAnchor: 'start',
                            text: `Móvil (${mobileTraffic})`,
                        }
                    }
                    
                    this.horizontalBarChart.chartOptions.annotations.xaxis = [annotation]
                }

                this.horizontalBarChart.chartOptions.xaxis.categories = categories
                return series
            }
        },
        async asyncData(app, route, store, redirect){
            
            let startDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
            let endDate   = moment().format('YYYY-MM-DD')
            let { data }  = await axios.get(`/admin/analytics/traffic?start_date=${startDate}&end_date=${endDate}`)

            return {
                analytics: data
            }
        },
        methods: {
            getAnalytics(){
                let vm     = this
                let period = vm.getPeriod()
                console.log(period)
                vm.loading = true
                axios.get(`/admin/analytics/traffic?start_date=${period.start}&end_date=${period.end}`).then(reponse => {
                    vm.analytics = reponse.data
                    vm.loading   = false
                });
            },
            getPeriod(){
                switch (this.filter) {
                    case 'YESTERDAY':
                        return {
                            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'days').format('YYYY-MM-DD')
                        }
                    case 'TODAY':
                        return {
                            start: moment().format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'THIS_WEEK':
                        return {
                            start: moment().startOf('week').format('YYYY-MM-DD'),
                            end:   moment().endOf('week').format('YYYY-MM-DD')
                        }
                    case 'LAST_WEEK':
                        return {
                            start: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                        }
                    case 'LAST_7_DAYS':
                        return {
                            start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_15_DAYS':
                        return {
                            start: moment().subtract(15, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_30_DAYS':
                        return {
                            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_60_DAYS':
                        return {
                            start: moment().subtract(60, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_90_DAYS':
                        return {
                            start: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'THIS_YEAR':
                        return {
                            start: moment().startOf('year').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_YEAR':
                        return {
                            start: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                        }
                }
            },
            round(value){
                return (Math.round(value * 100) / 100)
            }
        },
        created() {
            $(this.$el).find('[data-toggle="tooltip"]').tooltip()
        },
    }
</script>