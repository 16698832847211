<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Monedas</h5>
                    <p class="text-muted mb-1">Lista de Monedas del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#modalCurrency" >
                        <span class="icon-add-2 mr-2"></span> Nueva Moneda
                    </button>
                </div>
            </div>
            
            <currency-modal :Currency="Currency" v-on:closeCurrencyModal="clearCurrencyVar()" v-on:reloadCurrencyModal="reloadCurrency()"></currency-modal>

            <data-table :ajax="{ url: '/admin/location/currencies/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="currencyTable" :serverSide="false" :columns="dt.columns"></data-table>
            
        </div>
    </div>
</template> 
<script>
    import dataTable from '~/components/dataTable'
    import CurrencyModal from '~/components/geodatos/CurrencyModal'

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            CurrencyModal
        },
        metaInfo() {
            return {
                title: "Monedas del Sistema"
            }
        },
        data() {
            return {
                Currency: null,
                dt: {
                    columns : [
                        { title: 'Id', data: 'id', searchable: true },
                        { title: 'nombre', data: 'name', searchable: true },
                        { title: 'codigo',   data: 'code', searchable: true },
                        { title: 'simbolo',   data: 'symbol', searchable: true },
                        { title: 'Actions',       data: 'id', orderable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#modalCurrency">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            }
        },
        methods: {
            reloadCurrency() {
                this.$refs.currencyTable.ajaxReload();
            },
            clearCurrencyVar() {
                this.Currency = null;
            },
            onDelete(currency) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Moneda',
                    `¿Está seguro que desea eliminar la moneda <u>${currency.name}</u>?`, 
                    function(){
                        vm.deleteItem(currency.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/location/currencies/delete', { id: idDel }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 

                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.currencyTable.ajaxReload();
                        return;
                    }

                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            }
        },
        mounted(){ 
            let vm = this

            $(vm.$el).off("click.currency", ".btn-delete");
            $(vm.$el).on("click.currency", ".btn-delete",function(){
                let currency = vm.$refs.currencyTable.rowData($(this).closest('tr'));
                vm.onDelete(currency);
            });

            $(vm.$el).off("click.currency", ".btn-edit");
            $(vm.$el).on("click.currency", ".btn-edit", function(e){
                let currency = vm.$refs.currencyTable.rowData($(this).closest('tr'))
                vm.Currency = currency;
            });
        },
    }
</script>