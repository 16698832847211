<template>
	<div class="modal fade" id="addHotelCategory">
		<div class="modal-dialog">
			<div class="modal-content">
				<form id="formHotelCategory" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span v-if="HotelCategory && HotelCategory.btn_i18n==true">Traducción de Categoria de Hotel</span>
							<span v-else-if="formHotelCategory.id">Editar Categoria de Hotel</span>
							<span v-else>Nueva Categoria de Hotel</span>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="name">Nombre Categoria</label>
							<input
								type="text"
								class="form-control"
								placeholder="Ingrese Nombre"
								v-model="formHotelCategory.name"
								name="name"
							/>
						</div>
						<div class="form-group">
							<label for="description">Descripción de la Categoria</label>
							<textarea
								type="text"
								class="form-control"
								rows="4"
								placeholder="Ingrese Descripción"
								v-model="formHotelCategory.description"
								name="description"
							></textarea>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary"
							v-can:create="'packages-hotels-index'">
							<span v-if="HotelCategory && HotelCategory.btn_i18n==true">Guardar Traducción</span>
							<span v-else-if="formHotelCategory.id && $can('update','packages-hotels-index')">Editar</span>
							<span v-else-if="formHotelCategory.id && !$can('update','packages-hotels-index')">Solicitar Edicion</span>
							<span v-else>Guardar</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["HotelCategory"],
	data() {
		return {
			formHotelCategory: new WebForm("#formHotelCategory", {
				name: "",
				description: "",
				id: null,
				btn_i18n: false,
				id_i18n: null,
			})
		};
	},
	watch: {
		HotelCategory: function(val, oldVal) {
			if (
				_.isEmpty(this.HotelCategory) ||
				this.HotelCategory.id == ""
			) {
				// console.log('Vacio Att: ', this.HotelCategory);
			} else {
				this.formHotelCategory.fill(this.HotelCategory);
				if(this.HotelCategory.btn_i18n && this.HotelCategory.i18n.length>0) {
					this.formHotelCategory.name 		= this.HotelCategory.i18n[0].name;
					this.formHotelCategory.id_i18n 		= this.HotelCategory.i18n[0].id;
					this.formHotelCategory.description 	= this.HotelCategory.i18n[0].description;
				}
			}
		}
	},
	methods: {
		onSaveItem() {
			let vm = this;

			// tipo : 1 "Nuevo" , Sino, Editar
			let tipo = vm.formHotelCategory.id == null ? 1 : 2;
			if (this.formHotelCategory.id != null && this.formHotelCategory.btn_i18n==false) {
				$.confirm({
					title: `Editar Categoria`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function() {
								vm.updateOrCreate(
									"/admin/hotels/categories/save",
									tipo
								);
							}
						},
						cancelar: function() {}
					}
				});
			} else {
				vm.updateOrCreate("/admin/hotels/categories/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formHotelCategory.post(url).then(response => {
				let data = response.data;
				if (data.user_request_status) {
					$("#addHotelCategory").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento Actualizado con exito.";
				vm.$snotify.success(snotify_message);
				let obj = data.success;
				vm.$emit("reloadHotelCategoryModal", obj);
				$("#addHotelCategory").modal("hide");
				vm.formHotelCategory.clear();
				vm.formHotelCategory.reset();
				return;
			});
		}
	},
	mounted() {
		let vm = this;
		$("#addHotelCategory").off("hidden.bs.modal");
		$("#addHotelCategory").on("hidden.bs.modal", function() {
			vm.formHotelCategory.clear();
			vm.formHotelCategory.reset();
			vm.$emit("closeHotelCategoryModal");
		});
	}
};
</script>
<style scoped>
</style>