<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Categorias de Hoteles</h5>
                    <p class="text-muted mb-1">Lista de Categorias de Hoteles</p>
                </div>
                <div class="ml-auto">
                    <button class="btn btn-secondary center-content d-inline-block"  @click.prevent='goBack()'>
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button>
                    <button v-can:create="'packages-hotels-index'" class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addHotelCategory" >
                        <span class="icon-add-2 mr-2"></span> Nueva Categoria
                    </button>
                </div>
            </div>
            
            <hotel-category-modal :HotelCategory="HotelCategory" v-on:closeHotelCategoryModal="clearHotelCategoryVar()" v-on:reloadHotelCategoryModal="reloadHotelCategoryModal()"></hotel-category-modal>

            <data-table :ajax="{ url: '/admin/hotels/categories/all/dt'}" :scrollY="'calc(100vh - 350px)'" ref="hotelCategoryTable" :serverSide="true" :columns="dt.columns()"></data-table>

        </div>
    </div>
</template>

<script>
    import dataTable from '~/components/dataTable'
    import HotelCategoryModal from '~/components/packages/HotelCategoryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            HotelCategoryModal,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                HotelCategory  :   null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'name', data: 'name' },
                                { title: 'description', data: 'description' },
                                { title: 'Actions', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addHotelCategory" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addHotelCategory" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let btnedit     =  $this.$can('write', 'packages-hotels-index')  ? `<button class="btn btn-success btn-sm mr-2 font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#addHotelCategory">Editar</button>` : `-`;
                                        let btndelete   =  $this.$can('write', 'packages-hotels-index')  ? `<button class="btn btn-danger btn-sm mr-2 font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btni18n + btnedit + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            // Redirecciones=========================================================
            goBack: function () {
                window.history.back();
            },
            clearHotelCategoryVar() {
                this.HotelCategory = null;
            },
            reloadHotelCategoryModal() {
                this.$refs.hotelCategoryTable.ajaxReload();
            },
            onDelete(category) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Categoria',
                    `¿Está seguro que desea eliminar la categoria <u>${category.name}</u>?`, 
                    function(){
                        vm.deleteItem(category.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/hotels/categories/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.hotelCategoryTable.ajaxReload();
                        return
                    }
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
        },
        computed: {
            
        },
        mounted(){
            let vm = this;
            // ------------ Para usar el valor que tiene la tabla actual.
            $(vm.$el).off("click.hotel_categories",".btn-edit");
            $(vm.$el).on("click.hotel_categories",".btn-edit",function(){
                let hotel_category = vm.$refs.hotelCategoryTable.rowData($(this).closest('tr'));
                hotel_category.btn_i18n = false;
                vm.HotelCategory = hotel_category;
            });

            $(vm.$el).off("click.hotel_categories",".btn-i18n");
            $(vm.$el).on("click.hotel_categories",".btn-i18n",function(){
                let hotel_category = vm.$refs.hotelCategoryTable.rowData($(this).closest('tr'));
                hotel_category.btn_i18n = true;
                vm.HotelCategory = hotel_category;
            });

            $(vm.$el).off("click.hotel_categories",".btn-delete");
            $(vm.$el).on("click.hotel_categories",".btn-delete",function(){
                let category = vm.$refs.hotelCategoryTable.rowData($(this).closest('tr'));
                vm.onDelete(category);
            });
        },
    }
</script>
