<template>
    <div>
        <div class="modal fade" id="modalMyPackage">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formPackage" autocomplete="off" @submit.prevent="onSavePackage()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
    							<span v-if="MyPackage && MyPackage.btn_i18n == true">Traducción de Paquete</span>
                                <span v-else-if="formPackage.id">
                                    Editar Paquete Turistico
                                </span>
                                <span v-else>Nuevo Paquete Turistico</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row" v-show="!(MyPackage && MyPackage.btn_i18n == true)">
                                <div class="form-group col-md-6" style="height: 230px;">
                                    <label for="image">Imagen</label>
                                    <load-image :clearCurrent="clearCurrent" :color_primary="colors.primary" :last_image="formPackage.path_image + formPackage.last_image" v-on:getImage="getImage"></load-image>
                                </div>
                                <div class="form-group col-md-2" style="height: 230px;">
                                    <label for="image">Color Primario</label>
                                    <div v-if="colors.primary" class="cuadro shadow-sm" :style="{'background-color':colors.primary}"></div>
                                </div>
                                <div class="form-group col-md-4" style="height: 230px;">
                                    <label for="image">Paleta de Colores</label>
                                    <div v-if="colors.palette">
                                        <div v-for="(color, index) in colors.palette" @click.prevent="selectPrimary(color)" :style="{'background-color':color}" :key="index" class="circulo shadow-sm"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group" :class="!(MyPackage && MyPackage.btn_i18n == true) ? 'col-6' : 'col-12' ">
                                    <label for="title">Titulo del Paquete</label>
                                    <input type="text" class="form-control" ref="name" placeholder="Ingrese Titulo" 
                                    v-model="formPackage.title" name="title">
                                </div>
                                <div class="form-group col-6" v-show="!(MyPackage && MyPackage.btn_i18n == true)">
                                    <label for="Package_category_id" style="width: 100%">Categoria de Paquete  
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" 
                                        data-toggle="modal" data-target="#addPackageCategory">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="package_category" 
                                    name="package_category_id" ref="vSelectPackageCategory" label="name"
                                    @keypress.enter.native.prevent="" selectOnTab  required="required"
                                    placeholder="Seleccione Categoria" :options="package_categories">
                                    </v-select>
                                </div>
                            </div>
                            <div class="row" v-show="!(MyPackage && MyPackage.btn_i18n == true)">
                                <div class="form-group col-12 col-md-6">
                                    <label for="country_id">Pais</label>
                                    <v-select v-model="country" name="country_id" 
                                    @search="searchCountry" ref="vSelectCountry" 
                                    placeholder="Seleccione Pais"
                                    @keypress.enter.native.prevent="" selectOnTab
                                    :options="countries" required="required">
                                        <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="state_id" class="required" style="width: 100%">Estado
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addState">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select autocomplete="off"  v-model="state" name="state_id" ref="vSelectState" placeholder="Seleccione Estado" :options="states"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="city_id" style="width: 100%">Ciudad
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" 
                                        data-toggle="modal" data-target="#addCity">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="city" name="city_id" 
                                    ref="vSelectCity" @keypress.enter.native.prevent="" 
                                    selectOnTab placeholder="Seleccione Ciudad" :options="cities">
                                        <div v-if="country" slot="no-options">Sin resultados</div>
                                        <div v-else slot="no-options">Debe seleccionar un pais.</div>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="start_date">Fecha de Inicio</label>
                                    <vue-datepicker name="start_date" v-model="formPackage.start_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label for="start_time">Hora</label>
                                    <vue-timepicker id="start_time" v-model="formPackage.start_time_array" placeholder="Hora de Inicio" 
                                    format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="expired_date">Fecha de Expiración</label>
                                    <vue-datepicker name="expired_date" v-model="formPackage.expired_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label for="expired_time">Hora</label>
                                    <vue-timepicker id="expired_time" v-model="formPackage.expired_time_array" placeholder="Hora de Expiración" 
                                    format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="short_description">Descripción corta para la portada del paquete</label>
                                    <textarea type="text" class="form-control" rows="4" placeholder="Ingrese Descripción Corta" 
                                    v-model="formPackage.short_description" id="short_description"></textarea>
                                </div>
                                <div class="form-group col-12">
                                    <label for="content">Descripción general del paquete</label>
                                    <textarea type="text" class="form-control" rows="5" placeholder="Ingrese Descripción General" 
                                    v-model="formPackage.content" id="content"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-index'" > 
							    <span v-if="MyPackage && MyPackage.btn_i18n == true">Guardar Traducción</span>
                                <span v-else-if="formPackage.id && $can('update','packages-index')">
                                    Editar
                                </span>
                                <span v-else-if="formPackage.id && !$can('update','packages-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    import { isNumber } from 'util';
    import { stringify } from 'querystring';
    import ColorThief from 'colorthief';
    import loadImage from '~/components/covers/loadImage';

    export default {
        props: {
            MyPackage: {
                type : [Object, null],
                default: null,
            },
            PackageCategoryNew: {
                type: [Object, null],
                default: null,
            },
            StateNew: {
                type: [Object, null],
                default: null,
            },
            CityNew: {
                type: [Object, null],
                default: null,
            }
        },
        components: {
            loadImage
        },
        data() {
            return {
                colors: {
                    'image': null,
                    'primary': null,
                    'palete': null,
                },
                clearCurrent:   false,
                newImage                    : null,
                searchDelay                 : null,
                formPackage: new WebForm('#formPackage', {
                    title                   : null,
                    short_description       : null,
                    content                 : null,
                    start_date              : null,
                    start_time              : null,
                    start_time_array        : { "hh" : "01", "mm" : "00", "A"  : "AM" },
                    expired_date            : null,
                    expired_time            : null,
                    expired_time_array      : { "hh" : "11", "mm" : "59", "A"  : "PM" },
                    country_id              : null,
                    state_id                : null,
                    city_id                 : null,
                    package_category_id     : null,
                    state_package           : 'draft',
                    image                   : null, 
                    last_image              : null,
                    path_image              : null,
                    color_primary           : null,
                    id                      : null,
                    change                  : null,
                    btn_i18n                : false,
                    id_i18n                 : null,
                }),
                package_categories          : [],
                countries                   : [],
                states                      : [],
                cities                      : [],
                package_category            : null,
                country                     : null,
                state                       : null,
                city                        : null,
            }
        },
        watch: {
            country: function(val, oldVal) {
                this.$refs.vSelectState.clearSelection();
                this.states = [];
                if (!_.isEmpty(this.country)) {
                    let code = this.country.code;
                    this.countryCode = code;
                    this.getStates(this.country.id);
                }
            },
            state: function(val, oldVal) {
                this.$refs.vSelectCity.clearSelection();
                this.cities = [];
                if (!_.isEmpty(this.state)) {
                    this.$refs.vSelectCity.clearSelection();
                    this.getCities(this.state.id);
                }
            },
            StateNew: function(val, oldVal) {
                if (!_.isEmpty(this.StateNew) && this.country && this.country.id) {
                    if (this.StateNew && this.StateNew.id && this.StateNew.country_id == this.country.id) {
                        let state_ = {
                            id: this.StateNew.id,
                            label: `${this.StateNew.name}`,
                            name: `${this.StateNew.name}`,
                        };
                        this.states.push(state_);
                        this.state = state_;
                    }
                }
            },
            CityNew: function(val, oldVal) {
                if (!_.isEmpty(this.CityNew) && this.state && this.state.id) {
                    if (this.CityNew && this.CityNew.id && this.CityNew.state_id == this.state.id) {
                        let city_ = {
                            id: this.CityNew.id,
                            label: `${this.CityNew.name}`,
                            name: `${this.CityNew.name}`,
                        };
                        this.cities.push(city_);
                        this.city = city_;
                    }
                }
            },
            PackageCategoryNew: function(val, oldVal) {
                if (!_.isEmpty(this.PackageCategoryNew)) {
                    this.package_categories.push(this.PackageCategoryNew);
                    this.package_category = this.PackageCategoryNew;
                }
            },
            MyPackage: function(val, oldVal) {
                let vm = this;
                if (_.isEmpty(vm.MyPackage) || vm.MyPackage.id == '') {
                    vm.initDate();
                }else {

                    // vm.currentImage                         =   '/storage/packages/' + vm.MyPackage.banner;
                    console.log(vm.MyPackage);
                    vm.formPackage.last_image               =   vm.MyPackage.banner;
                    vm.formPackage.path_image               =   vm.MyPackage.path_image;
                    vm.formPackage.color_primary            =   vm.MyPackage.color_primary;
                    vm.formPackage.id                       =   vm.MyPackage.id;
                    vm.formPackage.title                    =   vm.decodeHTML(vm.MyPackage.title);
                    vm.formPackage.short_description        =   vm.decodeHTML(vm.MyPackage.short_description);
                    vm.formPackage.content                  =   vm.decodeHTML(vm.MyPackage.content);
                    vm.formPackage.expired_date             =   vm.MyPackage.expired_date;
                    vm.formPackage.start_date               =   vm.MyPackage.start_date;
                    vm.formPackage.state_package            =   vm.MyPackage.state_package;
                    
                    if (vm.MyPackage.start_time) {
                        let time = vm.MyPackage.start_time;
                        vm.formPackage.start_time = time;
                        vm.formPackage.start_time_array = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formPackage.start_time_array = { "hh" : "01", "mm" : "00", "A"  : "AM" }
                    }
                    
                    if (vm.MyPackage.expired_time) {
                        let time = vm.MyPackage.expired_time;
                        vm.formPackage.expired_time = time;
                        vm.formPackage.expired_time_array = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formPackage.expired_time_array = { "hh" : "11", "mm" : "59", "A"  : "PM" }
                    }

                    if(vm.MyPackage!=null && vm.MyPackage.package_category_id!="") {
                        vm.package_category = vm.package_categories.find(c => {
                            return c.id == vm.MyPackage.package_category_id;
                        });
                    }
                    vm.getCountry(vm.MyPackage.city.state.country.id);

                    vm.formPackage.btn_i18n     = (vm.MyPackage.btn_i18n) ? vm.MyPackage.btn_i18n : false;

                    if (vm.MyPackage.btn_i18n && vm.MyPackage.i18n.length > 0) {
                        vm.formPackage.title   	            = (vm.MyPackage.i18n[0].title) ? vm.MyPackage.i18n[0].title : vm.MyPackage.title;
                        vm.formPackage.short_description   	= (vm.MyPackage.i18n[0].short_description) ? vm.MyPackage.i18n[0].short_description : vm.MyPackage.short_description;
                        vm.formPackage.content              = (vm.MyPackage.i18n[0].content) ? vm.MyPackage.i18n[0].content : vm.MyPackage.content;
                        vm.formPackage.id_i18n   	        = vm.MyPackage.i18n[0].id;
                    }
                }
            },
        },
        methods: {
            getImage(obj) {
                let last_primary = this.formPackage.color_primary;

                let primary = 'rgb(' + obj.primary.join(',') + ')';
                obj.primary = primary;
                obj.palette = obj.palette.map(p => {
                    return 'rgb(' + p.join(',') + ')';
                });
                obj.palette.unshift(primary);
                this.colors = obj;
                
                if (obj.hasOwnProperty('last')) {
                    this.colors.primary = last_primary;
                } else {
                    this.formPackage.change = true;
                }
            },
            selectPrimary(color) {
                this.colors.primary = color;
            },
            setFocus(e) {
                setTimeout (() => {
                    e.target.focus();
                }, 300);
            },
            searchCountry(search, loading) {
                if (search.length>2 ) {
                    loading(true);
                    const vm = this;
                    clearInterval(vm.searchDelay);
                    vm.searchDelay = setInterval(function(){
                        clearInterval(vm.searchDelay);
                        axios.get(`/admin/location/countries/${search}`).then((response) => {
                            if (response.data) {
                                let countries = response.data.map(y => {
                                    let country = y;
                                    let d = {
                                        id      :       country.id,
                                        code    :       country.short_iso_code,
                                        name    :       `${country.name} (${country.native_name})`,
                                        label   :       `${country.name} (${country.native_name})` 
                                    }
                                    return d;
                                });
                                vm.countries = countries;
                            } else {
                                console.log('No se pudieron cargar los paquetes');
                            }
                            loading(false);
                        });
                    }, 500);
                }
            },
            getCountry(id) {
                let vm = this;
                axios.get(`/admin/location/countries/${id}`).then((response) => {
                    if (response.data) {
                        let data = response.data;
                        let countries = [{
                            id      :       data.id,
                            code    :       data.short_iso_code,
                            name    :       `${data.name} (${data.native_name})`,
                            label   :       `${data.name} (${data.native_name})` 
                        }];
                        vm.countries = countries;
                        vm.country = vm.countries[0];
                        vm.getStates(vm.country.id);
                    } else {
                        console.log('No se pudieron cargar los paquetes');
                    }
                });
            },
            getStates(country_id){
                let vm = this;
                let endpoint = `/admin/location/states/country/${country_id}`;
                axios.get(endpoint).then(({data}) => {
                    let states = data.map(state => {
                        let d = {
                            id: state.id,
                            label: `${state.name}`,
                            name: `${state.name}`,
                        }
                        return d;
                    });
                    this.states = states;
                    if(vm.MyPackage!=null && vm.MyPackage.city.state_id!="") {
                        vm.state = vm.states.find(c => {
                            return c.id == vm.MyPackage.city.state_id;
                        });
                    }
                });
            },
            getCities(state_id){
                let vm = this;
                let endpoint = `/admin/location/cities/state/${state_id}`;
                axios.get(endpoint).then(({data}) => {
                    let cities = data.map(city => {
                        let d = {
                            id: city.id,
                            label: `${city.name}`,
                            name: `${city.name}`,
                        }
                        return d;
                    });
                    this.cities = cities;

                    if(vm.MyPackage!=null && vm.MyPackage.city.id!="") {
                        vm.city = vm.cities.find(c => {
                            return c.id == vm.MyPackage.city.id;
                        });
                    }
                });
            },
            async getPackageCategories(){
                let endpoint = '/admin/packages/categories/all';
                let {data} = await axios.get(endpoint);
                this.package_categories = data;
            },
            validateImage() {
                let vm = this;
                let respo = 0;
                let formato =["data:image/jpeg;base64", "data:image/jpg;base64", "data:image/png;base64"];
                if(vm.formPackage.image!="" && vm.formPackage.image!=null) {
                    
                    let short = vm.formPackage.image.substring(0, 50);
                    for (var i = 0; i < formato.length; i++) {
                        if(short.indexOf(formato[i], 0) == 0 ) {
                            respo++;
                        }
                    }
                }
                return respo;
            },
            onSavePackage() {
                let vm = this;
                
                this.formPackage.image = this.colors.image;
                this.formPackage.color_primary = this.colors.primary;

                if (vm.formPackage.btn_i18n==false) {

                    if (this.formPackage.image == null) {
                        vm.$snotify.warning('Debe seleccionar una imagen');
                        return;
                    }
    
                    if(vm.validateImage()==0  && vm.formPackage.id == null) {
                        vm.currentImage = null;
                        vm.newImage = null;
                        vm.formPackage.image = '';
                        vm.$snotify.warning('Archivo de Imagen no compatible...');
                        return;
                    }
    
                    if(vm.city) {
                        vm.formPackage.city_id = vm.city.id;
                    }
    
                    if(vm.state) {
                        vm.formPackage.state_id = vm.state.id;
                    }
    
                    if(vm.country) {
                        vm.formPackage.country_id = vm.country.id;
                    }
                    
                    if(vm.package_category) {
                        vm.formPackage.package_category_id = vm.package_category.id;
                    }
    
                    if(vm.formPackage.start_time_array) {
                        let time = vm.formPackage.start_time_array;
                        vm.formPackage.start_time = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
                    }
    
                    if(vm.formPackage.expired_time_array) {
                        let time = vm.formPackage.expired_time_array;
                        vm.formPackage.expired_time = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
                    }
                }


                let tipo = (vm.formPackage.id != null) ? 2 : 1;
                if(this.formPackage.id != null && vm.formPackage.btn_i18n==false) {
                    $.confirm({
                        title: `Editar Paquete Turistico`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () { },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (tipo) {
                let vm = this;
                
                vm.formPackage.post('/admin/packages/save').then(response => {
                    let data = response.data;

                    if (data.user_request_status) {
                        $('#modalMyPackage').modal('hide');
                        return;
                    }

                    let snotify_message = tipo == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);
                    if(tipo == 1) {
                        vm.$emit('createdPackage', {data});
                    } else {
                        vm.$emit('reloadPackage');
                    }
                    $('#modalMyPackage').modal('hide');
                    vm.formPackage.clear();
                    vm.formPackage.reset();
                    return;
                });
            },
            initDate() {
                let vm = this;
                if(vm.MyPackage==null) {
                    vm.formPackage.start_date = moment().format('YYYY-MM-DD');
                    vm.formPackage.expired_date = moment().add(3,'days').format('YYYY-MM-DD');
                }
            }
        },
        async mounted() {
           let vm = this;

            await vm.getPackageCategories();
            vm.initDate();

            $('#modalMyPackage').off('hidden.bs.modal');
            $('#modalMyPackage').on('hidden.bs.modal', function () {
                vm.currentImage = '/img/pick.png';
                vm.newImage         = null;
                vm.country          = null;
                vm.state            = null;
                vm.city             = null;
                vm.package_category = null;
                vm.formPackage.clear();
                vm.formPackage.reset();
                vm.colors = { 'image': null, 'primary': null, 'palete': null};
                setTimeout (() => {
                    vm.clearCurrent = true;
                }, 200);
                vm.clearCurrent = false;
                vm.initDate();
                vm.$emit('closePackageModal');
            });
        }
    }
</script>
<style>
    .time-picker input.display-time{
        width: 90px;
    }
</style>
<style scope>
    .modal#modalMyPackage{
        overflow-y: auto !important;
    }

    .circulo {
        width: 50px;
        height: 50px;
        float: left;
        display: block;
        cursor: pointer;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: solid 3px white;
    }

    .cuadro {
        width: 100px; 
        height: 100px; 
        border: solid 3px white;
    }
</style>