<template>
	<div class="modal fade" id="modalTypeRoom">
		<div class="modal-dialog">
			<div class="modal-content">
				<form id="formTypeRoom" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span v-if="TypeRoom && TypeRoom.btn_i18n == true">Traducción de Habitacion</span>
							<span v-if="formTypeRoom.id">Editar Tipo Habitacion</span>
							<span v-else>Nuevo Tipo Habitacion</span>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="title">Nombre</label>
							<input
								type="text"
								class="form-control"
								placeholder="Ingrese Nombre"
								v-model="formTypeRoom.title"
								name="title"
							/>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" v-can:create="'packages-hotels-index'">
							<span v-if="TypeRoom && TypeRoom.btn_i18n==true">Guardar Traducción</span>
							<span v-else-if="formTypeRoom.id && $can('update','packages-hotels-index')">Editar</span>
							<span v-else-if="formTypeRoom.id && !$can('update','packages-hotels-index')" >Solicitar Edicion</span>
							<span v-else>Guardar</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["TypeRoom"],
	data() {
		return {
			formTypeRoom: new WebForm("#formTypeRoom", {
				title: "",
				id: null,
				btn_i18n                : false,
				id_i18n                 : null,
			})
		};
	},
	watch: {
		TypeRoom: function(val, oldVal) {
			if (_.isEmpty(this.TypeRoom) || this.formTypeRoom.id == "") {
				// console.log('Vacio Att: ', this.AttractionCategory);
			} else {
				let vm = this;
				vm.formTypeRoom.fill(vm.TypeRoom);
				if (vm.TypeRoom.btn_i18n && vm.TypeRoom.i18n.length > 0) {
					vm.formTypeRoom.title   	= (vm.TypeRoom.i18n[0].title) ? vm.TypeRoom.i18n[0].title : vm.TypeRoom.title;
					vm.formTypeRoom.id_i18n   	= vm.TypeRoom.i18n[0].id;
				}
			}
		}
	},
	methods: {
		onSaveItem() {
			let vm = this;
			// tipo : 1 "Nuevo" , Sino, Editar
			let tipo = vm.formTypeRoom.id == null ? 1 : 2;
			if (this.formTypeRoom.id != null && this.formTypeRoom.btn_i18n==false) {
				$.confirm({
					title: `Editar El Tipo de Habitacion`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function() {
								vm.updateOrCreate(
									"/admin/hotels/typerooms/save",
									tipo
								);
							}
						},
						cancelar: function() {}
					}
				});
			} else {
				vm.updateOrCreate("/admin/hotels/typerooms/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formTypeRoom.post(url).then(response => {
				let data = response.data;

				if (data.user_request_status) {
					$("#modalTypeRoom").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento Actualizado con exito.";
				vm.$snotify.success(snotify_message);
				let obj = data.success;
				vm.$emit("reloadTypeRoomModal", obj);

				$("#modalTypeRoom").modal("hide");
				return;
			});
		}
	},
	mounted() {
		let vm = this;
		$("#modalTypeRoom").off("hidden.bs.modal");
		$("#modalTypeRoom").on("hidden.bs.modal", function() {
			vm.formTypeRoom.clear();
			vm.formTypeRoom.reset();
			vm.$emit("closeTypeRoomModal");
		});
	}
};
</script>
<style scoped>
</style>