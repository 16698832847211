`<template>
    <div>
        <div class="row section mx-2 my-1 p-2">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-travel-luggag mr-1"></span> Paquete Turistico Cotizado
                            <!-- <a :href="process.env.WEBSITE_URL + (process.env.WEBSITE_URL.slice(-1)=='/') ? 'packages/' + quotePackage.id : '/packages/' + quotePackage.id " target="_blank" class="float-right badge badge-success">a Ir al paquete</a> -->
                            <a :href="'https://beta.pavolar.com/packages/' + quotePackage.id " target="_blank" class="float-right badge badge-success">a Ir al paquete</a>
                        </label>
                    </div>
                </div>
                <div class="row" v-if="loadComplete">
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Paquete</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quotePackage.package.title">
                            </div>
                            <div class="form-group col-6">
                                <label>Categoria</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quotePackage.package.package_category.name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6" v-if="quotePackage.package_hotel && quotePackage.package_hotel.hotel">
                                <label>Hotel</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quotePackage.package_hotel.hotel.name">
                            </div>
                            <div class="form-group col-6" v-if="quotePackage.package_stay && quotePackage.package_stay.hotel_room && quotePackage.package_stay.hotel_room.type_room">
                                <label>Tipo Habitacion</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quotePackage.package_stay.hotel_room.type_room.title">
                            </div>
                        </div>
                        <div class="row" v-if="quotePackage.package_stay && quotePackage.package_stay">
                            <div class="form-group col-12">
                                <label>Detales de estadia</label>
                                <br>
                                <div class="border my-1 p-1" style="height: auto; background-color: #e9ecef;"> 
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            Fecha Ingreso
                                            <br>
                                            <small>{{ quotePackage.package_stay.start_date + ' ' + quotePackage.package_stay.start_hour | moment('lll')}}</small>
                                        </div>
                                        <div class="col-6">
                                            Fecha Salida
                                            <br>
                                            <small>{{ quotePackage.package_stay.end_date + ' ' + quotePackage.package_stay.end_hour | moment('lll')}}</small>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-3">
                                            Costo
                                            <br>
                                            <small>$ {{ quotePackage.package_stay.cost}}</small>
                                        </div>
                                        <div class="col-3">
                                            Impuestos
                                            <br>
                                            <small>$ {{ quotePackage.package_stay.taxes }}</small>
                                        </div>
                                        <div class="col-3">
                                            Utilidad
                                            <br>
                                            <small>$ {{ quotePackage.package_stay.utility }}</small>
                                        </div>
                                        <div class="col-3">
                                            Link
                                            <br>
                                            <small><a :href="quotePackage.package_stay.link" target="_blank">Link</a></small>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            Descripcion
                                            <br>
                                            <small>{{ quotePackage.package_stay.description }}</small>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6" v-if="quotePackage.services_json.length>0">
                                <label>Servicios Seleccionados</label>
                                <div class="row" v-for="(service, idx_service) in quotePackage.services_json" :key="service.id + '_idx_' + idx_service">
                                    <div class="col-12 col-sm-9">
                                        <p class="m-0"><small>{{ service.service.title }}</small></p>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <p class="m-0 mb-1 text-right" v-if="service.is_free"><small class="badge badge-success"> Incluido </small></p>
                                        <p class="m-0 mb-1 text-right" v-if="service.prices"><small class="">$ {{ ( parseFloat(service.prices.cost || 0) + parseFloat(service.prices.taxes || 0) + parseFloat(service.prices.utility || 0)) }} </small></p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6" v-if="quotePackage.attractions_json.length>0">
                                <label>Attracciones Seleccionadas</label>
                                <div class="row" v-for="(attraction, idx_attraction) in quotePackage.attractions_json" :key="attraction.id + '_idx_' + idx_attraction">
                                    <div class="col-12 col-sm-9">
                                        <p class="m-0"><small>{{ attraction.attraction.name }}</small></p>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <p class="m-0 mb-1 text-right" v-if="attraction.is_free"><small class="badge badge-success"> Incluido </small></p>
                                        <p class="m-0 mb-1 text-right" v-if="attraction.prices"><small class="">$ {{ ( parseFloat(attraction.prices.cost || 0) + parseFloat(attraction.prices.taxes || 0) + parseFloat(attraction.prices.utility || 0)) }} </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Comentarios del usuario sobre el paquete</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quotePackage.comments}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12">
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Tipo de Atraccion Turistica</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quotePackage.attraction_category.name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Adultos</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quotePackage.n_adults>1) ? quotePackage.n_adults + ' Personas' : quotePackage.n_adults + ' Persona' ">
                            </div>
                            <div class="form-group col-6">
                                <label>Niños</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quotePackage.n_childrens>1) ? quotePackage.n_childrens + ' Personas' : quotePackage.n_childrens + ' Persona' ">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6" :class="{'col-12':quotePackage.only_one_day}">
                                <label v-if="quotePackage.only_one_day" class="w-100">Fecha
                                    <span class="float-sm-right">
                                        <span class="badge badge-success">Solo un dia</span>
                                    </span>
                                </label>
                                <label v-else>Fecha de Entrada </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quotePackage.start_date).format('LL')">
                            </div>
                            <div class="form-group col-6" v-if="!quotePackage.only_one_day">
                                <label>Fecha de Salida</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quotePackage.end_date).format('LL')">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Comentarios sobre atraccion turistica</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quotePackage.description}}</div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row" v-else>
                    <div class="col-12 text-center d-flex justify-content-center align-items-center">
                        <div class="spinner-border m-5" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- <div class="col-md-7 border-left">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Soluciones de Atraccion Turistica
                        </label>
                        <span class="float-sm-right">
                            <button class="btn ml-3 btn-primary float-right btn-sm" :disabled="!owner"
                            data-toggle="modal" data-target="#touristResponseModal"
                            @click="showTouristModal()">
                                Nueva Solucion
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row" v-if="quotePackage && quotePackage.quote_tourist_response && quotePackage.quote_tourist_response.length>0">
                    <div class="form-group col-12  mb-0 pb-0">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                        <th scope="col">#</th>
                                        <th scope="col">Lugar</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Fecha Final</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadComplete">
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center py-3">
                                                    <div class="spinner-border text-secondary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div>
                                                        <small>Cargando...</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item, index) in (touristResponse) ? touristResponse : null"
                                        :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">
                                                {{ (item.attraction) ? item.attraction.name : 'Error to load... id: ' + item.attraction_id }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.non_date) ? 'Sin Fechas...' : null}}
                                                {{ (item.body_json && item.body_json.entry_format) ? $moment(item.body_json.entry_format).format('LL') : null }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.non_date) ? 'Sin Fechas...' : null}}
                                                {{ (item.body_json && item.body_json.out_format) ? $moment(item.body_json.out_format).format('LL') : null }}
                                            </td>
                                            <td class="text-right">
                                                {{ (item.body_json && !item.body_json.is_free) ? '$ ' + (item.body_json.cost + item.body_json.taxes + item.body_json.utility).toFixed(2) : null }}
                                                <span class="text-success"><b>
                                                {{ (item.body_json && item.body_json.is_free) ? '$ 0.00' : null }}
                                                </b></span>
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-info" @click="showTouristModal(index)"
                                                data-toggle="modal" data-target="#touristResponseModal">
                                                    <span class="icon-view font-12"></span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger" :disabled="!owner"
                                                @click="onDeleteTouristResponse(index)">
                                                    <span class="icon-bin-2 font-12"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" v-else>
                    <div class="form-group align-middle text-center col-12 mb-0 pb-0 mt-5">
                        <p class="mb-0">Aun no se ha proporcionado soluciones</p>
                        <p class="mb-0"><small>"Haga click en
                            <b style="cursor:pointer;">Nueva Solucion</b> para agregar datos"
                        </small></p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        quotePackage: {
            type: [Object],
            default () {
                return false
            }
        },
        lastUpdateLocal: {
            type: Number
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    components: {
        
    },
    data() {
        return {
            showResponse:       true,
            loadComplete:       false,
            attractionBank:     [],
            servicesBank:       [],

        };
    },
    watch: {
        lastUpdateLocal: function(val, oldVal) {
            let vm = this;
            if (vm.lastUpdateLocal != null) {
                // vm.initPackageResponse();
            }
        },
    },
    methods: {
        async initPackageResponse() {
            let vm = this;
            vm.loadComplete = false;
            try {
                vm.quotePackage.services_json = (vm.quotePackage.services_json && JSON.parse(vm.quotePackage.services_json)) ? JSON.parse(vm.quotePackage.services_json) : null;
                vm.quotePackage.attractions_json = (vm.quotePackage.attractions_json && JSON.parse(vm.quotePackage.attractions_json)) ? JSON.parse(vm.quotePackage.attractions_json) : null;
            } catch (error) {
            }
            
            if (vm.quotePackage.services_json.length>0) {
                let varTemp = vm.quotePackage.services_json;

                await varTemp.reduce(async (promise, thisItem) => {
                    await promise;
                    const data = await axios.get(`/admin/packages/services/${thisItem.id}`);
                    if (data.data && data.data[0]) {
                        thisItem.service = data.data[0];
                    } else {
                        console.log(`Ups... Error al buscar: /admin/packages/services/${thisItem.id}`);
                    }
                }, Promise.resolve());
                vm.quotePackage.services_json = varTemp;
            }

            if (vm.quotePackage.attractions_json.length>0) {
                let varTemp = vm.quotePackage.attractions_json;

                await varTemp.reduce(async (promise, thisItem) => {
                    await promise;
                    const data = await axios.get(`/admin/attractions/${thisItem.id}`);
                    console.log("data, ", data);
                    if (data.data) {
                        thisItem.attraction = data.data;
                    } else {
                        console.log(`Ups... Error al buscar: /admin/attractions/${thisItem.id}`);
                    }
                }, Promise.resolve());
                vm.quotePackage.attractions_json = varTemp;
            }

            console.log("vm.quotePackage ", vm.quotePackage);
            vm.loadComplete = true;
            
        }
    },
    computed: {
        
    },
    mounted() {
        console.log('viewPackageRequirements Mounted');
        this.initPackageResponse();
        
    },
    created() {
        let vm      = this
        // let url = process;
        // let newVar = url.env;
        // console.log('CREATED URL ', newVar);
    }
}
</script>
<style scoped>
    .table-detail-flight p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .font-12 {
        font-size: 12px;
    }
    .option-route:hover .btn-option-route{
        opacity: 1;
    }
    .btn-option-route{
        opacity: 0;
        transition: 0.3s;
    }
</style>