<template>
    <div class="loading-wrapper" :class="{ 'overlay' : overlay}" v-if="isActive">
        <div>
            <h6 v-if="title" class="w-100 text-center" :class="{ 'mb-0' : !_.isEmpty(message)}">{{title}}</h6>
            <p v-if="message" class="w-100 text-center text-muted">{{message}}</p>
            <div class="loading-container mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="30" stroke="#3e85f7" stroke-width="10" stroke-linecap="round" fill="none" transform="rotate(22.6915 50 50)">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.3s" values="0 50 50;180 50 50;720 50 50" keyTimes="0;0.5;1"></animateTransform>
                        <animate attributeName="stroke-dasharray" repeatCount="indefinite" dur="1.3s" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1"></animate>
                    </circle>
                </svg>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .loading-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        &.overlay{
            position: fixed;
            z-index: 10000;
            background: rgba(248, 248, 248, 0.95);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>

<script>
    export default {
        data() {
            return {
                isActive: this.active,
            }
        },
        methods: {
            show(){
                this.isActive = true
            },
            hide(){
                this.isActive = false
            },
            toggle(){
                this.isActive = !this.isActive
            },
        },
        props: {
            active: {
                type: Boolean,
                default: true
            },
            overlay: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: null
            },
            message: {
                type: String,
                default: null
            }
        },
        watch: {
            active(value) {
                this.isActive = value
            }
        },
    }
</script>