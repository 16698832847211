<template>
	<div class="modal fade" id="addFurnishing">
		<div class="modal-dialog">
			<div class="modal-content">
				<form id="formFurnishing" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span v-if="Furnishing && Furnishing.btn_i18n == true">Traducción de Mueble</span>
							<span v-else-if="formFurnishing.id">Editar Muebles y Equipo</span>
							<span v-else>Ingresar Muebles y Equipo</span>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="name">Nombre</label>
							<input
								type="text"
								class="form-control"
								placeholder="Ingrese Nombre"
								v-model="formFurnishing.name"
								name="name"
							/>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" v-can:create="'packages-hotels-index'">
							<span v-if="Furnishing && Furnishing.btn_i18n==true">Guardar Traducción</span>
							<span v-else-if="formFurnishing.id && $can('update','packages-hotels-index')" >Editar</span>
							<span v-else-if="formFurnishing.id && !$can('update','packages-hotels-index')" >Solicitar Edicion</span>
							<span v-else>Guardar</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["Furnishing"],
	data() {
		return {
			formFurnishing: new WebForm("#formFurnishing", {
				name: "",
				id: null,
				btn_i18n                : false,
				id_i18n                 : null,
			})
		};
	},
	watch: {
		Furnishing: function(val, oldVal) {
			if (
				_.isEmpty(this.Furnishing) ||
				this.formFurnishing.id == ""
			) {
				// console.log('Vacio Att: ', this.AttractionCategory);
			} else {
				let vm = this;
				vm.formFurnishing.fill(vm.Furnishing);
				if (vm.Furnishing.btn_i18n && vm.Furnishing.i18n.length > 0) {
					vm.formFurnishing.name   	= (vm.Furnishing.i18n[0].name) ? vm.Furnishing.i18n[0].name : vm.Furnishing.name;
					vm.formFurnishing.id_i18n   = vm.Furnishing.i18n[0].id;
				}
			}
		}
	},
	methods: {
		onSaveItem() {
			let vm = this;

			// tipo : 1 "Nuevo" , Sino, Editar
			let tipo = vm.formFurnishing.id == null ? 1 : 2;
			if (this.formFurnishing.id != null && this.formFurnishing.btn_i18n==false) {
				$.confirm({
					title: `Editar Muebles y equipo`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function() {
								vm.updateOrCreate(
									"/admin/hotels/furnishings/save",
									tipo
								);
							}
						},
						cancelar: function() {}
					}
				});
			} else {
				vm.updateOrCreate("/admin/hotels/furnishings/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formFurnishing.post(url).then(response => {
				let data = response.data;

				this.formFurnishing.id = null;

				if (data.user_request_status) {
					$("#addFurnishing").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento Actualizado con exito.";
				vm.$snotify.success(snotify_message);
				let obj = data.success;
				vm.$emit("reloadFurnishingModal", obj);
				$("#addFurnishing").modal("hide");
				return;
			});
		}
	},
	mounted() {
		let vm = this;
		$("#addFurnishing").off("hidden.bs.modal");
		$("#addFurnishing").on("hidden.bs.modal", function() {
			vm.formFurnishing.clear();
			vm.formFurnishing.reset();
			vm.$emit("closeFurnishingModal");
		});
	}
};
</script>
<style scoped>
</style>