<template>
    <div class="mx-3">
        <div class="row">
            <div class="col-12 section py-3">
                <h5 class="font-weight-bold mb-0">Contenido para Soporte</h5>
                <p class="text-muted">Categorias y preguntas frecuentes par auto-ayuda de usuarios</p>

                <div class="d-flex justify-content-end w-100">
                    <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newCategoryModal" v-can:create>
                        <span class="icon-ile-hierarchy"></span> Nueva Categoria
                    </button>
                    <button class="btn btn-primary mr-2" v-can:create @click.prevent='addQuestion'>
                        <span class="icon-alert-question"></span> Nueva Pregunta
                    </button>
                </div>

                <div class="mt-3">
                    <div class="data-container mt-3">
                        <div class="row">
                            <div class="col-12 col-md-5 col-lg-3 col-xl-3">
                                <div class="list-group mb-2" id="list-tab" role="tablist">
                                    <select name="sltLng" id="sltLng" v-model="lngQst" @change="loadData" class="form-control mb-2 font-weight-bold font-size-0_8rem">
                                        <option value="15">ESPAÑOL</option>
                                        <option value="7">ENGLISH</option>
                                    </select>

                                    <a class="list-group-item font-weight-bold font-size-0_8rem disabled">
                                        CATEGORIAS
                                        <svg v-if='loading' class="spinner spinner-2" width="25px" height="25px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                                        </svg>
                                    </a>

                                    <a class="list-group-item list-group-item-action" v-for="(category, index) in categories" :key="index" :class="category.id == activeCategory.id ? 'active' : ''" data-toggle="list" role="tab" :aria-controls="category.id" @click.prevent="activeCategory = category">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p class="mb-0 font-weight-bold">
                                                <span class="mr-1" :class="category.icon"></span> {{category.name}}
                                            </p>
                                            <div class='action-buttons'>
                                                <button class='btn btn-light btn-sm border' data-toggle="modal" data-target="#newCategoryModal" v-can:update @click.prevent='editCategory(category)'>
                                                    <span grid="16" class="icon-pencil"></span>
                                                </button>
                                                <button class='btn btn-light btn-sm border text-danger' v-can:manage @click.prevent='deleteCategory(category)'>
                                                    <span grid="16" class="icon-bin"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="col-12 col-md-7 col-lg-9 col-xl-9">
                                <div class="tab-content" id="nav-tabContent" v-if="activeCategory">
                                    <h5 class="font-weight-bold">
                                        <span class="mr-1" :class="activeCategory.icon"></span> {{ activeCategory.name }}
                                    </h5>
                                    <p v-if="activeCategory.description">{{ activeCategory.description }}</p>

                                    <div class="accordion" id="accordionExample">
                                        <div class="card" v-for="question in activeCategory.questions" :key="question.id">
                                            <div class="card-header cursor-pointer d-flex justify-content-between" :id="'heading'+question.id" data-toggle="collapse" :data-target="'#collapse'+question.id" aria-expanded="true" :aria-controls="'collapse'+question.id">
                                                <p class="mb-0">{{ question.title }}</p>
                                                <div class='action-buttons'>
                                                    <button class='btn btn-transparent btn-sm' v-can:update @click.stop='editQuestion(question.id)'>
                                                        <span grid="16" class="icon-pencil-edith"></span>
                                                    </button>
                                                    <button class='btn btn-transparent btn-sm text-danger' v-can:manage @click.stop='deleteQuestion(question)'>
                                                        <span grid="16" class="icon-bin"></span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div :id="'collapse'+question.id" class="collapse" :aria-labelledby="'heading'+question.id" data-parent="#accordionExample">
                                                <div class="card-body html-block" v-html="question.content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="newCategoryModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form autocomplete="off" @submit.prevent="saveCategory" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title"><span v-if="!category.id">Nueva</span> <span v-else>Editar</span> Categoría</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="cat_name">Idioma</label>
                                            <select name="sltLngCat" id="sltLngCat"  v-model="category.lngCat" class="form-control">
                                                <option value="15">ESPAÑOL</option>
                                                <option value="7">ENGLISH</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="cat_name">Nombre</label>
                                            <input type="text" class="form-control" v-can:manage.disable v-model="category.name" id="cat_name" v-bind:class="{ 'is-invalid': category.errors.name }">
                                            <span class="invalid-feedback" v-for="error in category.errors.name || []" :key="error.name">{{ error }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="cat_description">Descripción</label>
                                            <textarea class="form-control" v-can:manage.disable v-model="category.description" id="cat_description" v-bind:class="{ 'is-invalid': category.errors.description }" rows="3"></textarea>
                                            <span class="invalid-feedback" v-for="error in category.errors.description || []" :key="error.description">{{ error }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <IconSelector v-model="category.icon"/>
                                    </div>
                                </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary" v-can:write.disable><span v-if="!category.id">Guardar</span><span v-else>Editar</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import IconSelector from '~/components/icons/IconSelector.vue'
    export default {
        middleware:'auth',
        layout: 'dashboard',
        data () {
            return {
                loading: true,
                showSpinner: false,
                categories: [],
                activeCategory: null,
                questions: null,
                lngQst : 15,
                category: {
                    id: null,
                    name: '',
                    description: '',
                    icon: null,
                    lngCat : 15,
                    errors: []
                },
                subCategory: {
                    id: null,
                    name: '',
                    description: '',
                    category_id: null,
                    icon: null,
                    errors: []
                },
                question: {
                    title: '',
                    content: '',
                    category_id: null,
                    sub_category_id: null,
                    errors: []
                }
            }
        },
        metaInfo () {
            return { title: "Support Contents" }
        },
        components: {
            IconSelector
        },
        methods: {
            getSupportData: function () {
                let lng = this.lngQst
                this.activeCategory = []
                
                this.category =  {
                    id: 0,
                    name: '',
                    description: '',
                    icon: null,
                    lngCat : 15,
                    errors: []
                }
                return axios.get('/admin/contents/support/categories/all/'+lng)
                .then(response => {
                    this.categories = response.data || []
                    this.loading    = false
                    if(this.categories.length > 0)
                        this.activeCategory = this.categories[0]
                })
            },
            loadData(){
                let lng = this.lngQst
                this.activeCategory = []
                this.category =  {
                    id: 0,
                    name: '',
                    description: '',
                    icon: null,
                    lngCat : 15,
                    errors: []
                }
                return axios.get('/admin/contents/support/categories/all/'+lng)
                .then(response => {
                    this.categories = response.data || []
                    this.loading    = false
                    if(this.categories.length > 0)
                        this.activeCategory = this.categories[0]
                })
            },
            editCategory (category) {
                this.category.id = category.id
                this.category.name = category.name
                this.category.description = category.description
                this.category.icon = category.icon
            },
            deleteQuestion (question) {
                let vm = this
                $.confirm.delete(
                    `Eliminar Pregunta`,
                    `Seguro quiere eliminar <b>${question.title}</b>?`,
                    function(){
                        vm.confirmDeleteQuestion(question.id)
                    }
                )
            },
            confirmDeleteQuestion (id) {
                let vm = this
                let endpoint = '/admin/contents/support/question/delete'
                axios.post(endpoint, {id}).then(response => {
                    // if(response.data.deleted) {

                    //     let index = vm.questions.findIndex(q => {
                    //         return q.id == id
                    //     })

                    //     if(index >= 0) {
                    //         vm.questions.splice(index, 1)
                    //         vm.$snotify.success('Pregunta Eliminada');
                    //     }
                    // }
                    vm.getSupportData()
                    vm.$snotify.success('Pregunta Eliminada');
                })
            },
            deleteCategory (category) {
                let vm = this
                $.confirm.delete(
                    `Eliminar Cetegoria`,
                    `Seguro quiere eliminar ${category.name}? al borrar la categoria se borraran todas las sub categorias y preguntas relacionadas`,
                    function(){
                        vm.confirmDeleteCategory(category.id)
                    }
                )
            },
            confirmDeleteCategory (id) {
                let vm = this
                let endpoint = '/admin/contents/support/category/delete'
                axios.post(endpoint, {id}).then(response => {
                    if(response.data.deleted) {
                        let index = vm.categories.findIndex(c => {
                            return c.id == id
                        })

                        if(index >= 0) {
                            vm.categories.splice(index,1)
                            vm.$snotify.success('Elemento eliminado');
                        }
                    }
                })
            },
            saveCategory: function () {
                let vm = this
                let data = {
                    name: this.category.name,
                    description: this.category.description,
                    icon: this.category.icon,
                    lngCat: this.category.lngCat
                
                }
                if(this.category.id) {
                    data.id = this.category.id
                }
                axios.post('/admin/contents/support/categories/add', data).then(response => {
                    if(!response.data.success) {
                        this.category.errors = response.data.error
                        return;
                    }
                    const { category } = response.data

                    category.sub_categories = []

                    let exist_cat = vm.categories.find(c => c.id == category.id)

                    if(exist_cat) {
                        exist_cat.name = category.name
                        exist_cat.description = category.description
                    } else {
                        this.categories.push(category)
                    }
                    
                    vm.getSupportData()
                    vm.$snotify.success('Elemento almacenado');
                    $('#newCategoryModal').modal('hide')
                    //Restaura los controles
                }).catch(exception => {
                    this.setFormErrors(response, this.category)
                });
            },
            addQuestion: function () {
                this.$router.push({ name: 'contents-faqs-question-id'})
            },
            editQuestion(id){
                this.$router.push({name: 'contents-faqs-question-id', params: { id } })
            }
        },
        async mounted() {
            let vm = this
            await vm.getSupportData()

            $('#newCategoryModal').off('hidden.bs.modal')
            $('#newCategoryModal').on('hidden.bs.modal', function () {
                vm.clearForm(vm.category);
                vm.category.id = null
                vm.category.description = ''
            })
        }
    }
</script>

<style scoped>
    .card:hover{
        box-shadow: none !important;
    }
    .list-group-item.active{
        color: #fff;
    }

    /* .list-group-item.disabled {
        opacity: 1;
    }
    .data-container {
        flex: 1;
    }
    .outer {
        position: relative;
        padding: 5px;
        background-color: #f3f3f8;
        border-radius: 3px;
        min-height: 150px;
    }
    .list-group li {
        cursor: pointer;
    }
    .font-size-0_8rem {
        font-size: 0.8.rem;
    }
    .category-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .sub-category-link {
        color: #495057;
        font-size: 1.1rem;
        text-decoration: underline !important;
    }
    .sub-category-link:hover{
        color: #3e85f7 !important;
    }
    .spinner-1 {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .spinner-2 {
        position: absolute;
        right: 20px
    }

    .btn-transparent {
        background-color: inherit !important;
    }
    .list-group-item.active .btn-transparent {
        color: #FFF !important;
    }

    .go-back {
        height: 40px;
        width: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        color: rgb(255, 255, 255) !important;
        font-weight: 700;
        font-size: 1.5rem;
        position: relative;
    }
    .go-back-icon {
        font-weight: 700;
        font-size: 1.2rem;
    }
    .go-back-label {
        font-size: 1.1rem;
        color: inherit !important;
    }

    .go-back:hover .go-back-icon {
        color: #fff !important;
    }

    a.text-primary:hover, a.text-primary:focus {
        color: #fff !important
    } */
</style>
