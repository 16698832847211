
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


import io from 'socket.io-client';
window.io = io;
import Vue from 'vue'
import {Picker, Emoji} from 'emoji-mart-vue'
import ClickOutside from 'vue-click-outside'
import eventHub from './plugins/eventHub.js'
// const eventHub = new Vue() // Single event hub

eventHub.openConversationById = function (data) {
    document.getElementById(`channel_id_${data.conversation_id}`).dispatchEvent( new Event('click'))
}

eventHub.openPage = function (data) {
    let routes = data.vm.$router.options.routes
    let route = routes.find( route => route.name == data.params.component)
    data.vm.$router.push({ name: route.name, params: { id: data.params.id } })
}

eventHub.redirectToUrl =  function (data) {
    data.vm.$router.push(data.url)
}
// Distribute to components using global mixin
Vue.mixin({
    data: function () {
        return {
            eventHub: eventHub
        }
    }
})

Vue.component('picker', Picker)
Vue.component('emoji', Emoji)


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('vue-chat', require('./components/chat/VueChat.vue'))


Vue.directive('click-outside', ClickOutside)

const scrollToBottom = el => {
    el.scrollTop = el.scrollHeight;
};
const emit = (vnode, name, data) => {
    var handlers = (vnode.data && vnode.data.on) ||
      (vnode.componentOptions && vnode.componentOptions.listeners);
  
    if (handlers && handlers[name]) {
      handlers[name].fns(data);
    }
}
const vChatScroll = {
    bind: (el, binding, vnode) => {
        let timeout;
        let scrolled = false;
        let scrolledTop = false;
        let scrollHeight = el.scrollHeight
        let firstChild = null
        let noScroll = false
        el.addEventListener('scroll', e => {
            if (timeout) window.clearTimeout(timeout);
            timeout = window.setTimeout(function() {
                scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
                console.log(el.scrollTop)
                if(el.scrollTop < 50){
                    firstChild = el.querySelector('.chat-message').id
                    emit(vnode, 'scroll-top', "123")
                    scrolledTop = true
                    noScroll = false
                } else if(el.scrollTop == (el.scrollHeight - el.offsetHeight) ) {
                    scrolledTop = false
                    noScroll = false
                } else {
                    scrolledTop = false
                    noScroll = true
                }
            }, 200);
        });

        (new MutationObserver(e => {
            let config = binding.value || {};
            let scrollTo = null
            scrollHeight = el.scrollHeight
            if(firstChild) {
                scrollTo = document.getElementById(firstChild).offsetTop - 100
            }
            console.log(`scrollTo: ${scrollTo}`)
            console.log(`scrolled: ${scrolled}`)
            let pause = config.always === false && scrolled;
            if(scrolledTop) {
                
                el.scrollTop = scrollTo;
            } 
            if (pause || e[e.length - 1].addedNodes.length != 1) return;
            console.log(pause, scrolled)
            if(!noScroll && !scrolledTop ) {
                scrollToBottom(el);
            } 
        })).observe(el, {childList: true, subtree: true});
    },
    inserted: scrollToBottom
};

Vue.directive('chat-scroll', vChatScroll);

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key)))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
if (!Object.prototype.forEach) {
	Object.defineProperty(Object.prototype, 'forEach', {
		value: function (callback, thisArg) {
			if (this == null) {
				throw new TypeError('Not an object');
			}
			thisArg = thisArg || window;
			for (var key in this) {
				if (this.hasOwnProperty(key)) {
					callback.call(thisArg, this[key], key, this);
				}
			}
		}
	});
}
function appendHtml(el, str) {
    var div = document.createElement('div');
    div.innerHTML = str;
    while (div.children.length > 0) {
        return  el.appendChild(div.children[0]);
    }
    
}

window.appendHtml = appendHtml

