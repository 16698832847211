import cookies from 'js-cookie'

const locale  = (navigator.language || navigator.userLanguage).split('-')[0] 
const locales = {'es' : 'ES', 'en' : 'EN'}

// Mutation Types
export const SET_LOCALE = 'SET_LOCALE'

// State
export const state = {
    locale: cookies.get('pavolar_locale') || locale,
    locales: locales
}

// Mutations
export const mutations = {
    [SET_LOCALE] (state, { locale }) {
        state.locale = locale

        // set default moment locale
        moment.updateLocale(locale)
    }
}

// actions
export const actions = {
    setLocale ({ commit }, { locale }) {
        commit(SET_LOCALE, { locale })
        cookies.set('pavolar_locale', locale, { expires: 365 })
    }
}

// Getters
export const getters = {
    locale:  state => state.locale,
    locales: state => state.locales
}

// Export the module
// export default {
//     state,
//     mutations,
//     actions,
//     getters
// }