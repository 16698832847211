<template>
	<div class="container mt-1 px-4">
        <div class="user-profile-header p-4 d-flex">
            <div class="user-profile-basic">
                <div class="user-profile-photo">
                    <img :src="user.photo || '/img/none.png'" onerror="this.src='/img/none.png';" alt="">
                </div>
                <div>
                    <h2 class="title text-center mt-2 mb-0"> {{ user.first_name }} {{ user.last_name }}</h2>
                    <p class="text-muted text-center mb-0">@{{ user.username }}</p>
                </div>
            </div>
            <div class="user-profile-details px-4">
               <h2 class="title mb-2">Mas sobre mi</h2>
               <div class="d-flex flex-wrap">
                    <div class="col-4 px-0 py-2">
                        <p class="text-muted mb-0"><span class="ico-calendar-time icon"></span> Se Unio a Pavolar el 15 nov 2018</p>
                    </div>
                    <div class="col-4 px-0 py-2">
                        <p class="text-muted mb-0"><span class="ico-location-point icon"></span> El Salvador SST</p>
                    </div>
                    <div class="col-4 px-0 py-2">
                        <p class="text-muted mb-0"><span class="ico-calendar-time icon"></span> Se Unio a Pavolar el 15 nov 2018</p>
                    </div>
                    <div class="col-4 px-0 py-2">
                        <p class="text-muted mb-0"><span class="ico-calendar-time icon"></span> Se Unio a Pavolar el 15 nov 2018</p>
                    </div>
               </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-auto">
                <div class="card py-3 px-5">
                    <div class="d-flex justify-content-center">
                        <div class="image-container">
                            <div class="picture-content mx-auto rounded-circle border shadow-sm">
                                <img class="user-picture mb-2" :src="user.photo || '/img/none.png'" onerror="this.src='/img/none.png';">
                            </div>
                        </div>
                    </div>
                    <h2 class="title mt-2 mb-0"> {{ user.first_name }} {{ user.last_name }}</h2>
                    <p class="text-muted text-center mb-0">@{{ user.username }}</p>
                    <p class="text-center">
                        <span class="fi ico-email-k"></span> {{ user.email }}
                    </p>
                    <h6 class="sub-title text-center">Roles</h6>
                    <p class="text-center">
                        <span v-for="item in user.roles" :key="item.id" class="tag">
                            {{ item.title }}
                        </span>
                    </p>
                    <div class="text-center">
                        <button v-can:manage class="btn btn-danger align-self-end" type="button" @click.prevent="disableAccount">{{ user.active == 1 ? 'Inhabilitar Cuenta' : 'Habilitar Cuenta'}}</button>
                    </div>
                </div>
            </div>
        </div>
	</div>
   
</template>

<script>
	'use strict'
	import userImage from '~/components/users/userImage'
    import tokenInput from '~/components/tokenInput'
    import { mapGetters } from 'vuex'
	export default {
		middleware:'auth',
		layout: 'dashboard',
		components: {
			'user-image': userImage,
            'token-input': tokenInput
		},
		metaInfo () {
            return { title: "User" }
        },
		data: () => ({
            errors: {},
            password: null,
            passwordConfirmation: null,
            user: {},
            roles: []
        }),
        computed: mapGetters({
            userAuth : 'auth/user',
        }),
        created() {  
            this.getRolesList()
            if(!('user' in this.$route.params)) {
            	this.getUserData()
            	return
            }
            this.user = this.$route.params.user;
        },
      
        watch:{
            $route (to, from){
                this.getUserData()
            }
        },
        methods: {
        	update: function () {
                //JSON.parse('uno-do')
                let params = this.user
                params.password = this.password
                params.password_confirmation = this.passwordConfirmation
        		axios
        			.post('/admin/user/update', params)
        			.then(response => {
        				if(response.data.success === false){
                            //console.log(response.data)
                            const { error, message } = response.data
                            this.errors = (typeof error === 'string') ? [error] : (typeof message !== 'undefined') ? [message] : error
                            return
                        }
                        this.errors = {}
                        this.$router.replace("/users/"+response.data.user.username)
                        vm.$snotify.success('Se actualizó la información del usuario')
                        //console.log(this.$router.params)
                        //this.$route.params.username = response.data.username
        			})
        		.catch(error => { })
        	},
        	getUserData: function () {
        		axios
        			.get('/admin/user/find/'+this.$route.params.username)
        			.then(response => { 

        				let { user } = response.data
        				if(!user){
        					this.$router.push({name: 'users-index'})
        					return
                        }
        				this.user = user
        			})
        		.catch(error => { })
        	},
            getRolesList: function () {
                axios
                    .get('/admin/permission/roles/all')
                    .then(response => {
                        if(!response.data.success) return
                        this.roles = response.data.roles
                    })
            },
            disableAccount: function () {
                axios
                    .post('/admin/user/state', {
                        id: this.user.id,
                        state: !(this.user.active == 1)
                    })
                .then(response => {
                    if(!response.data.success) {
                        alert('something went wrong')
                        return;
                    }
                    this.user.active = Number(!(this.user.active == 1))
                    vm.$snotify.simple( this.user.active ? 'Cuenta de usuario habilitada.' : 'Cuenta de usuario inhabilitada');
                })
            },
            goBack: function () {
                this.$router.go(-1)
            },
            // removeRole: function (item) {
            //     axios
            //         .post('/admin/permission/roles/revoke', { id: item.id })
            //         .then(response => { })
            // },
            // addRole: function (item) {
            //     axios
            //         .post('/admin/permission/roles/add', { role_id: item.role_id, user_id: this.user.id })
            //         .then(response => {
            //             const index = this.user.roles.findIndex(element =>  element.role_id === item.role_id)

            //             this.user.roles[index].assigned_role_id = response.data.assigned_role.assigned_role_id
            //         })
            // }
        }
	}
</script>

<style scoped>
    .tag{
        display: inline-block;
        border-radius: 30px;
        background-color: #eeeeee;
        color: #3e85f7;
        margin: 0 5px;
        padding: 0 1rem;
    }
    .sub-title{
        color: #a7a7a7;
        font-size: 1.1em;
        text-transform: uppercase;
    }
    .title{
        font-size: 1.1em;
        color: #333;
        font-weight: 700;
    }

	.subtitle {
        font-size: 0.9em;
        color: #a7a7a7;
        margin: 0.2em;
    }

	.font-weight-500,
    label {
    	font-weight: 500
    }
.picture-content {
		position: relative;
		overflow: hidden;
	}
	.picture-content,
	.user-picture{
		width: 120px;
		height: 120px;
	}

	.picture-content:hover > .btn-change-user-picture{
		opacity: 1;
		top: 60px;
	}

	.btn-change-user-picture:focus {outline:0;}
	.btn-change-user-picture{
		opacity: 0;
		background-color: rgba(0,0,0,0.5);
		transition: 0.3s;
		top: 120px;
		width: 120px;
		height: 120px;	
	}
	.btn-transparent {
		outline: none;
		color: #fff;
		font-size: 2em;	
		cursor: pointer;		
	}

	.btn-transparent:hover {
		background: transparent;
	}
</style>