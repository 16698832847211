<template>
    <div class="mx-3">
        <div class="row">
            <div class="col-12 py-3">
                <h5 class="font-weight-bold mb-0">Panel de Aplicaciones</h5>
                <p class="text-muted">Administracion de Apps para acceso a admin</p>
                <div class="d-flex flex-wrap" v-if="apps">
                    <div class="card border-light mb-3 ml-1 mr-1 shadow-sm app-card" v-for="app in apps" :key="app.id">
                        <div class="app-body position-relative">
                            <button v-can:manage v-if="app.is_current" class="btn-edit btn btn-transparent btn-sm" data-toggle="modal" data-target="#newAppModal" @click="editApp(app.id)">
                                <span class="icon-pencil"></span>
                            </button>
                            <div class="d-flex w-100">
                                <div class="app-icon rounded-circle mr-2">{{ app.name.substring(0, 2).toUpperCase() }}</div>
                                <div class="app-title">
                                    <p class="mb-0 font-weight-bold">
                                        <span v-if="app.environment != 'PRODUCTION'" grid="12" class="text-warning icon-alert-warning"></span>
                                        <span v-else grid="12" class="text-success icon-circle-check"></span>
                                        {{ app.name }} 
                                    </p>
                                    <p class="mb-0">
                                       <span class="font-weight-bold" :class="app.is_active == 1 ? 'text-success' : 'text-danger'">{{ app.is_active == 1 ? 'En Línea' : 'Desconectado' }}</span>
                                       <span class="small text-black"> | </span>
                                       <span class="small text-muted">{{app.environment}}</span>
                                    </p>
                                    <p class="mb-0 small" :class="!app.is_current ? 'text-danger' : ''">
                                        <span><span class="icon-clock" grid="16"></span> {{ app.is_current ? 'Expiración' : 'Expirada' }}:</span>
                                        {{ app.expiration == null ? 'Nunca' : app.expiration }}
                                    </p>
                                </div>
                            </div>
                            <div class="app-key d-flex justify-content-between align-items-center mt-3">
                                <span :id="`key-${app.id}`" v-copy>
                                    {{ app.key }}
                                </span>
                                <button class="btn btn-transparent px-2 py-1" v-copy="`key-${app.id}`">
                                    <span class="icon-copy"></span>
                                </button>
                            </div>
                            <div class="app-footer d-flex mt-2">
                                <toggle-button 
                                    v-can:update
                                    :value="app.active" 
                                    :class="'mb-0'"
                                    color="#38c172" 
                                    :sync="true"
                                    :labels="{checked: 'Activo', unchecked: 'Inactivo'}"
                                    :width="75"
                                    @change="powerOff(app.id)"
                                    v-model="app.active" />
                                <div class="px-2" v-if="!app.is_current">
                                    <p class="mb-0 small  font-weight-bold"> <button class="btn-reload" data-toggle="modal" data-target="#newAppModal" @click="renovateApp(app.id)">Renovar <span class="icon-refresh"></span></button> </p>
                                </div>
                            </div>
                            <p class="mt-2 mb-0 small">
                                {{ app.description }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-dark" v-if="apps.length == 0">
                    <span class="icon-emoji-sad mr-2" grid="24"></span> No se encuentras datos disponibles...
                </div>
            </div>
        </div>

        <button v-can:create class="btn btn-primary center-content position-absolute rounded-circle floating-button shadow" data-toggle="modal" data-target="#newAppModal">
            <span class="icon-add"></span>
        </button>

        <div class="modal fade" id="newAppModal" tabindex="-1" role="dialog" aria-labelledby="newAppModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="newAppModalLabel">{{ this.form.defaultAction === 'update' ? 'Editar App' : this.form.defaultAction === 'renovate' ? 'Renovar App' : 'Crear nueva App' }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form autocomplete="off" id="appForm" class="needs-validation" novalidate method="post">
                            <div class="form-row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Nombre</label>
                                        <input type="text" name="name" class="form-control" v-model="form.name">
                                    </div>
                                </div>

                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="environment">Entorno</label>
                                        <select id="environment" class="form-control" v-model="form.environment">
                                            <option value="PRODUCTION">Production</option>
                                            <option value="STAGING">Staging</option>
                                            <option value="DEVELOPMENT">Development</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="description">Descripción</label>
                                        <textarea name="description" class="form-control" cols="10" rows="2" v-model="form.description"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="expiration">Fecha de Expiración</label>
                                        <div class="d-flex">
                                            <vue-datepicker id="expiration" placeholder="Sin Expiración..." v-model="form.expiration" :minDate="startDate" :disabled="form.lifetime"></vue-datepicker>
                                            <button class="btn center-content ml-2 mb-0" :class="{'btn-primary': !form.lifetime, 'btn-secondary': form.lifetime}"  @click.prevent="resetExpiration">
                                                <span class="icon-loop"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="createApp">
                            {{ this.form.defaultAction === 'update' ? 'Editar' : 'Guardar' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import ToggleButton from 'vue-js-toggle-button/src/Button'

    export default {
        components: {
            'toggle-button': ToggleButton,
        },
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Apps" }
        },
        data(){
            return {
                form : new WebForm('#appForm', {
                    id : '',
                    name : '',
                    environment : 'PRODUCTION',
                    description : '',
                    expiration : '',
                    active : true,
                    lifetime : false,
                    hosts_allowed: []
                }),
                apps: [],
                new_host: null
            }
        },
        async asyncData(app, route, store, redirect){
            
            // await axios.get('/admin/apps').then(response => {
            //     console.log(response)
            // })

            let response = await axios.get('/admin/apps')
            let data     = response.data || []

            data.forEach(app => {
                app.active     = app.active     == 1
                app.is_active  = app.is_active  == 1
                app.is_current = app.is_current == 1
            })

            return {
                apps: data
            }
        },
        computed: {
            startDate () {
                let date = new Date()
                date.setDate(date.getDate() + 1);
                return date
            }
        },
        methods: {
            resetExpiration () {
                this.form.expiration = ''
                this.form.lifetime = !this.form.lifetime
                if(!this.form.lifetime) {
                    this.form.expiration = ''
                    document.getElementById('expiration').focus()
                }
            },
            powerOff (id) {

                let vm = this
                let app = this.apps.find(a => a.id == id)
                if(!app)
                    return

                axios.post('/admin/apps/poweroff', {
                    id: app.id,
                    active: app.active
                }).then(response => {
                    if(!response.data.success) {
                        vm.$snotify.error(`Error al actualizar`)
                        return
                    }

                    let app = this.apps.find(a => {
                        return a.id == response.data.app.id
                    })

                    app.active = response.data.app.active == 1
                    app.is_active = response.data.app.active == 1 
                    let snotify_message = `${app.name} esta ${app.active ? 'Online' : 'Offline'}`

                    if(app.active) {
                        vm.$snotify.success(snotify_message)
                        return
                    }
                    vm.$snotify.warning(snotify_message)
                })
            },
            editApp (id) {
                let app = this.apps.find(a => a.id == id)

                if(!app)
                    return

                this.form.fill(app)
                this.form.lifetime = (this.form.expiration == null || this.form.expiration == '');
                this.form.defaultAction = 'update'
            },
            renovateApp (id) {
                let app = this.apps.find(a => a.id == id)

                if(!app)
                    return
                
                this.form.fill(app)
                this.form.expiration = ''
                this.form.defaultAction = 'renovate'
            },
            getApps(){
                let vm = this
                axios.get('/admin/apps').then(response => {
                    vm.apps = response.data
                    vm.apps.forEach(app => {
                        app.active = app.active == 1 
                        app.is_active = app.is_active == 1 
                        app.is_current = app.is_current == 1 
                    });
                })
            },
            createApp(){
                let vm = this
                this.form.submit().then((response) => {
                    let snotify_message = vm.form.defaultAction === 'update'  ? `${vm.form.name} editado` : vm.form.defaultAction === 'renovate' ? `${vm.form.name} renovado` : `${vm.form.name} creado`
                    
                    vm.$snotify.simple(snotify_message)
                    let $modal = $('#newAppModal')
                    $modal.modal('hide')
                    vm.getApps()
                })
            },
            validateOrigin(origin) {
                const hostregexp = /^(http[s]?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
                const ipregexp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                return hostregexp.test(origin) || ipregexp.test(origin)
            },
            addHost() {
                if(!this.new_host) return

                if(!this.validateOrigin(this.new_host))
                {
                    this.$snotify.warning("Invalid Host/IP")
                    this.new_host = null
                    return
                }

                this.form.hosts_allowed.push(this.new_host)
                this.new_host = null
            },
            removeHost(index) {
                this.form.hosts_allowed.splice(index, 1)
            }
        },
        created() {
            // this.getApps()
            this.form.setActions([
                {
                    name: 'create',
                    url: '/admin/apps/new',
                    default: true
                },
                {
                    name: 'update',
                    url: '/admin/apps/edit',
                },
                {
                    name: 'renovate',
                    url: '/admin/apps/renovate',
                }
            ])
        },
        mounted(){
            let vm = this
            $('#newAppModal').on('hidden.bs.modal', function () {
                vm.form.clear()
                vm.form.reset()
            })
        }
    }
    

</script>

<style scoped>
    .btn-edit{
        border: 1px solid #d3dbe0;
        padding: 0.15rem 0.35rem;
        line-height: 1;
        position: absolute;
        top: 1rem;
        right: 0.5rem;
    }
    .btn-reload{
        height: 22px;
        background-color: #2196F3;
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        line-height: 22px;
        display: inline-block;
    }
    .app-icon {
        width: 65px;
        height: 65px;
        background: #2196F3;
        color: #fff;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .app-card{
        /* width: 325px; */
        width: 370px;
    }
    .app-body{
        padding: 1rem 0.5rem;
    }
    .app-key {
        margin: 3px;
        background: #b8e9ff;
        color: #525fab;
        border-radius: 3px;
        padding: 0.5rem;
        font-size: 13px;
        font-weight: bold;
    }
    
    .floating-button {
        width: 55px;
        height: 55px;
        bottom: 20px;
        right: 20px;
        font-size: 1rem;
    }

    .accepted-hosts .host {
        display: block;
    }
    .outer {
        display: block;
        position: relative;
        padding: 1rem;
        background-color: #f3f3f8;
        border-radius: 3px;
    }
    .host {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .host .description {
        color: #f66D9b;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
    .remove {
        cursor: pointer;
    }
</style>