<template>
    <div class="container">
        <img src="/img/logo-white.png" alt="pavolar.com" class="logo mt-1 mt-md-3 mb-2 mb-md-5">
        <div class="bg-curve">
            <svg xmlns="http://www.w3.org/2000/svg" class="curve" viewBox="0 0 1440 68" enable-background="new 0 0 1440 68">
                <path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill="#F4F5F7" transform="translate(0-1977)"></path>
            </svg>
        </div>
        <div class="d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start flex-wrap login-container">
            <div class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white">
                <h5 class="font-weight-bold mb-1 mt-3">Iniciar</h5>
                <p class="text-muted">Ingrese sus credenciales</p>

                <form id="loginForm" autocomplete="off" @submit.prevent="login" novalidate method="post">
                    <img v-bind:src="photo || '/img/default.png'" alt="user" class="user-avatar">
                    <div class="form-group mt-4">
                        <label for="login">Nombre de Usuario o E-mail</label>
                        <input type="text" id="login" class="form-control bg-white" v-model="form.login">
                    </div>
                    <div class="form-group mt-4">
                        <label for="password">Contraseña</label>

                        <div class="position-relative">
                            <input :type="!form.visible ? 'password' : 'text'" id="password" class="form-control" v-model="form.password" >
                            <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="form.visible = !form.visible">
                                <span :class="form.visible ? 'icon-view-off' : 'icon-view'"></span>
                            </button>
                        </div>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="remember" v-model="form.remember">
                        <label class="form-check-label" for="remember">Recordar</label>
                    </div>
                    <div class="form-group d-flex justify-content-between align-items-center mt-5">
                        <v-button class="center-content" :type="'success'" :loading="form.processing">
                            Ingresar
                            <span class="icon-login-3 ml-2"></span>
                        </v-button>
                        <router-link :to="{name: 'auth-reset-password'}">
                            Olvido su contraseña?
                        </router-link>
                    </div>
                </form>
            </div>

            <div class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                <h4>Bienvenido a Pavolar Admin</h4>
                <p>Esta sección esta dirigida única y exclusivamente a personal de administración,
                si usted no posee permisos o consentimiento de acceso por favor no intente ingresar a la plataforma, gracias.
                <br>Desea salir de esta página? <u><a class="text-white" href="https://www.pavolar.com/">Salir de aquí.</a></u></p>
            </div>
        </div>
        <p class="text-muted mb-0">© Pavolar | Todos los derechos reservados <span v-once>{{ year }}</span></p>
    </div>
</template>

<script>

    import store from '~/store'
    import vButton from '~/components/button'

    export default {
        middleware: 'guest',
        components: {
            vButton,
        },
        metaInfo () {
            return { title: "Login" }
        },
        computed: {
            userLogin() {
                return this.form.login;
            }
        },
        data(){
            return {
                routes: [],
                form: new WebForm("#loginForm", {
                    login    : '',
                    password : '',
                    remember : false,
                    visible  : false
                }),
                photo: '/img/none.png',
                loading: false,
                year: (new Date).getFullYear()
            };
        },
        watch: {
            userLogin: _.debounce(function () {
                this.getPhoto()
            }, 1000),
        },
        methods: {
             togglePassword: function ( e ) {
                $('.toggle-password').html(!this.form.visible ? '<i class="far fa-eye-slash"></i>' : '<i class="far fa-eye"></i>')
                $('#password').attr('type', this.form.visible ? 'password': 'text')
                this.form.visible = !this.form.visible
            },
            getPhoto(){
                axios.get('/admin/account/user/photo/'+(this.form.login || "unknown"))
                .then(response => {
                    this.photo = response.data || '/img/none.png';
                }).catch(error => {
                    //error
                });
            },
            login(){

                this.form.post('/admin/account/login').then(response => {

                    let data = response.data

                    // Almacena el token y el usuario
                    this.$store.dispatch('auth/login', {
                        user:     data.user,
                        remember: data.remember
                    })

                    localStorage.setItem('rememberMe', data.remember ? 1 : 0)
                    this.$router.push({ name: 'index' })
                })
            }
        },
        mounted() {
            let rememberMe      = localStorage.getItem('rememberMe') || 0
            this.form.remember  = rememberMe != 0
        },
    }
</script>

<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
</style>
