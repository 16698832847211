<template>
<div class="row justify-content-center mx-0">
        <div class="col col-12 col-lg-12 mt-3 mt-lg-0 py-2 section">
            <div class="">
                <div class="row">
                    <div class="col col-6">
                        <h5 class="font-weight-bold mb-0">Contrato de Aerolinea/s:</h5>
                    </div>                    
                    <div class="col-6 d-flex flex-row-reverse">

                        <button class="btn btn-primary mr-0"  @click.prevent='goBack()'>
                            <span class="icon-left-arrow mr-2"></span> Volver
                        </button>
                    </div>                    
                </div>
                <div class="row">
                    <div class="col col-6">
                        <button type="button"  class="btn btn-primary ml-2" data-toggle="modal" data-target="#rulemodal">
                            + Regla
                        </button>

                        <button type="button"  class="btn btn-primary ml-2" data-toggle="modal" data-target="#regionmodal">
                            + Region
                        </button>

                        <button type="button"  class="btn btn-primary mr-0" data-toggle="modal" data-target="#testcomision">
                                Consultar Comision
                        </button>
                        
                    </div>
                    <div class="col col-6">
                        <h5 class="font-weight-bold mb-0">Aerolineas , Rutas y clase de servicio no contemplado en alguno de estos contratos sera tomado como 0%</h5>
                    </div>
                </div>
            </div>
            <!-- Modal Rules -->
            <div class="modal fade" id="rulemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                            <form id="formRule" autocomplete="off" @submit.prevent="saveRule" novalidate>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Añadir Regla de Comisiones</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="form-check ml-3">
                                        <input type="checkbox" id="rule_military" v-model="formrule.aplydate" class="form-check-input" @change="mark()">
                                        <label for="rule_military">RESTRINCCION DE FECHAS DE VIAJE</label>
                                    </div>
                                    <div class="form-check ml-3">
                                        <input type="checkbox" id="bidireccional" v-model="formrule.bidireccional" class="form-check-input">
                                        <label for="bidireccional">REGLA BIDIRECCIONAL</label>
                                    </div>
                                </div>
                                <div  v-if="formrule.aplydate">
                                    <div class="row"  v-for="(item, index) in formrule.dateRanges" :key="item" >
                                            <div class="col col-5">
                                                <div class="form-group">
                                                    <label for="start_date">Inicio de viaje </label>
                                                    <vue-datepicker name="start_date" v-model="item.start_date" class="form-control daterange"></vue-datepicker>
                                                </div>
                                            </div>
                                            <div class="col col-5">
                                                <div class="form-group">
                                                    <label for="end_date">Fin Viaje </label>
                                                    <vue-datepicker name="end_date" v-model="item.end_date" class="form-control daterange"></vue-datepicker>
                                                </div>
                                            </div>
                                            <div class="col-2" v-if="index==0">
                                                <button class="btn btn-success" v-on:click.stop.prevent="addRangeDate">+</button>
                                            </div>
                                            <div class="col-2" v-else>
                                                <button class="btn btn-danger" v-on:click.stop.prevent="removeRangeDate(index)">-</button>
                                            </div>
                                    </div>
                                </div>
                                <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="origins">Inicio de viaje </label>
                                                <v-select id="origins" placeholder="Buscar Origen" v-model="formrule.origins" :options="optionList" label="text" :filterable="true"  @search="onsearchPoint"  multiple>
                                                    <template slot="no-options">Buscar Origen</template>
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.text }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        {{ option.iata }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <!-- <v-select id="primaryairline" placeholder="Buscar Aerolinea..." v-model="form.airlines" :options="airlines" label="name" :filterable="false" @search="onSearchAirlines"> -->
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="destinations">Destino de viaje </label>
                                                <v-select id="destinations" placeholder="Buscar Origen" v-model="formrule.destinations" :options="optionList" label="text" :filterable="true" @search="onsearchPoint" multiple>
                                                    <template slot="no-options">Buscar Destino</template>
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.text }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        {{ option.iata }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>

                                </div>
                                <div class="row">
                                         <div class="col-6">
                                            <div class="form-group">
                                                <label for="classservice" class="d-flex justify-content-between">Clase de Servicio</label>
                                                <v-select id="classservice" placeholder="Seleccionar Clase de Servicio..." v-model="formrule.classservice" :options="serviceClass" label="text" :filterable="true" multiple>
                                                    <template slot="no-options">Seleccionar Clase de Servicio...</template>
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.text }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        {{ option.text }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="col col-6">
                                            <div class="form-group">
                                                <label for="comission_percent" class="d-flex justify-content-between">Porcentaje de Comision</label>
                                                <select name="comission_percent" id="comission_percent" v-model="formrule.commision" class="form-control">
                                                    <option  v-for="n  in 20 " :key="n"  :value="n">{{ n }}</option>
                                                </select>
                                            </div>
                                        </div>
                                </div>
                               <div class="row">
                                    <div class="form-check ml-3">
                                        <input type="checkbox" id="clear_form_rule" v-model="formrule.clear_form" class="form-check-input">
                                        <label for="clear_form_rule">LIMPIAR FORMULARIO AL GUARDAR </label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </div>
            <!-- Fin del Modal -->
<!-- Modal Rules -->
            <div class="modal fade" id="contractModal" tabindex="-1" role="dialog" aria-labelledby="contractModalTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                            <form id="formContracts" autocomplete="off" @submit.prevent="saveContract" novalidate>
                            <div class="modal-header">
                                <h5 class="modal-title" id="contractModalTitle">Crear Nuevo Contrato</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="col-md-12 py-3 section">
                                    <h5 class="font-weight-bold mb-0">{{ form.title }}</h5>
                                    <p class="text-muted">Esto creará o editará un Contrato para una Aerolinea</p>
                                        <div class="form-row" >
                                            <div class="col-12 col-lg-6">
                                                <div class="form-group">
                                                    <label for="startDate">Fecha Inicio</label>
                                                    <vue-datepicker v-model="form.start_date" class="form-control"></vue-datepicker>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <div class="form-group">
                                                    <label for="endDate">Fecha Fin</label>
                                                    <vue-datepicker v-model="form.end_date" class="form-control"></vue-datepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="primaryairline">Aerolinea</label>
                                                    <airline-input id="primaryairline" v-model="form.airlines"></airline-input>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="airlineassoc">EMISION DE OTRAS PLACAS</label>
                                                    <airline-input id="airlineassoc" v-model="form.otherairlines" :multiple="true"></airline-input>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input type="checkbox" id="infants" v-model="form.infants" class="form-check-input">
                                                    <label for="infants">INFANTES</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input type="checkbox" id="military" v-model="form.military" class="form-check-input">
                                                    <label for="military">MILITARES</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input type="checkbox" id="student" v-model="form.student" class="form-check-input">
                                                    <label for="student">ESTUDIANTES</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input type="checkbox" id="roundtrip" v-model="form.roundtrip" class="form-check-input">
                                                    <label for="roundtrip">ROUND TRIP</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input type="checkbox" id="oneway" v-model="form.oneway" class="form-check-input">
                                                    <label for="oneway">ONE WAY</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input type="checkbox" id="multicity" v-model="form.multicity" class="form-check-input">
                                                    <label for="multicity">MULTICITY</label>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </div>
            <!-- Fin del Modal -->
            <!-- Modal Test Helper -->
            <div class="modal fade" id="reponseHelper" tabindex="-1" role="dialog" aria-labelledby="reponseHelp" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="reponseHelp">Response Helper</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    Response : {{ responseHelper }}
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
            </div>
            <!-- Fin del Modal -->

             <!-- Modal Regions -->
            <div class="modal fade" id="regionmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                            <form id="formRegions" autocomplete="off" @submit.prevent="saveRegion" novalidate>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Regiones</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">

                                        <div class="col col-6">
                                            <div class="form-group">
                                                <label for="regionname">Nombre Region</label>
                                                <input id="regionname" type="text" v-model="formregion.name" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="countries">Paises</label>
                                                <v-select id="countries" placeholder="Buscar Pais" v-model="formregion.countries" :options="countryList" label="text" :filterable="true"  multiple>
                                                    <template slot="no-options">Buscar Pais...</template>
                                                    <template slot="option" slot-scope="option">
                                                         {{ option.text }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        {{ option.text }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>

                                </div>

                                <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="exceptions" class="d-flex justify-content-between">Excepciones de Aeropuertos en region: </label>
                                                <v-select id="exceptions" placeholder="Buscar Destino..." v-model="formregion.airexceptions" :options="airports" label="name" :filterable="false" @search="onSearchAirport" multiple>
                                                    <template slot="no-options">Buscar Aeropuertos...</template>
                                                    <template slot="option" slot-scope="option">
                                                        ({{ option.iata }}) {{ option.name }}
                                                    </template>
                                                    <template slot="selected-option" slot-scope="option">
                                                        {{ option.iata }}
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </div>
            <!-- Fin del Modal -->
            <!-- Modal #testcomision -->
            <!-- Modal Regions -->
            <div class="modal fade" id="testcomision" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                            <form id="formTest" autocomplete="off" @submit.prevent="testCommision" novalidate>
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Calcular Comision segun ruta</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-row">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label for="startDate">Fecha Inicio</label>
                                            <vue-datepicker name="departuredate" id="departuredate"  v-model="formtest.departuredate" class="form-control" ></vue-datepicker>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label for="endDate">Fecha Fin</label>
                                            <vue-datepicker name="enddate" id="enddate"  v-model="formtest.enddate" class="form-control" ></vue-datepicker>
                                        </div>
                                    </div>
                                 </div>


                                 <div class="form-row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="primaryairline">Aerolinea</label>
                                            <airline-input id="primaryairline" v-model="formtest.primaryairline"></airline-input>
                                        </div>
                                    </div>
                                </div>

                                 <div class="form-row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="parthnerairlines">Aerolinea Conexion/Operada</label>
                                            <airline-input id="parthnerairlines" v-model="formtest.parthnerairlines"></airline-input>
                                        </div>
                                    </div>
                                </div>

                               <div class="form-row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="departurecode" class="d-flex justify-content-between">Origen </label>
                                            <v-select id="departurecode" placeholder="Buscar Origen..." v-model="formtest.departurecode" :options="airports" label="name" :filterable="false" @search="onSearchAirport">
                                                <template slot="no-options">Buscar Aeropuertos...</template>
                                                <template slot="option" slot-scope="option">
                                                    ({{ option.iata }}) {{ option.name }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    {{ option.iata }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="endpointcode" class="d-flex justify-content-between">Destino </label>
                                            <v-select id="endpointcode" placeholder="Buscar Destino..." v-model="formtest.endpointcode" :options="airports" label="name" :filterable="false" @search="onSearchAirport">
                                                <template slot="no-options">Buscar Aeropuertos...</template>
                                                <template slot="option" slot-scope="option">
                                                    ({{ option.iata }}) {{ option.name }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    {{ option.iata }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-row">
                                    <div class="col-6">
                                        <div class="form-group">
                                           <label for="flight_type">TIPO DE VUELO</label>
                                            <select id="flight_type" name="flight_type" class="form-control" v-model="formtest.flight_type">
                                                <option value="ROUNDTRIP">ROUND TRIP</option>
                                                <option value="ONEWAY">ONE WAY</option>
                                                <option value="MULTICITY">MULTICITY</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="serviceClass" class="d-flex justify-content-between">Clase de Servicio
                                            <!-- <button class="btn btn-primary" @click.prevent="selectAllClass">Select All</button> -->
                                            </label>
                                            <v-select id="serviceClass" placeholder="Seleccionar Clase de Servicio..." v-model="formtest.serviceClass" :options="serviceClass" taggable label="text" :filterable="false" multiple>
                                                <template slot="no-options">Seleccionar Clase de Servicio...</template>
                                                <template slot="option" slot-scope="option">
                                                    {{ option.text }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    {{ option.text }}
                                                </template>

                                            </v-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-4">
                                        <div class="form-check">
                                            <input type="checkbox" id="test_infants" v-model="formtest.infants" class="form-check-input">
                                            <label for="test_infants">INFANTES</label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-check">
                                            <input type="checkbox" id="test_military" v-model="formtest.military" class="form-check-input">
                                            <label for="test_military">MILITARES</label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-check">
                                            <input type="checkbox" id="test_student" v-model="formtest.student" class="form-check-input">
                                            <label for="test_student">ESTUDIANTES</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                        <p class="ml-auto p-2" style="font-size:18px;">Comision Aplica : <code id="commissionresult" class="highlighter-rouge"> ? % </code>.</p>
                                </div>

                                 <div class="form-row">
                                        <p>Resultado : <code  class="highlighter-rouge" style="font-size:10px;"> {{ resultTestCommission }} </code>.</p>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Calcular Comision</button>
                            </div>
                            </form>
                        </div>
                    </div>
            </div>
            <!-- Fin del Modal -->
            <div class="row border border-dark rounded m-2 px-0" >
                <div class="col-1 d-flex justify-content-start px-0">
                    <button v-if="selectedContract.sales_apply == 0" class="btn btn-warning m-0" @click="editContract(selectedContract)" data-toggle="modal" data-target="#contractModal"><span class="icon-pencil"></span></button>
                </div>
                <div class="col col-1">
                    <div class="row d-flex justify-content-center">
                        <img class="airline-logo" width="45" :src="`/img/airlines/${selectedContract.airlinecontract[0].iata || ''}.png`" onerror="this.src='/img/airlines/default.png';" >
                    </div>

                    <div class="row  row d-flex justify-content-center">
                        <h5 style="font-style:bold;">{{ selectedContract.airlinecontract[0].name || '' }}</h5>
                    </div>

                    <div class="row  row d-flex justify-content-center">
                        <b style="font-size:10px;" v-for="(airline,index) in selectedContract.airlinecontract" :key="index"> ({{ airline.name }}) <img width="15" class="airline-logo" :src="`/img/airlines/${airline.iata || ''}.png`" onerror="this.src='/img/airlines/default.png';" >
                        </b>
                    </div>
                </div>
                <div class="col col-5">
                    <div class="row-form">Periodo de Emisión de Boletos : <code style="font-size:17px;">{{ selectedContract.start_date || ''  }} - {{ selectedContract.end_date || ''  }}</code></div>
                    <div class="row-form">Aplica en Tipos de Viaje: <code style="font-size:17px;">
                        {{ selectedContract.apply_round_trip == true ? 'ROUND TRIP': '' }} -
                        {{ selectedContract.apply_one_way == true ? 'ONE WAY': '' }} -
                        {{ selectedContract.apply_multicity == true ? 'MULTICITY': '' }}</code>
                    </div>
                    <div class="row-form">Aerolineas /Placas permitidas (Conexión u Operación):
                        <code style="font-size:17px;">
                            <b v-for="(airline,index) in selectedContract.airlinecontract" :key="index" > ({{ airline.prefix_code }})  </b>
                        </code>
                    </div>
                    <div class="row-form">
                        Aplica Infantes: <code style="font-size:17px;">{{ selectedContract.apply_infant == true ? 'SI ': 'NO' }}</code>
                        - Estudiantes : <code style="font-size:17px;">{{ selectedContract.apply_student == true ? 'SI ': 'NO' }}</code>
                        - Militares : <code style="font-size:17px;">{{ selectedContract.apply_military == true ? 'SI ': 'NO' }}</code>
                    </div>
                </div>
                <div class="col-5">
                    REGIONES DEL CONTRATO
                     <div class="row-form" v-for="(region,index) in regionCountries" :key="index">
                        {{ region.name }}: <code style="font-size:17px;">{{ region.countries_short }}</code>
                    </div>
                </div>
            </div>
            <div>
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>
                                    ID
                                </th>
                                <th>
                                    ORIGEN
                                </th>
                                <th>
                                    DESTINO
                                </th>
                                <th>
                                    CLASE DE SERVICIO
                                </th>
                                <th>
                                    PERIODO DE VIAJE
                                </th>
                                <th>
                                    COMISION
                                </th>
                                <th>

                                </th>
                            </tr>
                    </thead>
                    <tbody v-if="rulescontract">
                        <tr v-for="item in rulescontract" :key="item.id">
                            <td scope="row">
                                {{ item.id || '' }}
                            </td>
                            <td>
                            {{ item.origin_airports || '' }} <p v-if="item.origin_airports && item.origin_countries"> , </p>
                            {{ item.origin_countries  || ''}}  <p v-if="item.origin_countries && item.origin_regions"> , </p>
                            {{ item.origin_regions  || ''}}
                            </td>
                            <td>
                                {{ item.destination_airports || '' }} <p v-if="item.destination_airports && item.destination_countries"> , </p>
                                {{ item.destination_countries || ''}} <p v-if="item.destination_countries && item.destination_regions"> , </p>
                                {{ item.destination_regions || ''}}
                            </td>
                            <td>
                                {{ item.service_class }}
                            </td>
                             <td>
                                {{item.init_range | moment('LL')}}  - {{item.finish_range | moment('LL')}}
                            </td>
                             <td>
                                {{ item.commision }}
                            </td>
                             <td>
                                <button v-can:delete.disable v-can:write.disable class="btn btn-transparent text-danger" @click="removeRule(item)">-<span class="icon-bin"></span></button>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <!-- <data-table
                        ref="commisionTable"
                        :ajax="{
                            url: '/admin/contracts/get/rulescontract/'+contractIdJs,
                        }"
                        v-on:xhr="dt.xhr"
                        :scrollY="'calc(100vh - 350px)'"
                        :serverSide="true"
                        :columns="dt.columns">
                    </data-table> -->
            </div>
        </div>
    </div>


</template>
<script>

    import draggable from "vuedraggable"
    import dataTable from '~/components/dataTable'
    import airlineInput from '~/components/airlineInput'

    var vueObj
    var contractIdJs = 0

    // window.nestable = require('nestable2');
    export default {
        middleware:'auth',
        layout: 'dashboard',
        components: {
            draggable,
            dataTable,
            airlineInput
        },
        computed:{
            airportList(){
                var list    =   []
                this.airports.forEach((e)=>{
                    list.push({
                        'text'  : '('+e.iata+') '+e.name,
                        'id'    : e.id
                    })
                })
                return list;
            },
            dataOptions(){
                var list    =   []
                this.airports.forEach((e)=>{
                    list.push({
                        'text'  : '('+e.iata+') '+e.name,
                        'iata'  : e.iata,
                        'id'    : 'A'+e.id,
                        'type'  : 'AIRPORT'
                    })
                })
                 this.regions.forEach((e)=>{
                    list.push({
                        'text'  : 'REGION :'+e.name,
                        'iata'  : e.alpha_code,
                        'id'    : 'R'+e.id,
                        'type'  : 'REGION'
                        })
                })
                this.countries.forEach((e)=>{
                    list.push({
                        'text'  : e.name,
                        'iata'  : e.name,
                        'id'    : 'C'+e.id,
                        'type'  : 'COUNTRY'
                        })
                })
                return list;
            },
            countryList(){
                var list    =   []
               this.countries.forEach((e)=>{
                            list.push({
                                'text'  : e.name,
                                'id'    : e.id
                            })
                })
                return list;
            },
        },
        metaInfo () {
            return { title: "Rules" }
        },
        data(){
            return {                
                responseHelper : [{}],
                airlines: [],
                favoriteAirlines: [],
                rulescontract:  [],
                resultTestCommission : [{}],
                optionListDefault : [],
                optionList : [],
                form: new WebForm("#formContracts", {
                    title : "Nuevo contrato",
                    saveContractId  :   0,
                    start_date      :   "2020-01-01",
                    end_date        :   "2020-12-01",
                    airlines        :   [],
                    otherairlines   :   [],
                    infants         : false,
                    military        : false,
                    student         : false,
                    roundtrip       : false,
                    oneway          : false,
                    multicity       : false
                }),
                formtest: new WebForm("#formTest", {
                    title : "Prueba de Comisiones",
                    departurecode    :   [],
                    endpointcode     :   [],
                    departuredate    :   "2020-01-01",
                    enddate          :   "2020-12-31",
                    primaryairline   :   [],
                    parthnerairlines :   [],
                    flight_type      : "ROUNDTRIP",
                    military        : false,
                    student         : false,
                    infants         : false,
                    roundtrip       : false,
                    oneway          : false,
                    multicity       : false,
                    serviceClass    : ''
                }),
                formregion: new WebForm("#formRegions", {
                    title : "Region Contract",
                    name : '',
                    countries : [],
                    airexceptions: [],
                    contractid: 0
                }),
                formrule: new WebForm("#formRule", {
                    title : "Admin Rules Contract",
                    origins : [],
                    destinations: [],
                    classservice:[],
                    dateRanges      :   [{
                        start_date  : "2020-02-01",
                        end_date    : "2020-06-01"}
                    ],
                    commision:0,
                    aplydate : false,
                    contractid: 0,
                    clear_form : false,
                    bidireccional : false
                }),
                contractid  : "",
                contracts   : [],
                selectedContract : {},
                regionContract: {},
                rules       : [],
                regions     : [],
                countries   : [],
                airports    : [],
                regionCountries: [],
                airportsexceptions: [],
                addisVisible : false,
                serviceClass : []
            }
        },
        async asyncData(app, route, store, redirect){

            let data = {
                period:           null,
                countries:        null,
                regions:          null,
                optionList:       null,
                regions:          null,
                countries:        null,
                airports:         null,
                // airlines:         null,
                // favoriteAirlines: null,
                fareClasses:      null,
                rulescontract:    null,
                selectedContract: null,
                serviceClass:     null,
                regionCountries:  null
            }

            //Valida que el parametro sea correcto
            let { id } = route.params
            if(_.isNull(id) || _.isUndefined(id) || !_.isNumeric(id)){
                redirect({ name: 'error-not-found' })
                return data
            }
            
            let contract    = await axios.get(`/admin/contracts/get/contract/${id}`)
            let rules       = await axios.get(`/admin/contracts/get/rulescontract/${id}`)
            let initdata    = await axios.get(`/admin/contracts/get/initdata/${id}`)
            let regions     = await axios.get(`/admin/contracts/getRegionsContract/${id}`)

            data.regionCountries = regions.data
            data.optionList      = initdata.data.all
            data.regions         = initdata.data.regions
            data.countries       = initdata.data.countries
            data.airports        = initdata.data.airports
            data.rulescontract   = rules.data
            data.selectedContract= contract.data
            data.serviceClass = _.range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1, 1).map(x => String.fromCharCode(x))

            return data
        },
        methods: {
                selectAllClass(){
                   this.formtest.serviceClass   = this.serviceClass
                },
                async deleteContract(){
                    let vm = this
                    let text = '¿Está seguro que desea eliminar este contrato de comision <span class="p-1 text-primary bg-light rounded font-weight-bold"> Perido # '+vm.selectedContract.id+' - '+vm.selectedContract.start_date+'-'+
                    vm.selectedContract.end_date+'</span>?'
                       $.confirm.delete(
                           'Eliminar contrato de comision',
                           text,
                           function() {
                                axios.post('/admin/contracts/delete', {
                                    id: vm.selectedContract.id
                                    }).then((response) => {
                                        vm.$snotify.success("Periodo Eliminado Exitosamente.")
                                        vm.getContracts()
                                    }).catch(() => {
                                        vm.$snotify.warning("No se pudo eliminar este Contrato")
                                    })
                                }
                            )
                },
                async saveContract(){
                   await this.form.post('/admin/contracts/savecontract').then(response => {
                        this.$snotify.simple('Contrato de Aerolinea Agregado con Exito.');
                        this.form.reset();
                        $('#contractModal').modal('hide');
                    }).catch(exception => {
                        this.setFormErrors(response, this.form)
                    });
                    this.getContracts()
                },
                 onsearchPoint(search, loading) {
                    if(_.isEmpty(search)){
                        this.optionList = this.optionListDefault
                        loading(false);
                        return
                    }
                    loading(true);
                    this.searchPoint(loading, search, this);
                },
                searchPoint: _.debounce((loading, search, vm) => {
                    debugger
                    axios.get(`/admin/contracts/get/pointSearch/${vm.selectedContract.id}/${escape(search)}`).then(res => {
                        debugger
                        vm.optionList = res.data
                        loading(false);
                    });
                }, 800),
                onSearchAirport(search, loading) {
                    if(_.isEmpty(search))
                        return
                    loading(true);
                    this.searchAirport(loading, search, this);
                },
                searchAirport: _.debounce((loading, search, vm) => {
                    axios.get(`/admin/airports/${escape(search)}`).then(res => {
                        vm.airports = res.data
                        loading(false);
                    });
                }, 800),
                openModal(){
                    $("span .select2-selection").prop("style","");
                    $("span .select2-selection").css("min-width: 90px !important");
                },
                addRangeDate: function () {
                    this.formrule.dateRanges.push({
                        start_date  : "2020-02-01",
                        end_date    : "2020-06-01"}
                    );
                     $('.daterange').datepicker({
                        language : 'en',
                        dateFormat : 'yyyy-mm-dd',
                        range: false,
                        multipleDatesSeparator: ' | '
                    })
                    return;
                },
                testCommision(){
                    $("#commissionresult").text("0");
                    this.formtest.post('/admin/contracts/testcommision').then(response => {
                        // this.$snotify.simple(response);
                        this.resultTestCommission = response.data[0]
                        $("#commissionresult").text(response.data[0].commision+ ' % ');
                    }).catch(exception => {
                        // this.setFormErrors(response, this.formtest)
                    });
                },
                removeRangeDate: function (index) {
                        this.formrule.dateRanges.splice(index, 1);
                    return;
                },
                removeRule: function (data) {
                       let vm = this
                       $.confirm.delete(
                           'Eliminar regla de periodo',
                           '¿Está seguro que desea eliminar la regla de <span class="p-1 text-primary bg-light rounded font-weight-bold"> Utilidad # ${data.id}</span>?',
                           function() {
                                axios.post('/admin/contracts/rule/deleteRule', {
                                    id: data.id
                                    }).then((response) => {
                                        vm.$snotify.success("Regla eliminada exitosamente.")
                                        vm.getRulesContract(vm.selectedContract.id)
                                    }).catch(() => {
                                        vm.$snotify.warning("No se pudo eliminar esta regla")
                                    })
                                }
                            )
                },
               async saveRegion(){
                    let vm = this
                    vm.formregion.contractid = vm.selectedContract.id
                    await vm.formregion.post('/admin/contracts/addRegionContract').then(response => {
                        $('#regionmodal').modal('hide');
                        vm.$snotify.simple('Region Actualizada Con Exito');
                        vm.formregion.reset();
                    }).catch(exception => {
                        vm.setFormErrors(response, vm.formregion)
                    });
                    vm.getContracts()
                },
                saveRule(){
                    let vm = this
                    vm.formrule.contractid = vm.selectedContract.id
                    vm.formrule.post('/admin/contracts/addRule').then(response => {
                        let contractid = vm.formrule.contractid;
                        vm.$snotify.success('Regla agregada con Exito');
                        vm.formrule.clear_form
                        vm.formrule.reset(vm.formrule);
                        vm.formrule.contractid = contractid
                        contractIdJs           = contractid
                        $('#rulemodal').modal('hide');
                        vm.getRulesContract(vm.selectedContract.id)
                    }).catch(exception => {
                        vm.setFormErrors(response, vm.formrule)
                    });
                },
                newContract(){
                    this.form.reset()
                },
                editContract(data){

                    let primary =   []
                    let other   =   []
                    data.airlinecontract.forEach(element => {
                        if(element.pivot.relation_type=="primary"){
                            primary.push(element);
                        }else{
                            other.push(element);
                        }
                    });

                    let vm  = this
                    vm.form.title           = "Editar Contrato Aerolineas"
                    vm.form.saveContractId  =   data.id
                    vm.form.start_date      =   data.start_date
                    vm.form.end_date        =   data.end_date
                    vm.form.airlines        =   primary
                    vm.form.otherairlines   =   other
                    vm.form.infants         =   data.apply_infant
                    vm.form.military        =   data.apply_military
                    vm.form.student         =   data.apply_student
                    vm.form.roundtrip       =   data.apply_round_trip
                    vm.form.oneway          =   data.apply_one_way
                    vm.form.multicity       =   data.apply_multicity
                },
                async getRegionsContract(){
                    let vm  = this
                    let idContract = (vm.selectedContract.id == null) ?  0 : vm.selectedContract.id
                    await axios.get('/admin/contracts/getRegionsContract/'+idContract).then(response => {
                        vm.regionCountries  = response.data
                        console.log(response.data)
                    }).catch(exception => {
                    });
                    vm.getInitData()
                },
                getInitData(){
                    let vm =  this
                    let idContract  = vm.selectedContract.id ? vm.selectedContract.id : 0
                    console.log('CONTRACT ID ',idContract)
                    axios.get('/admin/contracts/get/initdata/'+idContract).then(response => {
                        vm.optionListDefault = response.data.all
                        vm.optionList  = response.data.all
                        vm.regions     = response.data.regions
                        vm.countries   = response.data.countries
                        vm.airports    = response.data.airports
                    }).catch(exception => {
                    });
                },
                async getRulesContract(id){
                    let vm = this
                    id =    (id == "") ? 0 : id

                    if(vm.selectedContract.id==0){
                        this.rulescontract = [];
                        return;
                    }
                    console.log(this.selectedContract)
                    vm.contractid            = vm.selectedContract.id
                    vm.formregion.contractid = vm.selectedContract.id
                    vm.formrule.contractid   = vm.selectedContract.id
                    contractIdJs             = vm.selectedContract.id
                    vm.form.saveContractId   = vm.selectedContract.id
                   await axios.get('/admin/contracts/get/rulescontract/'+vm.selectedContract.id).then(response => {
                        var values          = response.data
                        this.rulescontract  = values
                    }).catch(exception => {
                        this.setFormErrors(response, this.form)
                    });
                    vm.getInitData()
                },
                async getContracts(){
                    var vm = this
                    await axios.get(`/admin/contracts/get/contract/${vm.selectedContract.id}`).then(response => {
                        vm.selectedContract = response.data
                    }).catch(exception => {
                        vm.$snotify.warning('Ocurrio un error en retraer la reglas de utilidad.');
                    });
                    vm.getRegionsContract()
                },
                getFavoriteArilines(){
                    axios.get('/admin/airlines/favorite').then(response => {
                        this.airlines = this.favoriteAirlines = response.data || []
                    })
                },
                testHelper(){
                    axios.get('/admin/contracts/testhelper').then(response => {
                        this.responseHelper = response.data;
                    }).catch(exception => {
                        this.$snotify.warning('Ocurrio un error en retraer la reglas de utilidad.');
                    });
                },
                goBack: function () {
                    window.history.back();
                },
        },
        created() {
        },
         mounted(){
             let vm = this 
        },
    }


</script>

<style>
    .select2-container{
        width: 100% !important;
        min-width:100px !important;
    }
    span .select2-selection{
        min-width: 100px !important;
    }
</style>
