<template>
    <div>
        <div class="modal fade" @submit.prevent="saveHotelRoomResponse()" id="hotelRoomResponseModal">
            <div v-if="HotelResponseModal" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formHotelRoomResponse" autocomplete="off" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="roomIndex!=null">
                                    Editar estadia en {{HotelResponseModal.hotel.name}}
                                </span>
                                <span v-else>Nueva estadia en {{HotelResponseModal.hotel.name}}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!-- -->
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_date" class="required">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="entry_date" name="entry_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_hour" class="required">Hora de Ingreso</label>
                                    <vue-timepicker name="entry_hour" v-model="entry_hour" placeholder="Hora de Ingreso" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="out_date" class="required">Fecha de Salida</label>
                                    <vue-datepicker v-model="out_date" name="out_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="out_hour" class="required">Hora de Salida</label>
                                    <vue-timepicker name="out_hour" v-model="out_hour" placeholder="Hora de Salida" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-9 col-md-9">
                                    <label class="required" style="width: 100%; margin-bottom: 3px;">Habitación
                                        <a :href="'/packages/hotels'" target="_blank" class="float-right btn btn-secondary btn-sm" title="Agregar Nueva Habitacion">
                                            <span class="icon-add-2"></span>
                                        </a>
                                        <span class="float-right mr-1" v-show="loadHotelRooms">
                                            <small class="mt-1">Cargando </small>
                                            <div class="spinner-border text-secondary spinner-border-sm" role="status">
                                            </div>
                                        </span>
                                        <span class="float-right btn btn-light btn-sm mr-1" v-show="!loadHotelRooms" @click="getHotelRooms()">
                                            Recargar <span class="icon-refresh"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="hotel_room"
                                    @keypress.enter.native.prevent="" name="hotel_room_id" required="required"
                                    ref="vSelectRoomId" placeholder="Tipo Habitacion" :options="hotelRoomsSelect">
                                    </v-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="required">Cantidad</label>
                                    <input type="number" min="1" max="9"  v-model.number="cant" class="form-control">
                                </div>
                                <div class="form-group col-12 col-md-12">
                                    <label for="beds" style="width: 100%">Resumen de habitacion</label>
                                    <span v-show="!hotel_room">-- Debe seleccionar una habitacion --</span>
                                    <div class="form-control" v-show="hotel_room" style="height: auto; min-height: 35px;">
                                        <div class="row">
                                            <div class="col-3 font-weight-bold">Capacidad:</div>
                                            <div class="col-9">
                                                <span>{{resumenRoom['capacity']}} {{ (resumenRoom['capacity'] > 1) ? ' Personas' : ' Persona' }}</span>
                                            </div>
                                            <div class="col-3 font-weight-bold">Camas:</div>
                                            <div class="col-9">
                                                <span v-for="(item, index) in resumenRoom.beds" class="my-1" style="display: block" :key="'bed-'+index">
                                                    <span class="ds-icon mr-2" :class="item.icon"></span> 
                                                    <b>{{item.n}}</b> -  {{item.title}} ( <b>{{item.capacity * item.n}} <span class="icon-user-2"></span></b> )
                                                </span>
                                            </div>
                                            <div v-show="resumenRoom['views'] && resumenRoom['views'].length>0" class="col-3 font-weight-bold">Vistas:</div>
                                            <div v-show="resumenRoom['views'] && resumenRoom['views'].length>0" class="col-9">
                                                <span v-for="(item, index) in resumenRoom.views" class="badge badge-light mr-1 py-1 my-1" :key="'view-'+index">
                                                    {{item}}
                                                </span>
                                            </div>
                                            <div v-show=" resumenRoom.hotel_services &&  resumenRoom.hotel_services.length>0" class="col-3 font-weight-bold">Servicios:</div>
                                            <div v-show=" resumenRoom.hotel_services &&  resumenRoom.hotel_services.length>0" class="col-9">
                                                <span v-for="(item, index) in  resumenRoom.hotel_services" class="badge badge-light mr-1 py-1 my-1" :key="'service-'+index">
                                                    <!-- <span :class="item.icon" grid="16"></span>  -->
                                                    {{item.title}}
                                                </span>
                                            </div>
                                            <div v-show=" resumenRoom.furnishings &&  resumenRoom.furnishings.length>0" class="col-3 font-weight-bold">Muebles:</div>
                                            <div v-show=" resumenRoom.furnishings &&  resumenRoom.furnishings.length>0" class="col-9">
                                                <span v-for="(item, index) in  resumenRoom.furnishings" class="badge badge-light mr-1 py-1 my-1" :key="'furnishing-'+index">
                                                    {{item.name}}
                                                </span>
                                            </div>
                                            <div v-show="resumenRoom.description" class="col-3 font-weight-bold">Descripcion:</div>
                                            <div v-show="resumenRoom.description" class="col-9">
                                                {{resumenRoom.description}}
                                            </div>
                                            <div class="col-3"></div>
                                            <div v-show="resumenRoom.smok" class="col-9">
                                                Se permite fumar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelRoomResponse__cost"
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelRoomResponse__taxes"
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formHotelRoomResponse__utility"
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="total">Precio total por estadia</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="priceTotal"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" 
                                    :precision="2" :decimal-separator="'.'" disabled
                                    name="total"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-8">
                                    <label for="url">Enlace de origen del precio</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="url" name="url">
                                </div>
                                <div class="form-group col-12">
                                    <label>Descripcion de estadia</label>
                                    <textarea class="form-control" v-model="description" name="description" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" :disabled="!validateSave" class="btn btn-primary" v-can:create="'packages-index'"> 
                                <span v-if="roomIndex!=null && $can('update', 'packages-index')">
                                    Editar Estadia
                                </span>
                                <span v-else>
                                    Guardar Estadia
                                </span>
                            </button>
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="css" scoped>
    .is-invalid{
        border-color: #e3342f;
    }
</style>
<script>

import VueNumeric from 'vue-numeric';

export default {
    components: {
        VueNumeric,
    },
    props: {
        HotelResponseModal: {
            type: [Object],
            default () {
                return false
            }
        },
        roomIndex: null
    },
    data() {
        return {
            loadHotelRooms          : false,
            hotelRooms              : [],
            hotelRoomsSelect        : [],
            formHotelRoomResponse: new WebForm('#formHotelRoomResponse', {
                id                      : null,
                hotel_id                : null,
                quote_hotel_id          : null,
                body_json               : null
            }),
            hotel_room              : null,
            hotel_room_id           : null,
            cant                    : 1,
            entry_date              : '',
            out_date                : '',
            entry_format            : '',
            out_format              : '',
            entry_hour              : {"H":"00", "mm":"00"},
            out_hour                : {"H":"00", "mm":"00"},
            cost                    : 0,
            taxes                   : 0,
            utility                 : 0,
            description             : '',
            url                     : '',
        };
    },
    watch: {
        HotelResponseModal: function(val, oldVal) {
            let vm = this;
            if(vm.HotelResponseModal!=null) {
                vm.getHotelRooms();
            }
        },
        roomIndex: function(val, oldVal) {
            let vm = this;
            if(vm.roomIndex!=null && vm.HotelResponseModal!=null) {
                let vm = this;
                vm.cant                    = vm.HotelResponseModal.body_json[vm.roomIndex].cant_room;
                vm.entry_date              = vm.HotelResponseModal.body_json[vm.roomIndex].entry_date;
                vm.out_date                = vm.HotelResponseModal.body_json[vm.roomIndex].out_date;
                vm.entry_format            = vm.HotelResponseModal.body_json[vm.roomIndex].entry_format;
                vm.out_format              = vm.HotelResponseModal.body_json[vm.roomIndex].out_format;
                vm.entry_hour              = vm.HotelResponseModal.body_json[vm.roomIndex].entry_hour;
                vm.out_hour                = vm.HotelResponseModal.body_json[vm.roomIndex].out_hour;
                vm.cost                    = vm.HotelResponseModal.body_json[vm.roomIndex].cost;
                vm.taxes                   = vm.HotelResponseModal.body_json[vm.roomIndex].taxes;
                vm.utility                 = vm.HotelResponseModal.body_json[vm.roomIndex].utility;
                vm.description             = vm.HotelResponseModal.body_json[vm.roomIndex].description;
                vm.url                     = vm.HotelResponseModal.body_json[vm.roomIndex].url;
            }
        }, 
    },
    computed: {
        validateSave() {
            let vm = this;
            let response = true;
            response = (vm.entry_date) ? response : false;
            response = (vm.out_date) ? response : false;
            response = (vm.hotel_room) ? response : false;
            response = (vm.cost && vm.cost > 0) ? response : false;
            response = (vm.utility && vm.utility > 0) ? response : false;
            return response;
        },
        resumenRoom() {
            let vm = this;
            if(vm.hotel_room && vm.hotel_room.id) {
                let newResumen = vm.hotelRooms.find(c => {
                    return c.id == vm.hotel_room.id;
                });
                newResumen = Object.assign({}, newResumen);
                newResumen.beds = (newResumen.beds && newResumen.beds!=null) ? JSON.parse(vm.decodeHTML(newResumen.beds)) : '';
                newResumen.views = (newResumen.views && newResumen.views!=null) ? JSON.parse(vm.decodeHTML(newResumen.views)) : '';
                
                return newResumen;
            } else {
                return [];
            }
        },
        priceTotal() {
            let vm = this;
            let cost        = (vm.cost) ? parseFloat(vm.cost) : 0; 
            let taxes       = (vm.taxes) ? parseFloat(vm.taxes) : 0;
            let utility     = (vm.utility) ? parseFloat(vm.utility) : 0;
            let total = cost + taxes + utility;
            vm.card_amount = total;
            return total;
        },
    },
    methods: {
        clearValues() {
            let vm = this;
            vm.hotel_room              = null;
            vm.hotel_room_id           = null;
            vm.cant                    = 1;
            vm.entry_date              = '';
            vm.out_date                = '';
            vm.entry_format            = '';
            vm.out_format              = '';
            vm.entry_hour              = {"H":"00", "mm":"00"};
            vm.out_hour                = {"H":"00", "mm":"00"};
            vm.cost                    = 0;
            vm.taxes                   = 0;
            vm.utility                 = 0;
            vm.description             = '';
            vm.url                    = '';
            vm.loadHotelRooms          = false;
            vm.hotelRooms              = [];
            vm.hotelRoomsSelect        = [];
            vm.formHotelRoomResponse.clear();
            vm.formHotelRoomResponse.reset();
        },
        saveHotelRoomResponse() {
            let vm = this;
            if(vm.hotel_room && vm.hotel_room.id) {
                vm.hotel_room_id = vm.hotel_room.id;
            }

            let entry_format    = (vm.entry_hour.H>9 || vm.entry_hour.H.length > 1) ? vm.entry_date + ' ' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' : vm.entry_date + ' 0' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' ;
            
            let out_format      = (vm.out_hour.H>9 || vm.out_hour.H.length > 1) ? vm.out_date + ' ' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' : vm.out_date + ' 0' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' ;
            
            let newData = {
                cant_room:          vm.cant,
                cost:               vm.cost,
                description:        vm.description,
                entry_date:         vm.entry_date,
                entry_format:       entry_format,
                entry_hour:         vm.entry_hour,
                hotel_room_id:      vm.hotel_room_id,
                out_date:           vm.out_date,
                out_format:         out_format,
                out_hour:           vm.out_hour,
                taxes:              vm.taxes,
                url:                vm.url,
                utility:            vm.utility
            };

            if ( vm.HotelResponseModal.body_json !=null 
                && vm.HotelResponseModal.body_json.length > 0
                && vm.roomIndex!=null
                && vm.HotelResponseModal.body_json[vm.roomIndex]) {
                    vm.HotelResponseModal.body_json[vm.roomIndex] = newData;
            } else {
                if(vm.HotelResponseModal.body_json == null) {
                    vm.HotelResponseModal.body_json = [];
                }
                vm.HotelResponseModal.body_json.push(newData);
            }

            let tempBody = null;
            
            if ( vm.HotelResponseModal.body_json !=null && vm.HotelResponseModal.body_json.length>0) {
                tempBody = JSON.parse(JSON.stringify(vm.HotelResponseModal));
                tempBody.body_json.forEach(element => {
                    (element.hotel_room)    ? delete element.hotel_room : null ;
                });
            }

            vm.formHotelRoomResponse.id             = tempBody.id;
            vm.formHotelRoomResponse.hotel_id       = tempBody.hotel_id;
            vm.formHotelRoomResponse.quote_hotel_id = tempBody.quote_hotel_id;
            vm.formHotelRoomResponse.body_json      = JSON.stringify(tempBody.body_json);
            
            vm.formHotelRoomResponse.post('/admin/quotes/hotels/save').then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if(data.success) {
                    let msj = (vm.roomIndex!=null) ? 'Estadia editada con exito.' : 'Nueva Estadia agregada.' ;
                    vm.$snotify.success(msj);
                    let obj = data.success;
                    this.$emit("saveNewHotelResponse", obj);
                    $("#hotelRoomResponseModal").modal("hide");
                }
                return;
                vm.$snotify.info(`Algo resulto mal...`)
            });
        },
        getHotelRooms(id_select) {
            let vm = this;
            if(vm.HotelResponseModal && vm.HotelResponseModal.hotel_id) {
                vm.loadHotelRooms = true;
                let endpoint = `/admin/hotels/hotel_rooms/allbyid/${vm.HotelResponseModal.hotel_id}`;
                axios.get(endpoint).then(({data}) => {
                    let hotelRooms = data.map(l => {
                        let room = l
                        let d = {
                            id: room.id,
                            name: room.type_room.title,
                            label: `${room.type_room.title} - Capacidad ${room.capacity} ${ (room.capacity > 1) ? 'Personas' : 'Persona' } `,
                        }
                        return d
                    });
                    vm.hotelRooms = data;
                    vm.hotelRoomsSelect = hotelRooms;
                    vm.hotelRoomsSelect.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                    if(id_select) {
                        vm.formHotelRoomResponse.hotel_room = vm.hotelRoomsSelect.find(c => {
                            return c.id == id_select;
                        });
                    }
                    if(vm.roomIndex!=null && vm.HotelResponseModal.body_json!=null && vm.HotelResponseModal.body_json[vm.roomIndex] && vm.HotelResponseModal.body_json[vm.roomIndex].hotel_room_id) {
                        vm.hotel_room = vm.hotelRoomsSelect.find(c => {
                            return c.id == vm.HotelResponseModal.body_json[vm.roomIndex].hotel_room_id;
                        });
                    }
                })
                .finally(() => {
                    vm.loadHotelRooms = false;
                });
            }
        },
    },
    mounted() {
        let vm = this;
        $('#hotelRoomResponseModal').off('hidden.bs.modal');
        $('#hotelRoomResponseModal').on('hidden.bs.modal', function () {
            vm.clearValues();
            vm.$emit('closeHotelRoomResponse');
        });
        console.log('HotelRoomResponseModal mounted.');
    }
}
</script>

