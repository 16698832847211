import Vue from 'vue'
import store from '../store'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    messages: {}
})

export async function loadMessages (locale) {
    
    if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
        const messages = require(`./locale/${locale}.json`)
        i18n.setLocaleMessage(locale, messages)
    }

    if (i18n.locale !== locale)
        i18n.locale = locale
}

;(async function () {

    let locale  = store.getters['lang/locale']
    let locales = store.getters['lang/locales']

    if(!locales.hasOwnProperty(locale))
        locale  = "en"

    await loadMessages(locale)
})()

export default i18n