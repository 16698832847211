<template>
    <div class="modal fade" id="addDestination">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <form id="formDestination" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formDestination.id">
                                Editar Portada  para Destinos
                            </span>
                            <span v-else>Nueva Portada para Destinos</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-6" style="height: 230px;">
                                <label for="image" class="">Imagen</label>
                                <load-image :clearCurrent="clearCurrent" :color_primary="colors.primary" :last_image="formDestination.path_image + formDestination.last_image" v-on:getImage="getImage"></load-image>
                            </div>
                            <div class="form-group col-md-2" style="height: 230px;">
                                <label for="image" class="">Color Primario</label>
                                <div v-if="colors.primary" class="cuadro shadow-sm" :style="{'background-color':colors.primary}"></div>
                            </div>
                            <div class="form-group col-md-4" style="height: 230px;">
                                <label for="image">Paleta de Colores</label>
                                <div v-if="colors.palette">
                                    <div v-for="(color, index) in colors.palette" @click.prevent="selectPrimary(color)" :style="{'background-color':color}" :key="index" class="circulo shadow-sm"></div>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="country_id">Pais</label>
                                <v-select v-model="country" name="country_id" 
                                @search="searchCountry" ref="vSelectCountry" 
                                placeholder="Seleccione Pais" label="label"
                                @keypress.enter.native.prevent="" selectOnTab 
                                :options="countries" required="required">
                                    <template v-slot:option="option">
                                        <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                        {{ option.label }}
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="city_id" style="width: 100%">Ciudad
                                    <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addCity">
                                        <span class="icon-add-2" grid="12"></span>
                                    </span>
                                </label>
                                <v-select v-model="formDestination.city" name="city_id" 
                                ref="vSelectCity" @keypress.enter.native.prevent="" label="label"
                                selectOnTab placeholder="Seleccione Ciudad" :options="cities">
                                    <div v-if="country" slot="no-options">Sin resultados</div>
                                    <div v-else slot="no-options">Debe seleccionar un pais.</div>
                                </v-select>
                            </div>
                            <div class="form-group col-12">
                                    <label for="airports">Aeropuertos</label>
                                    <v-select placeholder="Buscar Aeropuertos" v-model="formDestination.airports" :options="airports" 
                                    label="name" :filterable="false" @search="onSearchAirport" multiple selectOnTab>
                                        <template slot="no-options">Buscar Aeropuertos...</template>
                                        <template slot="option" slot-scope="option">
                                            {{ option.destination.length > 0 ? '--USED--' : ''}}
                                            ({{ option.iata }}) {{ option.name }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            {{ option.iata }}
                                        </template>
                                    </v-select>
                                </div>
                            <div v-show="showText" class="form-group col-md-4">
                                <label for="title" class="required">Titulo</label>
                                <input type="text" class="form-control" placeholder="Ingrese Titulo" v-model="formDestination.title" name="title">
                            </div>
                            <div v-show="showText" class="form-group col-md-8">
                                <label for="short_description" class="required">Descripcion Corta</label>
                                <textarea class="form-control" placeholder="Descripcion" name="short_description"  v-model="formDestination.short_description"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'contents-destinations-index'" > 
                            <span v-if="formDestination.id && $can('update','contents-destinations-index')">
                                Editar
                            </span>
                            <span v-else-if="formDestination.id && !$can('update','contents-destinations-index')">
                                Solicitar Edicion
                            </span> 
                            <span v-else>
                                Guardar
                            </span>
                        </button> 
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <city-modal v-on:reloadCityModal="reloadCity()"></city-modal>
    </div>
</template>
<script>

import loadImage from '~/components/covers/loadImage';
import CityModal from '~/components/geodatos/CityModal';

export default {
    props: ["Destination"],
    components: {
        loadImage,
        CityModal
    },
    data() {
        return {
            colors: {
                'image'     : null,
                'primary'   : '',
                'palete'    : null,
            },
            showText                    : false,
            clearCurrent                : false,
            formDestination: new WebForm("#formDestination", {
                short_description       : null,
                image                   : null,
                last_image              : null,
                path_image              : '/storage/destinations/',
                color_primary           : '',
                title                   : null,
                city                    : null,
                city_id                 : null,
                id                      : null,
                change                  : null,
                airports                : []
            }),
            cities                      : [],
            countries                   : [],
            country_id                  : '',
            country                     : '',
            searchDelay                 : null,
            airports                    : []
        };
    },
    watch: {
        Destination: function(val, oldVal) {
            if (_.isEmpty(this.Destination) || this.Destination.id == '') {
                
            }else {
                this.formDestination.fill(this.Destination);
                this.formDestination.last_image = this.Destination.image;
                this.getCountry(this.Destination.city.state.country.id);
                this.loadAirports(this.Destination.airport);
            }
        },
        country: function(val, oldVal) {
            // console.log('cambio: ', this.country.id);
            if (_.isEmpty(this.country)) {
                this.$refs.vSelectCity.clearSelection();
                this.cities = [];
            }else {
                this.$refs.vSelectCity.clearSelection();
                this.getCities(this.country.id);
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            this.formDestination.image = this.colors.image;
            this.formDestination.color_primary = this.colors.primary;

            if(vm.formDestination.city) {
                vm.formDestination.city_id = vm.formDestination.city.id;
            }

            if (this.formDestination.image == null) {
                vm.$snotify.warning('Debe seleccionar una imagen');
                return;
            }

            let tipo = vm.formDestination.id == null ? 1 : 2;
            if(this.formDestination.id != null) {
                $.confirm({
                    title: `Editar Destino`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.updateOrCreate(tipo)
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.updateOrCreate(tipo)
            }
        },
        getImage(obj) {
            let last_primary = this.formDestination.color_primary;

            let primary = 'rgb(' + obj.primary.join(',') + ')';
            obj.primary = primary;
            obj.palette = obj.palette.map(p => {
                return 'rgb(' + p.join(',') + ')';
            });
            obj.palette.unshift(primary);
            this.colors = obj;
            
            if (obj.hasOwnProperty('last')) {
                this.colors.primary = last_primary;
            } else {
                this.formDestination.change = true;
            }
        },
        selectPrimary(color) {
            this.colors.primary = color;
        },
        updateOrCreate(type) {
            let vm = this;
            vm.formDestination.post('/admin/destinations/save').then(response => {
                let data = response.data;

                if (data.user_request_status) {
                    $('#addDestination').modal('hide');
                    return;
                }

                let snotify_message =
                    type == 1
                    ? "Elemento Agregado con exito."
                    : "Elemento actualizado con exito.";
                vm.$snotify.success(snotify_message);
                vm.formDestination.clear();
                vm.formDestination.reset();
                vm.$emit('reloadDestinationModal');
                $("#addDestination").modal("hide");
                return;
            });
        },
        reloadCity() {
            let vm = this;
            if(vm.country && vm.country.id) {
                vm.getCities(vm.country.id);
            }
        },
        searchCountry(search, loading) {
            if (search.length>2 ) {
                loading(true);
                const vm = this;
                clearInterval(vm.searchDelay);

                vm.searchDelay = setInterval(function(){
                    clearInterval(vm.searchDelay);
                    axios.get(`/admin/location/countries/${search}`).then((response) => {
                        if (response.data) {
                            let countries = response.data.map(y => {
                                let country = y;
                                let d = {
                                    id      :       country.id,
                                    name    :       `${country.name} (${country.native_name})`,
                                    label   :       `${country.name} (${country.native_name})` 
                                }
                                return d;
                            });
                            vm.countries = countries;

                            vm.countries.sort(function(a, b) {
                                var a1= a.name, b1= b.name;
                                if(a1 == b1) return 0; 
                                return a1> b1? 1: -1;    
                            });     
                        } else {
                            console.log('No se pudieron cargar los paquetes');
                        }
                        loading(false);
                    });
                }, 500);
            }
        },
        getCountry(id) {
            let vm = this;
            axios.get(`/admin/location/countries/${id}`).then((response) => {
                if (response.data) {
                    let data = response.data;
                    let countries = [{
                        id      :       data.id,
                        code    :       data.short_iso_code,
                        name    :       `${data.name} (${data.native_name})`,
                        label   :       `${data.name} (${data.native_name})` 
                    }];
                    vm.countries = countries;
                    vm.country = vm.countries[0];
                    vm.getCities(vm.country.id);
                } else {
                    console.log('No se pudieron cargar los paquetes');
                }
            });
        },
        getCities(country_id){
            let vm = this;
            // let endpoint = `/admin/cities/allbycountry/${country_id}`;
            let endpoint = `/admin/location/cities/country/${country_id}`;

            axios.get(endpoint).then(({data}) => {
                console.log(data);
                let cities = data.map(l => {
                    let city = l
                    let d = {
                        id: city.id,
                        label: (city.name.indexOf(city.district)>=0) ? `${city.name}` : `${city.name} ${city.district} `,
                        name: (city.name.indexOf(city.district)>=0) ? `${city.name}` : `${city.name} ${city.district} `,
                    }
                    return d
                });
                this.cities = cities;

                this.cities.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });

                this.loadEdit = false;
                
                if(vm.Destination!=null && vm.Destination.city.id!="") {
                    vm.formDestination.city = vm.cities.find(c => {
                        return c.id == vm.Destination.city.id;
                    });
                }
            });
        },
        onSearchAirport(search, loading) {
            if(_.isEmpty(search))
                return
            loading(true);
            this.searchAirport(loading, search, this);
        },
        searchAirport: _.debounce((loading, search, vm) => {
            axios.get(`/admin/airports/${escape(search)}/${true}`).then(res => {
                vm.airports = res.data
                loading(false);
            });
        }, 800),
        loadAirports(airportsToLoad) {
            let newAirports =[];
            airportsToLoad.forEach(air => {
                let val = air;
                newAirports.push(val);
            });

            this.formDestination.airports = newAirports;
        },
    },
    mounted() {
        let vm = this;
        
        $('#addDestination').off('hidden.bs.modal');
        $('#addDestination').on('hidden.bs.modal', function () {
            vm.formDestination.clear();
            vm.formDestination.reset();
            vm.country = null;
            vm.colors = { 'image': null, 'primary': null, 'palete': null};
            setTimeout (() => {
                vm.clearCurrent = true;
            }, 200);
            vm.clearCurrent = false;
            vm.$emit('closeDestinationModal');
        });
    }
};
</script>
<style scoped>
.circulo {
    width: 50px;
    height: 50px;
    float: left;
    display: block;
    cursor: pointer;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 3px white;
}

.cuadro {
    width: 100px; 
    height: 100px; 
    border: solid 3px white;
}
</style>