<template>
    <div style="height: 100%">
         <div class="legal-docs-wrapper">
            <div  class="d-flex align-items-center">
                <h5 class="font-weight-bold mb-0">Noticias</h5>

                <div class="ml-auto">
                    <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newLegalDocsModal" >Agregar <span class="icon-add-2"></span></button>
                </div>
            </div>
            <div v-if="loading" class="d-flex flex-wrap" style="height: 100%;">
                <div class="col-12 col-lg-2 py-3">
                    <legal-docs-loader :width_svg="150" :height_svg="150" :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'" />
                </div> 
                <div class="col-12 col-lg-10 panel-border-left py-3 legal-documents-text">
                   Cargando...
                </div>
            </div>
            <template v-else>
                <div v-if="documents.length > 0 " class="section mt-3 section-scrolled flex-grow-1">
                    <div class="d-flex flex-wrap position-relative" style="height: 100%;">
                        <div class="d-lg-block col-12 col-lg-3 py-3" :class="{'d-none': showing}">
                            <label for="name">Seleccione Idioma</label>
                            <select @change="updateShowDocsList" id="documentLanguages" v-if="documentLanguages" class="form-control" v-model="languageView">
                                <option :value="15">Español</option>
                                <option :value="7">English</option>
                            </select>
                            <ul class="menu-list defaul-size">
                                <li v-for="document in showListDocument" :key="'doc-'+document.id" @click="showDocument(document.id)" :class="{'active': document.id == documentActive.id}">
                                    {{ document.title }}
                                </li>
                            </ul>
                        </div> 
                        <div v-if="documentActive" class=" d-lg-block col-12 col-lg-9 panel-border-left py-3 legal-documents-text" :class="{'d-none': !showing}">
                            <header>
                                <h4 class="font-weight-bold">{{ documentActive.title }}</h4>
                            </header>
                            <hr>
                            <div class="html-block" v-html="documentActive.content"></div>
                        </div>
                        <div class="legal-documents-tools  d-lg-block" >
                            <div class="d-flex">
<!-- v-can:manage  -->
                                 <button class="btn btn-light text-danger mx-1" @click="onDeleteDoc"><span class="icon-bin"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="section-empty">
                    <div class="text-center">
                        <span class="icon-folder icon"></span>
                        <p class="title my-2">No se ha agregado información legal</p>
                        <div>
                            <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newLegalDocsModal" v-can:create>Agregar <span class="icon-add-2"></span></button>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- Modal Create -->
        <div class="modal fade" id="newLegalDocsModal" tabindex="-1" role="dialog"  aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nueva Noticia</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="legalDocsFrom" @submit.prevent="saveDocument">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="title">Titulo</label>
                                        <input type="text" id="title" class="form-control bg-white" v-model="form.title">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="name">Seleccione Idioma</label>
                                        <select id="system_language_id" class="form-control" v-model="form.language_id">
                                            <option :value="15" selected>Español</option>
                                            <option :value="7">English</option>
                                        </select>
                                    </div>
                                </div>                                
                            </div>
                            
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="start_date">Fecha Publicación</label>
                                        <vue-datepicker v-model="form.start_date" class="form-control bg-white" name="start_date" id="start_date"></vue-datepicker>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="form-group">
                                        <label for="end_date">Ultimo día Publicacion (dejar vacío si no tiene limite)</label>
                                        <vue-datepicker v-model="form.end_date" class="form-control bg-white" name="end_date" id="end_date"></vue-datepicker>
                                    </div>
                                </div>
                            </div>
                                    
                            <div class="form-group editor-height mt-4">
                                <label for="content">Contenido</label>
                                <quill-editor id="content" v-model="form.content" 
                                    ref="editor" 
                                    @blur="onEditorBlur($event)"
                                    @focus="onEditorFocus($event)"
                                    @ready="onEditorReady($event)">
                                </quill-editor>
                            </div>
                            <div class="text-center">
                                <v-button class="center-content ml-auto" :type="'success'" :loading="form.processing">
                                    Guardar <span class="icon-floppy-disk ml-2"></span>
                                </v-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal update -->
       
        
    </div>
</template>
<style scoped>
    .legal-docs-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .section-scrolled{
        height: 50%;
    }
    .legal-documents-text {
        height: 100%;
        overflow: auto;
        padding-top: 67px !important;
    }
    .legal-documents-tools{
        position: absolute;
        padding: 0.75rem;
        width: 100%;
        right: 0;
    }
    @media (min-width: 992px) {
        .legal-documents-text { 
            padding-top: 1rem !important;
        }
        .legal-documents-tools{
            width: auto;
        }

    }
</style>

<script>

import vButton from '~/components/button'
import LegalDocsLoader from '~/components/LegalDocsLoader'
import { filter } from 'minimatch'

export default {
    middleware:'auth',
    layout: 'dashboard',
    components: {
        vButton,
        LegalDocsLoader
    },
    data () {
        return {
            languageView: 15,
            showing: false,
            form: new WebForm("#legalDocsFrom", {
                    name                : '',
                    title               : '',
                    content             : '',
                    language_id  : 15,
                    start_date : '',
                    end_date : null
            }),
            systemLanguages: [],
            loading: true,
            documents: [],
        }
    },
    computed: {
        documentActive () {
            let document = this.documents.find(item => {
                return item.show == true
            })
            
            if(document) {
                return document
            }
            return false
        },
        showListDocument () {
            let vm = this
            let documents = this.documents.filter(doc => {
                return doc.language_id == vm.languageView
            })
            return documents
        },
        documentLanguages(){
            let languages = _.uniq(this.documents.map((item) => item.language_id))
            let filters   = this.systemLanguages.filter(e => {
                if(languages.indexOf(e.id) !== -1)
                    return e
            })

            if(filters.length > 0)
                this.languageView = filters[0].id

            return filters;
        }
    },
    watch: {
       
    },
    methods: {
        onDeleteDoc () {
            let vm = this
            $.confirm.delete(
                `Eliminar Noticia`,
                `Seguro que quiere eliminar? ${vm.documentActive.title}`,
                function(){
                    vm.deteleDoc()
                }
            )
        },
        deteleDoc () {
            let vm = this
            let endpoint = `/admin/contents/news/delete`
            axios.post(endpoint, vm.documentActive).then(response => {                
                vm.getDocuments ()    

            })
        },
        updateShowDocsList () {
            this.getDocuments()
        },
        showDocument (id) {
            this.showing = true
            if(this.documentActive.id == id) {
                window.history.pushState(null, null, '/contents/news');
                return
            }

            if(this.documentActive) {
                this.documentActive.show = false 
            }

            let document = this.documents.find(item  => {
                return item.id == id
            })
            window.history.pushState(null, null, '/contents/news');
            document.show = true
        },
        saveDocument () {
            let vm = this
            let endpoint = '/admin/contents/news/add'

            this.form.post(endpoint).then(response  => {                  
                vm.$snotify.simple('Noticia agregada con Exito.');
                vm.form.reset();
                $('#newLegalDocsModal').modal('hide');
                vm.getDocuments ()               
            })
        },
        getDocuments () {
            let vm = this            
            this.documents =  []
            let lng = vm.languageView !== null ? vm.languageView : 15
            let endpoint = '/admin/contents/news/get/all/'+lng
            let name = this.$route.params.name
            axios.get(endpoint)
                .then(response => {
                    response.data.forEach( (item, index) => {

                        item.show = false
                        if(name) {
                            if (name ==  item.name) {
                                item.show = true
                                vm.languageView = item.language_id
                            }
                            vm.showing = true
                        } else if(index == 0) {
                            item.show = true
                        } 
                        vm.documents.push(item)
                    });
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                })
        },
        onEditorBlur () {

        }, 
        onEditorFocus () {

        },
        onEditorReady () {

        },
        translate () {
            let vm = this
            let lang_from = this.systemLanguages.find(l => {
                return l.id == vm.documentActive.language_id
            })

            let lang_to = this.systemLanguages.find(l => {
                return l.id == vm.translateForm.language_id
            })
            if(!(lang_from && lang_to)) {
                console.warn('Lenguages no especificado')
            }
            let lang = `${lang_from.short_iso_code}-${lang_to.short_iso_code}`.toLowerCase()

            let $content = $(`<div>${this.documentActive.content}</div>`)

            window.YandexTranslate.translate({lang: lang, text: this.documentActive.name}, function (text) {
                vm.translateForm.name = text
            })

            window.YandexTranslate.translate({lang: lang, text: this.documentActive.title}, function (text) {
                vm.translateForm.title = text
            })

            $content.children().each(async function(item) {
                let _this = this
                let text = this.textContent
                await window.YandexTranslate.translate({lang: lang, text: text}, function (text) {
                    _this.textContent = text
                    vm.translateForm.content = $content.html()
                })
            })
        },
    },
    async mounted () {
        await this.getDocuments()
        await this.getSystemLanguages()
    }
}
</script>