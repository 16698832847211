<template>
    <div>
        <div class="modal fade" @submit.prevent="onStayAttraction()" id="addStayAttractionModal">
            <div v-if="Stay" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formStayAttraction" autocomplete="off"  novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="Stay.package_attraction!=null">
                                    Editar atraccion turistica en estadia
                                    <br><small>Del <u>{{Stay.start_date}} al {{Stay.end_date}}</u></small>
                                </span>
                                <span v-else>Agregar atraccion turistica en estadia
                                    <br><small>Del <u>{{Stay.start_date}} al {{Stay.end_date}}</u></small>
                                </span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <!--  -->
                                <div class="form-group col-12 col-md-6">
                                    <label for="attraction_id"  style="width: 100%">Atraccion Turistica
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#modalAttraction">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="attraction" @keypress.enter.native.prevent="" selectOnTab
                                    name="attraction_id" ref="vSelectAttractionId" placeholder="Atraccion" :options="attractions"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label for="tickets">Tickets</label>
                                    <input type="number" name="tickets" min="0" max="10" class="form-control" v-model="formStayAttraction.tickets">                     
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="formStayAttraction.non_date" name="non_date" class="pt-2 btn-add-aa">
                                        Sin fechas
                                    </label>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="formStayAttraction.is_free" name="is_free" class="pt-2 btn-add-aa">
                                        Sin Costo
                                    </label>  
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayAttraction.non_date" class="form-group col-12 col-md-3">
                                    <label for="start_date">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="formStayAttraction.start_date" name="start_date"></vue-datepicker>
                                </div>
                                <div v-show="!formStayAttraction.non_date" class="form-group col-12 col-md-3">
                                    <label for="start_hour">Hora de Ingreso</label>
                                    <vue-timepicker name="start_hour" v-model="formStayAttraction.start_hour_a" placeholder="Hora de Ingreso" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div v-show="!formStayAttraction.non_date" class="form-group col-12 col-md-3">
                                    <label for="end_date">Fecha de Salida</label>
                                    <vue-datepicker v-model="formStayAttraction.end_date" name="end_date"></vue-datepicker>
                                </div>
                                <div v-show="!formStayAttraction.non_date" class="form-group col-12 col-md-3">
                                    <label for="end_hour">Hora de Salida</label>
                                    <vue-timepicker name="end_hour" v-model="formStayAttraction.end_hour_a" placeholder="Hora de Salida" format="hh:mm A" close-on-complete hide-clear-button></vue-timepicker>                     
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayAttraction.cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayAttraction.taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-4">
                                    <label for="link">Enlace</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="formStayAttraction.link" name="link">
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad</label>
                                    <vue-numeric :currency="formStayAttraction.simbol" class="form-control" separator="," v-model.number="formStayAttraction.utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-4">
                                    <label for="total" class="required">Precio total de atraccion</label>
                                    <vue-numeric currency="$" class="form-control" separator="," disabled
                                    :min="0" :max="99999" :empty-value="0" placeholder="" :value="priceTotal"
                                    :precision="2" :decimal-separator="'.'"></vue-numeric>
                                </div>
                                <div class="col-4">
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12">
                                    <label for="description" class="w-100">
                                        <span>Descripción</span>
                                        <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                            <label class="btn btn-sm" :class="formStayAttraction.i18n ? 'btn-light' : 'btn-success'" @click="formStayAttraction.i18n=false">
                                                <input type="radio"> Español
                                            </label>
                                            <label class="btn btn-sm " :class="!formStayAttraction.i18n ? 'btn-light' : 'btn-success'" @click="formStayAttraction.i18n=true">
                                                <input type="radio"> Ingles
                                            </label>
                                        </div>
                                    </label>
                                    <textarea v-show="!formStayAttraction.i18n" type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formStayAttraction.description" name="description" ></textarea>
                                    <textarea v-show="formStayAttraction.i18n" type="text" class="form-control" rows="4" placeholder="Enter Description" v-model="formStayAttraction.description_i18n" name="description" ></textarea>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12" style="margin-top: 12px; margin-bottom: 5px;">
                                    <h5>
                                        <span class="badge badge-warning">
                                            Colocar monto que se cobrara con tarjeta; Y efectivo a cancelar en el lugar de atraccion
                                        </span>
                                    </h5>
                                </div>
                                <!--  -->
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-3">
                                    <label for="card_amount">Pago Tarjeta</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="formStayAttraction.card_amount"
                                    :min="0" :max="99999" :empty-value="0"
                                    :precision="2" :decimal-separator="'.'"
                                    name="card_amount"></vue-numeric>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-3">
                                    <label for="cash_amount">Restante en Efectivo</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="cashTotal"
                                    :min="0" :max="99999" :empty-value="0" disabled
                                    :precision="2" :decimal-separator="'.'"
                                    name="cash_amount"></vue-numeric>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-3">
                                    <label for="type_refund">Reembolso</label>
                                    <select v-model="formStayAttraction.type_refund" name="type_refund" class="form-control">
                                        <option value="NONE">Ninguno</option>
                                        <option value="TOTAL">Total</option>
                                        <option value="PARTIAL">Parcial</option>
                                    </select>
                                </div>
                                <div v-show="!formStayAttraction.is_free" class="form-group col-12 col-md-3">
                                    <label for="refund">Monto Reembolso</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model="formStayAttraction.refund"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'" :disabled="formStayAttraction.type_refund!='PARTIAL'"
                                    name="refund"></vue-numeric>
                                </div>
                                <!--  -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-danger" @click.prevent="onDeleteAttraction()"
                            v-if="Stay.package_attraction!=null && $can('delete', 'packages-attractions-index')"> 
                                <span>
                                    Borrar
                                </span>
                            </button> 
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-attractions-index'"> 
                                <span v-if="Stay.package_attraction!=null && $can('update', 'packages-attractions-index')">
                                    Editar
                                </span>
                                <span v-else-if="Stay.package_attraction!=null && !$can('update', 'packages-attractions-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueNumeric from 'vue-numeric';

export default {
    components: {
        VueNumeric
    },
    props: {
        Stay: {
            type : [Object, null],
            default: null,
        },
        AttractionNew: {
            type: [Object, null],
            default: null,
        }
    },
    data() {
        return {
            attraction             : null,
            attractions            : [],
            formStayAttraction: new WebForm('#formStayAttraction', {
                id                      : null,
                package_stay_id         : null,
                attraction_id           : '',
                tickets                 : 1,
                start_date              : '',
                end_date                : '',
                start_hour              : null,
                end_hour                : null,
                start_hour_a            : {"hh":"01", "mm":"00", "A":"PM"},
                end_hour_a              : {"hh":"11", "mm":"00", "A":"AM"},
                cost                    : 0,
                taxes                   : 0,
                utility                 : 0,
                total                   : 0,
                link                    : '',
                description             : '',
                description_i18n        : '',
                simbol                  : '$',
                is_free                 : null,
                non_date                : null,
                type_refund             : 'NONE',
                refund                  : 0,
                cash_amount             : 0,
                card_amount             : 0,
                i18n                    : false,
            }),
            dates       : false,
            prices      : false,
        };
    },
    watch: {
        AttractionNew: function(val, oldVal) {
            if (!_.isEmpty(this.AttractionNew)) {
                this.formStayAttraction.attraction_id = this.AttractionNew.id;
                this.getAttractions();
            }
        },
        Stay: function(val, oldVal) {
            let vm = this;
            if(vm.Stay!=null) {
                if (vm.Stay.package_attraction==null) {
                    vm.initDate();
                    vm.formStayAttraction.package_stay_id  = vm.Stay.id;
                } else {
                    let stay = vm.Stay.package_stay_attraction[vm.Stay.package_attraction];

                    vm.formStayAttraction.id               = stay.id;
                    vm.formStayAttraction.tickets          = stay.tickets;
                    vm.formStayAttraction.cost             = stay.cost;
                    vm.formStayAttraction.taxes            = stay.taxes;
                    vm.formStayAttraction.utility          = stay.utility;
                    vm.formStayAttraction.non_date         = stay.non_date;
                    vm.formStayAttraction.is_free          = stay.is_free;
                    vm.formStayAttraction.cant_service     = stay.cant_service;
                    vm.formStayAttraction.link             = stay.link;
                    vm.formStayAttraction.description      = stay.description;
                    vm.formStayAttraction.description_i18n = stay.description_i18n;
                    vm.formStayAttraction.package_stay_id  = stay.package_stay_id;
                    vm.formStayAttraction.start_date       = stay.start_date;
                    vm.formStayAttraction.end_date         = stay.end_date;
                    vm.formStayAttraction.type_refund      = stay.type_refund;
                    vm.formStayAttraction.refund           = stay.refund;
                    

                    setTimeout (() => {
                        vm.formStayAttraction.cash_amount      = stay.cash_amount;
                        vm.formStayAttraction.card_amount      = stay.card_amount;
                    }, 500);

                    if (stay.start_hour) {
                        let time = stay.start_hour;
                        vm.formStayAttraction.start_hour = time;
                        vm.formStayAttraction.start_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formStayAttraction.start_hour_a = { "hh" : "01", "mm" : "00", "A"  : "AM" }
                    }

                    if (stay.end_hour) {
                        let time = stay.end_hour;
                        vm.formStayAttraction.end_hour = time;
                        vm.formStayAttraction.end_hour_a = {
                            "hh" : (time.substring(0, 2)>12) ? time.substring(0, 2) - 12 : time.substring(0, 2) ,
                            "mm" : time.substring(3, 5),
                            "A"  : (time.substring(0, 2)>12) ? "PM" : "AM",
                        };
                    } else {
                        vm.formStayAttraction.end_hour_a = { "hh" : "11", "mm" : "59", "A"  : "PM" }
                    }

                    if(stay!=null && stay.attraction_id!="") {
                        vm.attraction = vm.attractions.find(c => {
                            return c.id == stay.attraction_id;
                        });
                    }
                }
            } else {
                
            }
        },
    },
    computed: {
        priceTotal() {
            let vm = this;
            let cost        = (vm.formStayAttraction.cost) ? parseFloat(vm.formStayAttraction.cost) : 0; 
            let taxes       = (vm.formStayAttraction.taxes) ? parseFloat(vm.formStayAttraction.taxes) : 0;
            let utility     = (vm.formStayAttraction.utility) ? parseFloat(vm.formStayAttraction.utility) : 0;
            let total = cost + taxes + utility;
            vm.formStayAttraction.card_amount = total;
            return total;
        },
        cashTotal() {
            let vm = this;
            let card = (vm.formStayAttraction.card_amount) ? parseFloat(vm.formStayAttraction.card_amount) : 0; 
            let totalCash = 0;
            if(vm.priceTotal >= card) {
                totalCash = vm.priceTotal - card;
            } else {
                card = vm.priceTotal;
                totalCash = vm.priceTotal - card;
                vm.formStayAttraction.card_amount = card;
            }
            vm.formStayAttraction.cash_amount = totalCash;

            if(vm.formStayAttraction.type_refund=="TOTAL") {
                vm.formStayAttraction.refund = vm.priceTotal;
            } else if(vm.formStayAttraction.type_refund=="NONE") {
                vm.formStayAttraction.refund = 0;
            }
            return totalCash;
        }
    },
    methods: {
        isValidCss(id, is_valid=true) {
            if(is_valid) {
                $("#"+id).removeClass("is-invalid");
            } else {
                if (!$("#"+id).hasClass("is-invalid")) {
                    $("#"+id).addClass("is-invalid");
                }
            }
        },
        changeView() {
            let vm = this;
            vm.dates = vm.formStayAttraction.non_date;
            vm.prices = vm.formStayAttraction.is_free;
        },
        calcPrice() {
            let vm = this;

            let cost        = parseFloat(vm.formStayAttraction.cost); 
            let taxes       = parseFloat(vm.formStayAttraction.taxes);
            let utility     = parseFloat(vm.formStayAttraction.utility);
            let type        = parseFloat(vm.formStayAttraction.type_utility);

            let total = 0;
            if(type==1) {
                total = (utility>0) ? cost + taxes + (cost * (utility/100)) : cost + taxes;
                vm.formStayAttraction.total = '$ ' + total;
                vm.formStayAttraction.simbol = '%';
            } else if(type==2) {
                total = cost + taxes + utility;
                vm.formStayAttraction.total = '$ ' + total;
                vm.formStayAttraction.simbol = '$';
            }

            let card_amount = 0 + parseFloat(vm.formStayAttraction.card_amount); 
            let cash_amount = 0 + parseFloat(vm.formStayAttraction.cash_amount);
            let type_refund = 0 + parseFloat(vm.formStayAttraction.type_refund);
            let refund      = 0 + parseFloat(vm.formStayAttraction.refund);

            if (type_refund==1)
                 vm.formStayAttraction.refund = 0;

            if (type_refund==2)
                 vm.formStayAttraction.refund = card_amount;

            if ((card_amount != 0 || cash_amount != 0 ) && total > 0) { 
                if(card_amount > 0 && card_amount <= total && (cash_amount == 0 || cash_amount == null) ) {
                    vm.formStayAttraction.cash_amount = total - card_amount;
                } else if (cash_amount > 0 && cash_amount <= total && (card_amount == 0 || card_amount == null) ) {
                    vm.formStayAttraction.card_amount = total - cash_amount;
                } else {
                    if((cash_amount + card_amount) != total) {
                        vm.$snotify.warning('Verifique los montos de tarjeta y efectivo');
                        vm.isValidCss('formStayAttraction__cash_amount', false);
                        vm.isValidCss('formStayAttraction__card_amount', false);
                        return false;
                    }
                }
                vm.isValidCss('formStayAttraction__cash_amount');
                vm.isValidCss('formStayAttraction__card_amount');
            } else {
                vm.$snotify.warning('Verifique los montos de tarjeta y efectivo');
                vm.isValidCss('formStayAttraction__cash_amount', false);
                vm.isValidCss('formStayAttraction__card_amount', false);
            }
            if( total > 0 ) {
                vm.isValidCss('formStayAttraction__cost');
                return true;
            } else {
                vm.$snotify.warning('Verifique costos de estadia');
                vm.isValidCss('formStayAttraction__cost', false);
                return false;
            }
        },
        onStayAttraction() {
            let vm = this;

            if(vm.attraction && vm.attraction.id) {
                vm.formStayAttraction.attraction_id = vm.attraction.id;
            }

            if(vm.formStayAttraction.start_hour_a) {
                let time = vm.formStayAttraction.start_hour_a;
                vm.formStayAttraction.start_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            if(vm.formStayAttraction.end_hour_a) {
                let time = vm.formStayAttraction.end_hour_a;
                vm.formStayAttraction.end_hour = (time.A=='PM') ? (parseInt(time.hh) + parseInt(12)) + ':' + time.mm : time.hh + ':' + time.mm ;
            }

            if(vm.formStayAttraction.is_free) {
                vm.formStayAttraction.is_free = 1;
            }

            if(vm.formStayAttraction.non_date) {
                vm.formStayAttraction.non_date = 1;
            }

            if(this.formStayAttraction.id != null) {
                $.confirm({
                    title: `Editar Atraccion de Estadia`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.saveStayAttraction();
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.saveStayAttraction();
            }
        },
        saveStayAttraction() {
            let vm = this;

            vm.formStayAttraction.post('/admin/packages/hotels/attractions/save').then(response => {
                let data = response.data;
                if(data.success) {
                    vm.$emit('reloadPackageHotelStay', vm.Stay.package_hotel_id);
                    let snotify_message = vm.Stay.package_service == null ? 'Atraccion agregada con exito.' : 'Atraccion Actualizada con exito.';
                    vm.$snotify.success(snotify_message);
                    $('#addStayAttractionModal').modal('hide');
                    vm.formStayAttraction.clear();
                    vm.formStayAttraction.reset();
                    return;
                }
                
                vm.$snotify.info(`Algo resulto mal...`)
            });
        },
        onDeleteAttraction() {
            let vm = this;
            if(this.formStayAttraction.id != null) {
                $.confirm.delete(
                    'Borrar atraccion de Estadia',
                    '¿Seguro que quiere <b>borrar</b> esta atraccion?',
                    function(){
                        vm.deleteStayAttraction();
                    }
                )
            }
        },
        deleteStayAttraction() {
            let vm = this;
            let idDel = vm.formStayAttraction.id;
            axios.post('/admin/packages/hotels/attractions/delete', { id: idDel }).then(response => {
                let data = response.data;
            
                if(!data.success) {
                    vm.$snotify.warning('Ups. ocurrio un error');
                    return
                } 

                if(data.success) {
                    vm.$emit('reloadPackageHotelStay', vm.Stay.package_hotel_id);
                    vm.$snotify.success('La atraccion se elimino con exito.');
                    $('#addStayAttractionModal').modal('hide');
                    vm.formStayAttraction.clear();
                    vm.formStayAttraction.reset();
                    return
                } 

                vm.$snotify.info('Se ha enviado solicitud') 

            }).catch(err => console.log(err));
        },
        getAttractions() {
            let vm = this;

            // let endpoint = '/admin/attractions/all';

            axios.get('/admin/attractions/all').then(({data}) => {
                let attractions = data.map(l => {
                    let attraction = l
                    let d = {
                        id: attraction.id,
                        name: attraction.name,
                        label: `${attraction.name}, ${attraction.city.name}`
                    }
                    return d
                });
                this.attractions = attractions;

                this.attractions.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });

                if(vm.formStayAttraction.attraction_id && vm.formStayAttraction.attraction_id != "" ) {
                    vm.attraction = vm.attractions.find(c => {
                        return c.id == vm.formStayAttraction.attraction_id;
                    });
                }
            });
        },
        intTwoChar(val) {
            if(val>9) {
                return val;
            } else {
                return '0'+val;
            }
        },
        initDate() {
            let vm = this;
            if(vm.Stay.package_service==null) {
                // colocamos como fecha de expiracion 3 dias despues de hoy
                let hoy = new Date();
                var devolucion = new Date();
                devolucion.setDate(hoy.getDate() + 3);
                vm.formStayAttraction.start_date = hoy.getUTCFullYear() + '-' + vm.intTwoChar(hoy.getMonth() + 1) + '-' + vm.intTwoChar(hoy.getDate());
                vm.formStayAttraction.end_date = devolucion.getUTCFullYear() + '-' + vm.intTwoChar(devolucion.getMonth() + 1) + '-' + vm.intTwoChar(devolucion.getDate());
            }
        }
    },
    mounted() {
        let vm = this;

        vm.getAttractions();

        $('#addStayAttractionModal').off('hidden.bs.modal');
        $('#addStayAttractionModal').on('hidden.bs.modal', function () {
            vm.formStayAttraction.clear();
            vm.formStayAttraction.reset();
            vm.changeView();
            vm.$emit('closeStayAttractionModal');
        });

        // console.log('StayAttractioneModal mounted.');
    }
};
</script>
<style>
    .modal#addStayAttractionModal{
        overflow-y: auto !important;
    }
</style>