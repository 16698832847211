<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Ciudades 
                    </h5>
                    <p class="text-muted mb-1">Lista de Ciudades del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addCity" >
                        <span class="icon-add-2 mr-2"></span> Nueva Ciudad
                    </button>
                </div>
            </div>
            
            <city-modal :City="City" :StateNew="StateNew" v-on:closeCityModal="clearCityVar()" v-on:reloadCityModal="reloadCity()"></city-modal>

            <state-modal v-on:reloadStateModal="reloadState"></state-modal>

            <data-table :ajax="{ url: '/admin/location/cities/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="cityTable" :serverSide="true" :columns="dt.columns"></data-table>

        </div>
    </div>
</template>
<script>

    import dataTable from '~/components/dataTable'
    import CityModal from '~/components/geodatos/CityModal';
    import StateModal from '~/components/geodatos/StateModal';


    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            CityModal,
            StateModal,
        },
        metaInfo() {
            return {
                title: "Ciudades del Sistema"
            }
        },
        data() {
            return {
                City        : null,
                StateNew    : null,
                dt: {
                    columns : [
                        { title: 'Id',              data: 'id', orderable: true, searchable: true,},
                        { title: 'Nombre Ciudad',   data: 'name', orderable: true, searchable: true,},
                        { title: 'Division',          data: 'state.geographical_division', orderable: true, searchable: true},
                        { title: 'Estado',          data: 'state.name', orderable: true, searchable: true},
                        { title: 'Pais',          data: 'state', orderable: false, searchable: false,
                        render: function ( data, type, row, meta) {
                            return  `<img src="https://www.countryflags.io/${data.country.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.country.short_iso_code}">
                                    ${data.country.name}`;
                        }},
                        { title: 'Actions',       data: 'id', orderable: false, searchable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#addCity">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            };
        },
        methods: {
            reloadState(obj) {
                this.StateNew = null;
                this.StateNew = obj;
                setTimeout(function(){
                    this.StateNew = null;
                }, 1000);
            },
            reloadCity() {
                this.$refs.cityTable.ajaxReload();
            },
            clearCityVar() {
                this.City = null;
            },
            onDelete(city) {
                let vm = this;
                $.confirm.delete(
                    'Eliminar Ciudad',
                    `¿Está seguro que desea eliminar la Ciudad <u>${city.name}</u>?`, 
                    function(){
                        vm.deleteCity(city.id)
                    }
                )
            },
            deleteCity (city_id) {
                let vm = this
                axios.post('/admin/location/cities/delete', { id: city_id }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    }
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.cityTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 
                })
            },
            probar() {
                axios.get('/admin/location/cities/all').then(response => {
                    let data = response.data;
                    console.log('>>>>VIENE>>>>', data);
                });
            }
        },
        mounted() {
            let vm = this;

            $(vm.$el).off("click.city", ".btn-delete");
            $(vm.$el).on("click.city", ".btn-delete",function(){
                let city = vm.$refs.cityTable.rowData($(this).closest('tr'));
                vm.onDelete(city);
            });

            $(vm.$el).off("click.city", ".btn-edit");
            $(vm.$el).on("click.city", ".btn-edit", function(e){
                let city = vm.$refs.cityTable.rowData($(this).closest('tr'))
                vm.City = city;
            });
        },
        destroyed() {
            let vm = this;
            $(vm.$el).off("click.city", ".btn-delete");
            $(vm.$el).off("click.city", ".btn-edit");
        }
    }

</script>