<template>
    <div>

        <div class="d-flex justify-content-between">
            <div class="col-md-6">
                <h5 class="font-weight-bold mb-0" @click="ver()">Administración de Paquete Turistico</h5>
                <p class="text-muted mb-1">Detalles del Paquete</p>
            </div>
            <div class="col-md-6 text-right">
                <div class="form-group col-12">
                    <button class="btn btn-secondary center-content d-inline-block"  @click.prevent="goBack()">
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button>
                    <a :href="'/voucher/package/'+packId" target="_blank" class="btn btn-secondary center-content d-inline-block">
                        <span class="icon-print mr-2"></span> Imprimir
                    </a>
                    <button class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addPackageHotel" >
                        <span class="icon-add-2 mr-2"></span> Agregar Hotel
                    </button>
                </div>
            </div>
        </div>

        <!-- Solo sirve para colocar un fondo negro con icono de carga -->
        <loading-modal></loading-modal>

        

        <!-- Muestra el resumen del puete -->
        <package-resumen :myPackage="myPackage" v-on:onEditPackage="onEditPackage()" v-on:onChange="changeDisplay()"></package-resumen>

        <!-- Para mostrar galeria de hoteles -->
        <hotel-gallery-modal :Gallery="Gallery" v-on:closeHotelGalleryModal="clearVarGallery()"></hotel-gallery-modal>

        <!-- Para mostrar galeria de atracciones -->
        <attraction-gallery-modal :Gallery="GalleryAttraction" v-on:closeAttractionGalleryModal="clearVarGalleryAttraction()"></attraction-gallery-modal>

        <!-- Muestra formulario para agregar un nuevo hotel al paquete o cambiar un hotel por otro -->
        <package-hotel-modal :myPackage="myPackage" :resetHotel="resetHotel"
        v-on:reloadPackageHotel="reloadPackageHotel()" 
        v-on:closePackageHotelModal="closePackageHotelModal()">
        </package-hotel-modal>

        <!-- Muestra formulario para agregar un estadia de hotel al paquete o cambiar una estadia hotel -->
        <package-hotel-stay-modal :PackageHotel="PackageHotel"
        v-on:reloadPackageHotelStay="reloadPackageHotelStay"
        v-on:closePackageHotelStayModal="closePackageHotelStayModal"
        v-on:addNewHotelRoom="addNewHotelRoom" :HotelRommAdded="HotelRommAdded">
        </package-hotel-stay-modal>

        <!-- Muestra formulario para agregar un servicio a la estadia del hotel -->
        <stay-service-modal :Stay="Stay"
        v-on:closeStayServiceModal="closeStayServiceModal"
        v-on:reloadPackageHotelStay="reloadPackageHotelStay"
        :PackageServiceNew="PackageService">
        </stay-service-modal>

        <package-service-modal v-on:reloadServiceModal="reloadPackageService"></package-service-modal>


        <!-- Muestra formulario para agregar un atraccion a la estadia del hotel -->
        <stay-attraction-modal :Stay="StayAttraction"
        v-on:closeStayAttractionModal="closeStayAttractionModal"
        v-on:reloadPackageHotelStay="reloadPackageHotelStay"
        :AttractionNew="Attraction">
        </stay-attraction-modal>

        <attraction-modal
        v-on:reloadAttraction="reloadAttraction"
        :AttractionCategoryNew="AttractionCategory" 
        :StateNew="State" 
        :CityNew="City">
        </attraction-modal>

        <attraction-category-modal 
        v-on:reloadAttractionCategoryModal="reloadAttractionCategory">
        </attraction-category-modal>
        
        <!-- Mostrara los hoteles que posee este paquete -->
        <package-hotel-component v-for="(hotelPack, index) in hotelPackages" :key="index" 
        :hotelPack="hotelPack" :indexKey="index"
        v-on:HotelPackDelete="HotelPackDelete" v-on:displayLoadModal="displayLoadModal"
        v-on:addPackageHotelStay="addPackageHotelStay" :reloadId="reloadId"
        v-on:viewGalery="viewGalery" v-on:viewGalleryAttraction="viewGalleryAttraction" 
        v-on:editHotelIndex="editHotelIndex"
        v-on:addStayService="addStayService"
        v-on:addStayAttraction="addStayAttraction">
        </package-hotel-component>

        <hotel-room-modal :Hotel="Hotel" v-on:closeHotelRoomModal="clearHotelVar()"
        v-on:reloadHotelRoom="createdHotelRoom" v-on:createdHotelRoom="createdHotelRoom">
        </hotel-room-modal>

        <package-modal :MyPackage="myPackageEdit" v-on:closePackageModal="clearPackageEdit()" v-on:reloadPackage="getPackage()"
        :PackageCategoryNew="PackageCategory" :StateNew="State" :CityNew="City"
        ></package-modal>

        <hotel-modal :Hotel="Hotel" v-on:closeHotelModal="clearHotelVar()" 
        v-on:reloadHotel="reloadHotel()" :HotelCategoryNew="HotelCategory"
        :StateNew="State" :CityNew="City" :HotelServiceNew="HotelService">
        </hotel-modal>

        <!-- Modals Inner Others Modals -->
        <hotel-category-modal v-on:reloadHotelCategoryModal="reloadHotelCategory"></hotel-category-modal>
        <hotel-service-modal v-on:reloadHotelServiceModal="reloadHotelService"></hotel-service-modal>
        <package-category-modal v-on:reloadPackageCategoryModal="reloadPackageCategory"></package-category-modal>


        <state-modal v-on:reloadStateModal="reloadState"></state-modal>
        <city-modal v-on:reloadCityModal="reloadCity"></city-modal>
        <!--  -->

    </div>
</template>
<script>
    
    import { isNull } from 'util';
    
    import LoadingModal from '~/components/packages/LoadingModal';

    import PackageModal from '~/components/packages/PackageModal';

    import PackageResumen from '~/components/packages/listeds/PackageResumen';
    import PackageHotelModal from '~/components/packages/listeds/PackageHotelModal';
    import PackageHotelStayModal from '~/components/packages/listeds/PackageHotelStayModal';
    import PackageHotelComponent from '~/components/packages/listeds/PackageHotelComponent';

    import StayServiceModal from '~/components/packages/listeds/StayServiceModal';
    import PackageServiceModal from '~/components/packages/PackageServiceModal';
    
    import StayAttractionModal from '~/components/packages/listeds/StayAttractionModal';
    import AttractionModal from '~/components/packages/AttractionModal';
    import AttractionCategoryModal from '~/components/packages/AttractionCategoryModal';

    import HotelGalleryModal from '~/components/packages/HotelGalleryModal';
    import AttractionGalleryModal from '~/components/packages/AttractionGalleryModal';

    import HotelModal from '~/components/packages/HotelModal';
    import HotelRoomModal from '~/components/packages/HotelRoomModal';

    import HotelCategoryModal from '~/components/packages/HotelCategoryModal';
    import StateModal from '~/components/geodatos/StateModal';
    import CityModal from '~/components/geodatos/CityModal';
    import HotelServiceModal from '~/components/packages/HotelServiceModal';
    import PackageCategoryModal from '~/components/packages/PackageCategoryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        route: {
            //Habia un conflicto con las rutas base
            //validacion del parametro numerico con regex
            validate: {
                id: '\\d+'
            }
        },
        components: {
            LoadingModal,
            PackageModal,
            PackageResumen,
            PackageHotelModal,
            PackageHotelStayModal,
            StayServiceModal,
            PackageServiceModal,
            StayAttractionModal,
            AttractionModal,
            AttractionCategoryModal,
            PackageHotelComponent,
            HotelGalleryModal,
            AttractionGalleryModal,

            HotelModal,
            HotelRoomModal,

            HotelCategoryModal,
            StateModal,
            CityModal,
            HotelServiceModal,
            PackageCategoryModal,
        },
        metaInfo() {
            return {
                title: "Administración de Paquete"
            }
        },
        data() {
            return {
                PackageService      : null,
                Hotel               : null,
                PackageCategory     : null,
                HotelCategory       : null,
                Attraction          : null,
                AttractionCategory  : null,
                State               : null,
                City                : null,
                HotelService        : null,
                HotelRommAdded  : null,     // Sirve para almacenar el valor del nuevo objeto creado

                resetHotel      : false,

                myPackage       : [],
                myPackageEdit   : null,
                thisHotel       : null,

                loadModal       : false,
                load            : false,
                loadAdd         : false,
                packId          : null,


                PackageHotel    : null,
                
                hotelPackages   : [],

                reloadId        : null,

                Gallery           : null,
                GalleryAttraction : null,

                Stay            : null,
                StayAttraction  : null,

                meses: ['','Enero','Febrero','Marzo','Abril','Mayo','Junio',
                'Julio', 'Agosto', 'Septiembre','Octubre','Noviembre','Diciembre'],
                // Inicio Formularios
                formHotelPack: new WebForm('#formAddHotel', {
                    id                      : null,
                    package_id              : '', 
                    hotel_id                : '',
                    airport_transportation  : '',
                    they_allow_pets         : '',
                    all_inclusive           : '',
                    errors                  : [],
                }),
                formHotelChange: new WebForm('#formHotelChange', {
                    id                      : null, 
                    hotelChange             : null,
                    loadChange              : false,
                    loadSaveChange          : false,
                    hotel_id                : '',
                    errors                  : [],
                }),
                formForStay: new WebForm('', {
                    id                      : null,
                    package_hotel_id        : null,
                    room                    : null,
                    cant_room               : 1,
                    room_id                 : '',
                    start_date              : '',
                    end_date                : '',
                    start_hour              : null,
                    end_hour                : null,
                    n_people                : 1,
                    cost                    : '',
                    taxes                   : '',
                    type_utility            : 2,
                    utility                 : '',
                    total                   : 0,
                    link                    : '',
                }),
                formForStayService: new WebForm('', {
                    id                      : null,
                    service                 : null,
                    cant_service            : 1,
                    description             : '',
                    start_date              : '',
                    start_hour              : null,
                    end_date                : '',
                    end_hour                : null,
                    start_hour_f            : null,
                    end_hour_f              : null,
                    is_free                 : 0,
                    non_date                : 0,
                    cost                    : '',
                    taxes                   : '',
                    type_utility            : 2,
                    utility                 : '',
                    total                   : 0,
                    link                    : '', 
                    package_stay_id         : null,
                    package_service_id      : null,
                }),
                formForStayAttraction: new WebForm('#formStayAttraction'+this._uid, {
                    id                      : null,
                    attraction              : null,
                    tickets                 : 0,
                    description             : '',
                    start_date              : '',
                    start_hour              : '',
                    end_date                : '',
                    end_hour                : '',
                    is_free                 : 0,
                    non_date                : 0,
                    cost                    : '',
                    taxes                   : '',
                    type_utility            : 2,
                    utility                 : '',
                    total                   : 0,
                    link                    : '', 
                    package_stay_id         : null,
                    attraction_id           : null,
                }),
                // Fin formularios
            };
        },
        watch: {

        },
        methods: {
            reloadAttraction(obj) {
                this.Attraction = null;
                this.Attraction = obj;
                setTimeout(function(){
                    this.Attraction = null;
                }, 1000);
            },
            reloadAttractionCategory(obj) {
                this.AttractionCategory = null;
                this.AttractionCategory = obj;
                setTimeout(function(){
                    this.AttractionCategory = null;
                }, 1000);
            },
            reloadPackageService(obj) {
                this.PackageService = null;
                this.PackageService = obj;
                setTimeout(function(){
                    this.PackageService = null;
                }, 1000);
            },
            reloadPackageCategory(obj) {
                this.PackageCategory = null;
                this.PackageCategory = obj;
                setTimeout(function(){
                    this.PackageCategory = null;
                }, 1000);
            },
            reloadHotelCategory(obj) {
                this.HotelCategory = null;
                this.HotelCategory = obj;
                setTimeout(function(){
                    this.HotelCategory = null;
                }, 1000);
            },
            reloadState(obj) {
                this.State = null;
                this.State = obj;
                setTimeout(function(){
                    this.State = null;
                }, 1000);
            },
            reloadCity(obj) {
                this.City = null;
                this.City = obj;
                setTimeout(function(){
                    this.City = null;
                }, 1000);
            },
            reloadHotelService(obj) {
                this.HotelService = null;
                this.HotelService = obj;
                setTimeout(function(){
                    this.HotelService = null;
                }, 1000);
            },
            changeDisplay() {
                const vm = this;
                let titulo = '';
                let desc = '';
                let clase = '';
                let textBoton = '';
                let val = '';
                if (vm.myPackage.state_package == 'PUBLISHED' ) {
                    titulo = 'Cambiar a Borrador';
                    desc = `¿Desea cambiar este paquete a modo <b>'Borrador'</b>?`;
                    clase = 'btn-primary';
                    textBoton = 'Continuar';
                    val = 'DRAFT';
                } else if(vm.myPackage.state_package == 'DRAFT' ) {
                    titulo = 'Publicar Paquete';
                    desc = `¿Desea <b>'Publicar'</b> este paquete ahora?`;
                    clase = 'btn-primary';
                    textBoton = 'Continuar';
                    val = 'PUBLISHED';
                } else {
                    return;
                }
                $.confirm({
                    title: titulo,
                    content: desc,
                    autoClose: 'Cancel|5000',
                    buttons: {
                        Editar:{
                            text: textBoton,
                            btnClass: clase,
                            keys: ['enter'],
                                action: function() {
                                    vm.changeState(vm.myPackage.id, val);
                                }
                        },
                        Cancel: {
                            text: `Cancelar`,
                        },
                    }
                });
            },
            changeState(id_change, val) {
                let vm = this;
                axios.post('/admin/packages/change', { id: id_change , val: val }).then(response => {
                    let data = response.data;
                    if(data.success) {
                        vm.$snotify.success('Cambio de estado exitoso.');
                        vm.myPackage.state_package = val;
                        return;
                    }
                    vm.$snotify.info('Se ha enviado solicitud');
                }).catch(err => console.log(err));
            },
            createdHotelRoom(data) {
                let vm = this;
                vm.HotelRommAdded = data;
                setTimeout (() => {
                    vm.HotelRommAdded = null;
                }, 1500);
            },
            addNewHotelRoom(hotel) {
                hotel.hotel_room_idx = null;
                this.Hotel = hotel;
            },
            editHotelIndex(obj) {
                this.Hotel = this.hotelPackages[obj.idx].hotel;
            },
            clearHotelVar() {
                this.Hotel = null;
            },
            reloadHotel() {
                this.resetHotel = true;
                setTimeout (() => {
                    this.resetHotel = false;
                }, 200);
                this.getPackageHotel();
            },
            onEditPackage() {
                this.myPackageEdit = this.myPackage;
            },
            clearPackageEdit() {
                this.myPackageEdit = null;
            },
            // Para StayService ----------------
            closeStayServiceModal() {
                this.Stay = null;
            },
            addStayService(obj) {
                this.Stay = obj.stay;
            },
            // ---------------------------------
            // Para StayAttraction ----------------
            closeStayAttractionModal() {
                this.StayAttraction = null;
            },
            addStayAttraction(obj) {
                this.StayAttraction = obj.stay;
            },
            // ---------------------------------
            goBack: function () {
                window.history.back();
            },
            ver() {
                // 
            },
            viewGalery(id) {
                this.Gallery = id;
            },
            viewGalleryAttraction(id) {
                this.GalleryAttraction = id;
            },
            clearVarGallery() {
                this.Gallery = null;
            },
            clearVarGalleryAttraction() {
                this.GalleryAttraction = null;
            },
            ChangeHotel(obj) {
                this.thisHotel = obj;
            },
            HotelPackDelete(obj) {
                this.hotelPackages.splice(obj, 1);
                setTimeout (() => {
                    this.displayLoadModal(false);
                }, 200);
            },
            closePackageHotelModal() {
                this.thisHotel = null;
            },
            reloadPackageHotel() {
                this.getPackageHotel();
            },
            displayLoadModal(state) {
                if(state) {
                    $('#modalLoading').modal('show');
                } else {
                    $('#modalLoading').modal('hide');
                }
            },
            addPackageHotelStay(obj) {
                this.PackageHotel = obj;
            },
            reloadPackageHotelStay(obj) {
                let vm = this;
                vm.reloadId = obj;
                setTimeout (() => {
                    vm.reloadId = null;
                }, 2000);
            },
            closePackageHotelStayModal() {
                this.PackageHotel = null;
            },
            duplyPack(pack) {
                let vm = this;

                vm.loadModal = true;

                vm.formHotelPack.clear();
                vm.formHotelPack.reset();

                vm.formHotelPack.id                         = null;

                vm.formHotelPack.hotel_id                   = pack.hotel_id;
                vm.formHotelPack.package_id                 = pack.package_id;
                vm.formHotelPack.airport_transportation     = pack.airport_transportation;
                vm.formHotelPack.they_allow_pets            = pack.they_allow_pets;
                vm.formHotelPack.all_inclusive              = pack.all_inclusive;

                vm.formHotelPack.post('/admin/packages/hotels/save').then(async response => {
                    if (!response.data.success) 
                        return false;

                    let hotelPack = response.data.success;

                    let endpoint = `/admin/packages/hotels/stays/allbyid/${pack.id}`;
                    await new Promise((resolve, reject) => {
                        axios.get(endpoint).then(({data}) => {
                            for (let indexStay = 0; indexStay < data.length; indexStay++) {
                                const elementStay = data[indexStay];
                                vm.formForStay.fill(elementStay);

                                vm.formForStay.id                = null;
                                vm.formForStay.package_hotel_id  = hotelPack.id;
                                vm.formForStay.start_hour        = (elementStay.start_hour) ? elementStay.start_hour.substring(0, 5) : null;
                                vm.formForStay.end_hour          = (elementStay.end_hour) ? elementStay.end_hour.substring(0, 5) : null;
                                
                                let promises = [];
                                vm.formForStay.post('/admin/packages/hotels/stays/save').then(responseStay => {
                                    if (responseStay.data.success) {
                                        let stay = responseStay.data.success;

                                        for (let indexService = 0; indexService < elementStay.package_stay_service.length; indexService++) {
                                            const myService = elementStay.package_stay_service[indexService];
                                            vm.formForStayService.fill(myService);

                                            vm.formForStayService.package_stay_id   = stay.id;
                                            vm.formForStayService.start_hour        = (myService.start_hour) ? myService.start_hour.substring(0, 5) : null ;
                                            vm.formForStayService.end_hour          = (myService.end_hour) ? myService.end_hour.substring(0, 5) : null ;
                                            vm.formForStayService.id = null;
                                            let process = vm.formForStayService.post('/admin/packages/hotels/services/save').then(response => { 
                                                vm.formForStayService.clear();
                                                vm.formForStayService.reset();
                                            });

                                            promises.push(process);
                                        }


                                        for (let indexAttraction = 0; indexAttraction < elementStay.package_stay_attraction.length; indexAttraction++) {
                                            const myAttraction = elementStay.package_stay_attraction[indexAttraction];
                                            vm.formForStayAttraction.fill(myAttraction);

                                            vm.formForStayAttraction.package_stay_id   = stay.id;
                                            vm.formForStayAttraction.start_hour        = (myAttraction.start_hour) ? myAttraction.start_hour.substring(0, 5) : null ;
                                            vm.formForStayAttraction.end_hour          = (myAttraction.end_hour) ? myAttraction.end_hour.substring(0, 5) : null ;
                                            vm.formForStayAttraction.id = null;
                                            let process = vm.formForStayAttraction.post('/admin/packages/hotels/attractions/save').then(response => { 
                                                vm.formForStayAttraction.clear();
                                                vm.formForStayAttraction.reset();
                                            });

                                            promises.push(process)
                                        }

                                        Promise.all(promises).then(function(values) { 
                                            resolve()
                                        });
                                    }
                                    
                                    vm.formForStay.clear();
                                    vm.formForStay.reset();
                                });
                            }
                        });
                    })

                    vm.formHotelPack.reset();
                    vm.formHotelPack.clear();
                    vm.getPackageHotel();
                    vm.loadModal = false;
                    vm.$snotify.simple('Accion realizada exitosamente.');

                });

            },
            getPackageHotel() {
                let vm = this;
                vm.loadAdd = true;
                let endpoint = `/admin/packages/hotels/all/${vm.myPackage.id}`;
                
                axios.get(endpoint).then(response => {
                    let hotelPackages = response.data;
                    vm.hotelPackages = hotelPackages;
                    
                }).catch(err => {
                    console.log('Ocurrio un error al obtener los hoteles');
                }).finally(() => {
                    vm.loadAdd = false;
                });
            },
            getPackage(){
                let vm = this;
                let endpoint = `/admin/packages/${vm.packId}/all`;
                axios.get(endpoint).then(response => {
                    let packageRes = response.data;
                    vm.myPackage = packageRes;
                    vm.getPackageHotel();
                });
            },
        },
        mounted() {
            let vm = this;
            vm.packId = vm.$route.params.id;
            vm.getPackage();
        },
        destroyed() {
            
        }
    }

</script>

<style scoped>
    .div-hotel-name .icon-change {
        display: none;
    }
    .div-hotel-name:hover .icon-change {
        display: inline-block;
        cursor: pointer;
    }
    label.optionHotel {
        display: block !important;
    }
</style>
