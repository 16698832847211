<template>
    <div class="modal fade" ref="cropModal" id="cropProfilePhotoModal" tabindex="-1" role="dialog" aria-labelledby="cropProfilePhotoModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="cropProfilePhotoModalLabel">Imagen de Perfil</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0">
                    <croppa v-model="userCroppa"

                        @image-remove="controls = false"
                        @file-choose="fileLoaded"

                        :width="500"
                        :height="500"
                        :show-remove-button="false"
                        :prevent-white-space="true"
                        :show-loading="true"
                        :loading-size="50"
                        :placeholder-font-size="16"
                        placeholder="Seleccionar imagen...">
                            <!-- <img crossOrigin="anonymous" v-if="url" :src="url" slot="initial"> -->
                    </croppa>

                    <div v-if="controls" class="controls">
                        <button class="center-content m-2" @click="userCroppa.remove()"><span class="icon-bin"></span></button>
                        <button class="center-content m-2" @click="userCroppa.rotate(-1)"><span class="icon-reload"></span></button>
                        <button class="center-content m-2" @click="userCroppa.zoomIn()"><span class="icon-add-2"></span></button>
                        <button class="center-content m-2" @click="userCroppa.zoomOut()"><span class="icon-subtract"></span></button>
                    </div>

                </div>
                <div class="modal-footer py-2">
                    <button type="button" class="btn btn-sm" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-sm btn-primary" :class="{ 'btn-loading' : loading}" @click="savePhoto">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    'use strict'

    import { mapGetters } from 'vuex'
    import 'vue-croppa/dist/vue-croppa.css';
    import Vue from 'vue';
    import Croppa from 'vue-croppa';

    Vue.use(Croppa);

    export default {
        data(){
            return {
                userCroppa: {},
                controls : true,
                loading: false
            }
        },
        computed: mapGetters({
            user : 'auth/user'
        }),
        methods: {
            fileLoaded(){
                this.controls = true
                $(this.$refs.cropModal).modal('show');
            },
            chooseFile(){
                this.userCroppa.chooseFile()
            },
            savePhoto(){
                let vm = this
                let formData = new FormData()
                formData.append('image', this.userCroppa.generateDataUrl('image/png', 0.8))
                formData.append('uid', this.user.id)
                axios.post('/admin/user/upload/image/base64', formData, {
                    headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        // this.$emit('change', this.newImage)
                        vm.$snotify.simple('Se actualizó la imagen del usuario.')
                        // if(this.sessid !== this.uid) return
                        this.$store.dispatch('auth/updateUserImage', {
                            image:  response.data.image_url || '/img/none.png'
                        })

                        $(this.$refs.cropModal).modal('hide');
                    })
                .catch(error => {
                    //console.log(error.response)
                })
            }
        },
        mounted: function () {

            let vm = this
            $("#cropProfilePhotoModal").off("hidden.bs.modal");
            $("#cropProfilePhotoModal").on("hidden.bs.modal", function(){
                vm.userCroppa.remove()
            });
        }
    }
</script>

<style>
    .croppa-container canvas{
        width: 100% !important;
        height: 100% !important;
        border: 1px solid rgba(0,0,0,.05);
    }
    /* @media (max-width: 575px) {
        .croppa-container canvas{
            width: 100% !important;
            height: 100% !important;
        }
    } */
    .controls {
        position: absolute;
        bottom: 10px;
    }
    .controls button{
        outline: none;
        width: 35px;
        height: 35px;
        border: none;
        color: #fff;
        background-color: rgba(0,0,0,.7);
        background-repeat: no-repeat;
        background-size: 50% 50%;
        background-position: 50%;
        border-radius: 50%;
        cursor: pointer;
    }
</style>
