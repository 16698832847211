
import axios from 'axios'

// Mutation Types
export const LOGOUT             = 'LOGOUT'
export const LOGIN              = 'LOGIN'
export const UPDATE_USER        = 'UPDATE_USER'
export const UPDATE_USER_IMAGE  = 'UPDATE_USER_IMAGE'

//State
export const state = {
    user: JSON.parse(sessionStorage.getItem('pavolar_user') || 'null'),
    displayName: capitalize(getDisplayName(JSON.parse(sessionStorage.getItem('pavolar_user') || 'null'))),
}

// Mutations
export const mutations = {

    [LOGIN] (state, { user, remember}) {
        state.user = user
        state.displayName = capitalize(getDisplayName(user))
        sessionStorage.setItem('pavolar_user', JSON.stringify(user))
    },

    [LOGOUT] (state) {
        state.user = null
        state.displayName = null
        sessionStorage.removeItem('pavolar_user')
        sessionStorage.removeItem('pavolar_menu')
        sessionStorage.removeItem('pavolar_permission')
        sessionStorage.removeItem('pavolar_analytics')
    },
    
    [UPDATE_USER] (state, { user }) {
        state.user = user
        state.displayName = capitalize(getDisplayName(user))
        sessionStorage.setItem('pavolar_user', JSON.stringify(user))
    },

    [UPDATE_USER_IMAGE] (state, { image }) {
        if(state.user == null)
            return

        state.user.photo = image
    }
}

// Actions
export const actions = {

    login ({ commit, dispatch }, payload) {
        commit(LOGIN, payload)
    },

    async logout ({ commit }) {
        return await axios.post('/admin/account/logout').then( res => {
            commit(LOGOUT)
        })
    },

    async fetchUser ({ commit, state }) {

        const { data } = await axios.get('/admin/account/user/check')
        
        if(!data){
            state.user = null
            sessionStorage.removeItem('pavolar_user')
        }

        //Si el usuario no esta logeado no intenta cerrar sesion
        if(!data || state.user != null)
            return;

      try {
            const { data } = await axios.get('/admin/account/user')
            commit(UPDATE_USER, { user: data })
        } catch (e) {

            if(e.response.status == 401)
                throw e.response.status;
            else
                console.log(e)
        }
    },

    updateUser ({ commit }, payload) {
        commit(UPDATE_USER, payload)
    },

    updateUserImage ( { commit }, payload) {
        commit(UPDATE_USER_IMAGE, payload)
    }
}

// Getters
export const getters = {
    user:        state => state.user,
    displayName: state => state.displayName,
    check:       state => state.user !== null && typeof(state.user) !== 'undefined'
};

//functions
function getDisplayName(user){
    if(user == null || typeof user === 'undefined')
        return null

    return (user.first_name && user.last_name) ?
                user.first_name.split(' ')[0]+' '+user.last_name.split(' ')[0] :
                (user.first_name && !user.last_name) ?
                    user.first_name.split(' ')[0] :
                    (user.username) ?
                        user.username : user.email.split('@')[0]
}

function capitalize(str){
    
    if(str == null)
        return str

    var words   = str.split(" ");
    var strCap  = []
    words.forEach(w => {
        strCap.push(w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    });
    return strCap.join(" ");
}
