<template>
    <div class="modal fade" id="addCover">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <form id="formSiteCover" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="MyCover && MyCover.btn_i18n == true">
                                Traducción de Portada
                            </span>
                            <span v-else-if="formSiteCover.id">
                                Editar Portada Pavolar
                            </span>
                            <span v-else>Nueva Portada Pavolar</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-show="!MyCover || (MyCover && !MyCover.btn_i18n)">
                            <div class="form-group col-md-6" style="height: 230px;">
                                <label for="image">Imagen</label>
                                <load-image :clearCurrent="clearCurrent" :color_primary="colors.primary" :last_image="formSiteCover.path_image + formSiteCover.last_image" v-on:getImage="getImage"></load-image>
                            </div>
                            <div class="form-group col-md-2" style="height: 230px;">
                                <label for="image">Color Primario</label>
                                <div v-if="colors.primary" class="cuadro shadow-sm" :style="{'background-color':colors.primary}"></div>
                            </div>
                            <div class="form-group col-md-4" style="height: 230px;">
                                <label for="image">Paleta de Colores</label>
                                <div v-if="colors.palette">
                                    <div v-for="(color, index) in colors.palette" @click.prevent="selectPrimary(color)" :style="{'background-color':color}" :key="index" class="circulo shadow-sm"></div>
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="action_text">Fecha de Inicio</label>
                                <vue-datepicker :minDate="new Date()" v-model="formSiteCover.start_date" name="start_date" placeholder="Ingresar fecha" :disabled="formSiteCover.unlimited_time"></vue-datepicker>
                                <div class="form-check mt-1">
                                    <input type="checkbox" class="form-check-input" id="unlimited_time" v-model="formSiteCover.unlimited_time">
                                    <label class="form-check-label" for="unlimited_time">Periodo Indefinido</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="action_link">Fecha de Fin</label>
                                <vue-datepicker :minDate="new Date()" v-model="formSiteCover.end_date" name="end_date" placeholder="Ingresar fecha" :disabled="formSiteCover.unlimited_time"></vue-datepicker>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="image_size">Tamaño <img class="d-inline-block ml-2" :src="`/img/icons/${formSiteCover.image_size.toLowerCase()}.svg`" :alt="formSiteCover.image_size" width="25"></label>
                                <select name="image_size" v-model="formSiteCover.image_size" class="form-control">
                                    <option value="COVER">Expandir</option>
                                    <option value="CENTER">Centrar</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="action_text" class="required">Titulo Botón</label>
                                <input type="text" class="form-control" placeholder="Ingrese Titulo" v-model="formSiteCover.action_text" name="action_text">
                            </div>
                            <div class="form-group col-md-4" v-show="!MyCover || (MyCover && !MyCover.btn_i18n)">
                                <label for="action_link">Enlace</label>
                                <input type="text" class="form-control" placeholder="Ingrese url" v-model="formSiteCover.action_link" name="action_link">
                            </div>
                            <div class="form-group col-md-4" v-show="!MyCover || (MyCover && !MyCover.btn_i18n)">
                                <label for="action_target">Carga</label>
                                <select name="action_target" v-model="formSiteCover.action_target" class="form-control">
                                    <option value="_blank">Nueva Ventana</option>
                                    <option value="_self">Ventana Actual</option>
                                </select>
                            </div>
                            <div class="form-group editor-height-sm col-md-12">
                                <label for="content">Contenido</label>
                                <quill-editor id="content" class="pavolar-client" 
                                    ref="editor" 
                                    :options="editorOption" 
                                    v-model="formSiteCover.content"> 
                                </quill-editor>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'contents-covers-index'" > 
                            <span v-if="MyCover && MyCover.btn_i18n==true">Guardar Traducción</span>
                            <span v-else-if="formSiteCover.id && $can('update','contents-covers-index')">
                                Editar
                            </span>
                            <span v-else-if="formSiteCover.id && !$can('update','contents-covers-index')">
                                Solicitar Edicion
                            </span> 
                            <span v-else>
                                Guardar
                            </span>
                        </button> 
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import IconSelector from '~/components/icons/IconSelector.vue'
import loadImage from '~/components/covers/loadImage';

export default {
    props: ["MyCover"],
    components: {
        IconSelector,
        loadImage
    },
    data() {
        return {
            colors: {
                'image': null,
                'primary': 'rgb(0,0,0)',
                'palete': null,
            },
            clearCurrent:   false,

            formSiteCover: new WebForm("#formSiteCover", {
                content:        null,
                image:          null,
                last_image:     null,
                path_image:     '/storage/covers/',
                color_primary:  '',
                action_text:    null,
                action_link:    null,
                unlimited_time: true,
                start_date:     null,
                end_date:       null,
                image_size:    'CENTER',
                action_target:  '_blank',
                id: null,
                change: null,
				btn_i18n: false,
				id_i18n: null,
            }),
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],
                        [{ 'align': [] }],
                        ['link'],
                        ['clean']
                    ]
                }
            }
        };
    },
    watch: {
        MyCover: function(val, oldVal) {
            if (_.isEmpty(this.MyCover) || this.MyCover.id == '') {
                
            }else {
                this.formSiteCover.fill(this.MyCover);
                if (this.MyCover.btn_i18n && this.MyCover.i18n.length > 0) {
                    this.formSiteCover.id_i18n = this.MyCover.i18n[0].id;
					this.formSiteCover.content = (this.MyCover.i18n[0].content) ? (this.MyCover.i18n[0].content || '').replace(/\&lt;/g, '<').replace(/\&gt;/g, '>') : this.MyCover.content;
					this.formSiteCover.action_text = (this.MyCover.i18n[0].action_text) ? this.MyCover.i18n[0].action_text : this.MyCover.action_text;
				} else {
                    this.formSiteCover.last_image = this.MyCover.image;
                    this.formSiteCover.unlimited_time = _.isNull(this.MyCover.start_date) && _.isNull(this.MyCover.end_date)
                }
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            this.formSiteCover.image = this.colors.image;
            this.formSiteCover.color_primary = this.colors.primary;

            if (this.MyCover && this.MyCover.btn_i18n==true) {

            } else {
                if (this.formSiteCover.image == null) {
                    vm.$snotify.warning('Debe seleccionar una imagen');
                    return;
                }
            }

            let tipo = vm.formSiteCover.id == null ? 1 : 2;
            if(this.formSiteCover.id != null && this.MyCover.btn_i18n==false) {
                $.confirm({
                    title: `Editar Portada`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.updateOrCreate(tipo)
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.updateOrCreate(tipo)
            }
        },
        getImage(obj) {
            let last_primary = this.formSiteCover.color_primary;

            let primary = 'rgb(' + obj.primary.join(',') + ')';
            obj.primary = primary;
            obj.palette = obj.palette.map(p => {
                return 'rgb(' + p.join(',') + ')';
            });
            obj.palette.unshift(primary);
            this.colors = obj;
            
            if (obj.hasOwnProperty('last')) {
                this.colors.primary = last_primary;
            } else {
                this.formSiteCover.change = true;
            }
        },
        selectPrimary(color) {
            this.colors.primary = color;
        },
        updateOrCreate(type) {
            let vm = this;
            vm.formSiteCover.post('/admin/settings/site-covers/save').then(response => {
                let data = response.data;

                if (data.user_request_status) {
                    $('#addCover').modal('hide');
                    return;
                }

                let snotify_message =
                    type == 1
                    ? "Elemento Agregado con exito."
                    : "Elemento actualizado con exito.";
                vm.$snotify.success(snotify_message);
                vm.formSiteCover.clear();
                vm.formSiteCover.reset();
                vm.$emit('reloadCoverModal');
                $("#addCover").modal("hide");
                return;
            });
        }
    },
    mounted() {
        let vm = this;
        
        $('#addCover').off('hidden.bs.modal');
        $('#addCover').on('hidden.bs.modal', function () {
            vm.formSiteCover.clear();
            vm.formSiteCover.reset();
            vm.colors = { 'image': null, 'primary': null, 'palete': null};
            setTimeout (() => {
                vm.clearCurrent = true;
            }, 200);
            vm.clearCurrent = false;
            vm.$emit('closeCoverModal');
        });
    }
};
</script>
<style scoped>
.circulo {
    width: 50px;
    height: 50px;
    float: left;
    display: block;
    cursor: pointer;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 3px white;
}

.cuadro {
    width: 100px; 
    height: 100px; 
    border: solid 3px white;
}
</style>