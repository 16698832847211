<template>
    <div>
        Account Home
    </div>
</template>
<script>
export default {
    middleware: 'auth',
    layout: 'dashboard',
}
</script>
