/*=============================================================================
  Created by NxtChg (admin@nxtchg.com), 2017. License: Public Domain.
=============================================================================*/

Vue.component('v-crumbs', {
	template:
			`<ul class="v-crumbs" v-if="crumbs.length">
				<li v-for="(crumb, i) in crumbs" style="display:inline-block">
					<router-link v-if="i != 1 && i < crumbs.length-1 " :to="crumb.path">{{ crumb.name }}</router-link>
					<span class=last v-else style="cursor:default">{{ crumb.name }}</span>
				</li>
			</ul>`,

	props: { 
		root: String 
	},
	computed: {
		crumbs: function() {
			
			const START_COLONS_END_INTERROGATION = /^\:+|\?+$/g
			var path = '', title = (this.root || 'home');
			var cs = [ { name: title, path: '/'} ]; 

			if(!this.$route)
				return [];

			if(this.$route.matched.length <= 0 )
				return cs;
			
			var r = (this.$route.path).split('/');
			var m = (this.$route.matched[0].meta.crumbs || '').split('/');

			for(var i = 1; i < r.length; i++) {
				var name = (m[i] || r[i]).replace(START_COLONS_END_INTERROGATION, '');
				
				if(r[i] == '') 
					continue;

				path += '/' + name;
				cs.push({ name: name, path: path });
			}

			return cs;
		}
	}
});