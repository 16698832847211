<template>
	<div class="card border-light mb-3 mx-1 shadow-sm">
  		<div class="card-body text-dark d-flex flex-column align-items-center">
  			<user-image :image="user.photo || '/img/none.png'" :uid="user.id" :sessid="currentUser.id" :is-enabled="false"/>
	  		<div class="prevent-click-propagation mt-2" @click.stop="">
	  			<toggle-button 
	  				v-can:update
	  				:value="user.active == 1" 
	  				color="#38c172" 
	  				:sync="true"
	  				:labels="{checked: 'Activo', unchecked: 'Inactivo'}"
	  				:width="75" 
	  				@change="changeUserState"
	  				v-model="userActive" />
	  		</div>

    		<h5 class="card-text mt-2 mb-1 cursor-pointer" @click="userClicked">{{user.first_name}} {{user.last_name}} {{ currentUser.id === user.id ? '(Tú)' : ''}}</h5>
    		<span class="email-label">{{user.email}}</span>
			
    		<span class="date-label mt-2"><span class="icon-calendar-3" grid="12"></span> Se registro {{createdAt}}</span>
			<span v-if="user.email_verified_at" class="verified bg-primary center-content" title="Usuario Verificado">
				<span class="icon-check"></span>
			</span>
			<button v-if="!user.email_verified_at && $can('manage')" class="tag unverified mt-4" @click.stop="forwardEmail">
				<span class="icon-email2"></span> Reenviar Email
			</button>
		</div>
	</div>
</template>

<script>
	'use strict'
	import ToggleButton from 'vue-js-toggle-button/src/Button'
	import { mapGetters } from 'vuex'
	import userImage from './userImage'
	export default {
		name: 'user-card',
		components: {
			'toggle-button': ToggleButton,
			'user-image': userImage
		},
		computed: mapGetters({
			currentUser : 'auth/user',
        }),
		props: {
            user: Object
        },
        data () {
        	return {
        		createdAt: null,
        		userActive: this.user.active == 1
        	}
        },
        methods: {
			forwardEmail () {
				let vm = this
				let endpoin = '/admin/account/user/resend_email'
				axios.post(endpoin, {
					user_id: vm.user.id
				}).then(response => {
					let res = response.data;
					if (res.success && res.success=="email_send") {
						this.$snotify.success( 'Se ha reenviado el correo de verificacion.');
					} else {
						this.$snotify.simple( 'Upss.. Algo a salido mal.');
					}
				})
			},
        	userClicked: function () {
        		this.$emit('click', this.user)
        	}, 
        	deleteUser: function () {
        		alert(this.user.email)
        	},
        	changeUserState: function () {
        		axios
        			.post('/admin/user/state', {
        				uid: this.user.id,
        				state: this.userActive
        			})
        			.then(response => {
        				if(!response.data.success) {
        					alert('something went wrong')
        					return;
        				}

        				this.$snotify.simple( this.userActive ? 'Cuenta de usuario habilitada.' : 'Cuenta de usuario inhabilitada');
        			})
        	},
        },
        mounted: function () {
        	this.createdAt = moment(this.user.created_at).fromNow()
        }
	}
</script>

<style scoped>
	.verified{
		position: absolute;
		color: #fff;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		top: 5px;
		right: 5px;
	}
	.date-label {
		font-size: 0.8em
	}
	.email-label {
		font-size: 0.9em;
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
		white-space: nowrap;
	}
	.date-label {
		color:#59595B;
	}
	.card {
		/* cursor: pointer; */
		width: 15rem;
		overflow: hidden;
	}
	.tag.unverified:before {
		content: '';
		width: 40%;
		left: calc(50% - 20%);
		height: 1px;
		background: #E0E0E0;
		display: inline-block;
		position: absolute;
		margin-top: -10px;
	}
</style>
