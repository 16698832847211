<template>
    <div id="app-chat" class="chat">
        <vue-chat-messages 
            :user="user"
            :openChannels="openChannels"
            :activeChannel="activeChannel"
            :conversations="conversations"
            @changeActiveChannel="onChangeActiveChannel"
            @loadMessagesPage="fetchMessages">
        </vue-chat-messages>
        <vue-chat-channels 
            :user="user"
            :users="users"
            :show_users_chat="show_users_chat"
            :conversations="conversations"
            @createNewGroup="onCreateNewGroup"
            @openChannel="onOpenChannel">
        </vue-chat-channels>
    </div>
</template>

<script>

import VueChatMessages from './VueChatMessages'
import VueChatChannels from './VueChatChannels'
export default {
    props: [ 'user', 'socket', 'show_users_chat'],

    data() {
        return {
            activeChannel: null,
            openChannels: [],
            messages: [],
            users: [],
            conversations: [],
            
        };
    },
    components: {
        VueChatMessages,
        VueChatChannels,
    },
    computed: {
       
        totalMessagesPending () {
            let vm = this
            let total = 0

            vm.conversations.forEach(c => {
                total += c.messages_pending
            })
            return total
        },
        rooms () {
            let vm = this
            let channels = []
            vm.users.forEach(user => {
                user.conversations.forEach(conversation => {
                    let index_channel = channels.findIndex(c => {
                        return conversation.channel.channel == c
                    })
                    if(index_channel < 0) {
                        channels.push(conversation.channel.channel)
                    }

                })
            })
            channels.push(vm.user.socket_id)
            return channels
        }

    },
    watch: { 
        activeChannel () {
            if(this.activeChannel) {
                try{
                    document.getElementById(`textarea-${this.activeChannel}`).focus()
                } catch (err) {
                    // console.log(err)
                }
            }
        },
        totalMessagesPending () {
            this.eventHub.$emit('updateMessagesPending', this.totalMessagesPending)
        },
      	rooms: function(newVal, oldVal) { // watch it

            let vm = this
            let rooms = newVal.filter(room => {
                return oldVal.indexOf(room) < 0
            })

            for (let room of rooms) {

                // Typing
                vm.socket.on(`${room}::User\\${vm.user.id}`, data => {
                    let channel = vm.openChannels.find(c => {
                        return c.channel_id == data.channel_id
                    })

                    if(!channel) 
                        return

                    let user = channel.users.find(u => {
                        return u.id == data.user_typing
                    })

                    if(user) {
                        let isTyping = channel.users_typing.find(u => {
                            return u.id == user.id
                        })

                        if(isTyping) {
                            console.log('Ya esta escribiendo')
                            return
                        }

                        channel.users_typing.push(user)

                        setTimeout(function () {
                            let index = channel.users_typing.findIndex(u => {
                                return u.id == user.id
                            })

                            if(index >= 0) {
                                channel.users_typing.splice(index, 1);
                            }

                        }, 5000)

                    }
                    // channel.users_typing.indexOf(data.user_typing)

                })

                // Watch Messages
                vm.socket.on(`${room}:App\\Events\\MessageWatch`, ({data}) => {
                    let user = vm.users.find(u => {
                        return u.id == data.user.id
                    })

                    if(user) {
                        let conversation = user.conversations.find(c => {
                            return c.id == data.conversation.id
                        })
                        if(conversation) {
                            conversation.messages_pending = data.conversation.messages_pending
                        }
                    }
                })
                // Update Group

                

                vm.socket.on(`${room}:App\\Events\\UpdateGroup`, ({data}) => {
                    
                    let vm = this
                    data.conversations_with_trashed.forEach(conversation  => {
                       if(vm.user.id == conversation.user_id) {
                           let c_index = vm.conversations.findIndex(c => {
                                if(c.id == conversation.id) {
                                    c.channel.title = conversation.channel.title
                                    c.channel.description = conversation.channel.description
                                }
                               return c.id == conversation.id
                           })
                           if (c_index > -1 && conversation.deleted_at != null) {
                                vm.conversations.splice(c_index, 1);

                                vm.users.forEach(u => {
                                    let conversation_index = u.conversations.findIndex(c => {
                                        if(c.id == conversation.id) {
                                            c.channel.title = conversation.channel.title
                                            c.channel.description = conversation.channel.description
                                        }
                                        return c.channel_id == conversation.channel_id
                                    })

                                    if(conversation_index > -1) {
                                        u.conversations.splice(conversation_index, 1)
                                    }
                                })
                                let openChannel_index = vm.openChannels.findIndex(c => {
                                    return c.channel_id == conversation.channel.channel
                                })
                                if(openChannel_index > -1) {
                                    vm.openChannels.splice(openChannel_index, 1);
                                }
                                console.log('Se borro!!')

                                vm.socket.off(`${conversation.channel.channel}:App\\Events\\MessageSent`)
                                vm.socket.off(`${conversation.channel.channel}:App\\Events\\UpdateGroup`)
                                vm.socket.off(`${conversation.channel.channel}:App\\Events\\MessageWatch`)
                            } else {
                                vm.conversations.push(conversation)
                            }

                            return

                       }

                       let conversation_index = vm.conversations.findIndex(c => {
                           return c.channel_id == conversation.channel_id
                       })
                       
                       let user = vm.users.find(u => {
                           return u.id == conversation.user_id
                       })

                       if(user) {
                           let user_conversation_index = user.conversations.findIndex( c  => {
                                if(c.id == conversation.id) {
                                    c.channel.title = conversation.channel.title
                                    c.channel.description = conversation.channel.description
                                }
                                 return c.id == conversation.id
                           })

                           if(user_conversation_index > -1 && conversation.deleted_at != null) {
                                console.log('Se borro!!')
                                user.conversations.splice(user_conversation_index, 1);
                           } else if(user_conversation_index < 0 && conversation_index > -1) {
                               user.conversations.push(conversation)
                           }
                       }
                   })
                })

                // Send Message
                vm.socket.on(`${room}:App\\Events\\MessageSent`, ({data}) => {
                    let message = data.message
                    
                    let conversations = data.conversations
                    let channel = conversations[0].channel
                    let openChannel = vm.openChannels.find(c => {
                        return c.channel_id == room
                    })
                    if (openChannel != undefined) {
                        let index_id_message = openChannel.messages.findIndex( m => {
                            return m.id == message.id
                        })
                        if(index_id_message < 0) {
                            let user_index = openChannel.users_typing.findIndex(u => {
                                return u.id == message.user_id
                            })

                            if(user_index >= 0) {
                                openChannel.users_typing.splice(user_index, 1)
                            }
                            
                            setTimeout(function () {
                                openChannel.messages.push(message);
                            }, 300)

                            
                            
                        }
                    }
                    conversations.forEach(c => {
                        let user  = vm.users.find(user => {
                            return user.id == c.user_id
                        })
                        if( user ) {
                            let conversation = user.conversations.find( _c => {
                                return _c.id == c.id
                            })
                            if(conversation) {
                                conversation.messages_pending = c.messages_pending
                            }
                        } 
                    }) 
                    if(message.user_id != vm.user.id) {
                        let user = vm.users.find(u => {
                            return u.id == message.user_id
                        })
                        var audio = document.createElement('audio');
                        audio.src = '/mp3/appointed.mp3'
                        audio.play();
                        
                      
                        conversations.forEach(c => {
                            let conversation = vm.conversations.find( _c => {
                                return _c.id == c.id
                            })
                            if(conversation) {
                                conversation.messages_pending = c.messages_pending
                            }
                        })



                        if(!window.focused) {
                            let title_push = channel.group > 0 ? `${user.first_name} desde ${channel.title} dice:` :  `${user.first_name} dice:`
                            Push.create(title_push, {
                                    body: message.message || 'He adjuntado un archivo 📎',
                                    icon: user.photo || '/img/none.png',
                                    timeout: 4000,
                                    onClick: function () {
                                        window.focus();
                                        document.getElementById(`channel_id_${message.channel_id}`)
                                                .dispatchEvent(new Event('click'))
                                        this.close();
                                    }
                                });
                        }
                        // vm.updateMessagesPending(1, data.data.channel_id)
                    
                    }
                });
                
            }
            vm.getConversations()
        }
    },
   
    methods: {
        readMessages (id) {
            let vm = this
            let ednpoint = `/admin/chat/channel/${id}/message/read`
            return axios.post(ednpoint).then( ({data}) => {
                let conversation = vm.conversations.find(c => {
                    return c.id == data.id
                })
                if(conversation) {
                    conversation.messages_pending = data.messages_pending
                }
            })
        },
        updateMessagesPending (count, channel) {
            let vm = this
            let ednpoint = `/admin/chat/channel/messages/pending`;
            return axios.post(ednpoint, {
                count,
                channel
            }).then( ({data}) => {
                let conversation = vm.conversations.find(c => {
                    return c.id == data.id
                })

                conversation.messages_pending = data.messages_pending
            })
        },
        isOpenChannel (channel) {
            let vm = this
            let o_c = vm.openChannels.find(c => {
                return c.channel_id == channel
            })
            return o_c

        },
        getConversations () {
            let ednpoint = `/admin/chat/conversations`
            return axios.get(ednpoint).then( ({data}) => {
                this.conversations = data
            })
        },
        getUsers () {
            if(this.$can('view', 'vue-chat')) {
                let ednpoint = `/admin/chat/users`
                return axios.get(ednpoint).then( ({data}) => {
                    data.forEach(user => {
                        user.online = false
                    })
                    this.users = data
                })
            }
        },

        fetchMessages (channel, page = 1, $el) {
            let vm = this
            let ednpoint = `/admin/chat/channels/${channel}/messages?page=${page}`
            return axios.get(ednpoint)
                .then( ({data})  => {
                    let openChannel = vm.openChannels.find(c => {
                        return c.channel_id == channel
                    })

                    data.data.forEach(message => {
                        let indexMessage = openChannel.messages.findIndex(m => {
                            return m.id == message.id
                        })

                        if(indexMessage < 0) {
                            openChannel.messages.unshift(message)
                        }
                    })
                    
                    // this.messages = data
                })
        },
        onCreateNewGroup (data) {
            let new_group = data.data
            let $el = data.el
            let vm = this
            let endpoint = `/admin/chat/create/group`
            $el.classList.add('load')
            return axios.post(endpoint, new_group)
                        .then( ({data}) => {
                            console.log(data)

                            if(data.error) {
                                Object.keys(data.error).forEach(function(key) {
                                    vm.$snotify.error(data.error[key], `Error ${key}`)
                                });
                            } else {
                                vm.eventHub.$emit('close-modal-new-group', false)
                            }
                             $el.classList.remove('load')
                        }).catch( err => {
                            console.log(err)
                            Object.keys(err.error).forEach(function(key) {
                                vm.$snotify.error(data.error[key], `Error ${key}`)
                            });
                            
                        })
        },
        async onOpenChannel(data) {
            let vm = this
            let socket = vm.socket
            let isOpenChannel = vm.isOpenChannel(data.channel_id)
            
            if(isOpenChannel == undefined && data.is_conversation) {
                
                data.messages = []
                data.open = true;
                data.show = true;
                data.out = false;
                data.page = 1;
                data.users_typing = [];
                
                vm.openChannels.push(data)
                console.log(data)

                vm.activeChannel = data.channel_id

                vm.fetchMessages(data.channel_id, data.page)
                vm.triggerRead(data.channel_id)
            } else if(data.is_conversation && isOpenChannel != undefined) {
                vm.activeChannel = data.channel_id
                vm.triggerRead(data.channel_id)
                isOpenChannel.show = true
                isOpenChannel.open = true
                isOpenChannel.out = false
            } else if(!data.is_conversation && isOpenChannel == undefined){
                let user = vm.users.find(u => {
                    return u.id ===  data.users[0].id
                })
                let conversation = await vm.createNewConversation(user.id);
                user.conversations.push(conversation.reciber)
                vm.openChannels.push({
                    channel_id: conversation.reciber.channel.channel,
                    messages: [],
                    users: [user],
                    group: false,
                    title: `${user.first_name} ${user.last_name}`,
                    open: true,
                    show: true,
                    out: false,
                    conversation: conversation.reciber,
                    users_typing: []
                })
                vm.activeChannel = conversation.reciber.channel.channel
                vm.getConversations();
                socket.emit('update-conversation', {id: data.channel_id, user: conversation.me})
            }
        },
        hidenChannel (add = 0) {
            let vm = this
            let window_width = window.innerWidth
            let diff_width = vm.$el.offsetWidth + add - window_width
            let count = Math.ceil(diff_width / 300)
            for (let index = 0; index < count; index++) {
                let channel = vm.openChannels.find(c => {
                    return c.show == true && c.out == false && c.channel_id != vm.activeChannel
                })

                if(channel) {
                    channel.out = true
                }
            }
            

        },
        showChannel () {
            let vm = this
            let window_width = window.innerWidth
            let diff_width = window_width - vm.$el.offsetWidth 
            let count = Math.floor(diff_width / 300)
            for (let index = 0; index < count; index++) {
                let channel = vm.openChannels.find(c => {
                    return c.out == true && c.show == true
                })
                if(channel) {
                    channel.out = false
                }
            }

        },
        onChangeActiveChannel (id) {
            this.activeChannel = id

            if( id ) {
                this.triggerRead(id)
            } 
        },
        triggerRead(id) {
            let vm = this

            let conversation = vm.conversations.find(c => {
                return c.channel.channel == id
            })

            if(conversation) {
                vm.readMessages(conversation.id);
            }
        },
        createNewConversation(user) {
            let ednpoint = `/admin/chat/new/conversation/${user}`

            return axios.post(ednpoint)
                .then( ({data}) => {
                    return data
                })
        },
        socketEvents() {
            let vm = this
            vm.socket.on('Events::Global/user-disconnect', (data) => {
                let user = vm.users.find(u => {
                    return u.id == data.id
                })
                if(user) {
                    user.online = false
                }
            })
            vm.socket.on('Events::Global/user-connect', (data) => {
                console.log('user-connect', data)
                for(let channel in data) {
                    let user = vm.users.find(u => {
                        return u.id == data[channel].id
                    })
                    if(user) {
                        user.online = true
                    }
                }
            })
            vm.socket.on(`${vm.user.socket_id}:App\\Events\\NewGroup`, ({data}) => {
                if(data.channel.user_id == vm.user.id) {
                    vm.$snotify.simple(`Se ha creado el grupo ${data.channel.title}`)
                } else {
                    let user = vm.users.find(u => {
                        return u.id == data.channel.user_id
                    })

                    if(user) {
                        if(!window.focused) {
                            
                            Push.create(`${user.first_name} te ha agregado al grupo ${data.channel.title}`, {
                                    body: `Finalidad ${data.channel.description}` ,
                                    icon: user.photo || '/img/none.png',
                                    timeout: 4000,
                                    onClick: function () {
                                        window.focus();
                                        vm.eventHub.openConversationById(data.channel.id)
                                        this.close();
                                    }
                                });
                        }
                    }
                }
                data.users.forEach(u => {
                    let user  = vm.users.find(user => {
                        return user.id == u.id
                    })
                    if(user) {
                        user.conversations.push(u.conversation)
                    }
                })

            })
            vm.socket.on('Global:App\\Events\\LastConnection', ({data}) => {
                let user = vm.users.find(u => {
                    return u.id == data.id
                })
                if(!user) return
                user.last_connection = data.last_connection
            })
            vm.socket.on(`${vm.user.socket_id}:App\\Events\\AddUserGroup`, ({data}) => {
                let vm = this
                data.conversations_with_trashed.forEach(conversation => {
                    if(vm.user.id != conversation.user_id) {
                        let user = vm.users.find(u => {
                            return u.user_id == conversation.user_id
                        })

                        if(user) {
                            user.conversations.push(conversation)
                        }
                        return
                    } 
                    
                    vm.conversations.push(conversation)
                })
            })
        
            vm.socket.on('update-conversation', function (conversation) {
                let user = vm.users.find(user => {
                    return user.id == conversation.user_id
                })

                user.conversations.push(conversation)

                
            })

            vm.socket.on('user', function (data) {
                let index_user = vm.users.findIndex(user => {
                    return user.id == data.user_online.id
                })
                if(index_user >= 0) {
                    vm.users[index_user].online = 1
                }
            })

            vm.eventHub.$on('typing', channel_id => {
                let channel = vm.openChannels.find(c => {
                    return c.channel_id == channel_id
                })
                let users = channel.users.map(u => {
                    return u.id
                })
                if(channel) {
                    console.log('typing')
                    vm.socket.emit('typing', {
                        channel: channel_id,
                        users: users,
                        emisor: vm.user.id
                    })
                }
            })

            // Emit connect user
            console.log('USER_CONNECT')
            console.log(vm.socket)
            vm.socket.emit('user-connect', {
                rooms: vm.rooms,
                user: vm.user
            })
        }
    },
   
    mounted: async function () {
        let vm = this
        
        // Connect to Socket.io
        await this.getUsers()
        await this.getConversations()
       

        window.onresize = function(event) {
            let window_width = window.innerWidth
            if(window_width <= vm.$el.offsetWidth) {
                vm.hidenChannel()
            } else {
                vm.showChannel()
            }
        };

        this.socketEvents()
        
    }

   
}




</script>

<style>

</style>
