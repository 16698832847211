<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
     .card__icon{
        font-size: 3em;
        color: #4463F0;
    }
</style>
<template>
    <div class="container">
        <img src="/img/logo-white.png" alt="pavolar.com" class="logo mt-1 mt-md-3 mb-2 mb-md-5">
        <div class="bg-curve">
            <svg xmlns="http://www.w3.org/2000/svg" class="curve" viewBox="0 0 1440 68" enable-background="new 0 0 1440 68"><path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill="#F4F5F7" transform="translate(0-1977)"></path></svg>
        </div>
        <transition name="fade">
        <div :key="123" v-if="!passwordReseted" class="d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start flex-wrap login-container">
            <div v-if="tokenValid" class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                <h5 class="font-weight-bold mb-1 mt-3">Signa Nueva Contraseña</h5>
                <form id="restorePasswordForm" autocomplete="off" @submit.prevent="setNewPassword" novalidate method="post">
                    <div class="form-group mt-4">
                        <label for="password">Contraseña</label>
                        <div class="position-relative">
                            <input :type="!passwordShow ? 'password' : 'text'"  id="password" class="form-control bg-white" v-model="form.password">
                            <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="passwordShow = !passwordShow">
                                <span class="icon-view" :class="!passwordShow ? 'icon-view' : 'icon-view-off'"></span>
                            </button>
                        </div>
                    </div>

                    <div class="form-group mt-4" >
                        <label for="passwordConfirm">Confirm Password</label>
                        <input :type="!passwordShow ? 'password' : 'text'" id="passwordConfirm" class="form-control"  v-model="form.password_confirmation">
                    </div>

                    <div class="form-group d-flex justify-content-between mt-5">
                        <button type="submit" class="btn btn-success ajax">Asignar contraseña</button>
                    </div>
                </form>
            </div>
            <div v-else-if="tokenValid === null" class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                <h5 class="font-weight-bold mb-1 mt-3">Verificando Solicitud</h5>
                <p>Por favor espere..</p>
            </div>
            <div v-else class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white">
                <h5 class="font-weight-bold mb-1 mt-3">Socilictud ha Expirado</h5>
                <p>Vaya parce que el tiempo maximo de espera a caducado...</p>
                <div class="d-flex align-items-center justify-content-between">
                    <router-link :to="{name: 'auth-reset-password'}" class="btn btn-success">
                        Solicitar de Nuevo
                    </router-link>
                    <router-link :to="{name: 'auth-login'}" >
                        Iniciar Sesion <span class="icon-user"></span>
                    </router-link>

                </div>
            </div>
            <div class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                <h4>Hola! bienvenido a Pavolar Admin</h4>
                <p>Esta seccion para recuperar contraseña</p>
            </div>
        </div>
        <div :key="1234" v-else class="d-flex justify-content-center justify-content-center align-items-center align-items-md-start flex-wrap login-container">
            <div class="col-12 col-md-6 col-lg-8 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                <div class="d-flex py-3">
                    <div class="px-3">
                        <span class="card__icon icon-lock-circle"></span>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="font-weight-bold mb-1">Contraseña actualizada!</h5>
                        <p>Ya puedes iniciar sesion con tu nueva contraseña</p>
                        <div>
                            <router-link :to="{name: 'auth-login'}" class="btn btn-success">
                                Iniciar session <span class="icon-user"></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>
        <p class="text-muted mb-0">© Pavolar | Todos los derechos reservados <span v-once>{{ year }}</span></p>
    </div>
</template>

<script>

    "use strict";
    import store from '~/store'

    export default {
        middleware: 'guest',
        metaInfo () {
            return { title: "Login" }
        },

        data(){
            let vm = this
            return {
                passwordShow: false,
                passwordReseted: false,
                tokenValid: null,
                form: new WebForm("#restorePasswordForm", {
                    password: '',
                    password_confirmation: '',
                    token: vm.$route.params.token
                }),
                year: (new Date).getFullYear()
            };
        },


        methods: {
            setNewPassword() {
                let endpoint = `/admin/account/password/set`
                this.form.post(endpoint).then(response => {
                    if(response.data) {
                        this.passwordReseted = response.data
                    }
                })
            },

            checkToken() {
                let endpoint = `/admin/account/password/recover/check`
                this.form.post(endpoint, {token: this.form.token}).then(response => {
                    this.tokenValid = response.data.valid
                })
            }
        },

        created() {
            this.checkToken()
        }

    }
</script>

<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
</style>
