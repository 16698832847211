<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Destinos Mas Buscados</h5>
                    <p class="text-muted">Portadas de los destinos mas solicitados</p>
                </div>
                <div>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addDestination">
                        <span class="icon-add-2 mr-2"></span> Nuevo Destino
                    </button>
                </div>
            </div>
            
            <data-table :ajax="{ url: '/admin/destinations/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="destinationsTable" :serverSide="true" :columns="dt.columns()"></data-table>
            
            <destination-modal :Destination="Destination" v-on:closeDestinationModal="clearDestinationVar()" v-on:reloadDestinationModal="reloadDestinationVar()"></destination-modal>

        </div>
    </div>
</template>
<script>
import dataTable from '~/components/dataTable';
import DestinationModal from '~/components/destinations/DestinationModal';

export default {
    middleware: 'auth',
    layout: 'dashboard',
    components: {
        dataTable,
        DestinationModal
    },
    metaInfo() {
        return {
            title: "Destinos mas populares"
        }
    },
    data() {
        return {
            Destination: null,
            destinations: [],
            dt: {
                columns : () => {
                    var $this = this;
                    let columns =
                        [
                            { title: 'id', data: 'id' },
                            { title: 'imagen', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `<img src="${row.path_image}${row.image}" width="240" style="border: 8px ${row.color_primary} solid; opacity: 0.95;" />`;
                                }
                            },
                            { title: 'Destino', data: 'city',  orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let val = data.name;
                                    let name = (val.indexOf(data.district)>=0) ? data.name : data.name + ' ' + data.district;
                                    return  `
                                    <img src="https://www.countryflags.io/${data.country.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.country.short_iso_code}">
                                    ${data.country.name} </br><b>${name}</b>`;
                                }},
                            { title: 'Aeropuertos', data: 'airport', orderable: false, searchable: true, className: 'html-block',
                                render: function ( data, type, row) {
                                    if( data.length > 0 ) {
                                        let htmlText = '';
                                        data.forEach(element => {
                                            htmlText = htmlText + ' <h5><span class="badge badge-primary"> (' + element.iata + ') ' + element.name + '</span></h5>';
                                        });
                                        return `${htmlText}`;
                                    } else {
                                        return `<span class="text-muted">ninguno...</span>`;
                                    }
                                }
                            },
                            { title: 'Acciones', data: 'id', orderable: false,
                                render: function ( data, type, row, meta ) {
                                    let btnedit     =  $this.$can('update')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#addDestination">Editar</button>` : `-`;
                                    let btndelete   =  $this.$can('update')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete" data-id="${data}">Eliminar</button>` : `-`;
                                    return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                                }
                            },
                        ];
                    return columns
                }
            }
        }
    },
    methods: {
        clearDestinationVar() {
            this.Destination = null;
        },
        reloadDestinationVar () {
            this.$refs.destinationsTable.ajaxReload();
        },
        onDelete(destiny) {
            let vm = this;
            $.confirm.delete(
                'Eliminar Portada',
                `¿Está seguro que desea eliminar esta portada?`, 
                function(){
                    vm.deleteItem(destiny)
                }
            )
        },
        deleteItem (destiny) {
            let vm = this;
            axios.post('/admin/destinations/delete', { id: destiny.id}).then(response => {
                let data = response.data;

                if (data.user_request_status) {
                    return;
                }

                let snotify_message = 'Se elimino con exito.'
                vm.$snotify.success(snotify_message);
                vm.$refs.destinationsTable.ajaxReload();
                return;

            }).catch(err => console.log(err));
        },
    },
    mounted() {
        let vm = this;
        $(vm.$el).off("click.destinations",".btn-delete");
        $(vm.$el).on("click.destinations",".btn-delete",function(){
            let destiny = vm.$refs.destinationsTable.rowData($(this).closest('tr'));
            vm.onDelete(destiny);
        });

        $(vm.$el).off("click.destinations",".btn-edit");
        $(vm.$el).on("click.destinations",".btn-edit",function(){
            let destiny = vm.$refs.destinationsTable.rowData($(this).closest('tr'));
            vm.Destination = destiny;
        });
    }
}
</script>
<style>
.card__cover {
    height: 200px;
    display: block;
    background-position: center;
    background-size: cover;
}
.card__cover p{
    color: white;
    overflow: hidden;
}
</style>