<template>
    <div class="modal fade" id="addRoom">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="formRoom" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formRoom.id">
                                Editar Tipo Habitacion
                            </span>
                            <span v-else>Nuevo Tipo Habitacion</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 col-md-12">
                                <label for="title">Nombre de Habitacion</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formRoom.name" name="title">
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="adults">Adultos</label>
                                <input type="number" class="form-control" v-model="formRoom.adults" :min="0" :max="10">
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="children">Niños</label>
                                <input type="number" class="form-control" v-model="formRoom.children" :min="0" :max="10">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" v-can:create="'packages-index'" > 
                            <span v-if="formRoom.id && $can('update','packages-index')">
                                Editar
                            </span>
                            <span v-else-if="formRoom.id && !$can('update','packages-index')">
                                Solicitar Edicion
                            </span> 
                            <span v-else>
                                Guardar
                            </span>
                        </button> 
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["Room"],
    components: {
    },
    data() {
        return {
            formRoom: new WebForm("#formRoom", {
                name           : '',
                adults         : '',
                children       : '',
                id             : null,
            }),
        };
    },
    watch: {
        Room: function(val, oldVal) {
            if (_.isEmpty(this.Room) || this.Room.id == '') {
                //
            }else {
                this.formRoom.fill(this.Room);  
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            let tipo = vm.formRoom.id == null ? 1 : 2;
            if(this.formRoom.id != null) {
                $.confirm({
                    title: `Editar tipo Habitacion`,
                    content: '¿Seguro quiere Editar?',
                    buttons: {
                        eliminar:{
                            text: 'Editar',
                            btnClass: 'btn-primary',
                            keys: ['enter'],
                            action: function() {
                                vm.updateOrCreate('/admin/hotels/rooms/save', tipo)
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                });
            } else {
                vm.updateOrCreate('/admin/hotels/rooms/save', tipo)
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formRoom.post(url).then(response => {
                let data = response.data;

                vm.formRoom.id = null;

                if (data.user_request_status) {
                    $('#addRoom').modal('hide');
                    return;
                }

                let snotify_message =
                    type == 1
                    ? "Elemento Agregado con exito."
                    : "Elemento actualizado con exito.";
                vm.$snotify.success(snotify_message);
                $("#addRoom").modal("hide");
                vm.formRoom.clear();
                vm.formRoom.reset();
                vm.$emit('reloadRoomModal');
                return;
            
            });
        }
    },
    mounted() {
        let vm = this;
        $('#addRoom').off('hidden.bs.modal');
        $('#addRoom').on('hidden.bs.modal', function () {
            vm.formRoom.clear();
            vm.formRoom.reset();
            vm.$emit('closeRoomModal');
        });
    }
};
</script>