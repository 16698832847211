<template>
    <div>
        Acound template
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    middleware: 'auth',
    layout: 'dashboard',
}
</script>
