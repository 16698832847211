`<template>
    <div>
        <div class="row section mx-2 my-1 p-2">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-car-2 mr-1"></span> Requerimientos de Servicio de Transporte
                        </label>
                    </div>
                </div>
                <div class="row" v-if="!quoteService.empty_quote">
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Pais</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteService.country.name">
                            </div>
                            <div class="form-group col-6">
                                <label class="w-100">Ciudad</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteService.city">
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6" v-if="quoteService.type=='RENT_A_CAR'">
                                <label>Tipo de Servicio</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="getServiceName(quoteService.type)" >
                            </div>
                            <div class="form-group col-6" v-if="quoteService.type=='RENT_A_CAR'">
                                <label>Tipo de Auto</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteService.car_type" >
                            </div>
                            <div class="form-group col-12" v-if="quoteService.type!='RENT_A_CAR'">
                                <label>Tipo de Servicio</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="getServiceName(quoteService.type)" >
                            </div>
                        </div>
                        <div class="row" v-if="quoteService.type=='TO_ADDRESS'">
                            <div class="form-group col-12">
                                <label>Direccion</label>
                                <div class="border my-1 p-1" style="height: 55px; overflow-y: scroll;">{{quoteService.address}}</div>
                            </div>
                        </div>
                        <div class="row" v-if="quoteService.type=='RENT_A_CAR'">
                            <div class="form-group col-6">
                                <label>Fecha Inicial </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteService.start_date).format('LL')">
                            </div>
                            <div class="form-group col-6">
                                <label>Fecha Final</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteService.end_date).format('LL')">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Comentarios sobre Servicio de Transporte</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteService.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-12">
                                <h5 class="text-center my-4">Requerimiento Vacio</h5>
                                <label>Comentarios</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteService.comments}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------- -->
            <div class="col-md-7 border-left">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Soluciones de Servicio de Transporte
                        </label>
                        <span class="float-sm-right">
                            <button class="btn ml-3 btn-primary float-right btn-sm"
                            data-toggle="modal" data-target="#serviceResponseModal" :disabled="!owner"
                            @click="showServiceModal()">
                                Nueva Solucion
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row" v-if="serviceResponse && serviceResponse.length>0">
                    <div class="form-group col-12  mb-0 pb-0">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                        <th scope="col">#</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Fecha Final</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadComplete">
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center py-3">
                                                    <div class="spinner-border text-secondary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div>
                                                        <small>Cargando...</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item, index) in (serviceResponse) ? serviceResponse : null"
                                        :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">
                                                {{ (item.body_json.type=="RENT_A_CAR") ? "Renta de Vehiculo" : null }}
                                                {{ (item.body_json.type=="TRANSPORT") ? "Transporte" : null }}
                                                {{ (item.body_json.type!="TRANSPORT" && item.body_json.type!="RENT_A_CAR") ? "Error" : null }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json.entry_format) ? $moment(item.body_json.entry_format).format('LL') : null }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json.out_format) ? $moment(item.body_json.out_format).format('LL') : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (item.body_json.cost + item.body_json.taxes + item.body_json.utility).toFixed(2) }}
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-info" @click="showServiceModal(index)"
                                                data-toggle="modal" data-target="#serviceResponseModal">
                                                    <span class="icon-view font-12"></span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger" :disabled="!owner"
                                                @click="onDeleteServiceResponse(index)">
                                                    <span class="icon-bin-2 font-12"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" v-else>
                    <div class="form-group align-middle text-center col-12 mb-0 pb-0 mt-5">
                        <p class="mb-0">Aun no se ha proporcionado soluciones</p>
                        <p class="mb-0"><small>"Haga click en
                            <b style="cursor:pointer;">Nueva Solucion</b> para agregar datos"
                        </small></p>
                    </div>
                </div>
            </div>
        </div>
        <service-response-modal
        :ServiceResponse="varServiceResponse"
        :QuoteServiceId="quoteServiceId"
        :owner="owner"
        v-on:saveServiceResponse="saveServiceResponse"
        v-on:closeServiceResponse="closeServiceResponse()">
        </service-response-modal>
    </div>
</template>
<script>
import ServiceResponseModal from './ServiceResponseModal'

export default {
    // props: ["quoteFlight"],
    props: {
        quoteService: {
            type: [Object],
            default () {
                return false
            }
        },
        lastUpdateLocal: {
            type: Number
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    components: {
        ServiceResponseModal
    },
    data() {
        return {
            varServiceResponse: null,
            quoteServiceId:     null,
            showResponse:       true,
            loadComplete:       false,
            serviceResponse:    null,
        };
    },
    watch: {
        lastUpdateLocal: function(val, oldVal) {
            let vm = this;
            if (vm.lastUpdateLocal != null) {
                vm.initServiceResponse();
            }
        },
    },
    methods: {
        async initServiceResponse() {
            let vm = this;
            vm.loadComplete = false;
            if (vm.quoteService != null && vm.quoteService.quote_service_response && vm.quoteService.quote_service_response.length > 0) {
                let varTemp = vm.quoteService.quote_service_response;
                await varTemp.reduce(async (promise, thisItem) => {
                    await promise;
                    try {
                        if (thisItem.body_json && JSON.parse(thisItem.body_json)) {
                            thisItem.body_json = JSON.parse(thisItem.body_json);
                        }
                    } catch (e) {
                        console.log("error JSON.parse(thisItem.body_json)");
                    }
                }, Promise.resolve());
                vm.serviceResponse = varTemp;
                vm.loadComplete = true;
            } else {
                setTimeout(() => {
                    vm.loadComplete = true;
                }, 400);
            }
        },
        saveServiceResponse(obj) {
            let vm = this;
            vm.loadComplete = false;
            vm.$emit("saveServiceResponse", obj);
        },
        getServiceName($type) {
            if($type) {
                let name = '';
                name = ($type=="RENT_A_CAR") ? "Renta de Vehiculo" : name ;
                name = ($type=="TO_HOTEL") ? "Transporte a Hotel" : name ;
                name = ($type=="TO_ADDRESS") ? "Transporte hacia direccion" : name ;
                return name;
            }
        },
        onDeleteServiceResponse(index) {
            let vm = this;
            $.confirm.delete(
                'Eliminar Solucion de Servicio de Transporte',
                `¿Está seguro que desea eliminar esta solucion?`,
                function(){
                    vm.deleteServiceResponse(index);
                }
            )
        },
        async deleteServiceResponse(index) {
            let vm = this;
            vm.loadComplete = false;
            await axios.post('/admin/quotes/services/delete', {id: vm.serviceResponse[index].id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                }
                if(data.success) {
                    let obj = data.success;
                    vm.$snotify.success('Solucion borrada con exito.');
                    vm.saveServiceResponse(obj);
                }
                return;
            });
        },
        showServiceModal(index = null) {
            let vm = this;
            if (index!=null) {
                vm.varServiceResponse = vm.serviceResponse[index];
            }
            vm.quoteServiceId = vm.quoteService.id;
        },
        closeServiceResponse() {
            this.quoteServiceId = null;
            this.varServiceResponse = null;
        }
    },
    computed: {
        
    },
    mounted() {
        this.initServiceResponse();
    }
}
</script>
<style scoped>
    .table-detail-flight p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .font-12 {
        font-size: 12px;
    }
    .option-route:hover .btn-option-route{
        opacity: 1;
    }
    .btn-option-route{
        opacity: 0;
        transition: 0.3s;
    }
</style>