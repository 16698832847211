<style lang="scss" scoped>
@import '~/../sass/_variables.scss';
.loading{
    position: relative;
    &-dots{
        display: inline-block;
        position: relative;
        padding: 0 1.5em;
        span{
            display: inline-block;
            width: 1em;
            height: 1em;
            border-radius: 1em;
            background-color: $color-primary;
            animation: loadingDost 1s infinite ease-in-out;
            animation-delay: 0.1s;
        }
        &::after,
            &::before{
                content: '';
                position: absolute;
                width: 1em;
                height: 1em;
                border-radius: 1em;
                display: inline-block;
                background-color: $color-primary;

            }
            &::after{
                left: 0;
                animation: loadingDost 1s infinite ease-in-out;
                animation-delay: 0.2s;
            }
            &::before{
                right: 0;
                animation: loadingDost 1s infinite ease-in-out;
                animation-delay: 0s;
            }
    }
}
@keyframes loadingDost {
    0%{
        transform: translateY(50%);
        opacity: 0.5;
    }
    50%{
        transform: translateY(-50%);
        opacity: 1;
    }
    100%{
        transform: translateY(50%);
        opacity: 0.5;
    }
}
</style>
<template>
    <div>
        <span class="loading loading-dots">
            <span></span>
        </span>
        <div>{{ message }}</div>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default() {
                return ''
            }
        }
    }
}
</script>
