<template>
    <div class="container" v-if="user.id">
        <div class="alert" v-if="errors.global">{{ errors.global }}</div>
        <div class="section px-4 py-4">
            <div class="row ">

                <div class="col-sm-12  col-lg-2 my-3">
                    <!--<p class="text-center font-weight-500">Imagen de la cuenta</p>-->
                    <div class="image-container sticky">
                        <user-image :image="user.photo || '/img/none.png'" :uid="user.id" is-enabled/>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-9">
                    <h2>Información de la cuenta</h2>
                    <hr>
                    <form autocomplete="off" @submit.prevent="update" class="needs-validation" novalidate method="post">
                        <p class="subtitle">INFORMACIÓN GENERAL</p>
                        <div class="form-row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="first_name">Nombres</label>
                                    <input type="text" id="first_name" class="form-control" :class="{ 'is-invalid': errors &&  errors.first_name }" v-model="user.first_name">
                                    <div class="invalid-feedback"> {{errors !== null && 'first_name' in errors ? errors.first_name.join('\n') : ""}} </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="last_name">Apellidos</label>
                                    <input type="text" id="last_name" class="form-control" :class="{ 'is-invalid': errors &&  errors.last_name }" v-model="user.last_name">
                                    <div class="invalid-feedback"> {{errors !== null  && 'last_name' in errors ? errors.last_name.join('\n') : ""}} </div>
                                </div>
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="username">Usuario</label>
                                    <input type="text" id="username" class="form-control" :class="{ 'is-invalid': errors && errors.username }" v-model="user.username">
                                    <div class="invalid-feedback"> {{errors !== null  && 'username' in errors ? errors.username.join('\n') : ""}} </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="email">Correo</label>
                                    <input type="text" id="email" class="form-control" :class="{ 'is-invalid': errors &&  errors.email }" v-model="user.email">
                                    <div class="invalid-feedback"> {{errors !== null  && 'email' in errors ? errors.email.join('\n') : ""}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <tag-input 
                                        ref="tagRoles"
                                        title="Niveles de Acceso"
                                        placeholder="Buscar Nivel"
                                        :show-focus="true"
                                        :data="rolesList"
                                        :value="userRoles"
                                         @afterAdd="addRole"
                                         @beforeRemove="checkRole"
                                         @afterRemove="removeRole"
                                        /> 
                                </div>
                            </div>    
                        </div>
                        <p class="subtitle">CAMBIO DE CONTRASEÑA</p>
                        <div class="form-row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="password">Contraseña Actual</label>
                                    <input type="password" id="current_password" class="form-control" :class="{ 'is-invalid': errors &&  errors.current_password }" v-model="passwordActual">
                                    <div class="invalid-feedback"> {{errors !== null  && 'current_password' in errors ? errors.current_password.join('\n') : ""}} </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="password">Nueva Contraseña</label>
                                    <input type="password" id="password" class="form-control" :class="{ 'is-invalid': errors &&  errors.password }" v-model="password">
                                    <div class="invalid-feedback"> {{errors !== null  && 'password' in errors ? errors.password.join('\n') : ""}} </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="form-group">
                                    <label for="password_confirmation">Comprobar Contraseña</label>
                                    <input type="password" id="password_confirmation" class="form-control" v-model="passwordConfirmation">
                                </div>
                            </div>
                        </div>
                        <hr v-if="user.id">
                        <div class="form-row" v-if="user.id">
                            <div class="col-lg-12">
                                <div class="d-flex flex-row justify-content-between">
                                    <button class="btn btn-secondary mr-2 align-self-start" type="submit">Guardar Cambios</button>
                                    <div>
                                        <button class="btn btn-danger align-self-end" type="button" @click.prevent="disableAccount">{{ user.active == 1 ? 'Inhabilitar Cuenta' : 'Habilitar Cuenta'}}</button>
                                        <button class="btn btn-default align-self-end" type="button" @click.prevent="goBack">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container card d-flex mt-5 px-4 py-4" v-else>

    </div>
</template>

<script>

    'use strict'
    import userImage from '~/components/users/userImage'
    import tokenInput from '~/components/tokenInput'
    import tagInput from '~/components/tagInput'
    import { mapGetters } from 'vuex'
    import { debuglog } from 'util';

    export default {
        middleware:'auth',
        layout: 'dashboard',
        components: {
            'user-image': userImage,
            'token-input': tokenInput,
            tagInput
        },
        metaInfo () {
            return { title: "User" }
        },
        data: () => ({
            errors: {},
            passwordActual: null,
            password: null,
            passwordConfirmation: null,
            user: {},
            roles: []
        }),
         
        computed: {
            userRoles () {
                return this.user.roles.map(r => {
                    return {
                        id: r.id,
                        label: r.title,
                        queryMatch: `${r.title} ${r.description} ${r.authority}`
                    }
                })
            },
            rolesList () {
                return this.roles.map(r => {
                    return {
                        id: r.id,
                        label: r.title,
                        queryMatch: `${r.title} ${r.description} ${r.authority}`
                    }
                })
            },
            highRole () {
               let h  = null
               Object.keys(this.ownerRoles.roles).forEach(key => {
                   if(!h) {
                       h = this.ownerRoles.roles[key]
                       return
                   }

                   if(this.ownerRoles.roles[key].authority > h.authority) {
                       h = this.ownerRoles.roles[key]
                       return
                   }
                   
               })
               return h
            },
            ...mapGetters({
                ownerRoles : 'permission/access', 
                userAuth : 'auth/user',
            })
        },
        created() {  
            this.getRolesList()
            if(! ('user' in this.$route.params)) {
                this.getUserData()
                return
            }
            this.user = this.$route.params.user;
        },
      
        watch:{
            $route (to, from){
                this.getUserData()
            }
        },
        methods: {
            update: function () {
                let vm = this
                //JSON.parse('uno-do')
                let params = this.user
                params.current_password = this.passwordActual
                params.password = this.password
                params.password_confirmation = this.passwordConfirmation
                axios
                    .post('/admin/user/update', params)
                    .then(response => {
                        if(response.data.success === false){
                            const { error, message } = response.data
                            this.errors = (typeof error === 'string') ? [error] : (typeof message !== 'undefined') ? [message] : error
                            return
                        }
                        this.errors = {}
                        vm.$snotify.success('Información actualizada.')
                        // this.$router.push({name: 'user-edit'})
                    })
                .catch(error => { })
            },
            getUserData: function () {
                axios
                    .get('/admin/user/find/'+this.userAuth.username)
                    .then(response => { 
                        let { user } = response.data
                        if(!user){
                            this.$router.push({name: 'users-index'})
                            return
                        }
                        this.user = user
                    })
                .catch(error => { })
            },
            getRolesList: function () {
                axios
                    .get('/admin/permission/roles/from/hight')
                    .then(response => {
                        if(!response.data.success) return
                        this.roles = response.data.roles
                    })
            },
            disableAccount: function () {
                let vm = this
                axios
                    .post('/admin/user/state', {
                        id: vm.user.id,
                        state: !(vm.user.active == 1)
                    })
                .then(response => {
                    if(!response.data.success) {
                        m.$snotify.warning("No se pudo actualizar su estado.")                        
                        return;
                    }
                    vm.user.active = Number(!(vm.user.active == 1))
                    let label = vm.user.active ? 'Usuario habilitado.' : 'Usuario inhabilitado'
                    vm.$snotify.success(label);
                })
            },
            goBack: function () {
                this.$router.go(-1)
            },
            checkRole (data) {
                let vm = this
                if(vm.highRole.id == data.item.id) { 
                    vm.$refs.tagRoles.remove = false
                    vm.$snotify.warning("No se puede eliminar su rol mas alto.")
                } else {
                    vm.$refs.tagRoles.remove = true
                }
            },
            removeRole: function (data) {
                let roles = this.user.roles.filter(r => {
                        let item_index = data.items.findIndex(item => {
                            return item.id == r.id
                        })

                        if(item_index >= 0) {
                            return true
                        }
                })
                axios
                    .post('/admin/permission/roles/revoke', { items: roles })
                    .then(response => { })
            },
            addRole: function (data) {
                axios
                    .post('/admin/permission/roles/add', { role_id: data.item.id, user_id: this.user.id })
                    .then(response => {
                        // const index = this.user.roles.findIndex(element =>  element.role_id === item.role_id)

                        // this.user.roles[index].assigned_role_id = response.data.assigned_role.assigned_role_id
                    })
            }
        }
    }
</script>

<style scoped>
    .tag{
        display: inline-block;
        border-radius: 30px;
        background-color: #eeeeee;
        color: #3e85f7;
        margin: 0 5px;
        padding: 0 1rem;
    }

    .subtitle {
        font-size: 0.9em;
        color: #a7a7a7;
        margin: 0.2em;
    }

    .font-weight-500{
        font-weight: 500
    }

    .sticky {
        position: sticky;
        top: 0;
    }

</style>