import vue from 'vue'
import vueRouter from 'vue-router'
import vueMeta from 'vue-meta'
import { sync } from 'vuex-router-sync'
import store from '~/store'
// import preload from "@badrap/preload";
import routeImporter from '~/helpers/routeImporter'
import middlewareImporter from '~/helpers/middlewareImporter'

//Carga las rutas de manera dinamica
let requireRoutes = require.context('~/pages', true, /.*\.vue$/)
const ROUTES = Object.values(routeImporter(requireRoutes))

//Carga los middlewares de manera dinamica
let requireMiddleware = require.context('./middleware', false, /.*\.js$/)
const MIDDLEWARES = middlewareImporter(requireMiddleware)

vue.use(vueMeta)
vue.use(vueRouter)

// Estos middlewares se aplican a cada ruta de la aplicacion
const GLOBALMIDDLEWARE = ['locale', 'check-auth', 'access']
const ROUTER = createRouter(ROUTES)

// Mantiene sincronizado el enrutador con almacenamieto Vuex
sync(store, ROUTER)

vue.mixin({
    async beforeRouteUpdate (to, from, next) {

        const { asyncData } = this.$options
        if (!(asyncData instanceof Function))
            return next()

        // Se ejecuta al reutilizar un componente para diferente rutas
        asyncDataCallback(this, asyncData, to, next)
    },
    async beforeRouteEnter (to, from, next){

        const [component] = ROUTER.getMatchedComponents({ ...to }).slice(-1)

        // La primera vez que entra al evento "beforeRouteEnter" se ya se ha ejecutado el
        // evento global "beforeEach" asi que asigna un bandera global (asyncDataHook) para
        // evitar llamar la funcion "asyncData" mas de una vez al inicio
        if (!component || ROUTER.app.asyncDataHook === false){
            ROUTER.app.asyncDataHook = true
            return next()
        }

        const asyncDataHook = component.asyncData
        if (typeof asyncDataHook !== 'function')
            return next()

        asyncDataCallback(null, asyncDataHook, to, next)
    },
    // En el caso del evento "beforeRouteEnter" el componente no funciona
    // por ese motivo asigna los datos a la ruta y al componente cuando este se crea
    created(){
        
        let vm = this
        if(typeof vm.$route === 'undefined')
            return

        let asyncData = vm.$route.params.asyncDataParams
        if(typeof asyncData === 'undefined' || asyncData == null)
            return

        if(vm.$route.name == vm.$options.name){
            Object.keys(asyncData).forEach(key => {
                vm[key] = asyncData[key]
            })
            delete vm.$route.params.asyncDataParams
        }

    }
})

/**
 * Crea una nueva instancia del enrutador.
 *
 * @return {Router}
 */
function createRouter(routes) {
    var router = new vueRouter({
        scrollBehavior,
        mode: 'history',
        routes
    })

    router.beforeEach(beforeEach)
    router.afterEach(afterEach)

    return router
}

async function asyncDataCallback(component, callback, to, next, name){

    try {

        var response = await callback(ROUTER.app, to, store, redirect)
        if(typeof response === 'undefined')
            return next()

        if(typeof response !== 'object')
            throw "[asyncData] Invalid response format, object expected."

        if(component !== null){
            Object.keys(response).forEach(key => {
                component[key] = response[key]
            })
            // component.$emit('dataLoaded', response)
            console.log('NEXT 1', response)
            next()
        }else{
            to.params.asyncDataParams = response
            next(vm => {
                console.log('NEXT 2', response)
                Object.keys(response).forEach(key => {
                    vm[key] = response[key]
                })
                // vm.$emit('dataLoaded', response)
            })
        }

    } catch (error) {
        ROUTER.app.$loading.fail()
        next()
        throw error
    }
}

/**
 * Validacion de acceso antes de mostrar cada pagina.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) {

    //Obtiene el componente al cual se desea navegar
    let matchedComps = ROUTER.getMatchedComponents({ ...to })
    const components = await resolveComponents(matchedComps)

    if (components.length === 0)
        return next()

    const [component] = components.slice(-1)

    //Inicia la barra "loading..."
    if (component.loading !== false)
        ROUTER.app.$nextTick(() => ROUTER.app.$loading.start())

    //Aplica los middleware para cada compenente
    const middleware = getMiddleware(components)

    //Llama cada middleware.
    const nextCallback = (...args) => {

        //Despues de validar los middleware asigna el Layout
        if (args.length === 0)
            ROUTER.app.setLayout(component.layout || '')

        next(...args)
    }

    const asyncDataHook = component.asyncData
    if (typeof asyncDataHook !== 'function' || ROUTER.app.asyncDataHook === true){
        callMiddleware(middleware, to, from, nextCallback)
        return
    }

    let route   = to
    let context = ROUTER.app

    try {

        var response = await asyncDataHook(context, route, store, redirect)

        if(typeof response === 'undefined')
            return next()

        if(typeof response !== 'object')
            throw "[asyncData] Invalid response format, object expected."

        let data = typeof component.data === 'function' ? component.data() : component.data

        // Se asigna como valor por defectos de las propiedades del componente
        // antes de que este se renderice por primera vez
        vue.set(component, 'data', () => {
            return { ...data, ...response }
        })

        callMiddleware(middleware, to, from, nextCallback)

    } catch (error) {
        ROUTER.app.$loading.fail()
        callMiddleware(middleware, to, from, nextCallback)
        throw error
    }
}

/**
 * Despues de cargar cada ruta.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach(to, from, next) {

    //Permite ejecutar los procesos cuando cambien los datos aun antes de que el navegador
    //renderice los cambios - alternativa a 'setTimeout'
    await ROUTER.app.$nextTick()

    //Oculta la barra de carga
    ROUTER.app.$loading.finish()
}

/**
 * Recorre y ejecuta cada middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware(middleware, to, from, next) {

    //Ordera el array de manera descendente
    const stack = middleware.reverse()

    const _next = (...args) => {

        // Valida si la funcion '_next' fue llamada con argumentos y el array 'stack' esta vacio
        if (args.length > 0 || stack.length === 0) {

            if (args.length > 0)
                ROUTER.app.$loading.finish()

            return next(...args)
        }

        // Obtiene el ultimo elemento del array
        const middleware = stack.pop()

        if (typeof middleware === 'function')
            middleware(to, from, _next)
        else if (MIDDLEWARES[middleware])
            MIDDLEWARES[middleware](to, from, _next)
        else
            throw Error(`Undefined middleware [${middleware}]`)
    }

    //Aplica los middlewares
    _next()
}

/**
 * Resuelve componentes asincronos.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}

/**
 * Combina los middleware globales con los middleware de los componenetes
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware(components) {
    const middleware = [...GLOBALMIDDLEWARE]

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware))
            middleware.push(...component.middleware)
        else
            middleware.push(component.middleware)
    })

    return middleware
}

/**
 * Recuerda la ultima configuracion del scroll
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {

    if (savedPosition)
        return savedPosition

    if (to.hash)
        return { selector: to.hash }

    const [component] = ROUTER.getMatchedComponents({ ...to }).slice(-1)

    if (component && component.scrollToTop === false)
        return {}

    return { x: 0, y: 0 }
}

function redirect(to) {
    ROUTER.replace(to)
}

export default ROUTER
