<template>
    <div class="chat-messages-container">
       
        <div class="chat-panel-message " 
            v-for="channel in openChannels"
            :key="channel.channel_id"
            v-show="channel.show && !channel.out"
            
        >
            <div class="chat-panel-message-wrapper px-1" 
                :class="{ 
                    'chat-open': channel.open,
                    
                }"
                v-click-outside="channelDesactive"
                @click.stop="changeActiveChannel(channel.channel_id)">
                <header class="chat-panel-message-header px-2"
                        :class="{
                            'chat-active': channel.channel_id == activeChannel,
                            'chat-new':  messagesPending(channel.channel_id) > 0
                        }"
                        @click.prevent="channel.open = !channel.open">
                    <div class="position-relative mr-2">
                        <div class="chat-list-item-photo-group"
                            :class="{
                                'chat-one': channel.users.length == 1,
                                'chat-two': channel.users.length == 2,
                                'chat-three': channel.users.length == 3,
                                'chat-four': channel.users.length >= 4
                            }">
                            <img @error="defaultImg" v-for="member in randomMembersGroup(channel.users)" :key="member.id" :src="member.photo || '/img/none.png'" alt="">
                        </div>
                 
                        <span class="chat-pending-indicator" v-show="messagesPending(channel.channel_id) > 0">{{ messagesPending(channel.channel_id) }}</span>
                        <span v-show="isOnline(channel.users)" class="chat-list-item-status" :class="{
                            'chat-list-item-online': isOnline(channel.users)
                        }"></span>
                    </div>
                    <h6 class="title position-relative m-0">
                        {{channel.title}}
                        
                    </h6>
                    <button class="chat-btn-close center-content" @click.stop.prevent="closeChannel(channel)"><span class="icon-close"></span></button>
                </header>
                <vue-chat-messages-body
                    class="chat-panel-message-body"
                        @loadMessagesPage="onLoadMessagesPage"
                    :channel="channel"
                    :user="user"
                    />
                <div class="chat-panel-message-footer">
                    <vue-chat-new-message 
                        :channel_id="channel.di"
                        :channel="channel.channel_id"
                        :active-channel="activeChannel"
                        :user="user"
                        
                        ></vue-chat-new-message>
                </div>

            </div>
           
        </div>
        <div class="chat-panel-message-out" v-show="existOut"  v-click-outside="hideOut">
            <div class="chat-panel-message-out-btn center-content" @click.stop="showOut"><span class="icon-bubble-typing"></span></div>
            <div class="chat-panel-message-out-wrapper"
                :class="{ 'active' : show_out }">
                <div  class="chat-panel-message-out-item" v-for="channel in openChannels"  
                    :key="channel.channel_id"
                    v-show="channel.show && channel.out"
                    @click.stop="showChannel(channel)">
              
                    <div class="position-relative mr-2">
                        <div class="chat-list-item-photo-group"
                            :class="{
                                'chat-one': channel.users.length == 1,
                                'chat-two': channel.users.length == 2,
                                'chat-three': channel.users.length == 3,
                                'chat-four': channel.users.length >= 4
                            }">
                            <img @error="defaultImg" v-for="member in randomMembersGroup(channel.users)" :key="member.id" :src="member.photo || '/img/none.png'" alt="">
                        </div>
                        <span class="chat-panel-message-header-pending" v-show="messagesPending(channel.channel_id) > 0">{{ messagesPending(channel.channel_id) }}</span>
                        <span v-show="isOnline(channel.users)" class="chat-list-item-status" :class="{
                            'chat-list-item-online': isOnline(channel.users)
                        }"></span>
                    </div>
                    {{channel.title}}
                    <span class="chat-panel-message-out-pending" v-show="messagesPending(channel.channel_id) > 0">{{ messagesPending(channel.channel_id) }}</span>
                    <button class="chat-btn-close" @click.stop.prevent="closeChannel (channel)"><span class="icon-close"></span></button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import VueChatMessagesBody from './VueChatMessagesBody'
import VueChatNewMessage from './VueChatNewMessage'
export default {
    data () {
        return {
            show_out: false
        }
    },
    components: {
        VueChatMessagesBody,
        VueChatNewMessage
    },
    props: ['openChannels', 'activeChannel', 'user', 'conversations'],
    computed: {
        existOut() {
            let channel = this.openChannels.find(c => {
                return c.out == true && c.show == true
            })
            if(channel) {
                return true
            }
            return false
        }
    },
    methods: {
        randomMembersGroup (members) {
            let vm = this
            let m = []
            let total_show = members.length <= 4 ? members.length : 4
            for(let i = 0; i < total_show; i++) {
                let new_m = vm.getRandom(members, m)
                m.push(new_m)
            }
            return m
            
        },
        getRandom (members, show_members) {
            let m = members[Math.floor(Math.random() * members.length)]
            let m_find = show_members.find( user => {
                return user.id == m.id
            })
            if(m_find) {
                return this.getRandom(members, show_members)
            }
            return m
        },
        isOnline (users) {
            let user_online = users.find(u => {
                return u.online == true
            })

            if(user_online) {
                return true
            }
            return false
        },
        showChannel (channel) {
            channel.show = true
            channel.out = false
            this.$emit('changeActiveChannel', channel.channel_id)
        },
        closeChannel (channel) {
            channel.show = false
            window.dispatchEvent(new Event('resize'))
        },
        showOut () {
            this.show_out = !this.show_out
        },
        hideOut () {
            this.show_out = false
        },
        changeActiveChannel (id) {
            this.$emit('changeActiveChannel', id)
            this.eventHub.$emit('update-picker-emoji', false)
            this.show_out = false
        },
        onLoadMessagesPage(data){
            this.$emit('loadMessagesPage', data.channel_id, data.page, data.$el)
        },
       
        messagesPending (channel) {
            let conversation = this.conversations.find(c => {
                return c.channel.channel == channel
            })
            if(!conversation) return 0
            return conversation.messages_pending
        },
        channelDesactive() {
            this.changeActiveChannel(null)
        }
    },

    updated () {
        console.log("Hey")
        if(this.activeChannel) {
            try{
                document.getElementById(`textarea-${this.activeChannel}`).focus()
            } catch (err) {
                // console.log(err)
            }
        }

        window.dispatchEvent(new Event('resize'))
    },
}
</script>

<style scoped>
.column {
    overflow-y: auto;
}

.message {
    /* background: #000; */
}

.datetime {
    color: turquoise;
}

.username {
    color: green;
}
</style>