class YandexTranslate {
    constructor ( apiKey ){
      this.apiKey = apiKey
      this.url = 'https://translate.yandex.net/api/v1.5/tr.json/translate'
    }
  
    translate ( data, callback ) {
        let lang = data.lang
        let text = data.text
        let output = this.fetchTranslate(lang, text).then(data => {
            return callback(data.text[0])
        })
       return output
    }

    fetchTranslate (lang, text) {
        let url = new URL(this.url),
            params = {
                key: this.apiKey,
                lang: lang,
                text: text,
                format: 'plain'
            }

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

        let output = fetch(url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            }
        }).then( res => {
            return res.json()
        })
        
        return output
    }
}

export default YandexTranslate;