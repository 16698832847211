<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 col-lg-4 pl-0 pr-0 pr-lg-3">
            <div class="col-md-12 py-3 section">
                <h5 class="font-weight-bold mb-0">Nuevo elemento</h5>
                <p class="text-muted">Agregar nuevo elemento de menu a la lista</p>
                <form id="pageForm" autocomplete="off" @submit.prevent="saveMenu" novalidate>
                    <div class="form-group">
                        <label for="title">Titulo</label>
                        <input type="text" class="form-control" v-model="pageForm.title" id="title">
                    </div>
                    <!-- <div class="form-group">
                        <label for="translate">Traduccion</label>
                        <input type="text" class="form-control" v-model="form.translate" id="translate" v-bind:class="{ 'is-invalid': form.errors.translate }">
                        <span class="invalid-feedback" v-if="form.errors.translate" v-for="error in form.errors.translate" :key="error.translate">{{ error }}</span>
                    </div> -->
                    <div class="form-row">
                        <div class="col-12 col-lg-12">
                            <div class="form-group">
                                <label for="url">Url</label>
                                <input type="text" class="form-control" v-can:manage.disable v-model="pageForm.url" id="url" aria-describedby="urldesc">
                                <small id="urldesc" class="form-text text-muted">Esta informacion solo puede ser asignada por un desarrollador.</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <label for="type">Tipo</label>
                                <select name="type" v-can:manage.disable class="form-control menu-type" @change="onMenuTypeChange">
                                    <option value="1">Action Menu</option>
                                    <option value="2">Empty Menu</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="form-group">
                                <label for="icon">Icono</label>
                                <input type="text" class="form-control" v-model="pageForm.icon" id="icon" placeholder="icon-*">
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary" v-can:write.disable>Guardar</button>
                    <button class="btn btn-secondary" v-if="pageForm.id != null" @click.prevent="clearData">Cancelar</button>
                </form>
            </div>
        </div>
        <div class="col col-12 col-lg-8 mt-3 mt-lg-0 py-2 section">
            <h5 class="font-weight-bold mb-0">Diagrama de Menu</h5>
            <p class="text-muted">Páginas del Dashboard</p>

            <div class="dd" id="nestableMenu">
                <ol class="dd-list" v-if="!_.isEmpty(menu)">
                    <li class="dd-item dd3-item" v-for="menuItem in menu" :key="menuItem.id" :data-id="menuItem.id" :data-title="menuItem.title" :data-icon="menuItem.icon">
                        <button data-action="collapse" type="button" v-if="menuItem.childrens">Collapse</button>
                        <button data-action="expand" type="button" style="display: none;" v-if="menuItem.childrens">Expand</button>
                        <div class="dd-handle dd3-handle" v-can:write.disabled></div>
                        <div class="dd3-content">
                            <span class="d-none d-sm-inline mr-2" :class="menuItem.icon"></span> 
                            <b>{{ menuItem.title }}</b> 
                            <span class="d-none d-sm-inline">|</span> 
                            <small class="text-muted d-none d-sm-inline">{{ menuItem.url }}</small> 
                            <div class="float-right menu-options" v-can:write>
                                <button v-can:delete.disable class="btn btn-transparent text-danger" @click.prevent="onDeleteItem(menuItem)"><span class="icon-bin"></span></button>
                                <button v-can:write.disable class="btn btn-transparent" @click.prevent="onEdithItem(menuItem)"><span class="icon-pencil-edith"></span></button>
                            </div>
                        </div>
                        <ol class="dd-list" v-if="menuItem.childrens">
                            <li class="dd-item dd3-item" v-for="menuChild in menuItem.childrens" :key="menuChild.id" :data-id="menuChild.id" :data-title="menuChild.title" :data-icon="menuChild.icon">
                                <div class="dd-handle dd3-handle" v-can:write.disabled></div>
                                <div class="dd3-content">
                                    <span class="d-none d-sm-inline mr-2" :class="menuChild.icon"></span> 
                                    <b>{{ menuChild.title }}</b> 
                                    <span class="d-none d-sm-inline">|</span> 
                                    <small class="text-muted d-none d-sm-inline">{{ menuChild.url }}</small> 
                                    <div class="float-right menu-options" v-can:write>
                                        <button v-can:delete.disable class="btn btn-transparent text-danger" @click.prevent="onDeleteItem(menuChild)"><span class="icon-bin"></span></button>
                                        <button v-can:write.disable class="btn btn-transparent" @click.prevent="onEdithItem(menuChild)"><span class="icon-pencil-edith"></span></button>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </li>
                </ol>
                <loading v-else title="Cargando" message="Obteniendo menu..." />
            </div>
        </div>
    </div>
</template>

<script>

    window.nestable = require('nestable2');

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Pages" }
        },
        data(){
            return {
                pageForm: new WebForm("#pageForm", {
                    id:    null,
                    title: '',
                    url:   '',
                    icon:  '',
                }),
                menu: [],
                loading: true,
            };
        },
        methods: {
            async getMenu(triggerEvent = true){
                let vm = this

                if(triggerEvent)
                    $('#nestableMenu').off("change").nestable('destroy');
                
                let { data } = await axios.get('/admin/page/menu')

                vm.clearData();
                vm.menu = data;

                if(triggerEvent){
                    vm.eventHub.$emit('menu:update', data)
                    vm.$snotify.info('Menu actualizado...');
                }

                setTimeout(() => {
                    $('#nestableMenu').nestable({
                        group: 1,
                        maxDepth : 2,
                    }).on("change", vm.updateMenu);
                }, 500);
            },
            saveMenu(){

                this.pageForm.post('/admin/page/create').then(response => {

                    this.$snotify.success('Elemento almacenado con exito.');

                    //Restaura los controles
                    this.clearData()

                    //Actualiza el menu
                    this.getMenu();
  
                })
            },
            async updateMenu(){
                let menu = JSON.stringify($('#nestableMenu').nestable('serialize'));
                this.$snotify.simple('Datos actualizados.');
                await axios.post('/admin/page/update', { data: menu })
                this.eventHub.$emit('menu:update')
            },
            deleteMenuItem(menuItemId){
                let vm = this
                axios.post('/admin/page/delete', {
                    id: menuItemId
                }).then(response => { 
                    vm.$snotify.success('Elemento eliminado con exito.');
                    vm.getMenu();
                });
            },
            onMenuTypeChange(e){

                if(!this.$can('manage'))
                    return;

                let isEmpty = e.target.value == 2;
                this.form.url = isEmpty ? '#' : '';
                $("#url").attr('disabled', isEmpty)

            },
            onDeleteItem(item){
                var vm   = this;
                let name = `<span class="p-1 text-primary bg-light rounded font-weight-bold"><span class="${item.icon} mr-1"></span> ${item.title}</span>`
                $.confirm.delete(
                    'Eliminar Aeropuerto',
                    `¿Está seguro que desea eliminar el elemento de menu ${name}?`,
                    function(){
                        vm.deleteMenuItem(item.id)
                    }
                )

            },
            onEdithItem(item){
                this.pageForm.fill(item)
            },
            clearData(){
                this.pageForm.reset()
                $("#url").attr('disabled', !this.$can('manage'));
                $('.menu-type').val(1);
            }
        },
        async created() {
            await this.getMenu(false)
            this.loading = false
        },
    }
    

</script>

<style scoped>
</style>