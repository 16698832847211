<template>
    <div>
        <crop-profile-photo v-if="user" ref="cropPhoto" :url="user.photo || '/img/none.png'"></crop-profile-photo>
        <div class="d-flex">
            <div class="user-photo mr-3">
                <img class="rounded-circle" :src="user.photo" :alt="user.first_name">
            </div>
            <div>
                <button class="btn btn-primary" @click="$refs.cropPhoto.chooseFile()">Subir nueva foto</button>
                <button class="btn btn-default">Borrar</button>
                <span class="d-block small text-muted">Formato permitido JPG PNFG o GIF, Maximo 800KB</span>
            </div>

        </div>

        <form id="generalForm" class="mt-4" @submit.prevent="save">
            <div class="form-group">
                <label class="d-block">
                    <span class="d-block font-weight-bold">Nombres</span>
                    <input class="form-control" type="text" name="first_name" id="first_name" v-model="form.first_name">
                </label>
            </div>
            <div class="form-group">
                <label class="d-block">
                    <span class="d-block font-weight-bold">Apellidos</span>
                    <input class="form-control" type="text" name="last_name" id="last_name"  v-model="form.last_name">
                </label>
            </div>

            <h6 class="border-bottom text-uppercase mt-4 mb-3 small font-weight-bold">Informacion Adicional</h6>

            <div class="mb-3">
                <span class="d-block">Fecha de Registro</span>
                <span class="d-block text-muted"><span class="icon-calendar-3"></span> Se unio a Pavolar en {{$moment(user.created_at).format('LL')}}</span>
            </div>
            <div>
                <span class="d-block">Roles de Acceso</span>
                <div>
                    <span class="d-block bg-secondary rounded px-2 py-1 text-uppercase font-weight-bold mb-2" v-for="(role, index) in roles" :key="`role-${role.id}-${index}`">
                        {{ role.role_title }}
                    </span>
                </div>
            </div>

            <div class="mt-3">
                <v-button class="center-content" :type="'primary'" :loading="form.processing">
                    Guardar
                </v-button>
            </div>
        </form>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import CropProfilePhoto from '~/components/cropProfilePhoto'
import vButton from '~/components/button'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    // data() {
    //     let vm = this
    //     return {
    //         form: new WebForm("#generalForm", {
    //             first_name: vm.user.first_name,
    //             last_name : vm.user.last_name,
    //         }),
    //     }
    // },
    data() {
        return {
            form: new WebForm("#generalForm", {
                first_name: '',
                last_name : '',
            }),
        }
    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
            displayName : 'auth/displayName',
            roles: 'permission/roles'
        })
    },

    watch: {
        user: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                this.form.first_name = newVal.first_name
                this.form.last_name = newVal.last_name
            }
        }
    },
    components: {
        CropProfilePhoto,
        vButton
    },
    methods: {
        save() {
            let endpoint = '/admin/user/update/part'
            this.form.post(endpoint).then(response => {
                if(response.data.id) {
                    debugger
                    this.$store.commit('auth/UPDATE_USER', {user: response.data})
                    this.$snotify.success('Guardado')
                }
            })
        }
    }
}
</script>
