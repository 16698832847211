<template>
    <div id="app">
        <vue-snotify/>
        <loading-bar ref="loading"/>
        <transition name="fade-slide" mode="out-in">
            <component v-if="layout" :is="layout"/>
        </transition>
    </div>
</template>

<script>
    "use strict";

    import vueImporter from '~/helpers/vueImporter';
    import loadingBar from './components/loadingBar';

    const requireContext = require.context('./layouts', false, /.*\.vue$/)
    const layouts = vueImporter(requireContext, 'rootLayout')

    export default {
        el: '#app',
        components: {
            loadingBar
        },
        data: () => ({
            layout: null,
            asyncDataHook: false,
            defaultLayout: 'default'
        }),
        metaInfo () {
            const appName = "Pavolar Admin"
            return {
                title: appName,
                titleTemplate: `${appName} - %s`
            }
        },
        methods: {
            setLayout (layoutKey) {
                if (!layoutKey || !layouts[layoutKey])
                    layoutKey = this.defaultLayout
                this.layout = layouts[layoutKey]
            },
        },  
        mounted () {
            this.$loading = this.$refs.loading
            
            // Con este evento actualiza el estado del usuario activo
            window.addEventListener("beforeunload", function (e) {
                axios.post('/admin/account/state', { disableStatus : true })
            });
        },
      
    }
</script>