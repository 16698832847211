import { render, staticRenderFns } from "./VueChat.vue?vue&type=template&id=2e37abed&"
import script from "./VueChat.vue?vue&type=script&lang=js&"
export * from "./VueChat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports