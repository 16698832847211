<template>
    <div class="mx-3">
        <div class="row">
            <div class="col-12 section py-3">
                <div class="d-flex justify-content-between">
                    <div>
                        <h5 class="font-weight-bold mb-0">Cuentas de Usuario</h5>
                        <p class="text-muted mb-1">Administración de cuentas de usuarios</p>
                    </div>
                    <div class="d-flex">
                        <!-- <div class="mr-1">
                            <button class="btn shadow-none text-primary"><span class="icon-layout-grid-2"></span></button>
                            <button class="btn shadow-none"><span class="icon-layout-grid"></span></button>
                        </div> -->
                        <div class="form-group has-search mb-1">
                            <span class="icon-search form-control-feedback" grid="16"></span>
                            <input type="text" class="form-control" placeholder="Realizar búsqueda..." v-model="search">
                        </div>
                    </div>
                </div>
                <div class="scrolling outer mt-2">
                    <div v-show="users.length == 0">
                        <card-loader :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"/>
                        <card-loader :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"/>
                        <card-loader :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"/>
                        <card-loader :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"/>
                        <card-loader :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"/>
                    </div>
                   
                    <transition name="fade">
                        <div class="users-container mt-3" v-show="users.length > 0">
                            <user-card v-for="user in filterUserList" :user="user" :key="user.id" v-on:click="userClicked"/>
                        </div>
                    </transition>
                    <button v-can:create class="btn btn-primary position-absolute rounded-circle floating-button shadow" data-toggle="modal" data-target="#addUserModal">
                        <span class="icon-add"></span>
                    </button>
                </div>
            </div>
            <add-user-modal v-on:added="getUsers()"/>
        </div>
    </div>
</template>

<script>
    import addUserModal from '~/components/users/addUserModal'
    import userCard from '~/components/users/userCard'
    import tokenInput from '~/components/tokenInput'
    import CardLoader from '~/components/cardLoader'

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Users" }
        },
        components: {
            addUserModal,
            userCard, 
            tokenInput,
            CardLoader
        },
        computed: {
            filterUserList() {
                return this.users.filter(user => {
                    
                    let search     = this.search.toLowerCase()
                    let last_name  = user.last_name.toLowerCase()
                    let first_name = user.first_name.toLowerCase()

                    return last_name.includes(search) || first_name.includes(search) || `${first_name} ${last_name}`.includes(search)
                })
            }
        },
        data: function() {
            return {
                users: [],
                search: '',
                loading: true,
                user: []
            }
        },
        methods: {
            getUser(user) {
                this.users.push(user)
            },
            userClicked: function (user) {
                if(!this.$can('update')) return
                this.$router.push({ name: 'users-username-profile', params: { username: user.username }})
            },
            getUsers: function() {
                this.loading = true;
                axios
                    .get('/admin/user/all')
                    .then(response => {
                        this.users = response.data.users
                        this.loading = false
                    })
                .catch(error => { })
            }
        },
        mounted: function () {
            this.getUsers()
        }
    }
</script>

<style scoped>
    .users-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap
    }
    .floating-button {
        width: 56px;
        height: 56px;
        bottom: 16px;
        right: 16px;
        font-size: 1rem;
    }
    .outer {
        position: relative;
        padding: 5px;
        background-color: #f3f3f8;
        border-radius: 3px;
        min-height: 150px;
    }
    .has-search .form-control {
        padding-left: 2.375rem;
    }
    .has-search .form-control-feedback {
        position: absolute;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
    }
</style>