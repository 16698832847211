<template>
    <div>
        <div class="section pt-3 pb-1" v-if="period">
            <div class="d-flex justify-content-between mx-3">
                <div>
                    <h5 class="font-weight-bold mb-0">Periodo Número {{ period.id }}</h5>
                    <p class="text-muted">{{ period.description }}</p>
                    
                    <p class="mb-1 small font-weight-bold">REGLA POR DEFECTO:</p>
                    <div class="d-flex">
                        <div class="bg-light d-inline-flex align-items-center border rounded mr-1 px-2">
                            <span class="icon-coins-bill mr-2"></span> {{ period.default_amount | numeral('$0,000.00') }}
                        </div>
                        <div class="bg-light d-inline-flex align-items-center border rounded ml-1 px-2">
                            <span class="icon-percent mr-2"></span> {{ period.default_percent }}%
                        </div>
                    </div>

                    <p class="mt-3 mb-1 small font-weight-bold">RANGO DE FECHAS:</p>
                    <div class="d-flex">
                        <div class="bg-light d-inline-flex align-items-center border rounded mr-1 px-2">
                            <span class="icon-calendar mr-2"></span> {{ period.start_date | moment('LL') }}
                        </div>
                        <span class="icon-right-arrow mt-1"></span>
                        <div class="bg-light d-inline-flex align-items-center border rounded ml-1 px-2">
                            <span class="icon-calendar mr-2"></span> {{ period.end_date | moment('LL') }}
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary center-content d-inline-block" @click="$refs.utilityTest.show(period)">
                        <span class="icon-ia"></span>
                    </button>
                    <button class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#rangeModal">
                        <span class="icon-add-2 mr-2"></span> Nuevo Rango
                    </button>
                    <button class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#ruleModal">
                        <span class="icon-add-2 mr-2"></span> Nueva Regla
                    </button>
                </div>
            </div>
            <hr class="mb-4 mt-3">
            <div class="row mx-0">
                <div class="col-12">
                    
                    <p class="text-muted" v-if="period.ranges.length == 0"> 
                        <span class="icon-alert-warning text-warning mr-1"></span>
                        No hay rangos disponibles por favor 
                        <button class="btn-link btn-transparent px-0" data-toggle="modal" data-target="#rangeModal">agregar uno</button> 
                        para continuar...
                    </p>

                    <div v-else class="table-responsive">
                        <p class="text-muted" v-if="period.rules.length == 0"> 
                            <span class="icon-alert-warning text-warning mr-1"></span>
                            Debe agregar un 
                            <button class="btn-link btn-transparent px-0" data-toggle="modal" data-target="#ruleModal">reglar</button> 
                            para continuar...
                        </p>
                        <draggable v-else v-show="!loadingTable" class="table table-borderless table-rule" v-model="period.rules" tag="table" handle=".handle" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="onDragEnd">
                            <transition-group tag="tbody" type="transition" :name="!drag ? 'flip-list' : null">
                                <tr class="rule-row bg-white" v-for="rule in period.rules" :key="rule.id">
                                    <td class="align-middle handle cursor-all-scroll">
                                        <span class="icon-menu-6-bold" grid="12"></span>
                                    </td>
                                    <td class="align-middle font-weight-bold">
                                        {{ rule.id }}
                                    </td>
                                    <td class="data-column">
                                        <div v-if="rule.airlines.length > 0">
                                            <div class="rule-item" :title="airline.name" v-for="airline in rule.airlines" :key="airline.id">
                                                <img class="airline-logo" width="20" :src="`/img/airlines/${airline.iata}.png`" onerror="this.src='/img/airlines/default.png';" >
                                                {{airline.name}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="mb-0 text-muted">Todo</p>
                                        </div>
                                    </td>
                                    <td class="data-column">
                                        <div class="d-flex" v-if="rule.fare_classes.length > 0">
                                            <div class="rule-item mr-1 font-weight-bold text-primary" v-for="fare in rule.fare_classes" :key="fare.id">
                                                {{fare.name}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="mb-0 text-muted">Todo</p>
                                        </div>
                                    </td>

                                    <td class="data-column">
                                        <div v-if="rule.departure_regions.length > 0 || rule.departure_countries.length > 0">
                                            <div class="rule-item" :title="region.name" v-for="region in rule.departure_regions" :key="region.id">
                                                {{region.name}}
                                            </div>
                                            <div class="rule-item" :title="country.name" v-for="country in rule.departure_countries" :key="country.id">
                                                <img :src="`https://www.countryflags.io/${country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="country.short_iso_code">
                                                {{country.name}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="mb-0 text-muted">Todo</p>
                                        </div>
                                    </td>
                                    <td class="data-column">
                                        <div v-if="rule.arrival_regions.length > 0 || rule.arrival_countries.length > 0">
                                            <div class="rule-item" :title="region.name" v-for="region in rule.arrival_regions" :key="region.id">
                                                {{region.name}}
                                            </div>
                                            <div class="rule-item" :title="country.name" v-for="country in rule.arrival_countries" :key="country.id">
                                                <img :src="`https://www.countryflags.io/${country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="country.short_iso_code">
                                                {{country.name}}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="mb-0 text-muted">Todo</p>
                                        </div>
                                    </td>
                                    <td class="px-2 align-middle border-right" v-for="(range, iRange) in period.ranges" :key="iRange">
                                        <div class="d-flex">
                                            <div class="text-nowrap mr-2">
                                                <b>$</b>
                                                <input type="number" min="0" class="form-control sm-input d-inline-block" :value="rangeValue(range.id, rule.ranges, 'amount')" :name="`amount${iRange}`" :id="`amount${iRange}`" @input="setRuleAmount($event.target.value, rule, range)">
                                            </div>
                                            <div class="text-nowrap">
                                                <b>%</b> 
                                                <select class="form-control sm-input d-inline-block" :name="`percent${iRange}`" :id="`percent${iRange}`" @input="setRulePercent($event.target.value, rule, range)">
                                                    <option value="0">0</option>
                                                    <option v-for="number in 100" :key="number" :value="number" :selected="rangeValue(range.id, rule.ranges, 'percent') == number" >{{ number | numeral('00') }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="align-middle text-nowrap">
                                        <button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold mx-1 btn-edit" @click="editRule(rule)">
                                            <span class="icon-pencil-edith pointer-events-none"></span> 
                                            Editar
                                        </button>
                                        <button class="btn btn-soft btn-soft-danger btn-rounded btn-sm font-weight-bold mx-1 btn-delete" @click="deleteRule(rule)">
                                            <span class="icon-bin pointer-events-none"></span> 
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            </transition-group>
                        </draggable>
                        <loading :active="loadingTable" title="Cargando" message="Espere un momento..." />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="rangeModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title" v-if="!rangeForm.id">Nuevo Rango de Boletos</h5>
                            <h5 class="modal-title" v-else>Editar Rango de Boletos</h5>
                            <p class="mb-0 text-muted">
                                Dependiendo de la cantidad de boletos vendidos así sera la regla que se aplicara a la venta para calcular la utilidad
                            </p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="rangeForm" autocomplete="off" novalidate @submit.prevent="saveRange">
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="title">Inicio</label>
                                    <input type="number" min="0" v-model="rangeForm.start" class="form-control" name="start" id="start" />
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="title">Fin</label>
                                    <input type="number" min="0" :placeholder="`De ${rangeForm.start} en adelante...`" v-model="rangeForm.end" :disabled="toggle.rangeEnd" class="form-control" name="end" id="end" />
                                    <div class="form-check mt-2">
                                        <input type="checkbox" class="form-check-input" id="rangeEnd" v-model="toggle.rangeEnd" 
                                        @change="() => { 
                                            rangeForm.end = toggle.rangeEnd ? null : 0
                                         }">
                                        <label class="form-check-label" for="rangeEnd">Sin finalización</label>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                </div>
                            </div>
                            <div class="modal-footer pb-0">
                                <button type="submit" class="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="ruleModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="!ruleForm.id">Nueva Regla</h5>
                        <h5 class="modal-title" v-else>Editar Regla</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="ruleForm" autocomplete="off" novalidate @submit.prevent="saveRule">
                            <div class="row">
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="airlines">Aerolineas</label>
                                        <toggle-button v-model="toggle.airlines" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.airlines = [] }" />
                                    </div>
                                    <airline-input v-model="ruleForm.airlines" :disabled="toggle.airlines" :placeholder="toggle.airlines ? 'Todo' : 'Buscar Aerolinea...'" :multiple="true"></airline-input>
                                </div>
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="fareClasses">Clases de Tarifas</label>
                                        <toggle-button v-model="toggle.fareClasses" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.fareClasses = [] }" />
                                    </div>
                                    <v-select v-model="ruleForm.fareClasses" :disabled="toggle.fareClasses" id="fareClasses" :placeholder="toggle.fareClasses ? 'Todo' : 'Seleccionar...'" multiple :options="fareClasses"></v-select>
                                </div>
                                <div class="form-group col-12">
                                    <small class="font-weight-bold"><span class="icon-plane-take-off text-primary"></span> ORIGENES</small>
                                    <hr class="m-0">
                                </div>
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="regions">Regiones</label>
                                        <toggle-button v-model="toggle.departureRegions" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.regions = [] }" />
                                    </div>
                                    <v-select v-model="ruleForm.departureRegions" :reduce="region => region.id" :disabled="toggle.departureRegions" id="departureRegions" :placeholder="toggle.departureRegions ? 'Todo' : 'Seleccionar...'" multiple label="name" :options="regions"></v-select>
                                </div>
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="countries">Paises</label>
                                        <toggle-button v-model="toggle.departureCountries" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.countries = [] }" />
                                    </div>
                                    <v-select v-model="ruleForm.departureCountries" :reduce="country => country.id" :disabled="toggle.departureCountries" id="departureCountries" :placeholder="toggle.departureCountries ? 'Todo' : 'Seleccionar...'" multiple label="name" :options="countries"></v-select>
                                </div>
                                <div class="form-group col-12">
                                    <small class="font-weight-bold"><span class="icon-plane-land text-primary"></span> DESTINOS</small>
                                    <hr class="m-0">
                                </div>
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="regions">Regiones</label>
                                        <toggle-button v-model="toggle.arrivalRegions" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.regions = [] }" />
                                    </div>
                                    <v-select v-model="ruleForm.arrivalRegions" :reduce="region => region.id" :disabled="toggle.arrivalRegions" id="arrivalRegions" :placeholder="toggle.arrivalRegions ? 'Todo' : 'Seleccionar...'" multiple label="name" :options="regions"></v-select>
                                </div>
                                <div class="form-group col-12">
                                    <div class="d-flex justify-content-between">
                                        <label class="mb-1" for="countries">Paises</label>
                                        <toggle-button v-model="toggle.arrivalCountries" :sync="true" title="Todo" data-toggle="tooltip" class="mb-0" color="#38c172" :width="40" @change="() => { ruleForm.countries = [] }" />
                                    </div>
                                    <v-select v-model="ruleForm.arrivalCountries" :reduce="country => country.id" :disabled="toggle.arrivalCountries" id="arrivalCountries" :placeholder="toggle.arrivalCountries ? 'Todo' : 'Seleccionar...'" multiple label="name" :options="countries"></v-select>
                                </div>
                            </div>
                            <div class="modal-footer pb-0">
                                <button type="submit" class="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <sales-utility-test ref="utilityTest" :period="period" :allowRemovePeriod="false" />

    </div>
</template>

<script>

    import draggable from "vuedraggable"
    import SalesUtilityTest from '~/components/salesUtilityTest'
    import airlineInput from '~/components/airlineInput'

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Administrar Periodo" }
        },
        components: {
            draggable,
            SalesUtilityTest,
            airlineInput
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
        },
        data() {
            return {
                drag: false,
                period: null,
                regions: [],
                airlines: [],
                test: null,
                countries: [],
                fareClasses: [],
                favoriteAirlines: [],
                loadingTable: false,
                toggle: {
                    rangeEnd:           false,
                    airlines:           false,
                    fareClasses:        false,
                    arrivalRegions:     false,
                    arrivalCountries:   false,
                    departureRegions:   false,
                    departureCountries: false,
                },
                rangeForm: new WebForm('#rangeForm', {
                    id :    null,
                    start : 0,
                    end :   0,
                }),
                ruleForm: new WebForm('#ruleForm', {
                    id :                null,
                    airlines:           [],
                    fareClasses:        [],
                    departureRegions:   [],
                    departureCountries: [],
                    arrivalRegions:     [],
                    arrivalCountries:   [],
                }),
            }
        },
        async asyncData(app, route, store, redirect){

            let data = {
                period:           null,
                countries:        null,
                regions:          null,
                // airlines:         null,
                // favoriteAirlines: null,
                fareClasses:      null
            }

            //Valida que el parametro sea correcto
            let { id } = route.params
            if(_.isNull(id) || _.isUndefined(id) || !_.isNumeric(id)){
                redirect({ name: 'error-not-found' })
                return data
            }
            
            let periodRes    = await axios.get(`/admin/sales-utility/period/${id}`)
            let regionRes    = await axios.get('/admin/location/regions/all')
            let countryRes   = await axios.get('/admin/location/countries/all')

            data.period      = periodRes.data
            data.regions     = regionRes.data  || []
            data.countries   = countryRes.data || []
            data.fareClasses = _.range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1, 1).map(x => String.fromCharCode(x))

            return data
        },
        methods: {
            getPeriod(){
                let vm = this
                
                vm.loadingTable = true
                axios.get(`/admin/sales-utility/period/${vm.$route.params.id}`).then((response) => {
                    vm.period = response.data
                    vm.setTableHeader()
                })
            },
            setTableHeader(){

                if(_.isNull(this.period) || _.isUndefined(this.period))
                    return

                var vm    = this
                var th    = '';
                var thead = '';

                vm.loadingTable = true
                vm.period.ranges.forEach(range => {
                    th +=   `<th class="align-middle p-0"> 
                                <div class="rule-range">
                                    <div class="d-flex align-items-center">
                                        ${ range.end == null ? '+ '+range.start : range.start+' <span class="icon-right-arrow mx-2" grid="12"></span> '+range.end }
                                    </div>
                                    <div class="d-flex">
                                        <button class="btn btn-sm text-white center-content mr-1" disabled>
                                            <span class="icon-pencil-edith" grid="12"></span>
                                        </button>
                                        <button class="btn btn-sm text-white center-content" disabled>
                                            <span class="icon-bin" grid="12"></span>
                                        </button>
                                    </div>
                                </div>
                            </th>`
                });

                thead = `<thead>
                                <tr>
                                    <th><span class="icon-data-v" grid="16"></span></th>
                                    <th class="small font-weight-bold">ID</th>
                                    <th class="small font-weight-bold">AEROLINEAS</th>
                                    <th class="small font-weight-bold">CLASES DE TARIFAS</th>
                                    <th class="small font-weight-bold">ORIGENES</th>
                                    <th class="small font-weight-bold">DESTINOS</th>
                                    ${th}
                                    <th></th>
                                </tr>
                            </thead>`
                
                // Asigna el encabezado de la table con Jquery porque vue-draggable tiene un bug con la animacion
                // al reordenar las filas de t-body
                $('.table-rule').find('thead').remove()
                $('.table-rule').prepend(thead)
                vm.loadingTable = false
            },
            saveRange(){
                let vm = this
                if(vm.toggle.rangeEnd)
                    vm.rangeForm.end = null
                else if(vm.rangeForm.end == null)
                    vm.rangeForm.end = 0

                let period = vm.period.id
                vm.rangeForm.post('/admin/sales-utility/range/save', { period }).then((response) => {
                    $('#rangeModal').modal('hide')
                    vm.getPeriod()
                })
            },
            saveRule(){
                let vm = this

                if(vm.toggle.fareClasses)
                    vm.ruleForm.fareClasses = null
                if(vm.toggle.departureRegions)
                    vm.ruleForm.departureRegions = null
                if(vm.toggle.departureCountries)
                    vm.ruleForm.departureCountries = null
                if(vm.toggle.arrivalRegions)
                    vm.ruleForm.arrivalRegions = null
                if(vm.toggle.arrivalCountries)
                    vm.ruleForm.arrivalCountries = null

                //Modifica el objeto para enviar el id de las aerolineas
                let airlines = vm.toggle.airlines ? null : ((vm.ruleForm.airlines || []).length > 0 ? vm.ruleForm.airlines.map(e => e.id) : [])
                let period   = vm.period.id

                vm.ruleForm.post('/admin/sales-utility/rule/save', { airlines, period }).then((response) => {
                    vm.getPeriod()
                    $("#ruleModal").modal('hide')
                })

            },
            onSearchAirlines(search, loading) {
                if(_.isEmpty(search)){
                    this.airlines = this.favoriteAirlines
                    loading(false);
                    return
                }

                loading(true);
                this.searchAirlines(loading, search, this);
            },
            searchAirlines: _.debounce((loading, search, vm) => {
                axios.get(`/admin/airlines/${escape(search)}`).then(res => {
                    vm.airlines = res.data
                    loading(false);
                });
            }, 800),
            getFavoriteArilines(){
                axios.get('/admin/airlines/favorite').then(response => {
                    this.airlines = this.favoriteAirlines = response.data || []
                })
            },
            setRuleAmount: _.debounce((value, rule, range) => {
                
                if(_.isEmpty(value) || value == 0){
                    $vm.$snotify.warning('El monto no puede ser nulo o igual a cero');
                    return;
                }

                axios.post(`/admin/sales-utility/rule/amount`, { 'rule_id': rule.id, 'range_id': range.id, 'amount': value })
                .then(response => {
                    $vm.$snotify.simple(`Se actualizo el monto de la regla`)
                });

            }, 500),
            setRulePercent: _.debounce((value, rule, range) => {
                
                if(_.isEmpty(value) || value == 0){
                    $vm.$snotify.warning('Debe seleccionar un porcentaje valido');
                    return;
                }

                axios.post(`/admin/sales-utility/rule/percent`, { 
                    'rule_id': rule.id, 
                    'range_id': range.id, 
                    'percent': value 
                })
                .then(response => {
                    $vm.$snotify.simple(`Se actualizo el monto de la regla`)
                });
                
            }, 500),
            onDragEnd(){
                let rules = this.period.rules.map((e, index) => {
                    return { 
                        'id':   e.id,
                        'sort': index
                    }
                })

                axios.post(`/admin/sales-utility/rule/sort`, { rules }).then(response => {
                    console.log(response.data)
                    this.$snotify.simple(`Datos actualizados`)
                });
            },
            rangeValue(id, ranges, key){
                let range = _.find(ranges, { id })
                if(_.isEmpty(range))
                    return 0

                return range.pivot[key] || 0
            },
            editRule(rule){

                let dataRule = {
                    id :                rule.id,
                    period:             rule.sales_utility_period_id,
                    airlines:           rule.airlines || [],
                    fareClasses:        rule.fare_classes.map(e => e.name ) || [],
                    departureRegions:   rule.departure_regions.map(e => e.id ) || [],
                    departureCountries: rule.departure_countries.map(e => e.id ) || [],
                    arrivalRegions:     rule.arrival_regions.map(e => e.id ) || [],
                    arrivalCountries:   rule.arrival_countries.map(e => e.id ) || [],
                }

                this.toggle.airlines           = dataRule.airlines.length == 0
                this.toggle.fareClasses        = dataRule.fareClasses.length == 0
                this.toggle.departureRegions   = dataRule.departureRegions.length == 0
                this.toggle.departureCountries = dataRule.departureCountries.length == 0
                this.toggle.arrivalRegions     = dataRule.arrivalRegions.length == 0
                this.toggle.arrivalCountries   = dataRule.arrivalCountries.length == 0

                this.ruleForm.fill(dataRule)
                $("#ruleModal").modal('show')
            },
            deleteRule(rule){
                let vm = this
                $.confirm.delete(
                    'Eliminar Periodo',
                    `¿Está seguro que desea eliminar la <span class="p-1 text-primary bg-light rounded font-weight-bold">regla número ${rule.id}</span> y toda la información relacionada a ella?`,
                    function() {
                        axios.post('/admin/sales-utility/rule/delete', {
                            id: rule.id
                        }).then((response) => {
                            vm.$snotify.success("Regla eliminada con exito.")
                            vm.getPeriod()
                        }).catch(() => {
                            vm.$snotify.warning("No se pudo eliminar la regla.")
                        })
                    }
                )
            },
        },
        created() {
            this.getFavoriteArilines()
        },
        mounted() {

            let vm = this
            $("#rangeModal").off("hidden.bs.modal");
            $("#rangeModal").on("hidden.bs.modal", function(){
                vm.toggle.rangeEnd = false
                vm.rangeForm.reset()
            });

            $("#ruleModal").off("hidden.bs.modal");
            $("#ruleModal").on("hidden.bs.modal", function(){
                vm.toggle.airlines           = false
                vm.toggle.fareClasses        = false
                vm.toggle.departureRegions   = false
                vm.toggle.departureCountries = false
                vm.toggle.arrivalRegions     = false
                vm.toggle.arrivalCountries   = false
                vm.ruleForm.reset()
            });

            $(vm.$el).find('[data-toggle="tooltip"]').tooltip()

            vm.$nextTick(() => {
                vm.setTableHeader()
            })
        },
    }
    
</script>
<style>
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .rule-range {
        background: #1f2b61;
        color: #fff;
        margin: 0px 0.15rem;
        padding: 0.75rem;
        height: 45px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
    }
    .rule-range .btn{
        height: 25px;
        background: rgba(255, 255, 255, 0.10) !important;
    }
</style>
<style lang="scss" scoped>
    table.table-rule{
        border-collapse: separate;
        border-spacing: 0 8px;
        tr.rule-row{
            box-shadow: inset 0 0 0px 1px #ededf5, 0 0.155rem 0.35rem rgba(0, 0, 0, 0.055) !important;
        }
        td.divider{
            box-shadow: 8px 0px 0px -7px #dee2e6;
        }

    }
    .data-column{
        min-width: 180px;
        max-width: 180px;
    }
    .sm-input{
        width: 75px;
    }
    .cursor-all-scroll{
        cursor: all-scroll;
    }
    .rule-item {
        background: #f8f9fa;
        border: 1px solid #dee2e6;
        border-radius: 3px;
        margin-bottom: 4px;
        padding: 2px 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>