<template>
    <section class="mx-2">
        <add-setting ref="addSettingModal" v-on:save="getSettings" />
        <header class="d-flex align-items-center pb-3">
            <div>
                <h5 class="font-weight-bold mb-0">Configuración</h5>
                <p class="text-muted mb-0">Configuración de Motor de Reservas</p>
            </div>
            <button data-toggle="modal" data-target="#addSetting" class="btn btn-primary ml-auto" style="position: relative;" v-can:manage>
                Agregar <span class="icon-add-2"></span>
            </button>
        </header>
        <template v-if="settings.length > 0">
            <div class="row mx-0">
                <div class="col-12 col-lg-8 col-xl-5 bg-white shadow-sm rounded">
                    <div class="row" v-for="setting in settings" :key="setting.id">
                        <div class="py-3 col-12 border-bottom" :ref="setting.name">
                            <setting-comp :item="setting" v-on:edit="showModal" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else>
            <span class="icon-emoji-sad mr-2" grid="24"></span> No se encuentras datos disponibles...
        </div>
    </section>
</template>

<style scoped>
    .active-item{
        background: #e5eeff !important;
        box-shadow: 0 0 0 1px #a2c4fc;
        -webkit-transition: background-color 100ms linear;
        -ms-transition: background-color 100ms linear;
        transition: background-color 100ms linear;
    }
</style>

<script>

import AddSetting from '~/components/bookingEngine/addSetting'
import SettingComp from '~/components/bookingEngine/settingComp'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    metaInfo () {
        return { title: "Configuración de Motor de Reservas" }
    },
    components: {
        AddSetting,
        SettingComp
    },
    data () {
        return {
            settings: []
        }
    },
    async asyncData(app, route, store, redirect){
        let { data } = await axios.get(`/admin/settings/sdk/bookingengine/all`)
        return{
            settings: data
        }
    },
    methods: {
        async getSettings() {
            let vm       = this
            let endpoint = `/admin/settings/sdk/bookingengine/all`
            axios.get(endpoint).then(response => {
                vm.settings = response.data
            })
        },
        formatValue (setting) {
            if(setting.type == 'ARRAY' ) {
                if(!setting.value) {
                    return []
                }
                return setting.value.split(',')
            } else {
                if(!setting.value) {
                    return ''
                }
                return setting.value
            }
        },
        goto(refName) {
            var element = this.$refs[refName][0];
            element.scrollIntoView({
                behavior: 'smooth'
            })

            $(element).addClass('active-item')
            setTimeout(() => {
                $(element).removeClass('active-item')
            }, 1000);
        },
        showModal(item){
            this.$refs.addSettingModal.show(item)
        }
    },
    // mounted () {
    //     this.getSettings().then(({data}) => {
    //         this.settings = data.data
    //     })
    // }
}
</script>