const empty = (param) =>{
    return typeof param === 'undefined' || param == null || param == 0 || param == '';
}

const readRoute = (components, name, component, isChild = false) =>{

    let router = {}

    //Regexs
    const COLONS           = /\:/g
    const SLASHS           = /\//g
    const ASTERISKS        = /\*/g
    const INTERROGATION    = /\?/g
    const START_UNDERSCORE = /^\_/g
    const END_DOLLAR_SIGN  = /\$$/g
    const START_END_SLASH  = /^\/+|\/+$/g
    const UPPERCASE        = /(?=[A-Z])/g

    router.component = component.default || component
    let settings = typeof router.component.route === 'function' ? router.component.route() : router.component.route || {}

    //Formatea los nombres en una url omitiendo el camelcase ejemplo: file/testName = file/test-name
    var route      = []
    var routeSplit = name.split('/')
    routeSplit.forEach(r => {
        route.push(r.split(UPPERCASE).map(s => s.toLowerCase()).join('-'))
    });

    name = route.join('/')

    //Si el archivo inicia con "_" convierte la ruta en parametros y si termina con el
    //signo "$" siginifica que el parametro es opcional
    //Si este archivo no contiene nombre (_.vue) lo convierte en ruta dinamica ("*")
    var nameSplit = name.split('/')
    let lastName  = nameSplit.slice(-1).pop()
    name = lastName === '_' ?
           nameSplit.slice(0, -1).join('/')+"/*" :
           name.split('/').map(s => START_UNDERSCORE.test(s) ? s.replace(START_UNDERSCORE, ':').replace(END_DOLLAR_SIGN,"?") : s).join('/')

    //Obtiene el primer elemento del arreglo y este sera el key
    let key = name.split('/').shift()
    //Verifica si existe un componente con el mismo nombre y si es asi el
    //resto de componente seran hijos
    if(!empty(components[key]) && components[key].path === `/${key}`){
        let children = []
        children     = readRoute(children, name, component, true)
        children     = Object.values(children).shift()

        if(empty(components[key].children))
            components[key].children = []

        //Si contiene una ruta por hijo defecto, remueve el nombre
        if(children.path === "")
            components[key].name = null

        components[key].name = key
        components[key].children.push(children)
        return true
    }

    //Asigna un nombre al componente en el caso de que
    //no tenga uno asignado por defecto
    if(typeof router.component.name === 'undefined'){}
        router.component.name = name.replace(SLASHS, '-').replace(COLONS, '').replace(INTERROGATION, '').replace(ASTERISKS, 'unknown')

    //En el caso de que la ruta se llame index ignora
    //el nombre y omite la palabra index de la ruta
    let url = name
    let urlSplit = name.split('/')
    if(urlSplit.slice(-1).pop() === 'index')
        url = urlSplit.slice(0, -1).join('/')

    url = `/${url}`
    if(isChild){
        var replace = url.replace(START_END_SLASH, '').split('/').splice(0, 1).shift()
        url = url.replace(replace, '').replace(START_END_SLASH, '')
    }

    router.path = (settings.path || url)
    router.name = router.component.name

    //Valida los parametros de la ruta a traves de regex
    if(router.path.indexOf(':') !== -1 && !empty(settings.validate) && typeof settings.validate === 'object'){
        let path  = router.path.split('/').filter(n => n)
        let ePath = []
        path.forEach(element => {
            let key = element.replace(COLONS, '')
            if(settings.validate.hasOwnProperty(key))
                element = element+`(${settings.validate[key]})`
            ePath.push(element)
        });

        router.path = `/${ePath.join('/')}`;
    }

    //Si el una ruta no contiene rutas pares y espera un parametro
    //convierte este parametro en opcional
    // let lastKey = Object.keys(components).slice(-1).pop()
    // if(!empty(lastKey) && lastKey.split('/').slice(-1).pop().includes(':')){
    //     let lastPath = lastKey.split('/').slice(0, -1).join('/').replace(START_END_SLASH, '')
    //     let basePath = router.path.replace(START_END_SLASH, '')
    //     if(lastPath != basePath)
    //         components[lastKey].path += '?'
    // }

    if(empty(settings.alias))
        router.alias = settings.alias

    if(empty(settings.props))
        router.props = settings.props

    if(empty(settings.redirect))
        router.redirect = settings.redirect

    if(empty(settings.children))
        router.children = settings.children

    components[name] = router
    return components
}

const routerImporter = (requireContext) => requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {

        readRoute(components, name, component)
        return components

    }, {})

export default routerImporter;
