jQuery(function($) {

    let rippleElements = [
        '.btn',
        '.nav-link',
        '.dropdown-item',
        '.list-group-item',
        '.dt-button',
        '.chat-btn',
    ]
    
    // MAD-RIPPLE // (jQ+CSS)
    $(document).on("mousedown", rippleElements.join(', '), function(e) {
      
        var $self = $(this);
      
        if($self.is(".btn-disabled") || $self.is(".disabled"))
            return;
        
        if($self.closest(rippleElements.join(', ')))
            e.stopPropagation();
        
        var initPos = $self.css("position"),
            offs = $self.offset(),
            x = e.pageX - offs.left,
            y = e.pageY - offs.top,
            dia = Math.min(this.offsetHeight, this.offsetWidth, 100), // start diameter
            $ripple = $('<ripple/>', {class : "ripple", appendTo : $self });
        
        if(!initPos || initPos==="static")
            $self.css({position:"relative"});
            
        $('<ripple/>', {
            class : "rippleWave",
            css : {
                background : $self.data("ripple") || ($self.isLightBackground() ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.35)'),
                width: dia,
                height: dia,
                left: x - (dia/2),
                top: y - (dia/2),
            },
            appendTo : $ripple,
            one : {
                animationend : function(){
                    $ripple.remove();
                }
            }
        });
    });
  
  });