// Determine if the background color of an element is light or dark.
(function($){
  
    $.fn.isLightBackground = function(){
      var r,b,g,alpha,hsp
        , a = this.css('background-color');
      
      if(!a.match(/^rgb/))
        a = convertHex(a)

      a = a.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = a[1];
      g = a[2];
      b = a[3];
      alpha = a[4];

      hsp = Math.sqrt( // HSP equation from http://alienryderflex.com/hsp.html
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      if(alpha < 1)
        return this.parent().isLightBackground()
      
      return (hsp > 127.5);
        
      function convertHex(hex){
          hex = hex.replace('#','');
          r = parseInt(hex.substring(0,2), 16);
          g = parseInt(hex.substring(2,4), 16);
          b = parseInt(hex.substring(4,6), 16);
          a = parseFloat(parseInt((parseInt(hex.substring(6,8) || "ff", 16)/255)*1000)/1000);
          
          result = 'rgba('+r+','+g+','+b+','+a+')';
          return result;
      }

  }
    
  })(jQuery);