<template>
    <div class="row justify-content-center mx-0">
        <div class="modal fade" id="addAirline">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="form.id">
                                Editar Aerolinea
                            </span>
                            <span v-else>Nueva Aerolinea</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="airlineForm" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                            <div class="form-group">
                                <div class="picture-content mx-auto border shadow-sm" @click.stop="">
                                <img class="user-picture mb-2" :src="currentImage" id="airlineimg">
                                <div class="btn-change-user-picture position-absolute text-center">
                                    <button class="btn-transparent" @click.prevent="pickImage"><span class="icon-camera"></span></button>
                                </div>
                                <input type="file" :ref="'img'+form.id" v-show="false" @change="imageSelected" accept="image/jpg,image/jpeg,image/png" id="airlinelogo">
                            </div>
                            </div>
                            <div class="form-group">
                                <label for="title">Nombre Aerolinea</label>
                                <input type="text" class="form-control" v-model="form.name" name="name" >
                            </div>
                            <div class="form-group">
                                <label for="title">Codigo Iata Aerolinea</label>
                                <input type="text" class="form-control" v-model="form.airlinecode" id="airlinecode">
                            </div>
                            <div class="form-group">
                                <label for="title">Alias</label>
                                <input type="text" class="form-control" v-model="form.aliascode" id="aliascode">
                                <input type="hidden" class="form-control" v-model="form.airlineimage" id="airlineimage">
                            </div>
                            <div class="form-group">
                                <label for="translate">ICAO</label>
                                <input type="text" class="form-control" v-model="form.icao" id="icao" >
                            </div>
                            <button type="submit" class="btn btn-primary" v-can:create > 
                                <span v-if="form.id && $can('update')">
                                    Editar
                                </span>
                                <span v-else-if="form.id && !$can('update')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <!-- <button type="submit" class="btn btn-danger" id="btnCancelar" v-can:create @click.prevent="cancelEdition()" v-if="form.airline_id && $can('update')">Cancelar</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <p class="ml-2 font-weight-bold mb-0">Lista de Aerolineas</p>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addAirline" >
                        <span class="icon-add-2 mr-2"></span> Nuevo
                    </button>
                </div>
            </div>
            <data-table :ajax="{ url: '/admin/airlines' }" ref="airlineTable" :serverSide="true" :columns="dt.columns()"></data-table>
        </div>
    </div>
</template>

<script>
    'use strict'
    import dataTable from '~/components/dataTable'

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                currentImage: '/img/plane.png',
                form: new WebForm('#airlineForm', {
                    name: '',
                    airlineimage : '',
                    airlinecode: '',
                    aliascode: '',
                    icao: '',
                    id: null,
                }),
                airlines: [],
                dt: {
                    columns : () => {
                        var $this = this
                        let columns =
                            [
                                { title: 'Id', data: 'id' },
                                { title: 'Nombre', data: 'name',
                                    render: function ( data, type, row, meta ) {
                                        return `<div class="d-flex">
                                                    <img class="mr-2 airline-logo" width="35px" src='/img/airlines/${row['iata']}.png' alt="${row['iata'] || row['icao']}" onerror="this.src='/img/airlines/default.png';" />
                                                    <div class="d-flex flex-wrap">
                                                        <span class="w-100">${data}</span>
                                                        <span class="small text-muted">${row['status']}</span>
                                                    </div>
                                                </div>`;
                                    } 
                                },
                                { title: 'Alias', data: 'alias' },
                                { title: 'IATA', data: 'iata' },
                                { title: 'ICAO', data: 'icao' },
                                { title: 'Favorito', data: 'favorite', className: 'text-center',
                                    render: function ( data, type, row, meta ) {
                                        return `<button data-favorite="${data || 0}" data-id="${row["id"]}" class="btn btn-transparent btn-favorite"><span class="icon-star${(data || 0) == 0 ? '' : '-bold text-warning'}"></span></button>`
                                    }
                                },
                                { title: 'Opciones', data: 'id', orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btnedit     =  $this.$can('write') ? `<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#addAirline">Editar</button>` : '';
                                        let btndelete   =  $this.$can('write') ? `<button class="btn btn-soft btn-soft-danger btn-rounded btn-sm ml-2 font-weight-bold btn-delete" data-id="${data}">Eliminar</button>` : '';
                                        return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                                    } 
                                },
                            ]
                        return columns
                    }
                },
                iata: ['iata_code'],
                newImage: null
            };
        },
        methods: {
            ver() {
                console.log('hi');
            },
            pickImage: function () {
                this.$refs[('img' + this.form.id)].click()
            },
            imageSelected: function (e) {
                let vm = this
                if(this.form.id == null) {
                    if (e.target.files && e.target.files[0]) {
                        var reader = new FileReader();
                        
                        reader.onload = function(e) {
                            vm.currentImage =  e.target.result
                            vm.form.airlineimage =  e.target.result
                        }
                        
                        reader.readAsDataURL(e.target.files[0]);
                    }
                }
                
                let formData = new FormData()
                formData.append('image', e.target.files[0])
                formData.append('id', vm.form.id)
                axios
                .post('/admin/airline/upload/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //this.$emit('change', this.newImage)
                    // $("#airlineimg").attr("src","/img/plane.png");
                    vm.currentImage = response.data.image_url
                    vm.$snotify.simple('Se actualizó la imagen de la aerolinea.');
                })
                .catch(error => {
                    console.log(error.response)
                }) 
            },
            onDelete(airline) {
                let vm = this
                
                $.confirm.delete(
                    'Eliminar Aerolinea',
                    `¿Está seguro que desea eliminar la aerolinea <u>${airline.name}</u>?`, 
                    function(){
                        vm.deleteItem(airline.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this
                let endpoint = '/admin/airlines/delete'

                axios.post('/admin/airlines/delete', { id: idDel }).then(response => {

                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.is_manage) {
                        vm.$snotify.simple('Se Elimino con Exito');
                        this.$refs.airlineTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 
                }).catch(err => console.log(err));
            },
            onEditItem(action, data) {
            },
            onSaveItem() {
                let vm = this
                let icao = $("#icao").val().trim()
                let ruta = (vm.form.id != null) ? '/admin/airlines/updateAirline' : '/admin/airlines/saveitem';
                let tipo = (vm.form.id != null) ? 1 : 2;
                $("#iata").removeClass('is-invalid');
                if(this.form.id != null) {
                    $.confirm({
                        title: `Editar Aerolinea`,
                        content: 'Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-priamary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(ruta, tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                            
                        }
                    });
                } else {
                    vm.updateOrCreate(ruta)
                }
            },
            updateOrCreate (url, type) {
                let vm = this
                vm.form.post(url).then(response => {
                    let data = response.data;
                    this.form.id = null
                    if(!data.success) {
                        if(data.error) {
                           vm.form.errors = data.error
                        }
                        return;
                    } 

                    if(data.is_manage || data.success) {
                        let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                        vm.$snotify.success(snotify_message);
                        this.$refs.airlineTable.ajaxReload();
                        $('#addAirline').modal('hide');
                        vm.form.reset();
                        return;
                    }
                    
                    vm.$snotify.info(`Se ha enviado solicitud`)
                    this.$refs.airlineTable.ajaxReload()
                });
            },
            cancelEdition() {
                // $("#btnCancelar").fadeOut();
                // this.clearForm(this.form);
                this.form.id = null
                $("#airlineimg").attr("src","/img/plane.png");
            },
            async getAirlines(){
                await axios.get('/admin/airlines').then(response => {
                    console.log(response.data)
                    this.airlines = response.data
                })
            },
        },
        computed: {
            userImage: function () {
                return this.newImage || this.image
            }
        },
        mounted(){ 
            var $this = this
            let vm = this

            $(vm.$el).off("click.airline", ".btn-edit");
            $(vm.$el).on("click.airline",  ".btn-edit",function(){
               axios.get('/admin/airlines/' + $(this).data('id'))
                .then(response => {
                    let data = response.data;
                    $this.form.name         =   data[0].name
                    $this.currentImage      =  (data[0].logo!="" && data[0].logo!=null) ? data[0].logo  :  "/img/plane.png"
                    $this.form.airlinecode  =   data[0].iata
                    $this.form.aliascode    =   data[0].alias
                    $this.form.icao         =   data[0].icao
                    $this.form.id           =   data[0].id
                 })
            }); 

            $(vm.$el).off("click.airline", ".btn-delete");
            $(vm.$el).on("click.airline",  ".btn-delete",function(){
                let airline = vm.$refs.airlineTable.rowData($(this).closest('tr'));
                vm.onDelete(airline);
            });

            $(vm.$el).off("click.airline", ".btn-favorite")
            $(vm.$el).on("click.airline",  ".btn-favorite", function(){

                let $btn      = $(this)
                let airlineId = $btn.data('id')
                let favorite  = $btn.data('favorite')

                $btn.addClass('btn-loading btn-loading-dark')
                favorite = !favorite

                axios.post('/admin/airlines/favorite', { 
                    id: airlineId,
                    favorite 
                }).then(response => {
                    $btn.data('favorite', favorite)
                    $btn.removeClass('btn-loading btn-loading-dark')
                    $btn.find('span').removeClass().addClass(favorite ? 'icon-star-bold text-warning' : 'icon-star')
                    vm.$snotify.simple(favorite ? 'Se agrego a favoritos' : 'Se elimino de favoritos') 
                })
            })
            
            $('#addAirline').off('hidden.bs.modal')
            $('#addAirline').on('hidden.bs.modal', function () {
                //limpia los errores del formulario
                vm.form.clear()
                //regresa los valores del formulario a su estado inicial
                vm.form.reset()
            })
        },
    }
</script>

<style scoped>
    .picture-content {
        position: relative;
        overflow: hidden;
    }
    .picture-content,
    .user-picture{
        width: 120px;
        height: 120px;
    }

    .picture-content:hover > .btn-change-user-picture{
        opacity: 1;
        top: 60px;
    }

    .btn-change-user-picture:focus {outline:0;}
    .btn-change-user-picture{
        opacity: 0;
        background-color: rgba(0,0,0,0.5);
        transition: 0.3s;
        top: 120px;
        width: 120px;
        height: 120px;    
    }
    .btn-transparent {
        outline: none;
        color: #fff;
        font-size: 2em;    
        cursor: pointer;        
    }

    .btn-transparent:hover {
        background: transparent;
    }
</style>