<template>
    <div class="modal fade" id="tagsModal" tabindex="-1" role="dialog" aria-labelledby="tagsModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="tagsModalLabel">{{ action == 'add' ? 'Agregar' : 'Eliminar' }} etiquestas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <v-select name="views" taggable
                    v-model="tags"
                    ref="vSelectTags" placeholder="Ingrese Etiquetas"
                    multiple @keypress.enter.native.prevent="" push-tags
                    @option:created="setFocusViews"
                    >
                </v-select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary"  data-dismiss="modal" @click="changeTags">Guardar</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        action: {
            type: String,
            default() {
                return 'add'
            }
        }
    },
    data () {
        return {
            tags: []
        }
    },
    methods: {
        setFocusViews() { // ok
            setTimeout (() => {
                $(this.$refs.vSelectTags.$el).find('.vs__search').focus();
            }, 200);
        },
        changeTags() {
            this.$emit('input', this.tags)
        }
    }
}
</script>
