<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Boletines informativos del Sistema</h5>
                    <p class="text-muted">Noticias e informacion relevante para clientes y usuarios.</p>
                </div>
                <div>
                    <button  class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addNewsletter">
                        <span class="icon-add-2 mr-2"></span> Nuevo Boletin
                    </button>
                </div>
            </div>
            <div >
                <data-table :ajax="{ url: '/admin/newsletter/list'}" :scrollY="'calc(100vh - 350px)'" ref="newsLetterTable" :serverSide="true" :columns="dt.columns()"></data-table>
            </div>
        </div>
        <div class="modal fade" id="addNewsletter">
            <div class="modal-dialog modal-lg" >
                <div class="modal-content">
                    <form id="formNewsletter" autocomplete="off" @submit.prevent="saveNewsletter" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span>Nuevo Boletin Pavolar</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="titleNewsletter" class="required">Titulo de la Noticia o Boletin</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Titulo" name="title" id="title" v-model="formNewsletter.title">
                                </div>
                            </div>
                            <div class="row">
                                 <div class="form-group col-md-6">
                                    <label for="start_date" class="required">Fecha de Publicacion</label>                                    
                                    <vue-datepicker v-model="formNewsletter.start_date" class="form-control" name="start_date" id="start_date"></vue-datepicker>
                                </div>

                                 <div class="form-group col-md-6">
                                    <label for="end_date" >Fecha de Finalización (Opcional)  
                                        <toggle-button 
                                        :value="formNewsletter.redirect_to_url" 
                                        color="#38c172" 
                                        :sync="true"
                                        :labels="{checked: 'Limitado', unchecked: 'Indefinido'}"
                                        :width="80" 
                                        v-model="formNewsletter.apply_end_date"
                                        @change="dateEnd"
                                        /></label>
                                    <vue-datepicker v-model="formNewsletter.end_date" class="form-control" name="end_date" id="end_date" :disabled="formNewsletter.apply_end_date == false"></vue-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="language" class="d-flex justify-content-between">Idioma </label>
                                    <v-select id="language" placeholder="Seleccionar Idioma" v-model="formNewsletter.language" :options="languages" label="name" disabled>
                                        <template slot="no-options">Buscar Idioma...</template>
                                    </v-select>
                                </div>

                                <div class="form-group col-6">                                    
                                    <label for="departurecode" class="d-flex justify-content-between">Redirecciona a una URL - OFF es solo informativo </label>
                                     <toggle-button 
                                        :value="formNewsletter.redirect_to_url" 
                                        color="#38c172" 
                                        :sync="true"
                                        :labels="{checked: 'Redireccionamiento a URL', unchecked: 'Sin Redirección'}"
                                        :width="165" 
                                        v-model="formNewsletter.redirect_to_url" />
                                </div>
                                 
                            </div>
                            <div class="row" v-if="formNewsletter.redirect_to_url">
                                <div class="form-group col-md-6">
                                    <label for="action_link" class="required">Enlace</label>
                                    <input type="text" id="url_redirect" name="url_redirect" class="form-control" v-model="formNewsletter.url_redirect" placeholder="Ingrese url">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="redirect_type" class="required">Target</label>
                                    <select id="redirect_type" name="redirect_type" v-model="formNewsletter.redirect_type" class="form-control">
                                        <option value="_blank">_blank</option>
                                        <option value="_self">_self</option>
                                        <option value="_parent">_parent</option>
                                        <option value="_top">_top</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="row">                                
                                <div class="form-group col-md-6" v-if="formNewsletter.redirect_to_url">
                                    <label for="action_link" class="required">Texto de Enlace</label>
                                    <input type="text" class="form-control" v-model="formNewsletter.link_text" placeholder="Ingrese url" name="action_link">
                                </div>                               
                                <div class="form-group editor-height-xsm col-md-12">
                                    <label for="summary_content">Resumen (Solo Texto) {{ formNewsletter.summary_content.length || '0' }} / 600</label>
                                    <textarea name="summary_content" id="summary_content" v-model="formNewsletter.summary_content" cols="105" rows="3">
                                    </textarea>
                                </div>

                                <div class="form-group editor-height-xsm col-md-12" v-if="formNewsletter.redirect_to_url == false">
                                    <label for="content">Contenido</label>
                                    <quill-editor id="content" ref="editor" v-model="formNewsletter.content"> 
                                    </quill-editor>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary"> 
                                Guardar
                            </button>
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
            </div>
            </div>        
        </div>
        <div class="modal fade" id="addLanguage">
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content">
                        <form id="formNewsletterTraslate" autocomplete="off" @submit.prevent="saveTraslateNewsletter" novalidate>
                            <div class="modal-header">
                                <h5 class="font-weight-bold mb-0">
                                    <span>Nuevo Traduccion para Boletin Pavolar # {{formNewsletterTraslate.idNewsLetter}}</span>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="title" class="required">Titulo de la Noticia o Boletin</label>
                                        <input type="text" class="form-control" placeholder="Ingrese Titulo" name="title" v-model="formNewsletterTraslate.title" >
                                    </div>
                                </div>
                                <div class="row">
                                     <div class="form-group col-md-6" v-if="dataNewsLetter.redirect_to_url">
                                        <label for="link_text" class="required">Texto de Enlace</label>
                                        <input type="text" class="form-control" v-model="formNewsletterTraslate.link_text"  placeholder="Texto del enlace" name="link_text">
                                    </div>  
                                </div>
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="language" class="d-flex justify-content-between">Idioma </label>
                                        <v-select name="language" placeholder="Seleccionar Idioma" v-model="formNewsletterTraslate.language" @input="traslateContent" :options="availableLanguages" @select="traslateContent" label="name">
                                            <template slot="no-options">Buscar Idioma...</template>
                                        </v-select>
                                    </div>
                                    
                                </div>
                                
                                <div class="row">                               
                                    <div class="form-group editor-height-xsm col-md-12">
                                        <label for="summary">Resumen (Solo Texto) {{ formNewsletterTraslate.summary_content.length || '0' }} / 600 </label>
                                        <textarea name="summary_content" v-model="formNewsletterTraslate.summary_content" cols="107" rows="3">
                                        </textarea>
                                    </div>
                                    <div class="form-group editor-height-xsm col-md-12" v-if="dataNewsLetter.redirect_to_url == false" >
                                        <label for="content">Contenido</label>
                                        <quill-editor name="content" ref="editor" v-model="formNewsletterTraslate.content"> 
                                        </quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-primary"> 
                                    Guardar
                                </button>
                                <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="editNewsletter">
            <div class="modal-dialog modal-xl" >
                <div class="modal-content">
                    <form id="editNewsletter" autocomplete="off" @submit.prevent="saveDataNewsletter" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span>Editar Boletin Pavolar</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">                            
                            <div class="row">
                                 <div class="form-group col-md-6">
                                    <label for="start_date" class="required">Fecha de Publicacion</label>                                    
                                    <vue-datepicker v-model="editNewsletter.start_date" class="form-control" name="start_date" ></vue-datepicker>
                                </div>

                                 <div class="form-group col-md-6">
                                    <label for="end_date" >Fecha de Finalización (Opcional)  
                                        <toggle-button 
                                        :value="editNewsletter.redirect_to_url == '1'" 
                                        color="#38c172" 
                                        :sync="true"
                                        :labels="{checked: 'Limitado', unchecked: 'Indefinido'}"
                                        :width="80" 
                                        v-model="editNewsletter.apply_end_date"
                                        @change="dateEnd"
                                        /></label>
                                    <vue-datepicker v-model="editNewsletter.end_date" class="form-control" name="end_date" :disabled="formNewsletter.apply_end_date == false"></vue-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                

                                <div class="form-group col-12">                                    
                                    <label for="departurecode" class="d-flex justify-content-between">Redirecciona a una URL - OFF es solo informativo </label>
                                     <toggle-button 
                                        :value="editNewsletter.redirect_to_url == '1'" 
                                        color="#38c172" 
                                        :sync="true"
                                        :labels="{checked: 'Redireccionamiento a URL', unchecked: 'Sin Redirección'}"
                                        :width="165" 
                                        v-model="editNewsletter.redirect_to_url" />
                                </div>
                                 
                            </div>
                            <div class="row" v-if="editNewsletter.redirect_to_url">
                                <div class="form-group col-md-6">
                                    <label for="url_redirect" class="required">Enlace</label>
                                    <input type="text" class="form-control" v-model="editNewsletter.url_redirect" placeholder="Ingrese url" name="url_redirect">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="redirect_type" class="required">Target</label>
                                    <select name="redirect_type" v-model="editNewsletter.redirect_type" class="form-control">
                                        <option value="_blank">_blank</option>
                                        <option value="_self">_self</option>
                                        <option value="_parent">_parent</option>
                                        <option value="_top">_top</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                                        <a class="nav-link" :class="{'active' : index==0 }" v-for="(content,index) in dataNewsLetter.contents" :key="index"  data-toggle="pill" :id="`#v-pills-home${content.id}-tab`" :href="`#v-pills-home${content.id}`" role="tab" :aria-controls="`v-pills-home${content.id}`" aria-selected="true">
                                            <button class="btn-success btn-sm mr-2" data-toggle="modal" data-target="#editContentNewsletter" v-on:click="loadEditLanguage(content)"> <span class="icon-pencil    "></span> </button> {{ content.language.native_name }} 
                                        </a>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div v-for="(content,index) in dataNewsLetter.contents"  class="tab-pane fade" :class="{' show active' : index==0 }" :key="index"  :id="`v-pills-home${content.id}`" role="tabpanel" :aria-labelledby="`v-pills-home${content.id}-tab`">
                                            <div class="row">
                                                Texto del Enlace: <p>{{ content.link_text}}</p>
                                            </div>
                                            <div class="row">
                                                Titulo: <h4>{{ content.title}}</h4>
                                            </div>
                                            <div class="row">
                                                Resumen: <p>{{ content.summary_content}}</p>
                                            </div>

                                            <div class="row">
                                                <div class="row">
                                                    Contenido:
                                                </div> 
                                                <div class="row html-block" v-html="htmlprint(content.content || '')">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary"> 
                                Guardar
                            </button>
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
            </div>
            </div>        
        </div>
        <div class="modal fade" id="editContentNewsletter">
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content">
                        <form id="editContentNewsletter" autocomplete="off" @submit.prevent="saveContentNewsletter" novalidate>
                            <div class="modal-header">
                                <h5 class="font-weight-bold mb-0">
                                    <span>Editar Traduccion</span>
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">

                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="title" class="required">Titulo de la Noticia o Boletin</label>
                                        <input type="text" class="form-control" placeholder="Ingrese Titulo" name="title" v-model="editContentNewsletter.title">
                                    </div>
                                </div>

                                <div class="row">
                                     <div class="form-group col-md-6" v-if="dataNewsLetter.redirect_to_url">
                                        <label for="link_text" class="required">Texto de Enlace (El boletin tiene redireccionamiento a otra pagina)</label>
                                        <input type="text" class="form-control" v-model="editContentNewsletter.link_text" placeholder="Texto del enlace" name="link_text">
                                    </div>  
                                </div>

                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="language" class="d-flex justify-content-between">Idioma </label>
                                        <v-select name="language" placeholder="Seleccionar Idioma" v-model="editContentNewsletter.language" :options="languages" label="name" :disabled="!_.isNull(editContentNewsletter.language) && editContentNewsletter.language.short_iso_code == 'ES'">
                                            <template slot="no-options">Buscar Idioma...</template>
                                        </v-select>
                                    </div>                                    
                                </div>
                                
                                <div class="row">                               
                                    <div class="form-group editor-height-xsm col-md-12">
                                        <label for="summary">Resumen (Solo Texto) {{ editContentNewsletter.summary_content.length || '0' }} / 600 </label>
                                        <textarea name="summary_content" v-model="editContentNewsletter.summary_content" cols="100" rows="3">

                                        </textarea>
                                    </div>

                                    <div class="form-group editor-height-xsm col-md-12" v-if="editNewsletter.redirect_to_url == false">
                                        <label for="content">Contenido</label>
                                        <quill-editor id="content" ref="editor"   v-model="editContentNewsletter.content"> 
                                        </quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-primary"> 
                                    Guardar
                                </button>
                                <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import dataTable from '~/components/dataTable';
import ToggleButton from 'vue-js-toggle-button/src/Button'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    components: {
        dataTable ,
		'toggle-button': ToggleButton
    },
    metaInfo() {
        return {
            title: "Boletines Informativos Pavolar"
        }
    },
    data() {
        return {
            MyCover: null,
            siteCovers: [],
            html: `<p><a href="https://gitlab.com/users/xaviGarcia/activity" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">View all</a></p><p>1 day ago</p><p><a href="https://gitlab.com/xaviGarcia" rel="noopener noreferrer" target="_blank" style="color: rgb(46, 46, 46);">Francisco Garcia</a>&nbsp;@xaviGarcia</p><p>Left project</p><p><a href="https://gitlab.com/stmr/pavolar-app-admin" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">Carlos Rivas /&nbsp;Pavolar App Admin</a></p><p>1 year ago</p><p><br></p><p><a href="https://gitlab.com/xaviGarcia" rel="noopener noreferrer" target="_blank" style="color: rgb(46, 46, 46);">Francisco Garcia</a>&nbsp;@xaviGarcia</p><p>Pushed to branch</p><p><a href="https://gitlab.com/stmr/pavolar-app-admin/-/commits/master" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">master</a></p><p>at&nbsp;<a href="https://gitlab.com/stmr/pavolar-app-admin" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">Carlos Rivas /&nbsp;Pavolar App Admin</a></p><ul><li><a href="https://gitlab.com/stmr/pavolar-app-admin/-/commit/65b358ab65e5b0b18379fbadb3fd65daae842e88" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">65b358ab</a>&nbsp;· Merge branch 'master' of&nbsp;<a href="https://gitlab.com/KeyzzRivas/pavolar-app-" rel="noopener noreferrer" target="_blank" style="color: rgb(46, 46, 46);">https://gitlab.com/KeyzzRivas/pavolar-app-</a>...</li><li>... and 1 more commit.&nbsp;<a href="https://gitlab.com/stmr/pavolar-app-admin/-/compare/bf0e9dbedab560effc7533efe41d5036c0468e6e...65b358ab65e5b0b18379fbadb3fd65daae842e88" rel="noopener noreferrer" target="_blank" style="color: rgb(27, 105, 182);">Compare&nbsp;bf0e9dbe...65b358ab</a></li></ul><p><br></p>`,
            firstLoad: false,
            languages:  [],
            availableLanguages : [],
            defaultLanguage: [],
            newsLetters :   [],
            dataNewsLetter: [],
            dataNewsLetterContent: [],
            formNewsletter: new WebForm("#formNewsletter", { 
                    title           : "",
                    redirect_to_url :   true,
                    language        :   null,
                    url_redirect    :   null,
                    redirect_type   :   null,
                    summary_content :   "",
                    content         :   "",
                    start_date      : "2020-05-01",
                    end_date        : null,
                    apply_end_date  : true,
                    link_text       : null
            }),
            editNewsletter: new WebForm("#editNewsletter", { 
                    id              : "",
                    redirect_to_url :   true,
                    url_redirect    :   null,
                    redirect_type   :   null,
                    start_date      : "2020-05-01",
                    end_date        : null,
                    apply_end_date  : true,
                    link_text       :   null
            }),
            editContentNewsletter: new WebForm("#editContentNewsletter", { 
                    id              : 0,
                    title           : "",
                    language        : null,
                    summary_content :   "",
                    content         :   "",
                    link_text       : null,
                    required_text_link : false,
                    language_edit   : true
            }),            
            formNewsletterTraslate: new WebForm("#formNewsletterTraslate", { 
                    idNewsLetter    : 0,
                    title           : "",
                    language        :   null,
                    summary_content :   "",
                    content         :   "",
                    language        :   null,
                    link_text       : null,
                    required_text_link : false
            }),
            dt: {
                columns : () => {
                    var $this = this;
                    let columns =
                        [
                            { title: 'id', data: 'id' },
                            { title: 'Fecha de publicación', data: 'start_date'},
                            { title: 'Titulo', data: 'contents', orderable: false, className:'sm-column text-wrap',                           
                                render: function ( data, type, row) {
                                     return data[0].title;
                                }
                            },
                            { title: 'Resumen', data: 'contents', orderable: false, className:'md-column text-wrap',                           
                                render: function ( data, type, row) {
                                    return data[0].summary_content;
                                }
                            },
                            { title: 'Idiomas disponibles', data: 'contents', orderable: false,                            
                                render: function ( data, type, row) {
                                    let languages = ""
                                    let addLanguage = '<button class="btn btn-success btn-sm ml-2 font-weight-bold btn-add-language" data-id="${row.id}" data-toggle="modal" data-target="#addLanguage"><span class="icon-add-2"></span></button> '
                                    data.forEach(element => {
                                        let space = (languages == '') ? '' : ' ,'
                                        languages += space + element.language.name                                        
                                    });
                                    return addLanguage + languages;
                                }
                            },
                            { title: 'Acciones', data: 'id', orderable: false,
                                render: function ( data, type, row, meta ) {
                                    let btnedit     =  `<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold btn-sm font-weight-bold btn-edit" data-id="${data}" data-toggle="modal" data-target="#editNewsletter">Editar</button>`;
                                    let btndelete   =  `<button class="btn btn-soft btn-soft-danger btn-rounded btn-sm font-weight-bold btn-sm ml-2 font-weight-bold btn-delete" data-id="${data}">Eliminar</button>`;
                                    return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                                } 
                            },
                        ];
                    return columns
                }
            }
        }
    },
    methods: {
    redirectChange(){
        this.formNewsletter.redirect = (this.formNewsletter.redirect == false )
    },
    async traslateContent(){
            let toLaguage = this.formNewsletterTraslate.language.short_iso_code.toLowerCase()
            let title,link_text,summary
            debugger
            let vm = this
             await window.YandexTranslate.translate({lang: 'es-'+toLaguage, text: vm.formNewsletterTraslate.title}, function (text) {
                title = text
            })
             await window.YandexTranslate.translate({lang: 'es-'+toLaguage, text: vm.formNewsletterTraslate.link_text}, function (text) {
                link_text = text
            })
             await window.YandexTranslate.translate({lang: 'es-'+toLaguage, text: vm.formNewsletterTraslate.summary_content}, function (text) {
                summary = text
            })
            this.formNewsletterTraslate.title           =   title
            this.formNewsletterTraslate.link_text       =   link_text
            this.formNewsletterTraslate.summary_content =   summary
        },
        async traduceText(language , string){
            let traslate 
            await window.YandexTranslate.translate({lang: language, text: string}, function (text) {
                traslate = text
            })
            return traslate
        },
        dateEnd(){
            if(this.formNewsletter.apply_end_date == false)
                this.formNewsletter.end_date = ""
        },
        htmlprint(html){
           let correcthtml = html.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>')
           return correcthtml
        },
        loadEditLanguage(language){
            console.log(language)
            let vm = this
            debugger
            vm.editContentNewsletter.id                 = language.id
            vm.editContentNewsletter.title              = language.title
            vm.editContentNewsletter.content            = vm.htmlprint(language.content || '')
            vm.editContentNewsletter.summary_content    = language.summary_content
            vm.editContentNewsletter.language           = language.language
            vm.editContentNewsletter.link_text          = language.link_text
            vm.editContentNewsletter.required_text_link = (vm.dataNewsLetter.redirect_to_url == '1')
            vm.editContentNewsletter.language_edit      = (language.language.short_iso_code == 'ES')
        },
        async saveDataNewsletter(){
            
            let vm = this            
             await vm.editNewsletter.post('/admin/newsletter/saveEditNewsletter').then(response => {   
                vm.$snotify.simple('Noticia agregada con Exito.');
                vm.editNewsletter.reset();
                $('.modal').modal('hide');         
                vm.$refs.newsLetterTable.ajaxReload();                       
            }).catch(exception => {     
                vm.setFormErrors(response, vm.editNewsletter)
            });
        },
        async saveNewsletter(){
            let vm = this
            await this.formNewsletter.post('/admin/newsletter/add').then(response => {   
                vm.$snotify.simple('Noticia agregada con Exito.');
                vm.formNewsletter.reset();
                $('#addNewsletter').modal('hide');         
                vm.$refs.newsLetterTable.ajaxReload();                       
            }).catch(exception => {     
                this.setFormErrors(response, vm.formNewsletter)
            });
            vm.formNewsletter.language = vm.defaultLanguage  
        },
         async saveTraslateNewsletter(){
            let vm = this
            await this.formNewsletterTraslate.post('/admin/newsletter/addTraslate').then(response => {   
                vm.$snotify.simple('Noticia agregada con Exito.');
                vm.formNewsletterTraslate.reset();
                $('#addLanguage').modal('hide');  
                vm.$refs.newsLetterTable.ajaxReload();                     
            }).catch(exception => {     
                this.setFormErrors(response, vm.formNewsletterTraslate)
            });
        },
        async saveContentNewsletter (){
            let vm = this
            await vm.editContentNewsletter.post('/admin/newsletter/editTraslate').then(response => {   
                vm.$snotify.simple('Noticia editada con Exito.');
                vm.editContentNewsletter.reset();
                $('.modal').modal('hide');  
                vm.$refs.newsLetterTable.ajaxReload();                     
            }).catch(exception => {     
                this.setFormErrors(response, this.form)
            });
        },
        getLanguages(){
            let vm = this
            axios.get('/admin/languages/list').then(response => {   
                vm.languages = response.data
                vm.languages.forEach(element => {
                    if(element.short_iso_code == "ES"){
                        vm.defaultLanguage          = element
                        vm.formNewsletter.language  = element
                    }                    
                });
            }).catch(exception => {     

            });
            // vm.getNewsLetters()
        },
        getNewsLetters(){
            let vm = this
            axios.get('/admin/newsletter/list').then(response => {   
                vm.newsLetters = response.data
                console.log(vm.newsLetters)
            }).catch(exception => {     
                
            });
        },
        onDelete(newsletter){
            let vm = this
             $.confirm.delete(
                'Eliminar Noticia',
                `¿Está seguro que desea eliminar esta Noticia?`, 
                function(){
                    axios.post('/admin/newsletter/delete',{
                        newsletter : newsletter
                    }).then(response => {          
                        vm.$snotify.success("Noticia eliminada Exitosamente");         
                        vm.$refs.newsLetterTable.ajaxReload();
                    }).catch(exception => {     
                        
                    });
                }
            )
           
        },
        onLoadEdit(newsletter){
            let vm = this
            vm.dataNewsLetter = newsletter
            debugger
            vm.editNewsletter.id                    =  newsletter.id
            vm.editNewsletter.redirect_to_url       =  (newsletter.redirect_to_url  == '1')
            vm.editNewsletter.url_redirect          =  newsletter.url_redirect
            vm.editNewsletter.redirect_type         =  newsletter.redirect_type
            vm.editNewsletter.start_date            =  newsletter.start_date
            vm.editNewsletter.end_date              =  newsletter.end_date
            vm.editNewsletter.apply_end_date        =  (newsletter.end_date != '' && newsletter.end_date != null)
            vm.editNewsletter.required_text_link    =  (newsletter.redirect_to_url  == '1')
        },
        addTraslate(){
            let vm = this
            vm.formNewsletterTraslate.post('/admin/newsletter/add').then(response => {   
                vm.$snotify.simple('Traduccion agregada con Exito.');
                vm.formNewsletterTraslate.reset();
                $('#addNewsletter').modal('hide');
                vm.$refs.newsLetterTable.ajaxReload();                      
            }).catch(exception => {     
                vm.setFormErrors(response, vm.formNewsletterTraslate)
            });
        },
        async loadAvailableLanguages(){
            let myLanguages = ""
            let vm = this
            let contents = vm.dataNewsLetter.contents            
            vm.formNewsletterTraslate.idNewsLetter    = vm.dataNewsLetter.id
            vm.formNewsletterTraslate.title           = vm.dataNewsLetter.contents[0].title   
            vm.formNewsletterTraslate.summary_content = vm.dataNewsLetter.contents[0].summary_content
            vm.formNewsletterTraslate.content         = vm.dataNewsLetter.contents[0].content    
            vm.formNewsletterTraslate.language        = null   
            vm.formNewsletterTraslate.link_text       = vm.dataNewsLetter.contents[0].link_text     
            await contents.forEach(element => {
                if(myLanguages=="")
                    myLanguages +=  element.language.id
                else                    
                    myLanguages +=  ","+element.language.id       
            });

            await axios.get('/admin/languages/availableList/'+myLanguages).then(response => { 
                vm.availableLanguages = response.data
            }).catch(exception => {     
                
            });
        }      
    },
    mounted() {
        let vm = this;

        $(vm.$el).off("click.newsletters",".btn-delete");
        $(vm.$el).on("click.newsletters",".btn-delete",function(){
            let newsletter = vm.$refs.newsLetterTable.rowData($(this).closest('tr'));
            vm.onDelete(newsletter);
        });

        $(vm.$el).off("click.newsletters",".btn-edit");
        $(vm.$el).on("click.newsletters",".btn-edit",function(){
            let newsletter = vm.$refs.newsLetterTable.rowData($(this).closest('tr'));
            vm.onLoadEdit(newsletter);
        });

        
        $(vm.$el).off("click.newsletters",".btn-add-language");
        $(vm.$el).on("click.newsletters",".btn-add-language",function(){
            let newsletter = vm.$refs.newsLetterTable.rowData($(this).closest('tr'));            
            vm.dataNewsLetter = newsletter
            vm.loadAvailableLanguages()
        });
        
        vm.getLanguages()
       
    }
}
</script>
<style>
.card__cover {
    height: 200px;
    display: block;
    background-position: center;
    background-size: cover;
}
.card__cover p{
    color: white;
    overflow: hidden;
}

</style>