
<script>

  import { ContentLoader } from "vue-content-loader"

  export default {
    components: {
      ContentLoader
    }
  }

</script>
<template>
    <content-loader id="menuLoader" :height="400" :width="270" :speed="1.5" primaryColor="#3b4982" secondaryColor="#22306b" >

        <rect x="9" y="20" rx="4" ry="4" width="100" height="20" /> 
        <!-- <circle cx="245" cy="30" r="10" /> -->

        <circle cx="35" cy="115" r="25" /> 
        <rect x="70" y="95" rx="4" ry="4" width="100" height="13" /> 
        <rect x="70" y="120" rx="4" ry="4" width="50" height="8" /> 
        <circle cx="245" cy="110" r="10" />

        <!-- <rect x="9" y="150" rx="4" ry="4" width="250" height="35" />  -->

        <rect x="9" y="200" rx="4" ry="4" width="150" height="15" /> 
        <rect x="9" y="225" rx="4" ry="4" width="100" height="15" /> 
        <rect x="9" y="250" rx="4" ry="4" width="150" height="15" /> 
        
        <rect x="9" y="290" rx="4" ry="4" width="150" height="15" /> 
        <rect x="9" y="315" rx="4" ry="4" width="100" height="15" /> 
        <rect x="9" y="340" rx="4" ry="4" width="150" height="15" /> 

    </content-loader>
</template>
<style scoped>
    svg#menuLoader{
        /* background: #22306b; */
        position: absolute;
        z-index: 1;
    }
</style>