<template>
    <div class="modal fade" id="addState">
        <div class="modal-dialog">
            <div class="modal-content">
                <form :id="'formState-'+_uid" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formState.id">Editar Estado</span>
                            <span v-else>Nuevo Estado</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-8 ">
                                <label for="name" class="required">Nombre</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre"
                                    v-model="formState.name" name="name" />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="iso_code" class="">ISO Code</label>
                                <input type="text" class="form-control" placeholder="Ingrese Codigo ISO"
                                    v-model="formState.iso_code" name="iso_code"/>
                            </div>
                            <div class="form-group col-md-8">
                                <label for="geographical_division" class="">Division Geografica</label>
                                <select class="form-control"  v-model="formState.geographical_division" name="geographical_division">
                                    <option value="Canton">Canton</option>
                                    <option value="Country">Pais</option>
                                    <option value="Department">Departamento</option>
                                    <option value="Distric">Distrito</option>
                                    <option value="Division">Division</option>
                                    <option value="Governorate">Gobernacion</option>
                                    <option value="Municipality">Municipalidad</option>
                                    <option value="Prefecture">Prefectura</option>
                                    <option value="Province">Provincia</option>
                                    <option value="Region">Region</option>
                                    <option value="State">Estado</option>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="fips_code" class="">FIPS Code</label>
                                <input type="text" class="form-control" placeholder="Ingrese Codigo FIPS"
                                    v-model="formState.fips_code" name="fips_code"/>
                            </div>
                            <div class="form-group col-12">
                                <label for="country_id" class="required">Pais</label>
                                <v-select v-model="formState.country"
                                name="country_id" @search="onSearchCountry" ref="vSelectCountry"
                                placeholder="Seleccione Pais" selectOnTab label="name"
                                :options="countries" required="required">
                                    <template v-slot:option="option">
                                        <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                        {{ option.name }} {{ (option.native_name) ? `(${option.native_name})` : '' }}
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" v-can:create="'geodata-states'">
                            <span v-if="formState.id && $can('update','geodata-states')">Editar</span>
                            <span v-else-if="formState.id && !$can('update','geodata-states')">Solicitar Edicion</span>
                            <span v-else>Guardar</span>
                        </button>
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["State"],
    components: {
        },
    data() {
        return {
            countries   : [],
            formState: new WebForm("#formState-" + this._uid, {
                name                    : null,
                geographical_division   : 'Division',
                iso_code                : null,
                fips_code               : null,
                country_id              : null,
                country                 : null,
                id                      : null
            }),
        };
    },
    watch: {
        State: function(val, oldVal) {
            if (_.isEmpty(this.State) || this.State.id == '') {
                // console.log('Vacio Att: ', this.AttractionCategory);    
            }else {
                this.formState.fill(this.State);  
                this.getCountry(this.State.country_id);
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            if(vm.formState.country.id) {
                vm.formState.country_id = vm.formState.country.id;
            } else {
                return
            }

            let tipo = (vm.formState.id == null) ? 1 : 2;
            if (this.formState.id != null) {
                $.confirm({
                    title: `Editar Ciudad`,
                    content: "¿Seguro quiere editar esta ciudad?",
                    buttons: {
                        eliminar: {
                            text: "Editar",
                            btnClass: "btn-primary",
                            keys: ["enter"],
                            action: function() {
                                vm.updateOrCreate("/admin/location/states/save", tipo);
                            }
                        },
                        cancelar: function() {}
                    }
                });
            } else {
                vm.updateOrCreate("/admin/location/states/save", tipo);
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formState.post(url).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    $('#addState').modal('hide');
                    return;
                }
                let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                vm.$snotify.success(snotify_message);
                let obj = data.success;
                vm.$emit('reloadStateModal', obj);
                vm.formState.clear();
                vm.formState.reset();
                $('#addState').modal('hide');
                return;
            });
        },
        onSearchCountry(search, loading) {
            if(_.isEmpty(search))
                return
            loading(true);
            this.searchCountry(loading, search, this);
        },
        searchCountry: _.debounce((loading, search, vm) => {
            axios.get(`/admin/location/countries/${escape(search)}`).then(res => {
                vm.countries = res.data
                loading(false);
            });
        }, 800),
        getCountry(id) {
            let vm = this;
            axios.get(`/admin/location/countries/${id}`).then((response) => {
                if (response.data) {
                    let data = response.data;
                    let countries = [{
                        id      :       data.id,
                        code    :       data.short_iso_code,
                        name    :       `${data.name} (${data.native_name})`,
                        label   :       `${data.name}` 
                    }];
                    vm.countries = countries;
                    vm.formState.country = vm.countries[0];
                } else {
                    console.log('No se pudieron cargar los paises');
                }
            });
        },
    },
    mounted() {
        let vm = this;
        $('#addState').off('hidden.bs.modal');
        $('#addState').on('hidden.bs.modal', function () {
            vm.countries   = [];
            vm.formState.clear();
            vm.formState.reset();
            vm.$emit('closeStateModal');
        });
    }
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>