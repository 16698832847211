<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Portadas del Sistema</h5>
                    <p class="text-muted">Imagen y contenido de pantalla principal del cliente</p>
                </div>
                <div>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addCover">
                        <span class="icon-add-2 mr-2"></span> Nueva Portada
                    </button>
                </div>
            </div>

            <data-table :ajax="{ url: '/admin/settings/site-covers/all/dt'}" :scrollY="'calc(100vh - 350px)'" ref="coversTable" :serverSide="true" :columns="dt.columns()"></data-table>

            <cover-modal :MyCover="MyCover" v-on:closeCoverModal="clearCoverVar()" v-on:reloadCoverModal="reloadCoverVar()"></cover-modal>

        </div>
    </div>
</template>
<script>
import dataTable from '~/components/dataTable';
import CoverModal from '~/components/covers/CoverModal';

export default {
    middleware: 'auth',
    layout: 'dashboard',
    components: {
        dataTable,
        CoverModal
    },
    metaInfo() {
        return {
            title: "Portadas Pavolar"
        }
    },
    data() {
        return {
            MyCover: null,
            siteCovers: [],
            modeList: true, // false
            firstLoad: false,
            dt: {
                columns : () => {
                    var $this = this;
                    let columns =
                        [
                            { title: 'id', data: 'id' },
                            // { title: 'Color', data: 'color_primary', orderable: false, searchable: true,
                            //     render: function ( data, type, row) {
                            //         return  `<div class="shadow-sm" style="width: 50px; height: 50px; border: solid 3px white; background-color: ${data};"></div>`;
                            //     }
                            // },
                            { title: 'imagen', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return  `<img src="${row.path_image}${row.image}" width="150" />`;
                                }
                            },
                            { title: 'Detalles', orderable: false, searchable: true,
                                render: function ( data, type, row) {
                                    let desc  = row.image_size == 'COVER' ? 'EXPANDIR' : 'CENTRAR'
                                    let image = `/img/icons/${row.image_size.toLowerCase()}.svg`

                                    let isBefore  = _.isNull(row['start_date']) ? true : moment(row['start_date'], 'YYYY-MM-DD').isAfter(moment())
                                    let classes   = isBefore ? 'text-success icon-circle-check' : 'text-danger icon-alert-warning'
                                    return `<p class="mt-1"><span grid="16" class="${classes} mr-1 mb-2"></span> <span class="font-weight-bold">${isBefore ? "Activo" : "Caducado"}</span></p>
                                    <p class="mt-1"><span class="font-weight-bold small mt-2">${desc}</span>
                                            <img class="d-inline-block ml-2" src="${image}" alt="${row.image_size}" width="30"></p>
                                    `
                                }
                            },
                            // { title: 'Tamaño', data: 'image_size', orderable: false, searchable: true,
                            //     render: function ( data, type, row) {
                            //         let desc  = data == 'COVER' ? 'EXPANDIR' : 'CENTRAR'
                            //         let image = `/img/icons/${data.toLowerCase()}.svg`
                            //         return `<span class="font-weight-bold small">${desc}</span>
                            //                 <img class="d-inline-block ml-2" src="${image}" alt="${data}" width="30">`
                            //     }
                            // },
                            { title: 'contenido', data: 'content', orderable: false, searchable: true, className: 'md-column text-wrap html-block',
                                render: function ( data, type, row) {
                                    let limit = 100
                                    let html  = (data || '').replace(/\&lt;/g, '<').replace(/\&gt;/g, '>')
                                    var text  = html.replace(/<[^>]+>/gm, ' ').split(' ').filter(e => e).join(' ')

                                    return text.length > limit ? `${ text.substring(0, limit-3) }...` : text
                                }
                            },
                            { title: 'Enlace', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    let text   = (row.action_text) ? '<b>Boton: </b>' + row.action_text + '<br>' : '';
                                    let link   = (row.action_text && row.action_link) ? '<b>Enlace: </b>' + row.action_link + '<br>' : '';
                                    let target = (row.action_text) ? '<b>Carga: </b>' + ((row.action_target == '_blank') ? 'Nueva Ventana' : 'Ventana Actual') + '<br>' : '';
                                    return  `<small>${text} ${link} ${target}</small>`;
                                }
                            },
                            { title: 'Periodo', orderable: false, searchable: false,
                                render: function ( data, type, row) {
                                    return _.isNull(row['start_date']) && _.isNull(row['end_date']) ? '<span class="text-success font-weight-bold small">INDEFINIDO</span>' :
                                            `${moment(row['start_date'], 'YYYY-MM-DD').format('LL')} <hr class="m-0"> ${moment(row['end_date'], 'YYYY-MM-DD').format('LL')}`
                                }
                            },
                            { title: 'Acciones', data: null,  orderable: false,
                                render: function ( data, type, row, meta ) {
                                    let btni18n     =  (data.i18n && data.i18n.length > 0) ? `<button class="btn btn-secondary btn-sm mr-1 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addCover" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addCover" title="Traducción"><span class="icon-translate"></span></button>`;
                                    let btnedit     =  $this.$can('update')  ? `<button class="btn btn-success btn-sm mr-1 font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#addCover">Editar</button>` : `-`;
                                    let btndelete   =  $this.$can('update')  ? `<button class="btn btn-danger btn-sm mr-1 font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>` : `-`;
                                    return `<div class="d-flex">${ btni18n + btnedit + btndelete}</div>`;
                                } 
                            },
                        ];
                    return columns
                }
            }
        }
    },
    methods: {
        changeModeView() {
            this.firstLoad= true;
            this.modeList = !(this.modeList);
        },
        clearCoverVar() {
            this.MyCover = null;
        },
        reloadCoverVar () {
            this.getSiteCovers();
            this.$refs.coversTable.ajaxReload();
        },
        getSiteCovers () {
            let endpoint = '/admin/settings/site-covers/all'
            axios.get(endpoint).then(({data}) => {
                this.siteCovers = data;
            });
        },
        onDelete(cover) {
            let vm = this
            $.confirm.delete(
                'Eliminar Portada',
                `¿Está seguro que desea eliminar esta portada?`, 
                function(){
                    vm.deleteItem(cover)
                }
            )
        },
        deleteItem (cover) {
            let vm = this;
            axios.post('/admin/settings/site-covers/delete', { id: cover.id, image: cover.image, path: cover.path_image}).then(response => {

                let data = response.data;

                if (data.user_request_status) {
                    return;
                }

                let snotify_message = 'Se elimino con exito.'
                vm.$snotify.success(snotify_message);
                vm.getSiteCovers();
                vm.$refs.coversTable.ajaxReload();
                return;

            }).catch(err => console.log(err));
        }
    },
    mounted() {
        let vm = this;
        $(vm.$el).off("click.site_covers",".btn-delete");
        $(vm.$el).on("click.site_covers",".btn-delete",function(){
            let cover = vm.$refs.coversTable.rowData($(this).closest('tr'));
            vm.onDelete(cover);
        });

        $(vm.$el).off("click.site_covers",".btn-i18n");
        $(vm.$el).on("click.site_covers",".btn-i18n",function(){
            let cover = vm.$refs.coversTable.rowData($(this).closest('tr'));
            cover.content = (cover.content || '').replace(/\&lt;/g, '<').replace(/\&gt;/g, '>')
            cover.btn_i18n = true;
            vm.MyCover = cover;
        });

        $(vm.$el).off("click.site_covers",".btn-edit");
        $(vm.$el).on("click.site_covers",".btn-edit",function(){
            let cover = vm.$refs.coversTable.rowData($(this).closest('tr'));
            cover.btn_i18n = false;
            cover.content = (cover.content || '').replace(/\&lt;/g, '<').replace(/\&gt;/g, '>') 
            vm.MyCover = cover;
        });
    },
    async created () {
        await this.getSiteCovers();
    }
}
</script>
<style>
.card__cover {
    height: 200px;
    display: block;
    background-position: center;
    background-size: cover;
}
.card__cover p{
    color: white;
    overflow: hidden;
}
</style>