<template>
    <div class="notifications-wrapper">
        <div  class="notification-item py-2 px-2" 
                :class="{ 'new': !alert.is_read }"
                v-for="alert in notifications" :key="alert.id" 
                @click="watchAlert(alert)">
            <div class="notification-item-img">
                <img :src="alert.notification.user.photo || '/img/none.png'" alt="">
            </div>
            <div class="pl-2 flex-grow-1">
                <!-- <h6 class="notification-item-title">
                </h6> -->
                <p class="notification-item-description">
                    <b>{{ alert.notification.title }}</b>
                    {{ alert.notification.description }}
                </p>
                <div>
                    
                    <span class="notification-item-icon">
                        <img :src="`/img/notification/${alert.notification.type.icon}.png`" style="max-height: 17px; max-width: 17px;" alt="">
                    </span>
                    <span class="notification-item-date" data-toggle="tooltip" data-placement="bottom" :title="alert.created_at | moment('LLLL')">
                        {{ alert.created_at | moment('from', 'now') }}
                    </span>
                </div>
            </div>
            <div class="px-2 d-flex align-items-center">
                <a  v-show="!alert.is_read"
                     class="notification-item-mark-view" 
                    data-toggle="tooltip" 
                    data-placement="left"
                    title="Marcar como visto"
                    @click.stop="watchAlert(alert, false, false)"></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['notifications'],
    
    methods: {
        executeAction (data) {
            if(data.action) {
                this.eventHub[data.action](data)
            }
        },
        watchAlert (alert, close = true, execute = true) {
            let vm = this
            let data_notification = alert.notification.data
            let endpoint = '/admin/notification/alert/watch'
            
            data_notification.vm = this

            if(close) {
                document.getElementById('overlay').dispatchEvent(new Event('click'))
            }
            
            if(!alert.is_read) {
                vm.eventHub.$emit('watchNotificationAlert', alert)
                axios.post(endpoint, {
                    id: alert.id
                }).then( ({data}) => {
                    vm.eventHub.$emit('watchNotificationAlert', data)
                })
            }
            if(execute) {
                this.executeAction(data_notification)
            }
        }
       
    },
    created () {
        this.interval = setInterval(() => this.$forceUpdate(), 1000);
    },
    beforeDestroy() {
		clearInterval(this.interval);
	},
    updated () {
        let vm = this
        $('[data-toggle="tooltip"]').tooltip()
        vm.notifications.forEach(a => {
            if(typeof(a.notification.data) != 'object') {
                let data_action = JSON.parse(a.notification.data)
                a.notification.data = data_action
            } 
        });
    }
}
</script>
