<template>
    <div>
        <div class="row mb-2 mx-0">
            <div class="col-12 col-md-8 col-lg-9">
                <h6 class="font-weight-bold mb-1">Hola {{ displayName }}, {{ getGreetingTime }}</h6>
                <p v-if="quote" style="line-height: 1.4;" class="text-muted mb-1">
                    <span class="text-warning icon-star-bold mr-1" grid="12"></span>
                    {{ quote.quote }} ~
                    <small>{{ quote.autor }}</small>
                </p>
            </div>
            <div class="d-flex col-12 col-md-4 col-lg-3">
                <div class="w-100" :class="{'btn-loading btn-loading-dark': loading}">
                    <select name="filter" id="filter" v-model="filter" class="form-control" @change="getAnalytics">
                        <option value="YESTERDAY">Ayer</option>
                        <option value="TODAY">Hoy</option>
                        <option value="THIS_WEEK">Esta semana</option>
                        <option value="LAST_WEEK">La semana pasada</option>
                        <option value="LAST_7_DAYS">Los últimos 7 días</option>
                        <option value="LAST_15_DAYS">Los últimos 15 días</option>
                        <option value="LAST_30_DAYS">Los últimos 30 días</option>
                        <option value="LAST_60_DAYS">Los últimos 60 días</option>
                        <option value="LAST_90_DAYS">Los últimos 90 días</option>
                        <option value="THIS_YEAR">Este año (de enero a hoy)</option>
                        <option value="LAST_YEAR">Último año</option>
                    </select>
                </div>
                <button class="btn btn-primary center-content ml-2" style="max-height: 37px;" :disabled="loading" @click="getAnalytics">
                    <span class="icon-refresh"></span>
                </button>
            </div>
        </div>
        <!-- Renderizacion de datos en graficas -->
        <div v-if="analytics">
            <div class="row mx-0">
                <div class="col-xl-3 col-md-6 pr-md-2 mb-3">
                    <div class="card section border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title small text-uppercase text-muted font-weight-bold mb-1">Búsquedas</h5>
                                    <span class="h4 font-weight-bold mb-0">{{ analytics.count.search | numeral('0,000') }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-gradient-blue text-white rounded-circle">
                                        <span class="icon-user-search" grid="24"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-2 mb-0 text-sm">
                                <span class="capitalize text-nowrap text-muted d-flex align-items-center">
                                    <span class="icon-calendar-3 mr-2" grid="16"></span>
                                    {{ getPeriod().start | moment('from', 'now') }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 pl-md-2 pr-lg-2 mb-3">
                    <div class="card section border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title small text-uppercase text-muted font-weight-bold mb-1">Visitas</h5>
                                    <span v-if="analytics.count.userViews.length > 0" class="h4 font-weight-bold mb-0">{{ analytics.count.userViews[0].pageviews | numeral('0,000') }}</span>
                                <span v-else>0</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-gradient-green text-white rounded-circle">
                                        <span class="icon-user-graph" grid="24"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-2 mb-0 text-sm">
                                <span v-if="analytics.count.userViews.length > 0" class="text-primary mr-1">
                                    {{ analytics.count.userViews[0].users | numeral('0,000') }}
                                </span>
                                <span v-else>0</span>
                                <span class="capitalize text-nowrap text-muted">Usuarios</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 pr-md-2 pl-lg-2 mb-3">
                    <div class="card section border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title small text-uppercase text-muted font-weight-bold mb-1">Reservas</h5>
                                    <span class="h4 font-weight-bold mb-0">{{ analytics.count.booking | numeral('0,000') }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-gradient-purple text-white rounded-circle">
                                        <span class="icon-ticket" grid="24"></span>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-2 mb-0 text-sm">
                                <span class="text-success mr-1">
                                    {{ (((analytics.count.sale * 100) / analytics.count.booking) || 0) | numeral('0.00') }}%
                                </span>
                                <span class="capitalize text-nowrap text-muted">Vendido</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6 pl-md-2 mb-3">
                    <div class="card bg-gradient-dark text-white border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h5 class="card-title small text-uppercase text-muted font-weight-bold mb-1">Cotizaciones</h5>
                                    <span class="h4 font-weight-bold mb-0">{{ analytics.count.quote | numeral('0,000') }}</span>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-gradient-yellow text-white rounded-circle">
                                        <span class="icon-user-file" grid="24"></span>
                                    </div>
                                </div>
                            </div>
                        <p class="mt-2 mb-0 text-sm">
                            <span class="text-success mr-2">
                                {{ (((analytics.count.quoteCom * 100) / analytics.count.quote) || 0) | numeral('0.00') }}%
                            </span>
                            <span class="capitalize text-nowrap text-muted">
                                Finalizadas
                            </span>
                        </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 col-lg-4 pr-lg-2 mt-3 mt-lg-0 order-2 order-lg-1">
                    <div class="section h-100 p-2">
                        <p class="chart-title mb-0">Rutas más buscadas</p>
                        <p class="chart-title font-weight-normal text-muted">Lista de las 5 rutas más buscadas</p>
                        <table class="table table-borderless table-sm mb-0 mt-3" v-if="!_.isEmpty(analytics.topFlightSearch)">
                            <thead class="border-none">
                                <tr>
                                    <th class="small pb-3 font-weight-bold" scope="col">PAIS</th>
                                    <th class="small pb-3 font-weight-bold" scope="col">RUTA</th>
                                    <th class="small pb-3 font-weight-bold text-center" scope="col">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(flightsearch, index) in analytics.topFlightSearch" :key="index">
                                    <td class="small pb-1">
                                        <img class="mr-1" :src="`https://www.countryflags.io/${flightsearch.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="flightsearch.country.short_iso_code">
                                        {{ flightsearch.country.name }}
                                    </td>
                                    <td class="small pb-1">{{ flightsearch.route }}</td>
                                    <td class="small text-center pb-1">{{ flightsearch.count }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-if="_.isEmpty(analytics.topFlightSearch) && !loading"><span class="icon-emoji-sad"></span> No se encuentran datos...</p>
                        <loading :active="_.isEmpty(analytics.topFlightSearch) && loading" title="Cargando" message="Espere un momento..." />
                    </div>
                </div>
                <div class="col-12 col-lg-8 pl-lg-2 order-1 order-lg-2">
                    <div class="section h-100 p-2">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <p class="chart-title mb-0">Actividad</p>
                                <p class="chart-title font-weight-normal text-muted">Actividad de los usuarios por periodos</p>
                            </div>
                            <button class="btn btn-sm btn-primary" disabled>Ver más...</button>
                        </div>
                        <apexchart type="bar" height="225" :options="lineChart.chartOptions" :series="activeUsers"></apexchart>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12 col-lg-4 pr-lg-2 mt-3 mt-lg-0 order-2 order-lg-1">
                    <div class="section h-100 p-2">
                        <p class="chart-title mb-0">Total de Boletos</p>
                        <p class="chart-title font-weight-normal text-muted">Porcentaje de boletos ETICKET emitidos</p>
                        <div class="d-flex justify-content-between">
                            <div class="text-center">
                                <p class="d-block text-muted mb-0">Boletos</p>
                                <h2 class="mb-0 text-center font-weight-bold">
                                    {{ analytics.ticketType.total || 0 }}
                                </h2>
                            </div>
                            <apexchart type="radialBar" height="300" :options="radialBar.chartOptions" :series="ticketPercent"></apexchart>
                        </div>
                        <div class="d-flex justify-content-between px-3">
                            <div class="text-center" v-for="(item, index) in ['ETICKET', 'EMD', 'MCO', 'CNJ']" :key="index">
                                <p class="d-block text-muted small mb-0">{{ item }}</p>
                                <h5 class="mb-0 text-center font-weight-bold">
                                    {{ analytics.ticketType[item] || 0 }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 pl-lg-2 order-1 order-lg-2">
                    <div class="section h-100 p-2">
                        <p class="chart-title mb-0">Boletos VS Reservas</p>
                        <p class="chart-title font-weight-normal text-muted">Comparación de boletos emitidos y reservas finalizadas</p>
                        <apexchart type="area" height="300" :options="areaChart.chartOptions" :series="bookingsVsTicket"></apexchart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .icon-shape {
        display: inline-flex;
        padding: 12px;
        text-align: center;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
    }
    .chart-title{
        font-size: 12px;
        font-weight: bold;
    }
    .capitalize {
        text-transform: capitalize;
    }
</style>

<script>

    /**
     * Apex Charts
     */
    import ApexCharts from 'apexcharts'
    import VueApexCharts from 'vue-apexcharts'
    import { mapGetters, mapActions } from 'vuex'
    
    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Inicio" }
        },
        components: {
            'apexchart': VueApexCharts
        },
        data() {
            return {
                quote: null,
                loading: false,
                filter: 'LAST_15_DAYS',
                analytics: {
                    count: {
                        booking: 0,
                        sale: 0,
                        search: 0,
                        quote: 0,
                        quoteCom: 0,
                        userViews: [{
                            users: 0,
                            pageviews: 0
                        }]
                    },
                    topFlightSearch: [],
                    activeUsers: [],
                    bookingsVsTicket: [],
                    ticketType: {
                        total: 0,
                        ETICKET: 0,
                        EMD: 0,
                        MCO: 0,
                        CNJ: 0
                    }
                },
                lineChart: {
                    chartOptions: {
                        chart: {
                            // height: 350,
                            type: 'bar',
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        // stroke: {
                        //     width: 2,
                        //     curve: 'straight',
                        //     dashArray: [0, 0, 5]
                        // },
                        colors: ['#9f56ff', '#4677fd', '#1ea5f5'],
                        legend: {
                            position: 'left',
                            horizontalAlign: 'center',
                            formatter: (seriesName, opts) => {
                                return [`
                                    <span class="text-muted font-weight-bold small">${_.toUpper(seriesName).replace('ACTIVIDAD ', '')}</span>
                                    <h5 class="text-center mb-2">${_.sum(opts.w.globals.series[opts.seriesIndex])}</h5>
                                `];
                            },
                        },
                        markers: {
                            size: 0,
                            hover: {
                                sizeOffset: 6
                            }
                        },
                        xaxis: {
                            type: 'datetime',
                        },
                    }
                },
                areaChart: {
                    chartOptions: {
                        chart: {
                            type: 'area',
                            height: 350,
                            zoom: {
                                autoScaleYaxis: true
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0,
                            style: 'hollow',
                        },
                        stroke: {
                            width: 2,
                            curve: 'smooth'
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'left'
                        },
                        xaxis: {
                            type: 'datetime',
                        },
                        tooltip: {
                            x: {
                                format: 'dd MMM yyyy'
                            }
                        },
                        colors: ['#1ea5f5', '#4677fd', '#886cff'],
                        fill: {
                            type: 'gradient',
                            colors: ['#1ea5f5', '#4677fd', '#886cff'],
                            gradient: {
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                stops: [0, 100]
                            }
                        },
                    }
                },
                radialBar: {
                    chartOptions: {
                        chart: {
                        height: 350,
                        type: 'radialBar',
                            toolbar: {
                                show: true
                            }
                        },
                        plotOptions: {
                            radialBar: {
                                startAngle: -135,
                                endAngle: 225,
                                hollow: {
                                    margin: 0,
                                    size: '70%',
                                    background: '#fff',
                                    image: undefined,
                                    imageOffsetX: 0,
                                    imageOffsetY: 0,
                                    position: 'front'
                                },
                                track: {
                                    background: '#fff',
                                    strokeWidth: '67%',
                                    margin: 0,
                                },
                                dataLabels: {
                                    show: true,
                                    name: {
                                        offsetY: -10,
                                        show: true,
                                        color: '#888',
                                        fontSize: '17px'
                                    },
                                    value: {
                                        formatter: function(val) {
                                            return `${parseInt(val)}%`;
                                        },
                                        color: '#111',
                                        fontSize: '36px',
                                        show: true,
                                    }
                                }
                            }
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shade: 'dark',
                                type: 'horizontal',
                                shadeIntensity: 0.5,
                                gradientToColors: ['#ABE5A1'],
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100]
                            }
                        },
                        stroke: {
                            lineCap: 'round'
                        },
                        labels: ['ETICKET'],
                    }
                }
            }
        },
        // async asyncData(app, route, store, redirect){
            
        //     let startDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
        //     let endDate   = moment().format('YYYY-MM-DD')
        //     let { data }  = await axios.get(`/admin/analytics/traffic/week?start_date=${startDate}&end_date=${endDate}`)

        //     return {
        //         analytics: data
        //     }
        // },
        computed: {
            ...mapGetters({
                user : 'auth/user',
                displayName : 'auth/displayName',
            }),
            getGreetingTime() {
                var today = new Date()
                var curHr = today.getHours()

                if(curHr < 12)
                    return 'buenos días'
                else if (curHr < 18)
                    return 'buenas tardes'
                else
                    return 'buenas noches'
            },
            activeUsers(){
                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.activeUsers))
                    return []

                let series = []
                let source = this.analytics.activeUsers
                
                series.push({ name: 'Actividad 1 día', data: [] })
                series.push({ name: 'Actividad 7 días', data: [] })
                series.push({ name: 'Actividad 30 días', data: [] })

                source.forEach(element => {
                    // Actividad 1 día
                    series[0].data.push([moment(element['date']).valueOf(), element['1dayUsers']])
                    // Actividad 7 días
                    series[1].data.push([moment(element['date']).valueOf(), element['7dayUsers']])
                    // Actividad 30 días
                    series[2].data.push([moment(element['date']).valueOf(), element['30dayUsers']])
                });

                return series
            },
            bookingsVsTicket(){
                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.bookingsVsTicket))
                    return []

                let series = []
                let source = this.analytics.bookingsVsTicket
                
                series.push({ name: 'Boletos', data: [] })
                series.push({ name: 'Reservas', data: [] })

                source.forEach(element => {
                    // Boletos
                    series[0].data.push([moment(element['date']).valueOf(), element['ticket']])
                    // Reservas
                    series[1].data.push([moment(element['date']).valueOf(), element['booking']])
                });

                return series
            },
            ticketPercent(){
                
                if(_.isNull(this.analytics) || _.isEmpty(this.analytics.ticketType))
                    return [0]
                
                let source  = this.analytics.ticketType
                let percent = (((source.ETICKET * 100) / source.total) || 0)
                return [percent]
            }
        },
        methods: {
            setQuote(quotes){
                this.quote = quotes[Math.floor(Math.random()*quotes.length)]
            },
            getAnalytics(){
                let vm     = this
                let period = vm.getPeriod()
                console.log(period)
                vm.loading = true
                axios.get(`/admin/analytics/traffic/week?start_date=${period.start}&end_date=${period.end}`).then(reponse => {
                    sessionStorage.setItem('pavolar_analytics', JSON.stringify(reponse.data))
                    vm.analytics = reponse.data
                    vm.loading   = false
                });
            },
            getPeriod(){
                switch (this.filter) {
                    case 'YESTERDAY':
                        return {
                            start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'days').format('YYYY-MM-DD')
                        }
                    case 'TODAY':
                        return {
                            start: moment().format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'THIS_WEEK':
                        return {
                            start: moment().startOf('week').format('YYYY-MM-DD'),
                            end:   moment().endOf('week').format('YYYY-MM-DD')
                        }
                    case 'LAST_WEEK':
                        return {
                            start: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                        }
                    case 'LAST_7_DAYS':
                        return {
                            start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_15_DAYS':
                        return {
                            start: moment().subtract(15, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_30_DAYS':
                        return {
                            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_60_DAYS':
                        return {
                            start: moment().subtract(60, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_90_DAYS':
                        return {
                            start: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'THIS_YEAR':
                        return {
                            start: moment().startOf('year').format('YYYY-MM-DD'),
                            end:   moment().format('YYYY-MM-DD')
                        }
                    case 'LAST_YEAR':
                        return {
                            start: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                            end:   moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                        }
                }
            },
            getChartOptions(newOptions) {
                let options = JSON.parse(JSON.stringify(this.chartOptions))

                if(newOptions.labels) options.labels = newOptions.labels

                if(newOptions.fill) {
                    if(newOptions.fill.gradient) {
                        if(newOptions.fill.gradient.gradientToColors) {
                            options.fill.gradient.gradientToColors = newOptions.fill.gradient.gradientToColors
                        }
                        if(newOptions.fill.gradient.colorStops) {
                            options.fill.gradient.colorStops = newOptions.fill.gradient.colorStops
                        }
                    }
                }

                return options
            }
        },
        mounted() {
            
            //Obtiene los datos de analitica desde la sesion
            let analytics = JSON.parse(sessionStorage.getItem('pavolar_analytics') || 'null')
            if(_.isNull(analytics))
                this.getAnalytics();
            else
                this.analytics = analytics
            
            this.$nextTick(() => {

                if(localStorage.getItem('quotes') == null){
                    jQuery.getJSON('/json/quotes.json').then((data) => {
                        localStorage.setItem('quotes', JSON.stringify(data))
                        this.setQuote(data)
                    })
                }else{
                    this.setQuote(JSON.parse(localStorage.getItem('quotes')))
                }
            })

        },
    }
</script>