<template>
    <div class="container">
        <img src="/img/logo-white.png" alt="pavolar.com" class="logo mt-1 mt-md-3 mb-2 mb-md-5">
        <div class="bg-curve">
            <svg xmlns="http://www.w3.org/2000/svg" class="curve" viewBox="0 0 1440 68" enable-background="new 0 0 1440 68"><path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill="#F4F5F7" transform="translate(0-1977)"></path></svg>
        </div>
        <transition name="fade">
            <div key="pendingEmail" v-if="!sendEmail"  class="d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start flex-wrap login-container">
                <div class="col-12 col-md-6 col-lg-4 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                    <h5 class="font-weight-bold mb-1 mt-3">Recuperar Contraseña</h5>
                    <form id="restorePasswordForm" autocomplete="off" @submit.prevent="tryResetePassword" novalidate method="post">
                        <div class="form-group mt-4">
                            <label for="login">Nombre de Usuario o E-mail</label>
                            <input type="text" id="login" class="form-control bg-white" v-model="form.login">
                        </div>

                        <div class="form-group d-flex justify-content-between mt-5">
                            <button type="submit" class="btn btn-success ajax">Recuperar contraseña</button>
                        </div>
                    </form>
                </div>
                <div class="col-12 d-none d-md-block col-md-6 col-lg-8 pl-5 mt-2 mt-md-5 text-white">
                    <h4>Solicita cambio de contraseña</h4>
                    <p>Ingresa el usuario o email de la cuenta que necesitas cambiar contraseña</p>
                </div>
            </div>
            <div key="senEmail" v-else class="d-flex justify-content-center justify-content-center align-items-center align-items-md-start flex-wrap login-container">
                <div class="col-12 col-md-6 col-lg-8 p-3 mt-2 mt-md-5 rounded shadow bg-white" >
                    <div class="d-flex py-3">
                        <div class="px-3">
                            <span class="card__icon icon-send"></span>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-weight-bold mb-1">Solicitud exitosa!</h5>
                            <p>Hemos enviado un correo al email de tu cuenta.</p>
                            <p>Revisa tu buzón  de correo.</p>
                            <p>Si no recibes el correo en unos minutos revisa tu carpeta de spam.</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <p class="text-muted mb-0">© Pavolar | Todos los derechos reservados <span v-once>{{ year }}</span></p>
    </div>
</template>

<script>

    "use strict";
    import store from '~/store'

    export default {
        middleware: 'guest',
        metaInfo () {
            return { title: "Login" }
        },

        data(){
            return {
                visible: false,
                sendEmail: false,
                form: new WebForm("#restorePasswordForm", {
                    login    : '',
                }),
                year: (new Date).getFullYear()
            };
        },

        methods: {
            tryResetePassword() {
                let vm = this
                let endpoint = `/admin/account/password/recover`
                this.form.post(endpoint).then(response => {
                    if(response.data.token) {
                        this.form.reset();
                        this.$snotify.success(`
                            Link de recuperacion enviado.
                            Revisa tu buzon de correo.
                        `);
                        this.sendEmail = true
                        // setTimeout(function(){
                        //     vm.$router.push({name: 'auth-login'})
                        // }, 5000);
                        //
                    } else {
                         this.$snotify.error(`
                            Hubo un problema
                        `);
                    }
                })
            }
        },

    }
</script>

<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
    .card__icon{
        font-size: 3em;
        color: #4463F0;
    }
</style>
