<template>
    <div >
        <vue-perfect-scrollbar class="chat-panel-message-body-wp px-2 pt-2" :settings="{ suppressScrollX: true}"  v-chat-scroll @scroll-top="loadMessages(channel)">
           <div>
                <div v-for="message in channel.messages"
                    :key="message.id"
                    class="chat-message mb-2"
                    :class="{
                        'chat-me': message.user_id == user.id
                    }"
                    :id="`message-${message.id}`">
                    
                    <img class="chat-photo" :src="message.user.photo || '/img/none.png'">
                    <div class="chat-data">
                        <div v-if="message.message" class="chat-message-text">
                            <span v-for="word in parseMessage(message.message)">
                                <span v-if="word.startsWith(':') && word.endsWith(':') && isEmoji(word)" style="font-size: 0;"><emoji :emoji="word" set="messenger" :size="20" />{{ word }}</span>
                                <span v-else v-html="word+' '"></span>
                            </span>
                        </div>
                        <template v-if="message.file">
                            <div v-if="fileFormat(message.file_format) == 'image'"
                                class="chat-message-img mt-2"
                                @click.stop="openBox(message.id, true)"
                                >
                                    <div class="p-relative" 
                                        :style="{
                                        'padding-bottom': message.height/message.width * 100 + '%'
                                        }">
                                        <img @load="loadFinished" :src="`/storage/chatfiles/${message.file}`" width="100%" :alt="message.file_name">
                                        <span class="loading"></span>
                                    </div>
                            </div>
                            <div class="chat-message-file-wrap" v-else>
                                <span   >
                                    <a class="chat-message-file" :href="`/storage/chatfiles/${message.file}`" :download="message.file_name">
                                        <div class="chat-message-file-icon">
                                            <img :src="`/svg/type_file/${fileFormat(message.file_format)}.svg`"  alt="">
                                        </div>
                                        <div class="chat-message-file-name">
                                            {{ message.file_name }}
                                        </div>
                                    </a>
                                </span>
                            </div>
                        </template>
                        <template v-if="channel.conversation">
                            <div  class="chat-check" 
                                :class="{
                                    'chat-check-watch': (channel.conversation.messages_pending == 0)
                                }"
                                
                                :title="channel.conversation.messages_pending == 0 ? 'Leido' : 'Enviado'">
                            </div>
                        
                        </template>
                        
                    </div>
                    
                </div>
            </div>
            <div v-if="channel.users_typing">
                <div v-show="channel.users_typing.length > 0" class="chat-typing">
                    <div class="chat-typing-users">

                        <img class="chat-typing-photo"  v-for="u in channel.users_typing" :key="u.user_id" :src="u.photo || '/img/none.png'">
                        <span class="typing">
                            <span class="typing-point"></span>
                            <span class="typing-point"></span>
                            <span class="typing-point"></span>
                        </span> 
                    </div>
                </div>
            </div>
        </vue-perfect-scrollbar>
        
        <div :id="`box-light-${channel.channel_id}`" v-if="img_box" @click="openBox(null)" class="chat-box-light">
            <div @click.stop="" class="chat-box-light-wrapper">
                <div class="chat-box-light-image mx-auto">
                    <img :src="`/storage/chatfiles/${imageInBox.file}`" alt="">
                    <div class="d-flex chat-box-light-slide">
                        <div class="chat-box-light-slide-panel left" @click="prevImage">
                            <span class="icon icon-left-arrow"></span>
                        </div>
                        <div class="chat-box-light-slide-panel right" @click="nextImage">
                            <span class="icon icon-right-arrow"></span>
                        </div>
                    </div>
                    <div class="chat-box-light-footer p-3">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <div class="chat-box-light-footer-avatar">
                                    <img :src="imageInBox.user.photo || '/img/none.png'" alt="">
                                </div>
                                <div>
                                    <span class="d-block font-weight-bold">{{ imageInBox.user.first_name }} {{ imageInBox.user.last_name }}</span>
                                    <span>{{ imageInBox.created_at }}</span>
                                </div>
                            </div>
                            <div>
                                <a class="chat-box-light-footer-download" :href="`/storage/chatfiles/${imageInBox.file}`" download>Descargar <span class="icon-download-arrow"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-box-light-files">
                    <div>
                        <ul data-v-0417b575=""  role="tablist" class="nav nav-pills mb-0 tabs-right-outside-header">
                            <li data-v-0417b575="" class="nav-item flex-grow-1">
                                <a data-v-0417b575="" data-toggle="pill"  role="tab" aria-controls="pills-notifications" aria-selected="false" 
                                    class="nav-link position-relative px-4"
                                    :class="{'active': tab == 'IMAGES'}"
                                    @click="changeTab('IMAGES')"
                                    >Imagenes</a>
                            </li> 
                            <li data-v-0417b575="" class="nav-item flex-grow-1">
                                <a data-v-0417b575="" data-toggle="pill"  role="tab" aria-controls="pills-notifications" aria-selected="false" 
                                    class="nav-link position-relative px-4" 
                                    :class="{'active': tab == 'FILES'}"
                                    @click="changeTab('FILES')"
                                >Archivos</a>
                            </li> 
                        </ul>
                    </div>
                    <div class="chat-box-light-files-wrapper">
                        <div :class="{
                            'fade-in': tab == 'IMAGES',
                            'd-none': tab != 'IMAGES'
                            }">
                            <div class="chat-box-light-files-images" >
                                <div v-for="message in images" :key="message.id" @click.stop="openBox(message.id)"  class="chat-box-light-thumbnail" :class="{'active': message.id == img_box}">
                                    
                                    <img @load="loadFinished" :src="`/storage/chatfiles/${message.file}`" :alt="message.file_name">
                                </div>
                            </div>
                        </div>
                        <div :class="{
                            'fade-in': tab == 'FILES',
                            'd-none': tab != 'FILES'
                            }">
                            <div v-for="message in files" :key="message.id">
                                <a  class="chat-message-file px-2 py-1" :href="`/storage/chatfiles/${message.file}`" :download="message.file_name">
                                    <div class="chat-message-file-icon">
                                        <img :src="`/svg/type_file/${fileFormat(message.file_format)}.svg`"  alt="">
                                    </div>
                                    <div class="flex-grow-1 chat-message-file-info">
                                        <div class="chat-message-file-name">
                                            {{ message.file_name }}
                                        </div>
                                        <div class="chat-message-file-user">
                                            <img :src="message.user.photo || '/img/none.png'" class="mr-1" alt="">
                                            <span class="mr-1 font-weight-bold">{{message.user.username}}</span>
                                            <span>{{message.created_at}}</span>
                                        </div>
                                        
                                    </div>
                                    <span class="icon ico-cloud-download px-1"></span>
                                </a>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-link mt-3" @click="getFiles">Cargar mas Archivos</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
const dataEmoji = require('emoji-mart-vue/data/messenger.json')
const emojis = Object.keys(dataEmoji.emojis)
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
    components: {
        VuePerfectScrollbar,
    },
    props: ['channel', 'user'],
    data() {
        return {
            files_format: {
                image: ['png', 'jpg', 'jpeg', 'gif'],
                word: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
                excel: ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw', 'csv'],
                power_point: ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm'],
                access: ['accdb', 'accde', 'accdt', 'accdr'],
                rar: ['rar', 'rar4'],
                zip: ['zip', '7zip'],
                pdf: ['pdf'],
                txt: ['txt'],

            },
            img_box: null,
            files: [],
            images: [],
            page_file: 1,
            page_image: 1,
            load_more_files: true,
            load_more_image: true,
            tab: 'IMAGES'
        }
    },
    updated () {
        this.loadLocalImages()
        this.loadLocalFiles()
        // let $active = this.$el.querySelector('.chat-box-light-thumbnail.active')
        // if($active) {
        //     let top = $($active).position().top
        //     this.$el.querySelector('.chat-box-light-files-wrapper').scrollTop = top
        // }
    },
    computed: {
        imageLoadPage() {
            return Math.ceil((this.images.length+1)/10 ) || 1
        },
        fileLoadPage() {
            return Math.ceil((this.files.length+1)/10 ) || 1
        },
        messagesWhitImages() {
            let vm = this
            let images = this.channel.messages.filter( message => {
                return vm.files_format.image.indexOf(message.file_format) >= 0
            })
            return images.reverse()
        },
        messagesWhitFiles() {
            let vm = this
            let files = this.channel.messages.filter( message  => {
                return vm.files_format.image.indexOf(message.file_format) < 0
            })

            return files.reverse()
        },
        imageInBox() {
            let vm = this
            let image = this.images.find( message => {
                return message.id == vm.img_box
            })

            return image
        }
    },
  
    methods: {
        isEmoji (str) {
            let only_str = str.trim()
                only_str = only_str.replace(/:/g, '');

            let emoji = emojis.indexOf(only_str)
            
            return emoji >= 0
        },
        parseMessage (str) {
            let words = str.split(' ')
            return words
        },
        changeTab (tab) {
            this.tab = tab
        },
        prevImage (event) {
             let vm = this
            let indexImage = vm.images.findIndex(img => {
                return img.id ==  vm.img_box
            })
            if(indexImage > 0) {
                vm.img_box = vm.images[indexImage - 1].id
                return
            }
        },
        nextImage (event) {
             let indexImage = this.images.findIndex(img => {
                return img.id ==  this.imageInBox.id
            })
            if(indexImage < (this.images.length - 1) ) {
                this.img_box = this.images[indexImage + 1].id
                return
            } else {
                this.getImages(indexImage+1)
            }
        },
        loadMessages(channel){
            channel.page++
            let $el = this.$el
            this.$emit('loadMessagesPage',{
                channel_id: channel.channel_id,
                page: channel.page,
                $el: $el
            })
        },
        loadFinished (event) {
            event.target.classList.add('load_finished')
            let $loading =  event.target
                .parentElement
                .querySelector('.loading')
            if($loading) {
                $loading.remove()
            }
           
        },
        openBox (message_id, getData = false) {
            this.img_box = message_id
            this.loadLocalImages()
            this.loadLocalFiles()

            if(getData) {
                this.getFiles()
                this.getImages()
            }
            this.files.sort((a, b) => b.id - a.id);
            this.images.sort((a, b) => b.id - a.id);
        },
        loadLocalFiles () {
            let vm = this
            
            let files = this.channel.messages.filter( message => {
                if(message.file_format) {
                    return vm.files_format.image.indexOf(message.file_format.toLowerCase()) < 0
                }
            })
            files.forEach(file => {
                let exist =  vm.files.find(i => {
                    return i.id == file.id
                })
                if(!exist) {
                    vm.files.unshift(file)
                }
            })
           
            
        },
        loadLocalImages () {
            let vm = this
            
            let images = this.channel.messages.filter( message => {
                if(message.file_format) {
                    return vm.files_format.image.indexOf(message.file_format.toLowerCase()) >= 0
                }
            })
            images.forEach(img => {
                let exist =  vm.images.find(i => {
                    return i.id == img.id
                })
                if(!exist) {
                    vm.images.unshift(img)
                }
            })
            
        },
        async getImages (index) {
            let vm = this
            let endpoint = `/admin/chat/messages/images/${this.channel.conversation.channel.id}?page=${this.imageLoadPage}`
            axios.get(endpoint)
                .then((response) => {
                    let data = response.data.data
                    data.forEach(item => {
                        if(vm.files_format.image.indexOf(item.file_format) >= 0) {
                            let exist =  vm.images.find(i => {
                                return i.id == item.id
                            })
                            if(!exist) {
                                vm.images.push(item)
                            }
                        } 

                    })
                    vm.images.sort((a, b) => b.id - a.id);
                    if(response.data.last_page == vm.page_image) {
                        vm.load_more_image = false
                    }
                    if(index != undefined) {
                        if(vm.images[index] ) {
                            vm.img_box = vm.images[index].id
                        }
                    }
                   
                    if(data.length > 0) {
                        vm.page_image++ 
                    }
                    
                })

        },
        async getFiles () {
            let vm = this
            let endpoint = `/admin/chat/messages/files/${this.channel.conversation.channel.id}?page=${this.fileLoadPage}`
            axios.get(endpoint)
                .then((response) => {
                    let data = response.data.data
                    data.forEach(item => {
                        let exist =  vm.files.find(i => {
                            return i.id == item.id
                        })
                        if(!exist) {
                            vm.files.push(item)
                        }

                    })
                    vm.files.sort((a, b) => b.id - a.id);
                    if(data.length > 0) {
                        vm.page_file++ 
                    }
                    if(response.data.last_page == vm.page_file) {

                    }
                })

        },
        fileFormat (file_format) {
            let format = null
            this.files_format.forEach(( item, key ) => {
               if(item.indexOf(file_format) >= 0) {
                   format = key
               }
           })
           return format
        },
        changeActiveChannel (id) {
            this.$emit('changeActiveChannel', id)
        },
    },
  
   
}
</script>