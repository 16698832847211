import Vue from 'vue'
import Vuex from 'vuex'
import storeImporter from '~/helpers/storeImporter';

Vue.use(Vuex)

// Carga los modulos de manera dinamica
const requireContext = require.context('./modules', false, /.*\.js$/)
const modules = storeImporter(requireContext)

export default new Vuex.Store({
    modules
})