var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-messages-container"},[_vm._l((_vm.openChannels),function(channel){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(channel.show && !channel.out),expression:"channel.show && !channel.out"}],key:channel.channel_id,staticClass:"chat-panel-message "},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.channelDesactive),expression:"channelDesactive"}],staticClass:"chat-panel-message-wrapper px-1",class:{ 
                'chat-open': channel.open,
                
            },on:{"click":function($event){$event.stopPropagation();return _vm.changeActiveChannel(channel.channel_id)}}},[_c('header',{staticClass:"chat-panel-message-header px-2",class:{
                        'chat-active': channel.channel_id == _vm.activeChannel,
                        'chat-new':  _vm.messagesPending(channel.channel_id) > 0
                    },on:{"click":function($event){$event.preventDefault();channel.open = !channel.open}}},[_c('div',{staticClass:"position-relative mr-2"},[_c('div',{staticClass:"chat-list-item-photo-group",class:{
                            'chat-one': channel.users.length == 1,
                            'chat-two': channel.users.length == 2,
                            'chat-three': channel.users.length == 3,
                            'chat-four': channel.users.length >= 4
                        }},_vm._l((_vm.randomMembersGroup(channel.users)),function(member){return _c('img',{key:member.id,attrs:{"src":member.photo || '/img/none.png',"alt":""},on:{"error":_vm.defaultImg}})}),0),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.messagesPending(channel.channel_id) > 0),expression:"messagesPending(channel.channel_id) > 0"}],staticClass:"chat-pending-indicator"},[_vm._v(_vm._s(_vm.messagesPending(channel.channel_id)))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOnline(channel.users)),expression:"isOnline(channel.users)"}],staticClass:"chat-list-item-status",class:{
                        'chat-list-item-online': _vm.isOnline(channel.users)
                    }})]),_vm._v(" "),_c('h6',{staticClass:"title position-relative m-0"},[_vm._v("\n                    "+_vm._s(channel.title)+"\n                    \n                ")]),_vm._v(" "),_c('button',{staticClass:"chat-btn-close center-content",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.closeChannel(channel)}}},[_c('span',{staticClass:"icon-close"})])]),_vm._v(" "),_c('vue-chat-messages-body',{staticClass:"chat-panel-message-body",attrs:{"channel":channel,"user":_vm.user},on:{"loadMessagesPage":_vm.onLoadMessagesPage}}),_vm._v(" "),_c('div',{staticClass:"chat-panel-message-footer"},[_c('vue-chat-new-message',{attrs:{"channel_id":channel.di,"channel":channel.channel_id,"active-channel":_vm.activeChannel,"user":_vm.user}})],1)],1)])}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.existOut),expression:"existOut"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideOut),expression:"hideOut"}],staticClass:"chat-panel-message-out"},[_c('div',{staticClass:"chat-panel-message-out-btn center-content",on:{"click":function($event){$event.stopPropagation();return _vm.showOut($event)}}},[_c('span',{staticClass:"icon-bubble-typing"})]),_vm._v(" "),_c('div',{staticClass:"chat-panel-message-out-wrapper",class:{ 'active' : _vm.show_out }},_vm._l((_vm.openChannels),function(channel){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(channel.show && channel.out),expression:"channel.show && channel.out"}],key:channel.channel_id,staticClass:"chat-panel-message-out-item",on:{"click":function($event){$event.stopPropagation();return _vm.showChannel(channel)}}},[_c('div',{staticClass:"position-relative mr-2"},[_c('div',{staticClass:"chat-list-item-photo-group",class:{
                            'chat-one': channel.users.length == 1,
                            'chat-two': channel.users.length == 2,
                            'chat-three': channel.users.length == 3,
                            'chat-four': channel.users.length >= 4
                        }},_vm._l((_vm.randomMembersGroup(channel.users)),function(member){return _c('img',{key:member.id,attrs:{"src":member.photo || '/img/none.png',"alt":""},on:{"error":_vm.defaultImg}})}),0),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.messagesPending(channel.channel_id) > 0),expression:"messagesPending(channel.channel_id) > 0"}],staticClass:"chat-panel-message-header-pending"},[_vm._v(_vm._s(_vm.messagesPending(channel.channel_id)))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOnline(channel.users)),expression:"isOnline(channel.users)"}],staticClass:"chat-list-item-status",class:{
                        'chat-list-item-online': _vm.isOnline(channel.users)
                    }})]),_vm._v("\n                "+_vm._s(channel.title)+"\n                "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.messagesPending(channel.channel_id) > 0),expression:"messagesPending(channel.channel_id) > 0"}],staticClass:"chat-panel-message-out-pending"},[_vm._v(_vm._s(_vm.messagesPending(channel.channel_id)))]),_vm._v(" "),_c('button',{staticClass:"chat-btn-close",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.closeChannel (channel)}}},[_c('span',{staticClass:"icon-close"})])])}),0)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }