<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Estados</h5>
                    <p class="text-muted mb-1">Lista de Estados del Sistema</p>
                </div>
                <div class="ml-auto">
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addState" >
                        <span class="icon-add-2 mr-2"></span> Nuevo Estado
                    </button>
                </div>
            </div>
            
            <state-modal :State="State" v-on:closeStateModal="clearStateVar()" v-on:reloadStateModal="reloadState()"></state-modal>

            <data-table :ajax="{ url: '/admin/location/states/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="stateTable" :serverSide="true" :columns="dt.columns"></data-table>
            
        </div>
    </div>
</template>
<script>

    import dataTable from '~/components/dataTable'
    import StateModal from '~/components/geodatos/StateModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            StateModal
        },
        metaInfo() {
            return {
                title: "Estados del Sistema"
            }
        },
        data() {
            return {
                State    : null,
                dt: {
                    columns : [
                        { title: 'Id', data: 'id'},
                        { title: 'Nombre Estado', data: 'name'},
                        { title: 'Division Geografica',    data: 'geographical_division'},
                        { title: 'Codigo ISO',    data: 'iso_code'},
                        { title: 'Codigo FIPS',   data: 'fips_code'},
                        { title: 'Pais',          data: 'country', orderable: false, searchable: true,
                        render: function ( data, type, row, meta) {
                            return  `<img src="https://www.countryflags.io/${data.short_iso_code}/flat/24.png" class="mr-2" width="24" height="24" alt="${data.short_iso_code}">
                                    ${data.name}`;
                        }},
                        { title: 'Actions',       data: 'id', orderable: false,
                            render: (data, type, row, meta) => {
                                let btnedit     =  this.$can('write')  ? `<button class="btn btn-success btn-sm font-weight-bold btn-edit" data-toggle="modal" data-target="#addState">Editar</button>` : `-`;
                                let btndelete   =  this.$can('write')  ? `<button class="btn btn-danger btn-sm ml-2 font-weight-bold btn-delete">Eliminar</button>` : `-`;
                                return `<div class="d-flex">${ btnedit + btndelete}</div>`;
                            } 
                        },
                    ]
                },
            };
        },
        methods: {
            reloadState() {
                this.$refs.stateTable.ajaxReload();
            },
            clearStateVar() {
                this.State = null;
            },
            onDelete(state) {
                let vm = this;
                $.confirm.delete(
                    'Eliminar Estado',
                    `¿Está seguro que desea eliminar el estado <u>${state.name}</u>?`, 
                    function(){
                        vm.deleteItem(state.id)
                    }
                )
            },
            deleteItem (state_id) {
                let vm = this
                axios.post('/admin/location/states/delete', { id: state_id }).then(response => {
                    let data = response.data;
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    }
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.stateTable.ajaxReload();
                        return
                    } 
                    vm.$snotify.info('Se ha enviado solicitud') 
                })
            }
        },
        mounted() {
            let vm = this;

            $(vm.$el).off("click.state", ".btn-delete");
            $(vm.$el).on("click.state", ".btn-delete",function(){
                let state = vm.$refs.stateTable.rowData($(this).closest('tr'));
                vm.onDelete(state);
            });

            $(vm.$el).off("click.state", ".btn-edit");
            $(vm.$el).on("click.state", ".btn-edit", function(e){
                let state = vm.$refs.stateTable.rowData($(this).closest('tr'))
                vm.State = state;
            });
        },
        destroyed() {
            let vm = this;
            $(vm.$el).off("click.state", ".btn-delete");
            $(vm.$el).off("click.state", ".btn-edit");
        }
    }

</script>