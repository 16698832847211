import axios from 'axios'

class WebForm {

    constructor (target, data = {}) {

        this.processing     = false
        this.exceptions     = []
        this.defaultAction  = ''
        this._baseAction    = ''
        this._actions       = []
        this._default       = data
        this._target        = null;
        this._targetName    = target;
        this._alertClass    = 'alert-danger'
        this._ajaxClass     = 'btn-loading'

        this._getSuccessful = function () { return null };
        Object.assign(this, this._default)
    }
    
    get successful() { 
        return this._getSuccessful(); 
    }

    set successful(v) {
        throw new Error('successful property is read only'); 
    }

    hasFailed(){
        return this.successful === false;
    }

    data() {
        return Object.keys(this._default).reduce((data, key) => (
            { ...data, [key]: this[key] }
        ), {})
    }

    fill(data) {
        Object.keys(this._default).forEach(key => {
            this[key] = data[key]
        })
    }

    _start(){
        this.clear()
        this.processing = true
        if(this._target != null){
            this._target.find(`.btn[type="submit"]`).addClass(this._ajaxClass)
            this._target.find('.alert').remove()
        }
    }

    _end(successful){
        this.processing = false
        this._getSuccessful = function () { return successful };
        if(this._target != null)
            this._target.find(`.btn[type="submit"]`).removeClass(this._ajaxClass)
    }

    clear(){

        this.exceptions = []
        this._getSuccessful = function () { return null };
        Object.keys(this._default).forEach(key =>{
            
            var input = this._getInput(key);
            if(input.length == 0)
                return;

            input.removeClass('is-invalid')
            input.parent().find('.invalid-feedback').remove();
        })

        if(this._target != null)
            this._target.find('div.alert').remove()
    }

    reset() {
        this.defaultAction = this._baseAction;
        Object.keys(this._default).forEach(key =>{
            this[key] = this._default[key]
        })

        this.clear();
    }

    get(url, override, config) {
        return this.request('get', url, override, config)
    }

    post(url, override, config) {
        return this.request('post', url, override, config)
    }

    request(method, url, override = null, config = {}) {

        this._target = $(this._targetName)
        this._start()
        
        var objData = this.data()
        if(override != null && typeof override !== 'undefined'){
            Object.keys(override).forEach(key => {
                objData[key] = override[key]
            })
        }

        var data   = method !== 'get' ? objData : null
        var params = method === 'get' ? objData : null

        // const data = method === 'get' && this._getType(objData) !== 'object' ? { params: objData } : objData
        
        return new Promise((resolve, reject) => { axios.request({ url, method, data, params, ...config })
            .then(response => {
                this._end(true)
                resolve(response)
            })
            .catch(error => {

                this._end(false)
                if (!error.response)
                    return;
                
                let e = error.response.data
                e     = (typeof e === 'string') ? [e] : e
                e     = e || []

                this.exceptions = e
                this._setDomErros();
                // reject(error)
            })
        }).catch(error => {
            // console.log(error)
        })
    }

    submit(){

        this._target = $(this._targetName)
        let action   = this._target.attr('action')
        let method   = this._target.attr('method')

        if(typeof action !== 'undefined' && method !== 'undefined')
            return this.request(method.toLowerCase(), action)

        if($.inArray(this.defaultAction, this._actions) !== -1)
            return this[this.defaultAction]()

        throw new Error('Undefined action or method')

    }

    setActions(actions = []){
        actions.forEach(action => {
            this.setAction(action.name, action.url, action.method || 'post', action.config || {}, action.default || false)
        });
    }

    setAction(name, url, method = 'post', config = {}, isDefault = false){

        if($.inArray(name.trim(), ['post', 'get', 'submit', 'request']) !== -1)
            throw new Error('Invalid action name')

        if($.inArray(name.trim(), Object.keys(this._default)) !== -1)
            throw new Error('Action name in conflict with data')

        if(name.trim() == '')
            throw new Error('Action name is required')

        if(url.trim() == '')
            throw new Error('Action url is required')

        let action   = {
            [name]: () => { 
                return method === 'post' ? this.post(url, config) : this.get(url, config)
            }
        }
        
        if(isDefault){
            this.defaultAction = name
            if(this._baseAction == '')
                this._baseAction = name
        }

        this._actions.push(name)
        Object.assign(this, action)
    }

    _setDomErros(){
        if(this._target == null || this._target.length == 0)
            return;

        var errors = [];
        errors = this.exceptions.hasOwnProperty('errors') ? this.exceptions.errors : this.exceptions;
        errors = this.exceptions.hasOwnProperty('error') ? this.exceptions.error : errors;

        if(errors.length == 1){
            this._target.prepend(`
                <div class="alert ${this._alertClass}">
                    ${ errors[0] }
                </div>`)
            return;
        }
        
        Object.keys(this._default).forEach(key =>{
            var input = this._getInput(key);
            
            if(input.length == 0 || !errors.hasOwnProperty(key))
                return;

            input.addClass('is-invalid')
            errors[key].forEach((error) => {
                input.after(`<span class="invalid-feedback">${error}</span>`);
            })
        })
    }

    _getInput(key){
        var input = $(this._targetName).find(`#${key}`)
        return input.length == 0 ? $(this._targetName).find(`[name="${key}"]`) : input;
    }

    _getType(obj){
        return (Object.prototype.toString.call(obj).match(/\[object (\w+)\]/)[1]).toLowerCase()
    }

}

WebForm.callbacks = []
WebForm.request = (callback) => {
    WebForm.callbacks.push(callback)
    return callback;
}

export default WebForm