<style scoped>
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }

</style>
<template>
    <div>
        <h6 class="font-weight-bold m-0">Configuracion de la cuenta</h6>
        <p class="text-muted">Actualizar informacion de acceso a la cuenta</p>
        <form id="generalForm" class="mt-4" @submit.prevent="save">
            <div class="form-group">
                <label class="d-block">
                    <span class="d-block font-weight-bold">Nombre de Usuario</span>
                    <input id="username" class="form-control" type="text" name="username" v-model="form.username">
                </label>
            </div>
            <div class="form-group">
                <label class="d-block">
                    <span class="d-block font-weight-bold">Correo Electronico</span>
                    <input class="form-control" type="email" name="email" id="email"  v-model="form.email">
                    <span class="text-muted d-block">Si actualiza el correo electronico sera necesario volver a verificar su cuenta</span>
                </label>
            </div>
            <div class="mt-5">
                <v-button class="center-content" :type="'primary'" :loading="form.processing">
                    Guardar
                </v-button>
            </div>
        </form>

        <div class="pt-5">
            <h6 class="border-bottom text-uppercase mt-4 mb-3 font-weight-bold">Desactivar cuenta</h6>
            <p class="text-muted">
                Desactivar cuenta de manera voluntaria, después de desactivar su cuenta no podrá acceder a la información del sistema y la única forma de recupérala sera por medio de reactivación por un administrador.
            </p>

            <button class="btn btn-danger" @click.prevent="confirmDeactivation">Desactivar cuenta</button>
        </div>

        <div class="modal fade" ref="modalConfirm" id="modalConfirm" tabindex="-1" role="dialog" aria-labelledby="modalConfirmLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalConfirmLabel">Confirmacion de Desactivacion</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form id="deactivationForm" @submit.prevent="deactivateAccount">
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="m-0 font-weight-bold" for="password">Contraseña</label>
                            <div class="position-relative">
                                <input :type="!passwordShow ? 'password' : 'text'"  id="password" class="form-control bg-white" v-model="formDeactivation.password">
                                <button type="button" class="btn btn-transparent position-absolute toggle-password" @click.prevent="passwordShow = !passwordShow">
                                    <span class="icon-view" :class="!passwordShow ? 'icon-view' : 'icon-view-off'"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click.prevent="" class="btn btn-light" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Confirmar</button>
                    </div>
                </form>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import vButton from '~/components/button'

export default {
    middleware: 'auth',
    layout: 'dashboard',
    // data() {
    //     let vm = this
    //     return {
    //         form: new WebForm("#generalForm", {
    //             first_name: vm.user.first_name,
    //             last_name : vm.user.last_name,
    //         }),
    //     }
    // },
    data() {
        return {
            passwordShow: false,
            formDeactivation: new WebForm("#deactivationForm", {
                password: '',
            }),
            form: new WebForm("#generalForm", {
                username: '',
                email : '',
            }),
        }
    },
    computed: {
        ...mapGetters({
            user : 'auth/user',
            displayName : 'auth/displayName',
            roles: 'permission/roles'
        })
    },

    watch: {
        user: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                this.form.username = newVal.username
                this.form.email    = newVal.email
            }
        }
    },
    components: {
        vButton
    },
    methods: {
        save() {
            let endpoint = '/admin/user/update/part'
            this.form.post(endpoint).then(response => {
                if(response.data.id) {
                    this.$snotify.success('Guardado')
                }
            })
        },

        confirmDeactivation() {
            $(this.$refs.modalConfirm).modal('show');
        },


        deactivateAccount() {
            let vm = this
            let endpoint = '/admin/account/deactivate'
            this.formDeactivation.post(endpoint).then(response => {
                $(vm.$refs.modalConfirm).modal('hide');
                vm.$snotify.simple('Se ha desactivado la cuenta.')
                vm.eventHub.$emit('logout')
            })
        }
    }
}
</script>
