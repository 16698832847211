<template>
	<div class="modal fade" id="addPackageCategory">
		<div class="modal-dialog">
			<div class="modal-content">
				<form
					id="formPackageCategory"
					autocomplete="off"
					@submit.prevent="onSaveItem()"
					novalidate
				>
					<div class="modal-header">
						<h5 class="font-weight-bold mb-0">
							<span
								v-if="
									PackageCategory &&
									PackageCategory.btn_i18n == true
								"
								>Traducción Categoria de Paquete Turístico</span
							>
							<span v-else-if="formPackageCategory.id"
								>Editar Categoria de Paquete Turístico</span
							>
							<span v-else
								>Nueva Categoria de Paquete Turístico</span
							>
						</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label for="name">Nombre Categoria</label>
							<input
								type="text"
								class="form-control"
								placeholder="Ingrese Nombre"
								v-model="formPackageCategory.name"
								name="name"
							/>
						</div>
					</div>
					<div class="modal-footer">
						<button type="submit" class="btn btn-primary" v-can:create="'packages-categories-index'">
							<span v-if="PackageCategory && PackageCategory.btn_i18n==true">Guardar Traducción</span>
							<span v-else-if="formPackageCategory.id && $can('update', 'packages-categories-index')">Editar</span>
							<span v-else-if="formPackageCategory.id && !$can('update', 'packages-categories-index')">Solicitar Edicion</span>
							<span v-else>Guardar</span>
						</button>
						<button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">
							Cancelar
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["PackageCategory"],
	data() {
		return {
			formPackageCategory: new WebForm("#formPackageCategory", {
				name: "",
                id: null,
				btn_i18n: false,
				id_i18n: null,
			}),
		};
	},
	watch: {
		PackageCategory: function (val, oldVal) {
			if (
				_.isEmpty(this.PackageCategory) ||
				this.PackageCategory.id == ""
			) {
				// console.log('Vacio Att: ', this.PackageCategory);
			} else {
				this.formPackageCategory.fill(this.PackageCategory);
				if (this.PackageCategory.btn_i18n && this.PackageCategory.i18n.length > 0) {
					this.formPackageCategory.name = this.PackageCategory.i18n[0].name;
					this.formPackageCategory.id_i18n = this.PackageCategory.i18n[0].id;
				}
			}
		},
	},
	methods: {
		onSaveItem() {
			let vm = this;
			let tipo = vm.formPackageCategory.id == null ? 1 : 2;
			if (this.formPackageCategory.id != null && this.formPackageCategory.btn_i18n==false) {
				$.confirm({
					title: `Editar Categoria`,
					content: "¿Seguro quiere Editar?",
					buttons: {
						eliminar: {
							text: "Editar",
							btnClass: "btn-primary",
							keys: ["enter"],
							action: function () {
								vm.updateOrCreate(
									"/admin/packages/categories/save",
									tipo
								);
							},
						},
						cancelar: function () {},
					},
				});
			} else {
				vm.updateOrCreate("/admin/packages/categories/save", tipo);
			}
		},
		updateOrCreate(url, type) {
			let vm = this;
			vm.formPackageCategory.post(url).then((response) => {
				let data = response.data;

				if (data.user_request_status) {
					$("#addPackageCategory").modal("hide");
					return;
				}

				let snotify_message =
					type == 1
						? "Elemento Agregado con exito."
						: "Elemento actualizado con exito.";
				vm.$snotify.success(snotify_message);
				let obj = data.success;
				vm.$emit("reloadPackageCategoryModal", obj);
				$("#addPackageCategory").modal("hide");
				return;
			});
		},
	},
	mounted() {
		let vm = this;
		$("#addPackageCategory").off("hidden.bs.modal");
		$("#addPackageCategory").on("hidden.bs.modal", function () {
			vm.formPackageCategory.clear();
			vm.formPackageCategory.reset();
			vm.$emit("closePackageCategoryModal");
		});
	},
};
</script>