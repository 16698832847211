<template>
    <div>
        <div>
            <div v-for="(img, index) in images" :key="index" class="contenedorImg">
                <button type="button" class="close text-light rounded" @click="removeImage(index)">
                    <span aria-hidden="true">&times;</span>
                </button>
                <img :src="img" class="miniature">
            </div>
        </div>
        <div class="contenedorButton" v-if="images.length < nImgs">
            <label title="File" class="btn btn-light btn-lg label-file-style center-content">
                <input type="file" class="file-style rounded" multiple @change="onFileChange" accept="image/jpg,image/jpeg,image/png">
                <i class="icon-image-gallery" grid="48" ></i>
            </label>
        </div>
    </div>    
</template>
<script>
export default {
    props: [
        'nImgs',
        'clearAll'
        ],
    data() {
        return {
            image: '',
            images: [],
        }
    },
    watch: {
        clearAll: function(val, oldVal) {
            let vm = this; // wait
            if (vm.clearAll && vm.images.length>0) {
                vm.images = [];
            }
        }
    },
    methods: {
        onFileChange(e) {
            const vm = this;
            let alert = false;
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            let n_file = vm.images.length;
            files.forEach(element => {
                if (n_file <= (vm.nImgs-1)) {
                    this.createImage(element);
                    n_file++;
                } else {
                    (!alert) ? vm.$snotify.warning('No puede exceder el limite de imagenes') : '';
                    alert = true;
                    return;
                }
            });
            $(e.target).val('');
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
                vm.images.push(vm.image);
                vm.image = '';
                vm.$emit('setGalery', vm.images);
            };
            reader.readAsDataURL(file);
        },
        removeImage(index) {
            this.images.splice(index, 1);
            this.$emit('setGalery', [null]);
        }
    },
    mounted() {
        // console.log('fileComponent mounted.');
    }
}
</script>
<style scoped>
.file-style{
    width: 80px;
    height: 80px;
    display: none;
}
.label-file-style{
    width: 80px;
    height: 80px;
    padding: 0px;
}
.miniature{
    margin: 10px;
    width: 80px;
    height: 80px;
    display: inline-block;
}
.contenedorImg{
    background-color: rgba(250, 250, 250, 0.767);
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    float: left;
}
.close{
    background-color: rgb(160, 160, 160);
    position: absolute;
    margin-left: 90px;
    margin-top: -10px;
    width: 20px;
}
.contenedorButton{
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
}
</style>