
<template>
    <div class="chat-panel-users-overlay"
        :class="{'chat-open': show_users_chat}"
        @click="hideUserChat">
        <div class="chat-panel-users-wrapper" @click.stop="">
            <div class="chat-panel-users"
            :class="{'chat-open': show_users_chat}">
                <div class="chat-panel-body">
                    <div class="d-flex justify-content-between align-items-center px-2">
                        <h6 class="chat-panel-body-title m-0 px-0">@ Usuarios</h6>
                        <span class="btn shadow-none p-0 chat-panel-close"  @click="hideUserChat">
                            <span class="icon-close"></span>
                        </span>
                    </div>
                    <ul class="chat-list m-0 p-0">
                        <li class="chat-list-item"
                            v-for="user in users"
                            :key="user.id">
                            <div v-if="hasConversation(user.conversations)"
                                :id="`channel_id_${(hasConversation(user.conversations).channel_id)}`"
                                class="chat-list-item-conversation" 
                                @click="openChannel({
                                        channel_id: hasConversation(user.conversations).channel.channel,
                                        is_conversation: true,
                                        users: [user],
                                        is_group: false,
                                        title:`${user.first_name} ${user.last_name}`,
                                        conversation: hasConversation(user.conversations)
                                    })">
                                <div class="position-relative">
                                    <img @error="defaultImg"  :src="user.photo || '/img/none.png'" class="chat-list-item-photo">
                                    <span class="chat-pending-indicator" v-show="messagesPendding(hasConversation(user.conversations).channel_id) > 0">
                                        {{messagesPendding(hasConversation(user.conversations).channel_id)}}
                                    </span>
                                    <span class="chat-list-item-status" :class="{
                                        'chat-list-item-online': user.online,
                                        'chat-list-item-offline': !user.online
                                    }"></span>
                                </div>
                                <div class="chat-list-item-name px-2">
                                    {{ user.first_name  }}  {{ user.last_name  }} 
                                    <div class="chat-list-item-date" >
                                        <span v-if="!user.online && user.last_connection">
                                            {{ user.last_connection   || 'Inactivo' | moment('from', 'now')}} 
                                        </span>
                                        <span v-else-if="user.online">
                                            Activo
                                        </span> 
                                        <span v-else>
                                            Inactivo
                                        </span>
                             
                                    </div>
                                    
                                </div>
                                <!-- <div class="chat-list-item-status">
                                    
                                </div> -->
                            </div>
                            <div v-else
                                class="chat-list-item-conversation"
                                @click="openChannel({
                                        channel_id: user.socket_id,
                                        is_conversation: false,
                                        users: [user],
                                        is_group: false,
                                        title: user.name,
                                        conversation: hasConversation(user.conversations)
                                    })">
                                <div class="position-relative">
                                    <img @error="defaultImg"  :src="user.photo || '/img/none.png'" class="chat-list-item-photo">
                                    <span class="chat-pending-indicator" v-show="messagesPendding(hasConversation(user.conversations).channel_id) > 0">
                                        {{messagesPendding(hasConversation(user.conversations).channel_id)}}
                                    </span>
                                    <span class="chat-list-item-status" :class="{
                                        'chat-list-item-online': user.online,
                                        'chat-list-item-offline': !user.online
                                    }"></span>
                                </div>
                                <div class="chat-list-item-name px-2">
                                    {{ user.first_name  }}  {{ user.last_name  }} 
                                    <div class="chat-list-item-date">
                                        <span v-if="!user.online && user.last_connection">
                                            {{ user.last_connection | moment('from', 'now')}} 
                                        </span>
                                        <span v-else-if="user.online">
                                            Activo
                                        </span> 
                                        <span v-else>
                                            Inactivo
                                        </span>
                                    </div>
                                </div>
                                <div class="chat-list-item-status">
                                    <span :class="{
                                        'chat-list-item-online': user.online,
                                        'chat-list-item-offline': !user.online
                                        }"></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="chat-panel-control align-items-center">
                        <h6 class="chat-panel-body-title m-0 px-2 flex-grow-1"># Grupos</h6>
                        <div v-can:write="'vue-chat'" class="chat-panel-control-btn btn p-0 shadow-none" @click.stop="form_create_group = true">
                            <span class="icon-add-2"></span>
                        </div>
                    </div>
                    <ul class="chat-list m-0 p-0">
                        <li class="chat-list-item"
                            v-for="group in groups"
                            :key="group.id">
                            <div class="chat-list-item-conversation" 
                                :id="`channel_id_${group.channel_id}`"
                                @click="openChannel({
                                        channel_id: group.channel.channel,
                                        is_conversation: true,
                                        users: group.members,
                                        is_group: true,
                                        title: group.channel.title,
                                        description: group.channel.description,
                                        conversation: group,
                                    })">
                                <div class="position-relative">
                                    <div class="chat-list-item-photo-group"
                                        :class="{
                                            'chat-one': group.members.length == 1,
                                            'chat-two': group.members.length == 2,
                                            'chat-three': group.members.length == 3,
                                            'chat-four': group.members.length >= 4
                                        }">
                                        <img @error="defaultImg" v-for="member in randomMembersGroup(group.members)" :key="member.id" :src="member.photo || '/img/none.png'" alt="">
                                    </div>
                                    <span class="chat-pending-indicator" v-show="messagesPendding(group.channel_id) > 0">
                                        {{ messagesPendding(group.channel_id)}}
                                    </span>
                                     <span class="chat-list-item-status" :class="{
                                        'chat-list-item-online': isOnline(group.members),
                                        'chat-list-item-offline': !isOnline(group.members)
                                    }"></span>
                                </div>
                                <div class="chat-list-item-name px-2">
                                    {{ group.channel.title }}
                                    <div class="chat-list-item-date">
                                        <span v-if="!isOnline(group.members) && getLastConnection(group.members)">
                                            {{ getLastConnection(group.members)  | moment('from', 'now') }} 
                                        </span>
                                        <span v-else-if="isOnline(group.members)">
                                            Activo
                                        </span>
                                        <span v-else>
                                            Inactivo
                                        </span>
                                    </div>
                                </div>
                                <div v-can:manage="'vue-chat'" v-if="group.channel.user_id == user.id" class="position-relative" v-click-outside="hideOptions">
                                    <div class="chat-options-btn" @click.stop="activeOptions">
                                        <span class="icon-menu-9-bold icon"></span>
                                    </div>
                                    <div  class="chat-options-list">
                                        <a  class="chat-options-list-item" @click.stop="editGroup(group)">
                                            Editar
                                        </a>
                                        <a class="chat-options-list-item text-danger" @click.stop="deleteGroup(group)">
                                            Eliminar
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div @mousedown.stop="closeForm(new_group)"  class="overlay-form"
                :class="{'active': form_create_group}">
                <div @mousedown.stop="hiddenList" class="overlay-form-wrapper form-new-group"
                    :class="{'active': form_create_group}">
                    <div class="overlay-form-header py-2 px-3">
                        Crear Grupo
                        <span class="btn overlay-form-close icon-close" @click="closeForm(new_group)"></span>
                    </div>
                    <div class="overlay-form-body py-2 px-3">
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="ng-title">Titulo</label>
                                    <input id="ng-title" class="form-control" type="text" v-model="new_group.title">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="ng-description">Description</label>
                                    <input id="ng-description" class="form-control" type="text" v-model="new_group.description">
                                </div>
                            </div>
                        </div>
                        <tag-input
                            ref="inputTagAddGroup"
                            :data="dataList"
                            :value="seletedAdd"
                            :title="'Agregar miembros'"
                            :placeholder="'Buscar usario'"
                            @afterAdd="addMemberNewGroup"
                            @afterRemove="removeMemberNewGroup"
                        />
                      
                        <div class="text-right py-3">
                            <button @click="createNewGroup" class="btn btn-primary ajax btn-ajax">
                                Crear Grupo
                                <span class="icon-add-2 icon"></span>
                            </button>
                        </div>
                    </div>
                
                </div>
            </div>
            <div  @mousedown.stop="closeForm(edit_group)" class="overlay-form"
                :class="{'active': edit_group.id}">
                <div @mousedown.stop="" class="overlay-form-wrapper form-new-group"
                    :class="{'active': edit_group.id}">
                    <div class="overlay-form-header py-2 px-3">
                        Editar Grupo {{ edit_group.title }}
                        <span class="btn overlay-form-close icon-close" @click="closeForm(edit_group)"></span>
                    </div>
                    <div class="overlay-form-body py-2 px-3">
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="eg-title">Titulo</label>
                                    <input id="eg-title" class="form-control" type="text" v-model="edit_group.title">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="eg-description">Description</label>
                                    <input id="eg-description" class="form-control" type="text" v-model="edit_group.description">
                                </div>
                            </div>
                        </div>
                        <tag-input
                            ref="inputTagAddGroup"
                            :data="dataList"
                            :value="seletedAddEdit"
                            :title="'Agregar miembros'"
                            :placeholder="'Buscar usario'"
                            @afterAdd="addMemberEditGroup"
                            @afterRemove="removeMemberEditGroup"
                        />
                        <div class="text-right py-3">
                            <button @click="editGroupSubmit" class="btn btn-success ajax btn-ajax">
                                Editar Grupo
                                <span class="icon-refresh icon"></span>
                            </button>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tagInput from '~/components/tagInput'
export default {
    props: ['user', 'users', 'conversations', 'show_users_chat'],
    components: {
        tagInput
    },
    data() {
        return {
            form_create_group: false,
            search_user: null,
            new_group: {
                title: null,
                description: null,
                members: [],
            },
            edit_group: {
                id: null,
                title: null,
                description: null,
                members: [],
            },
            interval: null
            
        }
    },

    created () {
        let vm = this
        vm.eventHub.$on('close-modal-new-group', (data) => {
            vm.closeForm(vm.new_group)
        })
        // this.interval = setInterval(() => this.$forceUpdate(), 1000);
    },
  
    // beforeDestroy() {
	// 	clearInterval(this.interval);
	// },
    computed: {
        dataList () {
            let data = this.users.map(user => {
                return {
                    id:     user.id,
                    img:    user.photo,
                    label:  `${user.first_name} ${user.last_name}`,
                    queryMatch:  `${user.first_name} ${user.last_name} ${user.email} ${user.username}`
                }
            })

            return data
        },
        seletedAdd () {
            let data = this.new_group.members.map(user => {
                return {
                    id:     user.id,
                    img:    user.photo,
                    label:  `${user.first_name} ${user.last_name}`,
                    queryMatch:  `${user.first_name} ${user.last_name} ${user.email} ${user.username}`
                }
            })
            return data
        },
        seletedAddEdit () {
            let data = this.edit_group.members.map(user => {
                return {
                    id:     user.id,
                    img:    user.photo,
                    label:  `${user.first_name} ${user.last_name}`,
                    queryMatch:  `${user.first_name} ${user.last_name} ${user.email} ${user.username}`
                }
            })
            return data
        },
        groups() {
            let g = []
            this.users.forEach(user => {
                user.conversations.forEach(c => {
                    let indexC = g.findIndex(x => {
                        return x.channel_id == c.channel_id 
                    })
                    if(c.channel.group == 1 && indexC < 0) {
                        c.members = []
                        c.members.push(user)
                        g.push(c);
                    } else if(c.channel.group == 1 && indexC >= 0) {
                        g[indexC].members.push(user)
                    }


                })
            });
            return g
        }
    },
  
    methods: {
        getLastConnection (users) {
            let dates = users.map(user => {
                return user.last_connection
            })
            dates.sort(function(a, b) {
                var dateA = new Date(a), dateB = new Date(b);
                return dateB - dateA;
            });
            console.log(dates[0])
            return dates[0]
        },
        isOnline (users) {
            let user_online = users.find(u => {
                return u.online == true
            })

            if(user_online) {
                return true
            }
            return false
        },
        hiddenList () {
            this.search_user = null
        },

        hideUserChat () {
            this.eventHub.$emit('hideUserChat', false)
        },
        hideOptions (e) {
            let $elements = document.querySelectorAll('.chat-options-list')

            $elements.forEach($el  => {
                $el.classList.remove('active')
            })
        },
        activeOptions(e) {
            let $el = e.currentTarget
            .parentElement
            .querySelector('.chat-options-list')
            $el.classList.toggle('active')
            let $elements = document.querySelectorAll('.chat-options-list')

            $elements.forEach($_el  => {
                if($el == $_el) {
                   return
                }
                $_el.classList.remove('active')
            })
            
        },
        searchUsers (group, hidden) {
            if(hidden) {
                this.search_user = null
                return false
            }

            let vm = this
            let users = this.users.filter(user => {
                let str = `${user.first_name} ${user.last_name} ${user.username} ${user.email}`.toLowerCase()
                let n = -1

                if(this.search_user) {
                    n = str.search(this.search_user.toLowerCase())
                }
                let index_user = group.members.findIndex(u => {
                    return u.id == user.id
                })
                return n >= 0 && index_user < 0 
            })

            return users
        },
        deleteGroup(group) {
            let vm = this
            $.confirm.delete(
                `Desea Elminar el grupo ${group.channel.title}`,
                'Si elimina el grupo se eliminaran todos sus mensajes y archivos',
                function(){
                    vm.deleteGroupSubmit(group)
                }
            )
            
        },

        deleteGroupSubmit(group) {
            let vm = this
            let endpoint = `/admin/chat/delete/group/${group.channel.channel}`
            return axios.post(endpoint)
                .then( ({data}) => {
                    console.log(data)
                }).catch( err => {
                    console.log(err)
                })
        },
        editGroup(group) {
            let vm = this
            vm.edit_group.id = group.channel.id
            vm.edit_group.title = group.channel.title
            vm.edit_group.description = group.channel.description
            vm.edit_group.members = []
            group.members.forEach(m => {
                let new_m = {
                    photo: m.photo,
                    id: m.id,
                    first_name: m.first_name,
                    last_name: m.last_name,
                    email: m.email,
                    socket_id: m.socket_id,
                }
                vm.edit_group.members.push(new_m)
            })

        },
        closeForm(form) {
            this.hiddenList()
            this.form_create_group = false
            Object.keys(form).forEach(function(index) {
                if(index != 'members') {
                    form[index] = null
                    return
                } 
                form['members'] = []
            })
           
        },
        randomMembersGroup (members) {
            let vm = this
            let m = []
            let total_show = members.length <= 4 ? members.length : 4
            for(let i = 0; i < total_show; i++) {
                let new_m = vm.getRandom(members, m)
                m.push(new_m)
            }
            return m
            
        },
        getRandom (members, show_members) {
            let m = members[Math.floor(Math.random() * members.length)]
            let m_find = show_members.find( user => {
                return user.id == m.id
            })
            if(m_find) {
                return this.getRandom(members, show_members)
            }
            return m
        },
        editGroupSubmit () {
            let vm = this
            let $el = event.target
            let endpoint = `/admin/chat/edit/group`

            $el.classList.add('load')

            return axios.post(endpoint, vm.edit_group)
                        .then( ({data}) => {
                            console.log(data)
                            if(data.error) {
                                 Object.keys(data.error).forEach(function(key) {
                                    vm.$snotify.error(data.error[key], `Error ${key}`)
                                });
                            } else {
                                vm.closeForm(vm.edit_group)
                            }
                            $el.classList.remove('load')

                        }).catch( err => {
                            console.log(err)
                            $el.classList.remove('load')
                        })
        },
        createNewGroup () {
            let $el = event.target
            this.$emit('createNewGroup', { data: this.new_group, el: $el })
        },
        removeMemberToGroup(items, group) {
            items.forEach(item => {
                let index_user = group.members.findIndex(member => {
                    return member.id == item.id
                })
                if(index_user >= 0) {
                    group.members.splice(index_user, 1);
                }
            });
        },
        addMemberToGroup (user, group) {
            let index_user = group.members.findIndex(member => {
                return member.id == user.id
            })
            if(index_user < 0) {
                group.members.push({
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    photo: user.photo,
                    email: user.email,
                    socket_id: user.socket_id
                })
            }
            this.search_user = null
        },
        addMemberNewGroup (data) {
            let user = this.users.find(user => {
                return user.id == data.item.id
            })
            let group = this.new_group
            this.addMemberToGroup(user, group)
        },
        addMemberEditGroup (data) {
            let user = this.users.find(user => {
                return user.id == data.item.id
            })
            let group = this.edit_group
            this.addMemberToGroup(user, group)
        },
        removeMemberNewGroup (data) {
            let group = this.new_group
            this.removeMemberToGroup(data.items, group)
            console.log('beforeremove', data)
        },
        removeMemberEditGroup (data) {
            let group = this.edit_group
            this.removeMemberToGroup(data.items, group)
            console.log('beforeremove', data)
        },
        openChannel(data) {
            this.eventHub.$emit('hideUserChat', false)
            // this.eventHub.$emit('openChannel', data)
            this.$emit('openChannel', data);
        },
        hasConversation(conversations) {
            let conversation = conversations.find(c => {
                return c.channel.group == 0
            })
            if(conversation) {
                return conversation
            }
            return false
        },
        messagesPendding(id) {

            let _c = this.conversations.find(c => {
                return c.channel_id == id
            })
            if(_c) {
                return _c.messages_pending
            }
            return 0
        }
    }
    
}
</script>

<style scoped>
.column {
    border-right: 2px dotted greenyellow;
}

.channel {
    cursor: pointer;
}

.channel.active {
    background: #666;
}
</style>