<template>
    <div>
        <div class="modal fade" @submit.prevent="saveServiceResponse()" id="serviceResponseModal">
            <div v-if="QuoteServiceId" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formServiceResponse" autocomplete="off" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="ServiceResponse && ServiceResponse.id">
                                    Editar Solucion de Servicio de Transporte
                                </span>
                                <span v-else>Nuevo Solucion de Servicio de Transporte</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-6 col-md-6">
                                    <label for="last_name" class="d-flex align-content-center">Tipo de Transporte </label>
                                    <select name="owner_permission" id="owner_permission" v-model="type" class="form-control">
                                        <option value="RENT_A_CAR">Renta de Vehiculo</option>
                                        <option value="TRANSPORT">Transporte</option>
                                    </select>
                                </div>
                                <div class="form-group col-6 col-md-6" v-show="type=='TRANSPORT'">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="just_start" name="non_date" class="pt-2 btn-add-aa">
                                        Solo fecha inicio
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <!-- -->
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_date" class="required">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="entry_date" name="entry_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_hour" class="required">Hora de Ingreso</label>
                                    <vue-timepicker name="entry_hour" v-model="entry_hour" placeholder="Hora de Ingreso" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div class="form-group col-12 col-md-3" :disabled="(just_start && type!='RENT_A_CAR')">
                                    <label for="out_date" :class="[(type=='RENT_A_CAR' | !just_start) ? 'required' : null ]">Fecha de Salida</label>
                                    <vue-datepicker v-model="out_date" name="out_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3" :disabled="(just_start && type!='RENT_A_CAR')">
                                    <label for="out_hour" :class="[(type=='RENT_A_CAR' | !just_start) ? 'required' : null ]">Hora de Salida</label>
                                    <vue-timepicker name="out_hour" v-model="out_hour" placeholder="Hora de Salida" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formServiceResponse__cost"
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formServiceResponse__taxes"
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formServiceResponse__utility"
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <!--  -->
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="total">Precio total por servicio</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="priceTotal"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" 
                                    :precision="2" :decimal-separator="'.'" disabled
                                    name="total"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-8">
                                    <label for="url">Enlace de origen del precio</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="url" name="url">
                                </div>
                                <div class="form-group col-12">
                                    <label>Descripcion de estadia</label>
                                    <textarea class="form-control" v-model="description" name="description" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" :disabled="!validateSave || !owner" class="btn btn-primary" v-can:create="'packages-index'"> 
                                <span v-if="ServiceResponse && ServiceResponse.id && $can('update', 'packages-index')">
                                    Editar Servicio
                                </span>
                                <span v-else>
                                    Guardar Servicio
                                </span>
                            </button>
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="css" scoped>
    .is-invalid{
        border-color: #e3342f;
    }
</style>
<script>

import VueNumeric from 'vue-numeric';

export default {
    components: {
        VueNumeric,
    },
    props: {
        ServiceResponse: {
            type: [Object],
            default () {
                return false
            }
        },
        QuoteServiceId: null,
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    data() {
        return {
            serviceIndex:           null,
            loadServices          : false,
            formServiceResponse: new WebForm('#formServiceResponse', {
                id                      : null,
                quote_service_id        : null,
                body_json               : null
            }),
            just_start              : false,
            type                    : 'RENT_A_CAR',
            entry_date              : '',
            out_date                : '',
            entry_format            : '',
            out_format              : '',
            entry_hour              : {"H":"00", "mm":"00"},
            out_hour                : {"H":"00", "mm":"00"},
            cost                    : 0,
            taxes                   : 0,
            utility                 : 0,
            description             : '',
            url                     : '',
        };
    },
    watch: {
        QuoteServiceId: function(val, oldVal) {
            let vm = this;
            if(vm.QuoteServiceId!=null) {
                vm.formServiceResponse.quote_service_id = vm.QuoteServiceId;
            } else {
                vm.formServiceResponse.quote_service_id = null;
            }
        },
        ServiceResponse: function(val, oldVal) {
            let vm = this;
            if(vm.ServiceResponse!=null && vm.QuoteServiceId!=null) {
                let vm = this;
                vm.formServiceResponse.id  = vm.ServiceResponse.id;
                vm.entry_date              = vm.ServiceResponse.body_json.entry_date;
                vm.entry_hour              = vm.ServiceResponse.body_json.entry_hour;
                vm.entry_format            = vm.ServiceResponse.body_json.entry_format;
                vm.out_date                = vm.ServiceResponse.body_json.out_date;
                vm.out_hour                = vm.ServiceResponse.body_json.out_hour;
                vm.out_format              = vm.ServiceResponse.body_json.out_format;
                vm.cost                    = vm.ServiceResponse.body_json.cost;
                vm.taxes                   = vm.ServiceResponse.body_json.taxes;
                vm.utility                 = vm.ServiceResponse.body_json.utility;
                vm.description             = vm.ServiceResponse.body_json.description;
                vm.url                     = vm.ServiceResponse.body_json.url;
            } else {
                vm.formServiceResponse.id  = null;
                vm.entry_date              = '';
                vm.entry_format            = '';
                vm.out_date                = '';
                vm.out_format              = '';
                vm.cost                    = 0;
                vm.taxes                   = 0;
                vm.utility                 = 0;
                vm.description             = '';
                vm.url                     = '';
                vm.entry_hour              = {"H":"00", "mm":"00"};
                vm.out_hour                = {"H":"00", "mm":"00"};
            }
        }, 
    },
    computed: {
        validateSave() {
            let vm = this;
            let response = true;
            response = (vm.entry_date) ? response : false;
            if (!vm.just_start) {
                response = (vm.out_date) ? response : false;
            }
            response = (vm.cost && vm.cost > 0) ? response : false;
            response = (vm.utility && vm.utility > 0) ? response : false;
            return response;
        },
        priceTotal() {
            let vm = this;
            let cost        = (vm.cost) ? parseFloat(vm.cost) : 0; 
            let taxes       = (vm.taxes) ? parseFloat(vm.taxes) : 0;
            let utility     = (vm.utility) ? parseFloat(vm.utility) : 0;
            let total = cost + taxes + utility;
            vm.card_amount = total;
            return total;
        },
    },
    methods: {
        saveServiceResponse() {
            let vm = this;

            let entry_format    = (vm.entry_hour.H>9 || vm.entry_hour.H.length > 1) ? vm.entry_date + ' ' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' : vm.entry_date + ' 0' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' ;
            
            let out_format = null;
            
            if (!vm.just_start) {
                out_format = (vm.out_hour.H>9 || vm.out_hour.H.length > 1) ? vm.out_date + ' ' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' : vm.out_date + ' 0' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' ;
            } 
            
            let newData = {
                just_start:         vm.just_start,
                cost:               vm.cost,
                description:        vm.description,
                entry_date:         vm.entry_date,
                entry_format:       entry_format,
                entry_hour:         vm.entry_hour,
                type:               vm.type,
                out_date:           vm.out_date,
                out_format:         out_format,
                out_hour:           vm.out_hour,
                taxes:              vm.taxes,
                url:                vm.url,
                utility:            vm.utility
            };

            if (vm.ServiceResponse && vm.ServiceResponse.id) {
                vm.formServiceResponse.id = vm.ServiceResponse.id;
            }
            vm.formServiceResponse.body_json      = JSON.stringify(newData);
            
            vm.formServiceResponse.post('/admin/quotes/services/save').then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if(data.success) {
                    let msj = (vm.ServiceResponse && vm.ServiceResponse.id) ? 'Transporte editado con exito.' : 'Nueva Servicio agregado.' ;
                    vm.$snotify.success(msj);
                    let obj = data.success;
                    this.$emit("saveServiceResponse", obj);
                    $("#serviceResponseModal").modal("hide");
                }
                return;
                vm.$snotify.info(`Algo resulto mal...`)
            });
        },
    },
    mounted() {
        let vm = this;
        $('#serviceResponseModal').off('hidden.bs.modal');
        $('#serviceResponseModal').on('hidden.bs.modal', function () {
            vm.$emit('closeServiceResponse');
        });
        console.log('ServiceResponseModal mounted.');
    }
}
</script>

