<template>
    <div class="text-center container my-5">
        <h1>Pavolar</h1>
        <p class="text-muted">Mapa de sitio pavolar</p>
        <div class="mb-5">
            <div class="row" v-for="(item, index) in routes" :key="index">
                <div class="col-3 text-left py-1">
                    <a :href="item.path">{{item.path}}</a>
                </div>
                <div class="col-3 text-left py-1">
                    {{item.name}}
                </div>
            </div>
        </div>
        <!-- <ul class="text-left list">
            <li v-for="(item, index) in routes" :key="index">
                <a :href="item.path">{{item.path}}</a> {{item.name}}
            </li>
        </ul> -->
    </div>
</template>

<script>
export default {
    data(){
        return{
            routes: []
        }
    },
    methods : {
        async obtenerPaginas() {
            let pages = await axios.get(`/admin/page/json`);
            if (pages.data) {
                console.log("Paginas", pages.data);
            } else {
                console.log("Error al obtener paginas");
            }
        }
    },
    mounted(){
        this.routes = this.$router.options.routes
        this.obtenerPaginas();
    }
}
</script>