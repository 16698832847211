<template>
    <div>
        <div id="listImg">
            <div v-for="(img, index) in images" :key="index" class="contenedorImg">
                <button type="button" class="close text-light rounded" @click="removeImage(index, img.id)">
                    <span aria-hidden="true">&times;</span>
                </button>
                <img :src="img.route+img.image" class="miniature">
            </div>
        </div>
    </div>    
</template>
<script>
export default {
    props: [
        'images',
        'urlDelete',
        'clearAll'
        ],
    data() {
        return {

        }
    },
    watch: {
        clearAll: function(val, oldVal) {
            let vm = this;
            if (vm.clearAll && vm.images.length>0) {
                vm.images = [];
            }
        }
    },
    methods: {
        removeImage(index, id) {
            let vm = this
            $.confirm.delete(
                'Eliminar Imagen',
                `¿Está seguro que desea eliminar esta Imagen?`, 
                function(){
                    vm.deleteGallery(index, id);
                }
            )
        },
        deleteGallery(index, idDel) {
        let vm = this
        axios.post(vm.urlDelete, { id: idDel }).then(response => {
                let data = response.data;
            
                if(!data.success) {
                    if(data.data.error) {
                        vm.$snotify.warning(data.data.error, 'Error')
                    }
                    return
                } 
                if(data.success) {
                    vm.images.splice(index, 1);
                    vm.$snotify.success('Imagen Eliminada con Exito');
                    vm.$emit('imageErased', vm.images);
                    return
                } 
                vm.$snotify.simple('Se ha enviado solicitud')

            }).catch(err => console.log(err));
        
        },
    },
    mounted() {
        // console.log('imageDisplay mounted.');
    }
}
</script>
<style scoped>
.file-style{
    width: 80px;
    height: 80px;
    display: none;
}
.label-file-style{
    width: 80px;
    height: 80px;
    padding: 0px;
}
.miniature{
    width: 80px;
    height: 80px;
    margin: 10px;
    display: inline-block;
}
.contenedorImg{
    background-color: rgba(250, 250, 250, 0.767);
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    float: left;
}
.close{
    background-color: rgb(160, 160, 160);
    position: absolute;
    margin-left: 90px;
    margin-top: -10px;
    width: 20px;
}
</style>