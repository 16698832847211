<template>
    <div class="modal fade" id="utilityTestModal" tabindex="-1" role="dialog" aria-labelledby="utilityTestLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="utilityTestLabel">
                        <span class="icon-ia mr-2" grid="24"></span>
                        Zona de prueba
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3 mx-0 p-0" v-if="periodItem">
                        <div class="alert w-100 bg-light border shadow-sm py-2">

                            <button v-if="allowRemovePeriod" type="button" class="close text-danger" data-dismiss="alert" aria-label="Close" @click="removePeriod">
                                <span aria-hidden="true"><span class="icon-bin"></span></span>
                            </button>

                            <p class="font-weight-bold mb-0">
                                Periodo Número {{ periodItem.id }}
                            </p>
                            <p class="text-muted">{{ periodItem.description }}</p>
                            <hr>
                            <p class="mt-3 mb-1 small font-weight-bold">RANGO DE FECHAS</p>
                            <div class="d-flex align-items-center">
                                <span class="icon-calendar mr-2"></span> {{ periodItem.start_date | moment('LL') }}
                                <span class="icon-right-arrow mx-3"></span>
                                <span class="icon-calendar mr-2"></span> {{ periodItem.end_date | moment('LL') }}
                            </div>
                        </div>
                    </div>
                    <div v-else class="alert bg-light border shadow-sm" role="alert">
                        <span class="icon-wallet-international mr-1"></span> La utilidad se calculara en base al periodo vigente de manera automática
                    </div>

                    <div class="row mb-3 mx-0 p-0" v-if="utilityResult">
                        <div class="col-12 alert alert-primary shadow-sm rounded py-2" role="alert">
                            <p class="text-muted small text-italic">
                                <span class="icon-alert-warning" grid="12"></span> 
                                {{ 
                                    utilityResult.range_rule == null ? 
                                        (utilityResult.period != null ? 
                                            'El valor fue obtenido de los datos por defecto del periodo' : 
                                            'El valor fue obtenido de la configuración del sistema') : ''
                                }}
                            </p>
                            <table class="table table-borderless table-sm m-0">
                                <tbody>
                                    <tr>
                                        <th class="alert-primary" scope="row">Monto</th>
                                        <td>{{ utilityResult.amount | numeral('$0,000.00') }}</td>
                                    </tr>
                                    <tr>
                                        <th class="alert-primary" scope="row">Porcentaje</th>
                                        <td>{{ utilityResult.percent }}%</td>
                                    </tr>
                                    <tr>
                                        <th class="alert-primary" scope="row">Periodo</th>
                                        <td v-if="utilityResult.period">
                                            {{ utilityResult.period.id }} - ({{ utilityResult.period.start_date | moment('L') }} | {{ utilityResult.period.end_date | moment('L') }})
                                        </td>
                                        <td v-else class="text-muted small font-weight-bold">
                                            INDEFINIDO
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="alert-primary" scope="row">Regla</th>
                                        <td v-if="utilityResult.range_rule">
                                            {{ utilityResult.range_rule.rule_id }}
                                        </td>
                                        <td v-else class="text-muted small font-weight-bold">
                                            INDEFINIDO
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <form id="testForm" autocomplete="off" novalidate @submit.prevent="testRule">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="departure" class="d-flex justify-content-between">Origen </label>
                                <v-select v-model="testForm.departure" id="departure" placeholder="Buscar Origen..." :options="airports" :reduce="(airport) => airport.id" @input="inputAirport" label="name" :filterable="false" @search="onSearchAirport">
                                    <template slot="no-options">Buscar Aeropuertos...</template>
                                    <template slot="option" slot-scope="option">
                                        ({{ option.iata }}) {{ option.name }}
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        ({{ option.iata }}) {{ option.name }}
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-12">
                                <label for="arrival" class="d-flex justify-content-between">Destino </label>
                                <v-select id="arrival" v-model="testForm.arrival" placeholder="Buscar Destino..." :options="airports" :reduce="(airport) => airport.id" @input="inputAirport" label="name" :filterable="false" @search="onSearchAirport">
                                    <template slot="no-options">Buscar Aeropuertos...</template>
                                    <template slot="option" slot-scope="option">
                                        ({{ option.iata }}) {{ option.name }}
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        ({{ option.iata }}) {{ option.name }}
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <div class="d-flex justify-content-between">
                                    <label class="mb-1" for="airlines">Aerolineas</label>
                                </div>
                                <airline-input v-model="testForm.airlines" placeholder="Buscar Aerolinea..." :multiple="true"></airline-input>
                            </div>
                            <div class="form-group col-12">
                                <div class="d-flex justify-content-between">
                                    <label class="mb-1" for="fareClasses">Clases de Tarifas</label>
                                </div>
                                <v-select v-model="testForm.fareClasses" id="fareClasses" placeholder="Seleccionar..." multiple :options="fareClasses"></v-select>
                            </div>
                        </div>
                        <div class="modal-footer pb-0">
                            <button type="submit" class="btn btn-primary">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    [scope="row"]{
        width: 100px;
    }
</style>

<script>

    import airlineInput from '~/components/airlineInput'

    export default {
        props: {
            period: {
                type: Object,
                default: null,
            },
            allowRemovePeriod: {
                type: Boolean,
                default: true,
            }
        },
        components: {
            airlineInput
        },
        data() {
            return {
                periodItem: null, 
                utilityResult: null, 
                airports: [],
                fareClasses: [],
                selectedAirports: [],
                testForm: new WebForm('#testForm', {
                    departure:   null,
                    arrival:     null,
                    airlines:    [],
                    fareClasses: [],
                }),
            }
        },
        watch: {
            period: function(val, oldVal) {
                this.periodItem = val
            },
        },
        methods: {
            onSearchAirport(search, loading) {
                if(_.isEmpty(search))
                    return
                loading(true);
                this.searchAirport(loading, search, this);
            },
            searchAirport: _.debounce((loading, search, vm) => {
                axios.get(`/admin/airports/${escape(search)}`).then(res => {
                    vm.airports = _.union((res.data || []), vm.selectedAirports)
                    loading(false);
                });
            }, 800),
            getFavoriteArilines(){
                axios.get('/admin/airlines/favorite').then(response => {
                    this.airlines = this.favoriteAirlines = response.data || []
                })
            },
            inputAirport(key){
                if(!_.isEmpty(_.find(this.selectedAirports, { 'id': key })))
                    return

                let airport = _.find(this.airports, { 'id': key })
                if(!_.isEmpty(airport))
                    this.selectedAirports.push(airport)
            },
            setPeriod(data){
                if(data != null)
                    this.periodItem = data
            },
            removePeriod(){
                this.periodItem = null
            },
            show(data = null){
                this.setPeriod(data)
                $("#utilityTestModal").modal('show')
            },
            testRule(){
                let vm       = this
                let period   = vm.periodItem == null ? null : vm.periodItem.id
                let airlines = (vm.testForm.airlines || []).length > 0 ? vm.testForm.airlines.map(e => e.id) : []
                vm.testForm.get('/admin/sales-utility/test', { airlines, period }).then((response) => {
                    console.log(response.data)
                    vm.utilityResult = response.data
                })
            }
        },
        created() {
            this.getFavoriteArilines()
            this.fareClasses = _.range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1, 1).map(x => String.fromCharCode(x))
        },
        mounted(){
            
            let vm = this
            $("#utilityTestModal").off("hidden.bs.modal");
            $("#utilityTestModal").on("hidden.bs.modal", function(){
                vm.removePeriod()
            });
        }
    }
</script>