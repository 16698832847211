<template>
    <div id="dashbord-wrapper" v-bind:class="{ 'fixed-navbar': toggleMenu}">

        <!-- navegacion -->
        <nav class="sidebar" v-bind:class="{ 'active': showMenu, 'toggled': toggleMenu}">
            <menu-loading v-show="this.menu.length == 0"></menu-loading>

            <transition name="fade">
                <div v-show="this.menu.length > 0" class="h-100">
                    <vue-perfect-scrollbar class="scroll-area mt-3" :settings="{ suppressScrollX: true}">
                        <div class="navbar-brand d-none d-md-flex justify-content-center">
                           <router-link :to="{ name: 'index'}" class="navbar-logo-min" @click.prevent="showMenu = false">
                                <img src="/img/logo-white.svg" alt="pavolar.com">
                            </router-link>
                            <div class="sidenav-toggler" v-bind:class="{'active': toggleMenu}" @click.prevent="toggleMenu = !toggleMenu">
                                <div class="sidenav-toggler-inner">
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                    <i class="sidenav-toggler-line"></i>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-transparent text-white btn-icon d-md-none close-sidebar" type="button" @click.prevent="showMenu = false">
                            <span class="icon-close"></span>
                        </button>

                        <div class="user d-flex justify-content-between align-items-center px-2 my-3" v-if="user">
                            <div class="user-data center-content flex-wrap w-100">
                                <div class="user-photo center-content w-100">
                                    <router-link class="bg-light rounded-circle" :to="{ name: 'users-username-profile', params: { username: user.username }}">
                                        <img :src="user.photo || '/img/none.png'" class="rounded-circle">
                                    </router-link>
                                    <button class="user-photo-button btn btn-light center-content position-absolute" @click="$refs.cropPhoto.chooseFile()">
                                        <span class="icon-pencil" style="font-size: 14px;"></span>
                                    </button>
                                </div>
                                <div class="user-info text-center mt-2 w-100">
                                    <div class="user-name w-100 text-white">{{ displayName }}</div>
                                    <div class="user-role small w-100">
                                        <!-- @{{ user.username }}  -->
                                        {{ user.hight_role.title }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="sidebar-body px-2">
                            <router-link v-if="user" :to="{ name: 'account-index' }" class="btn btn-success w-100">Mi Cuenta</router-link>
                            <ul class="list-group mt-3" v-if="menu">
                                <li v-for="menuItem in menu" :key="menuItem.id">
                                    <router-link :to="menuItem.url.split('\:')[0]" :title="menuItem.title" v-if="!menuItem.childrens && menuItem.access"  class="list-group-item truncate">
                                        <span class="mr-3" :class="menuItem.icon"></span>
                                        <span class="menu-item">{{ menuItem.title }}</span>
                                    </router-link>
                                    <div v-else-if="menuItem.access">
                                        <a :href="'#submenu'+menuItem.id" data-toggle="collapse" :data-target="'#submenu'+menuItem.id" :aria-controls="'#submenu'+menuItem.id" aria-expanded="true" class="list-group-item flex-column align-items-start collapsed">
                                            <div class="d-flex w-100 justify-content-start align-items-center" :title="menuItem.title">
                                                <span class=" mr-3" :class="menuItem.icon"></span>
                                                <span class="menu-item truncate">{{ menuItem.title }}</span>
                                            </div>
                                        </a>
                                        <div :id="'submenu'+menuItem.id" class="menu-collapsed collapse">
                                            <div v-for="menuChild in menuItem.childrens"  :title="menuChild.title" :key="menuChild.id">
                                                <router-link v-if="menuChild.access" :to="menuChild.url" class="list-group-item sub-group-item truncate">
                                                    <span class="mr-3" :class="menuChild.icon"></span>
                                                    <span class="menu-item truncate">{{ menuChild.title }}</span>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar-footer px-2">
                            <ul class="list-group mt-1"></ul>
                        </div>
                    </vue-perfect-scrollbar>
                </div>
            </transition>
        </nav>

        <!-- Panel de Acciones -->
        <nav class="right-sidebar px-0" v-bind:class="{ 'active': showPanel}">

            <div class="mt-0 notification-center">
                <ul class="nav nav-pills mb-0 tabs-right-outside-header" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link position-relative active px-4 py-2" id="pills-notifications-tab" data-toggle="pill" href="#pills-notifications" role="tab" aria-controls="pills-notifications" aria-selected="false">
                           <span class="count-pending" v-show="notifications_count > 0">{{notifications_count}}</span>
                           <div class="center-content"> <span class="icon-alarm mr-2"></span> Notificaciones</div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link position-relative px-4 py-2" id="pills-requests-tab" data-toggle="pill" href="#pills-requests" role="tab" aria-controls="pills-contact" aria-selected="false">
                            <span class="count-pending" v-show="user_requests_count > 0">{{user_requests_count}}</span>
                            <div class="center-content"> <span class="icon-user-shield mr-2"></span> Solicitudes</div>
                        </a>
                    </li>
                    <li class="nav-item center-content">
                        <div class="close-right-sidebar center-content" @click.prevent="showPanel = false">
                            <span class="icon-close"></span>
                        </div>
                    </li>
                </ul>


                <div class="tab-content tabs-right-outside" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-notifications" role="tabpanel" aria-labelledby="pills-notifications-tab">
                        <vue-notifications ref="notifications" :notifications="notifications"/>
                    </div>
                    <div class="tab-pane fade" id="pills-requests" role="tabpanel" aria-labelledby="pills-requests-tab">
                        <vue-requests ref="requests" @openRequestCompare="openRequestCompare" :user_requests="user_requests"/>
                    </div>
                </div>
            </div>

        </nav>

        <popup-search @closeSearch="onCloseSearch" :showSearch="showSearch"/>

        <!-- Contenedor -->
        <div class="wrapper d-flex flex-direction-col justify-content-between">
            <nav class="navbar navbar-expand-md navbar-light bg-primary">
                <div class="d-flex aling-items-center justify-content-between w-100">

                    <ul class="navbar-nav">
                        <li class="nav-item d-block d-md-none mx-0">
                            <span class="btn btn-transparent text-dark center-content nav-link show-sidebar" @click="showMenu = true"><span class="icon-menu-6-bold"></span></span>
                        </li>
                        <li class="nav-item mx-0">
                            <!-- <span class="btn btn-transparent center-content nav-link cursor-pointer" data-toggle="tooltip" title="Busqueda" @click="showSearch = true">
                                <span class="icon-search-bold"></span>
                                <span class="d-none d-md-inline ml-2">Búsqueda Rápida...</span>
                            </span> -->
                        </li>
                    </ul>

                    <span class="d-block d-md-none m-auto brand">
                        <img src="/img/logo-dark.svg" alt="pavolar.com" class="navbar-logo">
                    </span>
                    <ul class="navbar-nav align-items-center right-nav-options">
                        <!-- <li class="nav-item mx-0 mx-md-1 d-none d-md-block">
                            <div class="dropdown">
                                <a class="btn btn-transparent center-content nav-link" id="translate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="ico-global"></span>
                                    <span class="d-none d-md-inline ml-1">EN</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="translate">
                                    <a class="dropdown-item">English</a>
                                    <a class="dropdown-item">Español</a>
                                </div>
                            </div>
                        </li> -->

                        <li v-can:view="'vue-chat'" class="nav-item d-none d-md-block mx-0 mx-md-1">
                            <router-link to="/support/email" class="btn btn-transparent center-content nav-link p-relative cursor-pointer" data-toggle="tooltip" title="Soporte">
                                <span v-show="unansweredEmailsCounter != 0" class="chat-pending-indicator">{{ unansweredEmailsCounter }}</span>
                                <span class="icon-headphones" grid="24"></span>
                            </router-link>
                        </li>
                        <li v-can:view="'vue-chat'" class="nav-item d-none d-md-block mx-0 mx-md-1">
                            <span class="btn btn-transparent center-content nav-link p-relative cursor-pointer" @click="showUsersChat" data-toggle="tooltip" title="Chat">
                                <span v-show="messages_pending > 0" class="chat-pending-indicator">{{ messages_pending }}</span>
                                <span class="icon-conversations-quare-smile" grid="24"></span>
                                <!-- <span class="d-none d-md-inline ml-1">Chat</span> -->
                            </span>
                        </li>
                        <li class="nav-item d-none d-md-block mx-0 mx-md-1">
                            <span class="btn btn-transparent center-content nav-link position-relative cursor-pointer" data-toggle="tooltip" title="Notificaciones" @click="showPanel = true">
                                <span v-show="(notifications_count + user_requests_count) > 0" class="chat-pending-indicator">{{ notifications_count + user_requests_count }}</span>
                                <span class="icon-alarm" grid="24"></span>
                                <!-- <span class="d-none d-md-inline ml-1">Notification</span> -->
                            </span>
                        </li>
                        <li class="nav-item mx-0 mx-md-1" v-if="user">
                            <div class="dropdown">
                                <a   class="btn btn-transparent center-content nav-link" id="userOptionsNavbar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="user-photo-navbar">
                                        <img :src="user.photo || '/img/none.png'" class="rounded-circle">
                                    </div>
                                    <span class="ml-1 d-none d-md-inline-block">{{ displayName }}</span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right position-absolute" aria-labelledby="userOptionsNavbar">
                                    <router-link v-if="user" :to="{ name: 'account-index'}" class="dropdown-item"><span class="icon-user mr-2"></span> Mi Cuenta</router-link>
                                    <a class="dropdown-item"><span class="icon-bubble mr-2"></span> Bandeja de entrada</a>
                                    <router-link :to="{ name: 'users-username-edit', params: { username: user.username }}" class="dropdown-item"><span class="icon-settings mr-2"></span> Configuración</router-link>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" @click.prevent="logout"><span class="icon-logout-3 mr-2"></span> Cerrar sesión</a>
                                </div>
                            </div>
                        </li>

                         <li class="nav-item d-block d-md-none mx-0">
                            <div class="dropdown">
                                <a class="btn btn-transparent position-relative center-content nav-link" id="translate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span v-show="messages_pending > 0" class="chat-pending-indicator">{{ messages_pending }}</span>
                                    <span class="icon-menu-9-bold"></span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="translate">
                                    <span class="dropdown-item  p-relative" @click="showUsersChat"  title="Chat">
                                        <span v-show="messages_pending > 0" class="chat-pending-indicator">{{ messages_pending }}</span>
                                        <span class="icon-conversations-quare-smile"></span>
                                        <span class="ml-1">Chat</span>
                                    </span>
                                    <span class="dropdown-item " data-toggle="tooltip" title="Notificaciones" @click="showPanel = true">
                                        <span class="icon-alarm"></span>
                                        <span class="ml-1">Notificaciones</span>
                                    </span>
                                    <!-- <div class="dropdown-divider"></div>
                                    <h6 class="dropdown-header text-uppercase font-weight-bold">Idiomas</h6>
                                    <a class="dropdown-item">English</a>
                                    <a class="dropdown-item">Español</a> -->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <main class="d-flex flex-direction-col flex-1">
                <v-crumbs></v-crumbs>
                <div class="default-container d-flex flex-direction-col flex-1">
                    <div class="flex-1 position-relative" style="height: 100%">
                        <transition name="fade-slide" mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>
                </div>
                <div>
                    <small class="text-muted m-2">© Pavolar | Todos los derechos reservados <span v-once>{{ year }}</span></small>
                </div>
            </main>
        </div>
        <vue-chat v-if="user"  v-can:view="'vue-chat'" :user="user" :socket="socket" :show_users_chat="show_users_chat"/>
        <div id="overlay" class="overlay" v-bind:class="{ 'active': showMenu || showPanel || showSearch }" @click.prevent.stop="hideOverlay"></div>

        <!-- Modal de solicitudes -->
        <div  @click.stop="requests_tools.compare_form.open = false" class="overlay-form"
            :class="{'active': requests_tools.compare_form.open}">
            <div @click.stop="" class="overlay-form-wrapper form-new-group"
                :class="{'active': requests_tools.compare_form.open}">
                <div class="overlay-form-header py-2 px-3">
                    Detalles
                    <span class="btn overlay-form-close icon-close" @click="requests_tools.compare_form.open = false"></span>
                </div>
                <div v-if="requests_tools.compare_form.request != null" class="overlay-form-body py-2 px-3">
                    <div class="row m-0">
                        <div v-if="requests_tools.compare_form.request.data.attributes_old !=null && requests_tools.compare_form.request.data.attributes !=null" class="col-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Campo</th>
                                            <th>Detalle Actual</th>
                                            <th>Actualizacion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, index) in Object.keys(requests_tools.compare_form.request.data.attributes)"
                                            >
                                            <tr v-if="compareUserRequestDataValid(item)" :key="index"
                                                :class="{
                                                    'update-row': requests_tools.compare_form.request.data.attributes_old[item] != requests_tools.compare_form.request.data.attributes[item]
                                                }">
                                                <td v-text="item"></td>
                                                <td v-text="requests_tools.compare_form.request.data.attributes_old[item]"></td>
                                                <td v-text="requests_tools.compare_form.request.data.attributes[item]"></td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                        </div>
                        <div v-else class="col-12">
                            <div  class="overlay-form-body py-2 px-3">
                                <span v-if="requests_tools.compare_form.request.type == 'DELETE'">Se Eliminara esta Informacion</span>
                                <span v-else>
                                    Detalle Nombre
                                </span>
                                <div v-for="(item, index) in Object.keys(requests_tools.compare_form.request.data.attributes_old)" :key="index">
                                    {{ item }}: {{requests_tools.compare_form.request.data.attributes_old[item]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-form-footer py-2 px-3">
                    <transition v-if="requests_tools.compare_form.request != null" name="fade-in">
                        <div key="1" v-if="requests_tools.compare_form.request.is_approve == null" class="pl-2 d-flex justify-content-end">
                            <button @click="$refs.requests.approve(requests_tools.compare_form.request.id)" class="btn btn-soft btn-with-icon btn-rounded btn-sm btn-soft-success">
                                Aprobar
                                <span class="icon-circle-check"></span>
                            </button>
                            <button @click="$refs.requests.reject(requests_tools.compare_form.request.id)" class="btn btn-soft btn-with-icon btn-rounded btn-sm btn-soft-danger ml-1">
                                Cancelar
                                <span class="icon-cancel"></span>
                            </button>
                        </div>
                        <div key="2" v-else class="pl-2 text-right">
                            <span class="tag-rounded">
                                <div class="d-flex align-items-center ">
                                    <img :src="requests_tools.compare_form.request.manage_by.photo ||  '/img/none.png'" alt="" >
                                    <span class="ml-1">
                                        {{ requests_tools.compare_form.request.manage_by.first_name }}
                                        {{ requests_tools.compare_form.request.manage_by.last_name }}
                                    </span>
                                    <span v-if="requests_tools.compare_form.request.is_approve" class="icon text-success icon-circle-check"></span>
                                    <span v-else class="icon icon-cancel text-danger"></span>
                                </div>
                            </span>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <crop-profile-photo v-if="user" ref="cropPhoto" :url="user.photo || '/img/none.png'"></crop-profile-photo>
    </div>

</template>

<script>
    "use strict";

    import { mapGetters, mapActions } from 'vuex'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import VueChat from '~/components/chat/VueChat';
    import MenuLoading from '~/components/menuLoader'
    import PopupSearch from '~/components/popupSearch'
    import VueNotifications from '~/components/alerts/notifications'
    import VueRequests from '~/components/alerts/requests'
    import CropProfilePhoto from '~/components/cropProfilePhoto'

    export default {
        components: {
            VuePerfectScrollbar,
            VueChat,
            MenuLoading,
            VueNotifications,
            VueRequests,
            PopupSearch,
            CropProfilePhoto
        },
        data(){
            return {
                messages_pending: 0,
                show_users_chat: false,
                showMenu:   false,
                toggleMenu: false,
                showPanel:  false,
                showSearch: false,
                menu: [],
                year: (new Date).getFullYear(),
                socket: null,
                notifications: [],
                user_requests: [],
                requests_tools: {
                    compare_form: {
                        open: false,
                        request: null,
                    }
                }
            };
        },
        computed: {
            notifications_count() {
                let vm = this
                let news = vm.notifications.filter(n => {
                    return n.is_read === 0 || n.is_read == false || n.is_read == undefined
                })

                return news.length
            },
            user_requests_count() {
                let vm = this
                let news = vm.user_requests.filter(r => {
                    return r.request.is_approve == null
                })

                return news.length
            },
            ...mapGetters({
                user : 'auth/user',
                displayName : 'auth/displayName',
                unansweredEmailsCounter: 'support/unansweredEmailsCounter'
            })
        },

        methods: {
            async beforeUnload () {
                let vm = this
                await axios.post('/admin/account/user/last-connection').then(res => {
                    vm.socket.emit('user_last_connection', res)
                    return true
                })
            },
            async getMenu(menu = null){

                const START_END_SLASH = /^\/+|\/+$/g
                const START_COLONS_END_INTERROGATION = /^\:.*?\?$/g
                let routes = this.$router.options.routes
            
                if(_.isNull(menu) || _.isUndefined(menu)){
                    let sessionMenu = JSON.parse(sessionStorage.getItem('pavolar_menu') || 'null')
                    if(!_.isNull(sessionMenu)){
                        menu = sessionMenu
                    }else{
                        let response    = await axios.get('/admin/page/menu')
                        menu = response.data
                    }
                }
                sessionStorage.setItem('pavolar_menu', JSON.stringify(menu))

                menu.forEach(item => {
                    let sameChildAccess = false
                    if(item.childrens) {

                        item.childrens.forEach(child => {
                            let component = routes.find(route => {
                                let menuPath  = child.url.replace(START_END_SLASH, '')
                                let routePath = route.path.split('/').map(s => START_COLONS_END_INTERROGATION.test(s) ? '' : s).join('/').replace(START_END_SLASH, '')
                                return menuPath == routePath
                            })

                            if(component) {
                                child.component = component.name
                                child.access    = this.$can('view', {name: component.name})
                                if(child.access)
                                    sameChildAccess = true
                                else
                                    this.logWarn(component.name, 'PERMISION')

                                return
                            }

                            this.logWarn(child.url, 'UNDEFINED')
                            child.component = null
                        })
                    }

                    let component = routes.find(route => {
                        return route.path == item.url
                    })

                    if(component) {
                        item.component = component.name
                        item.access = this.$can('view', {name: component.name})
                        if(!item.access)
                            this.logWarn(item.url, 'PERMISION')
                        return
                    }else if(item.url != "#"){
                        this.logWarn(item.url, 'UNDEFINED')
                    }

                    item.component = null
                    item.access    = sameChildAccess

                });

                this.menu = menu;
            },
            logWarn(name, type = 'PERMISION'){
                let title   = type === 'PERMISION' ? "🔒 Permiso Denegado:" : "📃 Componente Indefinido:"
                let message = type === 'PERMISION' ? `No tienes permisos para visualizar el componente '${name}'` : `No se existe ningun componente que concuerde con la ruta '${name}'`;
                console.warn(title, message)
            },
            logout() {
                this.socket.disconnect(true)
                this.$store.dispatch('auth/logout')
                .then(() => {
                    // this.$router.push({ name: 'auth-login' })

                    // Redicrecciona de manera clasica para que se recargue la ventana
                    // y genere un nuevo el CRSF Token
                    window.location.href = '/auth/login'
                });
            },
            onCloseSearch (value) {
                this.showSearch = value
            },
            hideOverlay(e) {
                this.showMenu = false;
                this.showPanel = false;
                this.showSearch = false;
            },
            // Modal UserRequest
            openRequestCompare (r) {
                this.requests_tools.compare_form.request = r
                this.requests_tools.compare_form.open = true
            },
            compareUserRequestDataValid (item) {
                let fields_protected = ['id', 'created_at', 'updated_at', 'deleted_at']

                return fields_protected.indexOf(item) < 0
            },
            showUsersChat(){
                this.show_users_chat = !this.show_users_chat
            },
            getNotifications () {
                let vm = this
                let ednpoint = `/admin/notifications/alerts`
                return axios.get(ednpoint).then( ({data}) => {
                    vm.notifications = data.alerts

                })
            },
            getRequests () {
                let vm = this
                let ednpoint = '/admin/requests/alerts'
                return axios.get(ednpoint).then( ({data}) => {
                    vm.user_requests = data
                })
            },
            getTypeIcon (notification) {
                if(notification.type.name == 'user') {
                    return notification.user.photo || '/img/none.png'
                }
            },
            getNameModel (model, plural = false) {
                switch(model) {
                    case 'App\\Airport':
                        return  plural ? 'Aeropuertos' : 'Aeropuerto'
                        break
                    case 'App\\Airline':
                        return  plural ? 'Aerolineas' : 'Aerolinea'
                        break
                    default:
                        return plural ? model.replace('App\\', '')+"s" : model.replace('App\\', '')
                        break
                }
            },
            getAction(action){
                switch (action) {
                    case 'UPDATE':
                        return 'actualización';
                    case 'DELETE':
                        return 'elimiación';
                    case 'CREATE':
                        return 'creación';
                    default:
                        return action;
                }
            },
            // notificaiones metodos
            getBtnSnotifyActionText (action) {
                switch (action) {

                    case 'openPage':
                        return 'Abrir'
                    break

                    case 'openConversation':
                        return 'Ver Conversación'
                    break

                    default:
                        return 'Marcar como leido'
                    break

                }
            },
            getNotificationAction (action) {
                // 'DELETED','UPDATED','CREATED','ADDED','REMOVED','APPROVED','REJECTED'
                switch (action) {

                    case 'DELETED':
                        return 'borrado'
                    break

                    case 'UPDATED':
                        return 'actualizado'
                    break

                    case 'CREATED':
                        return 'creado'
                    break

                    case 'ADDED':
                        return 'agregado'
                    break

                    case 'REMOVED':
                        return 'eliminado'
                    break

                    case 'APPROVED':
                        return 'aprobado'
                    break

                    case 'REJECTED':
                        return 'rechazado'
                    break

                }
            },
            getNotificationMessage (notification) {
                let vm          = this
                const TYPE      = notification.type.name
                const USERSEND  = `${notification.user.first_name} ${notification.user.first_name}`
                const ACTION    = notification.action

                if( TYPE === 'request' ) {
                    // El Usuario <b>${data.alert.request.user.first_name} ${data.alert.request.user.last_name}</b> esta solicitando realizar una  <span class="text-primary font-weight-bold">${ vm.getAction(data.alert.request.type) }</span> en el elemento  <b>${ data.alert.request.title }</b>
                    return `<b>${USERSEND}</b> ha <span class="text-primary font-weight-bold">${vm.getNotificationAction(ACTION)}</span> tu solicitud en <b>${vm.getNameModel(notification.model)}</b> para <b>${notification.title}</b>`
                }

                return 'Ola khe ase test o khe ase'
            },
            getTypeSnotify (action) {
                // 'DELETED','UPDATED','CREATED','ADDED','REMOVED','APPROVED','REJECTED'
                switch (action) {

                    case 'DELETED':
                        return 'error'
                    break

                    case 'UPDATED':
                        return 'info'
                    break

                    case 'CREATED':
                        return 'success'
                    break

                    case 'ADDED':
                        return 'success'
                    break

                    case 'REMOVED':
                        return 'warning'
                    break

                    case 'APPROVED':
                        return 'success'
                    break

                    case 'REJECTED':
                        return 'error'
                    break

                }
            },
            ...mapActions({
                getEmails: 'support/getEmails'
            })

        },
        created() {
            let vm      = this
            let baseURL = (process.env.MIX_SOCKET_URL || 'http://127.0.0.1').replace(/\/$/, '')
            let port    =  process.env.MIX_SOCKET_PORT
            vm.eventHub.$on('logout', () => {
                vm.logout()
            })
            this.socket = io(`${baseURL}${port ? ':' + port : '/'}?user=${this.user.id}`);
            // this.socket = io(`http://localhost:3001?user=${this.user.user_id}`);
            //Configuraciones Datatable
            // $.fn.dataTable.ext.errMode = 'none';
            vm.getNotifications();
            vm.getRequests();

            vm.socket.on(`${vm.user.socket_id}:App\\Events\\SupportEmail`, ({data}) => {
                vm.$store.commit('support/UPDATE_EMAILS', {emails: [data], type: 'unanswered'})
                let notification_template = `<div class="notification-pop-wrapper">
                                                <div class="notification-pop-icon">
                                                    <img src="${data.customer_id ? data.customer.photo ||  '/img/none.png' : '/img/none.png'} ">
                                                </div>
                                                <div class="pl-2">
                                                    <h6 class="notification-pop-title mb-0"><b>[Soporte]</b> ${data.subject}</h6>
                                                    <p class="text-muted"> ${data.customer_id ? data.customer.first_name : 'Visitante'} - ${data.email}</p>
                                                    <p class="notification-pop-description">
                                                        ${data.excerpt}
                                                    </p>
                                                </div>
                                            </div>`

                vm.$snotify.html(notification_template, {
                    timeout: 30000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    type: 'info',
                    // icon: `${data.alert.request.user.photo ||  '/img/none.png'} `,
                    buttons: [
                        {
                            text: 'Leer',
                            action: (toast) =>{
                                // vm.$refs.requests.approve(data.alert.request.id)
                                console.log('redirect')
                                vm.$router.push(`/support/email/${data.id}`)
                                vm.$snotify.remove(toast.id)
                            },
                            bold: false,
                            className: 'btn btn-rounded btn-sm btn-link text-uppercase',

                        },
                        {
                            text: '',
                            action: (toast) => {
                                vm.$snotify.remove(toast.id)
                            },
                            className: 'btn-snotify-close icon-close',
                        },
                    ]
                });

            })

            vm.socket.on(`${vm.user.socket_id}:App\\Events\\SupportEmailStatus`, ({data}) => {
                vm.$store.commit('support/UPDATE_STATUS_EMAIL', data)

                if(data.user_id == vm.user.id) {
                    return
                }

                let notification_template = `<div class="notification-pop-wrapper">
                                                <div class="notification-pop-icon">
                                                    <img src="${data.user.photo ||  '/img/none.png'} ">
                                                </div>
                                                <div class="pl-2">
                                                    <h6 class="notification-pop-title mb-0"> ${data.user.first_name} </h6>
                                                    <p class="text-muted m-0">Esta dando soporte</p>
                                                    <p class="text-primary m-0">${data.subject}</p>
                                                    <p class="notification-pop-description">
                                                        ${data.excerpt}
                                                    </p>
                                                </div>
                                            </div>`

                 vm.$snotify.html(notification_template, {
                    timeout: 30000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    type: 'info',
                    // icon: `${data.alert.request.user.photo ||  '/img/none.png'} `,
                    buttons: [
                        {
                            text: 'Leer',
                            action: (toast) =>{
                                // vm.$refs.requests.approve(data.alert.request.id)
                                console.log('redirect')
                                vm.$router.push(`/support/email/${data.id}`)
                                vm.$snotify.remove(toast.id)
                            },
                            bold: false,
                            className: 'btn btn-rounded btn-sm btn-link text-uppercase',

                        },
                        {
                            text: '',
                            action: (toast) => {
                                vm.$snotify.remove(toast.id)
                            },
                            className: 'btn-snotify-close icon-close',
                        },
                    ]
                });
            })

            vm.socket.on(`${vm.user.socket_id}:App\\Events\\NewRequest`, ({data}) => {
                let notification_template = `<div class="notification-pop-wrapper">
                                                <div class="notification-pop-icon">
                                                    <img src="${data.alert.request.user.photo ||  '/img/none.png'} ">
                                                </div>
                                                <div class="pl-2">
                                                    <h6 class="notification-pop-title">${data.alert.request.model.replace('App\\', '')+"s"}</h6>
                                                    <p class="notification-pop-description">
                                                     El Usuario <b>${data.alert.request.user.first_name} ${data.alert.request.user.last_name}</b> esta solicitando realizar una  <span class="text-primary font-weight-bold">${ vm.getAction(data.alert.request.type) }</span> en el elemento  <b>${ data.alert.request.title }</b>
                                                    </p>
                                                </div>
                                            </div>`

                vm.user_requests.unshift(data.alert)

                vm.$snotify.html(notification_template, {
                    timeout: 30000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    type: 'info',
                    // icon: `${data.alert.request.user.photo ||  '/img/none.png'} `,
                    buttons: [
                        {
                            text: 'Aprobar',
                            action: (toast) =>{
                                vm.$refs.requests.approve(data.alert.request.id)
                                vm.$snotify.remove(toast.id)
                            },
                            bold: false,
                            className: 'btn btn-soft btn-rounded btn-sm btn-soft-success',

                        },
                        {
                            text: 'Cancelar ',
                            action: (toast) => {
                                vm.$refs.requests.reject(data.alert.request.id)
                                vm.$snotify.remove(toast.id)
                            },
                            className: 'btn btn-soft btn-rounded btn-sm btn-soft-danger mx-2',
                        },
                        {
                            text: 'Detalles',
                            action: (toast) => {
                                vm.$refs.requests.openModal(data.alert.request)
                                vm.$snotify.remove(toast.id)

                            },
                            className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                        },
                    ]
                });
            })



            vm.socket.on(`${vm.user.socket_id}:App\\Events\\ManageRequest`, ({data}) => {

                let user_requests = vm.user_requests.find(r => {
                    return r.request.id == data.id
                })
                if(user_requests) {
                    user_requests.request.is_approve = data.is_approve
                    user_requests.request.manage_by  = data.manage_by
                }
                // vm.user_requests.unshift(data.alert)
            })

            // Socket Notificaciones
            vm.socket.on(`${vm.user.socket_id}:App\\Events\\NewNotification`, ({ data: { alert } }) => {
                // alerta aun no es leida
                alert.is_read = false
                // se agrega al inicio de las notificaciones
                vm.notifications.unshift(alert)
                // Informacion de la notificacion
                let notification = alert.notification
                // Template para snotify
                let snotifyTemplate =    `<div class="notification-pop-wrapper">
                                                    <div class="notification-pop-icon">
                                                        <img src="${ notification.user.photo || '/img/none.png'} ">
                                                    </div>
                                                    <div class="pl-2">
                                                        <h6 class="notification-pop-title">${vm.getNameModel(notification.model)}</h6>
                                                        <p class="notification-pop-description">${vm.getNotificationMessage(notification)}</p>
                                                    </div>
                                                </div>`
                // Configuarar botones de snotify
                let buttons = [
                        {
                            text: 'Cerrar',
                            action: (toast) => {
                                vm.$snotify.remove(toast.id)
                            },
                            className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                        },
                    ]
                // Obtenemos la data que nos servira para el callback al dar click
                let data = JSON.parse(notification.data)
                // Funcion que se ejectara al dar click a la notificacion
                let notificationCallback = () => {
                                            data.vm = vm
                                            vm.eventHub[data.callback](data)
                                        }
                // CONFIGURAR BOTON DE ACCION SNOTIFY
                // Obtener el texto para el boton accion
                let btnActionText = vm.getBtnSnotifyActionText(data.callback)
                // Agragar boton a configuaracion
                buttons.unshift({
                    text: btnActionText,
                    action: (toast) => {
                        notificationCallback()
                        vm.$refs.notifications.watchAlert(alert)
                        vm.$snotify.remove(toast.id)
                    },
                    className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                })
                // Trigger snotify
                vm.$snotify.html(snotifyTemplate, {
                    timeout: 5000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    type: vm.getTypeSnotify(notification.action),
                    // icon: `${data.alert.request.user.photo ||  '/img/none.png'} `,
                    buttons: buttons
                });
                // if(params.action == 'openConversationById') {
                //     buttons.unshift( {
                //             text: 'Ver Conversación',
                //             action: (toast) => {
                //                 notification_func()
                //                 vm.$refs.notifications.watchAlert(data.alert)
                //                 vm.$snotify.remove(toast.id)
                //             },
                //             className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                //     })
                // } else if(params.action == 'redirectToUrl') {
                //     buttons.unshift( {
                //         text: 'Ver',
                //         action: (toast) => {
                //             notification_func()
                //             vm.$refs.notifications.watchAlert(data.alert)
                //             vm.$snotify.remove(toast.id)
                //         },
                //         className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                //     })
                // } else {
                //     buttons.unshift( {
                //         text: 'Marcar como visto',
                //         action: (toast) => {
                //             vm.$refs.notifications.watchAlert(data.alert)
                //             vm.$snotify.remove(toast.id)
                //         },
                //         className: 'btn btn-soft btn-rounded btn-sm btn-soft-link',
                //     })
                // }


            })

            //Obtiene el menu
            vm.$nextTick(function () {
                vm.getMenu();
            });

            vm.eventHub.$on('menu:update', data => {
                vm.getMenu(data)
            })

            vm.eventHub.$on('updateMessagesPending', data => {
                vm.messages_pending = data
            })
            vm.eventHub.$on('hideUserChat', data => {
                vm.show_users_chat = data
            })
            vm.eventHub.$on('watchNotificationAlert', data => {
                let notification = vm.notifications.find(n => {
                    return n.id == data.id
                })

                if(notification) {
                    notification.is_read = true
                }
            })

            vm.eventHub.$on('user_alert', data => {
                let template = `<div class="notification-pop-wrapper">
                                    <div class="pl-2">
                                        <h6 class="notification-pop-title">${data.title}</h6>
                                        <p class="notification-pop-description">${data.text}</p>
                                    </div>
                                </div>`;
                vm.$snotify.html(template, {
                    type: data.status.toLowerCase(),
                });
            })

            window.addEventListener('beforeunload', vm.beforeUnload)
        },
        mounted () {
            let vm = this
            vm.$nextTick(() => {
                $('.right-nav-options [data-toggle="tooltip"]').tooltip({container: 'ul.right-nav-options'});

                $('body').off('click.dashboard')
                $('body').on('click.dashboard', '[data-toggle="tooltip"]', (e) => {
                    $(e.target).tooltip('hide')
                })
            })

            vm.getEmails()
        },
        watch:{
            $route (to, from){
                this.showMenu = false;
            }
        },
        destroyed() {
            this.eventHub.$off('menu:update')
            this.eventHub.$off('user_alert')
        }
    }

</script>

<style scoped>
    .close-sidebar{
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 5;
    }
</style>
