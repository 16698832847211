<template>
    <div>
        <div class="modal fade" id="modalHotel">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formHotel" autocomplete="off" @submit.prevent="onSaveHotel()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
    							<span v-if="Hotel && Hotel.btn_i18n == true">Traducción de hotel</span>
                                <span v-else-if="formHotel.id">Editar Hotel</span>
                                <span v-else>Nuevo Hotel</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-6" :class="{'col-12':Hotel && Hotel.btn_i18n == true}">
                                    <label for="name" class="required">Nombre hotel</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formHotel.name" name="name">
                                </div>
                                <div class="form-group col-12 col-md-6" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="hotel_category_id"  class="required" style="width: 100%">Categoria de Hotel
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addHotelCategory">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="hotel_category" 
                                    name="hotel_category_id" ref="vSelectHotelCategory" placeholder="Seleccione Categoria" 
                                    :options="hotel_categories" selectOnTab label="name">
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="country_id" class="required">Pais</label>
                                    <v-select v-model="country" name="country_id" 
                                    @search="searchCountry" ref="vSelectCountry" 
                                    placeholder="Seleccione Pais" selectOnTab
                                    :options="countries" required="required">
                                        <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="state_id" class="required" style="width: 100%">Estado
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addState">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select autocomplete="off"  v-model="state" name="state_id" ref="vSelectState" placeholder="Seleccione Estado" :options="states"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="city_id" class="required" style="width: 100%">Ciudad
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addCity">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <v-select v-model="city" name="city_id" ref="vSelectCity" placeholder="Seleccione Ciudad" :options="cities"></v-select>
                                </div>
                                <div class="form-group col-12 col-md-6" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="name" class="required">Telefono</label>
                                    <VuePhoneNumberInput v-model="phone" ref="phone" v-on:update="loadPhone" :default-country-code="countryCode"/>
                                </div>
                                <div class="form-group col-9" :class="{'col-12':Hotel && Hotel.btn_i18n == true}">
                                    <label :for="'address-'+_uid" class="required">Dirección </label>
                                    <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Ingrese Dirección" v-model="formHotel.address">
                                    <!-- <input type="text" class="form-control map-input" placeholder="Ingrese Dirección" v-model="formHotel.address" name="address"> -->
                                    <input type="hidden" name="latitude" :id="'address-' + _uid + '-latitude'" v-model="formHotel.latitude" />
                                    <input type="hidden" name="longitude" :id="'address-' + _uid + '-longitude'" v-model="formHotel.longitude" />
                                    </div>
                                </div>
                                <div class="form-group col-12 col-md-3" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="stars" class="required">Estellas</label>
                                    <select class="form-control"  v-model="formHotel.stars" name="stars" id="stars">
                                        <option value="1">1 Estrella</option>
                                        <option value="2">2 Estrellas</option>
                                        <option value="3">3 Estrellas</option>
                                        <option value="4">4 Estrellas</option>
                                        <option value="5">5 Estrellas</option>
                                    </select>
                                </div>
                                <!-- <div id="address-map-container" class="form-group col-12" style="width:100%;height:200px; " v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <div style="width: 100%; height: 100%" :id="'address-' + _uid + '-map'"></div>
                                </div> -->
                                <div class="form-group col-12" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="airports">Aeropuertos</label>
                                    <v-select placeholder="Buscar Aeropuertos" v-model="formHotel.airports" :options="airports" 
                                    label="name" :filterable="false" @search="onSearchAirport" multiple selectOnTab>
                                        <template slot="no-options">Buscar Aeropuertos...</template>
                                        <template slot="option" slot-scope="option">
                                            ({{ option.iata }}) {{ option.name }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            {{ option.iata }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12" v-show="!(Hotel && Hotel.btn_i18n == true)">
                                    <label for="services" style="width: 100%">Servicios Populares 
                                        <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addHotelService">
                                            <span class="icon-add-2" grid="12"></span>
                                        </span>
                                    </label>
                                    <tag-input id="services" v-model="formHotel.services" ref="taginput" placeholder="Servicios..." :show-focus="true" :data="services"/>
                                </div>
                                <div class="form-group col-12">
                                    <label for="description">Descripción del Hotel</label>
                                    <textarea type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formHotel.description" name="description" ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'packages-hotels-index'" > 
							    <span v-if="Hotel && Hotel.btn_i18n == true">Guardar Traducción</span>
                                <span v-else-if="formHotel.id && $can('update','packages-hotels-index')">
                                    Editar
                                </span>
                                <span v-else-if="formHotel.id && !$can('update','packages-hotels-index')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        

    </div>
</template>
<script>
    import tagInput from '~/components/tagInput';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        props: {
                Hotel: {
                    type : [Object, null],
                    default: null,
                },
                HotelCategoryNew: {
                    type: [Object, null],
                    default: null,
                },
                StateNew: {
                    type: [Object, null],
                    default: null,
                },
                CityNew: {
                    type: [Object, null],
                    default: null,
                },
                HotelServiceNew: {
                    type: [Object, null],
                    default: null,
                }
            },
        components: {
            tagInput,
            VuePhoneNumberInput,
        },
        data() {
            return {
                searchDelay                 : null,
                phone                       : null,
                countryCode                 : null,
                formHotel: new WebForm('#formHotel', {
                    name                    : '',
                    hotel_category_id       : null,
                    description             : null,
                    address                 : null,
                    phone_number            : null,
                    country_code            : null,
                    country_calling_code    : null,
                    stars                   : 1,
                    city_id                 : null,
                    state_id                : null,
                    country_id              : null,
                    services                : [],
                    airports                : [],
                    id                      : null,
                    latitude                : 0,
                    longitude               : 0,
                    btn_i18n                : false,
                    id_i18n                 : null,
                }),
                services                    : [],
                hotel_categories            : [],
                countries                   : [],
                states                      : [],
                cities                      : [],
                airports                    : [],
                country                     : null,
                state                       : null,
                city                        : null,
                hotel_category              : null,
                autocomplete                : null
            }
        },
        watch: {
            country: function(val, oldVal) {
                this.$refs.vSelectState.clearSelection();
                this.states = [];
                if (!_.isEmpty(this.country)) {
                    let code = this.country.code;
                    this.countryCode = code;
                    this.getStates(this.country.id);
                }
            },
            state: function(val, oldVal) {
                this.$refs.vSelectCity.clearSelection();
                this.cities = [];
                if (!_.isEmpty(this.state)) {
                    this.$refs.vSelectCity.clearSelection();
                    this.getCities(this.state.id);
                }
            },
            HotelCategoryNew: function(val, oldVal) {
                if (!_.isEmpty(this.HotelCategoryNew)) {
                    this.hotel_categories.push(this.HotelCategoryNew);
                    this.hotel_category = this.HotelCategoryNew;
                }
            },
            StateNew: function(val, oldVal) {
                if (!_.isEmpty(this.StateNew) && this.country && this.country.id) {
                    if (this.StateNew && this.StateNew.id && this.StateNew.country_id == this.country.id) {
                        let state_ = {
                            id: this.StateNew.id,
                            label: `${this.StateNew.name}`,
                            name: `${this.StateNew.name}`,
                        };
                        this.states.push(state_);
                        this.state = state_;
                    }
                }
            },
            CityNew: function(val, oldVal) {
                if (!_.isEmpty(this.CityNew) && this.state && this.state.id) {
                    if (this.CityNew && this.CityNew.id && this.CityNew.state_id == this.state.id) {
                        let city_ = {
                            id: this.CityNew.id,
                            label: `${this.CityNew.name}`,
                            name: `${this.CityNew.name}`,
                        };
                        this.cities.push(city_);
                        this.city = city_;
                    }
                }
            },
            HotelServiceNew: function(val, oldVal) {
                if (!_.isEmpty(this.HotelServiceNew)) {
                    if (this.HotelServiceNew && this.HotelServiceNew.id) {
                        let service_ = {
                            id: this.HotelServiceNew.id,
                            icon: `${this.HotelServiceNew.icon}`,
                            label: `${this.HotelServiceNew.title}`,
                            name: `${this.HotelServiceNew.title}`,
                        };
                        this.services.push(service_);
                        this.formHotel.services.push(service_);
                    }
                }
            },
            Hotel: function(val, oldVal) {
                let vm = this;
                if (_.isEmpty(vm.Hotel) || vm.Hotel.id == '') {

                }else {
                    vm.formHotel.fill(vm.Hotel);
                    vm.phone = vm.formHotel.phone_number;
                    vm.countryCode = vm.formHotel.country_code;
                    vm.hotel_category = vm.Hotel.hotel_category;
                    vm.loadServices(vm.Hotel.hotel_service_detail);
                    vm.getCountry(vm.Hotel.city.state.country_id);
                    vm.loadAirports(vm.Hotel.hotel_airport_detail);
                    if (vm.Hotel.btn_i18n && vm.Hotel.i18n.length > 0) {
                        vm.formHotel.name   	    = (vm.Hotel.i18n[0].name) ? vm.Hotel.i18n[0].name : vm.Hotel.name;
                        vm.formHotel.address   	    = (vm.Hotel.i18n[0].address) ? vm.Hotel.i18n[0].address : vm.Hotel.address;
                        vm.formHotel.description   	= (vm.Hotel.i18n[0].description) ? vm.Hotel.i18n[0].description : vm.Hotel.description;
                        vm.formHotel.id_i18n   	    = vm.Hotel.i18n[0].id;
                    }

                }
            },
        },
        methods: {
            loadPhone(data) {
                this.formHotel.phone_number         = data.phoneNumber;
                this.formHotel.country_code         = data.countryCode;
                this.formHotel.country_calling_code = data.countryCallingCode;
            },
            // funcion para buscar paises mientras se escribe
            searchCountry(search, loading) {
                if (search.length>2 ) {
                    loading(true);
                    const vm = this;
                    clearInterval(vm.searchDelay);
                    vm.searchDelay = setInterval(function(){
                        clearInterval(vm.searchDelay);
                        axios.get(`/admin/location/countries/${search}`).then((response) => {
                            if (response.data) {
                                let countries = response.data.map(y => {
                                    let country = y;
                                    let d = {
                                        id      :       country.id,
                                        code    :       country.short_iso_code,
                                        name    :       `${country.name} (${country.native_name})`,
                                        label   :       `${country.name} (${country.native_name})` 
                                    }
                                    return d;
                                });
                                vm.countries = countries;
                            } else {
                                console.log('No se pudieron cargar los paquetes');
                            }
                            loading(false);
                        });
                    }, 500);
                }
            },
            onSearchAirport(search, loading) {
                if(_.isEmpty(search))
                    return
                loading(true);
                this.searchAirport(loading, search, this);
            },
            searchAirport: _.debounce((loading, search, vm) => {
                axios.get(`/admin/airports/${escape(search)}`).then(res => {
                    vm.airports = res.data
                    loading(false);
                });
            }, 800),
            async getServices(){
                let endpoint = '/admin/hotels/services/all';
                let {data} = await axios.get(endpoint);
                
                let services = data.map(l => {
                    let service = l
                    let d = {
                        id: service.id,
                        icon: `${service.icon}`,
                        name: `${service.title}`,
                        label: `${service.title}`
                    }
                    return d
                });
                this.services = services;

                this.services.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });
            },
            async getHotelCategories(){
                let endpoint = '/admin/hotels/categories/all';
                let {data} = await axios.get(endpoint);
                this.hotel_categories = data;
            },
            loadServices(servicesToLoad) {
                let vm = this;
                let arrayService =[];
                servicesToLoad.forEach(service => {
                    vm.services.forEach(ser => {
                        if(ser.id == service.hotel_service_id) {
                            arrayService.push(ser);
                        }
                    });
                });
                vm.formHotel.services = arrayService;
            },
            loadAirports(airportsToLoad) {
                let newAirports =[];
                airportsToLoad.forEach(air => {
                    let val = air.airport[0];
                    newAirports.push(val);
                });
                this.formHotel.airports = newAirports;
            },
            getCountry(id) {
                let vm = this;
                axios.get(`/admin/location/countries/${id}`).then((response) => {
                    if (response.data) {
                        let data = response.data;
                        let countries = [{
                            id      :       data.id,
                            code    :       data.short_iso_code,
                            name    :       `${data.name} (${data.native_name})`,
                            label   :       `${data.name} (${data.native_name})` 
                        }];
                        vm.countries = countries;
                        vm.country = vm.countries[0];
                        vm.getStates(vm.country.id);
                    } else {
                        console.log('No se pudieron cargar los paquetes');
                    }
                });
            },
            getStates(country_id){
                let vm = this;
                let endpoint = `/admin/location/states/country/${country_id}`;
                axios.get(endpoint).then(({data}) => {
                    let states = data.map(state => {
                        let d = {
                            id: state.id,
                            label: `${state.name}`,
                            name: `${state.name}`,
                        }
                        return d;
                    });
                    this.states = states;
                    if(vm.Hotel!=null && vm.Hotel.city.state_id!="") {
                        vm.state = vm.states.find(c => {
                            return c.id == vm.Hotel.city.state_id;
                        });
                    }
                });
            },
            getCities(state_id){
                let vm = this;
                let endpoint = `/admin/location/cities/state/${state_id}`;
                axios.get(endpoint).then(({data}) => {
                    let cities = data.map(city => {
                        let d = {
                            id: city.id,
                            label: `${city.name}`,
                            name: `${city.name}`,
                        }
                        return d;
                    });
                    this.cities = cities;

                    if(vm.Hotel!=null && vm.Hotel.city.id!="") {
                        vm.city = vm.cities.find(c => {
                            return c.id == vm.Hotel.city.id;
                        });
                    }
                });
            },
            onSaveHotel() {
                let vm = this
                // vm.formHotel.address = $('#hotel_address').val();
                if(vm.city && vm.city.id) {
                    vm.formHotel.city_id = vm.city.id;
                }
                if(vm.state && vm.state.id) {
                    vm.formHotel.state_id = vm.state.id;
                }
                if(vm.country && vm.country.id) {
                    vm.formHotel.country_id = vm.country.id;
                }
                if(vm.hotel_category && vm.hotel_category.id) {
                    vm.formHotel.hotel_category_id = vm.hotel_category.id;
                }

                let tipo = (vm.formHotel.id != null) ? 1 : 2;
                if(this.formHotel.id != null && this.formHotel.btn_i18n==false) {
                    $.confirm({
                        title: `Editar Hotel`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (type) {
                let vm = this;
                
                vm.formHotel.post('/admin/hotels/save').then(response => {
                    let data = response.data;
                    this.formHotel.id = null;

                    if (data.user_request_status) {
                        $('#modalHotel').modal('hide');
                        return;
                    }

                    let snotify_message = type != 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);

                    let obj = data.success;
                    vm.$emit('reloadHotel', obj);
                    $('#modalHotel').modal('hide');
                    return;
                });
            }
        },
        async mounted() {
           let vm = this

            await vm.getServices();
            await vm.getHotelCategories();

            this.$nextTick(() => {
                $('#modalHotel').off('hidden.bs.modal');
                $('#modalHotel').on('hidden.bs.modal', function () {
                    vm.$refs.taginput.clear();
                    vm.phone        = null;
                    vm.countryCode  = null;
                    vm.formHotel.clear();
                    vm.formHotel.reset();
                    vm.$emit('closeHotelModal');
                });

                $('form').on('keyup keypress', function(e) {
                    var keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                });

                // const locationInputs = document.getElementsByClassName("map-input");

                // let autocompletes = [];
                
                // const geocoder = new google.maps.Geocoder;
                // for (let i = 0; i < locationInputs.length; i++) {

                //     const input = locationInputs[i];
                //     const fieldKey = 'address-' + this._uid;
                //     const isEdit = document.getElementById(fieldKey + "-latitude").value != '' && document.getElementById(fieldKey + "-longitude").value != '';
                    
                //     const latitude = parseFloat(document.getElementById(fieldKey + "-latitude").value) || 13.4591376;
                //     const longitude = parseFloat(document.getElementById(fieldKey + "-longitude").value) || -88.1596901;
                //     const map = new google.maps.Map(document.getElementById(fieldKey + '-map'), {
                //         center: {lat: latitude, lng: longitude},
                //         zoom: 17
                //     });

                //     const marker = new google.maps.Marker({
                //         map: map,
                //         position: {lat: latitude, lng: longitude},
                //     });

                //     marker.setVisible(isEdit);

                //     const autocomplete = new google.maps.places.Autocomplete(input);
                //     autocomplete.key = fieldKey;
                //     autocompletes.push({input: input, map: map, marker: marker, autocomplete: autocomplete});
                // }

                // for (let i = 0; i < autocompletes.length; i++) {
                //     const input = autocompletes[i].input;
                //     const autocomplete = autocompletes[i].autocomplete;
                //     const map = autocompletes[i].map;
                //     const marker = autocompletes[i].marker;

                //     google.maps.event.addListener(autocomplete, 'place_changed', function () {
                //         marker.setVisible(false);
                //         const place = autocomplete.getPlace();

                //         geocoder.geocode({'placeId': place.place_id}, function (results, status) {
                //             if (status === google.maps.GeocoderStatus.OK) {
                //                 const lat = results[0].geometry.location.lat();
                //                 const lng = results[0].geometry.location.lng();
                //                 setLocationCoordinates(autocomplete.key, lat, lng);
                //             }
                //         });

                //         if (!place.geometry) {
                //             window.alert("No details available for input: '" + place.name + "'");
                //             input.value = "";
                //             return;
                //         }

                //         if (place.geometry.viewport) {
                //             map.fitBounds(place.geometry.viewport);
                //         } else {
                //             map.setCenter(place.geometry.location);
                //             map.setZoom(17);
                //         }
                //         marker.setPosition(place.geometry.location);
                //         marker.setVisible(true);
                //     });
                // }

                // function setLocationCoordinates(key, lat, lng) {
                //     const latitudeField = document.getElementById(key + "-" + "latitude");
                //     const longitudeField = document.getElementById(key + "-" + "longitude");
                //     latitudeField.value = lat;
                //     longitudeField.value = lng;
                //     vm.formAttraction.latitude = lat;
                //     vm.formAttraction.longitude = lng;
                // }
            });
        }
    }
</script>
<style>
    .pac-container.hdpi{
        z-index: 10000;
    }
    .modal#modalHotel{
        overflow-y: auto !important;
    }
</style>