<template>
    <div>
        <div class="section pt-3 pb-1">
            <div class="d-flex justify-content-between mx-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Utilidad de Ventas</h5>
                    <p class="text-muted mb-1">Periodos de reglas para calculo de utilidad de venta de boletos</p>
                </div>
                <div>
                    <button class="btn btn-primary center-content d-inline-block" data-toggle="modal" @click="$refs.utilityTest.show()">
                        <span class="icon-ia"></span> Zona de Prueba
                    </button>
                    <button v-can:create class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#periodModal">
                        <span class="icon-add-2 mr-2"></span> Nuevo Periodo
                    </button>
                </div>
            </div>
            <transition name="fade">
                <data-table 
                    ref="utilityTable" 
                    :ajax="{ 
                        url: '/admin/sales-utility',
                    }"
                    v-on:xhr="dt.xhr"
                    :scrollY="'calc(100vh - 350px)'" 
                    :serverSide="true" 
                    :columns="dt.columns">
                </data-table>
            </transition>
        </div>

        <div class="modal fade" id="periodModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="!periodForm.id">Nuevo Periodo</h5>
                        <h5 class="modal-title" v-else>Editar Periodo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form id="periodForm" autocomplete="off" novalidate @submit.prevent="savePeriod">
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="start_date">Fecha de Inicio</label>
                                    <vue-datepicker v-model="periodForm.start_date" class="form-control" name="start_date" id="start_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="end_date">Fecha de Finalización</label>
                                    <vue-datepicker v-model="periodForm.end_date" class="form-control" name="end_date" id="end_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12">
                                    <small class="font-weight-bold">Valores Por Defecto</small>
                                    <hr class="m-0">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="default_amount">Monto <small class="font-weight-bold">($)</small></label>
                                    <input v-model="periodForm.default_amount" type="number" class="form-control" name="default_amount" id="default_amount">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label class="mb-1" for="default_percent">Porcentaje <small class="font-weight-bold">(%)</small></label>
                                    <select v-model="periodForm.default_percent" class="form-control" name="default_percent" id="default_percent">
                                        <option value="0">Seleccionar...</option>
                                        <option v-for="number in 100" :key="number" :value="number">{{ number }}</option>
                                    </select>
                                </div>
                                <div class="form-group mb-0 col-12">
                                    <label class="mb-1" for="description">Descripción</label>
                                    <textarea v-model="periodForm.description" class="form-control" maxlength="2500" name="description" id="description" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer pb-0">
                                <button type="submit" class="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <sales-utility-test ref="utilityTest" />

    </div>
    

</template>

<script>

    import dataTable from '~/components/dataTable'
    import SalesUtilityTest from '~/components/salesUtilityTest'
 
    export default {
        middleware:'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            SalesUtilityTest
        },
        metaInfo () {
            return { title: "Utilidad de Ventas" }
        },
        data(){
            return {
                periodForm: new WebForm('#periodForm', {
                    id : null,
                    start_date : null,
                    end_date : null,
                    default_amount : 0,
                    default_percent : 0,
                    description : ''
                }),
                dt: {
                    xhr:(e, settings, json, xhr) =>{
                        $('.dataTables_wrapper').find('[data-toggle="tooltip"]').tooltip({container: 'td > .tooltip-table'})
                    },
                    columns : [
                        { title: 'Id', data: 'id' },
                        { title: 'Fecha Inicio', data: 'start_date',
                            render: (data, type, row) => {
                                return moment(data, 'YYYY-MM-DD').format('L')
                            }
                        },
                        { title: 'Fecha Fin', data: 'end_date',
                            render: (data, type, row) => {
                                return moment(data, 'YYYY-MM-DD').format('L')
                            }
                        },
                        { title: 'Monto ($)', data: 'default_amount' },
                        { title: 'Porcentaje (%)', data: 'default_percent' },
                        { title: 'Descripción', data: 'description', className: 'md-column text-wrap' },
                        { title: 'Fecha de Registro', data: 'created_at',
                            render: (data, type, row) => {
                                return moment(data, 'YYYY-MM-DD').format('LL')
                            }
                        },
                        { title: 'Opciones', data: 'id', orderable: false,
                            render: (data, type, row, meta) => {
                                let buttons = []
                                if(this.$can('write'))
                                    buttons.push(`<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold mx-1 btn-manage" title="Administrar" data-toggle="tooltip"><span class="icon-settings pointer-events-none"></span></button>`)
                                if(this.$can('write'))
                                    buttons.push(`<button class="btn btn-soft btn-soft-primary btn-rounded btn-sm font-weight-bold mx-1 btn-edit" title="Editar" data-toggle="tooltip"><span class="icon-pencil-edith pointer-events-none"></span></button>`)
                                if(this.$can('write'))
                                    buttons.push(`<button class="btn btn-soft btn-soft-success btn-rounded btn-sm font-weight-bold mx-1 btn-test" title="Zona de Prueba" data-toggle="tooltip"><span class="icon-ia pointer-events-none"></span></span></button>`)
                                if(this.$can('manage'))
                                    buttons.push(`<button class="btn btn-soft btn-soft-danger btn-rounded btn-sm font-weight-bold mx-1 btn-delete" title="Eliminar" data-toggle="tooltip"><span class="icon-bin pointer-events-none"></span></button>`)

                                return `<div class="d-flex tooltip-table">${buttons.join('')}</div>`;
                            } 
                        }
                    ]
                }
            };
        },
        methods: {
            savePeriod(){
                let vm = this
                vm.periodForm.post('/admin/sales-utility/period/save').then((response) => {
                    vm.$refs.utilityTable.ajaxReload()
                    $('#periodModal').modal('hide')
                })
            },
            deletePeriod(data){
                let vm = this
                
                let periodID = `<span class="p-1 text-primary bg-light rounded font-weight-bold">periodo número ${data.id}</span>`
                $.confirm.delete(
                    'Eliminar Periodo',
                    `¿Está seguro que desea eliminar el ${periodID}?`, 
                    function(){
                        axios.post('/admin/sales-utility/period/delete', {
                            id: data.id
                        }).then((response) => {
                            vm.$refs.utilityTable.ajaxReload()
                        }).catch(() => {
                            vm.$snotify.warning("No se pudo eliminar este periodo.")
                        })
                    }
                )
            }
        },
        mounted() {
            
            let vm = this
            $("#periodModal").off("hidden.bs.modal");
            $("#periodModal").on("hidden.bs.modal", function(){
                vm.periodForm.reset()
            });

            $(vm.$el).off('click.salesutility', '.btn-manage')
            $(vm.$el).on('click.salesutility', '.btn-manage', function(){
                let data = vm.$refs.utilityTable.rowData($(this).closest('tr'))
                $(this).addClass('btn-loading btn-loading-dark')
                vm.$router.push({ name: `booking-engine-sales-utility-id`, params: { id: data.id }})
            });

            $(vm.$el).off('click.salesutility', '.btn-edit')
            $(vm.$el).on('click.salesutility', '.btn-edit', function(){
                let data = vm.$refs.utilityTable.rowData($(this).closest('tr'))
                vm.periodForm.fill(data)
                $('#periodModal').modal('show')
            })

            $(vm.$el).off('click.salesutility', '.btn-test')
            $(vm.$el).on('click.salesutility', '.btn-test', function(){
                let data = vm.$refs.utilityTable.rowData($(this).closest('tr'))
                vm.$refs.utilityTest.show(data)
            })

            $(vm.$el).off('click.salesutility', '.btn-delete')
            $(vm.$el).on('click.salesutility', '.btn-delete', function(){
                let btn  = $(this)
                let data = vm.$refs.utilityTable.rowData(btn.closest('tr'))
                vm.deletePeriod(data)
            })
        },
    }

</script>