<template>
    <div class="iconSelector__wrapper">
        <button class="btn btn-secondary dropdown-toggle" type="button" :id="`icon-selector-${_uid}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span v-show="seletedIcon" :class="seletedIcon"></span>  
          {{ seletedIcon ? 'Cambiar Icono' : 'Selecionar Icono' }}
        </button>
        <div class="dropdown-menu" :aria-labelledby="`icon-selector-${_uid}`">
           <div class="iconSelector__list">
                <div v-for="(icon, index) in icons" :key="`icon-item-${_uid}-${index}`" class="iconSelector__item" @click="setIcon(icon)">
                    <span class="iconSelector__item__icon" :class="icon" ></span>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
    props: {
        value: {
            type: String
        }
    },
    data () {
        return {
            seletedIcon: null,
            icons: [
                "icon-home",
                "icon-home-bold",
                "icon-home-2",
                "icon-home-3",
                "icon-home-3-bold",
                "icon-menu-7",
                "icon-menu-7-bold",
                "icon-menu-6",
                "icon-menu-6-bold",
                "icon-menu",
                "icon-menu-bold",
                "icon-corners",
                "icon-corners-bold",
                "icon-dial-pad",
                "icon-dial-pad-bold",
                "icon-dial-pad-bold-2",
                "icon-menu-5",
                "icon-menu-5-bold",
                "icon-menu-9",
                "icon-menu-9-bold",
                "icon-menu-2",
                "icon-menu-2-bold",
                "icon-menu-8",
                "icon-menu-8-bold",
                "icon-menu-4",
                "icon-menu-4-bold",
                "icon-menu-3",
                "icon-menu-3-bol",
                "icon-layout-grid",
                "icon-layout-grid-3",
                "icon-layout-grid-2",
                "icon-layout",
                "icon-layout-2",
                "icon-layers",
                "icon-power",
                "icon-cancel",
                "icon-circle-check",
                "icon-add-2",
                "icon-remove",
                "icon-subtract",
                "icon-alert-warning",
                "icon-alert-info",
                "icon-alert-question",
                "icon-percent",
                "icon-pencil-circle",
                "icon-search-circle",
                "icon-config-circle",
                "icon-graph-stats",
                "icon-user",
                "icon-user-men",
                "icon-user-woman",
                "icon-keyhole",
                "icon-lock-circle",
                "icon-radioactive",
                "icon-download-arrow-2",
                "icon-warning",
                "icon-add",
                "icon-check",
                "icon-double-check",
                "icon-close",
                "icon-down-arrow",
                "icon-left-arrow",
                "icon-up-arrow",
                "icon-right-arrow",
                "icon-move",
                "icon-shrink",
                "icon-expand",
                "icon-data-h",
                "icon-data-v",
                "icon-kb-arrow-down",
                "icon-kb-arrow-left",
                "icon-kb-arrow-up",
                "icon-kb-arrow-right",
                "icon-touch-up",
                "icon-refresh",
                "icon-reload",
                "icon-search",
                "icon-search-bold",
                "icon-zoom-in",
                "icon-zoom-in-bold",
                "icon-zoom-out",
                "icon-zoom-out-bols",
                "icon-alarm",
                "icon-alarm-off",
                "icon-alarm-sleep",
                "icon-alarm-ring",
                "icon-bin",
                "icon-bin-2",
                "icon-bin-open",
                "icon-view",
                "icon-view-off",
                "icon-star",
                "icon-star-bold",
                "icon-heart",
                "icon-heart-bold",
                "icon-heart-beat",
                "icon-attachment",
                "icon-hierarchy",
                "icon-help",
                "icon-help-bold",
                "icon-pin",
                "icon-wench",
                "icon-hammer",
                "icon-config-double",
                "icon-config",
                "icon-settings",
                "icon-settings-pc",
                "icon-pencil",
                "icon-pencil-edith",
                "icon-paper-edit",
                "icon-pencil-paper",
                "icon-file-edit",
                "icon-folder",
                "icon-folder-bookmark",
                "icon-folder-open",
                "icon-filter",
                "icon-filter-text",
                "icon-flag",
                "icon-flash",
                "icon-cut",
                "icon-cut-2",
                "icon-link",
                "icon-link-broken",
                "icon-copy",
                "icon-download",
                "icon-download-arrow",
                "icon-upload",
                "icon-upload-arrow",
                "icon-rolling",
                "icon-color-bucket",
                "icon-hourglass",
                "icon-hourglass-2",
                "icon-clock",
                "icon-compass",
                "icon-gauge-dashboard",
                "icon-gauge-dashboard-2",
                "icon-clock-hand",
                "icon-login",
                "icon-login-3",
                "icon-login-2",
                "icon-lock",
                "icon-open",
                "icon-logout",
                "icon-logout-3",
                "icon-logout-2",
                "icon-user-2",
                "icon-user-previous",
                "icon-user-add",
                "icon-user-remove",
                "icon-user-update",
                "icon-user-check",
                "icon-user-fly",
                "icon-user-cancel",
                "icon-user-search",
                "icon-user-file",
                "icon-car",
                "icon-car-2",
                "icon-car-garage",
                "icon-car-key",
                "icon-card-payment",
                "icon-cash-briefcase",
                "icon-cashier-man",
                "icon-cashier-woman",
                "icon-cash-user",
                "icon-cat",
                "icon-certified",
                "icon-certified-2",
                "icon-chair",
                "icon-charger",
                "icon-palette",
                "icon-checklist",
                "icon-check-paymen",
                "icon-chess",
                "icon-church",
                "icon-cloud",
                "icon-cloud-off",
                "icon-coconut",
                "icon-coffee",
                "icon-coins",
                "icon-coins-bill",
                "icon-coins-stack",
                "icon-confetti",
                "icon-contract",
                "icon-check-in",
                "icon-contract-handshake",
                "icon-conversation",
                "icon-conversation-browser",
                "icon-copy-email",
                "icon-crossroad-direction",
                "icon-crown",
                "icon-currency",
                "icon-database",
                "icon-database-bug",
                "icon-database-cancel",
                "icon-database-check",
                "icon-database-download",
                "icon-database-edit",
                "icon-database-network",
                "icon-data-file",
                "icon-design",
                "icon-desktop-computer",
                "icon-diamond",
                "icon-dices",
                "icon-direction",
                "icon-dna",
                "icon-dog",
                "icon-draw-box",
                "icon-drawer-envelope",
                "icon-drink",
                "icon-email",
                "icon-email2",
                "icon-email-fly",
                "icon-email-monitor",
                "icon-emergency-call",
                "icon-employee",
                "icon-envelope-pigeon",
                "icon-expand-corners",
                "icon-face-flash",
                "icon-fax-1",
                "icon-fax-2",
                "icon-file-award",
                "icon-file-graph",
                "icon-file-sheet",
                "icon-file-text",
                "icon-floppy-disk",
                "icon-floppy-disk-2",
                "icon-fly",
                "icon-flyer-taken",
                "icon-folder-sealed",
                "icon-food",
                "icon-footprint",
                "icon-gif",
                "icon-gift-tag",
                "icon-gold-bars",
                "icon-graphs",
                "icon-mouse",
                "icon-happy-laptop",
                "icon-happy-smartphone",
                "icon-headphones",
                "icon-headphones-question",
                "icon-headphones-smile",
                "icon-headphones-support",
                "icon-helicopter",
                "icon-hit",
                "icon-hone-off",
                "icon-honeycomb",
                "icon-hospital",
                "icon-hospital-shield",
                "icon-house-blueprints",
                "icon-ia",
                "icon-id",
                "icon-id-card",
                "icon-id-card-2",
                "icon-ile-hierarchy",
                "icon-image-gallery",
                "icon-info-center",
                "icon-insurance-cover",
                "icon-invoice",
                "icon-invoice-2",
                "icon-invoice-mail",
                "icon-job-profile",
                "icon-like",
                "icon-key",
                "icon-key-lock",
                "icon-keys",
                "icon-keyboard-type",
                "icon-learn",
                "icon-learn-2",
                "icon-legal-hammer",
                "icon-legal-scale",
                "icon-lift",
                "icon-line-graph-pc",
                "icon-list-text",
                "icon-location-move",
                "icon-location-off",
                "icon-location-on",
                "icon-location-pin",
                "icon-location-user",
                "icon-loop",
                "icon-luggage-check",
                "icon-luggage-reject",
                "icon-magic",
                "icon-mailbox",
                "icon-man-woman",
                "icon-map",
                "icon-map-pin",
                "icon-medical",
                "icon-microphone",
                "icon-microphone-mute",
                "icon-modular",
                "icon-modular2",
                "icon-module",
                "icon-module-four",
                "icon-module-three",
                "icon-molecule",
                "icon-monetization-idea",
                "icon-monetization-phone",
                "icon-monetization-user",
                "icon-money-decrease",
                "icon-money-increase",
                "icon-monitor",
                "icon-monitor-chart",
                "icon-monitor-graph",
                "icon-monitor-line",
                "icon-emoji-happy",
                "icon-emoji-sad",
                "icon-mood-happy",
                "icon-conversation-smile",
                "icon-mood-square-happy",
                "icon-conversations-quare-smile",
                "icon-mood-peace",
                "icon-mood-rock",
                "icon-not-allowed",
                "icon-hand",
                "icon-handshake",
                "icon-network-browser",
                "icon-network-signal",
                "icon-network-user",
                "icon-office-desk",
                "icon-officer-animal",
                "icon-officer-camera",
                "icon-officer-gate",
                "icon-officer-luggage",
                "icon-officer-passport",
                "icon-officer-plant",
                "icon-officer-scanner",
                "icon-officer-scanner-2",
                "icon-office-shelf",
                "icon-office-table",
                "icon-omputer-shield",
                "icon-optimization-graph",
                "icon-optimization-plane",
                "icon-share-4",
                "icon-share-3",
                "icon-pad-finger",
                "icon-palm-tree",
                "icon-paper-write",
                "icon-passport",
                "icon-passport-globe",
                "icon-passport-ticket",
                "icon-pass-smartphone",
                "icon-password",
                "icon-payment",
                "icon-payment-bag",
                "icon-payment-bill",
                "icon-payment-bill-2",
                "icon-payment-bills",
                "icon-pc",
                "icon-pc-smile",
                "icon-pencil-quill",
                "icon-pencil-ruler",
                "icon-pen-write",
                "icon-performance-decrease",
                "icon-performance-increase",
                "icon-performance-mail",
                "icon-performance-tablet",
                "icon-person-bubble",
                "icon-person-bubble-square",
                "icon-person-pass",
                "icon-phone",
                "icon-phone-book",
                "icon-phone-chatting",
                "icon-phone-dial",
                "icon-phone-lock",
                "icon-phone-missed",
                "icon-phone-off",
                "icon-phone-receive",
                "icon-phone-refresh",
                "icon-phone-return",
                "icon-phone-ring",
                "icon-phone-ringing",
                "icon-photo",
                "icon-photogallery",
                "icon-plane-ticket",
                "icon-round-trip",
                "icon-round-trip-2",
                "icon-insurance-plane",
                "icon-insurance-shield",
                "icon-shipment-plane",
                "icon-plane",
                "icon-plane-2",
                "icon-plane-3",
                "icon-airport-road",
                "icon-plane-board",
                "icon-plane-cancel",
                "icon-plane-cancel-2",
                "icon-plane-land",
                "icon-plane-rect",
                "icon-plane-round",
                "icon-plane-take-off",
                "icon-plane-time",
                "icon-plane-time-2",
                "icon-trip-time",
                "icon-plane-trip",
                "icon-airplane-mode",
                "icon-remote-plane-2",
                "icon-remote-plane",
                "icon-trip-international",
                "icon-travel-plane",
                "icon-travel",
                "icon-travel-luggag",
                "icon-suitcase-key",
                "icon-travel-luggage",
                "icon-remote",
                "icon-presentation-screen",
                "icon-presentation",
                "icon-presentation-speaker",
                "icon-print",
                "icon-print-search",
                "icon-products-purse",
                "icon-profile-picture",
                "icon-user-shield",
                "icon-programming",
                "icon-programming-flag",
                "icon-promo-mail",
                "icon-puzzle",
                "icon-quill",
                "icon-ranking-ribbon",
                "icon-rating-booklet",
                "icon-read-email",
                "icon-reading",
                "icon-receipt",
                "icon-reception",
                "icon-register-print",
                "icon-responsive-design",
                "icon-responsive-design-2",
                "icon-robot",
                "icon-robot-2",
                "icon-rocket",
                "icon-rocket-2",
                "icon-room-clock",
                "icon-room-hanger",
                "icon-sailboat",
                "icon-save-money",
                "icon-saving-piggy",
                "icon-saving-piggy-2",
                "icon-saving-safe",
                "icon-screen-analytics",
                "icon-search-email",
                "icon-seat-child",
                "icon-seat-find",
                "icon-seat-regular",
                "icon-seat-vip",
                "icon-security-officer",
                "icon-send",
                "icon-server",
                "icon-server-network",
                "icon-service",
                "icon-ship",
                "icon-shipping",
                "icon-shop",
                "icon-shop-pc",
                "icon-shopping-cart",
                "icon-sign-document",
                "icon-single",
                "icon-single-briefcase",
                "icon-single-decrease",
                "icon-single-flight",
                "icon-single-increas",
                "icon-single-rotate",
                "icon-single-statics",
                "icon-skull",
                "icon-software-paytment",
                "icon-sound",
                "icon-sound-note-2",
                "icon-note",
                "icon-speaker",
                "icon-speaker-mute",
                "icon-stamp",
                "icon-stamp-document",
                "icon-startup-launch",
                "icon-sticker",
                "icon-stop",
                "icon-stopwatch",
                "icon-airport",
                "icon-airport-signal",
                "icon-surf",
                "icon-tablet-touch",
                "icon-task-checklist",
                "icon-task-checklist-2",
                "icon-taxi",
                "icon-terminal",
                "icon-ticket",
                "icon-ticket-2",
                "icon-ticket-add",
                "icon-ticket-buy",
                "icon-ticket-check",
                "icon-ticket-clock",
                "icon-ticket-exchange",
                "icon-ticket-group",
                "icon-ticket-hold",
                "icon-ticket-pay",
                "icon-ticket-remove",
                "icon-ticket-return",
                "icon-ticket-two",
                "icon-tool-box",
                "icon-touch-coin",
                "icon-touch-finger",
                "icon-train",
                "icon-translate",
                "icon-translate-2",
                "icon-travel-finger",
                "icon-travel-luggage-2",
                "icon-travel-man",
                "icon-travel-pin",
                "icon-trip-person",
                "icon-list",
                "icon-ui-bullets",
                "icon-unbalance",
                "icon-usb",
                "icon-user-bubble",
                "icon-user-graph",
                "icon-user-id",
                "icon-user-logout",
                "icon-users-deal",
                "icon-video",
                "icon-virus",
                "icon-virus-2",
                "icon-voice-mail",
                "icon-voucher-mail",
                "icon-walkway-luggage",
                "icon-walkway-people",
                "icon-wallet-close",
                "icon-wallet-open",
                "icon-wallet-international",
                "icon-webcam",
                "icon-wifi-laptop",
                "icon-windows",
                "icon-window-user",
                "icon-zip",
                "icon-ab-testing",
                "icon-accounting-calculator",
                "icon-airport-stairs",
                "icon-analytics-graph",
                "icon-analytics-net",
                "icon-analytics-pie",
                "icon-anchor",
                "icon-archive",
                "icon-arrival-departure",
                "icon-award-trophy",
                "icon-bag-duty-free",
                "icon-bag-smile",
                "icon-baggage",
                "icon-baggage-2",
                "icon-baggage-add",
                "icon-baggage-cart",
                "icon-baggage-check",
                "icon-baggage-check-user",
                "icon-baggage-drop",
                "icon-baggage-leave",
                "icon-baggage-line",
                "icon-baggage-plane",
                "icon-baggage-questio",
                "icon-baggage-rol",
                "icon-baggage-scan",
                "icon-baggage-take",
                "icon-baggage-view",
                "icon-baggage-weight",
                "icon-baggage-weight-2",
                "icon-bank",
                "icon-bank-2",
                "icon-bank-international",
                "icon-beach",
                "icon-beach-2",
                "icon-beach-3",
                "icon-bed",
                "icon-bed-room",
                "icon-bicycle-2",
                "icon-bill-magnet",
                "icon-bills",
                "icon-bill-stack",
                "icon-binary",
                "icon-binocular",
                "icon-blueprints",
                "icon-board-education",
                "icon-boarding-check",
                "icon-boarding-pass",
                "icon-bomb",
                "icon-bookmark",
                "icon-bookmark-doc",
                "icon-barcode-tag",
                "icon-tag",
                "icon-bookmarks",
                "icon-bookmarks-2",
                "icon-box",
                "icon-box-2",
                "icon-shipment-box",
                "icon-box-bar",
                "icon-break-contract",
                "icon-briefcase",
                "icon-browser-gauge",
                "icon-bubble",
                "icon-bubble-square",
                "icon-bubble-square-typing",
                "icon-bubble-typing",
                "icon-bud",
                "icon-bug",
                "icon-bug-2",
                "icon-building",
                "icon-buildings",
                "icon-buildings-2",
                "icon-bus-card",
                "icon-business-idea",
                "icon-businessman",
                "icon-businessman-passenger",
                "icon-business-man-woman",
                "icon-calculator",
                "icon-calendar",
                "icon-calendar-2",
                "icon-calendar-3",
                "icon-calendar-4",
                "icon-camera",
                "icon-twitter",
                "icon-facebook",
                "icon-instagram",
                "icon-smoking",
                "icon-no-smoking",
                "icon-glass-cup",
                "icon-idea",
                "icon-ac",
                "icon-bluetooth",
                "icon-wifi",
                "icon-not-wifi",
                "icon-drink-2",
                "icon-eat",
                "icon-eating",
                "icon-entertainment",
                "icon-stairs",
                "icon-wheelchair",
                "icon-seat",
                "icon-lie-flat-seats",
                "icon-plug",
                "icon-headphones-1",
                "icon-headphones-2",
                "icon-music",
                "icon-microphone-1",
                "icon-movie",
                "icon-movie-tape",
                "icon-tv",
                "icon-radio",
                "fas fa-swimmer"
                ]
        }
    },
    methods: {
        setIcon (icon) {
            this.seletedIcon = icon
            this.$emit('input', icon)
        }
    },
    watch: {
        value (newVal) {
            if(newVal != this.seletedIcon) {
                this.seletedIcon = newVal
            }
        }
    },
    mounted() {
        this.seletedIcon = this.value
    }
}
</script>