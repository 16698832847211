`<template>
    <div>
        <div class="row section mx-2 my-1 p-2">
            <div class="col-md-5" v-if="!quoteFlight.empty_quote">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-airport-road mr-1"></span> Requerimientos de Vuelo
                            <span class="float-sm-right">
                                <span class="badge badge-success" v-if="quoteFlight.flexible_date">
                                    Fecha Flexible &nbsp;</span>&nbsp;
                                <span class="badge badge-success" v-if="quoteFlight.direct">
                                    Vuelo Directo &nbsp;</span>&nbsp;
                                <span class="badge badge-success" v-if="quoteFlight.cabin_class">
                                    {{getCabinClass(quoteFlight.cabin_type)}} &nbsp;</span>&nbsp;
                            </span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Origen</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="getStringAirport(quoteFlight.origin)">
                            </div>
                            <div class="form-group col-6">
                                <label class="w-100">Destino</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="getStringAirport(quoteFlight.destiny)">
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Fecha de Salida </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteFlight.start_date).format('LL')" name="name">
                            </div>
                            <div class="form-group col-6">
                                <label>
                                    <span v-if="quoteFlight.one_way" class="font-weight-bold">Solo ida</span>
                                    <span v-else>Fecha de Retorno</span>
                                </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(!quoteFlight.one_way) ? $moment(quoteFlight.end_date).format('LL') : ''" name="name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="w-100">
                                    Pasajeros ({{totalPassengers}} {{ (totalPassengers>1) ? 'Personas' : 'Persona' }} )
                                </label>
                                <div class="row">
                                    <div class="form-group col-3" v-if="quoteFlight.passenger_adult">
                                        <input type="text" class="form-control form-control-sm form-control-sm"
                                        readonly disabled :value="quoteFlight.passenger_adult + ' Adultos'">
                                    </div>
                                    <div class="form-group col-3" v-if="quoteFlight.passenger_elderly">
                                        <input type="text" class="form-control form-control-sm form-control-sm"
                                        readonly disabled :value="quoteFlight.passenger_elderly + ' A. Mayor'">
                                    </div>
                                    <div class="form-group col-3" v-if="quoteFlight.passenger_child">
                                        <input type="text" class="form-control form-control-sm form-control-sm"
                                        readonly disabled :value="quoteFlight.passenger_child + ' Niño'">
                                    </div>
                                    <div class="form-group col-3" v-if="quoteFlight.passenger_infant">
                                        <input type="text" class="form-control form-control-sm form-control-sm"
                                        readonly disabled :value="quoteFlight.passenger_infant + ' Infante'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5" v-else>
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-airport-road mr-1"></span> Requerimientos de Vuelo
                        </label>
                    </div>
                    <div class="form-group col-12">
                        <h5 class="text-center my-4">Requerimiento Vacio</h5>
                        <label>Comentarios</label>
                        <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteFlight.comments}}</div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------- -->
            <div class="col-md-7 border-left">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Soluciones de Vuelo
                            <!-- <button class="btn btn-primary float-right btn-sm">Agregar</button> -->
                        </label>
                        <span class="float-sm-right">
                            <button class="btn ml-3 btn-primary float-right btn-sm" :class="{'btn-loading ': loadingItems.new_flight_response}"
                            @click="newFlightResponse()" :disabled="!owner">
                                Nueva Solucion
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row" v-if="flightResponses && flightResponses.length>0">
                    <div class="form-group col-12  mb-0 pb-0">
                        <!-- <label class="w-100">
                            Boletos
                        </label> -->
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                        <th scope="col">#</th>
                                        <th scope="col">Pasajeros</th>
                                        <th scope="col">Salida</th>
                                        <th scope="col">Retorno</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="loadComplete && loadingItems.solutions">
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center py-3">
                                                    <div class="spinner-border text-secondary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div>
                                                        <small>Cargando...</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item, index) in (flightResponses) ? flightResponses : null" 
                                        :key="index" :class="{'table-primary' : index==showResponse}">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">{{ (item.body_json != null && item.body_json.tickets) ? sumTickets(item.body_json.tickets, "cant") : "0" }}</td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.one_way_route && item.body_json.one_way_route.length > 0) ? item.body_json.one_way_route[0].takeoff.date : "Ninguna" }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.return_route && item.body_json.return_route.length > 0) ? item.body_json.return_route[0].takeoff.date : "Ninguna" }}
                                            </td>
                                            <td class="text-right">$ {{ (item.body_json && item.body_json.tickets) ? sumTickets(item.body_json.tickets, "sum").toFixed(2) : "0" }}</td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-info" @click="showResponse = index">
                                                    <span class="icon-view font-12"></span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger"
                                                :disabled="!owner"
                                                @click="onDeleteFlightResponse(index)">
                                                    <span class="icon-bin-2 font-12"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" v-else>
                    <div class="form-group align-middle text-center col-12 mb-0 pb-0 mt-5">
                        <p class="mb-0">Aun no se ha proporcionado soluciones</p>
                        <p class="mb-0"><small>"Haga click en
                            <b style="cursor:pointer;">Nueva Solucion</b> para agregar datos"
                        </small></p>
                    </div>
                </div>
            </div>
            <!-- ----------------------/////////////////////-------------------------------- -->
            <div class="col-md-12" v-if="flightResponses">
                <hr v-show="showResponse!=null">
                <div class="row mt-2" v-show="showResponse!=null">
                    <div class="col-md-6" v-for="typeRouteKey in keys" :key="typeRouteKey" >
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold mb-2">
                                    <span v-if="typeRouteKey=='one_way_route'">Ruta de Ida</span>
                                    <span v-if="typeRouteKey=='return_route'">Ruta de Retorno</span>
                                </label>
                                <button v-if="showResponse!=null && flightResponses[showResponse].body_json && flightResponses[showResponse].body_json[typeRouteKey] && flightResponses[showResponse].body_json[typeRouteKey].length>0" class="btn btn-primary float-right btn-sm" 
                                @click="showFlightRouteModal(typeRouteKey)" data-toggle="modal" data-target="#flightRouteModal" :disabled="!owner">
                                Agregar 
                                <span v-if="typeRouteKey=='one_way_route'">Ruta de Ida</span>
                                <span v-if="typeRouteKey=='return_route'">Ruta de Retorno</span>
                                </button>
                            </div>
                            <div class="col-12">
                                <table v-if="loadComplete && !loadingItems[typeRouteKey]" class="table table-detail-flight table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <tbody v-if="showResponse!=null && flightResponses[showResponse].body_json && flightResponses[showResponse].body_json[typeRouteKey] && flightResponses[showResponse].body_json[typeRouteKey].length>0">
                                        <!-- <tr><td>hola</td></tr> -->
                                        <tr class="option-route" v-for="(item, indexRoute) in flightResponses[showResponse].body_json[typeRouteKey]" :key="indexRoute">
                                            <!-- <td>{{item}}</td> -->
                                            <td v-if="item.takeoff.airport && item.landing.airport">
                                                <div class="container">
                                                    <div class="row mb-1">
                                                        <div class="col-md-12 text-center">
                                                            <p class="font-weight-bold">
                                                                <img class="airline-logo" width="20" :src="`/img/airlines/${item.airline.data.iata}.png`" onerror="this.src='/img/airlines/default.png';" >
                                                                ({{ item.airline.data.iata }}) {{ item.airline.data.name }} - {{ item.airline.data.alias }}
                                                            </p>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <p class="font-weight-bold">
                                                                <span class="icon-plane-take-off mr-1"></span>
                                                                Despegue
                                                            </p>
                                                            <p>({{ item.takeoff.airport.iata }}) {{ item.takeoff.airport.name }}</p>
                                                            <p>{{ item.takeoff.airport.city }}, {{ item.takeoff.airport.country.name }}</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <p>&nbsp;</p>
                                                            <p>{{ $moment(item.takeoff.date).format('LL') }}</p>
                                                            <p>{{ (item.takeoff.time_format) ? $moment(item.takeoff.time_format).format('LT') : null }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-7">
                                                            <p class="font-weight-bold">
                                                                <span class="icon-plane-land mr-1"></span>
                                                                Aterrizaje
                                                            </p>
                                                            <p>({{ item.landing.airport.iata }}) {{ item.landing.airport.name }}</p>
                                                            <p>{{ item.landing.airport.city }}, {{ item.landing.airport.country.name }}</p>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <p>&nbsp;</p>
                                                            <p>{{ $moment(item.landing.date).format('LL') }}</p>
                                                            <p>{{ (item.landing.time_format) ? $moment(item.landing.time_format).format('LT') : null }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td v-if="item.takeoff.airport && item.landing.airport" class="btn-option-route align-items-center align-middle" width="85">
                                                <div class="btn-group-vertical" role="group" aria-label="Basic example">
                                                    <button v-if="indexRoute>0" type="button" :disabled="!owner"
                                                    @click="onChangePosition(typeRouteKey, indexRoute, 'prev')" class="btn btn-sm btn-light">
                                                        <span class="icon-kb-arrow-up font-12"></span> Subir
                                                    </button>
                                                    <button v-if="showResponse!=null && flightResponses[showResponse].body_json[typeRouteKey].length>1 && indexRoute<flightResponses[showResponse].body_json[typeRouteKey].length-1" 
                                                    @click="onChangePosition(typeRouteKey, indexRoute, 'next')" type="button" class="btn btn-sm btn-light" :disabled="!owner">
                                                        <span class="icon-kb-arrow-down font-12"></span> Bajar
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light" data-toggle="modal" data-target="#flightRouteModal" :disabled="!owner"
                                                    @click="showFlightRouteModal(typeRouteKey, indexRoute)">
                                                        <span class="icon-pencil-edith font-12"></span> Editar
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light" :disabled="!owner"
                                                    @click.prevent="onDeleteRoute(typeRouteKey, indexRoute)">
                                                        <span class="icon-bin-2 font-12"></span> Borrar
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td>
                                                <div class="form-group align-middle text-center col-12  mb-0 pb-0">
                                                    <p class="mb-0">Aun no se ha proporcionado la ruta de ida</p>
                                                    <p class="mb-0"><small>"Puede agregar la <b>Ruta de Ida</b> aqui"</small></p>
                                                    <button class="btn btn-sm btn-primary mt-2" data-toggle="modal" data-target="#flightRouteModal" :disabled="!owner"
                                                    @click="showFlightRouteModal(typeRouteKey)">
                                                        Agregar 
                                                        <span v-if="typeRouteKey=='one_way_route'">ruta de Ida</span>
                                                        <span v-if="typeRouteKey=='return_route'">ruta de Retorno</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="col-12 text-center py-3" v-else>
                                    <div class="spinner-border text-secondary" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div>
                                        <small>Cargando...</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold mb-2">
                                    Boletos Aereos
                                </label>
                                <button v-if="showResponse!=null && flightResponses[showResponse].body_json" 
                                data-toggle="modal" data-target="#flightTicketsModal" :disabled="!owner"
                                @click="showFlightTicketsModal()" class="btn btn-primary float-right btn-sm">Nuevo Boleto</button>
                            </div>
                            <div class="col-12 mb-3">
                                <table class="table table-detail-flight table-sm table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                            <th scope="col"><span class="icon-ticket-group"></span></th>
                                            <th scope="col">Pasajeros</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Base</th>
                                            <th scope="col">Taxes</th>
                                            <th scope="col">Comision</th>
                                            <th scope="col">Utilidad</th>
                                            <th scope="col">Precio / Boleto</th>
                                            <th scope="col">Sub Total</th>
                                            <th scope="col"><span class="icon-menu-5-bold"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="
                                    loadComplete && showResponse!=null && flightResponses[showResponse].body_json
                                    && flightResponses[showResponse].body_json.tickets && flightResponses[showResponse].body_json.tickets != null 
                                    && Object.keys(flightResponses[showResponse].body_json.tickets).length !== 0">
                                        <tr v-for="(type_, index) in typeTickets" :key="index" v-show="flightResponses[showResponse].body_json.tickets[type_]">
                                            <th scope="row"></th>
                                            <td class="text-center">
                                                <p class="m-0">
                                                    {{ (type_ == 'adult') ? 'Adulto' : null }}
                                                    {{ (type_ == 'child') ? 'Adolecente' : null }}
                                                    {{ (type_ == 'elderly') ? 'Adulto Mayor' : null }}
                                                    {{ (type_ == 'infant') ? 'Infante' : null }}
                                                </p>
                                            </td>
                                            <td class="text-center">
                                                {{ (flightResponses[showResponse].body_json.tickets[type_]) ? flightResponses[showResponse].body_json.tickets[type_].cantTickets : null }}
                                            </td>
                                            <td class="text-right">
                                                $ {{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat(flightResponses[showResponse].body_json.tickets[type_].base).toFixed(2) : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat(flightResponses[showResponse].body_json.tickets[type_].taxes).toFixed(2) : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat(flightResponses[showResponse].body_json.tickets[type_].commission).toFixed(2) : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat(flightResponses[showResponse].body_json.tickets[type_].utility).toFixed(2) : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat((flightResponses[showResponse].body_json.tickets[type_].utility + flightResponses[showResponse].body_json.tickets[type_].commission + flightResponses[showResponse].body_json.tickets[type_].taxes + flightResponses[showResponse].body_json.tickets[type_].base) / flightResponses[showResponse].body_json.tickets[type_].cantTickets).toFixed(2) : null }}
                                            </td>
                                            <td class="text-right">
                                                ${{ (flightResponses[showResponse].body_json.tickets[type_]) ? parseFloat(flightResponses[showResponse].body_json.tickets[type_].utility + flightResponses[showResponse].body_json.tickets[type_].commission + flightResponses[showResponse].body_json.tickets[type_].taxes + flightResponses[showResponse].body_json.tickets[type_].base).toFixed(2) : null }}
                                            </td>
                                            <td width="75" class="text-right align-middle">
                                                <div class="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" class="btn btn-sm btn-light" :disabled="!owner"
                                                    data-toggle="modal" data-target="#flightTicketsModal" 
                                                    @click="showFlightTicketsModal(type_)">
                                                        <span class="icon-pencil-edith font-12"></span>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light" :disabled="!owner"
                                                    @click.prevent="onDeleteRoute('tickets', type_)">
                                                        <span class="icon-bin-2 font-12"></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr></tr>
                                        
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="9" class="text-center my-3 py-3">
                                                <div class="form-group align-middle text-center col-12 mb-0 pb-0">
                                                    <p class="mb-0">Aun no se han agregado los boletos aereos</p>
                                                    <p class="mb-0"><small>"Puede agregar los <b>Boletos Aereos</b> aqui"</small></p>
                                                    <button class="btn btn-sm btn-primary mt-2" data-toggle="modal" :disabled="!owner" @click="showFlightTicketsModal()"  data-target="#flightTicketsModal">
                                                        Agregar
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 v-show="showResponse!=null" class="font-weight-bold text-center  mt-2" style="font-size: 12px;">
                    <span style="cursor:pointer;" @click="showResponse = null">
                        Cerrar Detalles<span class="ml-1 icon-up-arrow" style="font-size: 12px;"></span>
                    </span>
                </h6>
            </div>
        </div>

        <flight-route-modal
        :typeRoute="typeRoute" 
        :flightResponses="varFlightRouteModal"
        :flightRoute="flightRoute"
        v-on:saveFlightRouteModal="saveFlightRouteModal"
        v-on:closeFlightRouteModal="closeFlightRouteModal()"></flight-route-modal>

        <flight-tickets-modal 
        :flightResponses="varFlightRouteModal"
        :flightTicket="flightTicket"
        v-on:saveFlightsTicketModal="saveFlightRouteModal"
        v-on:closeFlightTicketsModal="closeFlightRouteModal()"></flight-tickets-modal>

    </div>
</template>
<script>
import FlightRouteModal from '~/components/marketing/quotes/flight/FlightRouteModal';
import FlightTicketsModal from '~/components/marketing/quotes/flight/FlightTicketsModal';

export default {
    props: {
        quoteFlight: {
            type: [Object],
            default () {
                return false
            }
        },
        lastUpdateLocal: {
            type: Number
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    components: {
        FlightRouteModal,
        FlightTicketsModal
    },
    data() {
        return {
            loadingItems: {
                one_way_route:          false,
                return_route:           false,
                tickets:                false,
                new_flight_response:    false,
                solutions:              false,
            },
            loadComplete:               false,
            keys:                   ['one_way_route','return_route'],
            subKeys:                ['takeoff','landing','airline'],
            typeTickets:            ['adult','elderly','child','infant'],
            flightResponses:        null,
            loadAirports:           false,
            dataFlightPnr:          null,    // Datos para enviar al modal del pnr
            showDetailFlight:       false,
            showResponse:           null,
            showResponseBackup:     null,
            typeRoute:              null,
            varFlightRouteModal:    null,
            flightTicket:           null,
            flightRoute:            null,
            airportBank:            [],
            airlineBank:            [],
            cabinList: [
                {
                    value: 'M',
                    label: 'Económica'
                },
                {
                    value: 'W',
                    label: 'Económica Premiun'
                },
                {
                    value: 'C',
                    label: 'Business'
                },
                {
                    value: 'F',
                    label: 'First'
                },
            ],
        };
    },
    watch: {
        lastUpdateLocal: function(val, oldVal) {
            let vm = this;
            if (vm.lastUpdateLocal != null) {
                vm.load_response_flight();
            }
        },
        loadComplete: function(val, oldVal) {
            let vm = this;
            if (vm.loadComplete) {
                vm.loadingItems.one_way_route   = true;
                vm.loadingItems.return_route    = true;
                setTimeout(() => {
                    if (vm.showResponseBackup!=null) {
                        vm.showResponse         = vm.showResponseBackup;
                        vm.showResponseBackup   = null;
                    }
                    vm.loadingItems.one_way_route   = false;
                    vm.loadingItems.return_route    = false;
                },300);

            }
        },
    },
    methods: {
        async newFlightResponse() {
            let vm = this;
            vm.showResponse = null;
            vm.loadingItems.new_flight_response = true;
            await axios.post('/admin/quotes/flights/new', {quote_flight_id: vm.quoteFlight.id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if(data.success) {
                    vm.$snotify.success('Nueva solucion agregada.');
                    let obj = data.success;
                    this.saveFlightRouteModal(obj);
                    // this.$emit("saveNewFlightResponse", obj);
                }
                vm.loadingItems.new_flight_response = false;
                return;
            });
        },
        saveFlightRouteModal(obj) {
            let vm = this;
            vm.loadComplete = false;
            vm.loadingItems.one_way_route = true;
            vm.loadingItems.return_route = true;
            vm.showResponseBackup = (vm.showResponse!=null) ? vm.showResponse : vm.showResponseBackup;
            vm.showResponse = null;
            vm.$emit("saveNewFlightResponse", obj);
        },
        onDeleteFlightResponse(index) {
            let vm = this;
            $.confirm.delete(
                'Eliminar Solucion de Vuelo',
                `¿Está seguro que desea eliminar esta solucion?`,
                function(){
                    vm.deleteFlightResponse(index);
                }
            )
        },
        async deleteFlightResponse(index) {
            let vm = this;
            vm.showResponse = null;
            vm.loadingItems.solutions = true;
            await axios.post('/admin/quotes/flights/delete', {id: vm.flightResponses[index].id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                }
                if(data.success) {
                    let obj = data.success;
                    vm.$snotify.success('Solucion borrada con exito.');
                    this.$emit("deleteFlightResponse", obj);
                }
                vm.loadingItems.solutions = false;
                return;
            });
        },
        sumTickets(tickets = null, cantOrSum = "cant") {
            let temp = {"cant" : 0, "sum" : 0};
            if(tickets && temp[cantOrSum]===0) {
                tickets.forEach(ticket => {
                    temp["cant"]    += ticket.cantTickets;
                    temp["sum"]     += (ticket.base + ticket.commission + ticket.taxes + ticket.utility );
                });
                return temp[cantOrSum];
            } else {
                return null;
            }
        },
        async load_response_flight() {
            let vm = this;
            if (vm.quoteFlight && vm.quoteFlight.quote_flight_response ) {
                let temp = [];
                vm.quoteFlight.quote_flight_response.forEach((element, index) => {
                    try {
                        if (element.body_json && JSON.parse(element.body_json)) {
                            element.body_json = JSON.parse(element.body_json);
                        }
                        temp.push(element);
                    } catch (e) {
                        console.log("error JSON.parse(element.body_json)");
                        temp.push(element);
                    }
                });
                vm.flightResponses = temp;
                await vm.setAirportsBank();
                return true;
            }
            return null;
        },
        async setAirportsBank() {
            let vm = this;
            vm.loadComplete = false;
            await vm.insertOrUpdateAirportBank(vm.quoteFlight.origin);
            await vm.insertOrUpdateAirportBank(vm.quoteFlight.destiny);
            if (vm.flightResponses && vm.flightResponses != null && vm.flightResponses.length > 0) {
                vm.flightResponses.forEach(flightResponse => {
                    vm.keys.forEach(itemKey => {
                        vm.loadingItems[itemKey] = true;
                        if (flightResponse.body_json && flightResponse.body_json[itemKey] && flightResponse.body_json[itemKey].length > 0) {
                            let tempItemKey = flightResponse.body_json[itemKey];
                            tempItemKey.forEach(eachRoute => {
                                vm.subKeys.forEach(async itemSubKey => {
                                    if(itemSubKey == "airline") {
                                        if (eachRoute[itemSubKey].data) {
                                            vm.insertOrUpdateAirlineBank(eachRoute[itemSubKey].data);
                                        } else {
                                            let airlineFind = await vm.insertOrUpdateAirlineBank(null, eachRoute[itemSubKey].id);
                                            eachRoute[itemSubKey].data = airlineFind;
                                        }
                                    } else {
                                        if (eachRoute[itemSubKey]) {
                                            if (eachRoute[itemSubKey].airport) {
                                                vm.insertOrUpdateAirportBank(eachRoute[itemSubKey].airport);
                                            } else {
                                                let airportFind = await vm.insertOrUpdateAirportBank(null, eachRoute[itemSubKey].airport_id);
                                                eachRoute[itemSubKey].airport = airportFind;
                                            }
                                        }
                                    }
                                });
                            });
                        }
                        vm.loadingItems[itemKey] = false;
                    });
                });
            }
            setTimeout(() => {
                vm.loadComplete = true;
            },300);
        },
        async insertOrUpdateAirportBank(airport = null, id = null) {
            let vm = this;
            let find = null;
            if(airport) {
                vm.airportBank.forEach(element => {
                    if(element.id == airport.id) {
                        find = element;
                    }
                });
                (find) ? null : vm.airportBank.push(airport);
            } else if (id) {
                vm.airportBank.forEach(element => {
                    if(element.id == id) {
                        find = element;
                    }
                });
                if(!find) {
                    await axios.get(`/admin/airports/${id}`).then(res => {
                        if (res.data && res.data.data) {
                            find = res.data.data;
                            vm.airportBank.push(find);
                        } else {
                            console.log(`/admin/airports/${id} no encontrado`);
                        }
                    });
                }
            }
            return find;
        },
        async insertOrUpdateAirlineBank(airline = null, id = null) {
            let vm = this;
            let find = null;
            if(airline) {
                vm.airlineBank.forEach(element => {
                    if(element.id == airline.id) {
                        find = element;
                    }
                });
                (find) ? null : vm.airlineBank.push(airline);
            } else if (id) {
                vm.airlineBank.forEach(element => {
                    if(element.id == id) {
                        find = element;
                    }
                });
                if(!find) {
                    await axios.get(`/admin/airlines/${id}`).then(res => {
                        if (res.data && res.data && res.data[0]) {
                            find = res.data[0];
                            vm.airlineBank.push(find);

                        } else {
                            console.log(`/admin/airlines/${id} no encontrado`);
                        }
                    });
                }
            }
            return find;
        },
        searchInAirportBank(airport_id, typeResponse = false) {
            let valRes = null;
            this.airportBank.forEach(element => {
                if(element.id == airport_id) {
                    if(typeResponse) {
                        valRes = this.getStringAirport(element);
                    } else {
                        valRes = element;
                    }
                }
            });
            return valRes;
        },
        removeAirports(body_json) {
            let vm = this;
            let tempBodyJson = JSON.stringify(body_json);
            tempBodyJson = JSON.parse(tempBodyJson);
            vm.keys.forEach(item => {
                if (tempBodyJson !=null && tempBodyJson[item] && tempBodyJson[item].length>0) {
                    tempBodyJson[item].forEach(element => {
                        (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                        (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                    });
                }
            });
            tempBodyJson.tickets = body_json.tickets;
            return tempBodyJson;
        },
        onDeleteRoute(typeJson, index) {
            let vm = this
            let title_msj='';
            title_msj = (typeJson == 'one_way_route') ? 'Ruta de Ida' : title_msj;
            title_msj = (typeJson == 'return_route') ? 'Ruta de Retorno' : title_msj;
            title_msj = (typeJson == 'tickets') ? 'Boleto' : title_msj;
            $.confirm.delete( 
                'Eliminar ' + title_msj,
                `¿Está seguro que desea eliminar <u>${title_msj}</u>?`, 
                function(){
                    vm.deleteItem(typeJson, index);
                }
            )
        },
        deleteItem (typeJson, index) {
            let vm = this;
            let id = vm.flightResponses[vm.showResponse].id;
            let tempBody = (vm.flightResponses[vm.showResponse].body_json) ? JSON.parse(JSON.stringify(vm.flightResponses[vm.showResponse].body_json)) : null;
            this.loadingItems.one_way_route = true;
            this.loadingItems.return_route = true;
            // debugger
            if ( tempBody !=null &&  tempBody.one_way_route && tempBody.one_way_route.length>0) {
                 tempBody.one_way_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            if ( tempBody !=null &&  tempBody.return_route &&  tempBody.return_route.length>0) {
                 tempBody.return_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            if (tempBody[typeJson] && tempBody[typeJson][index]) {
                if(typeJson == 'tickets') {
                    delete tempBody[typeJson][index];
                } else {
                    tempBody[typeJson].splice(index, 1);
                }
                if (tempBody[typeJson].length==0) {
                    delete tempBody[typeJson];
                }
            } else {
                this.loadingItems.one_way_route = false;
                this.loadingItems.return_route = false;
                return;
            }

            let tempBodyString = JSON.stringify(tempBody);
            
            axios.post('/admin/quotes/flights/save', { id: vm.flightResponses[vm.showResponse].id, quote_flight_id: vm.flightResponses[vm.showResponse].quote_flight_id, body_json: tempBodyString }).then(response => {
                let data = response.data;
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                    this.loadingItems.one_way_route = false;
                    this.loadingItems.return_route = false;
                    return;
                }

                if(data.success) {
                    vm.$snotify.simple('Dato removido.');
                    this.loadingItems.one_way_route = false;
                    this.loadingItems.return_route = false;
                    this.showResponseBackup = this.showResponse;
                    this.showResponse = null;
                    let obj = data.success;
                    this.saveFlightRouteModal(obj);
                    return;
                }

                vm.$snotify.info('Se ha enviado solicitud')
                this.loadingItems.one_way_route = false;
                this.loadingItems.return_route = false;
                return;

            }).catch(err => {
                this.loadingItems.one_way_route = false;
                this.loadingItems.return_route = false;
                console.log(err);
            });
        },
        onChangePosition(typeJson, index, position="") {
            let vm = this
            $.confirm( 
                {
                    title: `Cambiar Posicion`,
                    content: `Esta apunto de cambiar la posicion del Item actual <br><b>¿Desea continuar?</b>`,
                    buttons: {
                        continuar:{
                            text: 'Continuar',
                            btnClass: 'btn-success',
                            keys: ['enter'],
                            action: function() {
                                vm.changePositionItem(typeJson, index, position);
                            }
                        },
                        cancelar: function () {
                            
                        },
                    }
                }
            )
        },
        changePositionItem(typeJson, index, position) {
            let vm = this;
            let temp = null;
            let indexReplace = null;
            temp = vm.flightResponses[vm.showResponse].body_json[typeJson][index];
            if (position=='prev') {
                indexReplace = index - 1;
            } else if (position=='next') {
                indexReplace = index + 1;
            } else {
                return;
            }
            vm.flightResponses[vm.showResponse].body_json[typeJson][index] = vm.flightResponses[vm.showResponse].body_json[typeJson][indexReplace];
            vm.flightResponses[vm.showResponse].body_json[typeJson][indexReplace] = temp;

            let id = vm.flightResponses[vm.showResponse].id;

            let tempBody = (vm.flightResponses[vm.showResponse].body_json) ? JSON.parse(JSON.stringify(vm.flightResponses[vm.showResponse].body_json)) : null;

            this.loadingItems.one_way_route = true;
            this.loadingItems.return_route = true;
            // debugger
            if ( tempBody !=null &&  tempBody.one_way_route && tempBody.one_way_route.length>0) {
                 tempBody.one_way_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            if ( tempBody !=null &&  tempBody.return_route &&  tempBody.return_route.length>0) {
                 tempBody.return_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            let tempBodyString = JSON.stringify(tempBody);
            
            axios.post('/admin/quotes/flights/save', { id: vm.flightResponses[vm.showResponse].id, quote_flight_id: vm.flightResponses[vm.showResponse].quote_flight_id, body_json: tempBodyString }).then(response => {
                let data = response.data;
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                    this.loadingItems.one_way_route = false;
                    this.loadingItems.return_route = false;
                    return;
                }

                if(data.success) {
                    vm.$snotify.success('Accion realizada con exito.');
                    this.showResponseBackup = this.showResponse;
                    this.showResponse = null;
                    let obj = data.success;
                    this.saveFlightRouteModal(obj);
                    return;
                }

                vm.$snotify.info('Se ha enviado solicitud')
                this.loadingItems.one_way_route = false;
                this.loadingItems.return_route = false;
                return;

            }).catch(err => {
                this.loadingItems.one_way_route = false;
                this.loadingItems.return_route = false;
                console.log(err);
            });
        },
        showformPnr() {
            let vm = this;
            let temp = [];
            temp.quote_flight_id = vm.quoteFlight.id;
            temp.quote_flight_response = (vm.quoteFlight.quote_flight_response[0]) ? vm.quoteFlight.quote_flight_response[0] : null;            
            vm.dataFlightPnr = temp;
        },
        showFlightRouteModal(type, index = null) { // 'one_way_route' o 'return_route'
            let vm = this;
            vm.typeRoute = type;
            vm.varFlightRouteModal = vm.flightResponses[vm.showResponse];
            vm.flightRoute = index;
        },
        showFlightTicketsModal(type = null) {
            let vm = this;
            vm.flightTicket = type;
            vm.varFlightRouteModal = vm.flightResponses[vm.showResponse];
        },
        closeFlightRouteModal() {
           this.varFlightRouteModal = null;
           this.flightRoute = null;
           this.flightTicket = null;
        },
        closeFlightTicketsModal() {
           this.varFlightRouteModal = null;
        },
        subTotalTicket(ticket, typeVal=null) {
            let value = 0;
            value += parseFloat(ticket.base) + 0;
            value += parseFloat(ticket.commission) + 0;
            value += parseFloat(ticket.taxes) + 0;
            value += parseFloat(ticket.utility) + 0;
            if (typeVal=='ticket') {
                return parseFloat(value);
            }
            return parseFloat(value) * parseFloat(ticket.cantTickets);
        },
        averageTickets(tickets, typeVal=null) {
            let totalSum = 0;
            let totalTickets = 0;
            if (tickets.adult) { // Extraemos el valor de los tickets de adult
                let adult = 0;
                adult += parseFloat(tickets.adult.base) + 0;
                adult += parseFloat(tickets.adult.commission) + 0;
                adult += parseFloat(tickets.adult.taxes) + 0;
                adult += parseFloat(tickets.adult.utility) + 0;
                adult = parseFloat(adult) * parseFloat(tickets.adult.cantTickets);
                totalSum += adult;
                totalTickets += parseFloat(tickets.adult.cantTickets);
            }
            if (tickets.child) { // Extraemos el valor de los tickets de child
                let child = 0;
                child += parseFloat(tickets.child.base) + 0;
                child += parseFloat(tickets.child.commission) + 0;
                child += parseFloat(tickets.child.taxes) + 0;
                child += parseFloat(tickets.child.utility) + 0;
                child = parseFloat(child) * parseFloat(tickets.child.cantTickets);
                totalSum += child;
                totalTickets += parseFloat(tickets.child.cantTickets);
            }
            if (tickets.elderly) { // Extraemos el valor de los tickets de elderly
                let elderly = 0;
                elderly += parseFloat(tickets.elderly.base) + 0;
                elderly += parseFloat(tickets.elderly.commission) + 0;
                elderly += parseFloat(tickets.elderly.taxes) + 0;
                elderly += parseFloat(tickets.elderly.utility) + 0;
                elderly = parseFloat(elderly) * parseFloat(tickets.elderly.cantTickets);
                totalSum += elderly;
                totalTickets += parseFloat(tickets.elderly.cantTickets);
            }
            if (tickets.infant) { // Extraemos el valor de los tickets de infant
                let infant = 0;
                infant += parseFloat(tickets.infant.base) + 0;
                infant += parseFloat(tickets.infant.commission) + 0;
                infant += parseFloat(tickets.infant.taxes) + 0;
                infant += parseFloat(tickets.infant.utility) + 0;
                infant = parseFloat(infant) * parseFloat(tickets.infant.cantTickets);
                totalSum += infant;
                totalTickets += parseFloat(tickets.infant.cantTickets);
            }
            return (typeVal=='prom') ? (totalSum / totalTickets) : totalSum;
        },
        copyPnr() {
            let codigoACopiar = document.getElementById('pnrCode');
            var seleccion = document.createRange();
            seleccion.selectNodeContents(codigoACopiar);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(seleccion);
            var res = document.execCommand('copy');
            window.getSelection().removeRange(seleccion);
            this.$snotify.simple('PNR copiado');
        },
        getCabinClass(id) {
            let vm = this;
            let thisClass = null;
            thisClass = vm.cabinList.find(c => {
                return c.value == id;
            });
            return (thisClass) ? thisClass.label : thisClass;
        },
        getStringAirport(airport) {
            let response = null;
            if(airport) {
                response = airport.city + ' (' + airport.iata + ') - ' + airport.country.name;
            }
            return response;
        },
        
    },
    computed: {
        flight_body_json() {
            let vm = this;
            let valResponse = null;
            if (vm.flightResponses && vm.flightResponses.body_json && vm.flightResponses.body_json) {
                valResponse = [];
                valResponse['tickets'] = (Object.entries(vm.flightResponses.body_json.tickets).length === 0) ? null : vm.flightResponses.body_json.tickets;
                valResponse['one_way_route'] = (Object.entries(vm.flightResponses.body_json.one_way_route).length === 0) ? null : vm.flightResponses.body_json.one_way_route;
                valResponse['return_route'] = (Object.entries(vm.flightResponses.body_json.return_route).length === 0) ? null : vm.flightResponses.body_json.return_route; 
            }
            return valResponse;
        },
        tickets_computed() {
            let vm = this;
            if (vm.flightResponses && vm.flightResponses.body_json && vm.flightResponses.body_json.tickets) {
                return (Object.entries(vm.flightResponses.body_json.tickets).length === 0) ? null : vm.flightResponses.body_json.tickets;
            }
            return null;
        },
        totalPassengers() {
            let vm = this;
            let total = null;
            if (vm.quoteFlight) {
                let flight = vm.quoteFlight;
                total += flight.passenger_adult;
                total += flight.passenger_elderly;
                total += flight.passenger_child;
                total += flight.passenger_infant;
            }
            return total;
        }
    },
    mounted() {
        if(this.lastUpdateLocal) {
            this.load_response_flight();
        }
    }
}
</script>
<style scoped>
    .table-detail-flight p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .font-12 {
        font-size: 12px;
    }
    .option-route:hover .btn-option-route{
        opacity: 1;
    }
    .btn-option-route{
        opacity: 0;
        transition: 0.3s;
    }
</style>