<template>
    <div class="section pt-3 pb-1 mx-2" v-if="myPackage.id">
        <transition name="fade">
            <div class="row mx-2">
                <div class="form-group col-12 col-md-9">
                    <label>
                        <b>Resumen</b>
                    </label> 
                    <button class="btn btn-default btn-sm float-right d-inline-block"
                        data-toggle="modal" data-target="#modalMyPackage" @click="edit()">
                        <span class="icon-pencil-edith mr-1"></span> Editar
                    </button>
                    <div class="form-control" style="height: auto;">
                        <b>Titúlo:</b> {{myPackage.title}}
                        <br>
                        <b>Categoria:</b> {{myPackage.package_category.name}}
                        <br>
                        <b>Pais:</b> {{myPackage.city.state.country.name}} <img :src="`https://www.countryflags.io/${myPackage.city.state.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="myPackage.city.state.country.short_iso_code">
                        <br>
                        <b>Ciudad:</b> {{myPackage.city.name}}, {{myPackage.city.state.name}} 
                        <br>
                        <b>Inicia:</b> {{myPackage.start_date}} a las {{myPackage.start_time}} 
                        <br>
                        <b>Expira:</b> {{myPackage.expired_date}} a las {{myPackage.expired_time}} 
                        <br>
                        <b>Descriptión Corta:</b> {{myPackage.short_description}}
                        <br>
                        <b>Contenido:</b> {{myPackage.content}}
                        <br>
                    </div>
                </div>
                <div class="form-group col-12 col-md-3">
                    <label @click.prevent="change()" style="cursor: pointer;" :class="'text-center '+ myPackage.state_package.toLowerCase() +' col-12 p-2 mb-4'">{{myPackage.state_package}}</label>
                    <img v-bind:src="'/storage/packages/'+myPackage.banner" clasas="bd-placeholder-img card-img-top " 
                     :style="'border: solid 5px ' + myPackage.color_primary + ';'" alt="image" width="100%">
                    <label class="p-1 text-center" style="width: 100%;">Portada</label>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

export default {
    props: ["myPackage"],
    data() {
        return {
        };
    },
    methods: {
        edit() {
            this.$emit('onEditPackage');
        },
        change() {
            this.$emit('onChange');
        }
    },
    mounted() {
        // console.log('PackageResumen mounted.');
    }
};
</script>