<template>
    <div class="modal fade" id="addUserModal" tabindex="-1" role="dialog" aria-labelledby="authModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="authModalLabel">Nuevo Usuario</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form id="addUserForm" autocomplete="off" @submit.prevent="register" class="needs-validation" novalidate action="/admin/user/register" method="post">
                    <div class="modal-body">
                        <p class="subtitle">INFORMACIÓN GENERAL</p>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="first_name">Nombres</label>
                                    <input type="text" id="first_name" class="form-control" v-model="form.first_name">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="last_name">Apellidos</label>
                                    <input type="text" id="last_name" class="form-control" v-model="form.last_name">
                                </div>
                            </div>
                        </div> 
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="username">Nombre de Usuario</label>
                                    <input type="text" id="username" class="form-control" v-model="form.username">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="level">Niveles de Acceso</label>
                                    <tag-input id="roles" v-model="form.roles" ref="taginput" placeholder="Roles..." :show-focus="true" :data="roles"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="email">Correo</label>
                                    <input type="text" id="email" class="form-control" v-model="form.email">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary register">Crear Usuario</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import tokenInput from '~/components/tokenInput'
    import tagInput from '~/components/tagInput'

    export default {
        props: {
            user: Object
        },
        components: {
            'token-input' : tokenInput,
            tagInput,
        },
        data () {
            return {
                form : new WebForm('#addUserForm', {
                    first_name : null,
                    last_name : null,
                    username : null,
                    email: null,
                    roles: []
                }),
                roles: []
            }
        },
        computed: {
            
        },
        methods: {
            getRolesList() {
                let endpoint = '/admin/permission/roles/all';
                axios.get(endpoint).then(({data}) => {
                    let roles = data.map(l => {
                        let rol = l;
                        let d = {
                            id: rol.id,
                            name: rol.title,
                            label: `${rol.title}`,
                            match: `${rol.title} ${rol.description}`
                        }
                        return d;
                    });
                    this.roles = roles;
                });
            },
            register() {
                console.log(this.form);
                this.form.submit().then(response => {
                    let user = response.data;
                    this.$snotify.success(`El usuario ${user.first_name} fue registrado con exito!`);
                    $('#addUserModal').modal('hide');
                    this.$emit('added');
                }).catch(err => {
                    console.log(err);
                    $('#addUserModal').modal('hide');
                    this.$emit('added');
                });
            },
        },
        mounted() {

            this.getRolesList();
            let vm = this;
            $("#addUserModal").off("hidden.bs.modal");
            $("#addUserModal").on("hidden.bs.modal", function(){
                vm.$refs.taginput.clear();
                vm.form.reset();
                vm.form.clear();
            });
        }
    }
</script>

<style scoped>
    
    .subtitle {
        font-size: 0.9em;
        color: #a7a7a7;
        margin: 0.2em;
    }
    label {
        margin-bottom: 0.3rem
    }
    .toggle-password {
        top: 1px;
        right: 1px;
        background: #fff;
    }
</style>