
<script>

  import { ContentLoader } from "vue-content-loader"
import { debuglog } from 'util';

  export default {
    props: [
        'primary_color', 
        'secondary_color',
        'width_svg',
        'height_svg',
        ],
    data () {
      return {
        id: null
      }
    },
    components: {
      ContentLoader
    },
    mounted () {
      this.id = this._uid
    }
  }

</script>
<template>
    <content-loader class="loaderEmpty" :id="`loader-${id}`" :height="height_svg" :width="width_svg" :speed="1.5" :primaryColor="primary_color"  :secondaryColor="secondary_color" >
        <rect x="9" y="0" rx="4" ry="4" width="150" height="15" /> 
        <rect x="9" y="30" rx="4" ry="4" width="100" height="15" /> 
        <rect x="9" y="50" rx="4" ry="4" width="150" height="15" /> 
        <rect x="9" y="70" rx="4" ry="4" width="150" height="15" /> 
        <rect x="9" y="90" rx="4" ry="4" width="100" height="15" /> 
        <rect x="9" y="110" rx="4" ry="4" width="150" height="15" /> 
    </content-loader>
</template>
<style scoped>
    svg.loaderEmpty{
        /* background: #22306b; */
        z-index: 1;
        left: 0;
    }
</style>