`<template>
    <div>
        <div class="row section mx-2 my-1 p-2">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-building mr-1"></span> Requerimientos de Hotel
                        </label>
                    </div>
                </div>
                <div class="row" v-if="!quoteHotel.empty_quote">
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Pais</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteHotel.country.name">
                            </div>
                            <div class="form-group col-6">
                                <label class="w-100">Ciudad</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteHotel.city">
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Tipo de Hotel</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteHotel.hotel_category.name">
                            </div>
                            <div class="form-group col-3">
                                <label>Personas</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quoteHotel.n_peoples>1) ? quoteHotel.n_peoples + ' Personas' : quoteHotel.n_peoples + ' Persona' ">
                            </div>
                            <div class="form-group col-3">
                                <label>Habitaciones</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quoteHotel.n_rooms>1) ? quoteHotel.n_rooms + ' Habitaciones' : quoteHotel.n_rooms + ' Habitacion' ">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Fecha de Entrada </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteHotel.start_date).format('LL')">
                            </div>
                            <div class="form-group col-6">
                                <label>Fecha de Salida</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteHotel.end_date).format('LL')">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Comentarios sobre reserva de hotel</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteHotel.comments}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-12">
                                <h5 class="text-center my-4">Requerimiento Vacio</h5>
                                <label>Comentarios</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteHotel.comments}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------- -->
            <div class="col-md-7 border-left">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Soluciones de Alojamientos en Hotel
                        </label>
                        <span class="float-sm-right">
                            <button class="btn ml-3 btn-primary float-right btn-sm" :disabled="!owner"
                            data-toggle="modal" data-target="#modalNewHotelResponse">
                                Nueva Solucion
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row" v-if="hotelResponses && hotelResponses.length>0">
                    <div class="form-group col-12  mb-0 pb-0">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                        <th scope="col">#</th>
                                        <th scope="col">Hotel</th>
                                        <th scope="col">Huéspedes</th>
                                        <th scope="col">Habitaciones</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadComplete">
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center py-3">
                                                    <div class="spinner-border text-secondary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div>
                                                        <small>Cargando...</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item, index) in (hotelResponses) ? hotelResponses : null" 
                                        :key="index" :class="{'table-primary' : index==showResponse}">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">{{ (item.hotel != null && item.hotel.name) ? item.hotel.name : null }}</td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.length > 0) ? guestsOrRooms(item.body_json, 'guests') : "Ninguno" }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.length > 0) ? guestsOrRooms(item.body_json, 'rooms') : "Ninguna" }}
                                            </td>
                                            <td class="text-right">
                                                {{ (item.body_json && item.body_json.length > 0) ? '$ ' + (guestsOrRooms(item.body_json, 'sum')).toFixed(2) : "Ninguno" }}
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-info" @click="showResponse = index">
                                                    <span class="icon-view font-12"></span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger" :disabled="!owner"
                                                @click="onDeleteHotelResponse(index)">
                                                    <span class="icon-bin-2 font-12"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" v-else>
                    <div class="form-group align-middle text-center col-12 mb-0 pb-0 mt-5">
                        <p class="mb-0">Aun no se ha proporcionado soluciones</p>
                        <p class="mb-0"><small>"Haga click en
                            <b style="cursor:pointer;">Nueva Solucion</b> para agregar datos"
                        </small></p>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="hotelResponses">
                <hr v-show="showResponse!=null">
                <div class="row mt-1" v-show="showResponse!=null">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold mb-2">
                                    Detalle de Habitaciones
                                </label>
                                <button v-if="showResponse!=null && hotelResponses[showResponse].body_json" 
                                data-toggle="modal" data-target="#hotelRoomResponseModal" :disabled="!owner"
                                @click="showRoomResponse()" class="btn btn-primary float-right btn-sm">Nueva Habitacion</button>
                            </div>
                            <div class="col-12 mb-3">
                                <table class="table table-detail-flight table-sm table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                            <th scope="col"><span class="icon-bed-room"></span></th>
                                            <th scope="col">Habitacion</th>
                                            <th scope="col">Costos</th>
                                            <th scope="col">Descripcion Estadia</th>
                                            <th scope="col">Link</th>
                                            <th scope="col"><span class="icon-menu-5-bold"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="loadComplete && showResponse!=null && hotelResponses[showResponse].body_json && hotelResponses[showResponse].body_json.length > 0">
                                        <tr v-for="(body_array, index) in hotelResponses[showResponse].body_json" :key="index">
                                            <th scope="row"></th>
                                            <td class="text-right detail_hotel_room_response" style="min-width: 350px;">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p class="font-weight-bold">Tipo Habitacion:</p>
                                                        <p class="font-weight-bold">Capacidad:</p>
                                                        <p class="font-weight-bold">Entrada:</p>
                                                        <p class="font-weight-bold">Salida:</p>
                                                    </div>
                                                    <div class="col-7 text-left">
                                                        <!-- <button type="button" class="btn btn-secondary btn-sm btn-show-room">Ver</button> -->
                                                        <p> 
                                                            {{ ( body_array.hotel_room &&  body_array.hotel_room.type_room.title) ? body_array.hotel_room.type_room.title : null }}
                                                            {{ ( body_array.cant_room > 1) ? ' ( ' + body_array.cant_room + ' habitaciones )' : ' ( ' + body_array.cant_room + ' habitacion )' }}
                                                        </p>
                                                        <p> {{ ( body_array.hotel_room && body_array.hotel_room.capacity) ? ( (body_array.hotel_room.capacity > 1) ? body_array.hotel_room.capacity + ' Personas' : body_array.hotel_room.capacity +' Persona' ) : null }} por habitacion</p>
                                                        <p> {{ (body_array.entry_format) ? $moment(body_array.entry_format).format('lll') : null }}</p>
                                                        <p> {{ (body_array.out_format) ? $moment(body_array.out_format).format('lll') : null }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-right" style="min-width: 350px;">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <p class="font-weight-bold">Precio:</p>
                                                        <p class="font-weight-bold">Impuestos:</p>
                                                        <p class="font-weight-bold">Utilidad:</p>
                                                        <p class="font-weight-bold">Total:</p>
                                                    </div>
                                                    <div class="col-6 text-left">
                                                        <p class="font-weight-bold text-secondary">
                                                            $ {{ (body_array.cost) ? parseFloat(body_array.cost).toFixed(2) : null }}
                                                        </p>
                                                        <p class="font-weight-bold text-secondary">
                                                            $ {{ (body_array.taxes) ? parseFloat(body_array.taxes).toFixed(2) : null }}
                                                        </p>
                                                        <p class="font-weight-bold text-success">
                                                            $ {{ (body_array.utility) ? parseFloat(body_array.utility).toFixed(2) : null }}
                                                        </p>
                                                        <p class="font-weight-bold text-primary">
                                                            $ {{ parseFloat(body_array.cost + body_array.taxes + body_array.utility).toFixed(2) }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {{ body_array.description }}
                                            </td>
                                            <td class="text-center" style="min-width: 150px;">
                                                <a :href="body_array.url" target="_blank" class="font-weight-bold">Ir al link...</a>
                                            </td>
                                            <td width="75" class="text-right" style="min-width: 50px;">
                                                <div class="btn-group" role="group" aria-label="Basic example">
                                                    <button type="button" class="btn btn-sm btn-light"
                                                    data-toggle="modal" data-target="#hotelRoomResponseModal" :disabled="!owner"
                                                    @click="showRoomResponse(index)">
                                                        <span class="icon-pencil-edith font-12"></span>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light" :disabled="!owner"
                                                    @click.prevent="onDeleteRoomResponse(index)">
                                                        <span class="icon-bin-2 font-12"></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr> 
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="9" class="text-center my-3 py-3">
                                                <div class="form-group align-middle text-center col-12 mb-0 pb-0">
                                                    <p class="mb-0">Aun no se han agregado habitaciones</p>
                                                    <p class="mb-0"><small>"Puede agregar las <b>habitaciones</b> aqui"</small></p>
                                                    <button class="btn btn-sm btn-primary mt-2" data-toggle="modal" @click="showRoomResponse()"  data-target="#hotelRoomResponseModal">
                                                        Nueva Habitacion
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 v-show="showResponse!=null" class="font-weight-bold text-center  mt-2" style="font-size: 12px;">
                    <span style="cursor:pointer;" @click="showResponse = null">
                        Cerrar Detalles<span class="ml-1 icon-up-arrow" style="font-size: 12px;"></span>
                    </span>
                </h6>
            </div>
        </div>
        <new-hotel-response 
        :quoteHotel="quoteHotel"
        v-on:saveNewHotelResponse="saveNewHotelResponse"></new-hotel-response>

        <hotel-room-response-modal
        :HotelResponseModal="varHotelResponseModal"
        :roomIndex="roomIndex"
        v-on:saveNewHotelResponse="saveNewHotelResponse"
        v-on:closeHotelRoomResponse="closeHotelRoomResponse()">
        </hotel-room-response-modal>
    </div>
</template>
<script>
import NewHotelResponse from './NewHotelResponse'
import HotelRoomResponseModal from './HotelRoomResponseModal'
export default {
    props: {
        quoteHotel: {
            type: [Object],
            default () {
                return false
            }
        },
        lastUpdateLocal: {
            type: Number
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    components: {
        NewHotelResponse,
        HotelRoomResponseModal,
    },
    data() {
        return {
            loadingItems: {
                hotels:          false,
                rooms:           false
            },
            loadComplete:       false,
            showResponse:       null,
            showResponseBackup: null,
            hotelResponses:     null,
            roomIndex:          null,
            varHotelResponseModal: null,
            hotelBank:          [],
            hotelRoomBank:      [],
        };
    },
    watch: {
        lastUpdateLocal: function(val, oldVal) {
            let vm = this;
            if (vm.lastUpdateLocal != null) {
                vm.load_response_hotel();
            }
        },
        loadComplete: function(val, oldVal) {
            let vm = this;
            if (vm.loadComplete) {
                setTimeout(() => {
                    if (vm.showResponseBackup!=null) {
                        vm.showResponse         = vm.showResponseBackup;
                        vm.showResponseBackup   = null;
                    }
                },300);
            }
        },
    },
    methods: {
        load_response_hotel() {
            let vm = this;
            if (vm.quoteHotel && vm.quoteHotel.quote_hotel_response) {
                let temp = [];
                vm.quoteHotel.quote_hotel_response.forEach((element, index) => {
                    try {
                        if (element.body_json && JSON.parse(element.body_json)) {
                            element.body_json = JSON.parse(element.body_json);
                        }
                        temp.push(element);
                    } catch (e) {
                        console.log("error JSON.parse(element.body_json)");
                        temp.push(element);
                    }
                });
                vm.hotelResponses = temp;
                vm.setHotelsAndRoomsBank();
                return true;
            }
            return null;
        },
        async setHotelsAndRoomsBank() {
            let vm = this;
            vm.loadComplete = false;
            vm.loadingItems.hotels = false;
            await vm.hotelResponses.reduce(async (promise, hotel) => {
                await promise;
                let find = null;

                await vm.hotelBank.reduce(async (promise, itemHotelBank) => {
                    await promise;
                    if (itemHotelBank.id == hotel.hotel_id) {
                        find = itemHotelBank;
                    }
                }, Promise.resolve());

                if (find) {
                    hotel.hotel = find;
                } else {
                    const data = await axios.get(`/admin/hotels/${hotel.hotel_id}`);
                    if (data.data) {
                        find = data.data;
                        hotel.hotel = find;
                        vm.hotelBank.push(find);
                    } else {
                        console.log(`Ups... Error al buscar: /admin/hotels/${hotel.hotel_id}`);
                    }
                }

                let indexBody = 0;
                if (hotel.body_json && hotel.body_json.length > 0) {
                    await hotel.body_json.reduce(async (promise, itemBody) => {
                        await promise;
                        let findRoom = null;

                        await vm.hotelRoomBank.reduce(async (promise, itemHotelRoomBank) => {
                            await promise;
                            if (itemHotelRoomBank.id == itemBody.hotel_room_id) {
                                findRoom = itemHotelRoomBank;
                            }
                        }, Promise.resolve());

                        if (findRoom) {
                            itemBody.hotel_room = findRoom;
                        } else {
                            // fix
                            const dataRoom = await axios.get(`/admin/hotels/hotel_rooms/find/${itemBody.hotel_room_id}`);
                            if (dataRoom.data && dataRoom.data[0]) {
                                findRoom = dataRoom.data[0];
                                try {
                                    if (findRoom.beds && JSON.parse(findRoom.beds)) {
                                        findRoom.beds = JSON.parse(findRoom.beds);
                                    }
                                } catch (e) {
                                    console.log("error JSON.parse(findRoom.beds)");
                                }
                                itemBody.hotel_room = findRoom;
                                vm.hotelRoomBank.push(findRoom);
                            } else {
                                console.log(`Ups... Error al buscar: /admin/hotels/hotel_rooms/find/${hotel.hotel_id}`);
                            }
                        }
                        indexBody++;
                    }, Promise.resolve());
                }
            }, Promise.resolve());
            vm.loadComplete = true;
            console.log("Complete:  ", vm.hotelResponses);
            vm.loadingItems.hotels = true;
        },
        guestsOrRooms(obj = null, type = 'guests') {
            let temp = {"guests" : 0, "rooms" : 0, "sum": 0};
            if(obj) {
                obj.forEach(element => {
                    temp["rooms"]       += (element.cant_room) ? element.cant_room : 0;
                    temp["guests"]      += (element.hotel_room && element.hotel_room.capacity) ? element.cant_room * element.hotel_room.capacity : 0;
                    temp["sum"]         += element.cost + element.taxes + element.utility;
                });
                return temp[type];
            } else {
                return null;
            }
        },
        saveNewHotelResponse(obj) {
            let vm = this;
            vm.loadComplete = false;
            vm.$emit("saveNewHotelResponse", obj);
        },
        onDeleteHotelResponse(index) {
            let vm = this;
            $.confirm.delete(
                'Eliminar Solucion de Alojamiento',
                `¿Está seguro que desea eliminar esta solucion?`,
                function(){
                    vm.deleteHotelResponse(index);
                }
            )
        },
        async deleteHotelResponse(index) {
            let vm = this;
            vm.loadComplete = false;
            await axios.post('/admin/quotes/hotels/delete', {id: vm.hotelResponses[index].id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                }
                if(data.success) {
                    let obj = data.success;
                    vm.$snotify.success('Solucion borrada con exito.');
                    vm.saveNewHotelResponse(obj);
                }
                return;
            });
        },
        showRoomResponse(index = null) {
            let vm = this;
            vm.roomIndex = index;
            vm.varHotelResponseModal = vm.hotelResponses[vm.showResponse];
        },
        closeHotelRoomResponse() {
             let vm = this;
            vm.roomIndex = null;
            vm.varHotelResponseModal = null;
        },
        onDeleteRoomResponse(index) {
            let vm = this
            let title_msj=' Estadia ';
            $.confirm.delete( 
                'Eliminar ' + title_msj,
                `¿Está seguro que desea eliminar <u>Estadia</u>?`, 
                function(){
                    vm.deleteItem(index);
                }
            )
        },
        deleteItem (index) {
            let vm = this;

            let tempBody = null;

            if ( vm.hotelResponses[vm.showResponse] && vm.hotelResponses[vm.showResponse].body_json!=null && vm.hotelResponses[vm.showResponse].body_json.length>0) {
                tempBody = JSON.parse(JSON.stringify(vm.hotelResponses[vm.showResponse]));
                tempBody.body_json.forEach(element => {
                    (element.hotel_room)    ? delete element.hotel_room : null ;
                });
            }
            
            if (tempBody.body_json != null && tempBody.body_json[index]) {
                tempBody.body_json.splice(index, 1);
                tempBody.body_json = JSON.stringify(tempBody.body_json);
            }

            (tempBody.hotel) ? delete tempBody.hotel : null ;
            
            axios.post('/admin/quotes/hotels/save', tempBody).then(response => {
                let data = response.data;
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                    return;
                }

                if(data.success) {
                    vm.$snotify.simple('Dato removido.');
                    this.loadComplete = false;
                    this.showResponseBackup = this.showResponse;
                    this.showResponse = null;
                    let obj = data.success;
                    this.saveNewHotelResponse(obj);
                    return;
                }

                vm.$snotify.info('Se ha enviado solicitud')
                return;
            }).catch(err => {
                console.log(err);
            });
        },
    },
    computed: {
        
    },
    mounted() {
        if(this.lastUpdateLocal) {
            this.load_response_hotel();
            // /admin/hotels/hotel_rooms/allbyid/
        }
    }
}
</script>
<style scoped>
    .table-detail-flight p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .font-12 {
        font-size: 12px;
    }
    .option-route:hover .btn-option-route{
        opacity: 1;
    }
    .btn-option-route{
        opacity: 0;
        transition: 0.3s;
    }
    .btn-show-room{
        position: absolute;
        right: 0px;
        font-size: 10px;
    }
</style>