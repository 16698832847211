<template>
    <div>
        <div v-if="quoteHotel!=null && quoteHotel.id" class="modal fade" @submit.prevent="saveNewHotelResponse()" id="modalNewHotelResponse">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formNewHotelResponse" autocomplete="off"  novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0 pb-0">
                                <span>Nueva solucion de Alojamiento</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="hotel_id" class="required" style="width: 100%; margin-bottom: 3px;">
                                        Seleccione Hotel
                                        <a :href="'/packages/hotels'" target="_blank" class="float-right btn btn-secondary btn-sm" title="Agregar Nueva Habitacion">
                                            <span class="icon-add-2"></span>
                                        </a>
                                    </label>
                                    <v-select placeholder="Buscar Hotel " v-model="hotel" :options="hotels" 
                                    label="name" :filterable="false" @search="onSearchHotel" selectOnTab>
                                        <template slot="no-options">Sin resultados...</template>
                                        <template slot="option" slot-scope="option">
                                            <img :src="`https://www.countryflags.io/${option.city.state.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="option.code" class="mr-1"> 
                                            {{ option.name }} - {{ option.city.state.country.name }}, {{ option.city.state.name }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <img :src="`https://www.countryflags.io/${option.city.state.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="option.code" class="mr-1"> 
                                            {{ option.name }} - {{ option.city.state.country.name }}, {{ option.city.state.name }}
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" id="btnChangeHotel" class="btn btn-primary" :class="[{'disabled': hotel==null || sending }, {'btn-loading': sending}]"> 
                                <span>Guardar</span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        quoteHotel: {
            type: [Object],
            default () {
                return false
            }
        }
    },
    data() {
        return {
            hotels                  : [],
            hotel                   : null,
            sending                 : false,
        };
    },
    methods: {
        onSearchHotel(search, loading) {
            if(_.isEmpty(search)){
                // this.airlines = this.favoriteAirlines
                loading(false);
                return
            }
            loading(true);
            this.searchHotel(loading, search, this);
        },
        searchHotel: _.debounce((loading, search, vm) => {
            axios.get(`/admin/hotels/${escape(search)}`).then(res => {
                console.log("RES:: ", res);
                vm.hotels = res.data
                loading(false);
            });
        }, 800),
        saveNewHotelResponse() {
            let vm = this;
            vm.sending = true;
            axios.post('/admin/quotes/hotels/new', {quote_hotel_id: vm.quoteHotel.id, hotel_id: vm.hotel.id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if(data.success) {
                    vm.$snotify.success('Nueva solucion agregada.');
                    let obj = data.success;
                    this.$emit("saveNewHotelResponse", obj);
                    $("#modalNewHotelResponse").modal("hide");
                }
                return;
            }).finally(() => vm.sending = false);
        },
        getHotels(){
            let vm = this;
            vm.loadGetHotels = true;
            let endpoint = '';
            if(vm.thisCity && vm.myPackage.city_id) {
                vm.thisCity = true;
                endpoint = `/admin/hotels/allbyid/${vm.myPackage.city_id}`;
            } else {
                vm.thisCity = false;
                endpoint = `/admin/hotels/all`;
            }

            axios.get(endpoint).then(({data}) => {
                let hotels = data.map(l => {
                    let hotel = l
                    let d = {
                        id: hotel.id,
                        name: `${hotel.name}`,
                        label: `${hotel.name}`
                    }
                    return d;
                });
                vm.hotels = hotels;

                vm.hotels.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });
            }).catch(err => {
                vm.$snotify.warning('Ocurrio un error al obtener los hoteles');
            }).finally(() => {
                vm.loadGetHotels = false;
            });
        },
    },
    mounted() {
        let vm = this;
        $('#modalNewHotelResponse').off('hidden.bs.modal');
        $('#modalNewHotelResponse').on('hidden.bs.modal', function () {
            vm.hotel = null;
        });
    }
};
</script>
            