<template>
    <div class="picture-content mx-auto rounded-circle border shadow-sm" @click.stop="">
        <img class="user-picture mb-2" :src="userImage" onerror="this.src='/img/none.png';">
        <div v-if="isEnabled" class="btn-change-user-picture position-absolute text-center">
            <button class="btn-transparent" @click.stop="pickImage"><span class="icon-camera"></span></button>
        </div>
        <!--<button class="btn-change-user-picture position-absolute" >
            
        </button-->
        <input type="file" :ref="'img' + uid" v-show="false" @change="imageSelected" @click.stop="" accept="image/jpg,image/jpeg,image/png">
    </div>
</template>

<script>
    'use strict'
    export default {
        props: {
            image: String,
            uid: Number,
            sessid: Number,
            isEnabled: Boolean
        },
        data () {
            return {
                newImage: null
            }
        },
        methods: {
            pickImage: function () {
                this.$refs[('img' + this.uid)].click()
            },
            imageSelected: function (e) {
                let formData = new FormData()
                formData.append('image', e.target.files[0])
                formData.append('uid', this.uid)
                axios
                    .post('/admin/user/upload/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.newImage = response.data.image_url
                        this.$emit('change', this.newImage)
                        this.$snotify.success('Se actualizó la imagen del usuario.');
                        if(this.sessid !== this.uid) return
                        this.$store.dispatch('auth/updateUserImage', {
                            image: this.newImage || '/img/none.png'
                        })
                    })
                .catch(error => {
                    //console.log(error.response)
                })                
            }
        },
        computed: {
            userImage: function () {
                return this.newImage || this.image
            }
        }
    }
</script>

<style scoped>
    .picture-content {
        position: relative;
        overflow: hidden;
    }
    .picture-content,
    .user-picture{
        width: 120px;
        height: 120px;
    }

    .picture-content:hover > .btn-change-user-picture{
        opacity: 1;
        top: 60px;
    }

    .btn-change-user-picture:focus {outline:0;}
    .btn-change-user-picture{
        opacity: 0;
        background-color: rgba(0,0,0,0.5);
        transition: 0.3s;
        top: 120px;
        width: 120px;
        height: 120px;    
    }
    .btn-transparent {
        outline: none;
        color: #fff;
        font-size: 2em;    
        cursor: pointer;        
    }

    .btn-transparent:hover {
        background: transparent;
    }
</style>