import store  from '~/store'
import router from '~/router'

function logout() {
    store.dispatch('auth/logout').then((response) => {
        router.push({ name: 'auth-login' })
    });
}

function showToast(vm, action, message, title = null){

    vm.$snotify.setDefaults({
        global: {
            preventDuplicates: true,
        }
    });

    let toast = vm.$snotify[action](message, title);
    toast.on('hidden', (toast) => { 
        vm.$snotify.setDefaults({
            global: {
                preventDuplicates: false,
            }
        });
    });
}

export default {
    interceptResponse: function ($vm) {
        let vm = new $vm();
        axios.interceptors.response.use(function (response) {

            if(typeof response.data !== 'object' )
                return response

            let result = response.data;
            if (response.status == 203 && typeof result.user_request_status !== 'undefined'){
                let action = 'actualizar';
                switch (result.data.type) {
                    default:
                    case 'CREATE':
                        action = 'crear'
                        break;
                    case 'UPDATE':
                        action = 'actualizar'
                        break;
                    case 'DELETE':
                        action = 'eliminar'
                        break;
                }

                let message = result.user_request_status == 'SUCCESS' ? 
                                `Se ha enviado una solicitud para ${action} el registo <u>${result.data.title}</u>` : 
                                `Actualmente ya existe una solicitud para ${action} el registo <u>${result.data.title}</u>`;

                vm.eventHub.$emit('user_alert', {status: result.user_request_status, title: `Solicitudes`, text: message});
            }

            return response;

        }, function (error) {

            if (typeof error.response !== 'undefined'){
                switch (error.response.status) {
                    case 401:
                        if(error.response.data.hasOwnProperty('session_expired') && error.response.data.session_expired)
                            logout()
                        break;
                    case 419:
                        showToast(vm, 'warning', "Sesión inactiva, actualizando... ")
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        break;
                    case 500:
                        showToast(vm, 'error', "Algo ha salido mal, al parecer su petición no ha podido completarse.", "Error Inesperado")
                        break;
                }
            }
            return Promise.reject(error);
        });
    }
}