<template>
	<div class="control-root">
		<div class="token-container">
			<span class="badge badge-primary mr-1" v-if="selection.length > 0" v-for="(item, index) in selection" :key="item[identifier]">{{ item[label] }} 
				<button type="button" class="close" aria-label="Remove" @click.prevent="removeTag(item[identifier])">
          			<span aria-hidden="true">&times;</span>
        		</button>
        	</span>
			<input type="text" class="neutral-input" @focus="activeParent" @blur="activeParent(false)">
		</div>
		<div class="dropdown token-dropdown">
		  	<div class="dropdown-menu token-dropdown-menu" aria-labelledby="dropdownMenuButton">
		  		<h6 v-if="title && title !== '' " class="dropdown-header">{{ title }}</h6>
		    	<a class="dropdown-item" v-if="list.length > 0" v-for="item in list" :key="item[identifier]" @click.prevent="selectTag(item)" :data-id="item[identifier]">
		    		{{ item[label] }}
		    	</a>
		  	</div>
		</div>
	</div>
</template>

<script>
import { debuglog } from 'util';
	export default {
		props: {
			identifier: {
				type: String,
				required: true
			},
			label: {
				type: String,
				required: true
			},
			dataList: {
				type: Array,
				required: true,
				default: function () {
					return []
				}
			},
			value: {
				type: Array,
				required: true,
				default: function () {
					return []
				}
			},
			title: {
				type: String
			}
		},
		data: function () {
			return {
				selection: this.value || [],
				list: this.dataList || []
			}
		},
		methods: {
			activeParent: (state = true) => {
				if(state){
					$('.token-container').addClass('active')
					$('.token-dropdown, .token-dropdown-menu').addClass('show')
					return
				}
				$('.token-container').removeClass('active')
			},
			removeTag: function (key) {
				let vm = this
				let index = this.selection.findIndex(element => element[vm.identifier] === key )
				if(index < 0) return

				let item = this.selection[index];
				this.selection.splice(index, 1);
				this.toggleItemActive(key)
				$('.token-dropdown, .token-dropdown-menu').removeClass('show')
				this.$emit('removeTag', item)
			},
			selectTag: function (item) {
				let vm = this
				$('.dropdown, .dropdown-menu').removeClass('show')
				if(this.selection.length <= 0) {
					this.selection.push(item)
					this.$emit('addTag', item)
					return
				}
				let finded = this.selection.find(element => element[vm.identifier] === item[vm.identifier] )
				if(finded) return
				this.selection.push(item)
				this.$emit('addTag', item)
			},
			toggleItemActive: function (key) {
				let list = $('.token-dropdown-menu')
				let item = list.find(`a[data-id=${key}]`)

				/*if(item.hasClass('active')){
					item.removeClass('active')
					return
				}
				item.addClass('active')*/
				item.toggleClass('active');
			}
		},
		watch: {
			selection: function (newVal) {
				this.$emit('input', this.selection)
			},
			value: function (newVal) {
				let vm = this
				this.selection = newVal || []
				this.selection.forEach(element => {
					$('.token-dropdown-menu').find(`a[data-id=${element[vm.identifier]}]`).addClass('active')
				})
			},
			dataList: function (newVal) {
				this.list = newVal || []
			}
		}
	}
</script>

<style scoped>
	.control-root {
		padding: 0 0.75rem;
    	align-items: center;
    	display: flex;
	}
	.neutral-input {
		margin: 0;
		border: none;
		flex: 1;
		min-width: 0px;
	}
	.neutral-input:focus {
		outline: none;
	}
	.token-container {
		display: flex;
		flex-direction: row;
		flex: 1;
		overflow: hidden;
	}
	.token-container.active{
		border-color: #03A9F4 !important;
	}
	.token-dropdown {
		position: absolute;
    	top: 64px;
	}
	.badge {
		font-size: 0.8rem;
		font-weight: normal;
		line-height: 1.4
	}
	.close {
		font-size: 1rem;
		color: #fff;
		opacity: 0.75;
		margin-left: 4px;
		font-weight: normal;
	}
	.close:hover,
	.close:focus {
		opacity: 1 !important;
		color: #fff !important;
	}
</style>