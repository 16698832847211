<template>
    <div>
        <div class="modal fade" id="flightTicketsModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formFlightTickets" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                        <div class="modal-header">
                            <h6 class="font-weight-bold mb-0 pb-0">
                               <span>Boletos Aereos</span>
                                <p class="mb-0">
                                   <small>Ingrese cada tipo de boleto aereo</small>
                                </p>
                            </h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p class="mb-0">
                                <small class="badge badge-secondary">Debe ingresar los valores totales de los boletos</small>
                            </p>
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label for="" class="required">Tipo Pasajero</label>
                                    <select class="form-control" v-model="ticket.type">
                                        <option value="adult">Adulto</option>
                                        <option value="elderly">Adulto Mayor</option>
                                        <option value="child">Adolecente</option>
                                        <option value="infant">Infante</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="required">Cantidad</label>
                                    <input type="number" min="1" max="9"  v-model.number="ticket.cant" class="form-control">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="required">Precio Base</label>
                                   <vue-numeric currency="$" class="form-control" separator="," v-model.number="ticket.base"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="required">Utilidad</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="ticket.utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label for="" class="">Impuestos</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="ticket.taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="">Comision</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="ticket.commission"
                                    :min="0" :max="99999" :empty-value="0" placeholder=""
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="">Precio / Boleto</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="promTicket"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" disabled
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="" class="">Precio Total</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="totalTicket"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" disabled
                                    :precision="2" :decimal-separator="'.'"
                                    name="base"></vue-numeric>
                                </div>
                            </div>
                            <p class="mb-0">
                                <small class="badge badge-secondary">
                                    Los boletos similares ingresados seran reescritos.
                                </small>
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" :disabled="!validateSave">
                                <span>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueNumeric from 'vue-numeric';
export default {
    props: {
        flightResponses : null,
        flightTicket: null
    },
    components: {
        VueNumeric
    },
    data() {
        return {
            keys: ['one_way_route','return_route'],
            subKeys: ['takeoff','landing'],
            formFlightTickets: new WebForm('#formFlightTickets', {
                id: null,
                quote_flight_id: null,
                body_json: null
            }),
            ticket: {
                type: 'adult',
                cant: 1,
                base: 0,
                taxes: 0,
                commission: 0,
                utility: 0,
            },
        }
    },
    watch: {
        flightResponses: function(val, oldVal) {
            if (this.flightResponses == null) {
                this.formFlightTickets.reset();
            } else {
                this.formFlightTickets.id = this.flightResponses.id;
                this.formFlightTickets.quote_flight_id = this.flightResponses.quote_flight_id;
                this.formFlightTickets.pnr_code = this.flightResponses.pnr_code;
                this.formFlightTickets.body_json = this.flightResponses.body_json;
            }
        },
        flightTicket: function(val, oldVal) {
            if (this.flightTicket != null) {
                let vm = this;
                if (vm.flightResponses.body_json && vm.flightResponses.body_json.tickets && vm.flightResponses.body_json.tickets[vm.flightTicket]) {
                    let temp = vm.flightResponses.body_json.tickets[vm.flightTicket];
                    vm.ticket.type = vm.flightTicket;
                    vm.ticket.cant = temp.cantTickets;
                    vm.ticket.base = temp.base;
                    vm.ticket.utility = temp.utility;
                    vm.ticket.taxes = temp.taxes;
                    vm.ticket.commission = temp.commission;
                }
            }
        }
    },
    methods: {
        onSaveItem() {
            let vm = this;
            let other = {
                base:         vm.ticket.base,
                taxes:        vm.ticket.taxes,
                commission:   vm.ticket.commission,
                utility:      vm.ticket.utility,
                cantTickets:  vm.ticket.cant
            };

            let tempFlightResponses = JSON.parse(JSON.stringify(this.flightResponses));

            if ( (tempFlightResponses.body_json == null) || (tempFlightResponses.body_json !=null && (tempFlightResponses.body_json.tickets)==null)) {
                if(tempFlightResponses.body_json == null) {
                    tempFlightResponses.body_json = {};
                }
                tempFlightResponses.body_json.tickets = {};
                tempFlightResponses.body_json.tickets[vm.ticket.type] = other;
            } else if (tempFlightResponses.body_json !=null && tempFlightResponses.body_json.tickets) {
                tempFlightResponses.body_json.tickets[vm.ticket.type] = other;
            } else {
                vm.$snotify.error('Ups... Algo a salido mal');
                return;
            }

            if ( tempFlightResponses.body_json !=null && tempFlightResponses.body_json.one_way_route && tempFlightResponses.body_json.one_way_route.length>0) {
                 tempFlightResponses.body_json.one_way_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            if ( tempFlightResponses.body_json !=null && tempFlightResponses.body_json.return_route && tempFlightResponses.body_json.return_route.length>0) {
                 tempFlightResponses.body_json.return_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }

            tempFlightResponses.body_json = JSON.stringify(tempFlightResponses.body_json);

            vm.formFlightTickets.post('/admin/quotes/flights/save', {id: tempFlightResponses.id, quote_flight_id: tempFlightResponses.quote_flight_id, body_json: tempFlightResponses.body_json }).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    $("#flightTicketsModal").modal("hide");
                    return;
                }
                if(data.success) {
                    let obj = data.success;
                    this.$emit("saveFlightsTicketModal", obj);
                    let msj = (vm.flightTicket != null || (vm.flightResponses.body_json != null && vm.flightResponses.body_json.tickets != null && vm.flightResponses.body_json.tickets[vm.ticket.type]!=null) ) ? 'Boleto modificado con exito...' : 'Boleto agregado con exito...';
                    vm.$snotify.success(msj);
                }
                vm.formFlightTickets.clear();
                vm.formFlightTickets.reset();
                $("#flightTicketsModal").modal("hide");
            }).catch(err => {
                console.log(err);
            });
        },
    },
    computed: {
        validateSave() {
            let vm = this;
            let response = true;
            response = (vm.ticket.cant > 0) ? response : false;
            response = (vm.ticket.base > 0) ? response : false;
            response = (vm.ticket.utility > 0) ? response : false;
            return response;
        },
        promTicket() {
            let vm = this;
            let val = 0;
            if(vm.ticket.cant>0) {
                let base        = parseFloat(vm.ticket.base || 0);
                let taxes       = parseFloat(vm.ticket.taxes || 0);
                let commission  = parseFloat(vm.ticket.commission || 0);
                let utility     = parseFloat(vm.ticket.utility || 0);
                let cant        = parseFloat(vm.ticket.cant || 0);
                val = (base + taxes + commission + utility) / cant;
            }
            return val;
        },
        totalTicket() {
            let vm = this;
            let val = 0;
            if(vm.ticket.cant>0) {
                let base        = parseFloat(vm.ticket.base || 0);
                let taxes       = parseFloat(vm.ticket.taxes || 0);
                let commission  = parseFloat(vm.ticket.commission || 0);
                let utility     = parseFloat(vm.ticket.utility || 0);
                let cant        = parseFloat(vm.ticket.cant || 0);
                val = base + taxes + commission + utility;
            }
            return val;
        }
    },
    mounted() {
        let vm = this;
        $("#flightTicketsModal").off("hidden.bs.modal");
        $("#flightTicketsModal").on("hidden.bs.modal", function() {
            vm.ticket = {
                type: 'adult',
                cant: 1,
                base: 0,
                taxes: 0,
                commission: 0,
                utility: 0,
            },
            vm.formFlightTickets.clear();
            vm.formFlightTickets.reset();
            vm.$emit("closeFlightTicketsModal");
        });
    }
}
</script>