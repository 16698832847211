<template>
    <div>
        <div class="modal fade" id="NewQuoteModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formQuote" autocomplete="off" @submit.prevent="onSaveQuote()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span>Nueva Cotización</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="name" class="required">Nombre cliente</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formQuote.customer_name" name="name">
                                </div>
                                <div class="form-group col-6">
                                    <label for="name" class="">Correo cliente</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Correo" v-model="formQuote.customer_email" name="email">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="country_id" class="required">Pais</label>
                                    <v-select v-model="country" name="country_id" 
                                    @search="searchCountry" ref="vSelectCountry" 
                                    placeholder="Seleccione Pais" selectOnTab
                                    :options="countries" required="required">
                                        <template v-slot:option="option">
                                                <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="name" class="required">Telefono</label>
                                    <VuePhoneNumberInput v-model="phone" ref="phone" v-on:update="loadPhone" :default-country-code="countryCode"/>
                                </div>
                                <div class="form-group col-md-9">
                                    <label for="description">Notas o comentarios</label>
                                    <textarea type="text" class="form-control" rows="4" placeholder="Ingrese Descripción" v-model="formQuote.customer_comments" name="description" ></textarea>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="stars" class="required">Idioma</label>
                                    <select class="form-control"  v-model="formQuote.language_id" name="language_id">
                                        <option value="15">Español</option>
                                        <option value="7">Ingles</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'marketing-quotes-index'" > 
                                <span>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import tagInput from '~/components/tagInput';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        props: {
                Hotel: {
                    type : [Object, null],
                    default: null,
                },
                HotelCategoryNew: {
                    type: [Object, null],
                    default: null,
                },
                StateNew: {
                    type: [Object, null],
                    default: null,
                },
                CityNew: {
                    type: [Object, null],
                    default: null,
                },
                HotelServiceNew: {
                    type: [Object, null],
                    default: null,
                }
            },
        components: {
            tagInput,
            VuePhoneNumberInput,
        },
        data() {
            return {
                searchDelay                 : null,
                phone                       : null,
                countryCode                 : null,
                country                     : null,
                formQuote: new WebForm('#formQuote', {
                    customer_name           : '',
                    customer_email          : null,
                    language_id             : 15,
                    customer_comments       : null,
                    phone_number            : null,
                    country_code            : null,
                    country_calling_code    : null,
                }),
            }
        },
        watch: {
            country: function(val, oldVal) {
                if (!_.isEmpty(this.country)) {
                    let code = this.country.code;
                    this.countryCode = code;
                }
            },
        },
        methods: {
            loadPhone(data) {
                this.formQuote.phone_number         = data.phoneNumber;
                this.formQuote.country_code         = data.countryCode;
                this.formQuote.country_calling_code = data.countryCallingCode;
            },
            // funcion para buscar paises mientras se escribe
            searchCountry(search, loading) {
                if (search.length>2 ) {
                    loading(true);
                    const vm = this;
                    clearInterval(vm.searchDelay);
                    vm.searchDelay = setInterval(function(){
                        clearInterval(vm.searchDelay);
                        axios.get(`/admin/location/countries/${search}`).then((response) => {
                            if (response.data) {
                                let countries = response.data.map(y => {
                                    let country = y;
                                    let d = {
                                        id      :       country.id,
                                        code    :       country.short_iso_code,
                                        name    :       `${country.name} (${country.native_name})`,
                                        label   :       `${country.name} (${country.native_name})` 
                                    }
                                    return d;
                                });
                                vm.countries = countries;
                            } else {
                                console.log('No se pudieron cargar los paquetes');
                            }
                            loading(false);
                        });
                    }, 500);
                }
            },
            onSaveQuote() {
                let vm = this
                // vm.formQuote.address = $('#hotel_address').val();
                if(vm.city && vm.city.id) {
                    vm.formQuote.city_id = vm.city.id;
                }
                if(vm.state && vm.state.id) {
                    vm.formQuote.state_id = vm.state.id;
                }
                if(vm.country && vm.country.id) {
                    vm.formQuote.country_id = vm.country.id;
                }
                if(vm.hotel_category && vm.hotel_category.id) {
                    vm.formQuote.hotel_category_id = vm.hotel_category.id;
                }

                let tipo = (vm.formQuote.id != null) ? 1 : 2;
                if(this.formQuote.id != null) {
                    $.confirm({
                        title: `Editar Hotel`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {
                                
                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (type) {
                let vm = this;
                
                vm.formQuote.post('/admin/hotels/save').then(response => {
                    let data = response.data;
                    this.formQuote.id = null;

                    if (data.user_request_status) {
                        $('#NewQuoteModal').modal('hide');
                        return;
                    }

                    let snotify_message = type != 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);

                    let obj = data.success;
                    vm.$emit('reloadHotel', obj);
                    $('#NewQuoteModal').modal('hide');
                    return;
                });
            }
        },
        async mounted() {
           let vm = this

            this.$nextTick(() => {
                $('#NewQuoteModal').off('hidden.bs.modal');
                $('#NewQuoteModal').on('hidden.bs.modal', function () {
                    vm.$refs.taginput.clear();
                    vm.phone        = null;
                    vm.countryCode  = null;
                    vm.formQuote.clear();
                    vm.formQuote.reset();
                    vm.$emit('closeHotelModal');
                });

                $('form').on('keyup keypress', function(e) {
                    var keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                });

                
            });
        }
    }
</script>