<template>
    <div class="row justify-content-center mx-0">
        <div class="col col-12 mt-3 mt-lg-0 py-2 px-0 section">
            <div class="d-flex px-2">
                <div>
                    <h5 class="font-weight-bold mb-0">Categorias de Atracciones Turisticas</h5>
                    <p class="text-muted mb-1">Lista de Categorias de Atracciones Turisticas</p>
                </div>
                <div class="ml-auto">
                    <button class="btn btn-secondary center-content d-inline-block"  @click.prevent='goBack()'>
                        <span class="icon-left-arrow mr-2"></span> Volver
                    </button>
                    <button v-can:create="'packages-attractions-index'" class="btn btn-primary center-content d-inline-block" data-toggle="modal" data-target="#addAttractionCategory" >
                        <span class="icon-add-2 mr-2"></span> Nueva Categoria
                    </button>
                </div>
            </div>
            
            <attraction-category-modal
            :AttractionCategory="AttractionCategory"
            v-on:closeAttractionCategoryModal="clearAttractionCategoryVar()"
            v-on:reloadAttractionCategoryModal="reloadAttractionCategory">
            </attraction-category-modal>

            <data-table :ajax="{ url: '/admin/attractions/categories/all/dt' }" :scrollY="'calc(100vh - 350px)'" ref="attractionCategoryTable" :serverSide="true" :columns="dt.columns()"></data-table>

        </div>
    </div>
</template>

<script>
    'use strict'
    import dataTable from '~/components/dataTable'
    import AttractionCategoryModal from '~/components/packages/AttractionCategoryModal';

    export default {
        middleware: 'auth',
        layout: 'dashboard',
        components: {
            dataTable,
            AttractionCategoryModal,
        },
        metaInfo() {
            return {
                title: "Dashboard"
            }
        }, 
        data() {
            return {
                AttractionCategory  :   null,
                dt: {
                    columns : () => {
                        var $this = this;
                        let columns =
                            [
                                { title: 'id', data: 'id' },
                                { title: 'name', data: 'name' },
                                { title: 'description', data: 'description' },
                                { title: 'Actions', data: null, orderable: false,
                                    render: function ( data, type, row, meta ) {
                                        let btni18n     =  (data.i18n.length>0) ? `<button class="btn btn-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addAttractionCategory" title="Traducción"><span class="icon-translate"></span></button>` : `<button class="btn btn-outline-secondary btn-sm mr-2 font-weight-bold btn-i18n" data-id="${data.id}" data-toggle="modal" data-target="#addAttractionCategory" title="Traducción"><span class="icon-translate"></span></button>`;
                                        let btnedit     =  $this.$can('write','packages-attractions-index')  ? `<button class="btn btn-success btn-sm mr-2 font-weight-bold btn-edit" data-id="${data.id}" data-toggle="modal" data-target="#addAttractionCategory">Editar</button>` : `-`;
                                        let btndelete   =  $this.$can('write','packages-attractions-index')  ? `<button class="btn btn-danger btn-sm mr-2 font-weight-bold btn-delete" data-id="${data.id}">Eliminar</button>` : `-`;
                                        return `<div class="d-flex">${ btni18n + btnedit + btndelete}</div>`;
                                    } 
                                },
                            ];
                        return columns
                    }
                }
            }
        },
        methods: {
            // Redirecciones=========================================================
            goBack: function () {
                window.history.back();
            },
            clearAttractionCategoryVar() {
                this.AttractionCategory = null;
            },
            reloadAttractionCategory() {
                this.$refs.attractionCategoryTable.ajaxReload();
            },
            onDelete(category) {
                let vm = this
                $.confirm.delete(
                    'Eliminar Categoria',
                    `¿Está seguro que desea eliminar la categoria <u>${category.name}</u>?`, 
                    function(){
                        vm.deleteItem(category.id)
                    }
                )
            },
            deleteItem (idDel) {
                let vm = this;
                axios.post('/admin/attractions/categories/delete', { id: idDel }).then(response => {
                    let data = response.data;
                
                    if(!data.success) {
                        if(data.data.error) {
                            vm.$snotify.warning(data.data.error);
                        }
                        return
                    } 
                    if(data.success) {
                        vm.$snotify.success('Se Elimino con Exito');
                        this.$refs.attractionCategoryTable.ajaxReload();
                        return
                    }
                    vm.$snotify.info('Se ha enviado solicitud') 

                }).catch(err => console.log(err));
            },
        },
        mounted(){
            let vm = this;

            // ------------ Para usar el valor que tiene la tabla actual.
            $(vm.$el).off("click.attraction_categories",".btn-edit");
            $(vm.$el).on("click.attraction_categories",".btn-edit",function(){
                let attraction_category = vm.$refs.attractionCategoryTable.rowData($(this).closest('tr'));
                attraction_category.btn_i18n = false;
                vm.AttractionCategory = attraction_category;
            });

            $(vm.$el).off("click.attraction_categories",".btn-i18n");
            $(vm.$el).on("click.attraction_categories",".btn-i18n",function(){
                let attraction_category = vm.$refs.attractionCategoryTable.rowData($(this).closest('tr'));
                attraction_category.btn_i18n = true;
                vm.AttractionCategory = attraction_category;
            });

            $(vm.$el).off("click.attraction_categories",".btn-delete");
            $(vm.$el).on("click.attraction_categories",".btn-delete",function(){
                let category = vm.$refs.attractionCategoryTable.rowData($(this).closest('tr'));
                vm.onDelete(category);
            });
            
        },
    }
</script>
