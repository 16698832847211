<template>
    <div>
        <div v-if="myPackage.id" class="modal fade" @submit.prevent="savePackageHotel()" id="addPackageHotel">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formPackageHotel" autocomplete="off"  novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span>Agregar Hotel al Paquete</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label class="pr-5">Seleccion de Hotel</label>
                                    <br>
                                    <label class="text-muted">
                                        <input type="checkbox" v-model="thisCity" name="thisCity" id="thisCity" class="pt-2">
                                        Buscar solo de la ciudad de {{myPackage.city.name}}.
                                        <div v-show="loadGetHotels" class="spinner-border text-dark spinner-border-md" style="width: 20px; height: 20px;" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </label>
                                    <span class="btn float-right btn-primary btn-sm font-weight-bold" style="padding: 2px 8px;" 
                                    data-toggle="modal" data-target="#modalHotel" title="Agregar un nuevo hotel">
                                        <span class="icon-add-2" grid="12"></span>
                                    </span>
                                    <v-select v-model="formPackageHotel.hotel" @keypress.enter.native.prevent="" 
                                    name="hotel_id" id="hotel_id" ref="vSelectHotelChange" 
                                    placeholder="Seleccione Hotel" :options="hotels" selectOnTab>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary disabled" v-if="loadGetHotels"> 
                                <div class="spinner-border text-white spinner-border-md mr-1" style="width: 20px; height: 20px;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <span>
                                    Loading...
                                </span>
                            </button> 
                            <button v-if="!loadGetHotels" type="submit" id="btnChangeHotel" class="btn btn-primary"> 
                                <span>Agregar</span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: [
        "myPackage",
        "resetHotel"
        ],
    data() {
        return {
            loadGetHotels           : false,
            thisCity                : true,
            resetModal              : false,
            hotels                  : [],
            formPackageHotel: new WebForm('#formPackageHotel', {
                id                      : null,
                package_id              : '', 
                hotel_id                : '',
                airport_transportation  : '',
                they_allow_pets         : '',
                all_inclusive           : '',
                hotel                   : null,
            }),
        };
    },
    watch: {
        thisCity: function(val, oldVal) {
            if(this.resetModal == false) {
                this.getHotels();
            }
        },
        myPackage: function(val, oldVal) {
            if(this.myPackage!=null) 
            {
                this.getHotels();
            }
        },
        resetHotel: function(val, oldVal) {
            if(this.resetHotel) 
            {
                this.getHotels();
            }
        },
    },
    methods: {
        savePackageHotel() {
            let vm = this;
            if (!vm.formPackageHotel.hotel) {
                vm.$snotify.warning('Seleccione un Hotel.');
                return;
            } else {
                vm.loadGetHotels = true;
                vm.formPackageHotel.hotel_id = vm.formPackageHotel.hotel.id;
                vm.formPackageHotel.package_id = vm.myPackage.id;

                vm.formPackageHotel.post('/admin/packages/hotels/save').then(response => {
                    let data = response.data;
                   
                    if (data.user_request_status) {
                        $('#addPackageHotel').modal('hide');
                        return;
                    }

                    if(data.success) {
                        vm.$snotify.simple('Elemento Agregado con exito.');   
                        vm.$emit('reloadPackageHotel');
                        $('#addPackageHotel').modal('hide');
                        return;
                    }
                    
                }).catch(err => {
                    vm.$snotify.warning('Ocurrio un error en la red');
                }).finally(() => {
                    vm.loadGetHotels = false;
                });
            }
        },
        getHotels(){
            let vm = this;
            vm.loadGetHotels = true;
            let endpoint = '';
            if(vm.thisCity && vm.myPackage.city_id) {
                vm.thisCity = true;
                endpoint = `/admin/hotels/allbyid/${vm.myPackage.city_id}`;
            } else {
                vm.thisCity = false;
                endpoint = `/admin/hotels/all`;
            }

            axios.get(endpoint).then(({data}) => {
                let hotels = data.map(l => {
                    let hotel = l
                    let d = {
                        id: hotel.id,
                        name: `${hotel.name}`,
                        label: `${hotel.name}`
                    }
                    return d;
                });
                vm.hotels = hotels;

                vm.hotels.sort(function(a, b) {
                    var a1= a.name, b1= b.name;
                    if(a1 == b1) return 0; 
                    return a1> b1? 1: -1;    
                });
            }).catch(err => {
                vm.$snotify.warning('Ocurrio un error al obtener los hoteles');
            }).finally(() => {
                vm.loadGetHotels = false;
            });
        },
    },
    mounted() {
        let vm = this;

        $('#addPackageHotel').off('hidden.bs.modal');
        $('#addPackageHotel').on('hidden.bs.modal', function () {
            vm.resetModal               = true;
            vm.thisCity                 = true;
            vm.hotels                   = [];
            vm.loadGetHotels            = false,
            vm.formPackageHotel.clear();
            vm.formPackageHotel.reset();
            vm.$emit('closePackageHotelModal');
        });

        // console.log('PackageHotelModal mounted.');
    }
};
</script>
            