`<template>
    <div>
        <div class="row section mx-2 my-1 p-2">
            <div class="col-md-5">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold w-100 mb-2">
                            <span class="icon-palm-tree mr-1"></span> Requerimientos de Atraccion Turistica
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Pais</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteTourist.country.name">
                            </div>
                            <div class="form-group col-6">
                                <label class="w-100">Ciudad</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteTourist.city">
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Tipo de Atraccion Turistica</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="quoteTourist.attraction_category.name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6">
                                <label>Adultos</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quoteTourist.n_adults>1) ? quoteTourist.n_adults + ' Personas' : quoteTourist.n_adults + ' Persona' ">
                            </div>
                            <div class="form-group col-6">
                                <label>Niños</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="(quoteTourist.n_childrens>1) ? quoteTourist.n_childrens + ' Personas' : quoteTourist.n_childrens + ' Persona' ">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6" :class="{'col-12':quoteTourist.only_one_day}">
                                <label v-if="quoteTourist.only_one_day" class="w-100">Fecha
                                    <span class="float-sm-right">
                                        <span class="badge badge-success">Solo un dia</span>
                                    </span>
                                </label>
                                <label v-else>Fecha de Entrada </label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteTourist.start_date).format('LL')">
                            </div>
                            <div class="form-group col-6" v-if="!quoteTourist.only_one_day">
                                <label>Fecha de Salida</label>
                                <input type="text" class="form-control form-control-sm" readonly disabled :value="$moment(quoteTourist.end_date).format('LL')">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Comentarios sobre atraccion turistica</label>
                                <div class="border my-1 p-1" style="height: 75px; overflow-y: scroll;">{{quoteTourist.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------- -->
            <div class="col-md-7 border-left">
                <div class="row">
                    <div class="col-12">
                        <label class="font-weight-bold mb-2">
                            Soluciones de Atraccion Turistica
                        </label>
                        <span class="float-sm-right">
                            <button class="btn ml-3 btn-primary float-right btn-sm" :disabled="!owner"
                            data-toggle="modal" data-target="#touristResponseModal"
                            @click="showTouristModal()">
                                Nueva Solucion
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row" v-if="quoteTourist && quoteTourist.quote_tourist_response && quoteTourist.quote_tourist_response.length>0">
                    <div class="form-group col-12  mb-0 pb-0">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm table-hover table-borderless mb-1" style="font-size: 12px;">
                                    <thead class="thead-light">
                                        <tr class="text-center">
                                        <th scope="col">#</th>
                                        <th scope="col">Lugar</th>
                                        <th scope="col">Fecha Inicio</th>
                                        <th scope="col">Fecha Final</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!loadComplete">
                                        <tr>
                                            <td colspan="6">
                                                <div class="col-12 text-center py-3">
                                                    <div class="spinner-border text-secondary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div>
                                                        <small>Cargando...</small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(item, index) in (touristResponse) ? touristResponse : null"
                                        :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">
                                                {{ (item.attraction) ? item.attraction.name : 'Error to load... id: ' + item.attraction_id }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.non_date) ? 'Sin Fechas...' : null}}
                                                {{ (item.body_json && item.body_json.entry_format) ? $moment(item.body_json.entry_format).format('LL') : null }}
                                            </td>
                                            <td class="text-center">
                                                {{ (item.body_json && item.body_json.non_date) ? 'Sin Fechas...' : null}}
                                                {{ (item.body_json && item.body_json.out_format) ? $moment(item.body_json.out_format).format('LL') : null }}
                                            </td>
                                            <td class="text-right">
                                                {{ (item.body_json && !item.body_json.is_free) ? '$ ' + (item.body_json.cost + item.body_json.taxes + item.body_json.utility).toFixed(2) : null }}
                                                <span class="text-success"><b>
                                                {{ (item.body_json && item.body_json.is_free) ? '$ 0.00' : null }}
                                                </b></span>
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-info" @click="showTouristModal(index)"
                                                data-toggle="modal" data-target="#touristResponseModal">
                                                    <span class="icon-view font-12"></span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger" :disabled="!owner"
                                                @click="onDeleteTouristResponse(index)">
                                                    <span class="icon-bin-2 font-12"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" v-else>
                    <div class="form-group align-middle text-center col-12 mb-0 pb-0 mt-5">
                        <p class="mb-0">Aun no se ha proporcionado soluciones</p>
                        <p class="mb-0"><small>"Haga click en
                            <b style="cursor:pointer;">Nueva Solucion</b> para agregar datos"
                        </small></p>
                    </div>
                </div>
            </div>
        </div>
        <tourist-response-modal
        :TouristResponse="varTouristResponse"
        :QuoteTourisId="quoteTouristId"
        :owner="owner"
        v-on:saveTouristResponse="saveTouristResponse"
        v-on:closeTouristResponse="closeTouristResponse()">
        </tourist-response-modal>
    </div>
</template>
<script>
import TouristResponseModal from './TouristResponseModal'
export default {
    // props: ["quoteFlight"],
    props: {
        quoteTourist: {
            type: [Object],
            default () {
                return false
            }
        },
        lastUpdateLocal: {
            type: Number
        },
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    components: {
        TouristResponseModal
    },
    data() {
        return {
            varTouristResponse: null,
            quoteTouristId:     null,
            showResponse:       true,
            loadComplete:       false,
            touristResponse:    null,
            attractionBank:     [],
        };
    },
    watch: {
        lastUpdateLocal: function(val, oldVal) {
            let vm = this;
            if (vm.lastUpdateLocal != null) {
                vm.initTouristResponse();
            }
        },
    },
    methods: {
        async initTouristResponse() {
            let vm = this;
            vm.loadComplete = false;
            // console.log("quoteTourist", vm.quoteTourist);
            if (vm.quoteTourist != null && vm.quoteTourist.quote_tourist_response && vm.quoteTourist.quote_tourist_response.length > 0) {
                let varTemp = vm.quoteTourist.quote_tourist_response;
                await varTemp.reduce(async (promise, thisItem) => {
                    await promise;
                    let find = null;

                    await vm.attractionBank.reduce(async (promise, itemAttBank) => {
                        await promise;
                        if (itemAttBank.id == thisItem.attraction_id) {
                            find = itemAttBank;
                        }
                    }, Promise.resolve());

                    if (find) {
                        thisItem.attraction = find;
                    } else {
                        const data = await axios.get(`/admin/attractions/${thisItem.attraction_id}`);
                        if (data.data) {
                            find = data.data;
                            thisItem.attraction = find;
                            vm.attractionBank.push(find);
                        } else {
                            console.log(`Ups... Error al buscar: /admin/attractions/${thisItem.attraction_id}`);
                        }
                    }

                    try {
                        if (thisItem.body_json && JSON.parse(thisItem.body_json)) {
                            thisItem.body_json = JSON.parse(thisItem.body_json);
                        }
                    } catch (e) {
                        console.log("error JSON.parse(thisItem.body_json)");
                    }
                }, Promise.resolve());
                vm.touristResponse = varTemp;
                vm.loadComplete = true;
            } else {
                setTimeout(() => {
                    vm.loadComplete = true;
                }, 400);
            }
        },
        onDeleteTouristResponse(index) {
            let vm = this;
            $.confirm.delete(
                'Eliminar Solucion de Atraccion Turistica',
                `¿Está seguro que desea eliminar esta solucion?`,
                function(){
                    vm.deleteTouristResponse(index);
                }
            )
        },
        async deleteTouristResponse(index) {
            let vm = this;
            vm.loadComplete = false;
            await axios.post('/admin/quotes/tourists/delete', {id: vm.touristResponse[index].id}).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                }
                if(data.success) {
                    let obj = data.success;
                    vm.$snotify.success('Solucion borrada con exito.');
                    vm.saveTouristResponse(obj);
                }
                return;
            });
        },
        saveTouristResponse(obj) {
            let vm = this;
            vm.loadComplete = false;
            vm.$emit("saveTouristResponse", obj);
        },
        showTouristModal(index = null) {
            let vm = this;
            if (index!=null) {
                vm.varTouristResponse = vm.touristResponse[index];
            }
            vm.quoteTouristId = vm.quoteTourist.id;
        },
        closeTouristResponse() {
            this.quoteTouristId = null;
            this.varTouristResponse = null;
        }
    },
    computed: {
        
    },
    mounted() {
        console.log('viewHotelRequirements Mounted');
        this.initTouristResponse();
    }
}
</script>
<style scoped>
    .table-detail-flight p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .font-12 {
        font-size: 12px;
    }
    .option-route:hover .btn-option-route{
        opacity: 1;
    }
    .btn-option-route{
        opacity: 0;
        transition: 0.3s;
    }
</style>