<template>
    <input type="text" ref="datepicker" :value="formattedDate" class="form-control" readonly>
</template>

<script>
    //http://t1m0n.name/air-datepicker/docs/
    export default {
        props: {
            value: {
                type: [String, Array],
                default: null,
            },
            range: {
                type: Boolean,
                default: false,
            },
            view: {
                type: String,
                default: 'days',
            },
            minView: {
                type: String,
                default: 'days',
            },
            multipleDates: {
                type: [Number, Boolean],
                default: false,
            },
            position: {
                type: String,
                default: 'bottom left',
            },
            minDate: {
                type: Date,
                default: null,
            },
            maxDate: {
                type: Date,
                default: null,
            },
            startDate: {
                type: Date,
                default: null,
            },
            inline: {
                type: Boolean,
                default: false,
            },
            autoClose: {
                type: Boolean,
                default: true,
            },
            config: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                locale: moment.locale(),
                localeFormat: moment.localeData()._longDateFormat.L,
                separator: moment.locale() == 'es' ? ' | ' : ' - ',
                defaultFormat:{
                    date: "YYYY-MM-DD",
                    time: "HH:mm:ss",
                }
            }
        },
        methods: {
            isDefined(object){
                return !_.isNull(object) && !_.isUndefined(object)
            },
            getDate(){
                
                if(_.isEmpty(this.formattedDate))
                    return result

                let date = _.includes(this.formattedDate, this.separator) ? this.formattedDate.split(this.separator) : this.formattedDate

                if(_.isArray(date)){
                    let result = []
                    date.forEach(e => {
                        result.push(moment(e, this.localeFormat).toDate())
                    });

                    return result
                }

                return moment(date, this.localeFormat).toDate()
            }
        },
        computed: {
            formattedDate: function () {
                if(_.isEmpty(this.value))
                    return ''

                if(_.isArray(this.value)){
                    let dates = []
                    this.value.forEach(element => {
                        dates.push(moment(element, this.defaultFormat.date).format(this.localeFormat))
                    });
                    return dates.join(this.separator)
                }
                return moment(this.value, this.defaultFormat.date).format(this.localeFormat)
            }
        },
        mounted() {
            
            let vm  = this
            let def = {
                language : 'es', 
                dateFormat : vm.defaultFormat.date.toLowerCase(),
                range: false,
                multipleDatesSeparator: vm.separator
            }
            
            let config = def
            if(vm.isDefined(vm.config)){
                config = {
                    ...def,
                    ...vm.config
                }
            }

            if(vm.isDefined(vm.range))
                config.range = vm.range

            if(vm.isDefined(vm.view))
                config.view = vm.view

            if(vm.isDefined(vm.minView))
                config.minView = vm.minView

            if(vm.isDefined(vm.multipleDates))
                config.multipleDates = vm.multipleDates

            if(vm.isDefined(vm.position))
                config.position = vm.position

            if(vm.isDefined(vm.minDate))
                config.minDate = vm.minDate

            if(vm.isDefined(vm.maxDate))
                config.maxDate = vm.maxDate

            if(vm.isDefined(vm.startDate))
                config.startDate = vm.startDate

            if(vm.isDefined(vm.inline))
                config.inline = vm.inline

            if(vm.isDefined(vm.autoClose))
                config.autoClose = vm.autoClose
            
            config.onSelect = function (formattedDate, date, inst) {
                vm.$refs.datepicker.value = formattedDate
                vm.$emit('input', _.includes(formattedDate, vm.separator) ? formattedDate.split(vm.separator) : formattedDate)
                vm.$emit('onSelect', {formattedDate, date, inst})
            }
            config.onShow = function (inst, animationCompleted) {
                vm.$emit('onShow', {inst, animationCompleted})
            }
            config.onHide = function (inst, animationCompleted) {
                vm.$emit('onHide', {inst, animationCompleted})
            }
            config.onChangeDecade = function (decade) {
                vm.$emit('onChangeDecade', {decade})
            }
            config.onChangeDecade = function (decade) {
                vm.$emit('onChangeDecade', {decade})
            }
            config.onChangeView = function (view) {
                vm.$emit('onChangeView', {view})
            }
            config.onRenderCell = function (date, cellType) {
                return vm.$emit('onRenderCell', {date, cellType})
            }

            vm.$nextTick(() => {
                $(vm.$el).datepicker(config)
            })
        }
    }
</script>