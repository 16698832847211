<template>
    <div class="mx-3">
        <div class="row">
            <div class="col-12 section py-3">

                <h5 class="font-weight-bold mb-0">Permisos y roles</h5>
                <p class="text-muted">Administración de niveles de acceso</p>

                <div class="d-flex justify-content-end w-100">
                    <button class="btn btn-primary mr-2" data-toggle="modal" data-target="#newRole" v-can:create>
                        <span class="icon-user-shield mr-2"></span> Nuevo Rol
                    </button>
                    <button class="btn btn-primary" data-toggle="modal" data-target="#newPermission" v-can:manage>
                        <span class="icon-lock-circle mr-2"></span> Nuevo Permiso
                    </button>
                </div>

                <div class="scrolling outer mt-3">
                    <table-loader v-if="!authority.permissions" :width_svg="150" :height_svg="150" :primary_color="'#e7eaed'" :secondary_color="'#f3f3f8'"></table-loader>
                    <div class="inner">
                        <table class="table table-condensed table-responsive table-hover table-condensed">
                            <transition name="fade">
                                <thead v-show="authority.roles">
                                    <tr>
                                        <th>
                                            PERMISOS
                                        </th>
                                        <td v-for="role in authority.roles" :key="role.id" :title="role.description"> 
                                            <div class="role p-2" :data-role="role.id">
                                                <div class="role-info mb-2">
                                                    <small class="text-muted w-100">Rol</small>
                                                    <div class="role-name font-weight-bold"> {{ role.title }}</div>
                                                </div>
                                                <div class="role-authority d-flex align-items-center flex-wrap">
                                                    <small class="text-muted w-100">NIVEL DE AUTORIDAD</small>
                                                    <div class="d-flex align-items-center" data-toggle="tooltip" data-placement="top" :title="`${role.authority}%`">
                                                        <div class="autority-level">{{ authorityTranslation(role.authority) }}</div>
                                                        <ul class="autority-bar ml-2" :class="authorityName(role.authority)">
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <button v-can:manage disabled class="delete-role btn-role btn btn-transparent btn-sm" :data-id="role.id"><span class="icon-bin"></span></button>
                                                <button v-can:create class="edit-role btn-role btn btn-transparent btn-sm" @click="editRole(role.id)" data-toggle="modal" data-target="#newRole" :data-id="role.id"><span class="icon-pencil"></span></button>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                            </transition>
                            
                            <transition name="fade">
                                <tbody v-show="authority.permissions">
                                        <tr v-for="permission in authority.permissions" :key="permission.id">
                                            <th :title="permission.title">
                                                <span v-if="$can('manage')" class="edit-permission cursor-pointer" data-toggle="modal" data-target="#newPermission" :data-id="permission.id" @click="editPermission(permission.id)">
                                                    <span class="permission-icon" :class="permission.icon" grid="16"></span> 
                                                    {{ permission.title }}
                                                </span>
                                                <span v-else>
                                                    <span class="permission-icon" :class="permission.icon" grid="16"></span>
                                                    {{ permission.title }}
                                                </span>
                                            </th>
                                            <td class="text-center" v-for="access in permission.permissions" :key="access.role_id">
                                                <select v-if="(access.role_id != highRole.id ) && accessTypes(accessByHighRole(permission)).indexOf((access.type || 'DENIED' )) >= 0 || highRole.authority == 100" name="permission" id="permission" v-can:write.disabled @change="setPermission" v-bind:style="bindStyle(access.type)" :value="access.type || 'DENIED'" :data-role="access.role_id" :data-component="permission.id" class="form-control form-control-sm permission-select">
                                                    <option value="DENIED" style="color:#495057;"> &nbsp; &#11096; &emsp;Denied </option>
                                                    <option v-if="['MANAGE', 'WRITE', 'VIEW'].indexOf((accessByHighRole(permission) || 'DENIED')) >= 0 || highRole.authority == 100" value="VIEW"   style="color:#FF9800;"> &nbsp; &#11044; &emsp;View</option>
                                                    <option v-if="['MANAGE', 'WRITE'].indexOf((accessByHighRole(permission) || 'DENIED')) >= 0 || highRole.authority == 100" value="WRITE"  style="color:#2196F3;"> &nbsp; &#11044; &emsp;Write</option>
                                                    <option v-if="['MANAGE'].indexOf((accessByHighRole(permission) || 'DENIED')) >= 0 || highRole.authority == 100" value="MANAGE" style="color:#4CAF50;"> &nbsp; &#11044; &emsp;Manage</option>
                                                </select>
                                                <div v-else class="text-left">
                                                    <span
                                                        class="text-left label-disabled"
                                                        :style="bindStyle(access.type)">
                                                        {{ access.type ? '&#11044;' : '&#11096;' }} &emsp;{{ (access.type || 'DENIED') | lowercase | capitalize}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                </tbody>
                            </transition>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <permission-modal ref="formPermission" :permissions="authority.permissions" />
        <role-modal ref="formRoles" :roles="authority.roles"/>
    </div>
</template>

<script>

    "use strict";
    import { mapGetters } from 'vuex'
    import PermissionModal from '~/components/access/permissionModal'
    import RoleModal from '~/components/access/roleModal'
    import TableLoader from '~/components/access/tableLoader'

    export default {
        middleware:'auth',
        layout: 'dashboard',
        metaInfo () {
            return { title: "Permission & Roles" }
        },
        data(){
            return {
                authority: []
            }
        },
        filters: {
            lowercase: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()
                return value
            },
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        computed: {
            highRole () {
               let h  = null
               Object.keys(this.ownerRoles.roles).forEach(key => {
                   if(!h) {
                       h = this.ownerRoles.roles[key]
                       return
                   }

                   if(this.ownerRoles.roles[key].authority > h.authority) {
                       h = this.ownerRoles.roles[key]
                       return
                   }
               })
               return h
            },
            ...mapGetters({
                ownerRoles : 'permission/access', 
            })
        },
        components: {
            PermissionModal,
            RoleModal,
            TableLoader
        },
        methods: {
            async getPermission(){
                let vm = this
                let { data } = await axios.get('/admin/page/menu')
                await axios.post('/admin/permission/get').then(response => {
                    vm.authority = response.data

                    // Si este componente concuerda con un elemento de menu
                    // asigna un icono para identificarlo
                    vm.authority.permissions.forEach(element => {

                        let item     = vm.findMenuItem(data, element)
                        element.icon = ':D'

                        if(!_.isEmpty(item))
                            element.icon = item.icon || null
                    });
                })
            },
            findMenuItem(menu, component){
                
                const START_END_SLASH = /^\/+|\/+$/g
                const START_COLONS_END_INTERROGATION = /^\:.*?\?$/g
                let routes = this.$router.options.routes
                let route  = routes.find(route => route.name == component.name)
                let result = null

                if(_.isEmpty(route))
                    return result

                menu.forEach(item => {
                    if(item.childrens) {
                        item.childrens.forEach(child => {
                            let menuPath  = child.url.replace(START_END_SLASH, '')
                            let routePath = route.path.split('/').map(s => START_COLONS_END_INTERROGATION.test(s) ? '' : s).join('/').replace(START_END_SLASH, '')
                            if(menuPath == routePath)
                                result = child
                        })
                    }

                    if(route.path == item.url)
                        result = item
                })

                return result
            },
            editPermission (permission_id) {
                this.$refs.formPermission.onEditPermission(permission_id)
            },
            editRole (role_id) {

                let level_edit = true
                if(role_id == this.highRole.id)
                    level_edit = this.highRole.authority == 100

                this.$refs.formRoles.onEditRole(role_id, level_edit)
            },
            accessByHighRole (permission) {
                let p = permission.permissions.find(p => {
                    return p.role_id == this.highRole.id
                })

                return p.type
            },
            accessTypes (type) {

                switch (type) {
                    case 'MANAGE':
                        return ['MANAGE', 'WRITE', 'VIEW', 'DENIED']
                    case 'WRITE':
                        return ['WRITE', 'VIEW', 'DENIED']
                    case 'VIEW':
                        return ['VIEW', 'DENIED']
                    default:
                        return ['DENIED']
                }
            },
            authorityName(percent) {
                let className = 'none'
                if(percent > 1 && percent <= 24)
                    className = 'lower'
                if(percent >= 25 && percent <= 49)
                    className = 'low'
                if(percent >= 50 && percent <= 74)
                    className = 'medium'
                if(percent >= 75 && percent <= 100)
                    className = 'high'
                return className
            },
            authorityTranslation(percent) {
                let translation = 'ninguno';
                if(percent > 1 && percent <= 24)
                    translation = 'inferior'
                if(percent >= 25 && percent <= 49)
                    translation = 'bajo'
                if(percent >= 50 && percent <= 74)
                    translation = 'medio'
                if(percent >= 75 && percent <= 99)
                    translation = 'alto'
                if(percent == 100)
                    translation = 'superior'
                return translation
            },
            setPermission(e){
                let $this      = $(e.target);
                let permission = {
                    role        : $this.data('role'),
                    component   : $this.data('component'),
                    permission  : $this.val()
                }

                let style = this.bindStyle(permission.permission)
                this.$snotify.html(`<p class="text-white mb-0"><span class="dot mr-2" style="background-color: ${_.values(style[0])};"></span> Permiso cambiado a <b>${permission.permission}</b>.</p>`)
                axios.post('/admin/permission/set', permission)
            },
            bindStyle(accessType){
                let style = [];
                switch (accessType) {
                    case 'VIEW':
                        style.push({'color': '#FF9800'})
                        break;
                    case 'WRITE':
                        style.push({'color': '#2196F3'})
                        break;
                    case 'MANAGE':
                        style.push({'color': '#4CAF50'})
                        break;
                    default:
                        style.push({'color': '#495057'})
                    break;
                }
                return style;
            },
            permissionValue(permission, role) {
                return permission.role_id == role ? permission.type : '';
            },
        },
        created() {
            this.getPermission();
        },
        mounted(){

            this.$off('update-data')
            this.$on('update-data', this.getPermission)

            $('body').off('change.permission')
            $('body').on('change.permission', '.permission-select', (e) => {
                let $this = $(e.target);
                let color = $this.find(":selected").css('color');
                $this.css('color', color)
            })

            $('[data-toggle="tooltip"]').tooltip()
        }
    }
</script>

<style>
    .dot {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
    }
</style>
<style scoped>

    .scrolling table {
        table-layout: inherit;
    }
    .scrolling td, th {
        vertical-align: top;
        padding: 5px;
        min-width: 100px;
    }
    .scrolling thead th:first-child{
        height: 135px;
        letter-spacing: 3px;
        font-size: 12px;
        color: #9aa2a7;
        display: flex;
        align-items: flex-end !important;
    }
    .scrolling thead > tr > th,
    .scrolling thead > tr > td{
        border: 0px;
    }
    .scrolling th {
        position: absolute;
        left: 5px;
        width: 185px;
        font-size: 0.875rem;
        padding: 8px 5px;
    }
    .scrolling th > span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .inner {
        overflow-x: auto;
        overflow-y: visible;
        margin-left: 185px;
    }
    @media (max-width: 576px) {
        .scrolling th {
            width: 135px;
        }
        .inner {
            margin-left: 135px;
        }
    }
    .outer {
        position: relative;
        padding: 5px;
        background-color: #f3f3f8;
        border-radius: 3px;
        min-height: 150px;
        overflow: hidden;
    }
    .role {
        position: relative;
        background: white;
        border-radius: 5px;
        width: 170px;
        height: 125px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    .btn-role{
        border: 1px solid #d3dbe0;
        padding: 0.15rem 0.35rem;
        line-height: 1;
        position: absolute;
        top: 5px;
    }
    .btn-role > span{
        font-size: 15px;
    }
    .edit-role{
        right: 5px;
    }
    .delete-role{
        right: 40px;
    }
    .edit-permission{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .autority-bar{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        width: 25px;
    }
    .autority-bar li {
        background: #dae0e4;
        width: 22%;
        height: 7px;
    }
    .permission-select{
        background: transparent;
        border: 0px;
        padding: 0px;
        font-size: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .autority-bar.lower li:first-child {
        background-color: #F44336;
    }
    .autority-bar.low li:nth-child(-n+2) {
        background-color: #FF9800;
    }
    .autority-bar.medium li:nth-child(-n+3) {
        background-color: #ffcc33;
    }
    .autority-bar.high li {
        background-color: #8BC34A !important;
    }
    .permission-icon{
        opacity: 0.65;
        margin-right: 10px;
        min-height: 16px;
        min-width: 16px;
    }
</style>