<template>
    <div>
        <div class="modal fade" id="flightRouteModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formFlightRoute" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                        <div class="modal-header">
                            <h6 class="font-weight-bold mb-0 pb-0">
                                <span v-if="flightRoute!=null">Editar </span>
                                <span>Ruta de Vuelo</span>
                                <p class="mb-0"><small>
                                    <span v-if="flightRoute!=null">Edite </span>
                                    <span v-else>Ingrese </span>
                                    la escalas de la 
                                    <b v-if="typeRoute=='one_way_route'">Ruta de Ida</b>
                                    <b v-if="typeRoute=='return_route'">Ruta de Retorno</b>
                                </small></p>
                            </h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-2">
                                    <h6 class="font-weight-bold">
                                        <span class="icon-airport-road mr-2"></span>
                                        Aerolinea
                                    </h6>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label for="country_id" class="required">Aerolinea</label>
                                            <v-select placeholder="Buscar Aerolinea" v-model="airline" :options="airlines" 
                                            label="name" :filterable="false" @search="onSearchAirline" selectOnTab>
                                                <template slot="no-options">Buscar Aerolinea...</template>
                                                <template slot="option" slot-scope="option">
                                                    <img class="airline-logo" width="20" :src="`/img/airlines/${option.iata}.png`" onerror="this.src='/img/airlines/default.png';" >
                                                    ({{ option.iata }}) {{ option.name }} - {{ option.alias }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <img class="airline-logo" width="20" :src="`/img/airlines/${option.iata}.png`" onerror="this.src='/img/airlines/default.png';" >
                                                    ({{ option.iata }}) {{ option.name }} - {{ option.alias }}
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-2">
                                    <h6 class="font-weight-bold">
                                        <span class="icon-plane-take-off mr-2"></span>
                                        Despegue
                                    </h6>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label for="country_id" class="required">Aeropuerto</label>
                                            <v-select placeholder="Buscar Aeropuertos" v-model="takeoff_airport" :options="airports" 
                                            label="name" :filterable="false" @search="onSearchAirport" selectOnTab>
                                                <template slot="no-options">Buscar Aeropuertos...</template>
                                                <template slot="option" slot-scope="option">
                                                    {{ showNameAirport(option.iata, option.name) }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    {{ showNameAirport(option.iata, option.name) }}
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="takeoff_date" class="required">Fecha</label>
                                            <vue-datepicker name="takeoff_date" 
                                            ref="takeoff_date_picker"
                                            v-model="takeoff_date"
                                            :attributes='datePicker'
                                            :columns="columnsPicker"
                                            :min-date="minDatePicker"
                                            ></vue-datepicker>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="takeoff_time" class="required">Hora</label>
                                            <vue-timepicker name="takeoff_time" placeholder="Hora" v-model="takeoff_time" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <h6 class="font-weight-bold">
                                        <span class="icon-plane-land mr-2"></span>
                                        Aterrizaje
                                    </h6>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label for="country_id" class="required">Aeropuerto</label>
                                            <v-select placeholder="Buscar Aeropuertos" v-model="landing_airport" :options="airports" 
                                            label="name" :filterable="false" @search="onSearchAirport" selectOnTab>
                                                <template slot="no-options">Buscar Aeropuertos...</template>
                                                <template slot="option" slot-scope="option">
                                                    {{ showNameAirport(option.iata, option.name) }}
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    {{ showNameAirport(option.iata, option.name) }}
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="takeoff_date" class="required">Fecha</label>
                                            <vue-datepicker name="takeoff_date" 
                                            ref="takeoff_date_picker"
                                            v-model="landing_date"
                                            :value="takeoff_date"
                                            :attributes='datePicker'
                                            :columns="columnsPicker"
                                            :min-date="minDatePicker"
                                            ></vue-datepicker>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="takeoff_time" class="required">Hora</label>
                                            <vue-timepicker name="takeoff_time" placeholder="Hora" v-model="landing_time" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" :disabled="!validateSave"> 
                                <span v-if="this.flightRoute==null">Guardar</span>
                                <span v-else>Guardar Cambios</span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        flightResponses : null,
        typeRoute: {
            type: String,
            default () {
                return false
            }
        },
        flightRoute: null
    },
    components: {
    
    },
    data() {
        return {
            pickerLoad:         false,
            airline:            null,
            takeoff_airport:    null,
            landing_airport:    null,
            takeoff_date:       null,
            landing_date:       null,
            takeoff_time:       {"H":"00", "mm":"00"},
            landing_time:       {"H":"00", "mm":"00"},
            formFlightRoute: new WebForm('#formFlightRoute', {
                id: null,
                quote_flight_id: null,
                body_json: null
            }),
            airports: [],
            airlines: [],
            datePicker: [
                {
                    key: 'range',
                    highlight: true,
                }
            ],
        }
    },
    watch: {
        flightResponses: function(val, oldVal) {
            if (this.flightResponses == null) {
                this.formFlightRoute.reset();
            } else {
                this.formFlightRoute.id = this.flightResponses.id;
                this.formFlightRoute.quote_flight_id = this.flightResponses.quote_flight_id;
                this.formFlightRoute.body_json = (this.flightResponses.body_json) ? JSON.parse(JSON.stringify(this.flightResponses.body_json)) : null;
                if (this.flightRoute!=null && this.typeRoute && this.formFlightRoute.body_json != null) {
                    let temp = this.formFlightRoute.body_json[this.typeRoute][this.flightRoute];
                    this.airline =          temp.airline.data;
                    this.takeoff_airport =  temp.takeoff.airport;
                    this.landing_airport =  temp.landing.airport;
                    this.takeoff_date =     temp.takeoff.date;
                    this.landing_date =     temp.landing.date;
                    this.takeoff_time =     {"H":temp.takeoff.time.H, "mm":temp.takeoff.time.mm};
                    this.landing_time =     {"H":temp.landing.time.H, "mm":temp.landing.time.mm};

                    ( this.airlines.find(element => element.id == temp.airline.data.id) )    ? null : this.airlines.push(temp.airline.data);
                    ( this.airports.find(element => element.id == temp.takeoff.airport.id) ) ? null : this.airports.push(temp.takeoff.airport);
                    ( this.airports.find(element => element.id == temp.landing.airport.id) ) ? null : this.airports.push(temp.landing.airport);
                }
            }
        },
        takeoff_date: function(val, oldVal) { 
            if (this.takeoff_date!=null && this.landing_date==null) {
                this.landing_date = this.takeoff_date;
            }
        }
    },
    methods: {
        onSaveItem() {
            let vm = this;
            let takeoff_time_format = (vm.takeoff_time.H>9 || vm.takeoff_time.H.length > 1) ? vm.takeoff_date + ' ' + vm.takeoff_time.H + ':' + vm.takeoff_time.mm + ':00' : vm.takeoff_date + ' 0' + vm.takeoff_time.H + ':' + vm.takeoff_time.mm + ':00' ;
            let landing_time_format = (vm.landing_time.H>9 || vm.landing_time.H.length > 1) ? vm.landing_date + ' ' + vm.landing_time.H + ':' + vm.landing_time.mm + ':00' : vm.landing_date + ' 0' + vm.landing_time.H + ':' + vm.landing_time.mm + ':00' ;
            let newData = {
                airline: {
                    id:             vm.airline.id
                },
                takeoff: {
                    airport_id:     vm.takeoff_airport.id,
                    airport_iata:   vm.takeoff_airport.iata,
                    date:           vm.takeoff_date,
                    time:           vm.takeoff_time,
                    time_format:    takeoff_time_format
                },
                landing: {
                    airport_id:     vm.landing_airport.id,
                    airport_iata:   vm.landing_airport.iata,
                    date:           vm.landing_date,
                    time:           vm.landing_time,
                    time_format:    landing_time_format
                }
            };

            if (vm.typeRoute == 'one_way_route') {
                if ( (vm.formFlightRoute.body_json == null) || (vm.formFlightRoute.body_json !=null && (vm.formFlightRoute.body_json.one_way_route)==null)) {
                    if(vm.formFlightRoute.body_json == null) {
                        vm.formFlightRoute.body_json = {};
                    }
                    vm.formFlightRoute.body_json.one_way_route = [];
                    vm.formFlightRoute.body_json.one_way_route.push(newData);

                } else if (vm.formFlightRoute.body_json !=null && vm.formFlightRoute.body_json.one_way_route) {
                    if (this.flightRoute==null) {
                        vm.formFlightRoute.body_json.one_way_route.push(newData);
                    } else {
                        vm.formFlightRoute.body_json.one_way_route[this.flightRoute] = newData;
                    }

                } else {
                    vm.$snotify.error('Ups... Algo a salido mal');
                    return;
                }
            } else if(vm.typeRoute == 'return_route') {
                if ( (vm.formFlightRoute.body_json == null) || (vm.formFlightRoute.body_json !=null && (vm.formFlightRoute.body_json.return_route)==null)) {
                    if(vm.formFlightRoute.body_json == null) {
                        vm.formFlightRoute.body_json = {};
                    }
                    vm.formFlightRoute.body_json.return_route = [];
                    vm.formFlightRoute.body_json.return_route.push(newData);
                    
                } else if (vm.formFlightRoute.body_json !=null && vm.formFlightRoute.body_json.return_route) {
                    if (this.flightRoute==null) {
                        vm.formFlightRoute.body_json.return_route.push(newData);
                    } else {
                        vm.formFlightRoute.body_json.return_route[this.flightRoute] = newData;
                    }

                } else {
                    vm.$snotify.error('Ups... Algo a salido mal');
                    return;
                }
            }

            if ( vm.formFlightRoute.body_json !=null &&  vm.formFlightRoute.body_json.one_way_route && vm.formFlightRoute.body_json.one_way_route.length>0) {
                 vm.formFlightRoute.body_json.one_way_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }
            
            if ( vm.formFlightRoute.body_json !=null &&  vm.formFlightRoute.body_json.return_route &&  vm.formFlightRoute.body_json.return_route.length>0) {
                 vm.formFlightRoute.body_json.return_route.forEach(element => {
                    (element.airline && element.airline.data)    ? delete element.airline.data : null ;
                    (element.takeoff && element.takeoff.airport) ? delete element.takeoff.airport : null ;
                    (element.landing && element.landing.airport) ? delete element.landing.airport : null ;
                });
            }

            vm.formFlightRoute.body_json = JSON.stringify(vm.formFlightRoute.body_json);

            vm.formFlightRoute.post('/admin/quotes/flights/save').then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    $("#flightRouteModal").modal("hide");
                    return;
                }

                if (data.not_found) {
                    vm.$snotify.error('Dato no encontrado...');
                    return;
                }

                if(data.success) {
                    let obj = data.success;
                    if (this.flightRoute == null) {
                        vm.$snotify.success('Ruta agregada...');
                    } else {
                        vm.$snotify.success('Ruta editada...');
                    }
                    vm.$emit("saveFlightRouteModal", obj);
                }
                vm.formFlightRoute.clear();
                vm.formFlightRoute.reset();
                $("#flightRouteModal").modal("hide");
                return;
            });
        },
        showNameAirport(iata = null, name = null) {
            if (iata) {
                if (name.length > 25) {
                    return '(' + iata + ') ' + name.slice(0,25) + '...';
                } else {
                    return '(' + iata + ') ' + name;
                }
            }
            return '';
        },
        showDatePicker (data) {
            this.pickerLoad = true
        },
        onSearchAirport(search, loading) {
            if(_.isEmpty(search))
                return
            loading(true);
            this.searchAirport(loading, search, this);
        },
        searchAirport: _.debounce((loading, search, vm) => {
            axios.get(`/admin/airports/${escape(search)}`).then(res => {
                vm.airports = res.data
                loading(false);
            });
        }, 800),
        onSearchAirline(search, loading) {
            if(_.isEmpty(search)){
                // this.airlines = this.favoriteAirlines
                loading(false);
                return
            }
            loading(true);
            this.searchAirline(loading, search, this);
        },
        searchAirline: _.debounce((loading, search, vm) => {
            axios.get(`/admin/airlines/${escape(search)}`).then(res => {
                vm.airlines = res.data
                loading(false);
            });
        }, 800),
    },
    computed: {
        validateSave() {
            let vm = this;
            let response = true;
            response = (vm.airline && vm.airline.id) ? response : false;
            response = (vm.takeoff_airport && vm.takeoff_airport.id) ? response : false;
            response = (vm.takeoff_date) ? response : false;
            response = (vm.landing_airport && vm.landing_airport.id) ? response : false;
            response = (vm.landing_date) ? response : false;
            return response;
        },
        minDatePicker () {
            return new Date()
        },
        columnsPicker () {
            if(!this.pickerLoad) {
                return 1
            }
            let layout = { default: 1, lg: 2 }
            return this.$screens(layout)
        },
    },
    mounted() {
        let vm = this;
        $("#flightRouteModal").off("hidden.bs.modal");
        $("#flightRouteModal").on("hidden.bs.modal", function() {
            vm.formFlightRoute.clear();
            vm.formFlightRoute.reset();
            vm.airline = null;
            vm.takeoff_airport = null;
            vm.landing_airport = null;
            vm.takeoff_date =    null;
            vm.landing_date =    null;
            vm.takeoff_time =    {"H":"00", "mm":"00"};
            vm.landing_time =    {"H":"00", "mm":"00"};
            vm.$emit("closeFlightRouteModal");
        });
    }
}
</script>