<template>
    <div>
        <div class="modal fade" id="modalCountry">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formCountry" autocomplete="off" @submit.prevent="onSaveCountry()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="formCountry.id">
                                    Editar Pais
                                </span>
                                <span v-else>Nuevo Pais</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="name" class="required">Nombre</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Nombre" v-model="formCountry.name" name="name">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="native_name" class="required">Nombre Nativo</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Nombre Nativo" v-model="formCountry.native_name" name="native_name">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="iso_code" class="required">Codigo ISO</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Codigo Alpha" v-model="formCountry.iso_code" name="iso_code">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="short_iso_code" class="required">Codigo ISO Corto</label>
                                    <input type="text" class="form-control" placeholder="Ingrese Alpha Corto" v-model="formCountry.short_iso_code" name="short_iso_code">
                                </div>
                                <div class="form-group col-12">
                                    <label for="timezone_id"  class="required" style="width: 100%">Zona Horaria</label>
                                    <v-select v-model="formCountry.timezone"
                                    name="timezone_id" ref="vSelectTimezone" label="name"
                                    @keypress.enter.native.prevent="" selectOnTab 
                                    placeholder="Seleccione Timezone" :options="timezones">
                                        <template v-slot:option="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="language_id" class="required" style="width: 100%">Lenguaje</label>
                                    <v-select v-model="formCountry.language" 
                                    name="language_id" ref="vSelectLanguage" label="name"
                                    @keypress.enter.native.prevent="" selectOnTab 
                                    placeholder="Seleccione Lenguaje" :options="languages">
                                        <template v-slot:option="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="currency_id" class="required" style="width: 100%">Moneda</label>
                                    <v-select v-model="formCountry.currency"
                                    name="currency_id" ref="vSelectCurrency" label="name"
                                    @keypress.enter.native.prevent="" selectOnTab 
                                    placeholder="Seleccione Modena" :options="currencies">
                                        <template v-slot:option="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="region_id" class="required" style="width: 100%">Región</label>
                                    <v-select v-model="formCountry.region"
                                    name="region_id" ref="vSelectRegion" label="name"
                                    @keypress.enter.native.prevent="" selectOnTab 
                                    placeholder="Seleccione Region" :options="regions">
                                        <template v-slot:option="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </div>
                                <!-- <div class="form-group col-12 col-md-6">
                                    <label for="flag" class="required">Bandera Link</label>
                                    <input type="text" class="form-control" v-model="formCountry.flag" name="flag">
                                </div> -->
                                <div class="form-group col-12 col-md-6">
                                    <label for="demonym" class="required">Denominacion</label>
                                    <input type="text" class="form-control" v-model="formCountry.demonym" name="demonym">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="capital" class="required">Capital</label>
                                    <input type="text" class="form-control" v-model="formCountry.capital" name="capital">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="numeric_code">Codigo Numerico</label>
                                    <input type="text" class="form-control" v-model.number="formCountry.numeric_code" name="numeric_code">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="phone_extension" class="required">Extencion</label>
                                    <input type="text" class="form-control" v-model="formCountry.phone_extension" name="phone_extension">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="latitude" class="required">Latitud</label>
                                    <input type="text" class="form-control" v-model="formCountry.latitude" name="latitude">
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="longitude" class="required">Longitud</label>
                                    <input type="text" class="form-control" v-model="formCountry.longitude" name="longitude">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" v-can:create="'geodata-countries'" > 
                                <span v-if="formCountry.id && $can('update','geodata-countries')">
                                    Editar
                                </span>
                                <span v-else-if="formCountry.id && !$can('update','geodata-countries')">
                                    Solicitar Edicion
                                </span> 
                                <span v-else>
                                    Guardar Pais
                                </span>
                            </button> 
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

    export default {
        props: ["Country"],
        components: {

        },
        data() {
            return {
                formCountry: new WebForm('#formCountry', {
                    name                : null,
                    native_name         : null,
                    iso_code            : null,
                    short_iso_code      : null,
                    numeric_code        : null,
                    demonym             : null,
                    capital             : null,
                    phone_extension     : null,
                    latitude            : null,
                    longitude           : null,
                    currency_id         : null,
                    language_id         : null,
                    timezone_id         : null,
                    region_id           : null,
                    currency            : null,
                    language            : null,
                    timezone            : null,
                    region              : null,
                    id                  : null,
                }),
                timezones : [],
                currencies: [],
                languages:  [],
                regions:    [],
            }
        },
        watch: {
            Country: function(val, oldVal) {
                let vm = this;
                if (_.isEmpty(vm.Country) || vm.Country.id == '') {
                    // 
                }else {
                    vm.formCountry.fill(vm.Country);
                    if(vm.Country!=null && vm.Country.timezone_id!="") {
                        vm.formCountry.timezone = vm.timezones.find(c => {
                            return c.id == vm.Country.timezone_id;
                        });
                    }
                    if(vm.Country!=null && vm.Country.language_id!="") {
                        vm.formCountry.language = vm.languages.find(c => {
                            return c.id == vm.Country.language_id;
                        });
                    }
                    if(vm.Country!=null && vm.Country.currency_id!="") {
                        vm.formCountry.currency = vm.currencies.find(c => {
                            return c.id == vm.Country.currency_id;
                        });
                    }
                    if(vm.Country!=null && vm.Country.region_id!="") {
                        vm.formCountry.region = vm.regions.find(c => {
                            return c.id == vm.Country.region_id;
                        });
                    }
                }
            },
        },
        methods: {
            getTimezones() {
                let endpoint = '/admin/location/timezones/all';
                axios.get(endpoint).then(({data}) => {
                    let timezones = data.map(l => {
                        let timezone = l
                        let d = {
                            id: timezone.id,
                            name: timezone.name,
                            label: `${timezone.name} ${timezone.description}`
                        }
                        return d
                    });
                    this.timezones = timezones;

                    this.timezones.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            getLanguages() {
                let endpoint = '/admin/location/languages/all';
                axios.get(endpoint).then(({data}) => {
                    let languages = data.map(l => {
                        let language = l
                        let d = {
                            id: language.id,
                            name: language.name,
                            label: `${language.name} (${language.native_name})`
                        }
                        return d
                    });
                    this.languages = languages;

                    this.languages.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            getCurrencies() {
                let endpoint = '/admin/location/currencies/all';
                axios.get(endpoint).then(({data}) => {
                    let currencies = data.map(l => {
                        let currency = l
                        let d = {
                            id: currency.id,
                            name: currency.name,
                            label: ` ${currency.symbol} ${currency.name} (${currency.code})`
                        }
                        return d
                    });
                    this.currencies = currencies;

                    this.currencies.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            getRegions() {
                let endpoint = '/admin/location/regions/all';
                axios.get(endpoint).then(({data}) => {
                    let regions = data.map(l => {
                        let region = l
                        let d = {
                            id: region.id,
                            name: region.name,
                            label: `${region.name}`
                        }
                        return d
                    });
                    this.regions = regions;

                    this.regions.sort(function(a, b) {
                        var a1= a.name, b1= b.name;
                        if(a1 == b1) return 0;
                        return a1> b1? 1: -1;
                    });
                });
            },
            onSaveCountry() {
                let vm = this;

                if(vm.formCountry.timezone.id) {
                    vm.formCountry.timezone_id = vm.formCountry.timezone.id;
                }
                if(vm.formCountry.language.id) {
                    vm.formCountry.language_id = vm.formCountry.language.id;
                }
                if(vm.formCountry.currency.id) {
                    vm.formCountry.currency_id = vm.formCountry.currency.id;
                }
                if(vm.formCountry.region.id) {
                    vm.formCountry.region_id = vm.formCountry.region.id;
                }
                
                let tipo = (vm.formCountry.id == null) ? 1 : 2;
                if(this.formCountry.id != null) {
                    $.confirm({
                        title: `Editar Pais`,
                        content: '¿Seguro quiere Editar?',
                        buttons: {
                            eliminar:{
                                text: 'Editar',
                                btnClass: 'btn-primary',
                                keys: ['enter'],
                                action: function() {
                                    vm.updateOrCreate(tipo)
                                }
                            },
                            cancelar: function () {

                            },
                        }
                    });
                } else {
                    vm.updateOrCreate(tipo);
                }
            },
            updateOrCreate (tipo) {
                let vm = this;
                
                vm.formCountry.post('/admin/location/countries/save').then(response => {
                    let data = response.data;

                    if (data.user_request_status) {
                        $('#modalCountry').modal('hide');
                        return;
                    }

                    let snotify_message = tipo == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.'
                    vm.$snotify.success(snotify_message);
                    vm.$emit('reloadCountryModal');
                    $('#modalCountry').modal('hide');
                    vm.formCountry.clear();
                    vm.formCountry.reset();
                    return;
                });
            },
        },
        mounted() {
           let vm = this

            vm.getTimezones();
            vm.getLanguages();
            vm.getCurrencies();
            vm.getRegions();

            $('#modalCountry').off('hidden.bs.modal');
            $('#modalCountry').on('hidden.bs.modal', function () {
                vm.formCountry.clear();
                vm.formCountry.reset();
                vm.$emit('closeCountryModal');
            });
        }
        
    }
</script>
<style>
    .modal#modalCountry{
        overflow-y: auto !important;
    }
</style>