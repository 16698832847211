<template>
    <button :type="actionType" :disabled="loading"
        :class="{ [`btn-${type}`]: true, 'btn-soft': soft, 'btn-loading': loading }" class="btn">
        <slot />
    </button>
</template>


<script>
export default {
    name: 'VButton',

    props: {

        type: {
            type: String,
            default: 'primary'
        },

        actionType: {
            type: String,
            default: 'submit'
        },

        loading: {
            type: Boolean,
            default: false
        },

        soft: {
            type: Boolean,
            default: false
        },
    }
}
</script>
