<template>
    <div class="modal fade" id="addSetting" tabindex="-1" role="dialog" aria-labelledby="addSettingLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form autocomplete="off" @submit.prevent="save" id="addSettingForm" class="needs-validation" novalidate method="post">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addSettingLabel">Nuevo Parámetro</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="title">Titulo</label>
                                        <input type="text" name="title" class="form-control" v-model="form.title">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="type">Tipo de dato</label>
                                        <select id="type" class="form-control" v-model="form.type">
                                            <option v-for="(type, index) in types" :key="index" :value="type.value" v-text="type.name"></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="name">Nombre del parámetro (Único)</label>
                                        <input type="text" name="name" class="form-control" v-model="form.name">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="description">Descripción</label>
                                        <textarea name="description" class="form-control" rows="5" v-model="form.description"></textarea>
                                    </div>
                                </div>
                            </div>

                            <small class="font-weight-bold" v-if="form.type !== 'BOOLEAN'">
                                OTRAS CONFIGURACIONES
                                <hr class="mt-0">
                            </small>
                            <div class="form-row" v-if="form.type === 'STRING'">
                                <div  class="col-md-12">
                                    <div class="form-group">
                                        <label for="first_name">Limite de caracteres</label>
                                        <input type="number" id="stringLimit" class="form-control" v-model="validates.string.limit">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" v-if="form.type === 'NUMBER'">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="first_name">Minimo</label>
                                        <input type="number" id="numberMin" class="form-control" v-model="validates.number.min">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="first_name">Maximo</label>
                                        <input type="number" id="numberMax" class="form-control" v-model="validates.number.max">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-0">
                                        <label class="mb-0">
                                            <input type="checkbox" id="numberAllowDecimals" v-model="validates.number.decimal">
                                            Permitir numeros decimales
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" v-if="form.type === 'ARRAY'">
                                <div class="col-12">
                                    <div class="form-group">
                                    <tagInput
                                            ref="tagList"
                                            title="Datos aceptados"
                                            placeholder="Agregar elementos..."
                                            :value="validates.array.items"
                                            :show-focus="true"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-0">
                                        <label class="mb-0">
                                            <input type="checkbox" id="arrayMultiple" v-model="validates.array.multiple">
                                            Permitir seleccionar multiples
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="modal-footer">
                        <v-button class="center-content" :type="'primary'" :loading="form.processing">
                            Guardar
                        </v-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import tagInput from '~/components/tagInput'
import vButton from '~/components/button'

export default {
    components: {
        tagInput,
        vButton
    },
    data () {
        return {
            form: new WebForm('#addSettingForm', {
                'id': null,
                'name': '',
                'title': '',
                'description': '',
                'type': 'STRING',
                'validate': '',
            }),
            types: [
                { name: 'Texto',    value: 'STRING' }, 
                { name: 'Numérico', value: 'NUMBER' }, 
                { name: 'Lista',    value: 'ARRAY'  }, 
                { name: 'Booleano', value: 'BOOLEAN'}
            ],
            validates: {
                string: {
                    limit: null
                },
                number:{
                    min: null,
                    max: null,
                    decimal: false
                },
                array: {
                    items: [],
                    multiple: false
                },
            }
        }
    },
    methods: {
        save () {
            let vm        = this
            let type      = this.form.type.toLocaleLowerCase()
            let validates = this.validates[type] || null
            let endpoint  = `/admin/settings/sdk/bookingengine/add`

            //validacion datos nulables
            if(validates != null){
                Object.keys(validates).forEach(key => {
                    let value      = validates[key]
                    validates[key] = value == '' ? null : value
                });
            }
            
            vm.form.validate = JSON.stringify(validates)
            vm.form.post(endpoint).then(response => {
                vm.form.reset()
                vm.resetOptions()
                vm.$snotify.success('Parametro agregado');
                vm.$emit('save', this.item)
                $('#addSetting').modal('hide')
            })
        },
        resetOptions () {
            this.validates.array        = []
            this.validates.number.min   = null
            this.validates.number.max   = null
            this.validates.string.limit = null
        },
        show(item = null){
            let vm = this
            if(item == null)
                return
            
            vm.form.fill(item)
            let validate = _.isEmpty(item.validate) ? null : JSON.parse(item.validate || null)
            if(validate != null)
                vm.validates[item.type.toLocaleLowerCase()] = validate
        }
    },
    mounted () {
        let vm = this

        $('#addSetting').off('hidden.bs.modal')
        $('#addSetting').on('hidden.bs.modal', function (e) {
            vm.form.reset()
        })
    },
}
</script>