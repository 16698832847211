<template>
    <div>
        <b>Pantalla</b> en proceso de desarrollo...
    </div>
</template>

<script>
    export default {
        middleware:'auth',
        layout: 'dashboard',
    }
</script>