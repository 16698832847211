
import axios   from 'axios'
// import cookies from 'js-cookie'

const cryptoJS  = require("crypto-js");
const cryptoKey = 'pavolar-el-salvador'

// Mutation Types
export const GET_PERMISSION = 'GET_PERMISSION'

//State
export const state = {
    access: JSON.parse(decryptPermissions() || 'null'),
}

// Mutations
export const mutations = {

    [GET_PERMISSION] (state, { permissions }) {
        state.access   = permissions
        var cipherText = cryptoJS.AES.encrypt(JSON.stringify(permissions), cryptoKey);
        sessionStorage.setItem('pavolar_permission', cipherText.toString())
        // cookies.set('pavolar_permission', cipherText.toString())
    },
}

// Actions
export const actions = {

    async getPermissions ({ commit }) {
      try {
            var decrypt = decryptPermissions()
            if(decrypt != null)
                return

            const { data } = await axios.post('/admin/permission/access')
            commit(GET_PERMISSION, { permissions: data })
        } catch (e) {
            if(e.response.status == 401)
                throw e.response.status;
            else
                console.log(e)
        }
    },

}

// Getters
export const getters = {
    access: state => state.access,
    roles: state => state.access.roles
};

function decryptPermissions(){

    // var cookie = cookies.get('pavolar_permission')
    var permissions = sessionStorage.getItem('pavolar_permission')
    if(typeof permissions === 'undefined' || permissions == null || permissions === '')
        return null;

    var bytes = cryptoJS.AES.decrypt(permissions, cryptoKey);
    return bytes.toString(cryptoJS.enc.Utf8);
}
