<template>
    <v-select v-model="selected" :disabled="disabled" :id="`airlinesInput${_uid}`" :placeholder="placeholder" :multiple="multiple" :options="airlines" label="name" :filterable="false" @search="onSearchAirlines" @input="onInput">
        <template slot="no-options">Realizar busqueda...</template>
        <template slot="option" slot-scope="option">
            <img class="airline-logo" width="20" :src="`/img/airlines/${option.iata}.png`" onerror="this.src='/img/airlines/default.png';" >
            ({{ option.iata }}) {{ option.name }}
        </template>
        <template slot="selected-option" slot-scope="option">
            <img class="airline-logo" width="20" :src="`/img/airlines/${option.iata}.png`" onerror="this.src='/img/airlines/default.png';">
            ({{ option.iata }}) {{ option.name }}
        </template>
    </v-select>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Array, Object],
                default: null,
            },
            placeholder: {
                type: String,
                default: "Buscar Aerolinea...",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                airlines: [],
                favoriteAirlines: [],
                selected: this.value,
            }
        },
        watch:{
            value(newValue){
                this.selected = newValue
            }
        },
        methods: {
            onInput(value){
                this.$emit("input", value);
            },
            onSearchAirlines(search, loading) {
                if(_.isEmpty(search)){
                    this.airlines = this.favoriteAirlines
                    loading(false);
                    return
                }

                loading(true);
                this.searchAirlines(loading, search, this);
            },
            searchAirlines: _.debounce((loading, search, vm) => {
                axios.get(`/admin/airlines/${escape(search)}`).then(res => {
                    vm.airlines = res.data
                    loading(false);
                });
            }, 800),
            getFavoriteArilines(){
                axios.get('/admin/airlines/favorite').then(response => {
                    this.airlines = this.favoriteAirlines = response.data || []
                })
            },
        },
        created() {
            this.getFavoriteArilines()
        },
    }
</script>