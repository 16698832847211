<template>
    <div>
        <div class="modal fade" @submit.prevent="saveTouristResponse()" id="touristResponseModal">
            <div v-if="QuoteTourisId" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formTouristResponse" autocomplete="off" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="TouristResponse && TouristResponse.id">
                                    Editar Solucion de Atraccion Turistica
                                </span>
                                <span v-else>Nuevo Solucion de Atraccion Turistica</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="attraction_id" class="required" style="width: 100%; margin-bottom: 3px;">
                                        Buscar Atraccion Turistica
                                        <a :href="'/packages/attractions'" target="_blank" class="float-right btn btn-secondary btn-sm" title="Agregar Atraccion">
                                            <span class="icon-add-2"></span>
                                        </a>
                                    </label>
                                    <v-select placeholder="Buscar Atraccion " v-model="attraction" :options="attractions" 
                                    label="name" :filterable="false" @search="onSearchAttraction" selectOnTab>
                                        <template slot="no-options">Sin resultados...</template>
                                        <template slot="option" slot-scope="option">
                                            <img :src="`https://www.countryflags.io/${option.city.state.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="option.code" class="mr-1"> 
                                            {{ option.name }} - {{ option.city.state.name }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <img :src="`https://www.countryflags.io/${option.city.state.country.short_iso_code}/flat/24.png`" width="24" height="24" :alt="option.code" class="mr-1"> 
                                            {{ option.name }} - {{ option.city.state.name }}
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label for="tickets">Tickets</label>
                                    <input type="number" name="tickets" min="0" max="10" class="form-control" v-model="tickets">                     
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="non_date" name="non_date" class="pt-2 btn-add-aa">
                                        Sin fechas
                                    </label>
                                </div>
                                <div class="form-group col-12 col-md-2">
                                    <label style="margin-top: 35px;">
                                        <input type="checkbox" v-model="is_free" name="is_free" class="pt-2 btn-add-aa">
                                        Sin Costo
                                    </label>  
                                </div>
                            </div>
                            <div class="row" v-show="!non_date">
                                <!-- -->
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_date" class="required">Fecha de Ingreso</label>
                                    <vue-datepicker v-model="entry_date" name="entry_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="entry_hour" class="required">Hora de Ingreso</label>
                                    <vue-timepicker name="entry_hour" v-model="entry_hour" placeholder="Hora de Ingreso" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="out_date" class="required">Fecha de Salida</label>
                                    <vue-datepicker v-model="out_date" name="out_date"></vue-datepicker>
                                </div>
                                <div class="form-group col-12 col-md-3">
                                    <label for="out_hour" class="required">Hora de Salida</label>
                                    <vue-timepicker name="out_hour" v-model="out_hour" placeholder="Hora de Salida" format="H:mm" close-on-complete hide-clear-button></vue-timepicker>
                                </div>
                                <!--  -->
                            </div>
                            <div class="row" v-show="!is_free">
                                <!--  -->
                                <div class="form-group col-12 col-md-4">
                                    <label for="cost" class="required">Costo $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="cost"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formTouristResponse__cost"
                                    :precision="2" :decimal-separator="'.'"
                                    name="cost"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="taxes">Impuestos $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="taxes"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formTouristResponse__taxes"
                                    :precision="2" :decimal-separator="'.'"
                                    name="taxes"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                    <label for="utility" class="required">Utilidad $</label>
                                    <vue-numeric currency="$" class="form-control" separator="," v-model.number="utility"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" id="formTouristResponse__utility"
                                    :precision="2" :decimal-separator="'.'"
                                    name="utility"></vue-numeric>
                                </div>
                                <!--  -->
                            </div>
                            <div class="row" v-show="!is_free">
                                <!--  -->
                                <div class="form-group col-12 col-md-4" >
                                    <label for="total">Precio total</label>
                                    <vue-numeric currency="$" class="form-control" separator="," :value="priceTotal"
                                    :min="0" :max="99999" :empty-value="0" placeholder="" 
                                    :precision="2" :decimal-separator="'.'" disabled
                                    name="total"></vue-numeric>
                                </div>
                                <div class="form-group col-12 col-md-8">
                                    <label for="url">Enlace de origen del precio</label>
                                    <input type="text" class="form-control" placeholder="Ingrese URL del Sitio" v-model="url" name="url">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Descripcion</label>
                                    <textarea class="form-control" v-model="description" name="description" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" :disabled="!validateSave || !owner" class="btn btn-primary" v-can:create="'packages-index'"> 
                                <span v-if="TouristResponse && TouristResponse.id && $can('update', 'packages-index')">
                                    Editar Atraccion
                                </span>
                                <span v-else>
                                    Guardar Atraccion
                                </span>
                            </button>
                            <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="css" scoped>
    .is-invalid{
        border-color: #e3342f;
    }
</style>
<script>

import VueNumeric from 'vue-numeric';

export default {
    components: {
        VueNumeric,
    },
    props: {
        TouristResponse: {
            type: [Object],
            default () {
                return false
            }
        },
        QuoteTourisId: null,
        owner: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    data() {
        return {
            attraction              : null,
            attractions             : [],
            touristIndex            : null,
            loadServices            : false,
            formTouristResponse: new WebForm('#formTouristResponse', {
                id                      : null,
                attraction_id           : null,
                quote_tourist_id        : null,
                body_json               : null
            }),
            tickets                 : 0,
            non_date                : false,
            is_free                 : false,
            entry_date              : '',
            out_date                : '',
            entry_format            : '',
            out_format              : '',
            entry_hour              : {"H":"00", "mm":"00"},
            out_hour                : {"H":"00", "mm":"00"},
            cost                    : 0,
            taxes                   : 0,
            utility                 : 0,
            description             : '',
            url                     : '',
        };
    },
    watch: {
        QuoteTourisId: function(val, oldVal) {
            let vm = this;
            if(vm.QuoteTourisId!=null) {
                vm.formTouristResponse.quote_tourist_id = vm.QuoteTourisId;
            } else {
                vm.formTouristResponse.quote_tourist_id = null;
            }
        },
        TouristResponse: function(val, oldVal) {
            let vm = this;
            if(vm.TouristResponse!=null && vm.QuoteTourisId!=null) {
                vm.formTouristResponse.id  = vm.TouristResponse.id;
                vm.non_date = vm.TouristResponse.body_json.non_date;
                vm.is_free  = vm.TouristResponse.body_json.is_free;
                
                if (!vm.TouristResponse.body_json.non_date) {
                    vm.entry_date               = vm.TouristResponse.body_json.entry_date;
                    vm.entry_hour               = vm.TouristResponse.body_json.entry_hour;
                    vm.entry_format             = vm.TouristResponse.body_json.entry_format;
                    vm.out_date                 = vm.TouristResponse.body_json.out_date;
                    vm.out_hour                 = vm.TouristResponse.body_json.out_hour;
                    vm.out_format               = vm.TouristResponse.body_json.out_format;
                }
                if (!vm.TouristResponse.body_json.is_free) {
                    vm.cost                     = vm.TouristResponse.body_json.cost;
                    vm.taxes                    = vm.TouristResponse.body_json.taxes;
                    vm.utility                  = vm.TouristResponse.body_json.utility;
                    vm.url                      = vm.TouristResponse.body_json.url;
                }
                vm.description                  = vm.TouristResponse.body_json.description;
                vm.tickets                      = vm.TouristResponse.body_json.tickets;

                vm.attractions = [];
                vm.attractions.push(vm.TouristResponse.attraction);
                vm.attraction = vm.TouristResponse.attraction;
                
            } else {
                vm.formTouristResponse.id  = null;
                vm.entry_date              = '';
                vm.entry_hour              = {"H":"00", "mm":"00"};
                vm.entry_format            = '';
                vm.out_date                = '';
                vm.out_hour                = {"H":"00", "mm":"00"};
                vm.out_format              = '';
                vm.cost                    = 0;
                vm.taxes                   = 0;
                vm.utility                 = 0;
                vm.description             = '';
                vm.url                     = '';
                vm.tickets                 = 0;
                vm.non_date                = false;
                vm.is_free                 = false;
                vm.attraction              = null;
            }
        }, 
    },
    computed: {
        validateSave() {
            let vm = this;
            let response = true;
            if (!vm.non_date) {
                response = (vm.entry_date) ? response : false;
                response = (vm.out_date) ? response : false;
            }
            if (!vm.is_free) {
                response = (vm.cost && vm.cost > 0) ? response : false;
                response = (vm.utility && vm.utility > 0) ? response : false;
            }
            response = (vm.attraction && vm.attraction.id) ? response : false;
            return response;
        },
        priceTotal() {
            let vm = this;
            let cost        = (vm.cost) ? parseFloat(vm.cost) : 0; 
            let taxes       = (vm.taxes) ? parseFloat(vm.taxes) : 0;
            let utility     = (vm.utility) ? parseFloat(vm.utility) : 0;
            let total = cost + taxes + utility;
            vm.card_amount = total;
            return total;
        },
    },
    methods: {
        onSearchAttraction(search, loading) {
            if(_.isEmpty(search)){
                // this.airlines = this.favoriteAirlines
                loading(false);
                return
            }
            loading(true);
            this.searchAttraction(loading, search, this);
        },
        searchAttraction: _.debounce((loading, search, vm) => {
            axios.get(`/admin/attractions/${escape(search)}`).then(res => {
                console.log("RES:: ", res);
                vm.attractions = res.data
                loading(false);
            });
        }, 800),
        saveTouristResponse() {
            let vm = this;
            let newData = {};

            newData.non_date = vm.non_date;

            if (!vm.non_date) {
                let entry_format = (vm.entry_hour.H>9 || vm.entry_hour.H.length > 1) ? vm.entry_date + ' ' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' : vm.entry_date + ' 0' + vm.entry_hour.H + ':' + vm.entry_hour.mm + ':00' ;
                let out_format      = (vm.out_hour.H>9 || vm.out_hour.H.length > 1) ? vm.out_date + ' ' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' : vm.out_date + ' 0' + vm.out_hour.H + ':' + vm.out_hour.mm + ':00' ;
                newData.entry_date      = vm.entry_date;
                newData.entry_format    = entry_format;
                newData.entry_hour      = vm.entry_hour;
                newData.out_date        = vm.out_date;
                newData.out_format      = out_format;
                newData.out_hour        = vm.out_hour;
            }
            
            newData.is_free = vm.is_free;

            if (!vm.is_free) {
                newData.cost        = vm.cost,
                newData.taxes       = vm.taxes,
                newData.url         = vm.url,
                newData.utility     = vm.utility
            }
            
            newData.description = vm.description;
            newData.tickets = vm.tickets;
            

            if (vm.TouristResponse && vm.TouristResponse.id) {
                vm.formTouristResponse.id = vm.TouristResponse.id;
            }

            if (vm.attraction && vm.attraction.id) {
                vm.formTouristResponse.attraction_id = vm.attraction.id;
            }

            vm.formTouristResponse.quote_tourist_id = vm.QuoteTourisId;

            vm.formTouristResponse.body_json      = JSON.stringify(newData);

            console.log(vm.formTouristResponse);
            
            vm.formTouristResponse.post('/admin/quotes/tourists/save').then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    vm.$snotify.error('Ups, algo ha salido mal...');
                }
                if(data.success) {
                    let msj = (vm.TouristResponse && vm.TouristResponse.id) ? 'Atraccion editada con exito.' : 'Nueva Atraccion agregada.' ;
                    vm.$snotify.success(msj);
                    let obj = data.success;
                    this.$emit("saveTouristResponse", obj);
                    $("#touristResponseModal").modal("hide");
                }
                return;
                vm.$snotify.info(`Algo resulto mal...`)
            });
        },
    },
    mounted() {
        let vm = this;
        $('#touristResponseModal').off('hidden.bs.modal');
        $('#touristResponseModal').on('hidden.bs.modal', function () {
            vm.$emit('closeTouristResponse');
        });
        console.log('TouristResponseModal mounted.');
    }
}
</script>

