<template>
    <div class="modal fade" id="modalHotelGallery">
            <div v-if="Gallery" class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form id="formHotelGallery" autocomplete="off" @submit.prevent="onSaveGallery()" novalidate>
                        <div class="modal-header">
                            <h5 class="font-weight-bold mb-0">
                                <span v-if="formHotelGallery.hotel_id">
                                    Galeria de Imagenes
                                </span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="images">Imagenes Actuales</label>
                                    <image-display :images="imageGallery" :clearAll="clearImages" :urlDelete="'/admin/hotels/galleries/delete'"></image-display>
                                </div>
                                <div class="form-group col-12">
                                    <label for="load">Cargar Imagenes (Maximo {{nMaxImages}})</label>
                                    <file-image v-on:setGalery="getHotelGalery" :nImgs="imagesRemaining" :key="'ForHotelGallery'" :clearAll="clearImages"></file-image>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button v-if="formHotelGallery.hotel_id && $can('update', 'packages-hotels-index')" type="submit" class="btn btn-primary" > 
                                <span>
                                    Guardar
                                </span>
                            </button> 
                            <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close" >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</template>
<script>
    import FileImage from '~/components/packages/FileImage';
    import imageDisplay from '~/components/gallery/imageDisplay';

    export default {
        props: ["Gallery"],
        components: {
            FileImage,
            imageDisplay
        },
        watch: {
            Gallery: function(val, oldVal) {
                if (this.Gallery) {
                    this.loadGallery(this.Gallery);
                }
            },
        },
        data() {
            return {
                clearImages                 : false,
                nMaxImages                  : 10,
                formHotelGallery: new WebForm('#formHotelGallery', {
                    hotel_id   : null,
                    newGallery : [],
                }),
                imageGallery   : [],
            }
        },
        computed: {
            imagesRemaining() {
                let vm = this;
                if (vm.imageGallery && vm.imageGallery.length>0) {
                    let cant = parseInt(vm.imageGallery.length);
                    return vm.nMaxImages - cant;
                } else {
                    return vm.nMaxImages;
                }
            }
        },
        methods: {
            getHotelGalery(obj) {
                this.formHotelGallery.newGallery = obj;
            },
            loadGallery(id) {
                let vm = this;
                axios.get(`/admin/hotels/galleries/${id}`).then(response => {
                    let data                        = response.data;
                    vm.imageGallery                 = data;
                    vm.formHotelGallery.newGallery  = [];
                    vm.formHotelGallery.hotel_id    = id;
                });
            },
            onSaveGallery() {
                let vm = this;
                if(vm.formHotelGallery.newGallery.length==1) {
                    if(vm.validateImage(vm.formHotelGallery.newGallery[0])==0) {
                        vm.formHotelGallery.newGallery.splice(0, 1);
                    }
                }
                if( vm.formHotelGallery.newGallery.length > 1) {
                    let num = vm.formHotelGallery.newGallery.length;
                    for (let index = num; index > 0; index--) {
                        const element = vm.formHotelGallery.newGallery[index-1];
                        if(vm.validateImage(element)==0) {
                            vm.formHotelGallery.newGallery.splice(index-1, 1);
                        }
                    }
                }

                if(vm.formHotelGallery.newGallery.length>0) {
                    // Ahora guardamos las imagenes
                    vm.formHotelGallery.post('/admin/hotels/galleries/save').then(response => {
                        let data = response.data;

                        if (data.user_request_status) {
                            $('#modalHotelGallery').modal('hide');
                            return;
                        }
                        
                        vm.$snotify.simple('Archivos cargados con exito.')
                        vm.formHotelGallery.clear();
                        vm.formHotelGallery.reset();
                        $('#modalHotelGallery').modal('hide');
                        return;
                    });

                } else {
                    vm.$snotify.simple('No hay imagenes a cargar')
                }
            },
            validateImage(img) {
                let respo = 0;
                let formato =["data:image/jpeg;base64", "data:image/jpg;base64", "data:image/png;base64"];
                if(img!="" && img!=null) {
                    let short = img.substring(0, 50);
                    for (var i = 0; i < formato.length; i++) {
                        if(short.indexOf(formato[i], 0) == 0 ) {
                            respo++;
                        }
                    }
                }
                return respo;
            },
        },
        mounted() {
           let vm = this
            $('#modalHotelGallery').off('hidden.bs.modal');
            $('#modalHotelGallery').on('hidden.bs.modal', function () {
                vm.imageGallery = [];
                vm.formHotelGallery.newGallery = [];
                vm.formHotelGallery.clear();
                vm.formHotelGallery.reset();
                vm.$emit('closeHotelGalleryModal');
            });
        }
    }
</script>