<template>
    <div class="modal fade" id="addCity">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form :id="'formCity-'+_uid" autocomplete="off" @submit.prevent="onSaveItem()" novalidate>
                    <div class="modal-header">
                        <h5 class="font-weight-bold mb-0">
                            <span v-if="formCity.id">Editar Ciudad</span>
                            <span v-else>Nueva Ciudad</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12 ">
                                <label for="name" class="required">Nombre</label>
                                <input type="text" class="form-control" placeholder="Ingrese Nombre de la Ciudad"
                                    v-model="formCity.name" name="name" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="latitude" class="">Latidud</label>
                                <input type="text" class="form-control" placeholder="Ingrese Latitud"
                                    v-model="formCity.latitude" name="latitude" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="longitude" class="">Longitud</label>
                                <input type="text" class="form-control" placeholder="Ingrese Longitud"
                                    v-model="formCity.longitude" name="longitude" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="country_id" class="required">Pais</label>
                                <v-select v-model="country"
                                name="country_id" @search="onSearchCountry" ref="vSelectCountry"
                                placeholder="Seleccione Pais" selectOnTab label="name"
                                :options="countries" required="required">
                                    <template v-slot:option="option">
                                        <img :src="`https://www.countryflags.io/${option.code}/flat/24.png`" width="24" height="24" :alt="option.code">
                                        {{ option.name }} ({{ option.native_name}})
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="state_id" style="width: 100%">Estado
                                    <span class="btn float-right btn-secondary btn-sm font-weight-bold" style="padding: 2px 8px;" data-toggle="modal" data-target="#addState">
                                        <span class="icon-add-2" grid="12"></span>
                                    </span>
                                </label>
                                <v-select v-model="state"
                                name="state_id" ref="vSelectState"
                                placeholder="Seleccione Estado" selectOnTab label="name"
                                :options="states" required="required"></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary"  v-can:create="'geodata-cities'">
                            <span v-if="formCity.id && $can('update','geodata-cities')">Editar</span>
                            <span v-else-if="formCity.id && !$can('update','geodata-cities')">Solicitar Edicion</span>
                            <span v-else>Guardar</span>
                        </button>
                        <button type="button" class="btn brn-default" data-dismiss="modal" aria-label="Close">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        City: {
            type : [Object, String],
            default: '',
        },
        StateNew: {
            type: [Object, null],
            default: null,
        }
    },
    components: {
        },
    data() {
        return {
            countries   : [],
            states      : [],
            country     : null,
            state       : null,
            formCity: new WebForm("#formCity-" + this._uid, {
                name            : null,
                latitude        : null,
                longitude       : null,
                state_id        : null,
                country_id      : null,
                id              : null
            }),
        };
    },
    watch: {
        country: function(val, oldVal) {
            this.$refs.vSelectState.clearSelection();
            this.states = [];
            if (!_.isEmpty(this.country)) {
                this.getStates(this.country.id);
            }
        },
        City: function(val, oldVal) {
            this.formCity.clear();
            this.formCity.reset();
            if (!_.isEmpty(this.City)) {
                this.formCity.fill(this.City);
                this.getCountry(this.City.state.country_id);
            }
        },
        StateNew: function(val, oldVal) {
            if (!_.isEmpty(this.StateNew)) {
                if (this.StateNew && this.StateNew.id && this.StateNew.country_id == this.country.id) {
                    let state_ = {
                        id: this.StateNew.id,
                        label: `${this.StateNew.name}`,
                        name: `${this.StateNew.name}`,
                    };
                    this.states.push(state_);
                    this.state = state_;
                }
            }
        },
    },
    methods: {
        onSaveItem() {
            let vm = this;
            if(vm.country.id) {
                vm.formCity.country_id = vm.country.id;
            }
            if(vm.state.id) {
                vm.formCity.state_id = vm.state.id;
            }
            let tipo = (vm.formCity.id == null) ? 1 : 2;
            if (this.formCity.id != null) {
                $.confirm({
                    title: `Editar Ciudad`,
                    content: "¿Seguro quiere esta ciudad Editar?",
                    buttons: {
                        eliminar: {
                            text: "Editar",
                            btnClass: "btn-primary",
                            keys: ["enter"],
                            action: function() {
                                vm.updateOrCreate("/admin/location/cities/save", tipo);
                            }
                        },
                        cancelar: function() {}
                    }
                });
            } else {
                vm.updateOrCreate("/admin/location/cities/save", tipo);
            }
        },
        updateOrCreate(url, type) {
            let vm = this;
            vm.formCity.post(url).then(response => {
                let data = response.data;
                if (data.user_request_status) {
                    $('#addCity').modal('hide');
                    return;
                }
                let snotify_message = type == 1 ? 'Elemento Agregado con exito.' : 'Elemento actualizado con exito.';
                vm.$snotify.success(snotify_message);
                let obj = data.success;
                vm.$emit('reloadCityModal', obj);
                vm.formCity.clear();
                vm.formCity.reset();
                $('#addCity').modal('hide');
                return;
            });
        },
        onSearchCountry(search, loading) {
            if(_.isEmpty(search))
                return
            loading(true);
            this.searchCountry(loading, search, this);
        },
        searchCountry: _.debounce((loading, search, vm) => {
            axios.get(`/admin/location/countries/${escape(search)}`).then(res => {
                vm.countries = res.data
                loading(false);
            });
        }, 800),
        getCountry(id) {
            let vm = this;
            axios.get(`/admin/location/countries/${id}`).then((response) => {
                if (response.data) {
                    let data = response.data;
                    let countries = [{
                        id      :       data.id,
                        code    :       data.short_iso_code,
                        name    :       `${data.name} (${data.native_name})`,
                        label   :       `${data.name}` 
                    }];
                    vm.countries = countries;
                    vm.country = vm.countries[0];
                } else {
                    console.log('No se pudieron cargar los paquetes');
                }
            });
        },
        getStates(country_id){
            let vm = this;
            let endpoint = `/admin/location/states/country/${country_id}`;
            axios.get(endpoint).then(({data}) => {
                let states = data.map(state => {
                    let d = {
                        id: state.id,
                        label: `${state.name}`,
                        name: `${state.name}`,
                    }
                    return d;
                });
                this.states = states;
                if(vm.City!=null && vm.City.state && vm.City.state.id!="") {
                    vm.state = vm.states.find(c => {
                        return c.id == vm.City.state.id;
                    });
                }
            });
        },
    },
    mounted() {
        let vm = this;
        $('#addCity').off('hidden.bs.modal');
        $('#addCity').on('hidden.bs.modal', function () {
            vm.countries   = [];
            vm.states      = [];
            vm.country     = null;
            vm.state       = null;
            vm.formCity.clear();
            vm.formCity.reset();
            vm.$emit('closeCityModal');
        });
    }
};
</script>
<style scoped>
.modal-content {
    border: solid 2px rgba(44, 44, 44, 0.3);
    box-shadow: 0px 0px 70px 20px #1d1c1cd9 !important;
}
</style>